import React, { useContext, useEffect, useRef, useState } from 'react'
import "../../css/ViewCarriarStyles/CriteriaDialog.css"
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Popover, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Symboles from '../../../components/symboles/symboles';
import deleteIcon from '../../../assets/Carriers/carrier-delete-icon.svg'
import editIcon from '../../../assets/Carriers/carrier-edit-chip.svg'
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"
import warningAlert from "../../../assets/Delete-alert.png"
import CloseIcon from '@mui/icons-material/Close';
import Icons from '../../../components/icons/icons';


const CriteriaDialog = (props) => {

    const { setFlagCriteriDialog, carrierDetails, setCarrierDetails, criteriaCellData, saveCriteareDetails, productIds } = props
    const {
        criteriaGenericData,
    } = useContext(CarrierContext).state

    const issueAgeTextFieldId = criteriaGenericData.length && criteriaGenericData?.find(item => item.criteria_name == "Issue Age")?.criteria_field_data?.find(field => field.criteria_field_name == "To Age")?.criteria_field_option_data?.find(option => option.criteria_field_option_value == "Custom Text Field")?.criteria_field_option_id;
    const renewalYearTextFieldId = criteriaGenericData.length && criteriaGenericData?.find(item => item.criteria_name == "Renewal Year")?.criteria_field_data?.find(field => field.criteria_field_name == "End Year")?.criteria_field_option_data?.find(option => option.criteria_field_option_value == "Custom Text Field")?.criteria_field_option_id;
    const excessYearTextFieldId = criteriaGenericData.length && criteriaGenericData?.find(item => item.criteria_name == "Excess Year")?.criteria_field_data?.find(field => field.criteria_field_name == "End Year")?.criteria_field_option_data?.find(option => option.criteria_field_option_value == "Custom Text Field")?.criteria_field_option_id;
    const policyYearTextFieldId = criteriaGenericData.length && criteriaGenericData?.find(item => item.criteria_name == "Policy Year")?.criteria_field_data?.find(field => field.criteria_field_name == "End Year")?.criteria_field_option_data?.find(option => option.criteria_field_option_value == "Custom Text Field")?.criteria_field_option_id;

    const [expanded, setExpanded] = useState({
        panel1: true,
        panel2: true,
        panel3: true,
        panel4: true,
        panel5: true,
        panel6: true,
        panel7: true
    });

    // FOR GLOBAL STATE 
    const [carrierProductCriteria, setCarrierProductCriteria] = useState([]);
    const [productName, setProductName] = useState('');

    //REF
    const textFieldRenewalYearRef = useRef(null);
    const textFieldIssueAgeRef = useRef(null);
    const textFieldExcessYearRef = useRef(null)
    const textFieldPolicyYearRef = useRef(null);

    // FOR CHECKBOX
    const [selectedStates, setSelectedStates] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    // FOR BUTTONS 
    const [showIssueAgeButtons, setShowIssueAgeButtons] = useState(false);
    const [showRenewalYearButtons, setShowRenewalYearButtons] = useState(false);
    const [showExcessYearButtons, setShowExcessYearButtons] = useState(false);
    const [showPolicyYearButtons, setShowPolicyYearButtons] = useState(false);

    const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);
    const [isAnySaveCancelVisible, setIsAnySaveCancelVisible] = useState(false);

    // FOR CHIPS 
    const [issueAgeSelectedChip, setIssueAgeSelectedChip] = useState(false);
    const [renewalYearSelectedChip, setRenewalYearSelectedChip] = useState(false);
    const [excessYearSelectedChip, setExcessYearSelectedChip] = useState(false);
    const [policyYearSelectedChip, setPolicyYearSelectedChip] = useState(false);


    const [issueAgeChips, setIssueAgeChips] = useState([]);
    const [renewalYearChips, setRenewalYearChips] = useState([]);
    const [excessYearChips, setExcessYearChips] = useState([]);
    const [policyYearChips, setPolicyYearChips] = useState([]);


    const [hoverOnIssueChip, setHoverOnIssueChip] = useState(false)
    const [hoverOnRenewalChip, setHoverOnRenewalChip] = useState(false)
    const [hoverOnExcessYearChip, setHoverOnExcessYearChip] = useState(false)
    const [hoverOnPolicyYearChip, setHoverOnPolicyYearChip] = useState(false)

    // FOR DATA STORAGE
    const [issueAgeTempValues, setIssueAgeTempValues] = useState({
        fromAge: "",
        toAgeOption: null,
        toAgeValue: "",
        carrier_product_criteria_id: null,
        carrier_product_criteria_id_custom: null
    });
    const [renewalYearTempValues, setRenewalYearTempValues] = useState({
        fromAge: "",
        toAgeOption: null,
        toAgeValue: "",
        carrier_product_criteria_id: null,
        carrier_product_criteria_id_custom: null
    });
    const [excessYearTempValues, setExcessYearTempValues] = useState({
        fromAge: "",
        toAgeOption: null,
        toAgeValue: "",
        carrier_product_criteria_id: null,
        carrier_product_criteria_id_custom: null
    });
    const [policyYearTempValues, setPolicyYearTempValues] = useState({
        fromAge: "",
        toAgeOption: null,
        toAgeValue: "",
        carrier_product_criteria_id: null,
        carrier_product_criteria_id_custom: null
    });

    // FOR POPOVER
    const [deletePopover, setDeletePopover] = useState({
        open: false,
        chip: null,
        anchorEl: null,
    });
    const [renewalYearDeletePopover, setRenewalYearDeletePopover] = useState({
        open: false,
        chip: null,
        anchorEl: null,
    });
    const [excessYearDeletePopover, setExcessYearDeletePopover] = useState({
        open: false,
        chip: null,
        anchorEl: null,
    });
    const [policyYearDeletePopover, setPolicyYearDeletePopover] = useState({
        open: false,
        chip: null,
        anchorEl: null,
    });


    // FOR DISABLE BUTTON
    const [activeChip, setActiveChip] = useState({
        editChip: null,
        deleteChip: null,
    });
    const [activeRenewalChip, setActiveRenewalChip] = useState({
        editChip: null,
        deleteChip: null,
    });
    const [activeExcessYearChip, setActiveExcessYearChip] = useState({
        editChip: null,
        deleteChip: null,
    });
    const [activePolicyYearChip, setActivePolicyYearChip] = useState({
        editChip: null,
        deleteChip: null,
    });


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded((prev) => ({ ...prev, [panel]: isExpanded }));
    };

    useEffect(() => {
        if (criteriaCellData && productIds) {
            const updatedCriteria = [];

            // Find the selected row based on the provided productIds
            const selectedRow = criteriaCellData.carrier_product_data.find(
                (productData) =>
                    productIds.productDataIds && productIds.productDataCustomIds
                        ? productData.carrier_product_data_id == productIds.productDataIds &&
                        productData.carrier_product_data_id_custom == productIds.productDataCustomIds
                        : productIds.productDataIds
                            ? productData.carrier_product_data_id == productIds.productDataIds
                            : productData.carrier_product_data_id_custom == productIds.productDataCustomIds
            );

            if (selectedRow) {
                setProductName(selectedRow?.carrier_product_name);

                // Create a deep copy of the criteria to avoid reference issues
                const copiedCriteria = JSON.parse(JSON.stringify(selectedRow.carrier_product_criteria || []));
                setCarrierProductCriteria(copiedCriteria);

                if (copiedCriteria.length > 0) {
                    // FOR ISSUE AGE
                    const issueAgeChipsData = copiedCriteria.filter((item) => item.criteria_name === "Issue Age").map((item) => {
                        const criteriaFields = criteriaGenericData.find((criteria) => criteria.criteria_name === "Issue Age")?.criteria_field_data;
                        return {
                            ...item, // Include the full object for Issue Age
                            name: generateChipLabel(item.carrier_product_criteria_data, criteriaFields),
                        };
                    });
                    setIssueAgeChips(issueAgeChipsData);

                    // FOR RENEWAL YEAR
                    const issueRenewalChipsData = copiedCriteria.filter((item) => item.criteria_name === "Renewal Year").map((item) => {
                        const criteriaFields = criteriaGenericData.find((criteria) => criteria.criteria_name === "Renewal Year")?.criteria_field_data;
                        return {
                            ...item, // Include the full object for Issue Age
                            name: generateChipLabel(item.carrier_product_criteria_data, criteriaFields),
                        };
                    });
                    setRenewalYearChips(issueRenewalChipsData);


                    // FOR EXCESS YEAR 
                    const excessChipsData = copiedCriteria.filter((item) => item.criteria_name === "Excess Year").map((item) => {
                        const criteriaFields = criteriaGenericData.find((criteria) => criteria.criteria_name === "Excess Year")?.criteria_field_data;
                        return {
                            ...item, // Include the full object for Issue Age
                            name: generateChipLabel(item.carrier_product_criteria_data, criteriaFields),
                        };
                    });
                    setExcessYearChips(excessChipsData);


                    // FOR POLICY YEAR 
                    const policyChipsData = copiedCriteria.filter((item) => item.criteria_name === "Policy Year").map((item) => {
                        const criteriaFields = criteriaGenericData.find((criteria) => criteria.criteria_name === "Policy Year")?.criteria_field_data;
                        return {
                            ...item, // Include the full object for Issue Age
                            name: generateChipLabel(item.carrier_product_criteria_data, criteriaFields),
                        };
                    });
                    setPolicyYearChips(policyChipsData);

                    // FOR STATE
                    const stateCriteria = copiedCriteria.find((item) => item.criteria_name === "State");
                    if (stateCriteria) {
                        const stateIds = stateCriteria?.carrier_product_criteria_data?.map((data) => Number(data.criteria_field_option_id));
                        const multiSelectField = criteriaGenericData?.find((criteria) => criteria.criteria_name === "State")?.criteria_field_data.find((field) => field.criteria_field_data_type_name === "Multi Select");

                        const allStates = multiSelectField?.criteria_field_option_data || [];
                        const nonSelectAllStatesLength = allStates?.length;

                        setSelectedStates(stateIds);

                        // Check if all states are selected including "Select All"
                        const isAllSelectedNow = stateIds.length === nonSelectAllStatesLength;
                        setIsAllSelected(isAllSelectedNow);
                    } else {
                        setSelectedStates([]);
                        setIsAllSelected(false);
                    }
                } else {
                    setCarrierProductCriteria([]);
                    setSelectedStates([]);
                }
            }
            else {
                setCarrierProductCriteria([]);
                setSelectedStates([]);
            }
        }
    }, [criteriaCellData, productIds]);

    // OLD CODE BFR TYPE AND SAVE CANCEL BUTTON SHOULD VISIBLE
    // useEffect(() => {
    //     // Fetch the "Enter Year" option ID for Issue Age
    //     const issueAgeEnterYearOptionId = criteriaGenericData?.find((criteria) => criteria.criteria_name == "Issue Age")
    //         ?.criteria_field_data?.find((field) => field.criteria_field_name == "To Age")
    //         ?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field")
    //         ?.criteria_field_option_id;

    //     // Fetch the "Enter Year" option ID for Renewal Year
    //     const renewalYearEnterYearOptionId = criteriaGenericData?.find((criteria) => criteria.criteria_name == "Renewal Year")
    //         ?.criteria_field_data?.find((field) => field.criteria_field_name == "End Year")
    //         ?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field")
    //         ?.criteria_field_option_id;

    //     const excessYearEnterYearOptionId = criteriaGenericData?.find((criteria) => criteria.criteria_name == "Excess Year")
    //         ?.criteria_field_data?.find((field) => field.criteria_field_name == "End Year")
    //         ?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field")
    //         ?.criteria_field_option_id;

    //     const policyYearEnterYearOptionId = criteriaGenericData?.find((criteria) => criteria.criteria_name == "Policy Year")
    //         ?.criteria_field_data?.find((field) => field.criteria_field_name == "End Year")
    //         ?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field")
    //         ?.criteria_field_option_id;


    //     // Validate Issue Age fields
    //     let isIssueAgeValid = false;
    //     if (issueAgeTempValues.fromAge && issueAgeTempValues.toAgeOption) {
    //         if (issueAgeTempValues.toAgeOption == issueAgeEnterYearOptionId) {
    //             isIssueAgeValid = issueAgeTempValues.toAgeValue?.trim() != "";
    //         } else {
    //             isIssueAgeValid = true;
    //         }
    //     }

    //     // Validate Renewal Year fields
    //     let isRenewalYearValid = false;
    //     if (renewalYearTempValues.fromAge && renewalYearTempValues.toAgeOption) {
    //         if (renewalYearTempValues.toAgeOption == renewalYearEnterYearOptionId) {
    //             isRenewalYearValid = renewalYearTempValues.toAgeValue?.trim() != "";
    //         } else {
    //             isRenewalYearValid = true;
    //         }
    //     }

    //     // Validate Excess Year fields
    //     let isExcessYearValid = false;
    //     if (excessYearTempValues.fromAge && excessYearTempValues.toAgeOption) {
    //         if (excessYearTempValues.toAgeOption == excessYearEnterYearOptionId) {
    //             isExcessYearValid = excessYearTempValues.toAgeValue?.trim() != "";
    //         } else {
    //             isExcessYearValid = true;
    //         }
    //     }

    //     // Validate Policy Year fields
    //     let isPolicyYearValid = false;
    //     if (policyYearTempValues.fromAge && policyYearTempValues.toAgeOption) {
    //         if (policyYearTempValues.toAgeOption == policyYearEnterYearOptionId) {
    //             isPolicyYearValid = policyYearTempValues.toAgeValue?.trim() != "";
    //         } else {
    //             isPolicyYearValid = true;
    //         }
    //     }

    //     // Set the button state
    //     setShowIssueAgeButtons(isIssueAgeValid);
    //     setShowRenewalYearButtons(isRenewalYearValid);
    //     setShowExcessYearButtons(isExcessYearValid)
    //     setShowPolicyYearButtons(isPolicyYearValid)
    // }, [issueAgeTempValues, renewalYearTempValues, criteriaGenericData, excessYearTempValues, policyYearTempValues]);


    useEffect(() => {
        // Function to get "Enter Year" option ID dynamically
        const getEnterYearOptionId = (criteriaName, fieldName) => {
            return criteriaGenericData?.find((criteria) => criteria.criteria_name == criteriaName)
                ?.criteria_field_data?.find((field) => field.criteria_field_name == fieldName)
                ?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field")
                ?.criteria_field_option_id;
        };

        const issueAgeEnterYearOptionId = getEnterYearOptionId("Issue Age", "To Age");
        const renewalYearEnterYearOptionId = getEnterYearOptionId("Renewal Year", "End Year");
        const excessYearEnterYearOptionId = getEnterYearOptionId("Excess Year", "End Year");
        const policyYearEnterYearOptionId = getEnterYearOptionId("Policy Year", "End Year");

        // Function to check if the buttons should be shown
        const isButtonVisible = (tempValues, enterYearOptionId) => {
            if (tempValues.toAgeOption) { // If any radio button is selected, show the buttons
                return true;
            }
            if (tempValues.fromAge) { // If From Age is filled, show the buttons
                return true;
            }
            if (tempValues.toAgeOption == enterYearOptionId) { // If "Enter Year" is selected, ensure value is filled
                return tempValues.toAgeValue?.trim() != "";
            }
            return false;
        };

        // Update button visibility states
        setShowIssueAgeButtons(isButtonVisible(issueAgeTempValues, issueAgeEnterYearOptionId));
        setShowRenewalYearButtons(isButtonVisible(renewalYearTempValues, renewalYearEnterYearOptionId));
        setShowExcessYearButtons(isButtonVisible(excessYearTempValues, excessYearEnterYearOptionId));
        setShowPolicyYearButtons(isButtonVisible(policyYearTempValues, policyYearEnterYearOptionId));

    }, [issueAgeTempValues, renewalYearTempValues, excessYearTempValues, policyYearTempValues, criteriaGenericData]);

    useEffect(() => {
        const criteria = criteriaGenericData.find((ele) => ele.criteria_name === "Renewal Year");
        if (criteria) {
            const fieldData = criteria.criteria_field_data?.find((field) => field.criteria_field_name === "End Year");
            if (fieldData && fieldData.criteria_field_option_data) {
                const selectedOption = fieldData.criteria_field_option_data.find((option) => option.criteria_field_option_id == renewalYearTempValues.toAgeOption);

                if (selectedOption && renewalYearTempValues.toAgeOption == selectedOption.criteria_field_option_id && textFieldRenewalYearRef.current) {
                    setTimeout(() => {
                        textFieldRenewalYearRef.current.focus();
                    }, 200);
                }
            }
        }
    }, [renewalYearTempValues?.toAgeOption]);

    useEffect(() => {
        const criteria = criteriaGenericData?.find((ele) => ele.criteria_name === "Issue Age");
        if (criteria) {
            const fieldData = criteria.criteria_field_data?.find((field) => field.criteria_field_name === "To Age");
            if (fieldData && fieldData.criteria_field_option_data) {
                const type = fieldData?.criteria_field_option_data?.find((option) => option.criteria_field_option_id == issueAgeTempValues.toAgeOption);

                if (issueAgeTempValues.toAgeOption == type?.criteria_field_option_id && textFieldIssueAgeRef.current) {
                    setTimeout(() => {
                        textFieldIssueAgeRef.current.focus();
                    }, 200);
                }
            }
        }
    }, [issueAgeTempValues?.toAgeOption]);

    useEffect(() => {
        const criteria = criteriaGenericData?.find((ele) => ele.criteria_name == "Excess Year");
        if (criteria) {
            const fieldData = criteria.criteria_field_data?.find((field) => field.criteria_field_name == "End Year");
            if (fieldData && fieldData.criteria_field_option_data) {
                const type = fieldData?.criteria_field_option_data?.find((option) => option.criteria_field_option_id == excessYearTempValues.toAgeOption);

                if (excessYearTempValues.toAgeOption == type?.criteria_field_option_id && textFieldExcessYearRef.current) {
                    setTimeout(() => {
                        textFieldExcessYearRef.current.focus();
                    }, 200);
                }
            }
        }
    }, [excessYearTempValues?.toAgeOption]);

    useEffect(() => {
        const criteria = criteriaGenericData?.find((ele) => ele.criteria_name == "Policy Year");
        if (criteria) {
            const fieldData = criteria.criteria_field_data?.find((field) => field.criteria_field_name == "End Year");
            if (fieldData && fieldData.criteria_field_option_data) {
                const type = fieldData?.criteria_field_option_data?.find((option) => option.criteria_field_option_id == policyYearTempValues.toAgeOption);

                if (policyYearTempValues.toAgeOption == type?.criteria_field_option_id && textFieldPolicyYearRef.current) {
                    setTimeout(() => {
                        textFieldPolicyYearRef.current.focus();
                    }, 200);
                }
            }
        }
    }, [policyYearTempValues?.toAgeOption]);

    useEffect(() => {
        // Validate criteria to ensure all required fields are properly filled
        const isCriteriaValid = carrierProductCriteria?.some(item => {
            if (item.criteria_name === "Issue Age") {
                // Validate Issue Age: Check if "From Age" and "To Age" are filled properly
                const fromAgeValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "From Age" && field.criteria_field_value?.trim());
                const toAgeValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "To Age" && (field.criteria_field_value?.trim() || field.criteria_field_option_id));
                return fromAgeValid && toAgeValid;
            }

            if (item.criteria_name === "Renewal Year") {
                // Validate Renewal Year: Check if "Start Year" and "End Year" are filled properly
                const startYearValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "Start Year" && field.criteria_field_value?.trim());
                const endYearValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "End Year" && (field.criteria_field_value?.trim() || field.criteria_field_option_id));
                return startYearValid && endYearValid;
            }

            if (item.criteria_name === "Excess Year") {
                // Validate Renewal Year: Check if "Start Year" and "End Year" are filled properly
                const startYearValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "Start Year" && field.criteria_field_value?.trim());
                const endYearValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "End Year" && (field.criteria_field_value?.trim() || field.criteria_field_option_id));
                return startYearValid && endYearValid;
            }

            if (item.criteria_name === "Policy Year") {
                // Validate Renewal Year: Check if "Start Year" and "End Year" are filled properly
                const startYearValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "Start Year" && field.criteria_field_value?.trim());
                const endYearValid = item.carrier_product_criteria_data?.some(field => field.criteria_field_name === "End Year" && (field.criteria_field_value?.trim() || field.criteria_field_option_id));
                return startYearValid && endYearValid;
            }

            // For other criteria, ensure at least one value is present
            return item.carrier_product_criteria_data?.some(field => field.criteria_field_value?.trim() || field.criteria_field_option_id);
        });

        // Check if Save/Cancel buttons for Issue Age and Renewal Year are visible
        const areIssueAgeButtonsVisible = !!issueAgeTempValues.fromAge || !!issueAgeTempValues.toAgeOption || !!issueAgeTempValues.toAgeValue;
        const areRenewalYearButtonsVisible = !!renewalYearTempValues.fromAge || !!renewalYearTempValues.toAgeOption || !!renewalYearTempValues.toAgeValue;
        const areExcessYearButtonsVisible = !!excessYearTempValues.fromAge || !!excessYearTempValues.toAgeOption || !!excessYearTempValues.toAgeValue;
        const arePolicyYearButtonsVisible = !!policyYearTempValues.fromAge || !!policyYearTempValues.toAgeOption || !!policyYearTempValues.toAgeValue;

        // Final state for enabling/disabling the Main Save button
        setIsAddButtonEnabled(isCriteriaValid && !areIssueAgeButtonsVisible && !areRenewalYearButtonsVisible && !areExcessYearButtonsVisible && !arePolicyYearButtonsVisible);
    }, [
        carrierProductCriteria,
        issueAgeTempValues,
        renewalYearTempValues,
        excessYearTempValues,
        policyYearTempValues,

        showIssueAgeButtons,
        showRenewalYearButtons,
        showExcessYearButtons,
        showPolicyYearButtons,
    ]);

    const handleTypeChange = (e, section, field) => {
        const selectedOptionId = e.target.value;
        const selectedOption = field.criteria_field_option_data.find(
            (option) => option.criteria_field_option_id == selectedOptionId
        );

        setCarrierProductCriteria((prevState) => {
            // Check if the section already exists
            const existingCriteriaIndex = prevState?.findIndex((criteria) => criteria.criteria_id == section.criteria_id);

            if (existingCriteriaIndex !== -1) {
                // If criteria already exists, update the option inside it
                const updatedCriteria = [...prevState];
                updatedCriteria[existingCriteriaIndex] = {
                    ...updatedCriteria[existingCriteriaIndex],
                    carrier_product_criteria_data: [
                        {
                            criteria_field_id: field.criteria_field_id,
                            criteria_field_name: field.criteria_field_name,
                            criteria_field_option_id: selectedOptionId,
                            criteria_field_value: selectedOption?.criteria_field_option_value || "",
                        },
                    ],
                };
                return updatedCriteria;
            } else {
                // If criteria does not exist, add a new one
                return [
                    ...prevState,
                    {
                        criteria_id: section.criteria_id,
                        criteria_sequence: section.criteria_sequence,
                        criteria_name: section.criteria_name,
                        carrier_product_criteria_data: [
                            {
                                criteria_field_id: field.criteria_field_id,
                                criteria_field_name: field.criteria_field_name,
                                criteria_field_option_id: selectedOptionId,
                                criteria_field_value: selectedOption?.criteria_field_option_value || "",
                            },
                        ],
                    },
                ];
            }
        });
    };


    // SUB TYPE:-----------------
    const updateSubtype = (criteria, fieldId, value, fieldName) => {
        const criteriaId = criteria.criteria_id;
        const criteriaName = criteria.criteria_name;

        setCarrierProductCriteria((prev) => {
            const updatedCriteria = [...prev];
            const criteriaIndex = updatedCriteria.findIndex((criteria) => criteria.criteria_id == criteriaId);

            if (criteriaIndex == -1) {
                updatedCriteria.push({
                    criteria_id: criteriaId,
                    criteria_sequence: criteria.criteria_sequence,
                    criteria_name: criteriaName,
                    carrier_product_criteria_data: [
                        {
                            criteria_field_id: fieldId,
                            criteria_field_name: fieldName,
                            criteria_field_option_id: null,
                            criteria_field_value: value,
                        },
                    ],
                });
            } else {
                updatedCriteria[criteriaIndex].criteria_name = criteriaName;
                const fieldIndex = updatedCriteria[criteriaIndex].carrier_product_criteria_data.findIndex(
                    (field) => field.criteria_field_id == fieldId
                );

                if (fieldIndex == -1) {
                    updatedCriteria[criteriaIndex].carrier_product_criteria_data.push({
                        criteria_field_id: fieldId,
                        criteria_field_name: fieldName,
                        criteria_field_option_id: null,
                        criteria_field_value: value,
                    });
                } else {
                    updatedCriteria[criteriaIndex].carrier_product_criteria_data[fieldIndex].criteria_field_value = value;
                }
            }
            return updatedCriteria;
        });
    };
    // SUB TYPE:-----------------


    // ISSUE AGE:-----------------
    const handleIssueAgeChange = (key, value) => {
        setIssueAgeTempValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleEditIssueAge = (chip) => {

        setActiveChip({
            editChip: chip,
            deleteChip: null,
        });
        const criteria = carrierProductCriteria.find(
            (item) => {
                const carrProdId = item.carrier_product_criteria_id;
                const carrCustomProdId = item.carrier_product_criteria_id_custom;
                const chiCarrProdIdpId = chip.carrier_product_criteria_id;
                const chiCarrCustomProdIdpId = chip.carrier_product_criteria_id_custom;
                if (carrProdId && chiCarrProdIdpId) {
                    return carrProdId == chiCarrProdIdpId
                } else {
                    return carrCustomProdId == chiCarrCustomProdIdpId
                }
            }
        )

        if (!criteria) {
            console.error("No matching criteria found for the selected chip!");
            return;
        }
        const criteriaGenericFields = criteriaGenericData.find((item) => item.criteria_name == "Issue Age")?.criteria_field_data;

        // Dynamically fetch the "From Age" and "To Age" field names
        const fromAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Text Field")?.criteria_field_name;
        const toAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Single Select")?.criteria_field_name;

        // Extract the "From Age" and "To Age" data dynamically from the criteria
        const fromAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == fromAgeFieldName);
        const toAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == toAgeFieldName);

        // Update the temporary state with the selected chip's data
        setIssueAgeSelectedChip(chip);
        setIssueAgeTempValues({
            fromAge: fromAgeData?.criteria_field_value || "",
            toAgeOption: toAgeData?.criteria_field_option_id || null,
            toAgeValue: toAgeData?.criteria_field_value || "",
            carrier_product_criteria_id: chip.carrier_product_criteria_id || null,
            carrier_product_criteria_id_custom: chip.carrier_product_criteria_id_custom || null,
        });
    };

    const saveIssueAgeData = () => {

        const criteria = criteriaGenericData.find((item) => item.criteria_name == "Issue Age");

        if (!criteria) {
            console.error("Criteria for Issue Age not found!");
            return;
        }

        setCarrierProductCriteria((prev) => {

            const updatedCriteria = [...prev];
            const isEditingExistingChip = issueAgeTempValues.carrier_product_criteria_id != null || issueAgeTempValues.carrier_product_criteria_id_custom != null;

            // Build field objects
            const fieldObjects = criteria.criteria_field_data.map((field) => {
                if (field.criteria_field_data_type_name == "Text Field") {
                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: null,
                        criteria_field_option_value: null,
                        criteria_field_value: issueAgeTempValues.fromAge,
                    };
                }
                if (field.criteria_field_data_type_name == "Single Select") {

                    const customOption = field?.criteria_field_option_data?.find((option) => option?.criteria_field_option_value == "Custom Text Field");
                    const isCustomField = customOption && issueAgeTempValues.toAgeOption == customOption.criteria_field_option_id;
                    const selectedOption = field?.criteria_field_option_data?.find((option) => option?.criteria_field_option_id == issueAgeTempValues.toAgeOption);

                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: issueAgeTempValues?.toAgeOption,
                        criteria_field_option_value: selectedOption ? selectedOption?.criteria_field_option_value : null,
                        criteria_field_value: isCustomField ? issueAgeTempValues.toAgeValue : null,
                    };
                }
                return null;
            });

            const validFieldObjects = fieldObjects.filter((obj) => obj != null);

            const updatedEntry = {
                criteria_id: criteria.criteria_id,
                criteria_sequence: criteria?.criteria_sequence,
                carrier_product_criteria_id: isEditingExistingChip ? issueAgeTempValues.carrier_product_criteria_id : null,
                carrier_product_criteria_id_custom: isEditingExistingChip ? issueAgeTempValues.carrier_product_criteria_id_custom : Date.now(), // Create a unique custom ID if adding a new chip
                carrier_product_criteria_data: validFieldObjects,
                criteria_name: "Issue Age",
                name: generateChipLabel(validFieldObjects, criteria.criteria_field_data),
            };

            if (isEditingExistingChip) {

                const existingIndex = updatedCriteria.findIndex((item) => {
                    const carrProdId = item?.carrier_product_criteria_id;
                    const carrCustomProdId = item?.carrier_product_criteria_id_custom;
                    const issueCarrProdId = issueAgeTempValues?.carrier_product_criteria_id;
                    const issueCarrCustomProdId = issueAgeTempValues?.carrier_product_criteria_id_custom;

                    if (carrProdId && issueCarrProdId) {
                        return carrProdId == issueCarrProdId;
                    } else {
                        return carrCustomProdId == issueCarrCustomProdId;
                    }
                });

                if (existingIndex != -1) {
                    updatedCriteria[existingIndex] = { ...updatedCriteria[existingIndex], ...updatedEntry, };
                }
            }
            else {
                // Add new chip
                updatedCriteria.push(updatedEntry);
            }

            // Update issueAgeChips state
            const issueAgeChipsData = updatedCriteria.filter((item) => item.criteria_name == "Issue Age").map((item) => ({
                ...item,
                name: generateChipLabel(item.carrier_product_criteria_data, criteria.criteria_field_data)
            })
            );
            setIssueAgeChips(issueAgeChipsData);
            return updatedCriteria;
        });


        // Reset temporary state
        setIssueAgeTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
            carrier_product_criteria_id: null,
            carrier_product_criteria_id_custom: null,
        });
        setActiveChip({ editChip: null, deleteChip: null });

    };

    const deleteIssueAgeChip = (chip) => {
        setCarrierProductCriteria((prev) => {
            const updatedCriteria = prev.filter((item) => {
                // Exclude the chip to delete based on ID or custom ID
                if (chip.carrier_product_criteria_id) {
                    return item.carrier_product_criteria_id != chip.carrier_product_criteria_id;
                } else if (chip.carrier_product_criteria_id_custom) {
                    return item.carrier_product_criteria_id_custom != chip.carrier_product_criteria_id_custom;
                }
                return true; // If no match, keep the item
            });

            // Update issueAgeChips state
            const issueAgeChipsData = updatedCriteria
                .filter((item) => item.criteria_name == "Issue Age")
                .map((item) => ({
                    ...item,
                    name: generateChipLabel(item.carrier_product_criteria_data, criteriaGenericData.find((criteria) => criteria.criteria_name == "Issue Age")?.criteria_field_data),
                }));

            setIssueAgeChips(issueAgeChipsData);

            return updatedCriteria;
        });
        setActiveChip({
            editChip: null, // Clear edit chip when deleting
            deleteChip: null, // Set the chip being deleted
        });
    };

    const handleCancelIssueAgeChip = (event) => {
        event.stopPropagation();
        setActiveChip({
            editChip: null,
            deleteChip: null
        });
        setIssueAgeTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
        });
        if (issueAgeSelectedChip) {
            setCarrierProductCriteria((prev) => {
                return prev.map((item) => {
                    if (
                        (item.carrier_product_criteria_id && item.carrier_product_criteria_id == issueAgeSelectedChip.carrier_product_criteria_id) ||
                        (item.carrier_product_criteria_id_custom && item.carrier_product_criteria_id_custom == issueAgeSelectedChip.carrier_product_criteria_id_custom)
                    ) {
                        // Revert the chip's fields to their original values
                        return {
                            ...item,
                            carrier_product_criteria_data: item?.carrier_product_criteria_data?.map((field) => {
                                return {
                                    ...field,
                                    criteria_field_value: field.criteria_field_value, // Retain original values
                                    criteria_field_option_value: field.criteria_field_option_value,
                                };
                            }),
                        };
                    }
                    return item;
                });
            });
        }
        setIssueAgeSelectedChip(false)
    }
    // ISSUE AGE:-----------------


    // RENEWAL AGE:-------------------
    const handleRenewalYearChange = (key, value, section) => {
        setRenewalYearTempValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleEditRenewalAge = (chip) => {

        setActiveRenewalChip({
            editChip: chip,
            deleteChip: null,
        });

        const criteria = carrierProductCriteria.find(
            (item) => {
                const carrProdId = item.carrier_product_criteria_id;
                const carrCustomProdId = item.carrier_product_criteria_id_custom;
                const chiCarrProdIdpId = chip.carrier_product_criteria_id;
                const chiCarrCustomProdIdpId = chip.carrier_product_criteria_id_custom;
                if (carrProdId && chiCarrProdIdpId) {
                    return carrProdId == chiCarrProdIdpId
                } else {
                    return carrCustomProdId == chiCarrCustomProdIdpId
                }
            }
        )


        if (!criteria) {
            console.error("No matching criteria found for the selected chip!");
            return;
        }

        const criteriaGenericFields = criteriaGenericData.find((item) => item.criteria_name == "Renewal Year")?.criteria_field_data;

        // Dynamically fetch the "From Age" and "To Age" field names
        const fromAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Text Field")?.criteria_field_name;
        const toAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Single Select")?.criteria_field_name;

        // Extract the "From Age" and "To Age" data dynamically from the criteria
        const fromAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == fromAgeFieldName);
        const toAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == toAgeFieldName);

        // Update the temporary state with the selected chip's data
        setRenewalYearSelectedChip(chip)
        setRenewalYearTempValues({
            fromAge: fromAgeData?.criteria_field_value || "",
            toAgeOption: toAgeData?.criteria_field_option_id || null,
            toAgeValue: toAgeData?.criteria_field_value || "",
            carrier_product_criteria_id: chip.carrier_product_criteria_id || null,
            carrier_product_criteria_id_custom: chip.carrier_product_criteria_id_custom || null,
        });

    };

    const saveRenewalYear = () => {
        const criteria = criteriaGenericData.find((item) => item.criteria_name == "Renewal Year");

        if (!criteria) {
            console.error("Criteria for Issue Age not found!");
            return;
        }

        setCarrierProductCriteria((prev) => {

            const updatedCriteria = [...prev];
            const isEditingExistingChip = renewalYearTempValues.carrier_product_criteria_id != null || renewalYearTempValues.carrier_product_criteria_id_custom != null;

            // Build field objects
            const fieldObjects = criteria.criteria_field_data.map((field) => {
                if (field.criteria_field_data_type_name == "Text Field") {
                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: null,
                        criteria_field_option_value: null,
                        criteria_field_value: renewalYearTempValues.fromAge,
                    };
                }
                if (field.criteria_field_data_type_name == "Single Select") {

                    const customOption = field?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field");
                    const isCustomField = customOption && renewalYearTempValues?.toAgeOption == customOption?.criteria_field_option_id;
                    const selectedOption = field?.criteria_field_option_data?.find(
                        (option) => option.criteria_field_option_id == renewalYearTempValues.toAgeOption
                    );

                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: renewalYearTempValues.toAgeOption,
                        criteria_field_option_value: selectedOption ? selectedOption.criteria_field_option_value : null, // Add option value
                        criteria_field_value: isCustomField ? renewalYearTempValues.toAgeValue : null,
                    };
                }
                return null;
            });

            const validFieldObjects = fieldObjects.filter((obj) => obj != null);

            const updatedEntry = {
                criteria_id: criteria.criteria_id,
                criteria_sequence: criteria.criteria_sequence,
                carrier_product_criteria_id: isEditingExistingChip ? renewalYearTempValues.carrier_product_criteria_id : null,
                carrier_product_criteria_id_custom: isEditingExistingChip ? renewalYearTempValues.carrier_product_criteria_id_custom : Date.now(), // Create a unique custom ID if adding a new chip
                carrier_product_criteria_data: validFieldObjects,
                criteria_name: "Renewal Year",
                name: generateChipLabel(validFieldObjects, criteria.criteria_field_data),
            };

            if (isEditingExistingChip) {

                const existingIndex = updatedCriteria.findIndex((item) => {
                    const carrProdId = item?.carrier_product_criteria_id;
                    const carrCustomProdId = item?.carrier_product_criteria_id_custom;
                    const issueCarrProdId = renewalYearTempValues?.carrier_product_criteria_id;
                    const issueCarrCustomProdId = renewalYearTempValues?.carrier_product_criteria_id_custom;

                    if (carrProdId && issueCarrProdId) {
                        return carrProdId == issueCarrProdId;
                    } else {
                        return carrCustomProdId == issueCarrCustomProdId;
                    }
                });

                if (existingIndex != -1) {
                    updatedCriteria[existingIndex] = { ...updatedCriteria[existingIndex], ...updatedEntry, };
                }
            } else {
                // Add new chip
                updatedCriteria.push(updatedEntry);
            }

            // Update issueAgeChips state
            const renewalChipsData = updatedCriteria.filter((item) => item.criteria_name == "Renewal Year").map((item) => ({
                ...item,
                name: generateChipLabel(item.carrier_product_criteria_data, criteria.criteria_field_data)
            })
            );
            setRenewalYearChips(renewalChipsData);
            return updatedCriteria;
        });

        // Reset temporary state
        setRenewalYearTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
            carrier_product_criteria_id: null,
            carrier_product_criteria_id_custom: null,
        });
        setActiveRenewalChip({
            editChip: null,
            deleteChip: null,
        });
    };

    const deleteRenewalYearChip = (chip) => {
        setCarrierProductCriteria((prev) => {
            const updatedCriteria = prev.filter((item) => {
                // Exclude the chip to delete based on ID or custom ID
                if (chip.carrier_product_criteria_id) {
                    return item.carrier_product_criteria_id != chip.carrier_product_criteria_id;
                } else if (chip.carrier_product_criteria_id_custom) {
                    return item.carrier_product_criteria_id_custom != chip.carrier_product_criteria_id_custom;
                }
                return true; // If no match, keep the item
            });

            // Update issueAgeChips state
            const renewalYearChipsData = updatedCriteria
                .filter((item) => item.criteria_name == "Renewal Year")
                .map((item) => ({
                    ...item,
                    name: generateChipLabel(item.carrier_product_criteria_data, criteriaGenericData.find((criteria) => criteria.criteria_name == "Renewal Year")?.criteria_field_data),
                }));

            setRenewalYearChips(renewalYearChipsData);

            return updatedCriteria;
        });
        setActiveRenewalChip({
            editChip: null,
            deleteChip: null,
        });
    };

    const handleCancelRenewalYearChip = (event) => {
        event.stopPropagation();
        setActiveRenewalChip({
            editChip: null,
            deleteChip: null,
        });
        setRenewalYearTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
        });

        if (renewalYearSelectedChip) {
            setCarrierProductCriteria((prev) => {
                return prev.map((item) => {
                    if (
                        (item.carrier_product_criteria_id && item.carrier_product_criteria_id == renewalYearSelectedChip.carrier_product_criteria_id) ||
                        (item.carrier_product_criteria_id_custom && item.carrier_product_criteria_id_custom == renewalYearSelectedChip.carrier_product_criteria_id_custom)
                    ) {
                        // Revert the chip's fields to their original values
                        return {
                            ...item,
                            carrier_product_criteria_data: item?.carrier_product_criteria_data?.map((field) => {
                                return {
                                    ...field,
                                    criteria_field_value: field.criteria_field_value, // Retain original values
                                    criteria_field_option_value: field.criteria_field_option_value,
                                };
                            }),
                        };
                    }
                    return item;
                });
            });
        }
        setRenewalYearSelectedChip(false)
    }
    // RENEWAL AGE:-------------------


    // EXCESS YEAR :-------------------
    const handleExcessYearChange = (key, value) => {
        setExcessYearTempValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleEditExcessYear = (chip) => {

        setActiveExcessYearChip({
            editChip: chip,
            deleteChip: null,
        });

        const criteria = carrierProductCriteria.find(
            (item) => {
                const carrProdId = item.carrier_product_criteria_id;
                const carrCustomProdId = item.carrier_product_criteria_id_custom;
                const chiCarrProdIdpId = chip.carrier_product_criteria_id;
                const chiCarrCustomProdIdpId = chip.carrier_product_criteria_id_custom;
                if (carrProdId && chiCarrProdIdpId) {
                    return carrProdId == chiCarrProdIdpId
                } else {
                    return carrCustomProdId == chiCarrCustomProdIdpId
                }
            }
        )


        if (!criteria) {
            console.error("No matching criteria found for the selected chip!");
            return;
        }

        const criteriaGenericFields = criteriaGenericData.find((item) => item.criteria_name == "Excess Year")?.criteria_field_data;

        // Dynamically fetch the "From Age" and "To Age" field names
        const fromAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Text Field")?.criteria_field_name;
        const toAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Single Select")?.criteria_field_name;

        // Extract the "From Age" and "To Age" data dynamically from the criteria
        const fromAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == fromAgeFieldName);
        const toAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == toAgeFieldName);

        // Update the temporary state with the selected chip's data
        setExcessYearSelectedChip(chip)
        setExcessYearTempValues({
            fromAge: fromAgeData?.criteria_field_value || "",
            toAgeOption: toAgeData?.criteria_field_option_id || null,
            toAgeValue: toAgeData?.criteria_field_value || "",
            carrier_product_criteria_id: chip.carrier_product_criteria_id || null,
            carrier_product_criteria_id_custom: chip.carrier_product_criteria_id_custom || null,
        });

    };

    const saveExcessYear = () => {
        const criteria = criteriaGenericData.find((item) => item.criteria_name == "Excess Year");

        if (!criteria) {
            console.error("Criteria for Issue Age not found!");
            return;
        }

        setCarrierProductCriteria((prev) => {

            const updatedCriteria = [...prev];
            const isEditingExistingChip = excessYearTempValues.carrier_product_criteria_id != null || excessYearTempValues.carrier_product_criteria_id_custom != null;

            // Build field objects
            const fieldObjects = criteria.criteria_field_data.map((field) => {
                if (field.criteria_field_data_type_name == "Text Field") {
                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: null,
                        criteria_field_option_value: null,
                        criteria_field_value: excessYearTempValues.fromAge,
                    };
                }
                if (field.criteria_field_data_type_name == "Single Select") {

                    const customOption = field?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field");
                    const isCustomField = customOption && excessYearTempValues?.toAgeOption == customOption?.criteria_field_option_id;
                    const selectedOption = field?.criteria_field_option_data?.find(
                        (option) => option.criteria_field_option_id == excessYearTempValues.toAgeOption
                    );

                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: excessYearTempValues.toAgeOption,
                        criteria_field_option_value: selectedOption ? selectedOption.criteria_field_option_value : null, // Add option value
                        criteria_field_value: isCustomField ? excessYearTempValues.toAgeValue : null,
                    };
                }
                return null;
            });

            const validFieldObjects = fieldObjects.filter((obj) => obj != null);

            const updatedEntry = {
                criteria_id: criteria.criteria_id,
                criteria_sequence: criteria.criteria_sequence,
                carrier_product_criteria_id: isEditingExistingChip ? excessYearTempValues.carrier_product_criteria_id : null,
                carrier_product_criteria_id_custom: isEditingExistingChip ? excessYearTempValues.carrier_product_criteria_id_custom : Date.now(), // Create a unique custom ID if adding a new chip
                carrier_product_criteria_data: validFieldObjects,
                criteria_name: "Excess Year",
                name: generateChipLabel(validFieldObjects, criteria.criteria_field_data),
            };

            if (isEditingExistingChip) {

                const existingIndex = updatedCriteria.findIndex((item) => {
                    const carrProdId = item?.carrier_product_criteria_id;
                    const carrCustomProdId = item?.carrier_product_criteria_id_custom;
                    const issueCarrProdId = excessYearTempValues?.carrier_product_criteria_id;
                    const issueCarrCustomProdId = excessYearTempValues?.carrier_product_criteria_id_custom;

                    if (carrProdId && issueCarrProdId) {
                        return carrProdId == issueCarrProdId;
                    } else {
                        return carrCustomProdId == issueCarrCustomProdId;
                    }
                });

                if (existingIndex != -1) {
                    updatedCriteria[existingIndex] = { ...updatedCriteria[existingIndex], ...updatedEntry, };
                }
            } else {
                // Add new chip
                updatedCriteria.push(updatedEntry);
            }

            // Update issueAgeChips state
            const renewalChipsData = updatedCriteria.filter((item) => item.criteria_name == "Excess Year").map((item) => ({
                ...item,
                name: generateChipLabel(item.carrier_product_criteria_data, criteria.criteria_field_data)
            })
            );
            setExcessYearChips(renewalChipsData);
            return updatedCriteria;
        });

        // Reset temporary state
        setExcessYearTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
            carrier_product_criteria_id: null,
            carrier_product_criteria_id_custom: null,
        });
        setActiveExcessYearChip({
            editChip: null,
            deleteChip: null,
        });
    };

    const deleteExcessYearChip = (chip) => {
        setCarrierProductCriteria((prev) => {
            const updatedCriteria = prev.filter((item) => {
                // Exclude the chip to delete based on ID or custom ID
                if (chip.carrier_product_criteria_id) {
                    return item.carrier_product_criteria_id != chip.carrier_product_criteria_id;
                } else if (chip.carrier_product_criteria_id_custom) {
                    return item.carrier_product_criteria_id_custom != chip.carrier_product_criteria_id_custom;
                }
                return true; // If no match, keep the item
            });

            // Update issueAgeChips state
            const excessYearChipsData = updatedCriteria
                .filter((item) => item.criteria_name == "Excess Year")
                .map((item) => ({
                    ...item,
                    name: generateChipLabel(item.carrier_product_criteria_data, criteriaGenericData.find((criteria) => criteria.criteria_name == "Excess Year")?.criteria_field_data),
                }));

            setExcessYearChips(excessYearChipsData);

            return updatedCriteria;
        });
        setActiveExcessYearChip({
            editChip: null,
            deleteChip: null,
        });
    };

    const handleCancelExcessYearChip = (event) => {
        event.stopPropagation();
        setActiveExcessYearChip({
            editChip: null,
            deleteChip: null,
        });
        setExcessYearTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
        });

        if (excessYearSelectedChip) {
            setCarrierProductCriteria((prev) => {
                return prev.map((item) => {
                    if (
                        (item.carrier_product_criteria_id && item.carrier_product_criteria_id == excessYearSelectedChip.carrier_product_criteria_id) ||
                        (item.carrier_product_criteria_id_custom && item.carrier_product_criteria_id_custom == excessYearSelectedChip.carrier_product_criteria_id_custom)
                    ) {
                        // Revert the chip's fields to their original values
                        return {
                            ...item,
                            carrier_product_criteria_data: item?.carrier_product_criteria_data?.map((field) => {
                                return {
                                    ...field,
                                    criteria_field_value: field.criteria_field_value, // Retain original values
                                    criteria_field_option_value: field.criteria_field_option_value,
                                };
                            }),
                        };
                    }
                    return item;
                });
            });
        }
        setExcessYearSelectedChip(false)
    }
    // EXCESS YEAR :-------------------


    // POLICY YEAR :-------------------
    const handlePolicyYearChange = (key, value) => {
        setPolicyYearTempValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleEditPolicyYear = (chip) => {

        setActivePolicyYearChip({
            editChip: chip,
            deleteChip: null,
        });

        const criteria = carrierProductCriteria.find(
            (item) => {
                const carrProdId = item.carrier_product_criteria_id;
                const carrCustomProdId = item.carrier_product_criteria_id_custom;
                const chiCarrProdIdpId = chip.carrier_product_criteria_id;
                const chiCarrCustomProdIdpId = chip.carrier_product_criteria_id_custom;
                if (carrProdId && chiCarrProdIdpId) {
                    return carrProdId == chiCarrProdIdpId
                } else {
                    return carrCustomProdId == chiCarrCustomProdIdpId
                }
            }
        )


        if (!criteria) {
            console.error("No matching criteria found for the selected chip!");
            return;
        }

        const criteriaGenericFields = criteriaGenericData.find((item) => item.criteria_name == "Policy Year")?.criteria_field_data;

        // Dynamically fetch the "From Age" and "To Age" field names
        const fromAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Text Field")?.criteria_field_name;
        const toAgeFieldName = criteriaGenericFields?.find((field) => field.criteria_field_data_type_name == "Single Select")?.criteria_field_name;

        // Extract the "From Age" and "To Age" data dynamically from the criteria
        const fromAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == fromAgeFieldName);
        const toAgeData = criteria.carrier_product_criteria_data.find((data) => data.criteria_field_name == toAgeFieldName);

        // Update the temporary state with the selected chip's data
        setPolicyYearSelectedChip(chip)
        setPolicyYearTempValues({
            fromAge: fromAgeData?.criteria_field_value || "",
            toAgeOption: toAgeData?.criteria_field_option_id || null,
            toAgeValue: toAgeData?.criteria_field_value || "",
            carrier_product_criteria_id: chip.carrier_product_criteria_id || null,
            carrier_product_criteria_id_custom: chip.carrier_product_criteria_id_custom || null,
        });

    };

    const savePolicyYear = () => {
        const criteria = criteriaGenericData.find((item) => item.criteria_name == "Policy Year");

        if (!criteria) {
            console.error("Criteria for Issue Age not found!");
            return;
        }

        setCarrierProductCriteria((prev) => {

            const updatedCriteria = [...prev];
            const isEditingExistingChip = policyYearTempValues.carrier_product_criteria_id != null || policyYearTempValues.carrier_product_criteria_id_custom != null;

            // Build field objects
            const fieldObjects = criteria.criteria_field_data.map((field) => {
                if (field.criteria_field_data_type_name == "Text Field") {
                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: null,
                        criteria_field_option_value: null,
                        criteria_field_value: policyYearTempValues.fromAge,
                    };
                }
                if (field.criteria_field_data_type_name == "Single Select") {

                    const customOption = field?.criteria_field_option_data?.find((option) => option.criteria_field_option_value == "Custom Text Field");
                    const isCustomField = customOption && policyYearTempValues?.toAgeOption == customOption?.criteria_field_option_id;
                    const selectedOption = field?.criteria_field_option_data?.find(
                        (option) => option.criteria_field_option_id == policyYearTempValues.toAgeOption
                    );

                    return {
                        criteria_field_id: field.criteria_field_id,
                        criteria_field_name: field.criteria_field_name,
                        criteria_field_option_id: policyYearTempValues.toAgeOption,
                        criteria_field_option_value: selectedOption ? selectedOption.criteria_field_option_value : null, // Add option value
                        criteria_field_value: isCustomField ? policyYearTempValues.toAgeValue : null,
                    };
                }
                return null;
            });

            const validFieldObjects = fieldObjects.filter((obj) => obj != null);

            const updatedEntry = {
                criteria_id: criteria.criteria_id,
                criteria_sequence: criteria.criteria_sequence,
                carrier_product_criteria_id: isEditingExistingChip ? policyYearTempValues.carrier_product_criteria_id : null,
                carrier_product_criteria_id_custom: isEditingExistingChip ? policyYearTempValues.carrier_product_criteria_id_custom : Date.now(), // Create a unique custom ID if adding a new chip
                carrier_product_criteria_data: validFieldObjects,
                criteria_name: "Policy Year",
                name: generateChipLabel(validFieldObjects, criteria.criteria_field_data),
            };

            if (isEditingExistingChip) {

                const existingIndex = updatedCriteria.findIndex((item) => {
                    const carrProdId = item?.carrier_product_criteria_id;
                    const carrCustomProdId = item?.carrier_product_criteria_id_custom;
                    const issueCarrProdId = policyYearTempValues?.carrier_product_criteria_id;
                    const issueCarrCustomProdId = policyYearTempValues?.carrier_product_criteria_id_custom;

                    if (carrProdId && issueCarrProdId) {
                        return carrProdId == issueCarrProdId;
                    } else {
                        return carrCustomProdId == issueCarrCustomProdId;
                    }
                });

                if (existingIndex != -1) {
                    updatedCriteria[existingIndex] = { ...updatedCriteria[existingIndex], ...updatedEntry, };
                }
            } else {
                // Add new chip
                updatedCriteria.push(updatedEntry);
            }

            // Update issueAgeChips state
            const policyChipsData = updatedCriteria.filter((item) => item.criteria_name == "Policy Year").map((item) => ({
                ...item,
                name: generateChipLabel(item.carrier_product_criteria_data, criteria.criteria_field_data)
            })
            );
            setPolicyYearChips(policyChipsData);
            return updatedCriteria;
        });

        // Reset temporary state
        setPolicyYearTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
            carrier_product_criteria_id: null,
            carrier_product_criteria_id_custom: null,
        });
        setActivePolicyYearChip({
            editChip: null,
            deleteChip: null,
        });
    };

    const deletePolicyYearChip = (chip) => {
        setCarrierProductCriteria((prev) => {
            const updatedCriteria = prev.filter((item) => {
                // Exclude the chip to delete based on ID or custom ID
                if (chip.carrier_product_criteria_id) {
                    return item.carrier_product_criteria_id != chip.carrier_product_criteria_id;
                } else if (chip.carrier_product_criteria_id_custom) {
                    return item.carrier_product_criteria_id_custom != chip.carrier_product_criteria_id_custom;
                }
                return true; // If no match, keep the item
            });

            // Update issueAgeChips state
            const policyYearChipsData = updatedCriteria
                .filter((item) => item.criteria_name == "Policy Year")
                .map((item) => ({
                    ...item,
                    name: generateChipLabel(item.carrier_product_criteria_data, criteriaGenericData.find((criteria) => criteria.criteria_name == "Policy Year")?.criteria_field_data),
                }));

            setPolicyYearChips(policyYearChipsData);

            return updatedCriteria;
        });
        setActivePolicyYearChip({
            editChip: null,
            deleteChip: null,
        });
    };

    const handleCancelPolicyYearChip = (event) => {
        event.stopPropagation();
        setActivePolicyYearChip({
            editChip: null,
            deleteChip: null,
        });
        setPolicyYearTempValues({
            fromAge: "",
            toAgeOption: null,
            toAgeValue: "",
        });

        if (policyYearSelectedChip) {
            setCarrierProductCriteria((prev) => {
                return prev.map((item) => {
                    if (
                        (item.carrier_product_criteria_id && item.carrier_product_criteria_id == policyYearSelectedChip.carrier_product_criteria_id) ||
                        (item.carrier_product_criteria_id_custom && item.carrier_product_criteria_id_custom == policyYearSelectedChip.carrier_product_criteria_id_custom)
                    ) {
                        // Revert the chip's fields to their original values
                        return {
                            ...item,
                            carrier_product_criteria_data: item?.carrier_product_criteria_data?.map((field) => {
                                return {
                                    ...field,
                                    criteria_field_value: field.criteria_field_value, // Retain original values
                                    criteria_field_option_value: field.criteria_field_option_value,
                                };
                            }),
                        };
                    }
                    return item;
                });
            });
        }
        setPolicyYearSelectedChip(false)
    }
    // POLICY YEAR :-------------------



    const generateChipLabel = (fieldData, criteriaFields) => {

        const fromField = criteriaFields.find((field) => field.criteria_field_data_type_name == "Text Field");
        const toField = criteriaFields.find((field) => field.criteria_field_data_type_name == "Single Select");

        const fromValue = fieldData.find((data) => data.criteria_field_id == fromField?.criteria_field_id)?.criteria_field_value;
        const toOptionId = fieldData.find((data) => data.criteria_field_id == toField?.criteria_field_id)?.criteria_field_option_id;

        const toValue = fieldData.find((data) => data.criteria_field_id == toField?.criteria_field_id)?.criteria_field_value;
        const selectedOption = toField?.criteria_field_option_data.find((option) => option.criteria_field_option_id == toOptionId);

        if (selectedOption?.criteria_field_option_value == "Lifetime") {
            return `${fromValue}+`;
        }
        if (selectedOption?.criteria_field_option_value == "Custom Text Field") {
            return `${fromValue} to ${toValue}`;
        }
        if (selectedOption?.criteria_field_option_value == "Not Applicable") {
            return `${fromValue}`;
        }

        return fromValue;
    };

    const handleStateSelection = (section, state, isChecked) => {
        const criteriaId = section.criteria_id;
        const criteriaName = section.criteria_name;
        const fieldId = section.criteria_field_data.find(field => field.criteria_field_data_type_name === "Multi Select")?.criteria_field_id;

        if (!fieldId) return;

        // Find Select All option
        const selectAllOption = section.criteria_field_data.find(field => field.criteria_field_data_type_name == "Multi Select")
            ?.criteria_field_option_data.find(option => option.criteria_field_option_value === "Select All");

        // Get all states except Select All
        const allStatesExceptSelectAll = section.criteria_field_data.find(field => field.criteria_field_data_type_name == "Multi Select")?.criteria_field_option_data
            .filter(option => option.criteria_field_option_value != "Select All") || [];

        // Handle different cases for state selection
        const isSelectAllCase = state?.criteria_field_option_value == "Select All";

        setCarrierProductCriteria(prev => {
            const updatedCriteria = [...prev];
            const criteriaIndex = updatedCriteria.findIndex(criteria => criteria.criteria_id == criteriaId);

            if (isSelectAllCase) {
                // Handle Select All case
                if (isChecked) {
                    const allStateData = allStatesExceptSelectAll.map(stateOption => ({
                        criteria_field_id: fieldId,
                        criteria_name: criteriaName,
                        criteria_field_option_id: stateOption.criteria_field_option_id,
                        criteria_field_option_value: stateOption.criteria_field_option_value,
                        criteria_field_value: null,
                    }));

                    // Add Select All option to the data
                    if (selectAllOption) {
                        allStateData.push({
                            criteria_field_id: fieldId,
                            criteria_name: criteriaName,
                            criteria_field_option_id: selectAllOption.criteria_field_option_id,
                            criteria_field_option_value: selectAllOption.criteria_field_option_value,
                            criteria_field_value: null,
                        });
                    }

                    if (criteriaIndex === -1) {
                        updatedCriteria.push({
                            criteria_id: criteriaId,
                            criteria_name: criteriaName,
                            criteria_sequence: section.criteria_sequence,
                            carrier_product_criteria_data: allStateData,
                        });
                    }
                    else {
                        updatedCriteria[criteriaIndex].carrier_product_criteria_data = allStateData;
                    }
                }
                else {
                    // Remove all states
                    if (criteriaIndex !== -1) {
                        updatedCriteria.splice(criteriaIndex, 1);
                    }
                }
            }
            else {
                // Handle individual state selection
                const stateId = state.criteria_field_option_id;
                const stateName = state.criteria_field_option_value;

                if (criteriaIndex == -1) {
                    if (isChecked) {
                        updatedCriteria.push({
                            criteria_id: criteriaId,
                            criteria_name: criteriaName,
                            criteria_sequence: section.criteria_sequence,
                            carrier_product_criteria_data: [{
                                criteria_field_id: fieldId,
                                criteria_name: criteriaName,
                                criteria_field_option_id: stateId,
                                criteria_field_option_value: stateName,
                                criteria_field_value: null,
                            }],
                        });
                    }
                } else {
                    let fieldData = updatedCriteria[criteriaIndex].carrier_product_criteria_data;

                    if (isChecked) {
                        // Add the state
                        fieldData = [
                            ...fieldData.filter(data => data.criteria_field_option_id != selectAllOption?.criteria_field_option_id),
                            {
                                criteria_field_id: fieldId,
                                criteria_name: criteriaName,
                                criteria_field_option_id: stateId,
                                criteria_field_option_value: stateName,
                                criteria_field_value: null,
                            }
                        ];

                        // Check if all states are selected and add Select All if needed
                        const selectedStatesCount = fieldData.length;
                        if (selectedStatesCount == allStatesExceptSelectAll.length && selectAllOption) {
                            fieldData.push({
                                criteria_field_id: fieldId,
                                criteria_name: criteriaName,
                                criteria_field_option_id: selectAllOption.criteria_field_option_id,
                                criteria_field_option_value: selectAllOption.criteria_field_option_value,
                                criteria_field_value: null,
                            });
                        }
                    }
                    else {
                        // Remove the state and Select All
                        fieldData = fieldData.filter(data => data.criteria_field_option_id != stateId && data.criteria_field_option_id != selectAllOption?.criteria_field_option_id);
                    }

                    if (fieldData.length == 0) {
                        updatedCriteria.splice(criteriaIndex, 1);
                    } else {
                        updatedCriteria[criteriaIndex].carrier_product_criteria_data = fieldData;
                    }
                }
            }

            return updatedCriteria;
        });

        // Update selectedStates
        setSelectedStates(prev => {
            if (isSelectAllCase) {
                if (isChecked) {
                    return [...allStatesExceptSelectAll.map(state => state.criteria_field_option_id),
                    selectAllOption?.criteria_field_option_id].filter(Boolean); //this part removes the select all option object if it is any falsy value
                } else {
                    return [];
                }
            } else {
                const stateId = state.criteria_field_option_id;
                let newSelectedStates = isChecked
                    ? [...prev, stateId]
                    : prev.filter(id => id != stateId);

                // Update Select All state based on the new selection
                if (selectAllOption) {
                    const regularStateIds = newSelectedStates.filter(
                        id => id != selectAllOption.criteria_field_option_id
                    );

                    if (regularStateIds.length == allStatesExceptSelectAll.length) {
                        if (!newSelectedStates.includes(selectAllOption.criteria_field_option_id)) {
                            newSelectedStates.push(selectAllOption.criteria_field_option_id);
                        }
                    } else {
                        newSelectedStates = newSelectedStates.filter(
                            id => id != selectAllOption.criteria_field_option_id
                        );
                    }
                }

                return newSelectedStates;
            }
        });

        // Update isAllSelected - Fixed logic
        setIsAllSelected(prevIsAllSelected => {
            if (isSelectAllCase) {
                return isChecked;
            }

            // For individual state changes, check if all regular states will be selected
            return isChecked ? selectedStates.filter(id => id != selectAllOption?.criteria_field_option_id).length + 1 == allStatesExceptSelectAll.length
                : false; // If unchecking any state, isAllSelected should be false
        });
    };

    const renderSection = (section) => {
        const criteriaName = section?.criteria_name?.toLowerCase().replace(/ /g, "_");
        const allStatesField = section?.criteria_field_data?.find((field) => field.criteria_field_data_type_name == "Multi Select");
        const allStates = allStatesField?.criteria_field_option_data || [];
        const selectAllState = allStates.find((state) => state.criteria_field_option_value == "Select All");
        const otherStates = allStates.filter((state) => state.criteria_field_option_value != "Select All");

        return (
            <>
                <Grid container sx={{ justifyContent: "space-between" }}>
                    {(criteriaName == "sub_type" || criteriaName == "type") && (
                        <>
                            {criteriaName == "sub_type" && (
                                <Grid item xs={12} key={section.criteria_id} sx={{ margin: '0px 0px 10px' }}>
                                    <Accordion
                                        defaultExpanded
                                        expanded={expanded.panel1}
                                        onChange={handleChange('panel1')}
                                        sx={{ width: '100%' }}
                                        className="cd-gbl-accordian"
                                    >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon sx={{ color: 'black' }} />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            sx={{ padding: '0px 15px' }}
                                        >
                                            <Typography className="cd-gbl-accordian-head-text">
                                                {section.criteria_name}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="cd-gbl-accordian-details">
                                            <Grid container>
                                                {section.criteria_field_data.map((field) => (
                                                    <Grid item xs={12} key={field.criteria_field_id}>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            autoComplete='off'
                                                            rows={4}
                                                            placeholder={`Enter ${field.criteria_field_name}`}
                                                            className="cd-multiline-textfield"
                                                            value={carrierProductCriteria?.find((criteria) => criteria.criteria_id == section.criteria_id)
                                                                ?.carrier_product_criteria_data?.find((data) => data.criteria_field_id == field.criteria_field_id)?.criteria_field_value || ""
                                                            }
                                                            onChange={(e) => updateSubtype(section, field.criteria_field_id, e.target.value, field.criteria_field_name)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}

                            {criteriaName == "type" && (
                                <Grid item xs={12} key={section.criteria_id} sx={{ margin: '0px 0px 10px' }}>
                                    <Accordion
                                        expanded={expanded.panel2}
                                        onChange={handleChange('panel2')}
                                        sx={{ width: '100%' }}
                                        className="cd-gbl-accordian"
                                    >
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDownIcon sx={{ color: 'black' }} />}
                                            aria-controls={`panel${section.criteria_id}-bh-content`}
                                            id={`panel${section.criteria_id}-bh-header`}
                                            sx={{ padding: '0px 15px', alignItems: "center", "& .MuiAccordionSummary-content": { alignItems: "center" } }}
                                        >
                                            <Grid item sx={{ width: "220px", minWidth: "220px" }}>
                                                <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                    <Grid item xs={5}>
                                                        <Typography className="cd-gbl-accordian-head-text">
                                                            {section.criteria_name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5.5} sx={{ textAlign: "end" }}>
                                                        {carrierProductCriteria?.find((criteria) => criteria.criteria_name == "Type")?.carrier_product_criteria_data.some((data) => data.criteria_field_option_id) && (
                                                            <Button
                                                                variant='outlined'
                                                                size='small'
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setCarrierProductCriteria((prevState) => {
                                                                        return prevState?.map((criteria) =>
                                                                            // Check if it's the "Type" criteria
                                                                            criteria.criteria_id == section.criteria_id ?
                                                                                {
                                                                                    ...criteria,
                                                                                    carrier_product_criteria_data: criteria.carrier_product_criteria_data.map((data) => ({
                                                                                        ...data,
                                                                                        criteria_field_option_id: "",
                                                                                        criteria_field_value: "",
                                                                                    })),
                                                                                } : criteria
                                                                        )?.filter((criteria) => {
                                                                            // Remove "Type" object if no value is selected
                                                                            return criteria.criteria_name != "Type" || criteria.carrier_product_criteria_data?.some((data) => data.criteria_field_option_id || data.criteria_field_value);
                                                                        });
                                                                    });
                                                                }}
                                                            >
                                                                Clear
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: "5px 20px 35px" }}>
                                            <Grid item xs={12} sx={{ background: "#fff", padding: "10px", borderRadius: '5px', width: "220px", minWidth: "220px" }}>
                                                {section.criteria_field_data.map((field) => {
                                                    // Render Radio Group
                                                    if (field.criteria_field_data_type_name == "Single Select") {
                                                        return (
                                                            <Grid item xs={12} key={field.criteria_field_id}>
                                                                <FormControl fullWidth>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        name="radio-buttons-group"
                                                                        value={carrierProductCriteria.find(
                                                                            (criteria) => criteria.criteria_name === "Type"
                                                                        )?.carrier_product_criteria_data.find(
                                                                            (data) => data.criteria_field_id == field.criteria_field_id
                                                                        )?.criteria_field_option_id || ""}
                                                                        onChange={(e) => handleTypeChange(e, section, field)}
                                                                    >
                                                                        {field?.criteria_field_option_data?.map((option) => (
                                                                            <FormControlLabel
                                                                                key={option.criteria_field_option_id}
                                                                                value={option.criteria_field_option_id}
                                                                                control={<Radio sx={{ padding: "2px 0px", margin: "2px 10px" }} />}
                                                                                label={option.criteria_field_option_value}
                                                                            />
                                                                        ))}
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}

                        </>
                    )}
                </Grid>

                {criteriaName == "issue_age" && (
                    <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                        <Accordion
                            expanded={expanded.panel3}
                            onChange={handleChange("panel3")}
                            sx={{ width: "100%" }}
                            className="cd-gbl-accordian"
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                sx={{ padding: "0px 15px" }}
                            >
                                <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Grid item className="cd-left-section-item">
                                        <Grid container className="cd-left-section-container">
                                            <Grid item>
                                                <Typography
                                                    className="cd-gbl-accordian-head-text">
                                                    {section.criteria_name} <span style={{ fontSize: "13px", fontWeight: '500', color: "gray" }}>(optional)</span>
                                                </Typography>
                                            </Grid>
                                            {showIssueAgeButtons &&
                                                <Grid item>
                                                    <Grid container sx={{ gap: "10px" }}>
                                                        <Grid item>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                className="cd-cancel-btn"
                                                                onClick={(event) => {
                                                                    handleCancelIssueAgeChip(event)
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                className="cd-save-btn"
                                                                disabled={
                                                                    !issueAgeTempValues.fromAge ||
                                                                    !issueAgeTempValues.toAgeOption ||
                                                                    (issueAgeTempValues.toAgeOption == issueAgeTextFieldId && !issueAgeTempValues.toAgeValue)
                                                                }
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    saveIssueAgeData()
                                                                    setIssueAgeSelectedChip(false);
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item className="cd-right-section-item">
                                        <Typography variant="span">Created Issue Age data</Typography>
                                    </Grid> */}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className="cd-gbl-accordian-details">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                            <Grid item className="cd-gbl-input-section">
                                                <Grid container>
                                                    {section.criteria_field_data.map((field) => {
                                                        // Render Text Field
                                                        if (field.criteria_field_data_type_name == "Text Field") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="span" className="cb-gbl-age-label">
                                                                                {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="cd-from-age-field-item">
                                                                            <TextField
                                                                                size="small"
                                                                                fullWidth
                                                                                autoComplete='off'
                                                                                placeholder={`Enter ${field.criteria_field_name}`}
                                                                                className="cd-from-age-field"
                                                                                value={issueAgeTempValues.fromAge || ""}
                                                                                onChange={(e) => {
                                                                                    const value = String(e.target.value);
                                                                                    if (/^\d*$/.test(value)) {
                                                                                        if ((value === "0" || value.length <= 3)) {
                                                                                            handleIssueAgeChange("fromAge", value)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }

                                                        // Render Radio Group
                                                        if (field.criteria_field_data_type_name == "Single Select") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <FormControl fullWidth>
                                                                        <FormLabel className="cb-gbl-age-label" sx={{ margin: "8px 0px" }}>
                                                                            {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={issueAgeTempValues.toAgeOption || ""}
                                                                            onChange={(e) =>
                                                                                handleIssueAgeChange("toAgeOption", e.target.value)
                                                                            }
                                                                        >
                                                                            {field.criteria_field_option_data.map((option) => (
                                                                                <Grid container alignItems="center" key={option.criteria_field_option_id}>
                                                                                    <Grid item>
                                                                                        <FormControlLabel
                                                                                            sx={{
                                                                                                margin: "5px 0px",
                                                                                            }}
                                                                                            value={option.criteria_field_option_id}
                                                                                            control={<Radio className="cd-radio-btn" size="medium" />}
                                                                                            label={
                                                                                                option.criteria_field_option_value == "Custom Text Field"
                                                                                                    ? issueAgeTempValues.toAgeOption == option.criteria_field_option_id ? "" : "Enter Year"
                                                                                                    : option.criteria_field_option_value
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    {issueAgeTempValues.toAgeOption == option.criteria_field_option_id &&
                                                                                        option.criteria_field_option_value == "Custom Text Field" && (
                                                                                            <Grid item xs={9}>
                                                                                                <TextField
                                                                                                    label={`Enter ${section.criteria_name}`}
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    size="small"
                                                                                                    autoComplete="off"
                                                                                                    className="cd-enter-year-field"
                                                                                                    value={issueAgeTempValues.toAgeValue || ""}
                                                                                                    inputRef={textFieldIssueAgeRef}
                                                                                                    onChange={(e) => {
                                                                                                        const value = e.target.value;
                                                                                                        if (/^\d*$/.test(value)) {
                                                                                                            if ((value === "0" || value.length <= 3)) {
                                                                                                                handleIssueAgeChange("toAgeValue", value)
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (!/[0-9]$/.test(e.key)) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                </Grid>
                                                                            ))}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Divider orientation="vertical" />
                                            </Grid>
                                            <Grid item className="cd-gbl-input-right-section">
                                                <Grid container sx={{ gap: "20px" }}>
                                                    {issueAgeChips && issueAgeChips?.map((chip, index) => {

                                                        const chipId = chip.carrier_product_criteria_id || chip.carrier_product_criteria_id_custom;
                                                        const isHovered = hoverOnIssueChip == chipId;
                                                        const isDeletePopoverOpen =
                                                            deletePopover.open &&
                                                            ((deletePopover.chip?.carrier_product_criteria_id && deletePopover.chip?.carrier_product_criteria_id == chip.carrier_product_criteria_id) ||
                                                                (deletePopover.chip?.carrier_product_criteria_id_custom && deletePopover.chip?.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom));

                                                        return (
                                                            <>
                                                                <Grid item
                                                                    className="cd-chip-bg-item"
                                                                    key={index}
                                                                    sx={{
                                                                        border: issueAgeSelectedChip?.carrier_product_criteria_id ? issueAgeSelectedChip.carrier_product_criteria_id == chip.carrier_product_criteria_id ? "1px solid #a3bfdf" : "none"
                                                                            : issueAgeSelectedChip?.carrier_product_criteria_id_custom ? issueAgeSelectedChip.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom ? "1px solid #a3bfdf" : "none"
                                                                                : "none"
                                                                    }}

                                                                >
                                                                    <Grid container sx={{ alignItems: "center", columnGap: "10px" }}>
                                                                        <Grid item>
                                                                            <Typography variant="span" className="cd-chip-text">
                                                                                Ages: <Typography variant="span" className="cd-chip-value">{chip.name}</Typography>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container sx={{ columnGap: "2px" }}>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{ padding: "1px 2px" }}
                                                                                    onClick={() => handleEditIssueAge(chip)}
                                                                                    disabled={!!activeChip.deleteChip}
                                                                                >
                                                                                    <img src={editIcon} style={{ fontSize: "20px" }} />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    disabled={!!activeChip.editChip}
                                                                                    sx={{
                                                                                        padding: "1px 2px",
                                                                                        cursor: activeChip.editChip != null ? "not-allowed !important" : "pointer !important",
                                                                                        pointerEvents: activeChip.editChip != null ? "auto !important" : ""
                                                                                    }}
                                                                                    onClick={(event) => setDeletePopover({ open: true, chip, anchorEl: event.currentTarget })}
                                                                                    onMouseEnter={() => {
                                                                                        const id = chip?.carrier_product_criteria_id || chip?.carrier_product_criteria_id_custom;
                                                                                        setHoverOnIssueChip(id);
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setHoverOnIssueChip(null);
                                                                                    }}
                                                                                >
                                                                                    <Icons iconName="deleteIcon" iconColor={isHovered || isDeletePopoverOpen ? "#FF6174" : "black"} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {criteriaName == "renewal_year" && (
                    <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                        <Accordion
                            expanded={expanded.panel4}
                            onChange={handleChange("panel4")}
                            sx={{ width: "100%" }}
                            className="cd-gbl-accordian"
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                sx={{ padding: "0px 15px" }}
                            >
                                <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Grid item className="cd-left-section-item">
                                        <Grid container className="cd-left-section-container">
                                            <Grid item>
                                                <Typography className="cd-gbl-accordian-head-text">
                                                    {section.criteria_name} <span style={{ fontSize: "13px", fontWeight: '500', color: "gray" }}>(optional)</span>
                                                </Typography>
                                            </Grid>
                                            {showRenewalYearButtons &&
                                                <Grid item>
                                                    <Grid container sx={{ gap: "10px" }}>
                                                        <Grid item>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                className="cd-cancel-btn"
                                                                onClick={(event) => {
                                                                    handleCancelRenewalYearChip(event)
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                size="small" className="cd-save-btn"
                                                                disabled={
                                                                    !renewalYearTempValues.fromAge ||
                                                                    !renewalYearTempValues.toAgeOption ||
                                                                    (renewalYearTempValues.toAgeOption == renewalYearTextFieldId && !renewalYearTempValues.toAgeValue)
                                                                }
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    saveRenewalYear();
                                                                    setRenewalYearSelectedChip(false)
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item className="cd-right-section-item">
                                        <Typography variant="span">Created Renewal Year Data</Typography>
                                    </Grid> */}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className="cd-gbl-accordian-details">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                            <Grid item className="cd-gbl-input-section">
                                                <Grid container>
                                                    {section.criteria_field_data.map((field) => {
                                                        // Render TextField for "Text Field" type
                                                        if (field.criteria_field_data_type_name == "Text Field") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="span" className="cb-gbl-age-label">
                                                                                {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="cd-from-age-field-item">
                                                                            <TextField
                                                                                size="small"
                                                                                fullWidth
                                                                                autoComplete='off'
                                                                                placeholder={`Enter ${field.criteria_field_name}`}
                                                                                className="cd-from-age-field"
                                                                                value={renewalYearTempValues.fromAge || ""}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    if (/^\d*$/.test(value)) {
                                                                                        if ((value === "0" || value.length <= 3)) {
                                                                                            handleRenewalYearChange("fromAge", value)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }

                                                        // Render RadioGroup for "Single Select" type
                                                        if (field.criteria_field_data_type_name == "Single Select") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <FormControl fullWidth>
                                                                        <FormLabel
                                                                            className="cb-gbl-age-label"
                                                                            sx={{ margin: "8px 0px" }}
                                                                        >

                                                                            {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={renewalYearTempValues.toAgeOption || ""}
                                                                            onChange={(e) =>
                                                                                handleRenewalYearChange("toAgeOption", e.target.value)
                                                                            }
                                                                        >
                                                                            {field.criteria_field_option_data.map((option) => (
                                                                                <Grid container alignItems="center" key={option.criteria_field_option_id}>
                                                                                    <Grid item>
                                                                                        <FormControlLabel
                                                                                            sx={{
                                                                                                margin: "5px 0px"
                                                                                            }}
                                                                                            value={option.criteria_field_option_id}
                                                                                            control={<Radio className="cd-radio-btn" size="medium" />}
                                                                                            label={
                                                                                                option.criteria_field_option_value == "Custom Text Field"
                                                                                                    ? renewalYearTempValues.toAgeOption == option.criteria_field_option_id ? ""
                                                                                                        : "Enter Year" : option.criteria_field_option_value
                                                                                            }
                                                                                        />
                                                                                    </Grid>

                                                                                    {/* Render TextField beside the "Custom Text Field" radio button */}
                                                                                    {renewalYearTempValues.toAgeOption == option.criteria_field_option_id &&
                                                                                        option.criteria_field_option_value == "Custom Text Field" && (
                                                                                            <Grid item xs={9}>
                                                                                                <TextField
                                                                                                    label={`Enter ${section.criteria_name}`}
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    size="small"
                                                                                                    autoComplete="off"
                                                                                                    className="cd-enter-year-field"
                                                                                                    value={renewalYearTempValues.toAgeValue || ""}
                                                                                                    inputRef={textFieldRenewalYearRef}
                                                                                                    onChange={(e) => {
                                                                                                        const value = e.target.value;
                                                                                                        if (/^\d*$/.test(value)) {
                                                                                                            if ((value === "0" || value.length <= 3)) {
                                                                                                                handleRenewalYearChange("toAgeValue", e.target.value)
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (!/[0-9]$/.test(e.key)) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                </Grid>
                                                                            ))}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Divider orientation="vertical" />
                                            </Grid>
                                            <Grid item className="cd-gbl-input-right-section">
                                                <Grid container sx={{ gap: "20px" }}>
                                                    {renewalYearChips && renewalYearChips?.map((chip, index) => {

                                                        const chipId = chip.carrier_product_criteria_id || chip.carrier_product_criteria_id_custom;
                                                        const isHovered = hoverOnRenewalChip == chipId;
                                                        const isDeletePopoverOpen =
                                                            renewalYearDeletePopover.open &&
                                                            ((renewalYearDeletePopover.chip?.carrier_product_criteria_id && renewalYearDeletePopover.chip?.carrier_product_criteria_id == chip.carrier_product_criteria_id) ||
                                                                (renewalYearDeletePopover.chip?.carrier_product_criteria_id_custom && renewalYearDeletePopover.chip?.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom));

                                                        return (
                                                            <>
                                                                <Grid item
                                                                    className="cd-chip-bg-item"
                                                                    key={index}
                                                                    sx={{
                                                                        border: renewalYearSelectedChip?.carrier_product_criteria_id ? renewalYearSelectedChip.carrier_product_criteria_id == chip.carrier_product_criteria_id ? "1px solid #a3bfdf" : "none"
                                                                            : renewalYearSelectedChip?.carrier_product_criteria_id_custom ? renewalYearSelectedChip.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom ? "1px solid #a3bfdf" : "none"
                                                                                : "none"
                                                                    }}
                                                                >
                                                                    <Grid container sx={{ alignItems: "center", columnGap: "10px" }}>
                                                                        <Grid item>
                                                                            <Typography variant="span" className="cd-chip-text">
                                                                                Years: <Typography variant="span" className="cd-chip-value">{chip.name}</Typography>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container sx={{ columnGap: "2px" }}>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{ padding: "1px 2px" }}
                                                                                    onClick={() => handleEditRenewalAge(chip)}
                                                                                    disabled={!!activeRenewalChip.deleteChip}
                                                                                >
                                                                                    <img src={editIcon} style={{ fontSize: "20px" }} />
                                                                                </IconButton>

                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{
                                                                                        padding: "1px 2px",
                                                                                        cursor: activeRenewalChip.editChip != null ? "not-allowed !important" : "pointer !important",
                                                                                        pointerEvents: activeRenewalChip.editChip != null ? "auto !important" : ""
                                                                                    }}
                                                                                    disabled={!!activeRenewalChip.editChip}
                                                                                    onClick={(event) => {
                                                                                        setRenewalYearDeletePopover({
                                                                                            open: true,
                                                                                            chip, // Pass the chip to identify what to delete
                                                                                            anchorEl: event.currentTarget, // Set the anchor for the popover
                                                                                        });
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        const id = chip?.carrier_product_criteria_id || chip?.carrier_product_criteria_id_custom;
                                                                                        setHoverOnRenewalChip(id);
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setHoverOnRenewalChip(null);
                                                                                    }}
                                                                                >
                                                                                    <Icons iconName="deleteIcon" iconColor={(isHovered || isDeletePopoverOpen) ? "#FF6174" : "black"} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {criteriaName == "excess_year" && (
                    <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                        <Accordion
                            expanded={expanded.panel5}
                            onChange={handleChange("panel5")}
                            sx={{ width: "100%" }}
                            className="cd-gbl-accordian"
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                sx={{ padding: "0px 15px" }}
                            >
                                <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Grid item className="cd-left-section-item">
                                        <Grid container className="cd-left-section-container">
                                            <Grid item>
                                                <Typography className="cd-gbl-accordian-head-text">
                                                    {section.criteria_name} <span style={{ fontSize: "13px", fontWeight: '500', color: "gray" }}>(optional)</span>
                                                </Typography>
                                            </Grid>
                                            {showExcessYearButtons &&
                                                <Grid item>
                                                    <Grid container sx={{ gap: "10px" }}>
                                                        <Grid item>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                className="cd-cancel-btn"
                                                                onClick={(event) => {
                                                                    handleCancelExcessYearChip(event)
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                size="small" className="cd-save-btn"
                                                                disabled={
                                                                    !excessYearTempValues.fromAge ||
                                                                    !excessYearTempValues.toAgeOption ||
                                                                    (excessYearTempValues.toAgeOption == excessYearTextFieldId && !excessYearTempValues.toAgeValue)
                                                                }
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    saveExcessYear();
                                                                    setExcessYearSelectedChip(false)
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item className="cd-right-section-item">
                                        <Typography variant="span">Created Renewal Year Data</Typography>
                                    </Grid> */}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className="cd-gbl-accordian-details">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                            <Grid item className="cd-gbl-input-section">
                                                <Grid container>
                                                    {section.criteria_field_data.map((field) => {
                                                        // Render TextField for "Text Field" type
                                                        if (field.criteria_field_data_type_name == "Text Field") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="span" className="cb-gbl-age-label">
                                                                                {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="cd-from-age-field-item">
                                                                            <TextField
                                                                                size="small"
                                                                                fullWidth
                                                                                autoComplete='off'
                                                                                placeholder={`Enter ${field.criteria_field_name}`}
                                                                                className="cd-from-age-field"
                                                                                value={excessYearTempValues.fromAge || ""}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    if (/^\d*$/.test(value)) {
                                                                                        if ((value === "0" || value.length <= 3)) {
                                                                                            handleExcessYearChange("fromAge", value)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }

                                                        // Render RadioGroup for "Single Select" type
                                                        if (field.criteria_field_data_type_name == "Single Select") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <FormControl fullWidth>
                                                                        <FormLabel
                                                                            className="cb-gbl-age-label"
                                                                            sx={{ margin: "8px 0px" }}
                                                                        >

                                                                            {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={excessYearTempValues.toAgeOption || ""}
                                                                            onChange={(e) =>
                                                                                handleExcessYearChange("toAgeOption", e.target.value)
                                                                            }
                                                                        >
                                                                            {field.criteria_field_option_data.map((option) => (
                                                                                <Grid container alignItems="center" key={option.criteria_field_option_id}>
                                                                                    <Grid item>
                                                                                        <FormControlLabel
                                                                                            sx={{
                                                                                                margin: "5px 0px"
                                                                                            }}
                                                                                            value={option.criteria_field_option_id}
                                                                                            control={<Radio className="cd-radio-btn" size="medium" />}
                                                                                            label={
                                                                                                option.criteria_field_option_value == "Custom Text Field"
                                                                                                    ? excessYearTempValues.toAgeOption == option.criteria_field_option_id ? ""
                                                                                                        : "Enter Year" : option.criteria_field_option_value
                                                                                            }
                                                                                        />
                                                                                    </Grid>

                                                                                    {/* Render TextField beside the "Custom Text Field" radio button */}
                                                                                    {excessYearTempValues.toAgeOption == option.criteria_field_option_id &&
                                                                                        option.criteria_field_option_value == "Custom Text Field" && (
                                                                                            <Grid item xs={9}>
                                                                                                <TextField
                                                                                                    label={`Enter ${section.criteria_name}`}
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    size="small"
                                                                                                    autoComplete="off"
                                                                                                    className="cd-enter-year-field"
                                                                                                    value={excessYearTempValues.toAgeValue || ""}
                                                                                                    inputRef={textFieldExcessYearRef}
                                                                                                    onChange={(e) => {
                                                                                                        const value = e.target.value;
                                                                                                        if (/^\d*$/.test(value)) {
                                                                                                            if ((value === "0" || value.length <= 3)) {
                                                                                                                handleExcessYearChange("toAgeValue", e.target.value)
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (!/[0-9]$/.test(e.key)) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                </Grid>
                                                                            ))}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Divider orientation="vertical" />
                                            </Grid>
                                            <Grid item className="cd-gbl-input-right-section">
                                                <Grid container sx={{ gap: "20px" }}>
                                                    {excessYearChips && excessYearChips?.map((chip, index) => {

                                                        const chipId = chip.carrier_product_criteria_id || chip.carrier_product_criteria_id_custom;
                                                        const isHovered = hoverOnExcessYearChip == chipId;
                                                        const isDeletePopoverOpen =
                                                            excessYearDeletePopover.open &&
                                                            ((excessYearDeletePopover.chip?.carrier_product_criteria_id && excessYearDeletePopover.chip?.carrier_product_criteria_id == chip.carrier_product_criteria_id) ||
                                                                (excessYearDeletePopover.chip?.carrier_product_criteria_id_custom && excessYearDeletePopover.chip?.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom));

                                                        return (
                                                            <>
                                                                <Grid item
                                                                    className="cd-chip-bg-item"
                                                                    key={index}
                                                                    sx={{
                                                                        border: excessYearSelectedChip?.carrier_product_criteria_id ? excessYearSelectedChip.carrier_product_criteria_id == chip.carrier_product_criteria_id ? "1px solid #a3bfdf" : "none"
                                                                            : excessYearSelectedChip?.carrier_product_criteria_id_custom ? excessYearSelectedChip.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom ? "1px solid #a3bfdf" : "none"
                                                                                : "none"
                                                                    }}
                                                                >
                                                                    <Grid container sx={{ alignItems: "center", columnGap: "10px" }}>
                                                                        <Grid item>
                                                                            <Typography variant="span" className="cd-chip-text">
                                                                                Years: <Typography variant="span" className="cd-chip-value">{chip.name}</Typography>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container sx={{ columnGap: "2px" }}>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{ padding: "1px 2px" }}
                                                                                    onClick={() => handleEditExcessYear(chip)}
                                                                                    disabled={!!activeExcessYearChip.deleteChip}
                                                                                >
                                                                                    <img src={editIcon} style={{ fontSize: "20px" }} />
                                                                                </IconButton>

                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{
                                                                                        padding: "1px 2px",
                                                                                        cursor: activeExcessYearChip.editChip != null ? "not-allowed !important" : "pointer !important",
                                                                                        pointerEvents: activeExcessYearChip.editChip != null ? "auto !important" : ""
                                                                                    }}
                                                                                    disabled={!!activeExcessYearChip.editChip}
                                                                                    onClick={(event) => {
                                                                                        setExcessYearDeletePopover({
                                                                                            open: true,
                                                                                            chip, // Pass the chip to identify what to delete
                                                                                            anchorEl: event.currentTarget, // Set the anchor for the popover
                                                                                        });
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        const id = chip?.carrier_product_criteria_id || chip?.carrier_product_criteria_id_custom;
                                                                                        setHoverOnExcessYearChip(id);
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setHoverOnExcessYearChip(null);
                                                                                    }}
                                                                                >
                                                                                    <Icons iconName="deleteIcon" iconColor={(isHovered || isDeletePopoverOpen) ? "#FF6174" : "black"} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {criteriaName == "policy_year" && (
                    <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                        <Accordion
                            expanded={expanded.panel6}
                            onChange={handleChange("panel6")}
                            sx={{ width: "100%" }}
                            className="cd-gbl-accordian"
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                sx={{ padding: "0px 15px" }}
                            >
                                <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Grid item className="cd-left-section-item">
                                        <Grid container className="cd-left-section-container">
                                            <Grid item>
                                                <Typography className="cd-gbl-accordian-head-text">
                                                    {section.criteria_name} <span style={{ fontSize: "13px", fontWeight: '500', color: "gray" }}>(optional)</span>
                                                </Typography>
                                            </Grid>
                                            {showPolicyYearButtons &&
                                                <Grid item>
                                                    <Grid container sx={{ gap: "10px" }}>
                                                        <Grid item>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                className="cd-cancel-btn"
                                                                onClick={(event) => {
                                                                    handleCancelPolicyYearChip(event)
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                size="small" className="cd-save-btn"
                                                                disabled={
                                                                    !policyYearTempValues.fromAge ||
                                                                    !policyYearTempValues.toAgeOption ||
                                                                    (policyYearTempValues.toAgeOption == policyYearTextFieldId && !policyYearTempValues.toAgeValue)
                                                                }
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    savePolicyYear();
                                                                    setPolicyYearSelectedChip(false)
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item className="cd-right-section-item">
                                        <Typography variant="span">Created Renewal Year Data</Typography>
                                    </Grid> */}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails className="cd-gbl-accordian-details">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                            <Grid item className="cd-gbl-input-section">
                                                <Grid container>
                                                    {section.criteria_field_data.map((field) => {
                                                        // Render TextField for "Text Field" type
                                                        if (field.criteria_field_data_type_name == "Text Field") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <Grid container>
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="span" className="cb-gbl-age-label">
                                                                                {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="cd-from-age-field-item">
                                                                            <TextField
                                                                                size="small"
                                                                                fullWidth
                                                                                autoComplete='off'
                                                                                placeholder={`Enter ${field.criteria_field_name}`}
                                                                                className="cd-from-age-field"
                                                                                value={policyYearTempValues.fromAge || ""}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    if (/^\d*$/.test(value)) {
                                                                                        if ((value === "0" || value.length <= 3)) {
                                                                                            handlePolicyYearChange("fromAge", value)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }

                                                        // Render RadioGroup for "Single Select" type
                                                        if (field.criteria_field_data_type_name == "Single Select") {
                                                            return (
                                                                <Grid item xs={12} key={field.criteria_field_id}>
                                                                    <FormControl fullWidth>
                                                                        <FormLabel
                                                                            className="cb-gbl-age-label"
                                                                            sx={{ margin: "8px 0px" }}
                                                                        >

                                                                            {field.criteria_field_name} <Symboles symboleName="astrics" />
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={policyYearTempValues.toAgeOption || ""}
                                                                            onChange={(e) =>
                                                                                handlePolicyYearChange("toAgeOption", e.target.value)
                                                                            }
                                                                        >
                                                                            {field.criteria_field_option_data.map((option) => (
                                                                                <Grid container alignItems="center" key={option.criteria_field_option_id}>
                                                                                    <Grid item>
                                                                                        <FormControlLabel
                                                                                            sx={{
                                                                                                margin: "5px 0px"
                                                                                            }}
                                                                                            value={option.criteria_field_option_id}
                                                                                            control={<Radio className="cd-radio-btn" size="medium" />}
                                                                                            label={
                                                                                                option.criteria_field_option_value == "Custom Text Field"
                                                                                                    ? policyYearTempValues.toAgeOption == option.criteria_field_option_id ? ""
                                                                                                        : "Enter Year" : option.criteria_field_option_value
                                                                                            }
                                                                                        />
                                                                                    </Grid>

                                                                                    {/* Render TextField beside the "Custom Text Field" radio button */}
                                                                                    {policyYearTempValues.toAgeOption == option.criteria_field_option_id &&
                                                                                        option.criteria_field_option_value == "Custom Text Field" && (
                                                                                            <Grid item xs={9}>
                                                                                                <TextField
                                                                                                    label={`Enter ${section.criteria_name}`}
                                                                                                    fullWidth
                                                                                                    variant="standard"
                                                                                                    size="small"
                                                                                                    autoComplete="off"
                                                                                                    className="cd-enter-year-field"
                                                                                                    value={policyYearTempValues.toAgeValue || ""}
                                                                                                    inputRef={textFieldPolicyYearRef}
                                                                                                    onChange={(e) => {
                                                                                                        const value = e.target.value;
                                                                                                        if (/^\d*$/.test(value)) {
                                                                                                            if ((value === "0" || value.length <= 3)) {
                                                                                                                handlePolicyYearChange("toAgeValue", e.target.value)
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (!/[0-9]$/.test(e.key)) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                </Grid>
                                                                            ))}
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Divider orientation="vertical" />
                                            </Grid>
                                            <Grid item className="cd-gbl-input-right-section">
                                                <Grid container sx={{ gap: "20px" }}>
                                                    {policyYearChips && policyYearChips?.map((chip, index) => {

                                                        const chipId = chip.carrier_product_criteria_id || chip.carrier_product_criteria_id_custom;
                                                        const isHovered = hoverOnPolicyYearChip == chipId;
                                                        const isDeletePopoverOpen =
                                                            policyYearDeletePopover.open &&
                                                            ((policyYearDeletePopover.chip?.carrier_product_criteria_id && policyYearDeletePopover.chip?.carrier_product_criteria_id == chip.carrier_product_criteria_id) ||
                                                                (policyYearDeletePopover.chip?.carrier_product_criteria_id_custom && policyYearDeletePopover.chip?.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom));

                                                        return (
                                                            <>
                                                                <Grid item
                                                                    className="cd-chip-bg-item"
                                                                    key={index}
                                                                    sx={{
                                                                        border: policyYearSelectedChip?.carrier_product_criteria_id ? policyYearSelectedChip.carrier_product_criteria_id == chip.carrier_product_criteria_id ? "1px solid #a3bfdf" : "none"
                                                                            : policyYearSelectedChip?.carrier_product_criteria_id_custom ? policyYearSelectedChip.carrier_product_criteria_id_custom == chip.carrier_product_criteria_id_custom ? "1px solid #a3bfdf" : "none"
                                                                                : "none"
                                                                    }}
                                                                >
                                                                    <Grid container sx={{ alignItems: "center", columnGap: "10px" }}>
                                                                        <Grid item>
                                                                            <Typography variant="span" className="cd-chip-text">
                                                                                Years: <Typography variant="span" className="cd-chip-value">{chip.name}</Typography>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Grid container sx={{ columnGap: "2px" }}>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{ padding: "1px 2px" }}
                                                                                    onClick={() => handleEditPolicyYear(chip)}
                                                                                    disabled={!!activePolicyYearChip.deleteChip}
                                                                                >
                                                                                    <img src={editIcon} style={{ fontSize: "20px" }} />
                                                                                </IconButton>

                                                                                <IconButton
                                                                                    size="small"
                                                                                    sx={{
                                                                                        padding: "1px 2px",
                                                                                        cursor: activePolicyYearChip.editChip != null ? "not-allowed !important" : "pointer !important",
                                                                                        pointerEvents: activePolicyYearChip.editChip != null ? "auto !important" : ""
                                                                                    }}
                                                                                    disabled={!!activePolicyYearChip.editChip}
                                                                                    onClick={(event) => {
                                                                                        setPolicyYearDeletePopover({
                                                                                            open: true,
                                                                                            chip, // Pass the chip to identify what to delete
                                                                                            anchorEl: event.currentTarget, // Set the anchor for the popover
                                                                                        });
                                                                                    }}
                                                                                    onMouseEnter={() => {
                                                                                        const id = chip?.carrier_product_criteria_id || chip?.carrier_product_criteria_id_custom;
                                                                                        setHoverOnPolicyYearChip(id);
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        setHoverOnPolicyYearChip(null);
                                                                                    }}
                                                                                >
                                                                                    <Icons iconName="deleteIcon" iconColor={(isHovered || isDeletePopoverOpen) ? "#FF6174" : "black"} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {criteriaName == "state" && (
                    <Grid item xs={12} sx={{ margin: "10px 0px 0px" }}>
                        <Accordion
                            expanded={expanded.panel7}
                            onChange={handleChange("panel7")}
                            sx={{ width: "100%" }}
                            className="cd-gbl-accordian"
                        >
                            {/* Accordion Summary */}
                            <AccordionSummary
                                expandIcon={<KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                                sx={{ padding: "0px 15px" }}
                            >
                                <Typography className="cd-gbl-accordian-head-text" sx={{ marginRight: "10px" }}>
                                    {section.criteria_name}
                                </Typography>
                            </AccordionSummary>

                            {/* Accordion Details */}
                            <AccordionDetails className="cd-gbl-accordian-details">
                                <Grid container>
                                    <Grid item xs={12} sx={{ margin: "0px 0px 20px" }}>
                                        <Grid container sx={{ gap: "10px", alignItems: "center", justifyContent: "center" }} >
                                            {selectAllState && (
                                                <FormControlLabel
                                                    className="cd-gbl-checkbox"
                                                    sx={{ ml: "0px" }}
                                                    control={
                                                        <Checkbox
                                                            sx={{ padding: "0px", mr: "5px" }}
                                                            checked={isAllSelected} // Check if all checkboxes are selected
                                                            onChange={(event) => {
                                                                event.stopPropagation();
                                                                handleStateSelection(section, selectAllState, event.target.checked);
                                                            }}
                                                        />
                                                    }
                                                    label={selectAllState.criteria_field_option_value}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormGroup row sx={{ justifyContent: "start", rowGap: "15px", columnGap: "15px" }}>
                                            {/* State-Specific Checkboxes */}
                                            {otherStates.map((state) => (
                                                <>

                                                    <FormControlLabel
                                                        key={state.criteria_field_option_id}
                                                        className="cd-gbl-checkbox"
                                                        sx={{ ml: "0px" }}
                                                        control={
                                                            <Checkbox
                                                                sx={{ padding: "0px", mr: "5px" }}
                                                                checked={selectedStates.includes(Number(state.criteria_field_option_id))} // Check if the state is selected
                                                                onChange={(e) =>
                                                                    handleStateSelection(
                                                                        section,
                                                                        state,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={state.criteria_field_option_value}
                                                    />
                                                </>

                                            ))}
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}
            </>
        )
    }

    return (
        <>
            <Popover
                open={deletePopover.open}
                anchorEl={deletePopover.anchorEl}
                onClose={() => {
                    // setDeletePopover({ open: false, chip: null, anchorEl: null })
                    setDeletePopover({ ...deletePopover, open: false });
                    setTimeout(() => {
                        setDeletePopover({ open: false, chip: null, anchorEl: null });
                    }, 300);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    zIndex: 2500,
                    ".MuiPaper-root": {
                        width: "450px",
                        padding: "10px",
                        marginTop: "15px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        position: "relative"
                    },
                }}
            >
                <Grid container>
                    <Grid item xs={12} sx={{ padding: '15px', }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: "end", position: "absolute", top: "0px", right: "0px" }}>
                                <IconButton
                                    onClick={() => {
                                        setDeletePopover({ ...deletePopover, open: false });
                                        setTimeout(() => {
                                            setDeletePopover({ open: false, chip: null, anchorEl: null });
                                        }, 300);
                                    }}
                                >
                                    <CloseIcon
                                        size="small"
                                        sx={{ fontSize: "15px", color: "black" }}
                                    />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                    <Grid item sx={{ maxWidth: "45px" }}>
                                        <img src={warningAlert} alt='Warning!' style={{ width: "100%", height: "100%" }} />
                                    </Grid>
                                    <Grid item sx={{ width: "calc(100% - 55px)", maxWidth: "calc(100% - 55px)" }}>
                                        <Typography variant="span" >
                                            Are you sure you want to delete Issue Age for {deletePopover.chip?.name ? deletePopover.chip?.name : ""}?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'center', gap: "20px", marginTop: "10px" }}>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                                background: "#fff",
                                                color: "#FF6174",
                                                border: "1px solid #FF6174",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "1px solid #FF6174"
                                                }
                                            }}
                                            onClick={() => {
                                                const chip = deletePopover.chip; // Keep reference to the chip
                                                setDeletePopover({ ...deletePopover, open: false }); // Close the popover first
                                                setTimeout(() => {
                                                    deleteIssueAgeChip(chip); // Delete chip after popover closes
                                                    setDeletePopover({ open: false, chip: null, anchorEl: null }); // Reset state after deletion
                                                }, 300);
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                                setDeletePopover({ ...deletePopover, open: false });
                                                setTimeout(() => {
                                                    setDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                            sx={{
                                                backgroundColor: "#1565C0",
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none"
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>

            <Popover
                open={renewalYearDeletePopover.open}
                anchorEl={renewalYearDeletePopover.anchorEl}
                // onClose={() => setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null })}
                onClose={() => {
                    setRenewalYearDeletePopover({ ...renewalYearDeletePopover, open: false });
                    setTimeout(() => {
                        setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null });
                    }, 300);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    zIndex: 2500,
                    ".MuiPaper-root": {
                        width: "450px",
                        padding: "10px",
                        marginTop: "15px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        position: "relative"
                    },
                }}
            >
                <Grid container>
                    <Grid item xs={12} sx={{ padding: '15px' }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: "end", position: "absolute", top: "0px", right: "0px" }}>
                                <IconButton
                                    onClick={() => {
                                        setRenewalYearDeletePopover({ ...renewalYearDeletePopover, open: false });
                                        setTimeout(() => {
                                            setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                        }, 300);
                                    }}
                                >
                                    <CloseIcon
                                        size="small"
                                        sx={{ fontSize: "15px", color: "black" }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                    <Grid item sx={{ maxWidth: "45px" }}>
                                        <img src={warningAlert} alt='Warning!' style={{ width: "100%", height: "100%" }} />
                                    </Grid>
                                    <Grid item sx={{ width: "calc(100% - 55px)", maxWidth: "calc(100% - 55px)" }}>
                                        <Typography variant="span">
                                            Are you sure you want to delete Renewal Year for {renewalYearDeletePopover.chip?.name ? renewalYearDeletePopover.chip?.name : ""}?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'center', gap: "20px" }}>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                                background: "#fff",
                                                color: "#FF6174",
                                                border: "1px solid #FF6174",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "1px solid #FF6174",
                                                },
                                            }}
                                            onClick={() => {
                                                const chip = renewalYearDeletePopover.chip;
                                                setRenewalYearDeletePopover({ ...renewalYearDeletePopover, open: false });
                                                setTimeout(() => {
                                                    deleteRenewalYearChip(chip);
                                                    setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                                setRenewalYearDeletePopover({ ...renewalYearDeletePopover, open: false });
                                                setTimeout(() => {
                                                    setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                            sx={{
                                                backgroundColor: "#1565C0",
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>

            <Popover
                open={excessYearDeletePopover.open}
                anchorEl={excessYearDeletePopover.anchorEl}
                // onClose={() => setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null })}
                onClose={() => {
                    setExcessYearDeletePopover({ ...excessYearDeletePopover, open: false });
                    setTimeout(() => {
                        setExcessYearDeletePopover({ open: false, chip: null, anchorEl: null });
                    }, 300);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    zIndex: 2500,
                    ".MuiPaper-root": {
                        width: "450px",
                        padding: "10px",
                        marginTop: "15px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        position: "relative"
                    },
                }}
            >
                <Grid container>
                    <Grid item xs={12} sx={{ padding: '15px' }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: "end", position: "absolute", top: "0px", right: "0px" }}>
                                <IconButton
                                    onClick={() => {
                                        setExcessYearDeletePopover({ ...excessYearDeletePopover, open: false });
                                        setTimeout(() => {
                                            setExcessYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                        }, 300);
                                    }}
                                >
                                    <CloseIcon
                                        size="small"
                                        sx={{ fontSize: "15px", color: "black" }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                    <Grid item sx={{ maxWidth: "45px" }}>
                                        <img src={warningAlert} alt='Warning!' style={{ width: "100%", height: "100%" }} />
                                    </Grid>
                                    <Grid item sx={{ width: "calc(100% - 55px)", maxWidth: "calc(100% - 55px)" }}>
                                        <Typography variant="span">
                                            Are you sure you want to delete Excess Year for {excessYearDeletePopover.chip?.name ? excessYearDeletePopover.chip?.name : ""}?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'center', gap: "20px" }}>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                                background: "#fff",
                                                color: "#FF6174",
                                                border: "1px solid #FF6174",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "1px solid #FF6174",
                                                },
                                            }}
                                            onClick={() => {
                                                const chip = excessYearDeletePopover.chip;
                                                setExcessYearDeletePopover({ ...excessYearDeletePopover, open: false });
                                                setTimeout(() => {
                                                    deleteExcessYearChip(chip);
                                                    setExcessYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                                setExcessYearDeletePopover({ ...excessYearDeletePopover, open: false });
                                                setTimeout(() => {
                                                    setExcessYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                            sx={{
                                                backgroundColor: "#1565C0",
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>

            <Popover
                open={policyYearDeletePopover.open}
                anchorEl={policyYearDeletePopover.anchorEl}
                // onClose={() => setRenewalYearDeletePopover({ open: false, chip: null, anchorEl: null })}
                onClose={() => {
                    setPolicyYearDeletePopover({ ...policyYearDeletePopover, open: false });
                    setTimeout(() => {
                        setPolicyYearDeletePopover({ open: false, chip: null, anchorEl: null });
                    }, 300);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    zIndex: 2500,
                    ".MuiPaper-root": {
                        width: "450px",
                        padding: "10px",
                        marginTop: "15px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                        position: "relative"
                    },
                }}
            >
                <Grid container>
                    <Grid item xs={12} sx={{ padding: '15px' }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: "end", position: "absolute", top: "0px", right: "0px" }}>
                                <IconButton
                                    onClick={() => {
                                        setPolicyYearDeletePopover({ ...policyYearDeletePopover, open: false });
                                        setTimeout(() => {
                                            setPolicyYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                        }, 300);
                                    }}
                                >
                                    <CloseIcon
                                        size="small"
                                        sx={{ fontSize: "15px", color: "black" }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                    <Grid item sx={{ maxWidth: "45px" }}>
                                        <img src={warningAlert} alt='Warning!' style={{ width: "100%", height: "100%" }} />
                                    </Grid>
                                    <Grid item sx={{ width: "calc(100% - 55px)", maxWidth: "calc(100% - 55px)" }}>
                                        <Typography variant="span">
                                            Are you sure you want to delete Policy Year for {policyYearDeletePopover.chip?.name ? policyYearDeletePopover.chip?.name : ""}?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'center', gap: "20px" }}>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                                background: "#fff",
                                                color: "#FF6174",
                                                border: "1px solid #FF6174",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                                "&:hover": {
                                                    border: "1px solid #FF6174",
                                                },
                                            }}
                                            onClick={() => {
                                                const chip = policyYearDeletePopover.chip;
                                                setExcessYearDeletePopover({ ...policyYearDeletePopover, open: false });
                                                setTimeout(() => {
                                                    deletePolicyYearChip(chip);
                                                    setPolicyYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item sx={{ width: "100px", maxWidth: "100px" }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            onClick={() => {
                                                setPolicyYearDeletePopover({ ...policyYearDeletePopover, open: false });
                                                setTimeout(() => {
                                                    setPolicyYearDeletePopover({ open: false, chip: null, anchorEl: null });
                                                }, 300);
                                            }}
                                            sx={{
                                                backgroundColor: "#1565C0",
                                                color: "#fff",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                textTransform: "none",
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>


            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='cd-header-item'>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography variant='span' className='cd-product-head'>
                                        Product Name: <Typography variant='span' className='cd-product-text'> {productName || ""} </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: "center" }}>
                                    <Typography variant='span' className='cd-head-text'>
                                        Criteria
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ padding: "20px 20px 0px 20px" }}>
                            <Grid container>
                                <Grid item className='cd-gbl-wrapper'>
                                    <Grid container sx={{ flexDirection: "row", justifyContent: 'space-between' }}>
                                        {/* {criteriaGenericData && criteriaGenericData?.map((section) => renderSection(section))} */}
                                        {criteriaGenericData && criteriaGenericData?.map((section) => (
                                            <Grid item xs={section?.criteria_name === 'Sub Type' || section?.criteria_name === 'Type' ? 5.9 : 12} key={section.criteria_id}>
                                                <Grid container>
                                                    {renderSection(section)}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: "20px 0px 20px" }}>
                            <Grid container sx={{ justifyContent: 'center', gap: '20px' }}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        size='medium'
                                        className='cd-close-btn'
                                        onClick={() => {
                                            setCarrierProductCriteria([])
                                            setSelectedStates([])
                                            setIsAllSelected(false)
                                            setIssueAgeTempValues({
                                                fromAge: "",
                                                toAgeOption: null,
                                                toAgeValue: "",
                                                carrier_product_criteria_id: null,
                                                carrier_product_criteria_id_custom: null
                                            })
                                            setRenewalYearTempValues({
                                                fromAge: "",
                                                toAgeOption: null,
                                                toAgeValue: "",
                                                carrier_product_criteria_id: null,
                                                carrier_product_criteria_id_custom: null
                                            })
                                            setFlagCriteriDialog(false)

                                        }}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size='medium'
                                        className='cd-Add-btn'
                                        disabled={!isAddButtonEnabled}
                                        onClick={() => {
                                            saveCriteareDetails(carrierProductCriteria, productIds)
                                            setFlagCriteriDialog(false)
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CriteriaDialog