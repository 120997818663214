import React, { useContext } from 'react'
import '../../css/BaTeam.css'

import { Close } from "@mui/icons-material";
import deleteAlert from '../../../assets/Delete-alert.png'
import { assignAgencyManagerToUser, GetBAMemberList } from '../../../utils/api/BATeamApi';
import { Button, Grid } from '@mui/material';
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";



function AssignAgencyDialog(props) {

    const {
        setUserList,
        setGlobalUserList,
        setFlagOpenAssignAgencyDialog,
        selectedUserId,
    } = props

    const { FlagApiLoader, UpdateSnackbarMessage, } = useContext(AuthContext);

    const handleAssignAgency = () => {

        FlagApiLoader(true)
        let payload = {
            user_id: selectedUserId
        }

        assignAgencyManagerToUser(payload,
            (res) => {
                if (res?.data?.status) {
                    console.log("Response of assignAgencyManagerToUser", res);
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message ? res?.data?.data?.message : 'Agency Manager role assigned successfully.'
                    });
                    GetBAMemberList(
                        (res) => {
                            if (res.data.status) {
                                console.log("response true of GetBAMemberList api", res);
                                const userList = res?.data?.data
                                setUserList(userList)
                                setGlobalUserList(userList)
                                FlagApiLoader(false)
                            }
                            else {
                                console.log("response false of GetBAMemberList api", res);
                                UpdateSnackbarMessage({
                                    status: "error",
                                    message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                                });
                                FlagApiLoader(false)
                            }
                        },
                        (err) => {
                            FlagApiLoader(false)
                            console.log("error of GetBAMemberList api", err);
                            UpdateSnackbarMessage({
                                status: "error",
                                message: "Something Went Wrong!",
                            });
                        }
                    )
                    setFlagOpenAssignAgencyDialog(false)
                    FlagApiLoader(false)
                }
                else {
                    console.log("response false of assignAgencyManagerToUser api", res);
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                        });
                    }
                    setFlagOpenAssignAgencyDialog(false)
                    FlagApiLoader(false)
                }
            },
            (err) => {
                console.log("Error Response of assignAgencyManagerToUser", err);
                setFlagOpenAssignAgencyDialog(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
            }
        )
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container className="delete-main-container">
                    <Grid item xs={11.5} textAlign={"end"}>
                        <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagOpenAssignAgencyDialog(false) }} />
                    </Grid>
                    <Grid item xs={12} className="delete-alert-item">
                        <img src={deleteAlert}></img>
                    </Grid>
                    <Grid item xs={12} className="delete-mesg-item">
                        Are you sure you want to assign Agency Manager role to this user?
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="delete-btn-container">
                            <Grid container className="popup-delete-button-container" >
                                <Button
                                    variant="contained"
                                    sx={{ width: "100px" }}
                                    onClick={() => {
                                        handleAssignAgency()
                                    }}
                                >
                                    Yes
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ width: "100px" }}
                                    onClick={() => { setFlagOpenAssignAgencyDialog(false); }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AssignAgencyDialog