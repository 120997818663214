import React from 'react'
import { Button, Grid, MenuItem, Popover, Select, TextField, Typography } from '@mui/material';
import '../../css/ViewCarriers.css'
import '../../css/ViewCarriarStyles/LevelCustomizationDialog.css'

function ColumnLevelPopover(props) {

    const {
        columnId, columnCellPopoverOpen, anchorElForLevelsColumn, setAnchorElForLevelsColumn,
        carrierDetails, PathName, newLevelData, setLevelSelectedData, emptyLevelName, setNewLevelData, colorList, setFlagAfterLevelPopoverRender,
        setEmptyLevelName, handleAddNewLevel

    } = props

    return (
        <>
            <div>
                <Popover
                    id={columnId}
                    open={columnCellPopoverOpen}
                    anchorEl={anchorElForLevelsColumn.type}
                    // onClose={handleCloseColumnPopover}
                    onClose={() => { setAnchorElForLevelsColumn({ type: null, columnId: 0 }); }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: (carrierDetails?.carrier_level?.length - anchorElForLevelsColumn?.columnId) <= 6 ? 'left' : "right"
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: PathName === "/carriers/view_carrier" ? (carrierDetails?.carrier_level?.length - anchorElForLevelsColumn?.columnId <= 6 ? 'right' : "left") : "left"
                    }}
                    PaperProps={{
                        style: {
                            padding: "8px 15px 10px",
                            width: '300px',
                            borderRadius: '10px',
                        }
                    }}
                    className="vw-action-popover"
                    transitionDuration={500}
                >
                    <Grid container>
                        <Grid item xs={12} sx={{ padding: "10px" }}>
                            <Grid container>
                                {
                                    carrierDetails?.carrier_level?.length != 0 &&
                                    <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>After Level</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    fullWidth
                                                    variant="standard"
                                                    size="medium"
                                                    value={newLevelData?.carrier_level_after}
                                                    onChange={(e) => {
                                                        setLevelSelectedData({
                                                            name: e.target.value,
                                                            value: e.target.value
                                                        })
                                                    }}
                                                >
                                                    {carrierDetails?.carrier_level?.map((level) => (
                                                        <MenuItem value={level.carrier_level_id || level.carrier_level_id_custom} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Level Name</Typography>
                                            <Typography variant="span" sx={{ color: "red", fontSize: "12px", fontWeight: "500" }}>*</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                size="small"
                                                variant="standard"
                                                fullWidth
                                                error={emptyLevelName}
                                                value={newLevelData.carrier_level_name}
                                                onChange={(e) => {
                                                    setNewLevelData({ ...newLevelData, carrier_level_name: e.target.value })
                                                }}
                                            ></TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Select Color</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                fullWidth
                                                variant="standard"
                                                size="medium"
                                                MenuProps={{ sx: { zIndex: 2002 } }}
                                                value={{
                                                    carrier_level_color_hex_code: newLevelData?.carrier_level_color_hex_code,
                                                    carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
                                                    carrier_level_color_name: newLevelData?.carrier_level_color_name,
                                                }}
                                                renderValue={() =>
                                                    <div>
                                                        <span className='lcd-dropdown-colorbox' style={{ background: newLevelData.carrier_level_color_hex_code }}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                        <span>{newLevelData.carrier_level_color_name}</span>
                                                    </div>}
                                                onChange={(e) => {
                                                    setNewLevelData({ ...newLevelData, carrier_level_color_id: Number(e.target.value?.carrier_level_color_id), carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code, carrier_level_color_name: e.target.value.carrier_level_color_name })
                                                }}
                                            >
                                                {colorList?.map((color) => {
                                                    return (
                                                        <MenuItem
                                                            value={{
                                                                carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                                                carrier_level_color_id: Number(color.carrier_level_color_id),
                                                                carrier_level_color_name: color.carrier_level_color_name,
                                                            }}
                                                        >
                                                            <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}></span>
                                                            <span value={color}>{color?.carrier_level_color_name}</span>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "25px 0px 10px" }}>
                                    <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                        <Grid item xs={5}>
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                                fullWidth
                                                onClick={() => {
                                                    setFlagAfterLevelPopoverRender(false)
                                                    setAnchorElForLevelsColumn({ type: null, columnId: 0 });
                                                    // handleCloseColumnPopover();
                                                    setNewLevelData({
                                                        carrier_level_after: null,
                                                        carrier_level_id: null,
                                                        carrier_level_name: "",
                                                        carrier_ba_level_flag: false,
                                                        // carrier_level_sequence: 1,
                                                        carrier_level_color_id: 1,
                                                        carrier_level_color_hex_code: "#1b2430",
                                                        carrier_level_color_name: "Default"
                                                    })
                                                    setEmptyLevelName(false)
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                fullWidth
                                                onClick={() => { handleAddNewLevel() }}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Grid>
                </Popover>
            </div>
        </>
    )
}

export default ColumnLevelPopover