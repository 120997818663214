import React from 'react'
import { Dialog, Grid } from '@mui/material'
// import './GlobalDialog.css'
import { FocusTrap } from "@mui/base";
import "./GlobalDialog.css"

const GlobalDialog = (props) => {
  return (
    <>
      {/* max-width='xs','sm','md','lg','xl',false,string */}
      <FocusTrap open={true} disableEnforceFocus={true}>
        <Dialog
          maxWidth={props.maxWidth ? props.maxWidth : "sm"}
          fullWidth={true}
          className={`${props.className} main-global-dialog`}
          open={props.open}
          onClose={props.onClose}
          sx={props.sxDialog}
          fullScreen={props.fullScreen ? props.fullScreen : false}
          disableEnforceFocus={true}
          disableAutoFocus={props.disableAutoFocus}
        >
          <Grid className='shuh=524' container justifyContent="center" alignItems="center">
            {props.data}
          </Grid>
        </Dialog>
      </FocusTrap>
    </>
  );
};

export default GlobalDialog;
