import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Avatar, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import BaLogo from "../../assets/balogo.png";
import { AddAgencyLogo } from "../../utils/api/AgencyDetailsAPI";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import bgVideo from "../../assets/Global/signin-bg-video.mp4"
import '../../pages/css/Signin.css'
import '../../pages/css/template.css'

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 380px
  };
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 500px
  };
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 500px
  };
`;

const BigAvatar = styled(Avatar)`
  width: 252px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;
const LogoComponent = () => (
  <img
    src={BaLogo}
    alt="Logo"
    width="216px"
    height="92px"
    textAlign="center"
    justifyContent="center"
  />
);

function SignIn() {
  const { AgencyLogoAPI } = useContext(AuthContext);
  const { agencyLogo } = useContext(AuthContext).state


  // console.log("agencyLogo", agencyLogo);
  // useEffect(() => {
  //   AgencyLogoAPI()
  // }, [])

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <div className="signup-card" >
        <Helmet title="myAdvisorGrids | Sign In" />

        <Grid container sx={{ width: "100%" }} className="sign-in-page-container" id="grid_container_auth_sign" justifyContent="center" >
          <video autoPlay muted loop preload="auto" load className="sign-in-video" >
            <source src={bgVideo} type="video/mp4" />
          </video>

          <Grid item className="sigin-form-main-item">
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid item xs={12}>
                <Grid container className="sigin-form-sub-container" >
                  <Grid item className="sign-in-agency-logo-item" >
                    {agencyLogo?.agency_name?.trim()?.toLowerCase() == "brokers alliance" ?
                      (
                        <Grid container sx={{ height: '100%' }}>
                          <Grid item xs={12} sx={{ height: '100%' }}>
                            <img
                              alt=""
                              src={BaLogo}
                              // src={sample}
                              className="sign-in-agency-logo"
                            />
                          </Grid>
                        </Grid>
                      ) :
                      (agencyLogo?.agency_logo_url !== null) ?
                        <img
                          alt="Company Logo"
                          src={agencyLogo?.agency_logo_url}
                          className="sign-in-agency-logo"
                        /> :
                        (<></>)
                    }
                  </Grid>
                  <Grid item xs={12} >
                    <SignInComponent />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default SignIn;


