import { Button, Checkbox, Divider, FormControlLabel, Grid, List, ListItem, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import '../../../css/Agency/AgencyDialogs/AgencyCloneTemplateDialog.css'
import { Close } from '@mui/icons-material'
import restriction from '../../../../assets/agency/agtc-restriction-icon.svg'
import { getRemaingCarrierListAPI, GetTemplateDataAPI } from '../../../../utils/api/AgencyDetailsAPI'
import { Context as agencyContext } from '../../../../contexts/reducerContexts/agencyContext';
import { Context as AuthContext } from '../../../../contexts/reducerContexts/authContext'
import { CreateTemplate } from '../../../../utils/api/TemplateApi'


const AgencyCloneTemplateDialog = (props) => {

    // AGENCY CONTEXT
    const {
        setAgencyTemplateDetails,
        setCloneTemplateDetails
    } = useContext(agencyContext)

    const {
        createAgencyId,
        agencyTemplateDetails,
        agencyDetails,
        cloneTemplateDetails,
    } = useContext(agencyContext).state

    // AUTH CONTEXT
    const { userDetail, } = useContext(AuthContext).state
    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(AuthContext)

    // PROPS
    const {
        fatchCloneTemplateDetails,
        setFlagOpenCloneDialog,
    } = props


    // VARIABLES
    const [agencyCarrierLevelsData, setAgencyCarrierLevelsData] = useState(null)
    const [flagClonePossible, setFlagClonePossible] = useState(null)
    const [newTemplateDetails, setNewTemplateDetails] = useState({
        template_name: null
    })
    const [cloneErrorMessage, setCloneErrorMessage] = useState('')
    const [flagTemplateDataLoaded, setFlagTemplateDataLoaded] = useState(false)
    const isButtonDisabled = !flagTemplateDataLoaded || !newTemplateDetails?.template_name?.trim();

    // console.log("userDetails", userDetail);
    // console.log("agencyDetails", agencyDetails);
    // console.log("fatchCloneTemplateDetails", fatchCloneTemplateDetails);
    // console.log("agencyCarrierLevelsData", agencyCarrierLevelsData);
    // console.log("cloneTemplateDetails", cloneTemplateDetails);

    useEffect(() => {

        let payload = {
            agency_id: createAgencyId,
            template_id: fatchCloneTemplateDetails?.template_id
        }

        let templatePayload = {
            agency_id: userDetail[0]?.user_agency_data?.agency_id,
            template_id: fatchCloneTemplateDetails?.template_id
        }

        getRemaingCarrierListAPI(payload,
            (res) => {
                console.log("response of getRemaingCarrierListAPI", res);
                if (res?.data?.status) {
                    if (res?.data?.data[0].is_template_clone_possible_flag) {
                        GetTemplateDataAPI(templatePayload,
                            (res) => {
                                if (res?.data?.status) {
                                    console.log("response of GetTemplateDataAPI", res);
                                    setCloneTemplateDetails(res.data.data[0])
                                    setFlagTemplateDataLoaded(true)
                                }
                                else {
                                    console.log("false response of GetTemplateDataAPI", res);
                                    if (res?.data?.error?.message) {
                                        UpdateSnackbarMessage({
                                            status: "error",
                                            message: res?.data?.error?.message
                                        })
                                    }
                                    else {
                                        UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Something Went Wrong!"
                                        })
                                    }
                                }
                            },
                            (err) => {
                                console.log("response error of GetTemplateDataAPI", err);
                                if (err?.message) {
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: err?.message
                                    })
                                }
                                else {
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: "Something Went Wrong!"
                                    })
                                }
                            })
                        setFlagClonePossible(true)
                    }
                    else {
                        setAgencyCarrierLevelsData(res?.data?.data[0])
                        setFlagClonePossible(false)
                    }
                }
                else {
                    console.log("false response of getRemaingCarrierListAPI", res);
                    setFlagClonePossible(false)
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                    }
                }

            },
            (err) => {
                console.log("error response of getRemaingCarrierListAPI", err);
                if (err?.message) {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: err?.message
                    })
                }
                else {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    })
                }
            }
        )
    }, [])


    const createNewCloneTemplate = () => {

        let payload = {
            template_name: newTemplateDetails?.template_name,
            agency_id: createAgencyId,
            carrier_type_id: cloneTemplateDetails?.carrier_type_id,
            template_data: cloneTemplateDetails?.template_data
        }

        FlagApiLoader(true)
        CreateTemplate(
            payload,
            (res) => {
                if (res?.data.status) {
                    console.log("true response of create clone template", res);
                    FlagApiLoader(false)
                    setFlagOpenCloneDialog(false)
                    UpdateSnackbarMessage({
                        status: "success",
                        message: "Template Clone Successfully!"
                    })
                }
                else {
                    console.log("false response of create clone template", res);
                    setCloneErrorMessage(res?.data?.error?.message)
                    FlagApiLoader(false)
                }
            },
            (err) => {
                console.log("error response of create clone template", err);
                FlagApiLoader(false)
                if (err?.message) {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: err?.message
                    })
                    setFlagOpenCloneDialog(false)
                }
                else {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    })
                    setFlagOpenCloneDialog(false)
                }
            }
        )

    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='agct-dialog-header-item'>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10} sx={{ textAlign: 'center' }}>
                                    <Typography variant='span'>
                                        Use This Template?
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                                    <Close
                                        sx={{ cursor: 'pointer', fontSize: '20px', color: 'black' }}
                                        onClick={() => {
                                            setFlagOpenCloneDialog(false)
                                            setFlagClonePossible(null)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={11} className='agtc-dialog-content-item'>
                            {
                                flagClonePossible != null &&
                                <Grid container>
                                    {(flagClonePossible == true) &&
                                        (<>
                                            <Grid item xs={11} sx={{ margin: "0 auto" }}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ margin: '20px 0px 5px' }}>
                                                        <Grid container>
                                                            <Grid item sx={{ width: '80px', textAlign: 'start' }}>
                                                                <span className='agtc-temp-label'>Agency</span>
                                                            </Grid>
                                                            <Grid item >
                                                                <span className='agtc-temp-value-label'>{agencyDetails?.agency_name ? agencyDetails?.agency_name : ''}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sx={{ margin: '5px 0px 10px' }}>
                                                        <Grid container>
                                                            <Grid item sx={{ width: '80px', textAlign: 'start' }}>
                                                                <span className='agtc-temp-label'>Template</span>
                                                            </Grid>
                                                            <Grid item >
                                                                <span className='agtc-temp-value-label'>
                                                                    {cloneTemplateDetails?.template_name ? cloneTemplateDetails?.template_name : ''}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} className='agtc-fields-main-item'>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Grid container className={cloneErrorMessage != '' && 'agtc-hgihlight-border-error'} sx={{ alignItems: 'center', height: '100%', width: "100%" }}>
                                                                    <Grid item className='agtc-temp-name-item'>
                                                                        <Grid container sx={{ alignItems: 'center', padding: '0px 10px', height: '100%' }}>
                                                                            New Template Name
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item className='agtc-temp-textfield-item'>
                                                                        <TextField
                                                                            variant='outlined'
                                                                            size='medium'
                                                                            className='agtc-textfield'
                                                                            placeholder={"Enter Template Name"}
                                                                            InputProps={{
                                                                                disableUnderline: true
                                                                            }}
                                                                            onFocus={() => { setCloneErrorMessage('') }}
                                                                            value={newTemplateDetails?.template_name}
                                                                            onChange={(e) => {
                                                                                setNewTemplateDetails({
                                                                                    ...newTemplateDetails,
                                                                                    template_name: e.target.value
                                                                                })
                                                                            }}
                                                                            autoComplete='off'
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid item sx={{ width: "155px" }}></Grid>
                                                                    <Grid item sx={{ textAlign: 'start' }} className='agtc-temp-textfield-item'>
                                                                        {cloneErrorMessage != '' &&
                                                                            <Grid item xs={12} sx={{ marginTop: "5px" }}>
                                                                                <Typography variant='span' className='agtc-error-mesg'>
                                                                                    {cloneErrorMessage}
                                                                                </Typography>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    <div style={{ cursor: 'not-allowed' }}>
                                                        <Grid item xs={12} sx={{ textAlign: 'start', marginLeft: '5px', pointerEvents: 'none', cursor: 'not-allowed', opacity: '0.6' }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size='small'
                                                                        sx={{ padding: '5px', }}
                                                                    />}
                                                                label={`Do you want this template to remain synced with ${userDetail[0].user_agency_data.agency_name}?`}
                                                            />
                                                        </Grid>
                                                    </div>

                                                    <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                        <span style={{
                                                            fontSize: '13px',
                                                            fontWeight: '500',
                                                            color: '#FF4848'
                                                        }}
                                                        >
                                                            Note: Once this template is clone it will not remain linked with original&nbsp;
                                                            <span style={{ color: '#000000' }}>{cloneTemplateDetails?.template_name ? `${cloneTemplateDetails?.template_name}` : ''}</span>
                                                        </span>
                                                    </Grid>

                                                    <Grid item xs={12} sx={{ margin: '15px 0px' }}>
                                                        <Grid container sx={{ justifyContent: 'center', gap: '15px' }}>
                                                            <Grid item>
                                                                <Button
                                                                    variant='outlined'
                                                                    size='medium'
                                                                    fullWidth
                                                                    className='agtc-outlined-btn'
                                                                    sx={{
                                                                        width: '100px',
                                                                        maxWidth: '100px',
                                                                        padding: '5px !important'
                                                                    }}
                                                                    onClick={() => { setFlagOpenCloneDialog(false) }}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    variant='contained'
                                                                    size='medium'
                                                                    fullWidth
                                                                    className='agtc-contained-btn'
                                                                    sx={{
                                                                        width: '100px',
                                                                        maxWidth: '100px',
                                                                        padding: '5px !important',
                                                                        backgroundColor: isButtonDisabled ? '#1565C0' : '#1565C0', // Default and disabled colors
                                                                        opacity: isButtonDisabled ? 0.5 : 1, // Reduced opacity when disabled
                                                                        cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                                                                        '&.Mui-disabled': {
                                                                            backgroundColor: '#1565C0', // Ensures the background color when disabled
                                                                            color: '#fff', // You can also adjust the text color here if needed
                                                                        }

                                                                    }}
                                                                    disabled={isButtonDisabled}
                                                                    onClick={() => {
                                                                        createNewCloneTemplate()
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>)
                                    }

                                    {(flagClonePossible == false) &&
                                        (<>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ textAlign: 'center', margin: '20px 0px' }}>
                                                        <img
                                                            src={restriction}
                                                            style={{
                                                                width: '70px',
                                                                height: 'auto'
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                        <Typography
                                                            variant='span'
                                                            sx={{
                                                                fontSize: '13px',
                                                                fontWeight: '500',
                                                                color: '#FF4848'
                                                            }}
                                                        >
                                                            This template
                                                            <span style={{ color: 'black' }}>&nbsp;{fatchCloneTemplateDetails?.template_name ? fatchCloneTemplateDetails?.template_name : ''}&nbsp;</span>
                                                            can't be cloned because below carriers or their levels are not assigned to the agency.
                                                        </Typography>
                                                    </Grid>

                                                    <Divider style={{ width: '100%', margin: '10px 0px' }} />

                                                    {agencyCarrierLevelsData?.remaining_carrier_data_arr?.length > 0 ?
                                                        (
                                                            <Grid item xs={12} sx={{ textAlign: 'start', margin: '10px 0px' }}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant='span' className='agtc-temp-heading-text'>
                                                                            Following carriers are not available
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12} sx={{ maxHeight: '9vh', overflow: 'auto', margin: '10px 0px' }}>
                                                                        <Grid container sx={{ justifyContent: 'start', }}>
                                                                            {agencyCarrierLevelsData?.remaining_carrier_data_arr?.length &&
                                                                                agencyCarrierLevelsData?.remaining_carrier_data_arr?.map((ele, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Grid item >
                                                                                                <Grid container sx={{ margin: '5px 0px', }}>
                                                                                                    <Typography
                                                                                                        variant='span'
                                                                                                        sx={{
                                                                                                            textOverflow: 'ellipsis',
                                                                                                            overflow: 'hidden',
                                                                                                            whiteSpace: "nowrap",
                                                                                                            maxWidth: '180px',
                                                                                                            minWidth: '180px',
                                                                                                            margin: '0px 5px'
                                                                                                        }}
                                                                                                    ><span>{index + 1}.</span>&nbsp;{ele?.carrier_name} </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (<></>)
                                                    }


                                                    {agencyCarrierLevelsData?.remaining_carrier_level_arr.length > 0 ?
                                                        (
                                                            <Grid item xs={12} sx={{ textAlign: 'start', margin: '10px 0px' }}>
                                                                <Grid container>

                                                                    <Grid item xs={12}>
                                                                        <Typography variant='span' className='agtc-temp-heading-text'>
                                                                            Following carrier levels are not available
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <Grid container>
                                                                            <List sx={{ width: '100%', maxHeight: '15vh', overflow: 'auto' }}>
                                                                                {agencyCarrierLevelsData?.remaining_carrier_level_arr.length && agencyCarrierLevelsData?.remaining_carrier_level_arr.map((carrier, index) => (
                                                                                    <ListItem key={carrier.carrier_id} sx={{ padding: '5px 0', display: 'flex', flexDirection: 'column' }}>
                                                                                        <Typography variant="body1" sx={{ mb: 1, pb: 1, width: '98%', borderBottom: '1px solid #ccc' }}>
                                                                                            {index + 1}. {carrier.carrier_name}
                                                                                        </Typography>
                                                                                        <Grid item xs={12} sx={{ margin: '5px 10px', width: '100%', textAlign: "start" }}>
                                                                                            <Grid container sx={{ justifyContent: 'start' }}>
                                                                                                {carrier.carrier_level_arr.map((level) => (
                                                                                                    <Grid item key={level.carrier_level_id}>
                                                                                                        <Typography
                                                                                                            variant="body2"
                                                                                                            sx={{ backgroundColor: '#f0f0f0', padding: '2px 10px', borderRadius: '4px', margin: '0px 5px 5px' }}>
                                                                                                            {level.carrier_level_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                ))}
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    </ListItem>
                                                                                ))}
                                                                            </List>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                        )
                                                        : (<></>)
                                                    }

                                                    <Grid item xs={12} sx={{ textAlign: 'center', margin: '25px 0px 0px' }}>
                                                        <Button
                                                            variant='contained'
                                                            size='medium'
                                                            className='agtc-contained-btn'
                                                            sx={{ width: '100px', padding: '5px 5px !important' }}
                                                            onClick={() => {
                                                                setFlagOpenCloneDialog(false)
                                                            }}
                                                        >
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>)
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyCloneTemplateDialog