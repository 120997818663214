import { FormControl, IconButton, Menu, MenuItem, Select, TableCell, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import { MoreVert } from '@mui/icons-material';
import { Link, useNavigate } from "react-router-dom";

export const GlobalCarrierTableRow = (props) => {

    const { row, ChangeDirectLevel, ChangeBaLevel, DLLength, i, DisableEdit, ChangeDlSection, anchorEl, handleClick, handleClose, setFlagDeletePopup, open, EnableEdit, selectedTab } = props
    const { carrierLevelData, carrierAnnuityLevelData, carrierLifeLevelData, globalCarrierLevelDetails } = useContext(globalCarrierContext).state;
    const navigate = useNavigate();

    const handleGiveLevelData = () => {
        // Step 1: Filter the data to find entries where the color is "#000000"
        let finalLevelData = globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]
            ?.filter(carrier => carrier.carrier_id == row.carrier_types[0].carrier_id)
            ?.flatMap(carrier => carrier.carrier_level_data)
            ?.filter(level => level.carrier_level_color_hex_code == "#000000");

        // Step 2: If there are any results with color "#000000", return those
        if (finalLevelData?.length > 0) {
            return finalLevelData;
        } else {
            // Step 3: If no "#000000" entries exist, return all the data (without filtering by color)
            finalLevelData = globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.filter(carrier => carrier.carrier_id == row.carrier_types[0].carrier_id)?.flatMap(carrier => carrier.carrier_level_data);
        }

        // Step 4: Return the data, either filtered or the full list, or an empty array if nothing is found
        return finalLevelData?.length > 0 ? finalLevelData : [];
    };


    return (
        row.edit_flag == true ?
            (
                <TableRow
                    key={row.carrier_name}
                    className="carrier_table_row"
                >
                    {/* Serial Number */}
                    <TableCell
                        className="sticky-table-body sticky-table-body-1"
                        stickyHeader
                    >
                        {i + 1}
                    </TableCell>

                    {/* Carrier */}
                    <TableCell
                        align="left"
                        className="sticky-table-body sticky-table-body-2 clickable_item"
                        stickyHeader
                    // onClick={() => {
                    //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
                    // }}
                    >
                        <Link
                            target="_blank"
                            className="clickable_item"
                            style={{ textDecoration: "none" }}
                            to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`} >
                            {row.carrier_name}
                        </Link>
                    </TableCell>

                    {/* BA Level */}
                    <TableCell
                        align="center"
                        className="sticky-table-body sticky-table-body-3"
                        stickyHeader
                    >
                        <FormControl m={2}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className="ba-level"
                                size="small"
                                value={row?.baLevel ? row?.baLevel : ""}
                                MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
                                // defaultValue={row?.baLevel}
                                renderValue={(val) => { return row?.baLevel ? val.carrier_level_name : "" }}
                                onChange={(e) => {
                                    // console.log(e.target.value)
                                    // if (e.target.value) {
                                    ChangeBaLevel(e, row)
                                    // }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                            >

                                <MenuItem value={""}>
                                    {"None"}
                                </MenuItem>
                                {/* TEMPORARY MENU OPTIONS FOR CARRIER BA LEVEL */}
                                {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.map((menu) => {
                                    if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                        return menu?.carrier_ba_level_data.map(
                                            (name, index) => (
                                                <MenuItem key={index} value={name}>
                                                    {name.carrier_level_name}
                                                </MenuItem>
                                            )
                                        );
                                    }
                                })}

                                {/* THIS CODE AFTER FILTERATION */}
                                {/* {globalCarrierLevelDetails && handleGiveLevelData()?.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option.carrier_level_name}
                                    </MenuItem>
                                ))
                                } */}

                            </Select>
                        </FormControl>
                    </TableCell>

                    {/* Direct Carrier */}
                    <TableCell
                        align="center"
                        sx={{
                            borderRight: "2px solid white",
                        }}
                        className="sticky-table-body sticky-table-body-4"
                    >
                        <FormControl m={2}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className="ba-level"
                                size="small"
                                value={row?.directCarrier ? row?.directCarrier : ''}
                                renderValue={(val) => row?.directCarrier ? val.carrier_level_name : ""}
                                MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
                                onChange={(e) => {
                                    // if (e.target.value) {
                                    ChangeDirectLevel(e, row)
                                    // }
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                {/* TEMPORARY MENU OPTIONS FOR CARRIER BA LEVEL */}
                                <MenuItem value={""}>
                                    {"None"}
                                </MenuItem>
                                {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.map((menu) => {
                                    if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
                                        return menu?.carrier_direct_level_data?.map(
                                            (name, index) => (
                                                <MenuItem key={index} value={name}>
                                                    {name?.carrier_level_name}
                                                </MenuItem>
                                            )
                                        );
                                    }
                                })}
                                {/* TEMPORARY MENU OPTIONS FOR CARRIER BA LEVEL */}


                                {/* THIS CODE AFTER FILTERATION */}
                                {/* {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.filter(carrier => carrier.carrier_id == row.carrier_types[0].carrier_id)
                                    ?.flatMap(carrier => carrier.carrier_level_data)
                                    ?.filter(level => level.carrier_level_color_hex_code !== "#000000")
                                    ?.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option.carrier_level_name}
                                        </MenuItem>
                                    ))
                                } */}
                            </Select>
                        </FormControl>
                    </TableCell>


                    {/* DL SECTION UPDATED CODE  */}
                    {Array.from({ length: DLLength }).map((mm, j) => {
                        // const carrierLevels = globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.find(menu => menu.carrier_id === row.carrier_types[0]?.carrier_id)?.carrier_direct_level_data?.filter(level => level.carrier_level_color_hex_code !== "#000000") || [];
                        // const carrierLevels = globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.find(menu => menu.carrier_id == row.carrier_types[0]?.carrier_id)?.carrier_level_data?.filter(level => level.carrier_level_color_hex_code !== "#000000") || [];
                        return (
                            <>
                                <TableCell align="center">
                                    <FormControl m={2}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="ba-level"
                                            size="small"
                                            // value={row?.carrier_level_data[j] ? row?.carrier_level_data[j] : ""}
                                            // renderValue={(val) => row?.directCarrier ? val.carrier_level_name : ""}
                                            value={row?.carrier_level_data[j] || ""}
                                            renderValue={(val) => val?.carrier_level_name || "None"}
                                            onChange={(e) => {
                                                ChangeDlSection(e, row, j)
                                            }}
                                            MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
                                            IconComponent={KeyboardArrowDownIcon}
                                        >
                                            <MenuItem value={""}>
                                                {"None"}
                                            </MenuItem>
                                            {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.map((menu) => {
                                                if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
                                                    return menu?.carrier_direct_level_data?.map(
                                                        (name, index) => (
                                                            <MenuItem key={index} value={name}>
                                                                {name?.carrier_level_name}
                                                            </MenuItem>
                                                        )
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </>
                        );
                    })
                    }

                    {/* Menu Item */}
                    <TableCell className="sticky-table-body sticky-table-body-5">
                        <div>
                            <IconButton
                                onClick={(e) => {
                                    DisableEdit(row)
                                }}
                            >
                                <DoneIcon />
                            </IconButton>
                            <IconButton
                                onClick={(e) => { handleClick(e, row) }}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: '10ch',
                                    },
                                }}
                            >

                                <MenuItem onClick={() => {
                                    setFlagDeletePopup(true)
                                }}>
                                    Delete
                                </MenuItem>

                            </Menu>
                            {/*******************
                         *  <DeleteForeverIcon sx={{ cursor: "pointer", color: "red" }} onClick={() => {
                            setDeleteCarrierData(row);
                            setFlagDeletePopup(true)
                        }} /> 
                         *
                         ****************/}
                        </div>
                    </TableCell>
                </TableRow>
            ) : (
                <TableRow
                    key={row.carrier_name}
                    className="carrier_table_row"
                >
                    {/* Serial Number */}
                    <TableCell
                        className="sticky-table-body sticky-table-body-1"
                        stickyHeader
                    >
                        {i + 1}
                    </TableCell>

                    {/* Carrier */}
                    <TableCell
                        align="left"
                        className="sticky-table-body sticky-table-body-2 clickable_item"
                        stickyHeader
                    // onClick={() => {
                    //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
                    // }}
                    >
                        <Link
                            target="_blank"
                            className="clickable_item"
                            style={{ textDecoration: "none" }}
                            to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`} >
                            {row.carrier_name}
                        </Link>

                    </TableCell>

                    {/* BA Level */}
                    <TableCell
                        align="center"
                        className="sticky-table-body sticky-table-body-3"
                        stickyHeader
                    >
                        {row?.baLevel?.carrier_level_name}
                    </TableCell>

                    {/* Direct Carrier */}
                    <TableCell
                        align="center"
                        sx={{
                            borderRight: "2px solid white",
                        }}
                        className="sticky-table-body sticky-table-body-4"
                    >
                        {row?.directCarrier?.carrier_level_name}
                    </TableCell>

                    {/* DL Selection */}
                    {Array.from({ length: DLLength }).map((mm, j) => {
                        return (
                            <>
                                <TableCell
                                    align="center"
                                >
                                    {row?.carrier_level_data?.length ? row?.carrier_level_data[j]?.carrier_level_name : ""}
                                </TableCell>
                            </>
                        );
                    })}

                    {/* Menu Item */}
                    <TableCell
                        className="sticky-table-body sticky-table-body-5"
                    >
                        <div>
                            <IconButton
                                onClick={(e) => {
                                    EnableEdit(row)
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                onClick={(e) => {
                                    handleClick(e, row)
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5,
                                        width: '10ch',
                                    },
                                }}
                            >

                                <MenuItem onClick={() => {
                                    setFlagDeletePopup(true)
                                }}>
                                    Delete
                                </MenuItem>

                            </Menu>
                            {/**********************  
                         * <DeleteForeverIcon sx={{ cursor: "pointer", color: "red" }} onClick={() => {
                            setDeleteCarrierData(row);
                            setFlagDeletePopup(true)
                        }} 
                        /> 
                        *
                        *****************/}
                        </div>
                    </TableCell>
                </TableRow>
            )
    )
}




// OLD CODE BFR FILTER CARRIER LEVEL DATA BASED ON COLOR CODING AND UPDATED CARRIR LEVEL :-------------------------------------------------

// import { FormControl, IconButton, Menu, MenuItem, Select, TableCell, TableRow } from '@mui/material'
// import React, { useContext } from 'react'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import EditIcon from '@mui/icons-material/Edit';
// import DoneIcon from '@mui/icons-material/Done';
// import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
// import { MoreVert } from '@mui/icons-material';
// import { Link, useNavigate } from "react-router-dom";

// export const GlobalCarrierTableRow = (props) => {

//     const { row, ChangeDirectLevel, ChangeBaLevel, DLLength, i, DisableEdit, ChangeDlSection, anchorEl, handleClick, handleClose, setFlagDeletePopup, open, EnableEdit, selectedTab } = props
//     const { carrierLevelData, carrierAnnuityLevelData, carrierLifeLevelData, globalCarrierLevelDetails } = useContext(globalCarrierContext).state;
//     const navigate = useNavigate();

//     return (
//         row.edit_flag == true ?
//             <TableRow
//                 key={row.carrier_name}
//                 className="carrier_table_row"
//             >
//                 {/* Serial Number */}
//                 <TableCell
//                     className="sticky-table-body sticky-table-body-1"
//                     stickyHeader
//                 >
//                     {i + 1}
//                 </TableCell>

//                 {/* Carrier */}
//                 <TableCell
//                     align="left"
//                     className="sticky-table-body sticky-table-body-2 clickable_item"
//                     stickyHeader
//                 // onClick={() => {
//                 //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
//                 // }}
//                 >
//                     <Link
//                         target="_blank"
//                         className="clickable_item"
//                         style={{ textDecoration: "none" }}
//                         to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`} >
//                         {row.carrier_name}
//                     </Link>
//                 </TableCell>

//                 {/* BA Level */}
//                 <TableCell
//                     align="center"
//                     className="sticky-table-body sticky-table-body-3"
//                     stickyHeader
//                 >
//                     <FormControl m={2}>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             className="ba-level"
//                             size="small"
//                             value={row?.baLevel}
//                             MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
//                             // defaultValue={row?.baLevel}
//                             renderValue={(val) => { return row?.baLevel ? val.carrier_level_name : "" }}
//                             onChange={(e) => {
//                                 console.log(e.target.value)
//                                 if (e.target.value) {
//                                     ChangeBaLevel(e, row)
//                                 }
//                             }}
//                             IconComponent={KeyboardArrowDownIcon}
//                         >
//                             {/********** LATEST CODE : FOR FILTERING OPTION BY COLOR CODE  **********/}
//                             {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.filter(carrier => carrier.carrier_id == row.carrier_types[0].carrier_id)
//                                 ?.flatMap(carrier => carrier.carrier_level_data)
//                                 ?.filter(level => level.carrier_level_color_hex_code === "#000000")
//                                 ?.map((option, index) => (
//                                     <MenuItem key={index} value={option}>
//                                         {option.carrier_level_name}
//                                     </MenuItem>
//                                 ))
//                             }
//                             {/********** LATEST CODE : FOR FILTERING OPTION BY COLOR CODE  **********/}



//                             {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}
//                             {/* {(selectedTab == 'life' ? carrierLifeLevelData : selectedTab == 'annuity' ? carrierAnnuityLevelData : [])?.map((menu) => { */}
//                             {/* {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.map((menu) => {
//                                 if (menu.carrier_id == row.carrier_types[0].carrier_id) {
//                                     return menu?.carrier_ba_level_data.map(
//                                         (name, index) => (
//                                             <MenuItem key={index} value={name}>
//                                                 {name.carrier_level_name}
//                                             </MenuItem>
//                                         )
//                                     );
//                                 }
//                             })
//                             } */}
//                             {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}


//                             {/*************** OLD CODE : BEFORE UPDATED LIFE ANNUITY DROPDOWN ISSUE IN PRODUCTION
//                                carrierLevelData?.map((menu) => {
//                                     if (menu.carrier_id == row.carrier_types[0].carrier_id) {
//                                         return menu?.carrier_ba_level_data.map(
//                                             (name, index) => (
//                                                 <MenuItem key={index} value={name}>
//                                                     {name.carrier_level_name}
//                                                 </MenuItem>
//                                             )
//                                         );
//                                     }
//                                 })
//                             ***************/}
//                         </Select>
//                     </FormControl>
//                 </TableCell>

//                 {/* Direct Carrier */}
//                 <TableCell
//                     align="center"
//                     sx={{
//                         borderRight: "2px solid white",
//                     }}
//                     className="sticky-table-body sticky-table-body-4"
//                 >
//                     <FormControl m={2}>
//                         <Select
//                             labelId="demo-simple-select-label"
//                             id="demo-simple-select"
//                             className="ba-level"
//                             size="small"
//                             value={row?.directCarrier}
//                             renderValue={(val) => row?.directCarrier ? val.carrier_level_name : ""}
//                             MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
//                             onChange={(e) => {
//                                 if (e.target.value) {
//                                     ChangeDirectLevel(e, row)
//                                 }
//                             }}
//                             IconComponent={KeyboardArrowDownIcon}
//                         >
//                             {/********** LATEST CODE: FOR FILTERING OPTION BY COLOR CODE  **********/}
//                             {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.filter(carrier => carrier.carrier_id === row.carrier_types[0].carrier_id)
//                                 ?.flatMap(carrier => carrier.carrier_level_data)
//                                 ?.filter(level => level.carrier_level_color_hex_code !== "#000000")
//                                 ?.map((option, index) => (
//                                     <MenuItem key={index} value={option}>
//                                         {option.carrier_level_name}
//                                     </MenuItem>
//                                 ))
//                             }
//                             {/********** LATEST CODE : FOR FILTERING OPTION BY COLOR CODE  **********/}

//                             {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}

//                             {/* {globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.map((menu) => {
//                                 if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
//                                     return menu?.carrier_direct_level_data?.map(
//                                         (name, index) => (
//                                             <MenuItem key={index} value={name}>
//                                                 {name?.carrier_level_name}
//                                             </MenuItem>
//                                         )
//                                     );
//                                 }
//                             })
//                             } */}

//                             {/* {
//                                 (selectedTab == 'life') ? (
//                                     carrierLifeLevelData?.map((menu) => {
//                                         if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
//                                             return menu?.carrier_direct_level_data?.map(
//                                                 (name, index) => (
//                                                     <MenuItem key={index} value={name}>
//                                                         {name?.carrier_level_name}
//                                                     </MenuItem>
//                                                 )
//                                             );
//                                         }
//                                     })
//                                 ) : (
//                                     (selectedTab == 'annuity') &&
//                                     (
//                                         carrierAnnuityLevelData?.map((menu) => {
//                                             if (menu.carrier_id == row.carrier_types[0].carrier_id) {
//                                                 return menu?.carrier_direct_level_data.map(
//                                                     (name, index) => (
//                                                         <MenuItem key={index} value={name}>
//                                                             {name.carrier_level_name}
//                                                         </MenuItem>
//                                                     )
//                                                 );
//                                             }
//                                         })
//                                     )
//                                 )
//                             } */}

//                             {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}

//                             {/*************** OLD CODE : BEFORE UPDATED LIFE ANNUITY DROPDOWN ISSUE IN PRODUCTION

//                                 carrierLevelData?.map((menu) => {
//                                         if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
//                                             return menu?.carrier_direct_level_data?.map(
//                                                 (name, index) => (
//                                                     <MenuItem key={index} value={name}>
//                                                         {name?.carrier_level_name}
//                                                     </MenuItem>
//                                             )
//                                         );
//                                     }
//                                 })

//                             ***************/}
//                         </Select>
//                     </FormControl>
//                 </TableCell>

//                 {/* DL SECTION BFR DYANMIC CARRIER TYPE */}
//                 {/* {Array.from({ length: DLLength }).map((mm, j) => {
//                     return (
//                         <>
//                             <TableCell align="center">
//                                 <FormControl m={2}>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         className="ba-level"
//                                         size="small"
//                                         value={row?.carrier_level_data[j] ? row?.carrier_level_data[j] : ""}
//                                         renderValue={(val) => val.carrier_level_name}
//                                         onChange={(e) => {
//                                             ChangeDlSection(e, row, j)
//                                         }}
//                                         MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
//                                         IconComponent={KeyboardArrowDownIcon}
//                                     >
//                                         <MenuItem value={""}>
//                                             {"None"}
//                                         </MenuItem>

//                                         ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
//                                         {(selectedTab == 'life') ?
//                                                 (
//                                                     carrierLifeLevelData?.map((menu) => {
//                                                         if (menu.carrier_id == row.carrier_types[0].carrier_id) {
//                                                             return menu?.carrier_direct_level_data.map(
//                                                                 (name, index) => {
//                                                                     if (row?.directCarrier?.carrier_level_sequence <= name.carrier_level_sequence) {
//                                                                         return (
//                                                                             <MenuItem
//                                                                                 key={index}
//                                                                                 value={name}
//                                                                             >
//                                                                                 {name.carrier_level_name}
//                                                                             </MenuItem>
//                                                                         );
//                                                                     }
//                                                                     else {
//                                                                         return (
//                                                                             <MenuItem
//                                                                                 key={index}
//                                                                                 value={name}
//                                                                             >
//                                                                                 {name.carrier_level_name}
//                                                                             </MenuItem>
//                                                                         );
//                                                                     }
//                                                                 }
//                                                             );
//                                                         }
//                                                     })
//                                                 ) :
//                                                 (
//                                                     (selectedTab == 'annuity') &&
//                                                     (
//                                                         carrierAnnuityLevelData?.map((menu) => {
//                                                             if (menu.carrier_id == row.carrier_types[0].carrier_id) {
//                                                                 return menu?.carrier_direct_level_data.map(
//                                                                     (name, index) => {
//                                                                         if (row?.directCarrier?.carrier_level_sequence <= name.carrier_level_sequence) {
//                                                                             return (
//                                                                                 <MenuItem
//                                                                                     key={index}
//                                                                                     value={name}
//                                                                                 >
//                                                                                     {name.carrier_level_name}
//                                                                                 </MenuItem>
//                                                                             );
//                                                                         }
//                                                                         else {
//                                                                             return (
//                                                                                 <MenuItem
//                                                                                     key={index}
//                                                                                     value={name}
//                                                                                 >
//                                                                                     {name.carrier_level_name}
//                                                                                 </MenuItem>
//                                                                             );
//                                                                         }
//                                                                     }
//                                                                 );
//                                                             }
//                                                         })
//                                                     )
//                                                 )
//                                         }
//                                         ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
//                                     </Select>
//                                 </FormControl>
//                             </TableCell >
//                         </>
//                     );
//                 })} */}

//                 {/* DL SECTION UPDATED CODE  */}
//                 {Array.from({ length: DLLength }).map((mm, j) => {
//                     // const carrierLevels = globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.find(menu => menu.carrier_id === row.carrier_types[0]?.carrier_id)?.carrier_direct_level_data || [];
//                     const carrierLevels = globalCarrierLevelDetails && globalCarrierLevelDetails[selectedTab]?.find(menu => menu.carrier_id === row.carrier_types[0]?.carrier_id)?.carrier_direct_level_data?.filter(level => level.carrier_level_color_hex_code !== "#000000") || [];
//                     return (
//                         <>
//                             <TableCell align="center">
//                                 <FormControl m={2}>
//                                     <Select
//                                         labelId="demo-simple-select-label"
//                                         id="demo-simple-select"
//                                         className="ba-level"
//                                         size="small"
//                                         value={row?.carrier_level_data[j] ? row?.carrier_level_data[j] : ""}
//                                         renderValue={(val) => val?.carrier_level_name || "None"} // Ensure you handle null values.
//                                         onChange={(e) => {
//                                             ChangeDlSection(e, row, j)
//                                         }}
//                                         MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
//                                         IconComponent={KeyboardArrowDownIcon}
//                                     >
//                                         {carrierLevels?.map((level, index) => (
//                                             <MenuItem
//                                                 key={index}
//                                                 value={level}
//                                             // disabled={row?.directCarrier?.carrier_level_sequence > level.carrier_level_sequence}
//                                             >
//                                                 {level.carrier_level_name}
//                                             </MenuItem>
//                                         ))}
//                                     </Select>
//                                 </FormControl>
//                             </TableCell>
//                         </>
//                     );
//                 })
//                 }

//                 {/* Menu Item */}
//                 <TableCell
//                     className="sticky-table-body sticky-table-body-5"
//                 >
//                     <div>
//                         <IconButton
//                             onClick={(e) => {
//                                 DisableEdit(row)
//                             }}
//                         >
//                             <DoneIcon />
//                         </IconButton>
//                         <IconButton
//                             onClick={(e) => { handleClick(e, row) }}
//                         >
//                             <MoreVert />
//                         </IconButton>
//                         <Menu
//                             id="long-menu"
//                             MenuListProps={{
//                                 'aria-labelledby': 'long-button',
//                             }}
//                             anchorEl={anchorEl}
//                             open={open}
//                             onClose={handleClose}
//                             PaperProps={{
//                                 style: {
//                                     maxHeight: 48 * 4.5,
//                                     width: '10ch',
//                                 },
//                             }}
//                         >

//                             <MenuItem onClick={() => {
//                                 setFlagDeletePopup(true)
//                             }}>
//                                 Delete
//                             </MenuItem>

//                         </Menu>
//                         {/*******************
//                          *  <DeleteForeverIcon sx={{ cursor: "pointer", color: "red" }} onClick={() => {
//                             setDeleteCarrierData(row);
//                             setFlagDeletePopup(true)
//                         }} />
//                          *
//                          ****************/}
//                     </div>
//                 </TableCell>
//             </TableRow>
//             :
//             <TableRow
//                 key={row.carrier_name}
//                 className="carrier_table_row"
//             >
//                 {/* Serial Number */}
//                 <TableCell
//                     className="sticky-table-body sticky-table-body-1"
//                     stickyHeader
//                 >
//                     {i + 1}
//                 </TableCell>

//                 {/* Carrier */}
//                 <TableCell
//                     align="left"
//                     className="sticky-table-body sticky-table-body-2 clickable_item"
//                     stickyHeader
//                 // onClick={() => {
//                 //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
//                 // }}
//                 >
//                     <Link
//                         target="_blank"
//                         className="clickable_item"
//                         style={{ textDecoration: "none" }}
//                         to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`} >
//                         {row.carrier_name}
//                     </Link>

//                 </TableCell>

//                 {/* BA Level */}
//                 <TableCell
//                     align="center"
//                     className="sticky-table-body sticky-table-body-3"
//                     stickyHeader
//                 >
//                     {row?.baLevel?.carrier_level_name}
//                 </TableCell>

//                 {/* Direct Carrier */}
//                 <TableCell
//                     align="center"
//                     sx={{
//                         borderRight: "2px solid white",
//                     }}
//                     className="sticky-table-body sticky-table-body-4"
//                 >
//                     {row?.directCarrier?.carrier_level_name}
//                 </TableCell>

//                 {/* DL Selection */}
//                 {Array.from({ length: DLLength }).map((mm, j) => {
//                     return (
//                         <>
//                             <TableCell
//                                 align="center"
//                             >
//                                 {row?.carrier_level_data?.length ? row?.carrier_level_data[j]?.carrier_level_name : ""}
//                             </TableCell>
//                         </>
//                     );
//                 })}

//                 {/* Menu Item */}
//                 <TableCell
//                     className="sticky-table-body sticky-table-body-5"
//                 >
//                     <div>
//                         <IconButton
//                             onClick={(e) => {
//                                 EnableEdit(row)
//                             }}
//                         >
//                             <EditIcon />
//                         </IconButton>
//                         <IconButton
//                             onClick={(e) => {
//                                 handleClick(e, row)
//                             }}
//                         >
//                             <MoreVert />
//                         </IconButton>
//                         <Menu
//                             id="long-menu"
//                             MenuListProps={{
//                                 'aria-labelledby': 'long-button',
//                             }}
//                             anchorEl={anchorEl}
//                             open={open}
//                             onClose={handleClose}
//                             PaperProps={{
//                                 style: {
//                                     maxHeight: 48 * 4.5,
//                                     width: '10ch',
//                                 },
//                             }}
//                         >

//                             <MenuItem onClick={() => {
//                                 setFlagDeletePopup(true)
//                             }}>
//                                 Delete
//                             </MenuItem>

//                         </Menu>
//                         {/**********************
//                          * <DeleteForeverIcon sx={{ cursor: "pointer", color: "red" }} onClick={() => {
//                             setDeleteCarrierData(row);
//                             setFlagDeletePopup(true)
//                         }}
//                         />
//                         *
//                         *****************/}
//                     </div>
//                 </TableCell>
//             </TableRow>
//     )
// }

// OLD CODE BFR FILTER CARRIER LEVEL DATA BASED ON COLOR CODING AND UPDATED CARRIR LEVEL :-------------------------------------------------

