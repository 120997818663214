import { Button, Divider, Grid, IconButton, Popover, Typography } from '@mui/material'
import React from 'react'
import "../../css/ViewCarriers.css"
import CloseIcon from '@mui/icons-material/Close';

function AddNewSectionPopover(props) {

    const {
        id, open, anchorEl, handleClose,
        addNewSection, setDeleteRowPopupFlag, setSelectedRowGroup, PathName,
        selectedLinePopover, carrierDetails, selectedRowGroupId, carrierLevelLength,
        disableAddNewGroup, setSectionOrRow, copyAboveSection
    } = props

    return (
        <>
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            padding: "8px 15px 10px",
                            width: '180px',
                            borderRadius: '10px'
                        }
                    }}
                    className="vw-action-popover"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        <Grid item xs={11}>
                                            <Typography variant="span" sx={{ fontSize: "11px", fontWeight: "400", color: "#909090" }}>Action Name</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <IconButton sx={{ padding: "2px", }}>
                                                <CloseIcon style={{ fontSize: "15px", color: "#909090", fontWeight: "600" }} onClick={() => handleClose()} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                            <Button
                                                variant="text"
                                                fullWidth
                                                className="vw-popover-action-text"
                                                onClick={() => {
                                                    addNewSection(selectedLinePopover.groups, selectedLinePopover.index)
                                                }}
                                            >
                                                Add New Section
                                            </Button>
                                        </Grid>

                                        {
                                            selectedLinePopover.index !== 0 &&
                                            <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                                <Button
                                                    variant="text"
                                                    fullWidth
                                                    className="vw-popover-action-text"
                                                    onClick={() => {
                                                        copyAboveSection(selectedLinePopover.groups, selectedLinePopover.index)
                                                    }}
                                                >
                                                    Copy Above Section
                                                </Button>
                                            </Grid>
                                        }

                                        <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                            <Button
                                                variant="text"
                                                // disabled = {}
                                                fullWidth
                                                className={
                                                    // ADDED : LAST ROW OF THE TABLE IS REMAINING 
                                                    PathName === "/carriers/add_carrier" && carrierLevelLength ? "vw-vw-popover-btn-disable" : disableAddNewGroup ? "vw-vw-popover-btn-disable" : "vw-popover-action-text"
                                                    // PathName === "/carriers/add_carrier" ? (carrierLevelLength ? "vw-vw-popover-btn-disable" : "vw-popover-action-text") : "vw-popover-action-text"
                                                }
                                                disabled={
                                                    // (PathName === "/carriers/add_carrier" && carrierDetails?.carrier_level_data?.length == 1) ? true : false}
                                                    (PathName === "/carriers/add_carrier" && carrierDetails?.carrier_product?.length == 1) ? true : disableAddNewGroup ? true : false}
                                                onClick={() => {
                                                    setDeleteRowPopupFlag(true)
                                                    setSelectedRowGroup({
                                                        selectId: null,
                                                        selectedId: selectedRowGroupId.selectId
                                                    })
                                                    handleClose()
                                                    handleClose()
                                                    setSectionOrRow('section')
                                                    handleClose()
                                                    setTimeout(() => {
                                                        let rowToHighLight = document.querySelector(`[data-section-id="${selectedRowGroupId.selectId}"]`);
                                                        let rect = rowToHighLight.getBoundingClientRect();
                                                        let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                                        let tableContainer = document.getElementById('custom-table').getBoundingClientRect();

                                                        let sectionToHighLight = carrierDetails?.carrier_product?.filter(group => group?.carrier_product_id == selectedRowGroupId.selectId || group?.carrier_product_id_custom == selectedRowGroupId.selectId)[0]
                                                        let top = rect.y;
                                                        let left = tableContainer.left + 24;
                                                        let width = tableContainer.width - 22;
                                                        let height = 44 * sectionToHighLight?.carrier_product_data?.length;
                                                        hiddenHighLighter.style.top = `${top}px`;
                                                        hiddenHighLighter.style.width = `${width}px`;
                                                        hiddenHighLighter.style.left = `${left}px`;
                                                        hiddenHighLighter.style.height = `${height}px`;
                                                        hiddenHighLighter.style.border = `1px solid #376fd0`;

                                                        document.body.style.overflow = 'hidden';
                                                        document.getElementById("carrier_view_container").style.overflow = "hidden"
                                                    }, 700)

                                                    document.body.style.overflow = 'hidden';
                                                    document.getElementById("carrier_view_container").style.overflow = "hidden"
                                                }}>
                                                Delete Section
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popover>
            </div>
        </>
    )
}

export default AddNewSectionPopover