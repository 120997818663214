import React, { useContext } from 'react'
import "../../../../css/Agent.css"



import { Grid, Button, IconButton } from "@mui/material";
import { DeleteAgentDataApi } from '../../../../../utils/api/AgentApi';

import { Context as AuthContext } from "../../../../../contexts/reducerContexts/authContext";
import { Close } from "@mui/icons-material";
import deleteAlert from '../../../../../assets/Delete-alert.png'



function DeleteAgentDialog(props) {

    const { setFlagDeletePopup, deleteAgentData, agentGloablDataList, agentDataList, setAgentGlobalDataList, setAgentDataList, CallUserMe } = props

    const {
        FlagApiLoader,
        UpdateSnackbarMessage,
    } = useContext(AuthContext);


    const handleDeleteAgent = () => {
        setFlagDeletePopup(false)
        FlagApiLoader(true)
        DeleteAgentDataApi(deleteAgentData?.agent_id, (res) => {
            if (res?.data?.status) {
                console.log("response true of DeleteAgentDataApi api", res);
                let tempAgentGlobalList = [...agentGloablDataList];
                let tempAgentList = [...agentDataList];

                tempAgentGlobalList = tempAgentGlobalList?.filter((element) => element?.agent_id != deleteAgentData?.agent_id)
                tempAgentList = tempAgentList?.filter((element) => element?.agent_id != deleteAgentData?.agent_id)
                setAgentGlobalDataList(tempAgentGlobalList)
                setAgentDataList(tempAgentList)

                UpdateSnackbarMessage({ status: "success", message: res?.data?.data?.message });
                CallUserMe()
                FlagApiLoader(false)
            }
            else {
                console.log("response false of DeleteAgentDataApi api", res);
                UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                });
                FlagApiLoader(false)
            }
        },
            (err) => {
                UpdateSnackbarMessage({ status: "error", message: "Something went wrong!" });
                FlagApiLoader(false)
            })
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container className="delete-main-container">
                    <Grid item xs={11.8} textAlign={"end"}>
                        <IconButton onClick={() => { setFlagDeletePopup(false) }} >
                            <Close />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12} className="delete-alert-item">
                        <img src={deleteAlert}></img>
                    </Grid>

                    <Grid item xs={12} className="delete-mesg-item">
                        Are you sure you want to delete {deleteAgentData?.agent_user_data[0]?.username}?
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className="delete-btn-container">
                            <Grid item xs={12}>
                                <Grid container className="popup-delete-button-container" >
                                    <Button
                                        variant="contained"
                                        sx={{ width: "100px" }}
                                        onClick={() => { handleDeleteAgent() }}
                                    >
                                        Yes
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: "100px" }}
                                        onClick={() => { setFlagDeletePopup(false) }}
                                    >
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default DeleteAgentDialog