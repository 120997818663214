import { Divider, Grid, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, FormControl, InputLabel, Select, MenuItem, IconButton, InputAdornment, styled, TextField } from "@mui/material";
import "../../css/Agency/AgencyTemplate.css"
import '../../css/Agency/AgencyCloneTemplate.css'

import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Search } from '@mui/icons-material'


// CONTEXT
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as authContext } from '../../../contexts/reducerContexts/authContext';
import AgencyTemplateData from "./AgencyTemplateData";

// API
import { GetAgencyTemplateDataList, GetTemplateDataAPI } from "../../../utils/api/AgencyDetailsAPI";

const CustomTextField = styled(TextField)({
    backgroundColor: "#EFEFF0",
    borderRadius: "5px",
    width: "230px",
    "& input": {
        padding: "9px 16px",
    },
    "& fieldset": {
        border: "none",
    },
});

const AgencyTemplate = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const {
        agencyTemplateList,
        agencyTemplateData,
        toggleDetails,
        subagencyDuplicateTemplateDetails
    } = useContext(agencyContext).state

    const {
        setAgencyTemplateList,
        setAgencyTemplateData,
        setSubagencyDuplicateTemplateDetails
    } = useContext(agencyContext)

    const {
        FlagApiLoader,
        UpdateSnackbarMessage,
        userDetail
    } = useContext(authContext)

    const {
        carrierTypeDetailsForAgency
    } = useContext(authContext).state

    const [flagShowTemplateData, setFlagShowTemplateData] = useState(false)
    const [selctedTemplate, setSelctedTemplate] = useState(null);
    const [maxDlLength, setMaxDlLength] = useState(10)
    const isAgencyTemplate = toggleDetails == 'agency_template'


    // Main useEffect
    useEffect(() => {
        setAgencyTemplateData([])
        setAgencyTemplateList([])
        const payLoad = { agency_id: agencyId }
        FlagApiLoader(true)
        GetAgencyTemplateDataList(payLoad, (res) => {
            if (res?.data?.status) {
                const data = res?.data?.data
                setAgencyTemplateList([...data])
                setSubagencyDuplicateTemplateDetails([...data])
                FlagApiLoader(false)
            } else {
                if (res?.data?.error?.message) {
                    console.log("GetAgencyTemplateDataList ERROR RESPONSE", res)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.error?.message
                    })
                    FlagApiLoader(false)
                }
                else {
                    console.log("GetAgencyTemplateDataList ERROR RESPONSE", res)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    })
                    FlagApiLoader(false)
                }
            }
        }
            , (err) => {
                console.log("GetAgencyTemplateDataList err>>>>", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            })
    }, [])

    const handleOnClickOfTableView = (template_id) => {
        const payLoad = {
            template_id: template_id,
            agency_id: agencyId
        }
        FlagApiLoader(true)
        GetTemplateDataAPI(
            payLoad,
            (res) => {
                if (res?.data?.status) {
                    const data = res?.data?.data
                    let maxDlLevelLength = 10
                    const templateData = data[0]?.template_data.map((temp_data) => {
                        const rowData = {
                            carrier_name: temp_data?.carrier_name,
                            ba_level_name: temp_data?.carrier_level_data?.filter(temp_data => temp_data?.carrier_ba_level_flag)[0]?.carrier_level_name,
                            direct_level_name: temp_data?.carrier_level_data?.filter(temp_data => temp_data?.carrier_direct_level_flag)[0]?.carrier_level_name,
                            dl_level: temp_data?.carrier_level_data?.filter(temp_data => !temp_data?.carrier_direct_level_flag && !temp_data?.carrier_ba_level_flag)
                        }

                        if (rowData?.dl_level?.length > maxDlLevelLength) {
                            maxDlLevelLength = rowData?.dl_level?.length
                        }
                        return rowData
                    })
                    console.log("templateData", templateData)
                    setSelctedTemplate(template_id)
                    setAgencyTemplateData(templateData)
                    setFlagShowTemplateData(true)
                    setMaxDlLength(maxDlLevelLength)
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        console.log("GetTemplateDataAPI ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("GetTemplateDataAPI ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("GetTemplateDataAPI form cpm-->rr", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            }
        )
    }

    const handleSelectChange = (event) => {
        handleOnClickOfTableView(event.target.value);
    };

    const handleSearch = (searchQuery) => {
        const query = searchQuery?.toLowerCase()
        if (subagencyDuplicateTemplateDetails != null) {
            const chatbotFilterData = subagencyDuplicateTemplateDetails.filter((ele) => {
                return (
                    ele.template_name !== null && ele?.template_name?.toLowerCase().includes(query)
                )
            })
            setAgencyTemplateList(chatbotFilterData)
        }
    };

    return (
        <>
            {/* <Box className="agt_main_box"> */}
            {/* {flagShowTemplateData &&
                <Box className="agt_header_box">
                    <Box className="agt-header-select-box">
                        <Button variant="text" className="agt-custom-breadcrumb-btn" onClick={() => {
                            setFlagShowTemplateData(false)
                            setAgencyTemplateData([])
                        }}>
                            Agency Templates /&nbsp;
                        </Button>

                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                className="agt-header-select"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selctedTemplate}
                                onChange={handleSelectChange}
                                MenuProps={{
                                    className: "agt-header-select-popover"
                                }}
                            >
                                {agencyTemplateList?.map((data, ind) => {
                                    return (
                                        <MenuItem value={data.template_id}>{data.template_name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            } */}
            {/* <Box className={!flagShowTemplateData ? "agt_content_box" : "agt_content_box-with-head"}> */}
            <Grid container
                sx={{
                    // height: '100%' 
                    height: "calc(100% - 65px)"
                }}
            >
                {flagShowTemplateData ? (<></>) :
                    <>
                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant='span'
                                        sx={{ fontSize: '17px', fontWeight: '500', color: '#1b2430' }}
                                    >
                                        Agency Template List
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'end', }}>
                                    <CustomTextField
                                        sx={{
                                            marginRight: '5px'
                                        }}
                                        placeholder="Search"
                                        onChange={(e) => {
                                            handleSearch(e.target.value)
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} marginTop={"12px"}><Divider /></Grid>
                    </>
                }

                <Grid item xs={12} className='agct-main-item' sx={{ height: '100%' }}>
                    <Grid container sx={{ height: '100%' }}>
                        {!flagShowTemplateData ?
                            (
                                <Grid item xs={12}
                                    sx={{
                                        margin: "10px 0px 0px",
                                        // height: isBaTemplate ? "calc(100% - 45px) !important" : "calc(100% - 15px) !important",
                                        height: isAgencyTemplate ? "100% !important" : "calc(100% - 15px) !important",
                                        overflow: "auto"
                                    }}>
                                    <TableContainer className="agt-table-container">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="agt-table-head-cell-1">
                                                        Name
                                                    </TableCell>
                                                    <TableCell className="agt-table-head-cell-2">
                                                        Type
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    agencyTemplateList?.length != 0 ?
                                                        agencyTemplateList?.map((data, ind) => {
                                                            let color = (carrierTypeDetailsForAgency && carrierTypeDetailsForAgency?.find(carrier => carrier.carrier_type_name == data.carrier_type_name)?.carrier_type_color) ?? 'black'

                                                            return (
                                                                <TableRow className="agt-table-body-row">
                                                                    <TableCell sx={{ paddin: "5px 10px" }} className="agt-table-body-name-cell">
                                                                        {data?.template_name}
                                                                    </TableCell>
                                                                    <TableCell sx={{ paddin: "5px 10px" }} className="agt-table-body-type-cell">
                                                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                            {/* <div
                                                                                style={{
                                                                                    border: data?.carrier_type_flag == true ? '1px solid #1b2dcf' : data?.carrier_type_flag == false ? '1px solid #2f970b' : '',
                                                                                    color: data?.carrier_type_flag == true ? '#1b2dcf' : data?.carrier_type_flag == false ? '#2f970b' : '',
                                                                                    borderRadius: '15px',
                                                                                    padding: '3px 10px',
                                                                                    fontSize: '13px',
                                                                                    fontWeight: '500',
                                                                                    width: '150px',
                                                                                    maxWidth: '70px',
                                                                                    minWidth: '70px',
                                                                                    textAlign: 'center'
                                                                                }}
                                                                            >
                                                                                {data?.carrier_type_flag ? 'Life' : 'Annuity'}
                                                                            </div> */}
                                                                            <div
                                                                                style={{
                                                                                    border: `1px solid ${color}`,
                                                                                    color: color,
                                                                                    borderRadius: '15px',
                                                                                    padding: '3px 10px',
                                                                                    fontSize: '13px',
                                                                                    fontWeight: '500',
                                                                                    width: '150px',
                                                                                    maxWidth: '70px',
                                                                                    minWidth: '70px',
                                                                                    textAlign: 'center'
                                                                                }}
                                                                            >
                                                                                {data?.carrier_type_name}
                                                                            </div>

                                                                            <Button
                                                                                sx={{ padding: "3px 10px !important" }}
                                                                                variant='outlined'
                                                                                size='small'
                                                                                className='agtc-outlined-btn'
                                                                                onClick={() => handleOnClickOfTableView(data?.template_id)}
                                                                            >
                                                                                View
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }) :
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: "center", fontSize: "14px" }} colSpan={2}>
                                                                No Data Found
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            ) :
                            (
                                <Grid item xs={12} sx={{ height: "100%" }}>
                                    <Box className="agct-main-tmp-data-box">
                                        <Box className="agct-header-box" >
                                            <IconButton
                                                disableFocusRipple
                                                disableRipple
                                                disableTouchRipple
                                                sx={{ padding: '5px 5px 5px 0px' }}
                                                onClick={() => {
                                                    setFlagShowTemplateData(false)
                                                    setAgencyTemplateData([])
                                                }}
                                            >
                                                <ArrowBackIcon style={{ color: '#1b2430' }} />
                                            </IconButton>
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <Select
                                                    className="agt-header-select"
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selctedTemplate}
                                                    onChange={handleSelectChange}
                                                    MenuProps={{
                                                        className: "agt-header-select-popover"
                                                    }}
                                                >
                                                    {agencyTemplateList?.map((data, ind) => {
                                                        return (
                                                            <MenuItem value={data.template_id}>{data.template_name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Grid item xs={12} sx={{ maxHeight: 'max-content' }}>
                                            <Divider />
                                        </Grid>
                                        <Box
                                            sx={{
                                                // height: "calc(100% - 44px)" 
                                                height: '100%'
                                            }}
                                        >
                                            <AgencyTemplateData
                                                maxDlLength={maxDlLength}
                                                templateData={agencyTemplateData}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            {/* </Box> */}
            {/* </Box> */}
        </>
    )
}

export default AgencyTemplate;