import React, { useContext, useEffect, useState } from 'react'
import {
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    AccordionDetails,
    AccordionSummary,
    Accordion,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    Box,
    MenuItem,
    FormControl,
    Button,
    capitalize,
    InputLabel,
    NativeSelect,
    ListItemIcon,
    useMediaQuery,
    Dialog
} from "@mui/material";
import Select from '@mui/material/Select';
import '../../css/ViewCarriarStyles/LevelCustomizationDialog.css'
import Divider from '@mui/material/Divider';
import { CarrierLevelDependencies } from '../../../utils/api/CarriersApi';
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { FocusTrap } from "@mui/base";
import CircleIcon from '@mui/icons-material/Circle';


const LevelCustomizationDialog = (props) => {
    let array = ['dg', 'silver', 'd', 'fgdfg', "kdfj", "sfkh", "sdfh", "sdfk", "sdfljk", "sdlfkj", "dflk", "dflhj", "sdfkj", "sdfkjh", "ds", "fsdf", "dfg", "dfgj", 'df']
    // 

    const { UpdateSnackbarMessage } = useContext(authContext)
    const [showMoreTemplate, setShowMoreTemplate] = useState(false)
    const [showMoreUser, setShowMoreUser] = useState(false)
    const [templatesAndUsers, setTemplatesAndUsers] = useState({
        templates: [],
        users: [],
    })
    const [errorFound, setErrorFound] = useState(false);
    const [displayedItems, setDisplayedItems] = useState({ templates: [], users: [] })
    const [showMoreTemplateUserRemNum, setShowMoreTemplateRemNum] = useState({ templates: 0, users: 0 })


    useEffect(() => {
        if (props?.levelSelectedData?.carrier_level_id) {
            CarrierLevelDependencies({
                levelId: props?.levelSelectedData?.carrier_level_id,
                carrier_id: props?.carrierId,
            }, (res) => {
                if (res?.data?.status) {
                    setTemplatesAndUsers({
                        templates: res?.data?.data[0]?.templates,
                        users: res?.data?.data[0]?.agents
                        // templates: array,
                        // users: array
                    })
                    setDisplayedItems({
                        templates: res?.data?.data[0]?.templates,
                        users: res?.data?.data[0]?.agents
                        // templates: array,
                        // users: array
                    })
                }
                else {
                    console.log("error");
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    });
                }
            }, (err) => {
                console.log("error", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
            })
        }
    }, [])

    useEffect(() => {

        let levelCustomizationMainPopup = document.getElementById('level-customization-main-popup');
        let lcdTemplateListMainItem = document.getElementById('lcd-template-list-main-item_id')
        let lcdUserListMainItemId = document.getElementById('lcd-users-list-main-item_id');
        const tempContainer = document.getElementById("tempContainer")
        const userContainer = document.getElementById("userContainer")
        let slicedTempIndex;
        let slicedUserIndex


        if (levelCustomizationMainPopup && lcdTemplateListMainItem) {
            let diff = (lcdTemplateListMainItem?.clientWidth - levelCustomizationMainPopup.clientWidth) * 100;
            // console.log("ele.offsetLeft", lcdTemplateListMainItem.clientWidth)
            let diff2 = diff / lcdTemplateListMainItem?.clientWidth;
            if (diff > 0 && diff2 + 8 > 0) {
                setShowMoreTemplate(true)
            }
        }

        if (levelCustomizationMainPopup && lcdUserListMainItemId) {
            let diff = (lcdUserListMainItemId?.clientWidth - levelCustomizationMainPopup.clientWidth) * 100;
            let diff2 = diff / lcdUserListMainItemId?.clientWidth;
            if (diff2 + 6 > 0) {
                setShowMoreUser(true)
            }
        }
        if (tempContainer) {
            const tempContainerWidth = tempContainer.clientWidth
            const tempItems = document.querySelectorAll('.lcd-temp-item');
            for (let i = 0; i < tempItems.length; i++) {
                // debugger
                let ele = tempItems[i]
                if (ele.offsetLeft <= tempContainerWidth) {
                    setShowMoreTemplateRemNum((prevState) => ({
                        ...prevState,
                        templates: tempItems.length - i - 1
                    }))
                    slicedTempIndex = i + 1;
                } else {
                    break
                }
            }
            if (userContainer) {
                const userContainerWidth = userContainer.clientWidth
                const userItems = document.querySelectorAll('.lcd-user-item');
                for (let i = 0; i < userItems.length; i++) {
                    let ele = userItems[i]
                    if (ele.offsetLeft <= userContainerWidth) {
                        setShowMoreTemplateRemNum((prevState) => ({
                            ...prevState,
                            users: userItems.length - i - 1
                        }))
                        slicedUserIndex = i + 1;
                    } else {
                        break
                    }
                }
            }
            const slicedTemplateItems = [...templatesAndUsers.templates].slice(0, slicedTempIndex)
            const slicedUserIntems = [...templatesAndUsers.users].slice(0, slicedUserIndex)
            setDisplayedItems({
                users: slicedUserIntems,
                templates: slicedTemplateItems
            })
        }

    }, [templatesAndUsers])

    const [editFlowState, setEditFlowState] = useState({
        carrier_level_name: props?.levelSelectedData?.carrier_level_name,
        carrier_level_color_hex_code: props?.levelSelectedData?.carrier_level_color_hex_code,
        // carrier_level_color_hex_code: props?.levelSelectedData?.carrier_level_color_hex_code,
        carrier_level_color_id: props?.levelSelectedData?.carrier_level_color_id,
        carrier_level_color_name: props?.levelSelectedData?.carrier_level_color_name,
        // carrier_level_note: props.levelSelectedData?.carrier_level_note
    })


    const handleLevelEdit = () => {
        let duplicateFound = props?.carrierDetails?.carrier_level?.find(level => level?.carrier_level_id != props?.levelSelectedData?.carrier_level_id && level?.carrier_level_name == editFlowState?.carrier_level_name)
        if (duplicateFound) {
            UpdateSnackbarMessage({
                status: "error",
                message: "Please select unique level name"
            })
            setErrorFound(true)
            return
        }
        let newCarrierLevel = { ...props?.carrierDetails }.carrier_level.map((level) => {
            let mainLevel = level?.carrier_level_id || level?.carrier_level_id_custom;
            let secLevel = props?.levelSelectedData?.carrier_level_id || props?.levelSelectedData?.carrier_level_id_custom
            if (mainLevel == secLevel) {
                return {
                    ...level,
                    carrier_level_name: editFlowState?.carrier_level_name,
                    carrier_level_color_name: editFlowState?.carrier_level_color_name,
                    carrier_level_color_hex_code: editFlowState?.carrier_level_color_hex_code,
                    carrier_level_color_id: editFlowState?.carrier_level_color_id,
                    // carrier_level_note: editFlowState?.carrier_level_note
                }
            }
            return level
        });
        props?.carrierDetails.carrier_level_data?.map((level) => {
            level?.carrier_group_data?.map((group) => {
                group?.carrier_commission_data?.map((commission) => {
                    let mainLevel = commission?.carrier_level_id || commission?.carrier_level_id_custom;
                    let secLevel = props?.levelSelectedData?.carrier_level_id || props?.levelSelectedData?.carrier_level_id_custom
                    if (mainLevel == secLevel) {

                        commission.carrier_level_name = editFlowState?.carrier_level_name

                    }

                })
            })
        })

        props?.setCarrierDetails({ ...props?.carrierDetails, carrier_level: newCarrierLevel })

        props.setFlagCustomizationCarriarSectionDialog(false);
        props.setFlagCarrierCustomizationGlobalDialog(false);
        props?.setFlagDialogGloballyRender(false);
    }

    return (
        <>
            <FocusTrap open={true} disableEnforceFocus={true}>
                <Dialog
                    open={true}
                    container={() => document.getElementById('custom-grid')}
                    disableEnforceFocus={true}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                // width: "100%",
                                // maxWidth: "830px",
                                borderRadius: "5px"
                            },
                        },
                    }}
                    className='vw-customization-section-dialog'
                    onClose={() => {
                        props.setFlagCarrierCustomizationGlobalDialog(false)
                        props.setFlagCustomizationCarriarSectionDialog(false)
                        props.setFlagCarrierCustomizationGlobalDeleteDialog(false)
                        props.setFlagCarrierCustomizationDeleteDialog(false)
                        props?.setSelectedAddIcon(null)
                        props?.setFlagDialogGloballyRender(false)
                        props?.setHideTransperentCell(true)

                    }}
                >
                    <Grid container id="level-customization-main-popup">
                        <Grid item xs={12}>
                            <Grid container>

                                <Grid item xs={12} className='lcd-head-main-item'>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={8}>
                                            <Typography variant='span'>{props?.levelSelectedData?.carrier_level_name}</Typography>
                                        </Grid>
                                        {/* <Grid item xs={2} sx={{ textAlign: "end" }}>
                                            <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography
                                                    variant='span'
                                                    sx={{
                                                        color: props.levelSelectedData.carrier_ba_level_flag ? "#2F970B" : "#868686",
                                                        fontSize: "14px",
                                                        fontWeight: "400"
                                                    }}
                                                >
                                                    <CircleIcon
                                                        style={{
                                                            color: props.levelSelectedData.carrier_ba_level_flag ? "#2F970B" : "#868686",
                                                            fontSize: "12px",
                                                            fontWeight: '500',
                                                            verticalAlign: 'middle'
                                                        }}
                                                    /> BA Level
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className='lcd-content-main-item'>
                                    <Grid container className='lcd-content-main-container'>

                                        <Grid item xs={12} className='lcd-dropdown-main-item'>
                                            <Grid container className='lcd-dropdown-main-container'  >
                                                <Grid item xs={5.5}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Typography variant='span' className='lcd-dropdown-main-typo'>Rename Level &nbsp;&nbsp;</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <TextField
                                                                sx={{
                                                                    '.MuiInput-root:before': {
                                                                        borderBottom: errorFound ? '1px solid red !important' : '1px solid rgba(0, 0, 0, 0.42) !important'
                                                                    }
                                                                }}
                                                                fullWidth
                                                                size='small'
                                                                id="standard-basic"
                                                                variant="standard"
                                                                value={editFlowState?.carrier_level_name}
                                                                onChange={(e) => {
                                                                    setEditFlowState({
                                                                        ...editFlowState,
                                                                        carrier_level_name: e.target.value
                                                                    })
                                                                }}
                                                                onClick={() => {
                                                                    setErrorFound(false)
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={5.5} className='lcd-dropdown-list-color'>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Typography className='lcd-dropdown-main-typo' variant='span'>Select Color &nbsp;&nbsp;</Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Select
                                                                fullWidth
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                variant='standard'
                                                                size='small'
                                                                MenuProps={{ disablePortal: true }}
                                                                value={{
                                                                    carrier_level_color_hex_code: editFlowState?.carrier_level_color_hex_code,
                                                                    carrier_level_color_id: editFlowState?.carrier_level_color_id,
                                                                    carrier_level_color_name: editFlowState?.carrier_level_color_name,
                                                                }}
                                                                renderValue={(e) =>
                                                                    <div style={{ textAlign: "left" }}>
                                                                        <span className='lcd-dropdown-colorbox' style={{ background: editFlowState.carrier_level_color_hex_code || props?.levelSelectedData?.carrier_level_color_hex_code }}>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        </span>
                                                                        <span>{editFlowState.carrier_level_color_name}</span>
                                                                    </div>}
                                                                onChange={(e) => {
                                                                    // console.log("nilesh", e)
                                                                    setEditFlowState({
                                                                        ...editFlowState,
                                                                        carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code,
                                                                        carrier_level_color_id: e.target.value.carrier_level_color_id,
                                                                        carrier_level_color_name: e.target.value.carrier_level_color_name,

                                                                    })
                                                                }}

                                                            // value={age}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    props?.colorList?.map((color) => {
                                                                        return (
                                                                            <MenuItem value={{
                                                                                carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                                                                carrier_level_color_id: color.carrier_level_color_id,
                                                                                carrier_level_color_name: color.carrier_level_color_name,

                                                                            }}>
                                                                                <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}>

                                                                                </span>
                                                                                <span value={color}>{color?.carrier_level_color_name}</span>
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={11.2}>
                                                <Grid container justifyContent={"start"}>
                                                    <Grid item>
                                                        <Typography variant='span' className='lcd-dropdown-main-typo'>Notes &nbsp;&nbsp;</Typography>
                                                    </Grid>
                                                    <Grid item xs={10.7}>
                                                        <TextField
                                                            sx={{
                                                                '.MuiInput-root:before': {
                                                                    borderBottom: errorFound ? '1px solid red !important' : '1px solid rgba(0, 0, 0, 0.42) !important'
                                                                }
                                                            }}
                                                            fullWidth
                                                            size='small'
                                                            id="standard-basic"
                                                            variant="standard"
                                                            value={editFlowState?.carrier_level_note}
                                                            onChange={(e) => setEditFlowState({
                                                                ...editFlowState,
                                                                carrier_level_note: e.target.value
                                                            })}
                                                            onClick={() => setErrorFound(false)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10} className='lcd-btn-main-item'>
                                            <Grid container className='lcd-btn-main-container'>
                                                <Grid item>
                                                    <Button
                                                        variant='outlined'
                                                        size='large'
                                                        fullWidth
                                                        className='lcd-cancel-btn'
                                                        onClick={() => {
                                                            props.setFlagCustomizationCarriarSectionDialog(false);
                                                            props.setFlagCarrierCustomizationGlobalDialog(false)
                                                            props?.setFlagDialogGloballyRender(false)
                                                            props?.setSelectedAddIcon(null)
                                                            props?.setHideTransperentCell(true)
                                                            props?.setCurrentlyAddedCols([])
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        fullWidth
                                                        className='lcd-save-btn'
                                                        onClick={() => {
                                                            handleLevelEdit()
                                                            props?.setSelectedAddIcon(null)
                                                            props?.setHideTransperentCell(true)
                                                            props?.setCurrentlyAddedCols([])
                                                        }}

                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        fullWidth
                                                        className='lcd-delete-level-btn'
                                                        onClick={() => {

                                                            props.setFlagCustomizationCarriarSectionDialog(false)
                                                            props.setFlagCarrierCustomizationGlobalDeleteDialog(true)
                                                            props.setFlagCarrierCustomizationDeleteDialog(true)
                                                            props?.setSelectedAddIcon(null)
                                                            props?.setHideTransperentCell(false)
                                                            props?.setCurrentlyAddedCols([])
                                                        }}
                                                    >
                                                        Delete Level
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider orientation="horizontal" flexItem />
                                </Grid>

                                <Grid item xs={12} className='lcd-temp-list-head-item'>
                                    <Typography variant='span' className='lcd-temp-list-head'>Templates:</Typography>
                                    {displayedItems.templates.length == 0 && (
                                        <Typography variant='span' className='lcd-None-text'>
                                            None
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item className='lcd-template-users-main-item' xs={12}>
                                    <Grid container>
                                        <Grid item xs={11} sx={{ maxWidth: "92% !important", overflow: "hidden" }} id="tempContainer">
                                            <Grid container >
                                                {displayedItems.templates?.length > 0 &&
                                                    <Grid item id="lcd-template-list-main-item_id" >
                                                        <Grid container>

                                                            <Grid item>
                                                                <Grid
                                                                    container
                                                                    className='lcd-tmplate-user-nowrap-container'
                                                                >
                                                                    {displayedItems?.templates?.length > 0 && displayedItems?.templates?.map((ele, ind) => {
                                                                        return (
                                                                            <Box key={ind} sx={{ display: "flex" }} className="lcd-temp-item">
                                                                                <Grid item className='lcd-temp-list-item '>
                                                                                    <Typography className={'lcd-temp-list-text'}>
                                                                                        {/* {ele} */}
                                                                                        {ele?.template_name}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Divider
                                                                                    orientation='vertical'
                                                                                    flexItem
                                                                                    sx={{ padding: "0px 5px" }}
                                                                                >
                                                                                </Divider>
                                                                            </Box>
                                                                        )
                                                                    })
                                                                    }
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        {displayedItems?.templates.length > 0 && <Grid className='lcd-more-text' item xs={0.9}>+{showMoreTemplateUserRemNum.templates} more</Grid>}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className='lcd-temp-list-head-item'>
                                    <Typography variant='span' className='lcd-temp-list-head'>Users:</Typography>
                                    {templatesAndUsers?.users?.length == 0 && (
                                        // <Grid variant="" span item className='lcd-temp-list-item'>
                                        <Typography variant='span' className='lcd-None-text'>
                                            None
                                        </Typography>
                                        // </Grid>
                                    )}
                                </Grid>


                                <Grid item className='lcd-template-users-main-item' xs={12}>
                                    <Grid container>
                                        <Grid item xs={11} sx={{ maxWidth: "92% !important", overflow: "hidden" }} id="userContainer" >
                                            <Grid container>
                                                {displayedItems?.users?.length > 0 &&
                                                    <Grid item id="lcd-users-list-main-item_id">
                                                        <Grid container >

                                                            <Grid item>
                                                                <Grid container className='lcd-tmplate-user-nowrap-container'>

                                                                    {displayedItems?.users?.length > 0 && displayedItems?.users?.map((ele, ind) => {
                                                                        return (
                                                                            <Box key={ind} sx={{ display: "flex" }} className="lcd-user-item">
                                                                                <Grid item className='lcd-temp-list-item'>
                                                                                    <Typography className='lcd-temp-list-text'>
                                                                                        {ele?.agent_primary_username}
                                                                                        {/* {ele} */}

                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Divider
                                                                                    orientation='vertical'
                                                                                    flexItem
                                                                                    sx={{ padding: "0px 5px" }}
                                                                                >
                                                                                </Divider>
                                                                            </Box>
                                                                        )
                                                                    })
                                                                    }
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        {displayedItems?.users.length > 0 && <Grid className='lcd-more-text' item xs={0.9}>+{showMoreTemplateUserRemNum.users} more</Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </FocusTrap>

        </>
    )
}

export default LevelCustomizationDialog