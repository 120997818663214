import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography } from "@mui/material";
import "../../css/Agency/AgencyTemplateData.css"

const AgencyTemplateData = ({ templateData, maxDlLength }) => {
    return (
        <><TableContainer className="atd-table-container">
            <Table stickyHeader >
                <TableHead >
                    <TableRow sx={{ position: "relative" }}>
                        <TableCell className="atd-tbl-head-cell-1 common-styles">
                            #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Carrier
                        </TableCell>
                        <TableCell className="atd-tbl-head-cell-2 common-styles">BA Level</TableCell>
                        <TableCell className="atd-tbl-head-cell-3 common-styles">Direct Carrier</TableCell>
                        {Array.from({ length: maxDlLength }, (_, ind) => (
                            <TableCell key={ind} align="left" className="atd-tbl-head-cell-4 common-styles">
                                DL
                            </TableCell>
                        ))}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        templateData.length != 0 ?
                            templateData?.map((data, ind) => {

                                return (
                                    <TableRow sx={{ position: "relative" }} key={ind}>
                                        <TableCell className="atd-tbl-body-cell-1">
                                            {ind + 1}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {
                                                data?.carrier_name
                                            }
                                        </TableCell>
                                        <TableCell className="atd-tbl-body-cell-2">
                                            {
                                                data?.ba_level_name ? data?.ba_level_name : ""
                                            }
                                        </TableCell>
                                        <TableCell className="atd-tbl-body-cell-3">
                                            {
                                                data?.direct_level_name ? data?.direct_level_name : ""
                                            }
                                        </TableCell>

                                        {
                                            Array.from({ length: maxDlLength }, (_, ind) => {
                                                const tempCarrier = data?.dl_level[ind]
                                                return (
                                                    <>
                                                        <TableCell className="atd-tbl-body-cell-4 common_body_styles" key={ind}>
                                                            {tempCarrier?.carrier_level_name}
                                                        </TableCell>
                                                    </>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            }) :
                            <TableRow>
                                <TableCell sx={{ textAlign: "center", fontSize: "14px" }} colSpan={13}>
                                    No Data Found
                                </TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table>

        </TableContainer>
        </>

    )
}
export default AgencyTemplateData;