import { Grid, Button, Box } from "@mui/material"
import "../../css/ViewCarriers.css"
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { Fragment, useContext } from "react"


export default function LifeAnnuityButtons({ setChangeInCarrierType, setCarrierTypeId, carrierDetails, carrierTypeTd, editDetails }) {

    const { carrierTypeDetailsForAgency } = useContext(authContext).state
    const carrierTypeList = carrierDetails?.carrier_types_list
    const selectedCarrierTypeId = carrierDetails?.carrier_type_id

    return (
        <>
            {
                !editDetails &&
                <Fragment>
                    {/* <Grid container className='pp-monthly-annually-container'>
                        <Box className="mask-box">
                            {carrierTypeDetailsForAgency?.map((carrier) => {
                                const carrierName = carrier.carrier_type_name
                                const carrierTypeId = carrier?.carrier_type_id
                                const carrierColor = carrier?.carrier_type_color
                                const availbleCarrier = carrierTypeList?.some(item => item.carrier_type_id === carrierTypeId) || selectedCarrierTypeId === carrierTypeId
                                const presentingCarrier = selectedCarrierTypeId === carrierTypeId
                                return (
                                    <Button key={carrierTypeId} variant="outlined" disabled={!availbleCarrier}
                                        className={`${presentingCarrier ? 'selected-life-button' : ''} sliderButton`}
                                        // sx={{
                                        //     color: `${carrierColor ? carrierColor : 'gray'}`,
                                        //     borderColor: `${carrierColor ? carrierColor : 'gray'}`,
                                        //     borderRadius: "8px",
                                        //     padding: "4px 7px"
                                        // }}
                                        onClick={() => {
                                            if (presentingCarrier) {
                                                return
                                            }
                                            setChangeInCarrierType(carrierTypeId)
                                            setCarrierTypeId(carrierTypeId)
                                        }}
                                    >
                                        {carrierName}
                                    </Button>
                                )
                            })}
                        </Box>
                    </Grid> */}
                    <Grid container className='pp-monthly-annually-container'>
                        <Grid item className="carrier-type-btn-wrapper">
                            {carrierTypeDetailsForAgency?.map((carrier) => {
                                const carrierName = carrier.carrier_type_name
                                const carrierTypeId = carrier?.carrier_type_id
                                const carrierColor = carrier?.carrier_type_color
                                const availbleCarrier = carrierTypeList?.some(item => item.carrier_type_id == carrierTypeId) || selectedCarrierTypeId == carrierTypeId
                                const presentingCarrier = selectedCarrierTypeId == carrierTypeId
                                return (
                                    <Box sx={{ display: "inline-flex" }} key={carrierTypeId}>
                                        <Box
                                            className="mask-box"
                                            sx={{
                                                textAlign: 'center',
                                                cursor: !availbleCarrier ? "no-drop !important" : "pointer",
                                            }}
                                        >
                                            <Button
                                                disabled={!availbleCarrier}
                                                disableRipple
                                                variant="text"
                                                sx={{
                                                    color: presentingCarrier ? "#000000" : "#000000",
                                                    background: presentingCarrier ? "#ffffff" : "#EFEFF0",
                                                    boxShadow: presentingCarrier ? "0px 0px 4px 0px #00000040" : "none",
                                                    opacity: !availbleCarrier ? 0.7 : 1,
                                                    cursor: !availbleCarrier ? "no-drop !important" : "pointer",
                                                    marginRight: '1px',
                                                    "&:hover": {
                                                        backgroundColor: presentingCarrier ? "#ffffff" : "#EFEFF0",
                                                    },
                                                }}
                                                onClick={() => {
                                                    if (presentingCarrier) {
                                                        return
                                                    }
                                                    setChangeInCarrierType(carrierTypeId)
                                                    setCarrierTypeId(carrierTypeId)
                                                }}
                                            >
                                                {carrierName}
                                            </Button>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Fragment>
            }
        </>
    )
}