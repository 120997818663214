import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../css/PrivacyPolicy/PrivacyPolicy.css'
import { getPrivacyPolicyAPI, getPrivacyPolicyStatusAPI, manageUserPrivacyPolicyAPI } from '../../../utils/api/PrivacyPolicy'
import Navbar from '../../../components/navbar/Navbar'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { Context as AuthContext } from '../../../contexts/reducerContexts/authContext';

const PrivacyPolicy = () => {

    const { state, UserMeContextApi, UpdateSnackbarMessage, FlagApiLoader, AgencyLogoAPI, } = useContext(AuthContext);
    const [htmlEle, setHtmlEle] = useState(null);
    const [privacyPolicyId, setPrivacyPolicyId] = useState(null);
    const [privacyPolicyStatusId, setPrivacyPolicyStatusId] = useState(null);
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
    const [privecyPolicyFileUrl, setPrivacyPolicyFileUrl] = useState(null)
    const iframeRef = useRef(null);

    useEffect(() => {
        const fetchPrivacyData = async () => {
            let payload = {
                privacy_policy_id: '',
                privacy_policy_status_id: 2
            }

            try {
                FlagApiLoader(true)
                console.log("Before getPrivacyPolicyAPI Log");
                await getPrivacyPolicyAPI(
                    (res) => {
                        if (res?.data?.status) {
                            console.log("after response getPrivacyPolicyAPI log", res?.data?.data);
                            if (res?.data?.data) {
                                setHtmlEle(res?.data?.data[0].privacy_policy_content)
                                setPrivacyPolicyFileUrl(res?.data?.data[0].privacy_policy_gcs_url)
                                payload.privacy_policy_id = res?.data?.data[0].privacy_policy_id
                                setPrivacyPolicyId(res?.data?.data[0].privacy_policy_id)
                            }
                            FlagApiLoader(false)
                        } else {
                            if (res?.data?.error) {
                                console.log("getPrivacyPolicyAPI response error log", res?.data?.data);
                                UpdateSnackbarMessage({
                                    status: "error", message: res?.data?.error?.message
                                })
                                FlagApiLoader(false)
                            }
                            else {
                                console.log("getPrivacyPolicyAPI response status false log", res?.data?.data);
                                UpdateSnackbarMessage({
                                    status: "error", message: "Something Went Wrong!"
                                })
                                FlagApiLoader(false)
                            }
                        }
                    },
                    (err) => {
                        console.log("getPrivacyPolicyAPI error block log", err);
                        // UserMeContextApi()
                        FlagApiLoader(false)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                    })

                FlagApiLoader(true)
                await getPrivacyPolicyStatusAPI(
                    (res) => {
                        if (res?.data?.status) {
                            console.log("after response getPrivacyPolicyStatusAPI log", res?.data?.data);
                            setPrivacyPolicyStatusId(2)
                            FlagApiLoader(false)
                        } else {
                            if (res?.data?.error) {
                                console.log("after error getPrivacyPolicyStatusAPI log", res?.data?.data);
                                UpdateSnackbarMessage({
                                    status: "error", message: res?.data?.error?.message
                                })
                                FlagApiLoader(false)
                            }
                            else {
                                console.log("getPrivacyPolicyStatusAPI response status false log", res?.data?.data);
                                UpdateSnackbarMessage({
                                    status: "error", message: "Something Went Wrong!"
                                })
                                FlagApiLoader(false)
                            }
                        }
                    },
                    (err) => {
                        console.log("getPrivacyPolicyStatusAPI error block log", err);
                        FlagApiLoader(false)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })

                    });

                FlagApiLoader(true)
                await manageUserPrivacyPolicyAPI(payload,
                    (res) => {
                        if (res?.data?.status) {
                            console.log("after response manageUserPrivacyPolicyAPI log", res?.data?.data);
                            FlagApiLoader(false)
                        }
                        else {
                            if (res?.data?.error) {
                                console.log("after error manageUserPrivacyPolicyAPI log", res?.data?.data);
                                UpdateSnackbarMessage({
                                    status: "error", message: res?.data?.error?.message
                                })
                                FlagApiLoader(false)
                            }
                            else {
                                console.log("manageUserPrivacyPolicyAPI response status false log", res?.data?.data);
                                UpdateSnackbarMessage({
                                    status: "error", message: "Something Went Wrong!"
                                })
                                FlagApiLoader(false)
                            }
                        }
                    },
                    (err) => {
                        console.log("manageUserPrivacyPolicyAPI error block log", err);
                        FlagApiLoader(false)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                    });

            } catch (err) {
                console.error("API call failed:", err);
            }
        };

        if (state.userDetail[0]?.user_status_name?.trim()?.toLowerCase() == "registered") {
            fetchPrivacyData();
        }

    }, [])

    const handlePrivacyPolicyStatus = async () => {
        let payload = {
            privacy_policy_id: privacyPolicyId,
            privacy_policy_status_id: 3
        }

        FlagApiLoader(true)

        console.log("->Before manageUserPrivacyPolicyAPI function Log");
        await manageUserPrivacyPolicyAPI(payload,
            async (res) => {
                if (res?.data?.status) {
                    console.log("after response manageUserPrivacyPolicyAPI function log", res?.data?.data);
                    console.log("bfr UserMeContextApi function log", res?.data?.data);
                    await UserMeContextApi();
                    console.log("aftr UserMeContextApi function log", res?.data?.data);
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error) {
                        console.log("after error manageUserPrivacyPolicyAPI function log", res?.data?.data);
                        UpdateSnackbarMessage({
                            status: "error", message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("manageUserPrivacyPolicyAPI response status false function log", res?.data?.data);
                        UpdateSnackbarMessage({
                            status: "error", message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("manageUserPrivacyPolicyAPI error function block log", err);
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                })
            });
    }

    return (
        <>
            <Navbar />
            {/* <div className={`main_policy_container`}>
                <div className='container'>
                    <div className='policy_document_container '>
                        {privecyPolicyFileUrl ?
                            (
                                <div className='iframe_container'>
                                    <iframe
                                        style={{ height: "100%", width: "100%" }}
                                        src={privecyPolicyFileUrl}
                                        title="Doc"
                                    >
                                    </iframe>
                                </div>
                            ) : (<div className='privacy_policy_document' dangerouslySetInnerHTML={{ __html: htmlEle }} />)
                        }
                    </div>

                    <div className='policy_acceptance_container '>
                        <FormControlLabel
                            className='acceptance_checkbox'
                            label="By clicking Accept, you are agreeing to the following non-disclosure terms regarding use of the myAdvisorGrids platform"
                            control={
                                <Checkbox
                                    checked={privacyPolicyChecked}
                                    onChange={(e) => { setPrivacyPolicyChecked(e.target.checked) }}
                                />
                            }
                        />
                        <div className='privacy_policy_button'>
                            <button
                                disabled={!privacyPolicyChecked}
                                className={`${!privacyPolicyChecked && "disable_button"}`}
                                onClick={() => {
                                    handlePrivacyPolicyStatus()
                                }}>
                                Accept
                            </button>
                        </div>
                    </div>

                </div>
            </div> */}

            <div className={`main_policy_container`}>
                <div className='container'>
                    <div className='policy_document_container'>
                        {privecyPolicyFileUrl ? (
                            <div className='iframe_container'>
                                <iframe
                                    style={{ height: "100%", width: "100%" }}
                                    src={privecyPolicyFileUrl}
                                    title="Doc"
                                >
                                </iframe>
                            </div>
                        ) : (
                            <div className='privacy_policy_document' dangerouslySetInnerHTML={{ __html: htmlEle }} />
                        )}
                    </div>

                    <div className='policy_acceptance_container'>
                        <FormControlLabel
                            className='acceptance_checkbox'
                            label="By clicking Accept, you are agreeing to the following non-disclosure terms regarding use of the myAdvisorGrids platform"
                            control={
                                <Checkbox
                                    checked={privacyPolicyChecked}
                                    onChange={(e) => { setPrivacyPolicyChecked(e.target.checked) }}
                                />
                            }
                        />
                        <div className='privacy_policy_button'>
                            <button
                                disabled={!privacyPolicyChecked}
                                className={`${!privacyPolicyChecked && "disable_button"}`}
                                onClick={() => {
                                    handlePrivacyPolicyStatus();
                                }}
                            >
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
