import React, { Fragment, useContext, useEffect, useState } from "react";
import '../../pages/css/ViewAgent.css'
import styled from "@emotion/styled";
import { Add, Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs as MuiBreadcrumbs, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider as MuiDivider, FormControlLabel, FormGroup, IconButton, Paper, Tab, Tabs, TextField, Typography, Select, MenuItem, FormControl, InputLabel, InputAdornment, Tooltip, Table, TableRow, TableHead, TableBody,
  Autocomplete,
  Popper,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import { fontSize, fontWeight, spacing, textTransform } from "@mui/system";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Link, Navigate, NavLink, useNavigate, useSearchParams } from "react-router-dom";

// Import image 
import PrimaryLogo from "../../assets/primary-logo.png";
import SecondaryLogo2 from "../../assets/secondary2-logo.png";
import Agent from "../../assets/agent1.png";
import Mail from "../../assets/mail.png";
import LifeInsuranceChip from "../../assets/life-insurance-chip.png";
import AnnuityInsuranceChip from "../../assets/annuity-insurance-chip.png";
import BackIcon from "../../assets/back-icon.png";
import CheckedIcon from "../../assets/checked.png";
import UnCheckedIcon from "../../assets/Uncheck.png";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RestorePageRoundedIcon from '@mui/icons-material/RestorePageRounded';
import deleteAlert from '../../assets/Delete-alert.png'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import GlobalDialog from "../../components/global/GlobalDialog";

import "../css/Agent.css";

// Context Import
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as agentContext } from "../../contexts/reducerContexts/agentContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";


// API Import 
import { GetCarrierDataList, GetCarrierLevelList, GetTemplateData, GetTemplateDataList } from "../../utils/api/TemplateApi";
import CarriersPopup from "../../components/global/CarriersPopup";
import GlobalCarrierTable from "../../components/global/GlobalCarrierTable";
import { AddSecondaryAgentDataApi, AssignTemplateToAgentApi, CreateNewAgentApi, DetachTemplateToAgentApi, GetAgentData, ResendExpiredNdaAgentApi, ResendNdaAgentApi, SendNewNdaAgentApi, UpdateAgentDataApi, UpdateUserDataApi } from "../../utils/api/AgentApi";
import { GrantDelegateAccessForAdmin } from "../../utils/api/userDetailApi";
import { AuthContext } from "../../contexts/JWTContext";
import { borderRadius, clearFix, padding } from "polished";

const Divider = styled(MuiDivider)(spacing);

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: #1565C0;
    color: #FFFF;
  }
`;

const BackIconComponent = () => (
  <img
    src={BackIcon}
    alt="Logo"
    width="28px"
    height="28px"
    text-align="center"
    justify-content="center"
  />
);


const AddNewAgents = () => {
  //Auth context
  const { FlagApiLoader, setFlagCarrierList, UpdateSnackbarMessage, } = useContext(authContext);
  const { userDetail, delegateAccessFlag, carrierTypeDetails, apiLoader } = useContext(authContext).state

  //Agent Context
  const {
    setFlagAgent,
    setAgentUserData,
    setAnnuityTemplateList,
    setLifeTemplateList
  } = useContext(agentContext);

  const {
    flagAgent,
    agentUserData,
    lifeTemplateList,
    annuityTemplateList,
  } = useContext(agentContext).state;

  const {
    setFlagType,
    setGlobalCarrierData,
    setGlobalLifeCarrierData,
    setGlobalAnnuityCarrierData,
    setCarrierLevelData,
    setCarrierAnnuityLevelData,
    setTemplateName,
    setTemplateId,
    setCarrierLifeLevelData,

    // NEW INTEGRATION
    setGlobalCarrierDetails,
    setGlobalCarrierLevelDetails,
  } = useContext(globalCarrierContext);

  const {
    carrierLevelData,
    flagType,
    templateName,
    templateId,
    globalCarrierData,
    globalLifeCarrierData,
    globalAnnuityCarrierData,
    carrierAnnuityLevelData,

    // NEW INTEGRATION
    globalCarrierLevelDetails,
    globalCarrierDetails
  } = useContext(globalCarrierContext).state;

  // State variable
  const [flagShowBottomButtons, setFlagShowBottomButtons] = useState(false);
  const [editFlag, setEditFlag] = useState(false)

  // --------
  const [selectedLifeTemplate, setSelectedLifeTemplate] = useState(null);
  const [selectedAnnuityTemplate, setSelectedAnnuityTemplate] = useState(null);

  const [selectedCarrierTemplate, setSelectedCarrierTemplate] = useState({});
  const [globalTemplateList, setGlobalTemplateList] = useState([{}])
  const [tempSelectedTemplate, setTempSelectedTemplate] = useState(null);
  const [flagUpdatedState, setFlagUpdatedState] = useState(new Date())
  // --------

  const [selectedTab, setSelectedTab] = useState("");
  const [flagAddPage, setFlagAddPage] = useState(true);
  const [flagNewAgentPage, setFlagNewAgentPage] = useState(false);
  const [deletedCarrierId, setDeletedCarrierId] = useState([])
  const [flagAccordianExpanded, setFlagAccordianExpanded] = useState(false);
  const [flagResendNdaPopup, setFlagResendNdaPopup] = useState({
    flag: false,
    type: "",
    expiredFlag: false
  });
  const [flagNdaInfoPopup, setFlagNdaInfoPopup] = useState(false);
  const [flagEditCarrierPopup, setFlagEditCarrierPopup] = useState(false);
  const [editClearFlag, setEditClearFlag] = useState("");
  const [editButtonVisible, setEditButtonVisible] = useState(false);
  const [clearButtonVisible, setClearEditButtonVisible] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const [primaryUsername, setPrimaryUsername] = useState("");
  const [flagSecondaryDetailsAlert, setFlagSecondaryDetailsAlert] = useState(false);
  const [ndaStatus, setNdaStatus] = useState("");
  const [agentFieldOldValue, setAgentFieldOldValue] = useState("");
  const [flagShowNdaSecondary, setFlagShowNdaSecondary] = useState(false);
  const [flagShowRemindIcon, setFlagShowRemindIcon] = useState({
    primary: false,
    secondary: false
  });
  const [flagShowExpiredIcon, setFlagShowExpiredIcon] = useState({
    primary: false,
    secondary: false
  });
  const [openChangeTemplatePopup, setOpenChangeTemplatePopup] = useState({
    flag: false,
    type: "",
    newValue: {}
  });
  const [flagEmailHelperText, setFlagEmailHelperText] = useState({
    primary: false,
    secondary: false
  });
  const [customNdaStatus, setCustomNdaStatus] = useState({
    type: "",
    message: ""
  });
  // navigation
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [flagTextFieldFocus, setFlagTextFieldFocus] = useState({
    primary: {
      username: false,
      email_address: false,
      address: false,
      state: false,
      country: false,
      zip_code: false,
      sso_email: false
    },
    secondary: {
      username: false,
      email_address: false,
      address: false,
      state: false,
      country: false,
      zip_code: false,
      sso_email: false
    }
  })

  const [oldUserDetails, setOldUserDetails] = useState({
    primary: {
      username: "",
      address: "",
      state: "",
      country: "",
      zip_code: "",
      sso_email: ""
    },
    secondary: {
      username: "",
      address: "",
      state: "",
      country: "",
      zip_code: "",
      sso_email: ""
    }
  })

  const [flagDisableUserDetails, setFlagDisableUserDetails] = useState({
    primary: true,
    secondary: true
  })
  // const [tempPreviousUserValue, setTempPreviousUserValue] = useState()



  // USE EFFECTS:----------
  // useEffect(() => {
  //   console.log("globalTemplateList useEffect1", globalTemplateList);
  //   console.log("globalTemplateList useEffect12", globalCarrierLevelDetails);
  //   console.log("globalTemplateList useEffect2", selectedCarrierTemplate);
  //   console.log("testtt D", globalCarrierDetails);
  //   console.log("testtt L", globalCarrierLevelDetails);
  // }, [selectedCarrierTemplate, globalTemplateList, globalCarrierLevelDetails, globalCarrierDetails, globalCarrierLevelDetails])

  useEffect(() => {
    let tempStatusData = { ...flagTextFieldFocus }
    let detailKeys = ["username", "email_address", "address", "state", "country", "zip_code", "sso_email"]
    for (let i = 0; i < detailKeys?.length; i++) {
      if (agentUserData?.primary[detailKeys[i]]) {
        tempStatusData.primary[detailKeys[i]] = true
      } else {
        tempStatusData.primary[detailKeys[i]] = false
      }
      if (agentUserData?.secondary[detailKeys[i]]) {
        tempStatusData.secondary[detailKeys[i]] = true
      } else {
        tempStatusData.secondary[detailKeys[i]] = false
      }
    }
    setFlagTextFieldFocus(tempStatusData)
  }, [agentUserData])

  useEffect(() => {
    // OLD
    // const lifeCarrierTypeId = carrierTypeDetails.find(type => type.carrier_type_name?.trim()?.toLowerCase() === "life")?.carrier_type_id;
    // const annuityCarrierTypeId = carrierTypeDetails.find(type => type.carrier_type_name?.trim()?.toLowerCase() === "annuity")?.carrier_type_id;
    // if (selectedTab == "life") {
    //   setFlagType(lifeCarrierTypeId)
    // }
    // if (selectedTab == "annuity") {
    //   setFlagType(annuityCarrierTypeId)
    // }
    setFlagType(selectedTab)
  }, [selectedTab])

  useEffect(() => {
    // OLD
    // if (selectedTab == "life") {
    //   if (globalLifeCarrierData?.length > 0) {
    //     setFlagShowBottomButtons(true)
    //   } else {
    //     setFlagShowBottomButtons(false)
    //   }
    // } else if (selectedTab == "annuity") {
    //   if (globalAnnuityCarrierData?.length > 0) {
    //     setFlagShowBottomButtons(true)
    //   } else {
    //     setFlagShowBottomButtons(false)
    //   }
    // }

    if (selectedTab) {
      if (globalCarrierDetails && globalCarrierDetails[selectedTab]?.length > 0) {
        setFlagShowBottomButtons(true)
      }
      else {
        setFlagShowBottomButtons(false)
      }
    }
  }, [globalCarrierDetails, selectedTab])

  useEffect(() => {
    // OLD
    // if (selectedTab == 'life') {
    //   setSelectedTab("life");
    // }
    // else if (selectedTab == 'annuity') {
    //   setSelectedTab("annuity");
    // }
    // else {
    //   if (agentUserData.carrier_type_life) {
    //     setSelectedTab("life");
    //   } else if (agentUserData.carrier_type_annuity) {
    //     setSelectedTab("annuity");
    //   } else {
    //     setSelectedTab("");
    //   }
    // }

    if (selectedTab) {
      setSelectedTab(selectedTab)
    }
    else {
      if (agentUserData[selectedTab]) {
        setSelectedTab(selectedTab)
      }
      else {
        setSelectedTab("")
      }
    }
  }, [agentUserData])

  // THIS USE EFFECT MEARGE WITH BELOW CONDITION 
  // useEffect(() => {
  //   if ((selectedTab == "annuity" && selectedAnnuityTemplate != null) || (selectedTab == "life" && selectedLifeTemplate != null)) {
  //     setEditButtonVisible(true)
  //   }
  //   else {
  //     setEditButtonVisible(false)
  //   }
  //   if ((selectedTab == "annuity" && globalAnnuityCarrierData?.length && selectedAnnuityTemplate == null) || (selectedTab == "life" && globalLifeCarrierData?.length && selectedLifeTemplate == null)) {
  //     setClearEditButtonVisible(true)
  //   }
  //   else {
  //     setClearEditButtonVisible(false)
  //   }
  // }, [selectedTab, selectedLifeTemplate, selectedAnnuityTemplate, globalLifeCarrierData, globalAnnuityCarrierData])

  useEffect(() => {
    if (!selectedTab) return

    if (selectedCarrierTemplate && selectedCarrierTemplate[selectedTab]) {
      if (selectedCarrierTemplate[selectedTab].template_id) {
        setEditButtonVisible(true)
        setEditFlag(false)
      }
    }
    else {
      setEditFlag(true)
      setEditButtonVisible(false)
      if (globalCarrierDetails && globalCarrierDetails[selectedTab] && globalCarrierDetails[selectedTab].length) {
        setClearEditButtonVisible(true)
      } else {
        setClearEditButtonVisible(false)
      }
    }
    // OLD
    // if (selectedTab == "life") {
    //   if (selectedLifeTemplate == null) {
    //     setEditFlag(true)
    //   } else {
    //     setEditFlag(false)
    //   }
    // } else if (selectedTab == "annuity") {
    //   if (selectedAnnuityTemplate == null) {
    //     setEditFlag(true)
    //   } else {
    //     setEditFlag(false)
    // }
    // }
  },
    [selectedTab, selectedCarrierTemplate, editButtonVisible, globalCarrierDetails]
    // [selectedLifeTemplate, selectedCarrierTemplate, selectedTab]
  )

  useEffect(() => {
    (async () => {
      FlagApiLoader(true)
      let templateIdExists = searchParams.has("id");
      await GetLifeAndAnnuityTemplate()
      if (templateIdExists === true) {
        let searchParamsAgentId = searchParams.get("id");
        if (searchParamsAgentId !== null) {
          setAgentId(searchParamsAgentId)
          setFlagAddPage(false);
          setFlagNewAgentPage(false);
          setFlagAccordianExpanded(false);

          GetAgentData(searchParamsAgentId, (res) => {
            if (res?.data?.status) {
              console.log("response true of GetAgentData api", res);
              let tempAgentData = { ...agentUserData }
              let tempChangedDetails = { ...oldUserDetails }
              let tempFlagRemind = { ...flagShowRemindIcon }
              let tempFlagExpired = { ...flagShowExpiredIcon }
              let flagPrimaryRemind;
              let flagSecondaryRemind;
              let flagPrimaryExpired = false;
              let flagSecondaryExpired = false;
              res?.data?.data[0].agent_user_data?.map((row) => {
                if (row.primary_data_flag) {
                  tempAgentData['primary']['primary_data_flag'] = true
                  tempAgentData['primary']['user_id'] = row?.user_id
                  tempAgentData['primary']['username'] = row?.username
                  tempAgentData['primary']['email_address'] = row?.email_address
                  tempAgentData['primary']['address'] = row?.address
                  tempAgentData['primary']['state'] = row?.state
                  tempAgentData['primary']['country'] = row?.country
                  tempAgentData['primary']['zip_code'] = row?.zip_code
                  tempAgentData['primary']['sso_email'] = row?.sso_email
                  tempAgentData['primary']['nda_status'] = row?.user_nda_log
                  tempAgentData['primary']['created_by'] = row?.created_by_user_email_address
                  // NEW CHANGES : AFTER PRIVACY POLICY
                  tempAgentData['primary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
                  tempAgentData['primary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
                  tempChangedDetails['primary']['username'] = row?.username
                  tempChangedDetails['primary']['address'] = row?.address
                  tempChangedDetails['primary']['state'] = row?.state
                  tempChangedDetails['primary']['country'] = row?.country
                  tempChangedDetails['primary']['zip_code'] = row?.zip_code
                  tempChangedDetails['primary']['sso_email'] = row?.sso_email
                  setPrimaryUsername(row?.username)
                  flagPrimaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                  flagPrimaryExpired = row?.nda_expire_flag == true ? true : false
                }
                else {
                  if (row?.username) {
                    setFlagShowNdaSecondary(true)
                  } else {
                    setFlagShowNdaSecondary(false)
                  }
                  tempAgentData['secondary']['primary_data_flag'] = false
                  tempAgentData['secondary']['user_id'] = row?.user_id
                  tempAgentData['secondary']['username'] = row?.username
                  tempAgentData['secondary']['email_address'] = row?.email_address
                  tempAgentData['secondary']['address'] = row?.address
                  tempAgentData['secondary']['state'] = row?.state
                  tempAgentData['secondary']['country'] = row?.country
                  tempAgentData['secondary']['zip_code'] = row?.zip_code
                  tempAgentData['secondary']['sso_email'] = row?.sso_email
                  tempAgentData['secondary']['nda_status'] = row?.user_nda_log
                  tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address
                  // NEW CHANGES : AFTER PRIVACY POLICY
                  tempAgentData['secondary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
                  tempAgentData['secondary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
                  tempChangedDetails['secondary']['username'] = row?.username
                  tempChangedDetails['secondary']['address'] = row?.address
                  tempChangedDetails['secondary']['state'] = row?.state
                  tempChangedDetails['secondary']['country'] = row?.country
                  tempChangedDetails['secondary']['zip_code'] = row?.zip_code
                  tempChangedDetails['secondary']['sso_email'] = row?.sso_email
                  flagSecondaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                  flagSecondaryExpired = row?.nda_expire_flag == true ? true : false
                }
              })
              tempFlagRemind.primary = flagPrimaryRemind
              tempFlagRemind.secondary = flagSecondaryRemind
              tempFlagExpired.primary = flagPrimaryExpired
              tempFlagExpired.secondary = flagSecondaryExpired
              setFlagShowRemindIcon(tempFlagRemind)
              setFlagShowExpiredIcon(tempFlagExpired)
              let carrierIds = ''
              let tempCarrierFinalData = {}
              let tempCarriers = { ...selectedCarrierTemplate }

              res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
                const carrierTypeName = commissionRow?.carrier_type_name?.toLowerCase();
                if (carrierTypeName) {
                  if (!tempAgentData.carrier_type) {
                    tempAgentData.carrier_type = [];
                  }
                  const isFirstCarrierType = tempAgentData.carrier_type.length === 0;
                  if (!tempAgentData.carrier_type.includes(carrierTypeName)) {
                    tempAgentData.carrier_type.push(carrierTypeName);
                    if (isFirstCarrierType) {
                      setSelectedTab(carrierTypeName);  // Set the first carrier_type_name to setSelectedTab
                    }
                  }
                  if (commissionRow?.template_assign_flag == true) {
                    tempCarriers[carrierTypeName] = {
                      carrier_type_id: commissionRow.carrier_type_id,
                      carrier_type_name: carrierTypeName,
                      template_id: commissionRow?.template_id,
                      template_name: commissionRow?.template_name
                    }
                    console.log("tempCarriers", tempCarriers);
                  }
                  else {
                    let tempCarriers = { ...selectedCarrierTemplate }
                    tempCarriers[carrierTypeName] = null
                  }
                }
                let tempInnerArray = []
                if (commissionRow?.template_assign_flag == true) {
                  commissionRow?.template_data?.map((row) => {
                    let obj = {}
                    obj.carrier_name = row?.carrier_name
                    obj.carrier_id = row?.carrier_id
                    obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
                    // obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_name: commissionRow?.carrier_type_name }]
                    row?.carrier_level_data?.map((element) => {
                      element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
                      element.carrier_level_sequence = element?.template_level_sequence
                    })
                    obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                    obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                    obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                    tempInnerArray.push(obj)
                    carrierIds += `,${row?.carrier_id}`
                  })
                }
                else {
                  commissionRow?.agent_carrier_assignment_data?.map((row) => {
                    let obj = {}
                    obj.carrier_name = row?.carrier_name
                    obj.carrier_id = row?.carrier_id
                    obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
                    // obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_name: commissionRow?.carrier_type_name }]
                    obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                    obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                    obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                    tempInnerArray.push(obj)
                    carrierIds += `,${row?.carrier_id}`
                  })
                }
                tempCarrierFinalData[carrierTypeName] = tempInnerArray
              })
              setGlobalCarrierDetails(tempCarrierFinalData)
              setSelectedCarrierTemplate(tempCarriers)
              // ----------------------------------------------------------
              carrierIds = carrierIds.slice(1)
              if (carrierIds) {
                GetCarrierLevelList(carrierIds,
                  (res) => {
                    if (res.data.status) {
                      console.log("response true of GetCarrierLevelList api", res);
                      console.log("tempCarrierFinalData=====>", tempCarrierFinalData);
                      Object.keys(tempCarrierFinalData).forEach(key => {
                        carrierLevelData[key] = res?.data?.data;
                      });
                      setGlobalCarrierLevelDetails({
                        ...globalCarrierLevelDetails,
                        ...carrierLevelData
                      });
                    }
                    else {
                      console.log("response false of GetCarrierLevelList api", res);
                      if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: res?.data?.error?.message
                        });
                        FlagApiLoader(false)
                      }
                      else {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: "Something Went Wrong!"
                        });
                        FlagApiLoader(false)
                      }
                    }
                  },
                  (err) => {
                    console.log("error of GetCarrierLevelList api", err);
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!",
                    });
                    FlagApiLoader(false)
                  })
              }

              if (flagAddPage) {
                setAgentUserData(tempAgentData);
              }
              else {
                setAgentUserData(tempAgentData);
              }
              setOldUserDetails(tempChangedDetails);
              FlagApiLoader(false)
            }
            else {
              console.log("response false of GetAgentData api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
              FlagApiLoader(false)
            }
          },
            (err) => {
              console.log("error of GetAgentData api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
              FlagApiLoader(false)
            })
        }
      }
      else {
        setFlagNewAgentPage(true)
        setFlagAccordianExpanded(true);
        ClearAgentDataContext()
        // OLD CODE :
        // setCarrierLevelData([])
        // UPDATED CODE 
        // setCarrierLifeLevelData([])
        // setCarrierAnnuityLevelData([])
        FlagApiLoader(false)
        setGlobalCarrierLevelDetails(null)
      }
    })()
  }, [])
  // USE EFFECTS:----------


  // EXTRA FUNCTIONS:-------
  const TextFieldFocusStatus = (type, field) => {
    let tempStatus = { ...flagTextFieldFocus }
    tempStatus[type][field] = true
    setFlagTextFieldFocus(tempStatus)
  }

  const TextFieldBlurStatus = (type, field) => {
    let dataLength = agentUserData[type][field]?.length
    let tempStatus = { ...flagTextFieldFocus }
    if (dataLength) {
      tempStatus[type][field] = true
    } else {
      tempStatus[type][field] = false
    }
    setFlagTextFieldFocus(tempStatus)
  }

  const ClearAgentDataContext = () => {
    setAgentUserData({
      primary: {
        "primary_data_flag": true,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      secondary: {
        "primary_data_flag": false,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      carrier_type_life: false,
      carrier_type_annuity: false,
      carrier_type: [],
    })
    FlagApiLoader(false)
  }

  const OpenEditCarrierPopup = (btnFlag) => {
    if (btnFlag == "editCarrier") {
      setFlagEditCarrierPopup(true)
      setEditClearFlag("editCarrier")
    }
    if (btnFlag == "clearAll") {
      setFlagEditCarrierPopup(true)
      setEditClearFlag("clearAll")
    }
  }

  const UserDetailValidation = () => {
    if (agentUserData?.primary?.username?.length == 0 && agentUserData?.primary?.email_address?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent name and email"
      });
      return false;
    }
    if (agentUserData?.primary?.username?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent name"
      });
      return false;
    }
    if (agentUserData?.primary?.email_address?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent email"
      });
      return false;
    }
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.primary?.email_address))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid primary agent email"
      });
      return false;
    }
    if (agentUserData?.secondary?.username?.length !== 0 && agentUserData?.secondary?.email_address?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter secondary agent email"
      });
      return false;
    }
    if (agentUserData?.secondary?.username?.length == 0 && agentUserData?.secondary?.email_address?.length !== 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent name"
      });
      return false;
    }
    if (agentUserData?.secondary?.username?.length !== 0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.secondary?.email_address))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid secondary agent email"
      });
      return false;
    }
    if (agentUserData?.primary?.sso_email?.length && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.primary?.sso_email))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid primary sso email"
      });
      return false;
    }
    if (agentUserData?.secondary?.sso_email?.length && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.secondary?.sso_email))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid secondary sso email"
      });
      return false;
    }
    return true;
  }

  const DisableEditTable = () => {
    // let tempCarrierData = selectedTab == "life" ? [...globalLifeCarrierData] : [...globalAnnuityCarrierData]
    // tempCarrierData?.map((obj) => {
    //   obj.edit_flag = false
    // })
    // if (selectedTab == "life") {
    //   setGlobalLifeCarrierData(tempCarrierData)
    // } else {
    //   setGlobalAnnuityCarrierData(tempCarrierData)
    // }

    if (!Array.isArray(globalCarrierDetails[selectedTab])) {
      console.error(`Expected globalCarrierDetails[${selectedTab}] to be an array, but found`, globalCarrierDetails[selectedTab]);
      return;
    }

    let tempCarrierData = [...globalCarrierDetails[selectedTab]];

    tempCarrierData.forEach((obj) => {
      obj.edit_flag = false;
    });

    setGlobalCarrierDetails({
      ...globalCarrierDetails,
      [selectedTab]: tempCarrierData
    });
  }

  const ConvertDateTimeFunction = (givenInputDate) => {
    const inputDate = new Date(givenInputDate);
    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
    const year = inputDate.getUTCFullYear();
    const hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();
    const seconds = inputDate.getUTCSeconds();
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedDate;
  }
  // EXTRA FUNCTIONS:-------

  // FUNCTIONS:-------
  const AddSecondaryData = () => {
    let payload = {
      agent_id: agentId,
      username: agentUserData?.secondary?.username,
      email_address: agentUserData?.secondary?.email_address,
      address: agentUserData?.secondary?.address,
      state: agentUserData?.secondary?.state,
      country: agentUserData?.secondary?.country,
      zip_code: agentUserData?.secondary?.zip_code,
      sso_email: agentUserData?.secondary?.sso_email,
      primary_data_flag: false
    }

    if (agentUserData?.secondary?.username && agentUserData?.secondary?.email_address) {
      AddSecondaryAgentDataApi(payload,
        (res) => {
          if (res?.data?.status) {
            console.log("response true of AddSecondaryAgentDataApi api", res);
            let tempAgentData = { ...agentUserData }
            let row = res?.data?.data[0]
            tempAgentData['secondary']['primary_data_flag'] = false
            tempAgentData['secondary']['user_id'] = row?.user_id
            tempAgentData['secondary']['username'] = row?.username
            tempAgentData['secondary']['email_address'] = row?.email_address
            tempAgentData['secondary']['address'] = row?.address
            tempAgentData['secondary']['state'] = row?.state
            tempAgentData['secondary']['country'] = row?.country
            tempAgentData['secondary']['zip_code'] = row?.zip_code
            tempAgentData['secondary']['sso_email'] = row?.sso_email
            tempAgentData['secondary']['nda_status'] = row?.user_nda_log
            tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address
            // NEW CHANGES : AFTER PRIVACY POLICY
            tempAgentData['secondary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
            tempAgentData['secondary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
            setAgentUserData(tempAgentData)
            let tempNdaFlag = { ...flagShowRemindIcon }
            tempNdaFlag.secondary = row.user_nda_signed_flag
            setFlagShowRemindIcon(tempNdaFlag)
            UpdateSnackbarMessage({
              status: "success",
              message: "Secondary agent created successfully."
            });
            setFlagShowNdaSecondary(true)
            FlagApiLoader(false)
          }
          else {
            console.log("response false of AddSecondaryAgentDataApi api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
            FlagApiLoader(false)
          }
        },
        (err) => {
          FlagApiLoader(false)
          console.log("error of AddSecondaryAgentDataApi api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something went wrong!"
          });
        })
    }
  }

  const UpdateUserDetails = (type) => {

    FlagApiLoader(true)
    let payload = {
      agent_id: agentId,
      user_id: agentUserData[type]?.user_id,
      agent_user_data_flag: true
    }
    let fieldKeys = ["username", "address", "state", "country", "zip_code", "sso_email"]
    let oldDetail = { ...oldUserDetails }
    let newDetail = { ...agentUserData }
    let flagApiCall = false
    for (let i = 0; i < fieldKeys.length; i++) {
      if (oldDetail[type][fieldKeys[i]] != newDetail[type][fieldKeys[i]]) {
        flagApiCall = true
        payload[fieldKeys[i]] = newDetail[type][fieldKeys[i]]
      }
    }

    if (flagApiCall) {
      UpdateUserDataApi(payload, (res) => {
        if (res?.data?.status) {
          console.log("response true of UpdateUserDataApi api", res);
          setPrimaryUsername(agentUserData?.primary?.username)
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });

          for (let i = 0; i < fieldKeys.length; i++) {
            oldDetail[type][fieldKeys[i]] = newDetail[type][fieldKeys[i]]
          }
          setOldUserDetails(oldDetail)
          FlagApiLoader(false)
        }
        else {
          console.log("response false of UpdateUserDataApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
          FlagApiLoader(false)
        }
        setFlagDisableUserDetails((old) => {
          let temp = { ...old }
          temp[type] = true
          return temp
        })
      },
        (err) => {
          console.log("error of UpdateUserDataApi api", err);
          setFlagDisableUserDetails((old) => {
            let temp = { ...old }
            temp[type] = true
            return temp
          })
          UpdateSnackbarMessage({
            status: "error",
            message: "Something went wrong!"
          });
          FlagApiLoader(false)
        })
    }
    else {
      setFlagDisableUserDetails((old) => {
        let temp = { ...old }
        temp[type] = true
        return temp
      })
      FlagApiLoader(false)
    }
  }

  const GetLifeAndAnnuityTemplate = async () => {
    let params = {
      agency_id: userDetail[0]?.user_agency_data.agency_id
    }
    await GetTemplateDataList(params,
      // {}, 
      (res) => {
        if (res.data.status) {
          console.log("response true of GetTemplateDataList api", res);
          var data = res.data.data;
          // Organize templates by carrier type name
          const groupedTemplates = data.reduce((acc, item) => {
            const typeName = item.carrier_type_name?.trim()?.toLowerCase();
            if (!acc[typeName]) {
              acc[typeName] = [];
            }
            acc[typeName].push(item);
            return acc;
          }, {});

          setGlobalTemplateList(groupedTemplates);
        }
        else {
          console.log("response false of GetTemplateDataList api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateDataList api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
      })
  }

  const GetTemplateDataFunction = (templateId) => {
    FlagApiLoader(true);
    GetTemplateData(
      {
        template_id: templateId,
        template_version_id: null,
        agency_id: userDetail[0]?.user_agency_data?.agency_id
      },
      (res) => {
        if (res.data.status === true) {
          console.log("response true of GetTemplateData api", res);
          const typeName = res?.data?.data[0]?.carrier_type_name?.toLowerCase()
          setFlagType(res?.data?.data[0]?.carrier_type_name?.trim()?.toLowerCase())
          setSelectedTab(typeName)
          let tempInnerArray = []
          res?.data?.data[0]?.template_data?.map((row) => {
            // setAssignmentId([...assignmentId, row?.template_carrier_assignment_id])
            let obj = {}
            obj.carrier_name = row?.carrier_name
            obj.carrier_id = row?.carrier_id
            obj.template_carrier_assignment_id = row?.template_carrier_assignment_id
            obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: res?.data?.data[0]?.carrier_type_name }]
            obj.baLevel = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == true)[0]
            obj.directCarrier = row?.carrier_level_data.filter(gg => gg?.carrier_direct_level_flag == true)[0]
            obj.carrier_level_data = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
            tempInnerArray.push(obj)
          })
          // console.log("tempInnerArray", tempInnerArray);
          // console.log("selectedTabbbbbbb", selectedTab);
          // console.log("selectedTabbbbbbb1", typeName);
          setGlobalCarrierDetails({
            ...globalCarrierDetails,
            [selectedTab]: tempInnerArray
          })
          // CODE BFR DYNAMIC TYPES
          // if (res?.data?.data[0]?.carrier_type_name?.trim()?.toLowerCase() == "life") {
          //   setGlobalLifeCarrierData(tempInnerArray);
          // }
          // else if (res?.data?.data[0]?.carrier_type_name?.trim()?.toLowerCase() == "annuity") {
          //   setGlobalAnnuityCarrierData(tempInnerArray);
          // }
          let carrierIds = ''
          res?.data?.data[0]?.template_data?.map((ele) => {
            carrierIds += `,${ele?.carrier_id}`
          })
          carrierIds = carrierIds.slice(1)
          GetCarrierLevelList(carrierIds,
            (res) => {
              if (res.data.status) {
                console.log("response true of GetCarrierLevelList api", res);
                setFlagCarrierList(true);
                setFlagAddPage(false)
                setGlobalCarrierLevelDetails({
                  ...globalCarrierLevelDetails,
                  [selectedTab]: res?.data?.data
                })
                // CODE BFR DYNAMIC TYPES
                // // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                // if (selectedTab == "life") {
                //   setCarrierLifeLevelData(res.data.data)
                // }
                // else if (selectedTab == "annuity") {
                //   setCarrierAnnuityLevelData(res.data.data)
                // }
                // // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
              }
              else {
                console.log("response false of GetCarrierLevelList api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }
                FlagApiLoader(false);
              }
            },
            (err) => {
              console.log("error of GetCarrierLevelList api", err);
              FlagApiLoader(false);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!",
              });
            })
          FlagApiLoader(false);

        }
        else {
          FlagApiLoader(false);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        FlagApiLoader(false);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!"
        });
        console.log("error of GetTemplateData api", err);
      }
    );
  }

  const AssignTemplateFunction = (templateId) => {

    const carrierTypeId = carrierTypeDetails?.find(carrier => carrier.carrier_type_name == selectedTab)?.carrier_type_id || null;
    if (flagNewAgentPage == false) {
      let payload = {
        agent_id: agentId,
        template_id: templateId,
        carrier_type_id: carrierTypeId
        // carrier_type_flag: selectedTab == "life" ? true : selectedTab == "annuity" ? false : null
      }

      AssignTemplateToAgentApi(payload,
        (res) => {
          if (res?.data?.status) {
            UpdateSnackbarMessage({
              status: "success",
              message: res.data?.data?.message
            });
            GetTemplateDataFunction(templateId)
          }
          else {
            console.log("response false of AssignTemplateToAgentApi api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
            FlagApiLoader(false)
          }
          setEditFlag(false)
        },
        (err) => {
          console.log("error of AssignTemplateToAgentApi api", err);
          FlagApiLoader(false)
          UpdateSnackbarMessage({
            status: "error",
            message: "Something went wrong!"
          });
        })
    }
  }

  const DetachTemplateFunction = (selectedType) => {

    const carrierTypeId = carrierTypeDetails?.find(carrier => carrier.carrier_type_name == selectedTab)?.carrier_type_id || null;
    let payload = {
      agent_id: agentId,
      carrier_type_id: carrierTypeId
      // carrier_type_flag: carrierTypeFlag
    }
    let searchParamsAgentId = searchParams.get("id");
    DetachTemplateToAgentApi(payload,
      (res) => {
        if (res?.data?.status) {
          console.log("response true of CreateTemplate api", res);
          GetAgentData(searchParamsAgentId,
            (res) => {
              if (res?.data?.status) {
                console.log("response true of GetAgentData api", res);
                let tempAgentData = { ...agentUserData }
                let tempChangedDetails = { ...oldUserDetails }
                let tempFlagRemind = { ...flagShowRemindIcon }
                let tempFlagExpired = { ...flagShowExpiredIcon }
                let flagPrimaryRemind;
                let flagSecondaryRemind;
                let flagPrimaryExpired = false;
                let flagSecondaryExpired = false;
                res?.data?.data[0].agent_user_data?.map((row) => {
                  if (row.primary_data_flag) {
                    tempAgentData['primary']['primary_data_flag'] = true
                    tempAgentData['primary']['user_id'] = row?.user_id
                    tempAgentData['primary']['username'] = row?.username
                    tempAgentData['primary']['email_address'] = row?.email_address
                    tempAgentData['primary']['address'] = row?.address
                    tempAgentData['primary']['state'] = row?.state
                    tempAgentData['primary']['country'] = row?.country
                    tempAgentData['primary']['zip_code'] = row?.zip_code
                    tempAgentData['primary']['sso_email'] = row?.sso_email
                    tempAgentData['primary']['nda_status'] = row?.user_nda_log
                    tempAgentData['primary']['created_by'] = row?.created_by_user_email_address
                    // NEW CHANGES : AFTER PRIVACY POLICY
                    tempAgentData['primary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
                    tempAgentData['primary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
                    tempChangedDetails['primary']['username'] = row?.username
                    tempChangedDetails['primary']['address'] = row?.address
                    tempChangedDetails['primary']['state'] = row?.state
                    tempChangedDetails['primary']['country'] = row?.country
                    tempChangedDetails['primary']['zip_code'] = row?.zip_code
                    tempChangedDetails['primary']['sso_email'] = row?.sso_email
                    setPrimaryUsername(row?.username)
                    flagPrimaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                    flagPrimaryExpired = row?.nda_expire_flag == true ? true : false
                  }
                  else {
                    if (row?.username) {
                      setFlagShowNdaSecondary(true)
                    } else {
                      setFlagShowNdaSecondary(false)
                    }
                    tempAgentData['secondary']['primary_data_flag'] = false
                    tempAgentData['secondary']['user_id'] = row?.user_id
                    tempAgentData['secondary']['username'] = row?.username
                    tempAgentData['secondary']['email_address'] = row?.email_address
                    tempAgentData['secondary']['address'] = row?.address
                    tempAgentData['secondary']['state'] = row?.state
                    tempAgentData['secondary']['country'] = row?.country
                    tempAgentData['secondary']['zip_code'] = row?.zip_code
                    tempAgentData['secondary']['sso_email'] = row?.sso_email
                    tempAgentData['secondary']['nda_status'] = row?.user_nda_log
                    tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address
                    // NEW CHANGES : AFTER PRIVACY POLICY
                    tempAgentData['secondary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
                    tempAgentData['secondary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
                    tempChangedDetails['secondary']['username'] = row?.username
                    tempChangedDetails['secondary']['address'] = row?.address
                    tempChangedDetails['secondary']['state'] = row?.state
                    tempChangedDetails['secondary']['country'] = row?.country
                    tempChangedDetails['secondary']['zip_code'] = row?.zip_code
                    tempChangedDetails['secondary']['sso_email'] = row?.sso_email
                    flagSecondaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                    flagSecondaryExpired = row?.nda_expire_flag == true ? true : false
                  }
                })

                tempFlagRemind.primary = flagPrimaryRemind
                tempFlagRemind.secondary = flagSecondaryRemind
                tempFlagExpired.primary = flagPrimaryExpired
                tempFlagExpired.secondary = flagSecondaryExpired
                setFlagShowRemindIcon(tempFlagRemind)
                setFlagShowExpiredIcon(tempFlagExpired)
                let carrierIds = ''
                // res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
                //   // --------------------------------------------------------
                //   const carrierTypeName = commissionRow?.carrier_type_name?.toLowerCase();
                //   if (carrierTypeName) {
                //     if (!tempAgentData.carrier_type) {
                //       tempAgentData.carrier_type = [];
                //     }

                //     if (!tempAgentData.carrier_type.includes(carrierTypeName)) {
                //       tempAgentData.carrier_type.push(carrierTypeName);
                //     }

                //     if (commissionRow?.template_assign_flag == true) {
                //       let tempCarriers = { ...selectedCarrierTemplate }
                //       tempCarriers[carrierTypeName] = {
                //         carrier_type_id: commissionRow.carrier_type_id,
                //         carrier_type_name: carrierTypeName,
                //         template_id: commissionRow?.template_id,
                //         template_name: commissionRow?.template_name
                //       }
                //       setSelectedCarrierTemplate(tempCarriers)
                //     }
                //     else {
                //       let tempCarriers = { ...selectedCarrierTemplate }
                //       tempCarriers[carrierTypeName] = null
                //       setSelectedCarrierTemplate(tempCarriers)
                //     }
                //   }

                //   let tempInnerArray = []
                //   if (commissionRow?.template_assign_flag == true) {
                //     commissionRow?.template_data?.map((row) => {
                //       let obj = {}
                //       obj.carrier_name = row?.carrier_name
                //       obj.carrier_id = row?.carrier_id
                //       obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
                //       obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_name }]
                //       row?.carrier_level_data?.map((element) => {
                //         element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
                //         element.carrier_level_sequence = element?.template_level_sequence
                //       })
                //       obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                //       obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                //       obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                //       tempInnerArray.push(obj)
                //       carrierIds += `,${row?.carrier_id}`
                //     })
                //   }
                //   else {
                //     commissionRow?.agent_carrier_assignment_data?.map((row) => {
                //       let obj = {}
                //       obj.carrier_name = row?.carrier_name
                //       obj.carrier_id = row?.carrier_id
                //       obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
                //       obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_name }]
                //       obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                //       obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                //       obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                //       tempInnerArray.push(obj)
                //       carrierIds += `,${row?.carrier_id}`
                //     })
                //   }

                //   const typeName = commissionRow.carrier_type_name
                //   setGlobalCarrierDetails({
                //     ...globalCarrierDetails,
                //     [selectedTab]: tempInnerArray
                //   })

                // })

                res?.data?.data[0]?.agent_commission_data?.forEach((commissionRow) => {
                  const carrierTypeName = commissionRow?.carrier_type_name?.toLowerCase();

                  if (carrierTypeName === selectedTab) { // Only process if carrierTypeName matches selectedTab
                    if (!tempAgentData.carrier_type) {
                      tempAgentData.carrier_type = [];
                    }

                    if (!tempAgentData.carrier_type.includes(carrierTypeName)) {
                      tempAgentData.carrier_type.push(carrierTypeName);
                    }

                    let tempCarriers = { ...selectedCarrierTemplate };
                    if (commissionRow?.template_assign_flag) {
                      tempCarriers[carrierTypeName] = {
                        carrier_type_id: commissionRow.carrier_type_id,
                        carrier_type_name: carrierTypeName,
                        template_id: commissionRow?.template_id,
                        template_name: commissionRow?.template_name
                      };
                    } else {
                      tempCarriers[carrierTypeName] = null;
                    }
                    setSelectedCarrierTemplate(tempCarriers);

                    let tempInnerArray = [];
                    const rows = commissionRow?.template_assign_flag ? commissionRow?.template_data : commissionRow?.agent_carrier_assignment_data;

                    rows?.forEach((row) => {
                      let obj = {};
                      obj.carrier_name = row?.carrier_name;
                      obj.carrier_id = row?.carrier_id;
                      obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id || row?.agent_commission_carrier_assignment_id;
                      obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_name: commissionRow?.carrier_type_name }];

                      row?.carrier_level_data?.forEach((element) => {
                        element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id;
                        element.carrier_level_sequence = element?.template_level_sequence;
                      });

                      obj.baLevel = row?.carrier_level_data?.find(gg => gg?.carrier_ba_level_flag);
                      obj.directCarrier = row?.carrier_level_data?.find(gg => gg?.carrier_direct_level_flag);
                      obj.carrier_level_data = row?.carrier_level_data?.filter(gg => !gg?.carrier_ba_level_flag && !gg?.carrier_direct_level_flag);

                      tempInnerArray.push(obj);
                      carrierIds += `,${row?.carrier_id}`;
                    });
                    setGlobalCarrierDetails({
                      ...globalCarrierDetails,
                      [selectedTab]: tempInnerArray
                    });
                  }
                });
                carrierIds = carrierIds.slice(1)
                GetCarrierLevelList(carrierIds,
                  (res) => {
                    if (res.data.status) {
                      console.log("response true of CreateTemplate api", res);
                      setGlobalCarrierLevelDetails({
                        ...globalCarrierLevelDetails,
                        [selectedTab]: res?.data?.data
                      })

                    }
                    else {
                      if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: res?.data?.error?.message
                        });
                      }
                      else {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: "Something Went Wrong!"
                        });
                      }
                    }
                  },
                  (err) => {
                    console.log(err);
                  })
                setAgentUserData(tempAgentData);
                setOldUserDetails(tempChangedDetails);
                FlagApiLoader(false)
              }
              else {
                FlagApiLoader(false)
                console.log("response false of GetCarrierLevelList api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }
              }
            },
            (err) => {
              console.log("error of GetCarrierLevelList api", err);
              FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!",
              });
            })
          // if (selectedType == "life") {
          //   setSelectedLifeTemplate(null)
          // }
          // else if (selectedType == "annuity") {
          //   setSelectedAnnuityTemplate(null)
          // }
          // NEW SELECTD TYPE
          if (selectedType) {
            // setSelectedCarrierTemplate(null)
            let tempData = { ...selectedCarrierTemplate }
            tempData[selectedTab] = null
            setSelectedCarrierTemplate(tempData)
          }
          FlagApiLoader(false)
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });
        }
        else {
          FlagApiLoader(false)
          console.log("response false of DetachTemplateToAgentApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of DetachTemplateToAgentApi api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
        FlagApiLoader(false)
      })
  }

  const CreateNewAgent = () => {

    let validate = UserDetailValidation()
    if (validate == false) {
      return;
    }
    let finalPayload = {}
    let userArray = []
    userArray[0] = {
      "primary_data_flag": true,
      "username": agentUserData?.primary?.username.trim(),
      "email_address": agentUserData?.primary?.email_address.trim(),
      "address": agentUserData?.primary?.address,
      "state": agentUserData?.primary?.state,
      "country": agentUserData?.primary?.country,
      "zip_code": agentUserData?.primary?.zip_code,
      "sso_email": agentUserData?.primary?.sso_email,
    }
    if (agentUserData?.secondary?.username) {
      userArray[1] = {
        "primary_data_flag": false,
        "username": agentUserData?.secondary?.username.trim(),
        "email_address": agentUserData?.secondary?.email_address.trim(),
        "address": agentUserData?.secondary?.address,
        "state": agentUserData?.secondary?.state,
        "country": agentUserData?.secondary?.country,
        "zip_code": agentUserData?.secondary?.zip_code,
        "sso_email": agentUserData?.secondary?.sso_email,
      }
    }
    finalPayload["agent_user_data"] = userArray;
    var templateCarrierTypeArr = { ...globalCarrierDetails };
    const agent_commission_data = []
    // OLD PAYLOAD CREATED PREVIOUS DO NOT ERASE 
    // for (var i in templateCarrierTypeArr) {
    //   if (selectedCarrierTemplate[i] && selectedCarrierTemplate[i].template_id) {
    //     agent_commission_data.push({
    //       "template_assign_flag": true,
    //       "template_id": selectedCarrierTemplate[i].template_id,
    //       "agent_carrier_assignment_data": [],
    //       "carrier_type_id": carrierTypeDetails?.filter(rr => rr?.carrier_type_name?.toLowerCase() == i)[0]['carrier_type_id']
    //     })
    //   }
    //   else {
    //     // const obj = {
    //     //   "carrier_type_id": carrierTypeDetails?.filter(rr => rr?.carrier_type_name?.toLowerCase() == i)[0]['carrier_type_id'],
    //     //   "template_assign_flag": false,
    //     //   "template_id": null,
    //     //   "agent_carrier_assignment_data": [],
    //     // }
    //     // for (var j in templateCarrierTypeArr[i]) {
    //     //   // Check if baLevel exists and is an object, then convert it into an array for merging
    //     //   const baLevelArray = templateCarrierTypeArr[i][j]['baLevel'] ? [templateCarrierTypeArr[i][j]['baLevel']] : [];
    //     //   obj.agent_carrier_assignment_data.push({
    //     //     "carrier_id": templateCarrierTypeArr[i][j]['carrier_types'][0]['carrier_id'],
    //     //     "carrier_level_data": [...baLevelArray, ...templateCarrierTypeArr[i][j]['carrier_level_data']]
    //     //   });
    //     // }
    //     // agent_commission_data.push(obj)

    //     const obj = {
    //       "carrier_type_id": carrierTypeDetails?.filter(rr => rr?.carrier_type_name?.toLowerCase() == i)[0]['carrier_type_id'],
    //       "template_assign_flag": false,
    //       "template_id": null,
    //       "agent_carrier_assignment_data": []
    //     };

    //     for (var j in templateCarrierTypeArr[i]) {
    //       const carrierData = templateCarrierTypeArr[i][j];
    //       const carrierLevelData = {
    //         "carrier_id": carrierData['carrier_types'][0]['carrier_id'],
    //         "carrier_level_data": []
    //       };

    //       // Check if baLevel exists and add it
    //       if (carrierData['baLevel']) {
    //         carrierLevelData.carrier_level_data.push({
    //           ...carrierData['baLevel'],
    //           "carrier_ba_level_flag": true,
    //           "carrier_direct_level_flag": false
    //         });
    //       }

    //       // Check if directCarrier exists and add it
    //       if (carrierData['directCarrier']) {
    //         carrierLevelData.carrier_level_data.push({
    //           ...carrierData['directCarrier'],
    //           "carrier_ba_level_flag": false,
    //           "carrier_direct_level_flag": true  // Setting this flag true when directCarrier exists
    //         });
    //       }

    //       // Add all other carrier level data
    //       carrierLevelData.carrier_level_data = [
    //         ...carrierLevelData.carrier_level_data,
    //         ...carrierData['carrier_level_data'].map(level => ({
    //           ...level,
    //           "carrier_ba_level_flag": false,
    //           "carrier_direct_level_flag": false  // Defaulting to false for other levels
    //         }))
    //       ];

    //       obj.agent_carrier_assignment_data.push(carrierLevelData);
    //     }

    //     obj.agent_carrier_assignment_data.push(carrierLevelData);
    //     // if (!obj.template_assign_flag) {
    //     //   agent_commission_data.push(obj);
    //     // }

    //   }
    // }

    for (var i in templateCarrierTypeArr) {
      if (selectedCarrierTemplate[i] && selectedCarrierTemplate[i].template_id) {
        // If a template is assigned, add it to the payload with an empty `agent_carrier_assignment_data`
        agent_commission_data.push({
          "carrier_type_id": carrierTypeDetails?.filter(rr => rr?.carrier_type_name?.toLowerCase() == i)[0]['carrier_type_id'],
          "template_assign_flag": true,
          "template_id": selectedCarrierTemplate[i].template_id,
          "agent_carrier_assignment_data": []
        });
      } else {
        // If no template is assigned, include the manually entered carriers in `agent_carrier_assignment_data`
        const obj = {
          "carrier_type_id": carrierTypeDetails?.filter(rr => rr?.carrier_type_name?.toLowerCase() == i)[0]['carrier_type_id'],
          "template_assign_flag": false,
          "template_id": null,
          "agent_carrier_assignment_data": []
        };

        for (var j in templateCarrierTypeArr[i]) {
          const carrierData = templateCarrierTypeArr[i][j];
          const carrierLevelData = {
            "carrier_id": carrierData['carrier_types'][0]['carrier_id'],
            "carrier_level_data": []
          };

          // Check if baLevel exists and add it
          if (carrierData['baLevel']) {
            carrierLevelData.carrier_level_data.push({
              ...carrierData['baLevel'],
              "carrier_ba_level_flag": true,
              "carrier_direct_level_flag": false
            });
          }

          // Check if directCarrier exists and add it
          if (carrierData['directCarrier']) {
            carrierLevelData.carrier_level_data.push({
              ...carrierData['directCarrier'],
              "carrier_ba_level_flag": false,
              "carrier_direct_level_flag": true
            });
          }

          // Add all other carrier level data
          carrierLevelData.carrier_level_data = [
            ...carrierLevelData.carrier_level_data,
            ...carrierData['carrier_level_data'].map(level => ({
              ...level,
              "carrier_ba_level_flag": false,
              "carrier_direct_level_flag": false
            }))
          ];

          obj.agent_carrier_assignment_data.push(carrierLevelData);
        }

        agent_commission_data.push(obj);
      }
    }
    finalPayload["agent_commission_data"] = agent_commission_data;

    FlagApiLoader(true)
    CreateNewAgentApi(finalPayload, (res) => {
      if (res?.data?.status) {
        console.log("response true of CreateNewAgentApi api", res);
        UpdateSnackbarMessage({
          status: "success",
          message: "Agent Created Successfully!"
        });
        FlagApiLoader(false)
        navigate("/");
      }
      else {
        console.log("response false of CreateNewAgentApi api", res);
        FlagApiLoader(false)
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
    },
      (err) => {
        console.log("error of CreateNewAgentApi api", err);
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!"
        });
      })

  }

  const UpdateAgent = () => {

    let templateDataArr = { ...globalCarrierDetails };
    const carrierTypeId = carrierTypeDetails.find(carrier => carrier.carrier_type_name == selectedTab)?.carrier_type_id || null;
    let finalPayload = {
      agent_id: agentId,
      carrier_type_id: carrierTypeId,
      deleted_agent_commission_carrier_assignment_id: deletedCarrierId,
    };
    var templateDataObj = [];
    var cnt = 1;
    // OLD NR CODE :
    // for (var i in templateDataArr[selectedTab]) {
    //   templateDataObj[i] = {};
    //   if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
    //     templateDataObj[i]["agent_commission_carrier_assignment_id"] = templateDataArr[i]["agent_commission_carrier_assignment_id"];
    //     templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
    //   } else {
    //     templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
    //   }
    //   templateDataObj[i]["carrier_level_data"] = [];
    //   cnt = 1;
    //   if (templateDataArr[i]["baLevel"]?.carrier_level_name) {
    //     if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
    //       templateDataObj[i]["carrier_level_data"].push({
    //         agent_commission_carrier_assignment_level_id: templateDataArr[i]["baLevel"]["agent_commission_carrier_assignment_level_id"],
    //         carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
    //         carrier_ba_level_flag: true,
    //         carrier_direct_level_flag: false,
    //         carrier_level_sequence: cnt,
    //       });
    //     } else {
    //       templateDataObj[i]["carrier_level_data"].push({
    //         carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
    //         carrier_ba_level_flag: true,
    //         carrier_direct_level_flag: false,
    //         carrier_level_sequence: cnt,
    //       });
    //     }
    //     cnt++;
    //   }
    //   if (templateDataArr[i]["directCarrier"]?.carrier_level_name) {
    //     if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
    //       templateDataObj[i]["carrier_level_data"].push({
    //         agent_commission_carrier_assignment_level_id: templateDataArr[i]["directCarrier"]["agent_commission_carrier_assignment_level_id"],
    //         carrier_level_name: templateDataArr[i]["directCarrier"]["carrier_level_name"],
    //         carrier_ba_level_flag: false,
    //         carrier_direct_level_flag: true,
    //         carrier_level_sequence: cnt,
    //       });
    //     } else {
    //       templateDataObj[i]["carrier_level_data"].push({
    //         carrier_level_name: templateDataArr[i]["directCarrier"]["carrier_level_name"],
    //         carrier_ba_level_flag: false,
    //         carrier_direct_level_flag: true,
    //         carrier_level_sequence: cnt,
    //       });
    //     }
    //     cnt++;
    //   }
    //   for (var j in templateDataArr[i]["carrier_level_data"]) {
    //     if (templateDataArr[i]["carrier_level_data"][j]) {
    //       if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
    //         templateDataObj[i]["carrier_level_data"].push({
    //           agent_commission_carrier_assignment_level_id: templateDataArr[i]["carrier_level_data"][j]["agent_commission_carrier_assignment_level_id"],
    //           carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
    //           carrier_ba_level_flag: false,
    //           carrier_direct_level_flag: false,
    //           carrier_level_sequence: cnt++,
    //         });
    //       } else {
    //         templateDataObj[i]["carrier_level_data"].push({
    //           carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
    //           carrier_ba_level_flag: false,
    //           carrier_direct_level_flag: false,
    //           carrier_level_sequence: cnt++,
    //         });
    //       }
    //     }
    //   }
    // }

    for (var i in templateDataArr[selectedTab]) {
      const currentData = templateDataArr[selectedTab][i];

      templateDataObj[i] = {};

      if (currentData?.agent_commission_carrier_assignment_id) {
        templateDataObj[i]["agent_commission_carrier_assignment_id"] = currentData["agent_commission_carrier_assignment_id"];
        templateDataObj[i]["carrier_id"] = currentData["carrier_types"][0]["carrier_id"];
      }
      else {
        templateDataObj[i]["carrier_id"] = currentData["carrier_types"][0]["carrier_id"];
      }

      templateDataObj[i]["carrier_level_data"] = [];
      cnt = 1;

      if (currentData["baLevel"]?.carrier_level_name) {
        templateDataObj[i]["carrier_level_data"].push({
          agent_commission_carrier_assignment_level_id: currentData["baLevel"]["agent_commission_carrier_assignment_level_id"],
          carrier_level_name: currentData["baLevel"]["carrier_level_name"],
          carrier_ba_level_flag: true,
          carrier_direct_level_flag: false,
          carrier_level_sequence: cnt,
        });
        cnt++;
      }

      if (currentData["directCarrier"]?.carrier_level_name) {
        templateDataObj[i]["carrier_level_data"].push({
          agent_commission_carrier_assignment_level_id: currentData["directCarrier"]["agent_commission_carrier_assignment_level_id"],
          carrier_level_name: currentData["directCarrier"]["carrier_level_name"],
          carrier_ba_level_flag: false,
          carrier_direct_level_flag: true,
          carrier_level_sequence: cnt,
        });
        cnt++;
      }

      for (var j in currentData["carrier_level_data"]) {
        if (currentData["carrier_level_data"][j]) {
          templateDataObj[i]["carrier_level_data"].push({
            agent_commission_carrier_assignment_level_id: currentData["carrier_level_data"][j]["agent_commission_carrier_assignment_level_id"],
            carrier_level_name: currentData["carrier_level_data"][j]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: false,
            carrier_level_sequence: cnt++,
          });
        }
      }
    }

    finalPayload["agent_carrier_assignment_data"] = templateDataObj
    let searchParamsAgentId = searchParams.get("id");

    FlagApiLoader(true)
    UpdateAgentDataApi(finalPayload, (res) => {
      if (res?.data?.status) {
        console.log("response true of UpdateAgentDataApi api", res);
        UpdateSnackbarMessage({
          status: "success",
          message: res?.data?.data?.message
        });
        GetAgentData(searchParamsAgentId,
          (res) => {
            if (res?.data?.status) {
              console.log("response after remove template API ", res?.data?.data[0].agent_user_data)
              console.log("response false of GetAgentData api", res);
              let tempAgentData = { ...agentUserData }
              let tempChangedDetails = { ...oldUserDetails }
              let tempFlagRemind = { ...flagShowRemindIcon }
              let tempFlagExpired = { ...flagShowExpiredIcon }
              let flagPrimaryRemind;
              let flagSecondaryRemind;
              let flagPrimaryExpired = false;
              let flagSecondaryExpired = false;
              res?.data?.data[0].agent_user_data?.map((row) => {
                if (row.primary_data_flag) {
                  tempAgentData['primary']['primary_data_flag'] = true
                  tempAgentData['primary']['user_id'] = row?.user_id
                  tempAgentData['primary']['username'] = row?.username
                  tempAgentData['primary']['email_address'] = row?.email_address
                  tempAgentData['primary']['address'] = row?.address
                  tempAgentData['primary']['state'] = row?.state
                  tempAgentData['primary']['country'] = row?.country
                  tempAgentData['primary']['zip_code'] = row?.zip_code
                  tempAgentData['primary']['sso_email'] = row?.sso_email
                  tempAgentData['primary']['nda_status'] = row?.user_nda_log
                  tempAgentData['primary']['created_by'] = row?.created_by_user_email_address
                  // NEW CHANGES : AFTER PRIVACY POLICY
                  tempAgentData['primary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
                  tempAgentData['primary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
                  tempChangedDetails['primary']['username'] = row?.username
                  tempChangedDetails['primary']['address'] = row?.address
                  tempChangedDetails['primary']['state'] = row?.state
                  tempChangedDetails['primary']['country'] = row?.country
                  tempChangedDetails['primary']['zip_code'] = row?.zip_code
                  tempChangedDetails['primary']['sso_email'] = row?.sso_email
                  setPrimaryUsername(row?.username)
                  flagPrimaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                  flagPrimaryExpired = row?.nda_expire_flag == true ? true : false
                }
                else {
                  if (row?.username) {
                    setFlagShowNdaSecondary(true)
                  } else {
                    setFlagShowNdaSecondary(false)
                  }
                  tempAgentData['secondary']['primary_data_flag'] = false
                  tempAgentData['secondary']['user_id'] = row?.user_id
                  tempAgentData['secondary']['username'] = row?.username
                  tempAgentData['secondary']['email_address'] = row?.email_address
                  tempAgentData['secondary']['address'] = row?.address
                  tempAgentData['secondary']['state'] = row?.state
                  tempAgentData['secondary']['country'] = row?.country
                  tempAgentData['secondary']['zip_code'] = row?.zip_code
                  tempAgentData['secondary']['sso_email'] = row?.sso_email
                  tempAgentData['secondary']['nda_status'] = row?.user_nda_log
                  tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address
                  // NEW CHANGES : AFTER PRIVACY POLICY
                  tempAgentData['secondary']['user_privacy_policy_accepted_flag'] = row?.user_privacy_policy_accepted_flag
                  tempAgentData['secondary']['user_privacy_policy_consent'] = row?.user_privacy_policy_consent
                  tempChangedDetails['secondary']['username'] = row?.username
                  tempChangedDetails['secondary']['address'] = row?.address
                  tempChangedDetails['secondary']['state'] = row?.state
                  tempChangedDetails['secondary']['country'] = row?.country
                  tempChangedDetails['secondary']['zip_code'] = row?.zip_code
                  tempChangedDetails['secondary']['sso_email'] = row?.sso_email
                  flagSecondaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                  flagSecondaryExpired = row?.nda_expire_flag == true ? true : false
                }
              })
              tempFlagRemind.primary = flagPrimaryRemind
              tempFlagRemind.secondary = flagSecondaryRemind
              tempFlagExpired.primary = flagPrimaryExpired
              tempFlagExpired.secondary = flagSecondaryExpired
              setFlagShowRemindIcon(tempFlagRemind)
              setFlagShowExpiredIcon(tempFlagExpired)
              let carrierIds = ''
              // ---------------------------------------------
              // res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
              //   const carrierTypeName = commissionRow?.carrier_type_name?.toLowerCase();
              //   if (carrierTypeName) {
              //     if (!tempAgentData.carrier_type) {
              //       tempAgentData.carrier_type = [];
              //     }
              //     // const isFirstCarrierType = tempAgentData.carrier_type.length === 0;
              //     if (!tempAgentData.carrier_type.includes(carrierTypeName)) {
              //       tempAgentData.carrier_type.push(carrierTypeName);
              //       // if (isFirstCarrierType) {
              //       //   setSelectedTab(carrierTypeName);  // Set the first carrier_type_name to setSelectedTab
              //       // }
              //     }
              //     if (commissionRow?.template_assign_flag == true) {
              //       let tempCarriers = { ...selectedCarrierTemplate }
              //       tempCarriers[carrierTypeName] = {
              //         carrier_type_id: commissionRow.carrier_type_id,
              //         carrier_type_name: carrierTypeName,
              //         template_id: commissionRow?.template_id,
              //         template_name: commissionRow?.template_name
              //       }
              //       setSelectedCarrierTemplate(tempCarriers)
              //     }
              //     else {
              //       let tempCarriers = { ...selectedCarrierTemplate }
              //       tempCarriers[carrierTypeName] = null
              //       setSelectedCarrierTemplate(tempCarriers)
              //     }
              //   }

              //   let tempInnerArray = []
              //   if (commissionRow?.template_assign_flag == true) {
              //     commissionRow?.template_data?.map((row) => {
              //       let obj = {}
              //       obj.carrier_name = row?.carrier_name
              //       obj.carrier_id = row?.carrier_id
              //       obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
              //       obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_name }]
              //       row?.carrier_level_data?.map((element) => {
              //         element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
              //         element.carrier_level_sequence = element?.template_level_sequence
              //       })
              //       obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
              //       obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
              //       obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
              //       tempInnerArray.push(obj)
              //       carrierIds += `,${row?.carrier_id}`
              //     })
              //   }
              //   else {
              //     commissionRow?.agent_carrier_assignment_data?.map((row) => {
              //       let obj = {}
              //       obj.carrier_name = row?.carrier_name
              //       obj.carrier_id = row?.carrier_id
              //       obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
              //       obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_name }]
              //       obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
              //       obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
              //       obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
              //       tempInnerArray.push(obj)
              //       carrierIds += `,${row?.carrier_id}`
              //     })
              //   }
              //   const typeName = commissionRow.carrier_type_name
              //   setGlobalCarrierDetails({
              //     ...globalCarrierDetails,
              //     [selectedTab]: tempInnerArray
              //   })
              // })
              // setDeletedCarrierId([])
              // carrierIds = carrierIds.slice(1)
              // GetCarrierLevelList(carrierIds,
              //   (res) => {
              //     if (res.data.status) {
              //       setGlobalCarrierLevelDetails({
              //         ...globalCarrierLevelDetails,
              //         [selectedTab]: res?.data?.data
              //       })
              //     }
              //   },
              //   (err) => {
              //     console.log(err);
              //   })
              //   setAgentUserData(tempAgentData);
              // ---------------------------------------------

              // -------------------USE EFFECT--------------------------
              let tempCarrierFinalData = {}
              let tempCarriers = { ...selectedCarrierTemplate }
              res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
                const carrierTypeName = commissionRow?.carrier_type_name?.toLowerCase();
                if (carrierTypeName) {
                  if (!tempAgentData.carrier_type) {
                    tempAgentData.carrier_type = [];
                  }
                  const isFirstCarrierType = tempAgentData.carrier_type.length === 0;
                  if (!tempAgentData.carrier_type.includes(carrierTypeName)) {
                    tempAgentData.carrier_type.push(carrierTypeName);
                    if (isFirstCarrierType) {
                      setSelectedTab(carrierTypeName);  // Set the first carrier_type_name to setSelectedTab
                    }
                  }
                  if (commissionRow?.template_assign_flag == true) {
                    tempCarriers[carrierTypeName] = {
                      carrier_type_id: commissionRow.carrier_type_id,
                      carrier_type_name: carrierTypeName,
                      template_id: commissionRow?.template_id,
                      template_name: commissionRow?.template_name
                    }
                    console.log("tempCarriers", tempCarriers);
                  }
                  else {
                    let tempCarriers = { ...selectedCarrierTemplate }
                    tempCarriers[carrierTypeName] = null
                  }
                }
                let tempInnerArray = []
                if (commissionRow?.template_assign_flag == true) {
                  commissionRow?.template_data?.map((row) => {
                    let obj = {}
                    obj.carrier_name = row?.carrier_name
                    obj.carrier_id = row?.carrier_id
                    obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
                    // obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_name: commissionRow?.carrier_type_name }]
                    row?.carrier_level_data?.map((element) => {
                      element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
                      element.carrier_level_sequence = element?.template_level_sequence
                    })
                    obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                    obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                    obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                    tempInnerArray.push(obj)
                    carrierIds += `,${row?.carrier_id}`
                  })
                }
                else {
                  commissionRow?.agent_carrier_assignment_data?.map((row) => {
                    let obj = {}
                    obj.carrier_name = row?.carrier_name
                    obj.carrier_id = row?.carrier_id
                    obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
                    // obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_name: commissionRow?.carrier_type_name }]
                    obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                    obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                    obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                    tempInnerArray.push(obj)
                    carrierIds += `,${row?.carrier_id}`
                  })
                }
                tempCarrierFinalData[carrierTypeName] = tempInnerArray
              })
              setGlobalCarrierDetails(tempCarrierFinalData)
              setSelectedCarrierTemplate(tempCarriers)
              // ----------------------------------------------------------
              carrierIds = carrierIds.slice(1)
              if (carrierIds) {
                GetCarrierLevelList(carrierIds,
                  (res) => {
                    if (res.data.status) {
                      console.log("response true of GetCarrierLevelList api", res);
                      console.log("tempCarrierFinalData=====>", tempCarrierFinalData);
                      Object.keys(tempCarrierFinalData)?.forEach(key => {
                        carrierLevelData[key] = res?.data?.data;
                      });
                      setGlobalCarrierLevelDetails({
                        ...globalCarrierLevelDetails,
                        ...carrierLevelData
                      });
                    }
                    else {
                      console.log("response false of GetCarrierLevelList api", res);
                      if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: res?.data?.error?.message
                        });
                        FlagApiLoader(false)
                      }
                      else {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: "Something Went Wrong!"
                        });
                        FlagApiLoader(false)
                      }
                    }
                  },
                  (err) => {
                    console.log("error of GetCarrierLevelList api", err);
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!",
                    });
                    FlagApiLoader(false)
                  })
              }
              if (flagAddPage) {
                setAgentUserData(tempAgentData);
              }
              else {
                setAgentUserData(tempAgentData);
              }
              setOldUserDetails(tempChangedDetails);
              // -------------------USE EFFECT------------------------
              setOldUserDetails(tempChangedDetails);
              FlagApiLoader(false)
            }
            else {
              FlagApiLoader(false)
              console.log("response false of GetAgentData api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
            }
          },
          (err) => {
            console.log("error of GetAgentData api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
            FlagApiLoader(false)
          })
        DisableEditTable()
        FlagApiLoader(false)
      }
      else {
        DisableEditTable()
        FlagApiLoader(false)
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }

    },
      (err) => {
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!"
        });
        console.log("updateError::", err)
      })
  }

  const ChangeLifeTemplate = (e) => {
    if (flagNewAgentPage) {
      if (selectedLifeTemplate) {
        setOpenChangeTemplatePopup({
          flag: true,
          type: "life",
          newValue: e.target.value
        })
      }
      else {
        setSelectedLifeTemplate(e.target.value)
        GetTemplateDataFunction(e.target.value.template_id)
      }
    }
    else {
      setOpenChangeTemplatePopup({
        flag: true,
        type: "life",
        newValue: e.target.value
      })
    }
  }

  const ChangeAnnuityTemplate = (e) => {
    if (flagNewAgentPage) {
      if (selectedAnnuityTemplate) {
        setOpenChangeTemplatePopup({
          flag: true,
          type: "annuity",
          newValue: e.target.value
        })
      } else {
        setSelectedAnnuityTemplate(e.target.value)
        GetTemplateDataFunction(e.target.value.template_id)
      }
    } else {
      setOpenChangeTemplatePopup({
        flag: true,
        type: "annuity",
        newValue: e.target.value
      })
    }
  }

  // DO NOT ERASE
  // const handleChangeTemplate = (types, e) => {
  //   // debugger

  //   // console.log("selectedCarrierTemplate[selectedTab]", selectedCarrierTemplate[selectedTab]);
  //   const typeValue = types;
  //   const eventValue = e.target.value;
  //   setTempSelectedTemplate({
  //     ...tempSelectedTemplate,
  //     [typeValue]: eventValue
  //   });
  //   if (flagNewAgentPage) {
  //     if (selectedCarrierTemplate[selectedTab]) {
  //       // debugger
  //       // setTempSelectedTemplate(selectedCarrierTemplate[selectedTab]);
  //       setOpenChangeTemplatePopup({
  //         flag: true,
  //         type: typeValue,
  //         newValue: eventValue
  //       })
  //     }
  //     else {
  //       GetTemplateDataFunction(e.target.value.template_id)
  //       let tempData = { ...selectedCarrierTemplate }
  //       tempData[selectedTab] = eventValue
  //       setSelectedCarrierTemplate(tempData)
  //     }
  //   }
  //   else {
  //     // setTempSelectedTemplate(selectedCarrierTemplate[selectedTab]);
  //     setOpenChangeTemplatePopup({
  //       flag: true,
  //       type: typeValue,
  //       newValue: eventValue
  //     })
  //   }
  // };

  const handleChangeTemplate = (types, newValue) => {
    const typeValue = types.toLowerCase();

    if (flagNewAgentPage) {
      if (selectedCarrierTemplate[selectedTab]) {
        setOpenChangeTemplatePopup({
          flag: true,
          type: typeValue,
          newValue: newValue,
        });
      }
      else {
        GetTemplateDataFunction(newValue.template_id);
        let tempData = { ...selectedCarrierTemplate };
        tempData[selectedTab] = newValue;
        setSelectedCarrierTemplate(tempData);
      }
    }
    else {
      setOpenChangeTemplatePopup({
        flag: true,
        type: typeValue,
        newValue: newValue,
      });
    }
  };

  // FUNCTIONS:-------


  // COMPONENT:--------
  const RemindNdaPopup = () => {
    return (
      <>
        <Grid item xs={12} >
          <Grid container className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => {
                setFlagResendNdaPopup({
                  flag: false,
                  type: "",
                  expiredFlag: false
                })
              }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              {flagResendNdaPopup.expiredFlag == true ? "Are you sure you want to resend a new Non-Disclosure Agreement?" : "Are you sure you want to remind NDA?"}
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        FlagApiLoader(true)
                        if (flagResendNdaPopup.expiredFlag == true) {
                          let data = {
                            agent_user_id: flagResendNdaPopup.type == "primary" ?
                              agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                          }
                          SendNewNdaAgentApi(data, (res) => {
                            if (res?.data?.status) {
                              console.log("response true of SendNewNdaAgentApi api", res);
                              let tempOldNda = { ...flagShowExpiredIcon }
                              if (flagResendNdaPopup.type == "primary") {
                                tempOldNda.primary = false
                              } else if (flagResendNdaPopup.type == "secondary") {
                                tempOldNda.secondary = false
                              }
                              // setFlagShowRemindIcon(tempOldNda);
                              setFlagShowExpiredIcon(tempOldNda)
                              setCustomNdaStatus({
                                type: flagResendNdaPopup.type,
                                message: `Sent at ${new Date().getFullYear()}-${new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : "0" + new Date().getMonth() + 1}-${new Date().getDate() > 9 ? new Date().getDate() : "0" + new Date().getDate()} ${new Date().getHours() > 9 ? new Date().getHours() : "0" + new Date().getHours()}:${new Date().getMinutes() > 9 ? new Date().getMinutes() : "0" + new Date().getMinutes()}:${new Date().getSeconds() > 9 ? new Date().getSeconds() : "0" + new Date().getSeconds()}`
                              })
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "success",
                                message: res?.data?.data?.message
                              });
                              FlagApiLoader(false)
                            }
                            else {
                              console.log("response false of SendNewNdaAgentApi api", res);
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              if (res?.data?.error?.message) {
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: res?.data?.error?.message
                                });
                              }
                              else {
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: "Something Went Wrong!"
                                });
                              }
                              FlagApiLoader(false)
                            }
                          },
                            (err) => {
                              console.log("error of SendNewNdaAgentApi api", err);
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                        }
                        else if (flagResendNdaPopup.expiredFlag == false) {
                          let data = {
                            user_id: flagResendNdaPopup.type == "primary" ?
                              agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                          }
                          ResendNdaAgentApi(data, (res) => {
                            if (res?.data?.status) {
                              setCustomNdaStatus({
                                type: "",
                                message: ""
                              })
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "success",
                                message: res?.data?.data?.message
                              });
                              FlagApiLoader(false)
                            }
                            else {
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: res.data?.error?.message
                              });
                              FlagApiLoader(false)
                            }
                          },
                            (err) => {
                              console.log("error of SendNewNdaAgentApi api", err);
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                        }
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setFlagResendNdaPopup({
                          flag: false,
                          type: "",
                          expiredFlag: false
                        })
                      }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>

                {/* OLD BUTTONS CONTAINER */}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setFlagResendNdaPopup({
                            flag: false,
                            type: "",
                            expiredFlag: false
                          })
                        }}
                      >No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          FlagApiLoader(true)
                          if (flagResendNdaPopup.expiredFlag == true) {
                            let data = {
                              agent_user_id: flagResendNdaPopup.type == "primary" ?
                                agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                            }
                            SendNewNdaAgentApi(data, (res) => {
                              if (res?.data?.status) {
                                let tempOldNda = { ...flagShowExpiredIcon }
                                if (flagResendNdaPopup.type == "primary") {
                                  tempOldNda.primary = false
                                } else if (flagResendNdaPopup.type == "secondary") {
                                  tempOldNda.secondary = false
                                }
                                // setFlagShowRemindIcon(tempOldNda);
                                setFlagShowExpiredIcon(tempOldNda)
                                setCustomNdaStatus({
                                  type: flagResendNdaPopup.type,
                                  message: `Sent at ${new Date().getFullYear()}-${new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : "0" + new Date().getMonth() + 1}-${new Date().getDate() > 9 ? new Date().getDate() : "0" + new Date().getDate()} ${new Date().getHours() > 9 ? new Date().getHours() : "0" + new Date().getHours()}:${new Date().getMinutes() > 9 ? new Date().getMinutes() : "0" + new Date().getMinutes()}:${new Date().getSeconds() > 9 ? new Date().getSeconds() : "0" + new Date().getSeconds()}`
                                })
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "success",
                                  message: res?.data?.data?.message
                                });
                                FlagApiLoader(false)
                              } else {
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: res.data?.error?.message
                                });
                                FlagApiLoader(false)
                              }
                            }, (err) => {
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                          } else if (flagResendNdaPopup.expiredFlag == false) {
                            let data = {
                              user_id: flagResendNdaPopup.type == "primary" ?
                                agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                            }
                            ResendNdaAgentApi(data, (res) => {
                              if (res?.data?.status) {
                                setCustomNdaStatus({
                                  type: "",
                                  message: ""
                                })
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "success",
                                  message: res?.data?.data?.message
                                });
                                FlagApiLoader(false)
                              } else {
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: res.data?.error?.message
                                });
                                FlagApiLoader(false)
                              }
                            }, (err) => {
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                          }
                        }}
                      >Yes</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </>
    )
  }

  const NdaInfoPopup = () => {
    return (
      <Grid container className="status-popup">
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11} textAlign="center">
              <Typography sx={{ fontWeight: "600", fontSize: "15px", color: "#1565C0", padding: "16px 10px" }}>NDA Status Log</Typography>
            </Grid>
            <Grid item xs={1} textAlign={"center"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagNdaInfoPopup(false) }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container flexDirection="column" alignItems="center" rowGap={4}>
            <Grid item>
              <Table
                className="templateCarrierTable"
                aria-label="a dense table"
                size="small"
                sx={{ borderRadius: "10px" }}
              >
                <TableHead>
                  <TableRow>

                    <TableCell
                      className="header-color"
                      sx={{ minWidth: "200px", borderRadius: "10px 0px 0px 0px" }}
                    >
                      Activity
                    </TableCell>


                    <TableCell
                      className="header-color"
                      align="left"
                      nowrap
                      sx={{ minWidth: "200px", borderRadius: "0px 10px 0px 0px" }}
                    >
                      Date & Time
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {(ndaStatus == "primary" ? agentUserData?.primary?.nda_status : ndaStatus == "secondary" ? agentUserData?.secondary?.nda_status : [])?.map((row, i) => { */}
                  {(ndaStatus == "primary" ? agentUserData?.primary?.user_privacy_policy_consent : ndaStatus == "secondary" ? agentUserData?.secondary?.user_privacy_policy_consent : [])?.map((row, i) => {
                    return (
                      <>
                        <TableRow sx={{ backgroundColor: "#EFEFF0", }} >
                          <TableCell
                            stickyHeader
                          >
                            {/* NEW CHANGES : AFTER PRIVACY POLICY */}
                            {/* {row?.nda_activity} */}
                            {row?.privacy_policy_activity}
                          </TableCell>
                          <TableCell align="left" stickyHeader>{ConvertDateTimeFunction(row?.privacy_policy_activity_timestamp)}</TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item padding="10px 10px 15px 10px" >
              <Button
                variant="contained"
                sx={{ marginBottom: "10px", paddingX: "40px", paddingY: "8px", backgroundColor: "#1565C0" }}
                onClick={() => setFlagNdaInfoPopup(false)}
              >Close
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    )
  }

  const SecondaryDetailAlert = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container justifyContent="center" className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagSecondaryDetailsAlert(false) }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to add a secondary agent?
            </Grid>
            {/* <Grid item xs={8} color="#00345E" textAlign="center" className="popup-note">
              Note: The primary agent's access will be suspended until the secondary agent signs the Non-Disclosure Agreement.
            </Grid> */}
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        FlagApiLoader(true)
                        setFlagSecondaryDetailsAlert(false)
                        AddSecondaryData()
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagSecondaryDetailsAlert(false) }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
                {/* OLD BUTTON CODE */}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => { setFlagSecondaryDetailsAlert(false) }}
                      >No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          FlagApiLoader(true)
                          setFlagSecondaryDetailsAlert(false)
                          AddSecondaryData()
                        }}
                      >Yes</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const EditCarrierPopup = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11.8} textAlign={"end"}>
              <IconButton onClick={() => { setFlagEditCarrierPopup(false) }} >
                <CloseIcon />
                {/* changes */}
              </IconButton>
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to edit carriers?
            </Grid>
            <Grid item xs={12} color="#00345E" textAlign="center" className="popup-note">
              {editClearFlag == "clearAll" ?
                (<>
                  <div>Note: All carriers will be removed</div>
                </>)
                : (editClearFlag == "editCarrier") ?
                  (
                    <>
                      <div>
                        {/* Note: {selectedTab == "annuity" ? selectedAnnuityTemplate?.template_name : selectedTab == "life" ? selectedLifeTemplate?.template_name : ""} template assignment will be removed. */}
                        Note: {selectedTab ? selectedCarrierTemplate[selectedTab]?.template_name : ""} template assignment will be removed.
                      </div>
                    </>
                  )
                  :
                  (<></>)
              }
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setFlagEditCarrierPopup(false)
                        FlagApiLoader(true)
                        if (editClearFlag == "editCarrier") {
                          setEditFlag(true)
                          setEditButtonVisible(false)
                          // if (selectedTab == "life") {
                          //   if (flagNewAgentPage == false) {
                          //     // DetachTemplateFunction(true)
                          //     DetachTemplateFunction("life")
                          //   }
                          //   else {
                          //     setSelectedLifeTemplate(null)
                          //     FlagApiLoader(false)
                          //   }
                          // }
                          // else if (selectedTab == "annuity") {
                          //   if (flagNewAgentPage == false) {
                          //     // DetachTemplateFunction(false)
                          //     DetachTemplateFunction("annuity")
                          //   }
                          //   else {
                          //     setSelectedAnnuityTemplate(null)
                          //     FlagApiLoader(false)
                          //   }
                          // }


                          if (selectedTab && flagNewAgentPage == false) {
                            DetachTemplateFunction(selectedTab)
                          }
                          else {
                            // setSelectedCarrierTemplate(null)
                            let tempData = { ...selectedCarrierTemplate };
                            tempData[selectedTab] = null;
                            setSelectedCarrierTemplate({ ...tempData });
                            FlagApiLoader(false)
                          }

                        }
                        else if (editClearFlag == "clearAll") {
                          let tempArray = []
                          let tempLife = { ...globalCarrierDetails }
                          tempLife[selectedTab]?.map((obj) => {
                            if (obj?.agent_commission_carrier_assignment_id) {
                              tempArray.push(obj.agent_commission_carrier_assignment_id)
                            }
                          })
                          setDeletedCarrierId(tempArray)
                          setGlobalCarrierDetails({
                            ...globalCarrierDetails,
                            [selectedTab]: null
                          })

                          let tempData = { ...selectedCarrierTemplate };
                          tempData[selectedTab] = null;
                          setSelectedCarrierTemplate({ ...tempData });
                          setClearEditButtonVisible(false)
                          FlagApiLoader(false)
                        }
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagEditCarrierPopup(false) }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const AssignTemplateAlert = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // if (selectedLifeTemplate == null) {
                  //   if (openChangeTemplatePopup.type == "life") {
                  //     setSelectedLifeTemplate(null)
                  //   } else {
                  //     setSelectedAnnuityTemplate(null)
                  //   }
                  // }
                  setOpenChangeTemplatePopup({
                    flag: false,
                    type: "",
                    newValue: {}
                  })
                  if (!selectedCarrierTemplate[selectedTab]) {
                    if (openChangeTemplatePopup.type == selectedTab) {
                      let tempData = { ...selectedCarrierTemplate }
                      tempData[selectedTab] = null
                      setSelectedCarrierTemplate(tempData)
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to change to {openChangeTemplatePopup?.newValue?.template_name} template?
            </Grid>
            <Grid item xs={12} color="#00345E" textAlign="center" className="popup-note">
              <>
                <div>
                  Note: {
                    // (selectedTab == "annuity" && selectedAnnuityTemplate?.template_name) ?
                    //   selectedAnnuityTemplate?.template_name + " Template assignment will be removed." :
                    //   (selectedTab == "life" && selectedLifeTemplate?.template_name) ?
                    //     selectedLifeTemplate?.template_name + " Template assignment will be removed." :
                    //     " All carriers will be removed"
                    (selectedTab && selectedCarrierTemplate[selectedTab]?.template_name) ? selectedCarrierTemplate[selectedTab]?.template_name + " Template assignment will be removed." : " All carriers will be removed"
                  }
                </div>
              </>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        // debugger
                        FlagApiLoader(true)
                        if (openChangeTemplatePopup.type == selectedTab) {
                          // setSelectedCarrierTemplate(openChangeTemplatePopup?.newValue)
                          let tempData = { ...selectedCarrierTemplate }
                          tempData[selectedTab] = openChangeTemplatePopup?.newValue
                          setSelectedCarrierTemplate(tempData)
                        }
                        if (flagNewAgentPage) {
                          GetTemplateDataFunction(openChangeTemplatePopup?.newValue?.template_id)
                        } else {
                          AssignTemplateFunction(openChangeTemplatePopup?.newValue?.template_id)
                        }
                        let tempFlag = { ...openChangeTemplatePopup }
                        tempFlag.flag = false;
                        setOpenChangeTemplatePopup(tempFlag);
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        if (!selectedCarrierTemplate[selectedTab]) {
                          if (openChangeTemplatePopup.type == selectedTab) {
                            let tempData = { ...selectedCarrierTemplate }
                            tempData[selectedTab] = tempData[selectedTab] ? tempData[selectedTab] : null
                            setSelectedCarrierTemplate(tempData)
                          }
                        }
                        else {
                          // console.log("test");
                          let tempData = { ...selectedCarrierTemplate }
                          tempData[selectedTab] = tempData[selectedTab] !== null ? tempData[selectedTab] : null
                          setSelectedCarrierTemplate(tempData)
                          // let tempData = { ...selectedCarrierTemplate };
                          // tempData[selectedTab] = tempSelectedTemplate;
                          // setSelectedCarrierTemplate(tempData);
                          // setTempSelectedTemplate({});
                        }
                        setFlagUpdatedState(new Date().getTime())
                        setOpenChangeTemplatePopup({
                          flag: false,
                          type: "",
                          newValue: {}
                        })
                      }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
  // COMPONENT:--------

  console.log("tempSelectedTemplate", tempSelectedTemplate);



  return (
    <Fragment>
      {flagResendNdaPopup.flag && (
        <GlobalDialog
          open={flagResendNdaPopup.flag}
          data={RemindNdaPopup()}
          maxWidth={"sm"}
          sxDialog={{ borderRadius: "20px" }}
        />
      )}
      {flagSecondaryDetailsAlert && (
        <GlobalDialog
          open={flagSecondaryDetailsAlert}
          data={SecondaryDetailAlert()}
          maxWidth={"sm"}
          sxDialog={{ borderRadius: "20px" }}
        />
      )}
      {flagNdaInfoPopup && (
        <GlobalDialog
          open={flagNdaInfoPopup}
          data={NdaInfoPopup()}
          maxWidth={"sm"}
          className="nda_info_popup"
          sxDialog={{ borderRadius: "25px" }}
        />
      )}
      {flagEditCarrierPopup && (
        <GlobalDialog
          open={flagEditCarrierPopup}
          data={EditCarrierPopup()}
          maxWidth={"sm"}
          sxDialog={{ borderRadius: "20px" }}
        />
      )}
      {openChangeTemplatePopup.flag && (
        <GlobalDialog
          open={openChangeTemplatePopup.flag}
          data={AssignTemplateAlert()}
          maxWidth={"sm"}

        />
      )}
      <Helmet title="myAdvisorGrids | Add New Agent" />

      {/* Navigation Section */}
      <Grid justifyContent="space-between" container alignItems="center" mb={2} className="agentmainPageStickyHeader">
        <Grid item xl={10} sx={{ textAlign: "center" }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xl={0.8}>
              <IconButton
                sx={{ padding: "0px 10px 0px 0px" }}
                onClick={() => {
                  navigate("/")
                }}
              >
                <BackIconComponent />
              </IconButton>
            </Grid>
            <Grid item xl={8} textAlign={"left"}>
              <Typography variant="h3" gutterBottom display="inline">
                {agentId == null ? "New Agent" : primaryUsername}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider mt={4} mb={2} />
        </Grid>
      </Grid>

      {/* Content Section */}
      <Grid container className="cg-global-inner-scrollable-wrapper-style">
        <Grid item className="main_box_shadow" xs={12} sx={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
          {/* ACCORION START */}
          <Accordion
            expanded={flagAccordianExpanded}
            sx={{
              backgroundColor: "#EFEFF0",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="add_agent_accordian_summary"
              onClick={() => setFlagAccordianExpanded(!flagAccordianExpanded)}
            >
              {!flagAccordianExpanded && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent={{ xs: "center", lg: "start" }}
                >
                  {(agentUserData?.primary?.username || agentUserData?.primary?.email_address) && (
                    <Grid item marginRight="10px">
                      <Grid container justifyContent={{ xs: "center", md: "flex-start" }} sx={{ alignItems: 'center' }}>
                        {(agentUserData?.primary?.username || agentUserData?.primary?.email_address) && (
                          <Grid item textAlign="center" sx={{ marginRight: { xs: "15px", md: "15px", lg: "10px", xl: "15px" } }}>
                            <img src={PrimaryLogo} />
                          </Grid>
                        )}

                        {(agentUserData?.primary?.username) && (
                          <Grid item textAlign="center" sx={{ marginRight: { xs: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                            <img src={Agent} />
                          </Grid>
                        )}

                        <Grid item sx={{ marginRight: { xs: "10px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.primary?.username}
                          </Typography>
                        </Grid>

                        {agentUserData?.primary?.email_address && (
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>|</Grid>

                            <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                              <img src={Mail} />
                            </Grid>
                          </>
                        )}
                        <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.primary?.email_address}
                          </Typography>
                        </Grid>
                        {!flagNewAgentPage &&
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                              |
                            </Grid>
                            <Grid item sx={{ marginRight: { lg: "5px", xl: "10px" } }}>
                              {/* {agentUserData?.primary?.nda_status[0]?.nda_activity == "NDA signed" ?
                                <span style={{ color: "#3FA976" }}>Signed</span>
                                :
                                <span style={{ color: "#D65656" }}>Not Signed</span>
                              } */}
                              {agentUserData?.primary?.user_privacy_policy_accepted_flag ?
                                <span style={{ color: "#3FA976" }}>Accepted</span>
                                :
                                <span style={{ color: "#D65656" }}>Not Accepted</span>
                              }
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Grid>
                  )}

                  {(agentUserData?.secondary?.username || agentUserData?.secondary?.email_address) && (
                    <Grid item marginRight="10px">
                      <Grid container justifyContent={{ xs: "center", md: "flex-start", }} sx={{ alignItems: 'center' }}>
                        {(agentUserData?.secondary?.username || agentUserData?.secondary?.email_address) &&
                          <>
                            <Grid item textAlign="center" sx={{ marginRight: { xs: "15px", md: "15px", lg: "10px", xl: "15px" } }}>
                              <img src={SecondaryLogo2} />
                            </Grid>
                          </>
                        }

                        {agentUserData?.secondary?.username &&
                          <Grid item textAlign="center" sx={{ marginRight: { xs: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                            <img src={Agent} />
                          </Grid>
                        }

                        <Grid item sx={{ marginRight: { xs: "10px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.secondary?.username}
                          </Typography>
                        </Grid>

                        {agentUserData?.secondary?.email_address &&
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                              |
                            </Grid>

                            <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                              <img src={Mail} />
                            </Grid>
                          </>
                        }

                        <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.secondary?.email_address}
                          </Typography>
                        </Grid>

                        {!flagNewAgentPage &&
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                              |
                            </Grid>
                            <Grid item>
                              {/* {agentUserData?.secondary?.nda_status[0]?.nda_activity == "NDA signed" ?
                                <span style={{ color: "#3FA976" }}>Signed</span>
                                :
                                <span style={{ color: "#D65656" }}>Not Signed</span>
                              } */}

                              {agentUserData?.secondary?.user_privacy_policy_accepted_flag ?
                                <span style={{ color: "#3FA976" }}>Accepted</span>
                                :
                                <span style={{ color: "#D65656" }}>Not Accepted</span>
                              }
                            </Grid>
                          </>
                        }

                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container >
                      {agentUserData?.carrier_type?.length > 0 && agentUserData?.carrier_type?.map((ele) => {
                        const detail = carrierTypeDetails?.find(detail => detail.carrier_type_name == ele);
                        return (
                          <Grid item
                            sx={{
                              color: detail ? detail.carrier_type_color : 'gray',
                              border: `1px solid ${detail ? detail.carrier_type_color : 'gray'}`,
                              padding: '10px',
                              borderRadius: '10px',
                              fontSize: '13px',
                              fontWeight: '500',
                              minWidth: '60px',
                              height: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textTransform: 'capitalize',
                              margin: '0px 5px'
                            }}
                          >
                            {ele}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </AccordionSummary>

            <AccordionDetails sx={{ marginTop: "-40px", marginBottom: "20px" }}>
              <Grid
                container
                // flexDirection="column"
                justifyContent="center"
                alignItems="center"
              // sx={{ marginTop: "-30px" }}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    // flexDirection={{ xs: "column" }}
                    justifyContent="center"
                    rowGap={6}
                  >
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                      <Grid container rowGap={{ xs: 5, sm: 0 }} justifyContent={{ xs: "center", md: "space-between" }}>
                        <Grid item xs={11} sm={5.6} md={5.7} lg={5.8} xl={5.8} >
                          <Grid container>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center" mb={4}>
                              <Typography fontWeight="600" sx={{ color: "#1565C0", fontSize: "15px" }} >Primary Agent</Typography>
                            </Grid>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} sx={{ backgroundColor: "#F9F9FC", borderRadius: "10px", border: flagDisableUserDetails.primary == false ? "1px solid #1565C0" : "" }}>
                              <Grid container rowGap={3} justifyContent={{ xs: "center" }} sx={{ padding: "20px" }}>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "req_fields user_field_color_disable" : "req_fields user_field_color_enable"}
                                        label="Name"
                                        value={agentUserData?.primary?.username}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['username'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        fullWidth
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.username
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "username") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "username") }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className="req_fields"
                                        label="Email"
                                        value={agentUserData?.primary?.email_address}
                                        disabled={flagNewAgentPage == false ? true : false}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['email_address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.email_address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "email_address") }}
                                        error={flagEmailHelperText?.primary}
                                        onBlur={(event) => {
                                          TextFieldBlurStatus("primary", "email_address")
                                          let oldFlag = { ...flagEmailHelperText }
                                          if ((/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/.test(event.target.value)) || !event.target.value) {
                                            oldFlag.primary = false
                                            setFlagEmailHelperText(oldFlag)
                                          } else {
                                            oldFlag.primary = true
                                            setFlagEmailHelperText(oldFlag)
                                          }

                                        }}
                                        // sx={{ width: { xs: "210px", sm: "120px", md: "120px", lg: "240px", xl: "280px" } }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Address"
                                        value={agentUserData?.primary?.address}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "address") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "address") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="State"
                                        value={agentUserData?.primary?.state}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['state'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.state
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "state") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "state") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Country"
                                        value={agentUserData?.primary?.country}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['country'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.country
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "country") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "country") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Zip code"
                                        type="number"
                                        value={agentUserData?.primary?.zip_code}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['zip_code'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.zip_code
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "zip_code") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "zip_code") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {userDetail[0]?.user_role_name == "Admin" &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                          label="SSO Email"
                                          value={agentUserData?.primary?.sso_email}
                                          onChange={(e) => {
                                            let tempUserData = { ...agentUserData };
                                            tempUserData['primary']['sso_email'] = e.target.value;
                                            setAgentUserData(tempUserData);
                                          }}
                                          disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                          InputLabelProps={{
                                            shrink: flagTextFieldFocus?.primary?.sso_email
                                          }}
                                          onFocus={() => { TextFieldFocusStatus("primary", "sso_email") }}
                                          onBlur={() => { TextFieldBlurStatus("primary", "sso_email") }}
                                          fullWidth
                                        />
                                      </Grid>
                                    }
                                    {!flagNewAgentPage &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-multiline-flexible"
                                          label="NDA Status"
                                          // NEW CHANGES : AFTER PRIVACY POLICY
                                          // value={(customNdaStatus?.type == "primary" && customNdaStatus?.message?.length) ? customNdaStatus.message : agentUserData?.primary?.nda_status?.length ? agentUserData?.primary?.nda_status[0]?.nda_activity_message : ""}
                                          value={(customNdaStatus?.type == "primary" && customNdaStatus?.message?.length) ? customNdaStatus.message : agentUserData?.primary?.user_privacy_policy_consent?.length ? agentUserData?.primary?.user_privacy_policy_consent[0]?.privacy_policy_activity_message : ""}
                                          disabled={true}
                                          fullWidth
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position='end'>
                                                {flagShowExpiredIcon.primary
                                                  ?
                                                  <CustomTooltip title="Send New NDA" color="#1565C0">
                                                    <Typography
                                                      variant="span"
                                                      sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                      onClick={() => {
                                                        setFlagResendNdaPopup({
                                                          flag: true,
                                                          type: "primary",
                                                          expiredFlag: true
                                                        })
                                                      }}
                                                    >
                                                      Send New NDA
                                                    </Typography>
                                                  </CustomTooltip>
                                                  :
                                                  <></>
                                                  // flagShowRemindIcon.primary == false &&
                                                  // <CustomTooltip title="Reminder NDA" color="#1565C0">
                                                  //   <Typography
                                                  //     variant="span"
                                                  //     sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                  //     onClick={() => {
                                                  //       setFlagResendNdaPopup({
                                                  //         flag: true,
                                                  //         type: "primary",
                                                  //         expiredFlag: false
                                                  //       })
                                                  //     }}
                                                  //   >
                                                  //     Remind
                                                  //   </Typography>
                                                  // </CustomTooltip>
                                                }
                                                <CustomTooltip title="NDA Status Log" color="#1565C0">
                                                  <Typography
                                                    variant="span"
                                                    sx={{ cursor: "pointer", color: "#1565C0", fontSize: "13px", textDecoration: "underline" }}
                                                    onClick={() => {
                                                      setNdaStatus("primary"); setFlagNdaInfoPopup(true)
                                                    }}
                                                  >
                                                    Logs
                                                  </Typography>
                                                </CustomTooltip>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>

                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {!flagNewAgentPage && userDetail[0]?.user_role_name == "Admin" &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          label="Created by"
                                          value={agentUserData?.primary?.created_by}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          disabled={true}
                                          fullWidth
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center">
                                  {(!flagNewAgentPage && flagDisableUserDetails.primary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        setFlagDisableUserDetails((old) => {
                                          let temp = { ...old }
                                          temp.primary = false
                                          return temp
                                        })
                                      }}
                                    >
                                      Edit Details
                                    </Button>
                                  }
                                  {(!flagNewAgentPage && !flagDisableUserDetails.primary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        UpdateUserDetails("primary")
                                      }}
                                    >
                                      Save Details
                                    </Button>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={11} sm={5.6} md={5.7} lg={5.8} xl={5.8}>
                          <Grid container>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center" mb={4}>
                              <Typography fontWeight="600" sx={{ color: "#1565C0", fontSize: "15px" }} >Secondary Agent</Typography>
                            </Grid>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} sx={{ backgroundColor: "#F9F9FC", borderRadius: "10px", border: flagDisableUserDetails.secondary == false ? "1px solid #1565C0" : "" }}>
                              <Grid container rowGap={3} justifyContent={{ xs: "center" }} sx={{ padding: "20px" }}>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Name"
                                        value={agentUserData?.secondary?.username}
                                        defaultValue={agentUserData?.secondary?.username}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['username'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.username
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "username") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "username") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        // className="user_field_color_disable"
                                        label="Email"
                                        value={agentUserData?.secondary?.email_address}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary) ? true : false}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['email_address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.email_address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "email_address") }}
                                        error={flagEmailHelperText?.secondary}
                                        onBlur={(event) => {
                                          TextFieldBlurStatus("secondary", "email_address")
                                          let oldFlag = { ...flagEmailHelperText }
                                          if ((/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/.test(event.target.value)) || !event.target.value) {
                                            oldFlag.secondary = false
                                            setFlagEmailHelperText(oldFlag)
                                          } else {
                                            oldFlag.secondary = true
                                            setFlagEmailHelperText(oldFlag)
                                          }
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Address"
                                        value={agentUserData?.secondary?.address}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "address") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "address") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="State"
                                        value={agentUserData?.secondary?.state}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['state'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.state
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "state") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "state") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Country"
                                        value={agentUserData?.secondary?.country}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['country'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.country
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "country") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "country") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Zip code"
                                        type="number"
                                        value={agentUserData?.secondary?.zip_code}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['zip_code'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.zip_code
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "zip_code") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "zip_code") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {userDetail[0]?.user_role_name == "Admin" &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                          label="SSO Email"
                                          value={agentUserData?.secondary?.sso_email}
                                          onChange={(e) => {
                                            let tempUserData = { ...agentUserData };
                                            tempUserData['secondary']['sso_email'] = e.target.value;
                                            setAgentUserData(tempUserData);

                                          }}
                                          disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                          InputLabelProps={{
                                            shrink: flagTextFieldFocus?.secondary?.sso_email
                                          }}
                                          onFocus={() => { TextFieldFocusStatus("secondary", "sso_email") }}
                                          onBlur={() => { TextFieldBlurStatus("secondary", "sso_email") }}
                                          fullWidth
                                        />
                                      </Grid>
                                    }

                                    {!flagNewAgentPage &&
                                      (flagShowNdaSecondary ?
                                        <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                          <TextField
                                            variant="standard"
                                            id="standard-basic"
                                            label="NDA Status"
                                            // value={(customNdaStatus?.type == "secondary" && customNdaStatus?.message?.length) ? customNdaStatus.message : agentUserData?.secondary?.nda_status?.length ? agentUserData?.secondary?.nda_status[0]?.nda_activity_message : ""}
                                            value={(customNdaStatus?.type == "secondary" && customNdaStatus?.message?.length) ? customNdaStatus.message : agentUserData?.secondary?.user_privacy_policy_consent?.length ? agentUserData?.secondary?.user_privacy_policy_consent[0]?.privacy_policy_activity_message : ""}
                                            disabled={true}
                                            fullWidth
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position='end'>
                                                  {flagShowExpiredIcon.secondary ?
                                                    <CustomTooltip title="Send New NDA" color="#1565C0">
                                                      <Typography
                                                        variant="span"
                                                        sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                        onClick={() => {
                                                          setFlagResendNdaPopup({
                                                            flag: true,
                                                            type: "secondary",
                                                            expiredFlag: true
                                                          })
                                                        }}
                                                      >
                                                        Send New NDA
                                                      </Typography>
                                                    </CustomTooltip>
                                                    :
                                                    <></>
                                                    // flagShowRemindIcon.secondary == false &&
                                                    // <CustomTooltip title="Reminder NDA" color="#1565C0">
                                                    //   <Typography
                                                    //     variant="span"
                                                    //     sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                    //     onClick={() => {
                                                    //       setFlagResendNdaPopup({
                                                    //         flag: true,
                                                    //         type: "secondary",
                                                    //         expiredFlag: false
                                                    //       })
                                                    //     }}
                                                    //   >
                                                    //     Remind
                                                    //   </Typography>
                                                    // </CustomTooltip>
                                                  }
                                                  <CustomTooltip title="NDA Status Log" sx={{ backgroundColor: "red" }}>
                                                    <Typography
                                                      variant="span"
                                                      sx={{ cursor: "pointer", color: "#1565C0", fontSize: "13px", textDecoration: "underline" }}
                                                      onClick={() => {
                                                        setNdaStatus("secondary"); setFlagNdaInfoPopup(true)
                                                      }}
                                                    >
                                                      Logs
                                                    </Typography>
                                                  </CustomTooltip>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        :
                                        ""
                                      )
                                    }
                                  </Grid>
                                </Grid>

                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {!flagNewAgentPage && userDetail[0]?.user_role_name == "Admin" && flagShowNdaSecondary &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          label="Created by"
                                          value={agentUserData?.secondary?.created_by}
                                          InputLabelProps={{
                                            shrink: agentUserData?.secondary?.created_by?.length ? true : false
                                          }}
                                          disabled={true}
                                          fullWidth
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>

                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center">
                                  {(!flagNewAgentPage && !flagShowNdaSecondary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        if ((!agentUserData?.secondary?.username) && (!agentUserData?.secondary?.email_address)) {
                                          UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Please enter secondary agent's name and email"
                                          });
                                        } else if (!agentUserData?.secondary?.username) {
                                          UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Please enter secondary agent's name"
                                          });
                                        } else if (!agentUserData?.secondary?.email_address) {
                                          UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Please enter secondary agent's email"
                                          });
                                        } else {
                                          setFlagSecondaryDetailsAlert(true)
                                        }
                                      }}
                                    >
                                      Save Details
                                    </Button>
                                  }
                                  {(!flagNewAgentPage && flagShowNdaSecondary && flagDisableUserDetails.secondary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        setFlagDisableUserDetails((old) => {
                                          let temp = { ...old }
                                          temp.secondary = false
                                          return temp
                                        })
                                      }}
                                    >
                                      Edit Details
                                    </Button>
                                  }
                                  {(!flagNewAgentPage && flagShowNdaSecondary && !flagDisableUserDetails.secondary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        UpdateUserDetails("secondary")
                                      }}
                                    >
                                      Save Details
                                    </Button>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Type Selection Section */}
                    <Grid item xs={9} sm={6.8} md={6} lg={5} xl={5}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            variant="outlined"
                            sx={{ color: "#1F1F1F" }}
                            gutterBottom
                            display="inline"
                          >
                            Type <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Grid>

                        {/* {carrierTypeDetails.map((ele) => {
                          const flagKey = `carrier_type_${ele.carrier_type_name.toLowerCase()}`;
                          return (
                            <Grid item key={ele.carrier_type_id}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{ padding: "0px 5px 0px 20px" }}
                                      checked={agentUserData[flagKey]}
                                      onChange={(e) => {
                                        const updatedDetails = {
                                          ...agentUserData,
                                          [flagKey]: e.target.checked
                                        };
                                        setAgentUserData(updatedDetails);
                                      }}
                                      value={agentUserData[flagKey]}
                                      name={ele.carrier_type_name.toLowerCase()}
                                      icon={<img src={UnCheckedIcon} />}
                                      checkedIcon={<img src={CheckedIcon} />}
                                    />
                                  }
                                  label={ele.carrier_type_name}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })} */}
                        {carrierTypeDetails?.map((ele) => {
                          // Use the carrier type name directly as the key in the state.
                          const carrierKey = ele.carrier_type_name.toLowerCase();
                          const formattedCarrierTypeName = ele.carrier_type_name.charAt(0).toUpperCase() + ele.carrier_type_name.slice(1).toLowerCase();
                          return (
                            <Grid item key={ele.carrier_type_id}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{ padding: "0px 5px 0px 20px" }}
                                      checked={agentUserData?.carrier_type?.filter((data) => data == carrierKey)?.length > 0}  // Directly use the carrierKey
                                      onChange={(e) => {

                                        let tempData = { ...agentUserData }
                                        // tempData.carrier_type.push(e.target.value)
                                        // setAgentUserData(tempData)
                                        // if (e.target.checked) {
                                        //   // Add the carrierKey if checked
                                        //   if (!tempData.carrier_type.includes(carrierKey)) {
                                        //     tempData.carrier_type.push(carrierKey);
                                        //   }
                                        // } else {
                                        //   // Remove the carrierKey if unchecked using splice
                                        //   const index = tempData.carrier_type.indexOf(carrierKey);
                                        //   if (index > -1) {
                                        //     tempData.carrier_type.splice(index, 1);
                                        //   }
                                        // }

                                        if (e.target.checked) {
                                          // Add the carrierKey if checked
                                          if (!tempData.carrier_type.includes(carrierKey)) {
                                            tempData.carrier_type.push(carrierKey);
                                          }
                                          // Set the first selected tab when checked
                                          if (tempData.carrier_type.length === 1) {
                                            setSelectedTab(carrierKey);  // Set the first carrierKey as the selected tab
                                          }
                                        } else {
                                          // Remove the carrierKey if unchecked
                                          const index = tempData.carrier_type.indexOf(carrierKey);
                                          if (index > -1) {
                                            tempData.carrier_type.splice(index, 1);
                                          }
                                          // Optionally, update the selected tab when unchecking all
                                          if (tempData.carrier_type.length === 0) {
                                            setSelectedTab(null);  // Reset the selected tab when all are unchecked
                                          }
                                        }
                                        setAgentUserData(tempData);
                                        FlagApiLoader(false)

                                      }}
                                      value={carrierKey}
                                      name={carrierKey}
                                      icon={<img src={UnCheckedIcon} />}
                                      checkedIcon={<img src={CheckedIcon} />}
                                    />
                                  }
                                  label={formattedCarrierTypeName}
                                />
                              </FormGroup>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* ACCORDION END */}

          {/* TABLE SECTION */}
          {(agentUserData?.carrier_type?.length) ?

            <Grid
              container
              sx={{
                backgroundColor: "#EFEFF0",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <Grid item xs={12}>
                <Grid container>
                  {/* TAB SELECTION SECTION */}
                  <Grid item xs={12}>
                    <Grid container alignItems="space-between" justifyContent="space-between">
                      <Grid item>
                        <Grid container columnGap={{ xs: 2, sm: 4 }}>
                          {/* {
                            carrierTypeDetails.map((types) => {
                              const carrierKey = types.carrier_type_name.toLowerCase();
                              // Directly check the state using the carrierKey
                              if (!agentUserData[carrierKey]) {
                                return null;
                              }
                              return (
                                <Grid item sx={{ cursor: "pointer" }} onClick={() => setSelectedTab(types.carrier_type_name.toLowerCase())}>
                                  <Grid container
                                    alignItems="end"
                                    backgroundColor={selectedTab === types.carrier_type_name.toLowerCase() ? "#FFFFFF" : "#D7D7D7"}
                                    paddingX={{ xs: 2, sm: 3 }}
                                    columnGap={{ xs: 1.5, sm: 3 }}
                                    sx={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", paddingBottom: "18px", height: "54px" }}>
                                    <Grid item sx={{ marginBottom: "4px" }}>
                                      <Typography component="span" color={selectedTab === types.carrier_type_name.toLowerCase() ? "#1565C0" : "#7A7A7A"}>
                                        {types.carrier_type_name}
                                      </Typography>
                                      <Typography component="span" color="#7A7A7A" display={{ xs: "none", md: "inline" }}>
                                        {" | Template"}
                                      </Typography>
                                    </Grid>
                                    <Grid item sx={{ display: selectedTab == types.carrier_type_name.toLowerCase() ? "inline" : { xs: "none", sm: "inline" } }}>
                                      <FormControl variant="standard" sx={{ minWidth: 150 }}
                                        disabled={selectedTab !== types.carrier_type_name.toLowerCase()}
                                      >
                                        <InputLabel id="demo-simple-select-standard-label" shrink={false}> {selectedCarrierTemplate[types.carrier_type_name.toLowerCase()] == null ? "Select Template" : ""}</InputLabel>
                                        <Select
                                          className="addNewAgent-select"
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          MenuProps={{
                                            sx: { zIndex: 2002 }
                                          }}
                                          sx={{ paddingLeft: "10px" }}
                                          value={
                                            globalTemplateList && globalTemplateList[types?.carrier_type_name.toLowerCase()]?.filter(rr => rr?.template_id == selectedCarrierTemplate[types.carrier_type_name.toLowerCase()]?.template_id)[0]
                                          }
                                          onChange={(e) => handleChangeTemplate(types.carrier_type_name.toLowerCase(), e)}
                                          label={types.carrier_type_name.charAt(0).toUpperCase() + types.carrier_type_name.slice(1) ? types.carrier_type_name.charAt(0).toUpperCase() + types.carrier_type_name.slice(1) : ''}
                                        >
                                          {globalTemplateList && globalTemplateList[types?.carrier_type_name.toLowerCase()] && globalTemplateList[types?.carrier_type_name.toLowerCase()]?.length > 0 ? (
                                            globalTemplateList[types?.carrier_type_name.toLowerCase()]?.map((item) => (
                                              <MenuItem key={item.template_id} value={item}>
                                                {item.template_name}
                                              </MenuItem>
                                            ))
                                          ) : (
                                            <MenuItem disabled value="">
                                              No data found
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })
                          } */}

                          {
                            agentUserData?.carrier_type.map((types) => {
                              const formattedType = types.charAt(0).toUpperCase() + types.slice(1).toLowerCase();
                              console.log("typeee", types);
                              return (
                                <Grid item
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => { setSelectedTab(types.toLowerCase()) }}>
                                  <Grid container
                                    alignItems="end"
                                    backgroundColor={selectedTab == types ? "#FFFFFF" : "#D7D7D7"}
                                    paddingX={{ xs: 2, sm: 3 }}
                                    columnGap={{ xs: 1.5, sm: 3 }}
                                    sx={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px", paddingBottom: "13px", height: "54px" }}>
                                    <Grid item sx={{ marginBottom: "4px" }}>
                                      <Typography component="span"
                                        color={selectedTab === types.toLowerCase() ? "#1565C0" : "#7A7A7A"}
                                      >
                                        {formattedType}
                                      </Typography>
                                      <Typography component="span" color="#7A7A7A" display={{ xs: "none", md: "inline" }}>
                                        {" | Template"}
                                      </Typography>
                                    </Grid>
                                    {/* SELECT CODE DO NOT REMOVE */}
                                    {/* <Grid item
                                      sx={{ display: selectedTab == types.toLowerCase() ? "inline" : { xs: "none", sm: "inline" } }}
                                    >
                                      <FormControl variant="standard" sx={{ minWidth: 150 }}
                                        disabled={selectedTab !== types.toLowerCase()}
                                      >
                                        <InputLabel id="demo-simple-select-standard-label"
                                          shrink={globalTemplateList[types?.toLowerCase()]?.filter(rr => rr?.template_id == selectedCarrierTemplate[types.toLowerCase()]?.template_id)[0] ? true : false}>
                                          {selectedCarrierTemplate[types?.toLowerCase()] == null ? "Select Template" : ""}
                                        </InputLabel>
                                        <Select
                                          className="addNewAgent-select"
                                          labelId="demo-simple-select-standard-label"
                                          id="demo-simple-select-standard"
                                          MenuProps={{
                                            sx: { zIndex: 2002 }
                                          }}
                                          sx={{ paddingLeft: "10px" }}
                                          value={globalTemplateList && globalTemplateList[types?.toLowerCase()]?.filter(rr => rr?.template_id == selectedCarrierTemplate[types.toLowerCase()]?.template_id)[0]}
                                          // value={selectedCarrierTemplate[types.toLowerCase()] ? globalTemplateList[types.toLowerCase()]?.find(rr => rr.template_id == selectedCarrierTemplate[types.toLowerCase()]) : ""}
                                          onChange={(e) => handleChangeTemplate(types.toLowerCase(), e)}
                                          label={types.charAt(0).toUpperCase() + types.slice(1) ? types.charAt(0).toUpperCase() + types.slice(1) : ''}
                                        >
                                          {globalTemplateList && globalTemplateList[types?.toLowerCase()] && globalTemplateList[types?.toLowerCase()]?.length > 0 ? (
                                            globalTemplateList[types?.toLowerCase()]?.map((item) => (
                                              <MenuItem key={item.template_id} value={item}>
                                                {item.template_name}
                                              </MenuItem>
                                            ))
                                          ) : (
                                            <MenuItem disabled value="">
                                              No data found
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid> */}
                                    <Grid item sx={{ display: selectedTab === types.toLowerCase() ? "inline" : { xs: "none", sm: "inline" }, }}>
                                      <FormControl
                                        variant="standard"
                                        sx={{
                                          minWidth: "150px",
                                        }}
                                        disabled={selectedTab !== types.toLowerCase()}
                                      >
                                        <Autocomplete
                                          size="small"
                                          disableClearable
                                          className="agent-dropdown"
                                          options={globalTemplateList[types?.toLowerCase()] || []}
                                          getOptionLabel={(option) => option.template_name || ""}
                                          value={globalTemplateList[types?.toLowerCase()]?.find((rr) => rr.template_id == selectedCarrierTemplate[types.toLowerCase()]?.template_id) || null}
                                          disablePortal={true}
                                          noOptionsText="No data found"
                                          // onChange={(e, newValue) => handleChangeTemplate(types.toLowerCase(), newValue)}
                                          onChange={(e, newValue) => {
                                            if (selectedCarrierTemplate[types.toLowerCase()]?.template_id !== newValue.template_id) {
                                              handleChangeTemplate(types.toLowerCase(), newValue);
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              fullWidth
                                              variant="standard"
                                              className="agent-dropdown-textfield"
                                              size="small"
                                              sx={{ "& .MuiInputBase-input": { minWidth: 'fit-content !important', width: 'fit-content !important', } }}
                                              label={selectedCarrierTemplate[types?.toLowerCase()] == null ? "Select Template" : ""}
                                              InputLabelProps={{
                                                shrink: Boolean(
                                                  globalTemplateList[types?.toLowerCase()]?.some((rr) => rr.template_id === selectedCarrierTemplate[types.toLowerCase()]?.template_id)
                                                ),
                                                sx: {
                                                  "& .MuiInputBase-input": {
                                                    minWidth: 'max-content !important',
                                                    maxWidth: 'max-content !important'
                                                  }
                                                }

                                              }}
                                              InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                  fontSize: '14px',
                                                  "& .MuiInputBase-input": {
                                                    minWidth: 'max-content', // Set min-width to max-content
                                                  },
                                                },
                                              }}
                                              PaperComponent={({ children }) => (
                                                <Paper
                                                  sx={{
                                                    minWidth: 'auto',
                                                    width: "auto"
                                                  }}
                                                >
                                                  {children}
                                                </Paper>
                                              )}
                                            />
                                          )}
                                          componentsProps={{
                                            popper: {
                                              modifiers: [
                                                {
                                                  name: 'flip',
                                                  enabled: false
                                                }
                                              ]
                                            }
                                          }}
                                          PopperComponent={(props) => (
                                            <Popper
                                              placement="bottom-start"
                                              {...props}
                                              sx={{
                                                zIndex: "2005 !important",
                                                width: 'max-content',
                                                minWidth: 'max-content',
                                              }}
                                              className="va-table-dropdown-paper-shadow"
                                            />
                                          )}

                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })
                          }
                        </Grid>
                      </Grid>

                      <Grid item>
                        {((editButtonVisible == true)) ?
                          (
                            <Button
                              variant="contained"
                              sx={{ marginBottom: "10px", paddingX: "30px", paddingY: "8px", marginRight: "5px" }}
                              className="clearEditBtn"
                              onClick={() => {
                                OpenEditCarrierPopup("editCarrier")
                              }}
                            >
                              Edit Carrier
                            </Button>
                          ) : (clearButtonVisible == true) ? (
                            <Button
                              variant="contained"
                              sx={{ marginBottom: "10px", paddingX: "30px", paddingY: "8px", marginRight: "5px" }}
                              onClick={() => { OpenEditCarrierPopup("clearAll") }}
                              className="clearEditBtn"
                            >
                              Clear All
                            </Button>
                          ) : (null)
                        }
                        {(delegateAccessFlag == false && agentId && primaryUsername) &&
                          <Button
                            variant="contained"
                            sx={{ padding: "8px 22px", marginLeft: "15px", marginBottom: "10px" }}
                            onClick={() => {
                              let obj = {
                                delegate_agent_id: agentId,
                                delegate_primary_agent_flag: true
                              }

                              GrantDelegateAccessForAdmin(obj, (res) => {
                                if (res?.data?.status) {
                                  window.location.href = "/"
                                } else {
                                  UpdateSnackbarMessage({
                                    status: "error",
                                    message: res?.data?.error?.message
                                  });
                                }
                              }, (err) => {
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: err?.message
                                });
                              })
                            }}
                          >
                            View As Agent
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* TAB SELECTION SECTION */}

                  {/* TAB CONTENT SECTION */}
                  <Grid item xs={12}>
                    <Grid
                      sx={{
                        backgroundColor: "#FFFFFF",
                        // width: { xs: "71vw", sm: "86vw", md: "77.5vw" },
                        // height: "45vh",
                        padding: "none !important",
                      }}
                      container
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <Grid item xs={12}>
                        <GlobalCarrierTable
                          flagAddPage={flagAddPage}
                          deletedCarrierId={deletedCarrierId}
                          setDeletedCarrierId={setDeletedCarrierId}
                          pageName="Agent"
                          editFlag={editFlag}
                          selectedTab={selectedTab}
                          flagNewAgentPage={flagNewAgentPage}
                        />
                      </Grid>

                      {(flagNewAgentPage == false && (selectedTab && selectedCarrierTemplate[selectedTab] == null)
                        // (selectedTab == "life" && selectedLifeTemplate == null) || (selectedTab == "annuity" && selectedAnnuityTemplate == null))
                      ) &&
                        <Grid item xs={12}>
                          <Grid
                            container
                            sx={{ justifyContent: "center", margin: "20px 0px" }}
                          >
                            <Grid item xl={1.5} lg={2} md={3} sm={3.5} xs={8} sx={{ textAlign: "center", margin: "0px 10px" }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  paddingX: "45px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#1565C0",
                                  borderColor: "#1565C0",
                                }}
                                fullWidth
                                onClick={() => { navigate("/") }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item xl={1.5} lg={2} md={3} sm={3.5} xs={8} sx={{ textAlign: "center", margin: { xs: "10px 10px 0", sm: "0px 10px" } }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  paddingX: "30px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  backgroundColor: "#1565C0",
                                }}
                                fullWidth
                                onClick={() => { UpdateAgent() }}
                                // onClick={() => createNewTemplate()}
                                disabled={agentUserData?.primary?.username?.length > 0 && agentUserData?.primary?.email_address?.length > 0 ? false : true}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  {/* TAB CONTENT SECTION */}
                </Grid>
              </Grid>
            </Grid> : <></>
          }
          {/* TABLE SECTION */}

          {/* BUTTON SECTION */}
          {(flagShowBottomButtons && flagNewAgentPage) && (
            <Grid item xs={12}>
              <Grid container sx={{ justifyContent: "center", margin: "20px 0px" }}>
                <Grid item xl={1.4} lg={2} md={2.5} sm={2.5} xs={8} sx={{ textAlign: "center", margin: "0px 10px" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      paddingX: "45px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#1565C0",
                      borderColor: "#1565C0",
                    }}
                    fullWidth
                    onClick={() => { navigate("/") }}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xl={1.4} lg={2} md={2.5} sm={2.5} xs={8} sx={{ textAlign: "center", margin: { xs: "10px 10px 0", sm: "0px 10px" } }}>
                  <Button
                    variant="contained"
                    sx={{
                      paddingX: "30px",
                      fontSize: "14px",
                      fontWeight: "500",
                      backgroundColor: "#1565C0",
                    }}
                    fullWidth
                    onClick={() => { CreateNewAgent() }}
                  // onClick={() => createNewTemplate()}
                  // disabled={agentUserData?.primary?.username?.length > 0 && agentUserData?.primary?.email_address?.length > 0 ? false : true}
                  >
                    Create Agent
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* BUTTON SECTION */}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddNewAgents;
