import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Verification from "./pages/auth/Verification";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components

// Form components

// Icon components

// Page components
import Templates from "./pages/pages/Templates";
import Pricing from "./pages/pages/Pricing";
import Carriers from "./pages/pages/Carriers";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";

// Table components

// Documentation

// Landing

// Protected routes
import Agents from "./pages/pages/Agents";
import { Context as AuthContext } from "./contexts/reducerContexts/authContext";
import { getGenericCarrierTypeDetails, UserMeApi } from "./utils/api/userDetailApi";
import { Navigate, Route, Routes, useRoutes } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "./pages/Loader/loader";
import AddNewTemplate from "./pages/pages/AddNewTemplate";
import AddNewAgents from "./pages/pages/AddNewAgent";
// import { AuthContext } from "./contexts/JWTContext";
import { Provider as TemplateProvider } from "./contexts/reducerContexts/templateContext";
import { Provider as CarrierTableProvider } from "./contexts/reducerContexts/globalCarrierContext";
import ViewCarrier from "./pages/pages/ViewCarrier";
import BATeam from "./pages/pages/BATeam";
import NdaAlert from "./pages/auth/NdaAlert";
import NdaError from "./layouts/NdaError";
import AddCarrier from "./pages/pages/AddCarrier";
import Agency from "./pages/pages/Agency/Agency";
import AgencyDetails from "./pages/pages/Agency/AgencyDetails";
import { ImportExport } from "@mui/icons-material";
import AgencyCarrierDetails from "./pages/pages/Agency/AgencyCarrierDetails";
import ApprovalWaiting from "./pages/pages/Agency/ApprovalWaiting";
import PrivacyPolicy from "./pages/pages/PrivacyPolicy/PrivacyPolicy";
import circleFav from "./assets/Global/circle-favicon.png"
import squareFav from "./assets/Global/square-favicon.png"

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components

// Icon components

// Table components

// Chart components

// Maps components
// Provider component
const GlobalRoutes = () => {

  const { state, UserMeContextApi, UpdateSnackbarMessage, FlagApiLoader, AgencyLogoAPI, setCarrierTypeDetails, setCarrierTypeDetailsForAgency } = useContext(AuthContext);

  const { flagAuthenticated, snackbarMessage, apiLoader, initialLoader, agencyLogo, delegateAccessFlag, } = {
    ...state,
  };

  const [routes, setRoutes] = useState([])

  useEffect(() => {
    console.log('USER DETAILS CONTEXT IN ROUTES USEEFECT', state.userDetail)
  }, [state?.userDetail])

  useEffect(() => {

    if (state?.userDetail?.length) {
      let agencyParentId = state?.userDetail[0]?.user_agency_data?.parent_agency_id;
      let userStatusName = state?.userDetail[0]?.user_status_name;
      let agencyAdminFlag = state?.userDetail[0]?.agency_admin_flag
      let userRoleName = state?.userDetail[0]?.user_role_name

      // NEW CONDITION IF NOT WORK THEN USE ABOVE
      if (state.userDetail[0]?.user_status_name && userStatusName?.trim()?.toLowerCase() == "registered") {
        if (delegateAccessFlag) {
          setRoutes([
            {
              path: "*",
              element: <NdaError />,
              children: [
                {
                  path: "*",
                  element: <NdaAlert />,
                },
              ],
            },
          ])
        }
        else {
          setRoutes([
            {
              path: "",
              element: state.userDetail[0]?.user_privacy_policy_accepted_flag ? <Navigate to="/nda_alert" replace={true} /> : <Navigate to="/privacy" replace={true} />,
              children: [
                {
                  path: "*",
                  element: state.userDetail[0]?.user_privacy_policy_accepted_flag ? <Navigate to="/nda_alert" replace={true} /> : <Navigate to="/privacy" replace={true} />,
                }
              ]
            },
            {
              path: "nda_alert",
              element: <NdaError />,
              children: [
                {
                  path: "",
                  element: <NdaAlert />,
                },
              ],
            },
            {
              path: "privacy",
              element: <PrivacyPolicy />,
              children: [
                {
                  path: "",
                  element: <PrivacyPolicy />,
                },
              ],
            },
          ])
        }
      }
      else {
        // CONDITION ADDED 
        if (agencyParentId == null && agencyAdminFlag == true && userRoleName?.trim()?.toLowerCase() == "member") {
          setRoutes([
            {
              path: "templates",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Templates />,
                },
                {
                  path: "view_template",
                  element: <AddNewTemplate />,
                }
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                },
              ],
            },
            {
              path: "/",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Agents />,
                },
                {
                  path: "add_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "view_agent",
                  element: <AddNewAgents />,
                },
              ],
            },
            {
              path: "agency",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Agency />,
                },
                {
                  path: "add_agency",
                  element: <AgencyCarrierDetails />,
                },
                {
                  path: "view_agency",
                  element: <AgencyCarrierDetails />,
                },
              ]
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ])
        }
        else if (userRoleName?.trim()?.toLowerCase() == "agent") {
          setRoutes([
            {
              path: "/",
              element: <TemplateProvider><DashboardLayout /></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view",
                  children: [
                    {
                      path: ":viewId",
                      element: <ViewCarrier />,
                    },
                  ],
                },
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                }
              ],
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ]);
        }
        // if (state.userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin" && state.userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance")
        else if (agencyParentId == null && agencyAdminFlag == true) {
          setRoutes([
            {
              path: "templates",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Templates />,
                },
                {
                  path: "add_template",
                  element: <AddNewTemplate />,
                },
                {
                  path: "view_template",
                  element: <AddNewTemplate />,
                }
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                },
                {
                  path: "add_carrier",
                  element: <ViewCarrier />,
                }
              ],
            },
            {
              path: "agency",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Agency />,
                },
                {
                  path: "add_agency",
                  element: <AgencyCarrierDetails />,
                },
                {
                  path: "view_agency",
                  element: <AgencyCarrierDetails />,
                },
                // {
                //   path: "approval_waiting",
                //   element: <ApprovalWaiting />,
                // }
              ]
            },
            {
              path: "/",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Agents />,
                },
                {
                  path: "add_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "view_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "users",
                  element: <BATeam />,
                },
              ],
            },
            {
              path: "invoices",
              element: <DashboardLayout />,
              children: [
                {
                  path: "",
                  element: <InvoiceList />,
                },
                {
                  path: "detail",
                  element: <InvoiceDetails />,
                },
              ],
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
                {
                  path: "404",
                  element: <Page404 />,
                },
                {
                  path: "500",
                  element: <Page500 />,
                },
              ],
            },
            {
              path: "nda_alert",
              element: <NdaError />,
              children: [
                {
                  path: "",
                  element: <NdaAlert />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ])
        }
        else if (agencyParentId == null && agencyAdminFlag == false && userRoleName?.trim()?.toLowerCase() == "admin") {
          setRoutes([
            {
              path: "templates",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Templates />,
                },
                {
                  path: "add_template",
                  element: <AddNewTemplate />,
                },
                {
                  path: "view_template",
                  element: <AddNewTemplate />,
                }
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                },
                {
                  path: "add_carrier",
                  element: <ViewCarrier />,
                }
              ],
            },
            {
              path: "/",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Agents />,
                },
                {
                  path: "add_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "view_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "users",
                  element: <BATeam />,
                },
              ],
            },
            {
              path: "invoices",
              element: <DashboardLayout />,
              children: [
                {
                  path: "",
                  element: <InvoiceList />,
                },
                {
                  path: "detail",
                  element: <InvoiceDetails />,
                },
              ],
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
                {
                  path: "404",
                  element: <Page404 />,
                },
                {
                  path: "500",
                  element: <Page500 />,
                },
              ],
            },
            {
              path: "nda_alert",
              element: <NdaError />,
              children: [
                {
                  path: "",
                  element: <NdaAlert />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ])
        }
        else if
          // (state.userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin" && state.userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() !== "brokers alliance") {
          (agencyParentId != null && userRoleName?.trim()?.toLowerCase() == "admin") {
          setRoutes([
            {
              path: "templates",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Templates />,
                },
                {
                  path: "add_template",
                  element: <AddNewTemplate />,
                },
                {
                  path: "view_template",
                  element: <AddNewTemplate />,
                }
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                },
                {
                  path: "add_carrier",
                  element: <ViewCarrier />,
                }
              ],
            },
            {
              path: "/",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Agents />,
                },
                {
                  path: "add_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "view_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "users",
                  element: <BATeam />,
                },
              ],
            },
            {
              path: "agency",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <AgencyCarrierDetails />,
                },
                // {
                //   path: "add_agency",
                //   element: <AgencyCarrierDetails />,
                // },
                // {
                //   path: "view_agency",
                //   element: <AgencyCarrierDetails />,
                // },
                // {
                //   path: "approval_waiting",
                //   element: <ApprovalWaiting />,
                // }
              ]
            },
            // {
            //   path: "agency",
            //   element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
            //   children: [
            //     {
            //       path: "",
            //       element: <Navigate to="/" />,
            //     },
            //     {
            //       path: "view_agency",
            //       element: <AgencyCarrierDetails />,
            //     },
            //   ],
            // },
            {
              path: "invoices",
              element: <DashboardLayout />,
              children: [
                {
                  path: "",
                  element: <InvoiceList />,
                },
                {
                  path: "detail",
                  element: <InvoiceDetails />,
                },
              ],
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
                {
                  path: "404",
                  element: <Page404 />,
                },
                {
                  path: "500",
                  element: <Page500 />,
                },
              ],
            },
            {
              path: "nda_alert",
              element: <NdaError />,
              children: [
                {
                  path: "",
                  element: <NdaAlert />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ])
        }
        else if (userRoleName?.trim()?.toLowerCase() == "member") {
          setRoutes([
            {
              path: "templates",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Templates />,
                },
                // {
                //   path: "add_template",
                //   element: <AddNewTemplate />,
                // },
                {
                  path: "view_template",
                  element: <AddNewTemplate />,
                }
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                },
              ],
            },
            {
              path: "/",
              element: (
                <TemplateProvider>
                  <CarrierTableProvider>
                    <DashboardLayout />
                  </CarrierTableProvider>
                </TemplateProvider>
              ),
              children: [
                {
                  path: "",
                  element: <Agents />,
                },
                {
                  path: "add_agent",
                  element: <AddNewAgents />,
                },
                {
                  path: "view_agent",
                  element: <AddNewAgents />,
                },
              ],
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ])
        }
        else {
          setRoutes([
            {
              path: "/",
              element: <TemplateProvider><DashboardLayout /></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view",
                  children: [
                    {
                      path: ":viewId",
                      element: <ViewCarrier />,
                    },
                  ],
                },
              ],
            },
            {
              path: "carriers",
              element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
              children: [
                {
                  path: "",
                  element: <Carriers />,
                },
                {
                  path: "view_carrier",
                  element: <ViewCarrier />,
                }
              ],
            },
            {
              path: "auth",
              element: <Navigate to="/" replace={true} />,
              children: [
                {
                  path: "sign",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
            {
              path: "*",
              element: <AuthLayout />,
              children: [
                {
                  path: "*",
                  element: <Navigate to="/" replace={true} />,
                },
              ],
            },
          ]);
        }
      }

    }
  }, [state.userDetail])

  const authenticatedRoutesElement = useRoutes(routes);


  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")

    if (agencyLogo?.agency_favicon_url == null && agencyLogo?.agency_name?.trim()?.toLowerCase() == "brokers alliance") {
      link.href = "https://ba-dev.myadvisorgrids.com/masterGridFav.png"
    }
    else if (agencyLogo && agencyLogo?.agency_favicon_url !== null) {
      link.href = agencyLogo?.agency_favicon_url;
    }
    else {
      link.href = squareFav;
      // link.href = "https://ba-dev.myadvisorgrids.com/masterGridFav.png";
    }
  }, [agencyLogo])

  useEffect(() => {
    (async () => {
      await AgencyLogoAPI();
      if (flagAuthenticated == null || flagAuthenticated == false) {
        await UserMeContextApi();
      }
      FlagApiLoader(false)
      await getGenericCarrierTypeDetails(
        (res) => {
          console.log("response of getGenericCarrierTypeDetails", res)
          if (res?.data?.status) {
            // Transform the carrier_type_name to lowercase before setting it to state
            const agencyData = res?.data?.data
            const transformedData = res?.data?.data?.map(carrierType => ({
              ...carrierType,
              carrier_type_name: carrierType.carrier_type_name.toLowerCase(),
            }));
            setCarrierTypeDetails(transformedData);
            setCarrierTypeDetailsForAgency(agencyData)

          }
        },
        (err) => {
          console.log("error of getGenericCarrierTypeDetails", err);
        })
    })();
  }, []);

  useEffect(() => {
    if (snackbarMessage?.status !== false) {
      setTimeout(function () {
        UpdateSnackbarMessage({ status: false, message: "" });
      }, 6000)
    }
  }, [snackbarMessage])

  const handleClose = () => {
    UpdateSnackbarMessage({ status: false, message: "" });
    return;
  };

  return (
    <>
      {apiLoader == true && <Loader />}
      {initialLoader == true ? (
        <>
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            my={6}
            mt="45vh"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Fragment>
          {snackbarMessage.status && (
            <Snackbar
              open={true}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={6000}
              onClose={handleClose}
              sx={{ zIndex: 2003 }}
            >
              <Alert
                onClose={handleClose}
                severity={snackbarMessage.status}
                sx={{ width: "100%", height: "100%" }}
              >
                {snackbarMessage.message}
              </Alert>
            </Snackbar>
          )}

          {
            flagAuthenticated == true ?
              (
                authenticatedRoutesElement
              )
              :
              (
                <Fragment>
                  <Routes>
                    <Route
                      path="/auth/sign"
                      element={
                        <AuthLayout>
                          <SignIn />
                        </AuthLayout>
                      }
                    />
                    <Route path="*" element={
                      <Navigate to="/auth/sign" />
                      // state?.userDetail?.length && state.userDetail[0]?.user_status_name.trim()?.toLowerCase() == "registered"
                      //   ?
                      //   <Navigate to="/" />
                      //   :
                      //   <Navigate to="/auth/sign" />
                    } />
                  </Routes>
                </Fragment>
              )
          }
        </Fragment>
      )}
    </>
  );
};

export default GlobalRoutes;
