import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../css/ViewCarriarStyles/ProductMappingDialog.css'

import { Autocomplete, Box, Button, Divider, Grid, IconButton, InputAdornment, Popper, TextField, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { addAICarrierProductAPI, getAICarrierProductListAPI, syncAICarrieWithMagCarrierproduct } from '../../../utils/api/CarriersApi';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { Close } from '@mui/icons-material';


function ProductMappingDialog(props) {

    const {
        setFlagProductMappingDialog,
        carrierId,
        setFlagCall,
        carrierDetails,
        criteriaCellData,
        productRowGroupDetails,

        flagNewProductView,
        setFlagNewProductView,

        setProductMappingListData

    } = props

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)

    const {
        productMappingListData,
    } = useContext(CarrierContext).state

    const inputRef = useRef(null);
    const [selectedProduct, setSelectedProduct] = useState({
        carrier_mag_id: "",
        carrier_ai_id: "",
        product_mag_id: "",
        product_ai_id: "",
        product_name: "",
        remove_product_maping_flag: false,
    });
    const [aiProductsList, setAiProductsList] = useState([
        { product_ai_name: "", product_ai_id: "" }
    ]);

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const lastItem = aiProductsList[aiProductsList.length - 1];
    const isAddDisabled = !lastItem || !lastItem.product_ai_name?.trim() || !lastItem.product_ai_id?.trim();
    const isSaveDisabled = aiProductsList?.some((item) => !item.product_ai_name?.trim() || !item.product_ai_id?.trim());

    useEffect(() => {
        if (carrierDetails) {
            setSelectedProduct({
                carrier_ai_id: carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id : "",
                carrier_mag_id: carrierId,

                product_ai_id: productRowGroupDetails?.groupData?.carrier_product_ai_mapping_data?.product_ai_id,
                product_mag_id: productRowGroupDetails?.groupData?.carrier_product_data_id ? productRowGroupDetails?.groupData?.carrier_product_data_id : "",

                product_name: productMappingListData?.find((item) => item?.product_ai_id == productRowGroupDetails?.groupData?.carrier_product_ai_mapping_data?.product_ai_id)?.product_name
            })
        }
    }, [carrierDetails])

    const saveProductAIMapping = () => {
        const payload = {
            carrier_mag_id: Number(carrierId),
            product_mag_id: Number(productRowGroupDetails?.groupData?.carrier_product_data_id),
            carrier_ai_id: Number(selectedProduct?.carrier_ai_id),
            product_ai_id: Number(selectedProduct?.product_ai_id)
        }

        if (!selectedProduct?.product_ai_id) {
            delete payload.product_ai_id;
            payload.remove_product_mapping_flag = true;
        }


        FlagApiLoader(true)
        syncAICarrieWithMagCarrierproduct(payload,
            (response) => {
                if (response?.data?.status) {
                    setFlagCall(new Date().getTime())
                    setFlagProductMappingDialog(false)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "success",
                        message: response?.data?.data?.message ? response?.data?.data?.message : "Product synced successfully!"
                    });
                }
                else {
                    if (response?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: response?.data?.error?.message ? response?.data?.error?.message : "Something Went Wrong!"
                        });
                    }
                    FlagApiLoader(false)
                }
            },
            (error) => {
                console.log("error", error);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
            }
        )
    }

    const handleProductChange = (index, field, value) => {
        const updatedList = [...aiProductsList];

        if (field === "product_ai_id") {
            const numericValue = value.replace(/\D/g, "");
            updatedList[index][field] = numericValue;
        } else {
            updatedList[index][field] = value;
        }

        setAiProductsList(updatedList);
    };

    const handleSaveNewProduct = () => {

        const payload = {
            carrier_ai_id: Number(selectedProduct?.carrier_ai_id),
            carrier_ai_products: aiProductsList?.map((ele) => {
                return {
                    product_ai_id: Number(ele?.product_ai_id),
                    product_ai_name: ele?.product_ai_name
                }
            })
        }

        FlagApiLoader(true)
        addAICarrierProductAPI(payload,
            (response) => {
                console.log("response addAICarrierProductAPI", response);
                if (response?.data?.status) {
                    // setFlagProductMappingDialog(false)
                    setFlagNewProductView(false)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "success",
                        message: response?.data?.data?.message ? response?.data?.data?.message : "Products added successfully!"
                    });

                    const payloadForProductMappingData = { carrier_ai_id: selectedProduct?.carrier_ai_id };
                    getAICarrierProductListAPI(payloadForProductMappingData,
                        (response) => {
                            console.log("response of getAICarrierProductListAPI", response);
                            const responseData = response?.data?.data
                            setProductMappingListData(responseData)
                            FlagApiLoader(false)

                        },
                        (error) => {
                            console.log("response of getAICarrierProductListAPI", error);
                            setProductMappingListData([])
                            UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!",
                            });
                            FlagApiLoader(false)
                        }
                    );
                }
                else {
                    if (response?.error?.code == 9163) {
                        setFlagProductMappingDialog(true)
                        setFlagNewProductView(true)
                    }
                    else {
                        setFlagProductMappingDialog(false)
                        setFlagNewProductView(false)
                        setAiProductsList([{ product_ai_name: "", product_ai_id: "" }])
                    }
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: response?.data?.error?.message ? response?.data?.error?.message : "Something Went Wrong!"
                    });

                }
            },
            (error) => {
                console.log("error addAICarrierProductAPI", error);
                setFlagProductMappingDialog(false)
                setFlagNewProductView(false)
                FlagApiLoader(false)
                setAiProductsList([{
                    product_ai_name: "",
                    product_ai_id: ""
                }])
                UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
            })
    }

    const handleAddProduct = () => {
        setAiProductsList([...aiProductsList, { product_ai_name: "", product_ai_id: "" }])
    }

    return (
        <>
            <Grid container>
                {flagNewProductView ?
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} className='prd-header-item'>
                                <Typography variant='span'>
                                    Add AI Product {productRowGroupDetails?.groupData?.carrier_product_name ? ` - ${productRowGroupDetails?.groupData?.carrier_product_name}` : ""}
                                </Typography>
                                <IconButton
                                    size='small'
                                    sx={{ position: 'absolute', right: "10px", top: "15px", color: "black" }}
                                    onClick={() => {
                                        setFlagProductMappingDialog(false)
                                        setFlagNewProductView(false)
                                        setAiProductsList([{
                                            product_ai_name: "",
                                            product_ai_id: ""
                                        }])
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>

                            <Grid item xs={10} sx={{ margin: "20px auto" }}>
                                <Grid container sx={{ justifyContent: "center" }}>
                                    <Grid item xs={12} sx={{ margin: "10px 0px 15px" }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={11.5} sx={{ margin: "0px 0px 10px" }}>
                                                        <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                                            <Grid item className='prd-product-textfield-item'>
                                                                AI Product Name
                                                            </Grid>
                                                            <Grid item className='prd-id-field-add-item' sx={{ pl: "20px" }}>
                                                                AI Product ID
                                                            </Grid>
                                                            <Grid item sx={{ maxWidth: "20px" }}></Grid>
                                                        </Grid>
                                                    </Grid>


                                                    {aiProductsList?.map((product, index) => {
                                                        return (
                                                            <>
                                                                <Grid item xs={12}
                                                                    sx={{ position: 'relative' }}
                                                                    key={index}
                                                                    onMouseEnter={() => setHoveredIndex(index)}
                                                                    onMouseLeave={() => setHoveredIndex(null)}
                                                                >
                                                                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <Grid item xs={11.5}>
                                                                            <Grid container className='prd-field-container'>
                                                                                <Grid item className='prd-product-textfield-item'>
                                                                                    <TextField
                                                                                        variant='outlined'
                                                                                        fullWidth
                                                                                        placeholder='Enter Product Name'
                                                                                        className='prd-product-textfield'
                                                                                        autoComplete='off'
                                                                                        value={product?.product_ai_name}
                                                                                        onChange={(e) => handleProductChange(index, "product_ai_name", e.target.value)}
                                                                                    />
                                                                                </Grid>
                                                                                <Divider orientation='vertical' sx={{ height: '30px', border: '1px solid #E3E3E3' }} />
                                                                                <Grid item className='prd-id-field-add-item'>
                                                                                    <TextField
                                                                                        variant='outlined'
                                                                                        fullWidth
                                                                                        value={product?.product_ai_id}
                                                                                        autoComplete='off'
                                                                                        className='prd-productId-textfield'
                                                                                        placeholder='Enter Product ID'
                                                                                        onChange={(e) => handleProductChange(index, "product_ai_id", e.target.value)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        {hoveredIndex == index && (
                                                                            <Grid item sx={{ maxWidth: "20px" }}>
                                                                                <IconButton
                                                                                    sx={{ padding: "5px" }}
                                                                                    onClick={() => {
                                                                                        if (aiProductsList.length == 1) {
                                                                                            setAiProductsList([{ product_ai_name: "", product_ai_id: "" }]);
                                                                                        } else {
                                                                                            const updatedList = [...aiProductsList];
                                                                                            updatedList.splice(index, 1);
                                                                                            setAiProductsList(updatedList);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <CancelIcon sx={{ color: "#1565C0", fontSize: "22px" }} />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}


                                                    <Grid item
                                                        sx={{
                                                            margin: "10px 0px 0px",
                                                            cursor: isAddDisabled ? 'not-allowed' : 'pointer',
                                                            opacity: isAddDisabled ? 0.7 : 1
                                                        }}
                                                        onClick={() => {
                                                            if (isAddDisabled) return;
                                                            handleAddProduct()
                                                        }}
                                                    >
                                                        <Grid container sx={{ alignItems: "center", gap: "5px" }}  >
                                                            <AddCircleRoundedIcon sx={{ color: "#1565C0" }} /> <Typography variant='span' sx={{ color: "#1565C0" }}>More Product</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ margin: "30px 0px 15px" }}>
                                        <Grid container sx={{ justifyContent: "center", gap: "15px" }}>
                                            <Grid item className='prd-button-width'>
                                                <Button
                                                    variant='outlined'
                                                    size='medium'
                                                    fullWidth
                                                    className='prd-close-btn'
                                                    onClick={() => {
                                                        // setFlagProductMappingDialog(false)
                                                        setFlagNewProductView(false)
                                                        setAiProductsList([{
                                                            product_ai_name: "",
                                                            product_ai_id: ""
                                                        }])
                                                        setSelectedProduct({
                                                            ...selectedProduct,
                                                            product_ai_id: "",
                                                        });
                                                    }}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item className='prd-button-width'>
                                                <Button
                                                    variant='contained'
                                                    size='medium'
                                                    fullWidth
                                                    disabled={isSaveDisabled}
                                                    className='prd-save-btn'
                                                    onClick={() => { handleSaveNewProduct() }}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} className='prd-header-item'>
                                <Typography variant='span'>
                                    {selectedProduct?.product_ai_id ? "Edit AI Product" : "Add AI Product"}  {productRowGroupDetails?.groupData?.carrier_product_name ? ` - ${productRowGroupDetails?.groupData?.carrier_product_name}` : ""}
                                </Typography>
                                <IconButton
                                    size='small'
                                    sx={{ position: 'absolute', right: "10px", top: "15px", color: "black" }}
                                    onClick={() => {
                                        setFlagProductMappingDialog(false)
                                        setFlagNewProductView(false)
                                        setAiProductsList([{
                                            product_ai_name: "",
                                            product_ai_id: ""
                                        }])
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>

                            <Grid item xs={10} sx={{ margin: "20px auto" }}>
                                <Grid container sx={{ justifyContent: "center" }}>
                                    <Grid item xs={12} sx={{ margin: "10px 0px 15px" }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ margin: "0px 0px 10px" }}>
                                                        <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                                            <Grid item className='prd-product-autocomplate'>
                                                                <Typography variant='span' sx={{ fontSize: "13px", fontWeight: "600 !important", color: "black" }}>
                                                                    AI Product Name
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item className='prd-id-field-item'>
                                                                <Typography variant='span' sx={{ fontSize: "13px", fontWeight: "600 !important", color: "black" }}>
                                                                    AI Product ID
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container className='prd-field-container'>
                                                            <Grid item className='prd-product-autocomplate'>
                                                                <Autocomplete
                                                                    fullWidth
                                                                    options={productMappingListData}
                                                                    getOptionLabel={(option) => `${option.product_name}`}
                                                                    value={productMappingListData?.find(item => Number(item?.product_ai_id) == Number(selectedProduct?.product_ai_id)) || null}
                                                                    onChange={(event, newValue) => {
                                                                        if (newValue) {
                                                                            setSelectedProduct({
                                                                                carrier_ai_id: newValue?.carrier_ai_id,
                                                                                product_ai_id: newValue?.product_ai_id,
                                                                                product_name: newValue?.product_name
                                                                            });
                                                                        } else {
                                                                            // Reset to default empty values
                                                                            setSelectedProduct({
                                                                                carrier_mag_id: carrierId,
                                                                                carrier_ai_id: carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id,
                                                                                product_mag_id: productRowGroupDetails?.groupData?.carrier_product_data_id,
                                                                                product_ai_id: "",
                                                                                // product_name: ""
                                                                            });
                                                                        }
                                                                    }}
                                                                    className='prd-autocomplate'
                                                                    popupIcon={<KeyboardArrowDownIcon />}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder={selectedProduct?.product_ai_id ? "" : "Enter Product Name"}
                                                                            label=""
                                                                            variant="outlined"
                                                                            className='prd-autocomplate-textfield'
                                                                            size='small'
                                                                            inputRef={inputRef}
                                                                        />
                                                                    )}
                                                                    PopperComponent={(props) => (
                                                                        <Popper
                                                                            {...props}
                                                                            style={{
                                                                                zIndex: 2222,
                                                                                width: inputRef.current ? inputRef?.current?.offsetWidth : "auto", // Match input width
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Divider orientation='vertical' sx={{ height: '30px', border: '1px solid #E3E3E3' }} />
                                                            <Grid item className='prd-id-field-item'>
                                                                <Typography
                                                                    variant='span'
                                                                    sx={{
                                                                        fontSize: '13px',
                                                                        fontWeight: "500",
                                                                        color: selectedProduct?.product_ai_id ? "black" : "#434343"
                                                                    }}
                                                                >
                                                                    {selectedProduct?.product_ai_id || "NA"}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                    <Grid item xs={12} sx={{ margin: "10px 0px 0px" }}>
                                                        <Grid container sx={{ alignItems: "center", gap: "5px", cursor: 'pointer' }}>
                                                            <Typography variant='span' sx={{ color: "#575757", fontSize: "12px", fontWeight: "500 !important" }}>
                                                                Can't find the product?
                                                            </Typography>
                                                            <Typography variant='span' sx={{ color: "#1565C0", fontSize: "12px", fontWeight: "500 !important" }}
                                                                onClick={() => {
                                                                    setFlagNewProductView(true)
                                                                    setAiProductsList([{
                                                                        product_ai_name: "",
                                                                        product_ai_id: ""
                                                                    }])
                                                                }}
                                                            >
                                                                Add New Product
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ margin: "30px 0px 15px" }}>
                                        <Grid container sx={{ justifyContent: "center", gap: "15px" }}>
                                            <Grid item className='prd-button-width'>
                                                <Button
                                                    variant='outlined'
                                                    size='medium'
                                                    fullWidth
                                                    className='prd-close-btn'
                                                    onClick={() => {
                                                        setFlagProductMappingDialog(false)
                                                        setFlagNewProductView(false)
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            </Grid>
                                            <Grid item className='prd-button-width'>
                                                <Button
                                                    variant='contained'
                                                    size='medium'
                                                    fullWidth
                                                    disabled={selectedProduct?.product_ai_id == null ? true : false}
                                                    className='prd-save-btn'
                                                    onClick={() => { saveProductAIMapping() }}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default ProductMappingDialog