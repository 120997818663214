import React, { useState } from 'react'
import "../../css/ViewCarriers.css"
import { Button, Grid, Typography } from '@mui/material'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';


function TableAccordianHeader(props) {

    const {
        editDetails,

        carrierDetails,
        setCarrierDetails,

        flagLevelAccordionExpanded,

        flagShowAddIcons,
        setFlagShowAddIcons,

        currentlyAddedCols,
        setCurrentlyAddedCols,

        flagShowCloseLevelSection,
        setFlagShowCloseLevelSection,

        tableContainerRef,
        setFlagLevelTablePopup,
        setAnchorElForLevelsColumn
    } = props

    const [addLevels, setAddLevels] = useState(null)

    const handleRevertColumnAddition = () => {
        let newCarrierLevel = { ...carrierDetails }.carrier_level?.filter(level => !currentlyAddedCols.includes(level?.carrier_level_id_custom));

        let newCarrierLevelData = carrierDetails?.carrier_product?.map((section) => {
            return {
                ...section,
                carrier_product_data: section?.carrier_product_data?.map((group) => {
                    let newCommissionData = [...group.carrier_product_commission_data].filter(commission => !currentlyAddedCols.includes(commission?.carrier_level_id_custom))

                    return { ...group, carrier_product_commission_data: newCommissionData }
                })
            }
        });

        setCurrentlyAddedCols([])
        setCarrierDetails({ ...carrierDetails, carrier_level: newCarrierLevel, carrier_product: newCarrierLevelData })
    }

    const handleOpenColumnPopover = (event, i, ele) => {
        if (ele) {
            setAnchorElForLevelsColumn({ type: ele, columnId: 7 });
        } else {
            setAnchorElForLevelsColumn({ type: event.currentTarget, columnId: i });
        }
    };

    return (
        <>
            <Grid item xs={12} className="vw-levels-heading-main-item">
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography className="accordionSummury_Label" >
                            Products
                        </Typography>
                    </Grid>
                    <Grid item display={editDetails ? "inline" : flagLevelAccordionExpanded ? "inline" : "none"}>
                        {editDetails ?
                            (<>
                                <Grid container>
                                    {flagShowAddIcons ?
                                        (
                                            <Grid container gap={"0.5rem"}>
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        disabled={currentlyAddedCols && currentlyAddedCols?.length == 0}
                                                        onClick={() => {
                                                            setFlagShowAddIcons(!flagShowAddIcons)
                                                            setCurrentlyAddedCols([])
                                                        }}
                                                    >
                                                        Continue
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        size="medium"
                                                        onClick={() => {
                                                            if (tableContainerRef.current) {
                                                                tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
                                                            }
                                                            handleRevertColumnAddition();
                                                            setFlagShowAddIcons(!flagShowAddIcons)
                                                            setCurrentlyAddedCols([])
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    startIcon={<AddCircleIcon />}
                                                    onClick={() => {
                                                        carrierDetails?.carrier_level?.length > 0 ?
                                                            setFlagShowAddIcons(!flagShowAddIcons) :
                                                            carrierDetails?.carrier_level?.length === 0 ?
                                                                handleOpenColumnPopover(undefined, 1, document.getElementById('blank-tablecell'))
                                                                :
                                                                setAddLevels(false)
                                                        setFlagShowAddIcons(true)

                                                    }}
                                                >
                                                    Levels
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </>
                            ) : (
                                <>
                                    <Grid container sx={{ gap: "10px" }}>
                                        <Grid item>
                                            <ZoomOutMapIcon sx={{ cursor: 'pointer' }} onClick={() => setFlagLevelTablePopup(true)} />
                                        </Grid>
                                        <Grid item>
                                            {flagShowCloseLevelSection ?
                                                <KeyboardArrowUpIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagShowCloseLevelSection(!flagShowCloseLevelSection) }} /> :
                                                <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagShowCloseLevelSection(!flagShowCloseLevelSection) }} />
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default TableAccordianHeader