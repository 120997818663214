import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Agent.css"
import "../css/Global/PagesNavbar.css"

import {
  Divider as MuiDivider, Typography, Box, Grid, InputBase, Button,
  TextField, FormGroup, FormControlLabel, Checkbox, IconButton, useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { darken } from "polished";
import styled from "@emotion/styled";
import { Add, Close } from "@mui/icons-material";
import { Divide, Search as SearchIcon, X } from "react-feather";
import { Stack, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

// Image 
import PrimaryLogo from "../../assets/primary-logo.png";
import SecondaryLogo from "../../assets/secondary-logo.png";
import deleteAlert from '../../assets/Delete-alert.png'

import GlobalDialog from "../../components/global/GlobalDialog";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { Context as agentContext } from "../../contexts/reducerContexts/agentContext";
import { DeleteAgentDataApi, GetAgentDataList } from "../../utils/api/AgentApi";

import { UserMeApi } from "../../utils/api/userDetailApi";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  DataGrid,
  GridActionsCellItem,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  useGridApiRef,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import DeleteAgentDialog from "./GlobalComponent/DialogComponent/AgentDialogs/DeleteAgentDialog";
import Icons from "../../components/icons/icons";


const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)`
  border: none;
  padding: 20px;
  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-cell {
    border-bottom:1px solid white;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
  .customRowSigned{
    border-left: 5px solid #3fa976;
  }
  .customRowUnsigned{
    border-left: 5px solid #d65656;
  }

  ${'' /* .MuiDataGrid-sortIcon {
    opacity: 1;
    color: white;
  }

  .MuiDataGrid-menuIconButton {
    opacity: 1;
    color: white;
  } */}

`;

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;


const Agents = () => {

  const {
    FlagApiLoader,
    UpdateSnackbarMessage,
    UpdateUserDetail,
    UpdateFlagAuthenticated,
    state,
    UpdateDelegateAccessFlag,
    UpdateDelegateAccessData,
    setCarrierTypeDetails
  } = useContext(AuthContext);

  const {
    carrierTypeDetails
  } = useContext(AuthContext).state;

  const {
    setGlobalLifeCarrierData,
    setGlobalAnnuityCarrierData,
    setGlobalCarrierDetails,
    setGlobalCarrierLevelDetails,
  } = useContext(globalCarrierContext);

  const { setAgentUserData } = useContext(agentContext);


  const navigate = useNavigate();
  const isLaptopScreen = useMediaQuery('(max-width: 1700px)');
  const isMidLaptopScreen = useMediaQuery('(max-width: 1380px)');

  const [ndaStatus, setNdaStatus] = React.useState({ signed: true, not_signed: true, });
  const [agentDataList, setAgentDataList] = useState([])
  const [agentGloablDataList, setAgentGlobalDataList] = useState([])
  const [flagDeletePopup, setFlagDeletePopup] = useState(false);
  const [deleteAgentData, setDeleteAgentData] = useState({});
  const [changeAgentStatusAndType, setChangeAgentStatusAndType] = useState(false);
  const [noDataMsgFlag, setNoDataMsgFlag] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [typeStates, setTypeStates] = useState({});

  // LOGS:
  // console.log("carrierTypeDetails in agent", carrierTypeDetails);


  // USE EFFECTS :------------------------ 

  // SET THE DATA FOR CHECKBOX
  useEffect(() => {
    const initialTypeStates = {};
    carrierTypeDetails?.forEach(type => {
      initialTypeStates[type.carrier_type_id] = true; // Initially set all types to true
    });
    setTypeStates(initialTypeStates);
  }, [carrierTypeDetails]);

  useEffect(() => {
    // OPTIMIZE FOR CHECKBOX FILTER
    let filteredData = [...agentGloablDataList];

    // Apply NDA and Type Filters
    filteredData = filteredData.filter(agent => {
      const agentNdaAccepted = agent.agent_user_data.some(user =>
        user.user_privacy_policy_consent.some(consent =>
          consent.privacy_policy_activity_message.indexOf("Accepted") != -1
        )
      );

      const agentNdaNotAccepted = agent.agent_user_data.some(user =>
        user.user_privacy_policy_consent.some(consent =>
          consent.privacy_policy_activity_message.indexOf("Accepted") == -1
        )
      );

      const ndaStatusMatch = (ndaStatus.signed && agentNdaAccepted) || (ndaStatus.not_signed && agentNdaNotAccepted);

      // Check if the agent type matches any of the selected types
      const typeMatch = agent.agent_commission_data.some(commission =>
        typeStates[commission.carrier_type_id] === true
      );

      return ndaStatusMatch && typeMatch;
    });

    setAgentDataList(filteredData);
  }, [typeStates, ndaStatus, agentGloablDataList]);

  useEffect(() => {
    FlagApiLoader(true)
    CallUserMe()
    ClearAgentDataContext()
    setGlobalLifeCarrierData([]);
    setGlobalAnnuityCarrierData([])
    setGlobalCarrierDetails(null)
    setGlobalCarrierLevelDetails(null)
    GetAgentDataList((res) => {
      if (res?.data?.status) {
        let sortedAgentList = res?.data?.data?.sort((a, b) =>
          a.agent_created_at_timestamp < b.agent_created_at_timestamp ? 1 :
            a.agent_created_at_timestamp > b.agent_created_at_timestamp ? -1 : 0
        );
        setAgentDataList(sortedAgentList);
        setAgentGlobalDataList(sortedAgentList);
        FlagApiLoader(false);
      }
      else {
        UpdateSnackbarMessage({
          status: "success",
          message: res?.data?.data?.message ? res?.data?.data?.message : "Something Went Wrong!"
        });
        FlagApiLoader(false)
      }
    },
      (err) => {
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
      })
  }, [])

  useEffect(() => {
    if (agentDataList?.length) {
      setNoDataMsgFlag(true)
    } else {
      setNoDataMsgFlag(false)
    }
  }, [agentDataList])
  // USE EFFECTS :------------------------


  // FUNCTIONS:---------------------
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  };

  const generateCarrierColumns = (carrierTypeDetails) => {
    return carrierTypeDetails.map((carrierType) => ({
      field: `${carrierType?.carrier_type_name?.toLowerCase()}Template`,
      headerName: `${capitalizeFirstLetter(carrierType?.carrier_type_name)} Template`,
      align: "center",
      headerAlign: "center",
      minWidth: 170,
      headerClassName: "common-header-styles",
      renderCell: (params) => {
        const templates = params?.row?.agent_commission_data ?
          params?.row?.agent_commission_data?.filter((data) => data.carrier_type_id == carrierType.carrier_type_id && data.template_assign_flag).map((data) => data.template_name)
          : [];
        return (
          <Grid container sx={{ justifyContent: "center" }}>
            {templates?.length > 0 ? templates?.map((template, index) => (
              <Grid
                item
                key={index}
                sx={{
                  fontSize: "13px",
                  fontWeight: 600,
                }}
              >
                {template}
              </Grid>
            ))
              : null}
          </Grid>
        );
      },
      sortable: true,
      valueGetter: (params) => params?.row?.agent_commission_data?.filter((data) => data?.carrier_type_id == carrierType?.carrier_type_id)?.map((data) => data?.template_name)?.join(", "),
    }));
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${year}/${month < 10 ? "0" : ""}${month}/${day < 10 ? "0" : ""}${day}`;
  }

  // FOR CHANGE CARRIER TYPE
  const handleChangeType = (event, carrierTypeId) => {
    setTypeStates(prev => ({
      ...prev,
      [carrierTypeId]: event.target.checked
    }));
  };

  // FOR CHANGE NDA
  const handleChangeNda = (event) => {
    setChangeAgentStatusAndType(!changeAgentStatusAndType)
    if (event.target.checked == false) {
      if (event.target.name == "signed") {
        if (ndaStatus?.not_signed == true) {
          setNdaStatus({ ...ndaStatus, [event.target.name]: event.target.checked });
        }
      } else {
        if (ndaStatus?.signed == true) {
          setNdaStatus({ ...ndaStatus, [event.target.name]: event.target.checked });
        }
      }
    } else {
      setNdaStatus({ ...ndaStatus, [event.target.name]: event.target.checked });
    }
  };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const currentPage = useGridSelector(apiRef, gridPageSelector) + 1;
    const [pageInput, setPageInput] = useState(currentPage);

    useEffect(() => {
      // Update pageInput whenever currentPage changes
      setPageInput(currentPage);
    }, [currentPage]);

    const handlePageInputChange = (event) => {
      setPageInput(event.target.value);
    };

    const handleGoClick = () => {
      const pageNumber = Number(pageInput) - 1;
      if (pageNumber >= 0 && pageNumber < pageCount) {
        apiRef.current.setPage(pageNumber);
      }
    };

    const handleChange = (event, value) => {
      apiRef.current.setPage(value - 1);
    };

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: '#EFEFF0',
          padding: '5px 5px',
          borderRadius: '5px',
          marginBottom: '10px'
        }}
      >
        <Pagination
          color="primary"
          count={pageCount}
          page={currentPage}
          onChange={handleChange}
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#233044 !important",
              "&:hover": {
                backgroundColor: "#233044 !important",
              }
            }
          }}
          renderItem={(item) => (
            <PaginationItem
              component="div"
              {...item}
              disableRipple
            />
          )}
        />
        {pageCount > 1 && (
          <>
            <TextField
              value={pageInput}
              onChange={handlePageInputChange}
              onBlur={handleGoClick}
              autoComplete="off"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleGoClick();
                }
              }}
              inputProps={{
                type: 'number',
                'aria-label': 'Page number',
                min: 1,
                max: pageCount,
              }}
              variant="standard"
              size="small"
              sx={{
                width: '50px',
                textAlign: 'center',
                "& .MuiInputBase-input": {
                  textAlign: 'center',
                  background: '#ffffff',
                  padding: "5px 7px"
                }
              }}
            />
            <IconButton onClick={handleGoClick} size="small" sx={{ ml: '5px' }}>
              Go
            </IconButton>
          </>
        )}
      </Box>
    );
  }

  function DataGridDemo(props) {
    const { agentDataList } = props
    const navigate = useNavigate();
    return (
      <Box sx={{ height: "80vh", width: "100%" }}>
        <CustomDataGrid
          rows={agentDataList?.length && agentDataList}
          getRowId={(row) => row?.agent_id}
          onCellClick={(param) => {
            if (param?.field != "actions") {
              navigate(`/view_agent?id=${param?.row?.agent_id}`)
            }
          }}
          className="agent_list_data_grid"
          sx={{
            padding: { xs: "10px 10px 0 10px", sm: "10px 20px 20px 20px" },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none"
            },
          }}
          columns={columns}
          // OLD CODE COMMENTTED BEFORE PAGINATION
          // initialState={{
          //   sorting: {
          //     sortModel: [{ field: 'name', sort: 'desc' }],
          //   },
          // }}
          // rowCount={agentDataList?.length}
          // rowCount={(agentDataList?.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          // count={agentDataList?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          // BFR PAGINATION
          // pageSize={20}
          // rowsPerPageOptions={[8]}

          pageSize={pageSize}  // Set the number of rows per page
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}  // Options for changing the page size
          pagination
          experimentalFeatures={{ newEditingApi: true }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          density="comfortable"
          headerHeight={30}
          column
          components={{
            Pagination: CustomPagination,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {noDataMsgFlag == false && state.apiLoader == false ? "No Data Found!" : ""}
              </Stack>
            ),
          }}

          getRowClassName={(params) => {
            let ndaMessageArray = []
            params?.row?.agent_user_data?.map((user) => {
              // OLD CODE BFR ACCEPT/NOT ACCEPT
              // user?.user_nda_log?.map((ndaRow) => {
              //   ndaMessageArray.push(ndaRow?.nda_activity_message)
              // })
              user?.user_privacy_policy_consent?.map((ndaRow) => {
                ndaMessageArray.push(ndaRow?.privacy_policy_activity_message)
              })
            })
            // console.log("ndaMessageArray", ndaMessageArray);
            // if (ndaMessageArray?.indexOf("Signed") > -1) {
            // if (ndaMessageArray?.filter((x) => x.indexOf("Accepted") != -1).length == ndaMessageArray.length) {
            //   return "customRowSigned"
            // } else {
            //   return "customRowUnsigned"
            // }
          }}
        />
      </Box>
    );
  }

  const SearchAgent = (name) => {
    let tempAgentData = [...agentGloablDataList]; // Assuming this is the complete list of agents

    let filteredAgents = tempAgentData.filter(agent => {
      // NDA status filter
      const agentNdaAccepted = agent.agent_user_data.some(user =>
        user.user_privacy_policy_consent.some(consent =>
          consent.privacy_policy_activity_message.includes("Accepted")
        )
      );
      const agentNdaNotAccepted = agent.agent_user_data.some(user =>
        user.user_privacy_policy_consent.every(consent =>
          !consent.privacy_policy_activity_message.includes("Accepted")
        )
      );

      const ndaStatusMatch = (ndaStatus.signed && agentNdaAccepted) ||
        (ndaStatus.not_signed && agentNdaNotAccepted);

      // Type filter
      const typeMatch = agent.agent_commission_data.some(commission =>
        typeStates[commission.carrier_type_id]
      );

      // Search filter
      const nameMatch = agent.agent_user_data.some(userData => {
        return userData.username.toLowerCase().includes(name.toLowerCase()) ||
          userData.email_address.toLowerCase().includes(name.toLowerCase());
      });

      return ndaStatusMatch && typeMatch && (nameMatch || name === "");
    });

    setAgentDataList(filteredAgents);
  };

  const ClearAgentDataContext = () => {
    setAgentUserData({
      primary: {
        "primary_data_flag": true,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      secondary: {
        "primary_data_flag": false,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      carrier_type_life: false,
      carrier_type_annuity: false
    })
  }

  const CallUserMe = () => {
    UserMeApi({},
      (res) => {
        UpdateFlagAuthenticated(res?.data?.status)
        if (res?.data?.status) {
          UpdateUserDetail(res.data.data)
          UpdateDelegateAccessFlag(res?.data?.delegate_access_flag)
          if (res?.data?.delegate_access_flag) {
            UpdateDelegateAccessData(res?.data?.delegate_user_data)
          }
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
          });
        }
      },
      (err) => {
        console.log(err)
        UpdateSnackbarMessage({ status: "error", message: "Something went wrong!" });
        FlagApiLoader(false)
      })
  }
  // FUNCTIONS:-------------------




  // COMPONENT FUNCTIONS:--------------------  
  function CheckboxesGroup(props) {
    const { ndaStatus, handleChangeNda } = props
    const { signed, not_signed } = ndaStatus;

    return (
      <Grid container justifyContent={{ xs: "center", sm: "center", md: "end", lg: "center" }} direction="row"
        sx={{
          "& .PrivateSwitchBase-root": {
            padding: " 0 5px 0 0 !important"
          }
        }}
      // spacing={1}
      >
        <Grid item xs={3} sm={4} md={4} lg={4} xl={4}>
          <Typography
            variant="outlined"
            sx={{ color: "#7A7A7A", marginRight: "16px" }}
            gutterBottom
            display="inline"
          >
            Policy Status
          </Typography>
        </Grid>
        <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={signed}
                  onChange={handleChangeNda}
                  name="signed"
                />
              }
              label="Accepted"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3.5} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={not_signed}
                  onChange={handleChangeNda}
                  name="not_signed"
                />
              }
              label="Not Accepted"
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  }

  function Checkboxes(props) {
    const { handleChangeType, flagLaptopScreen } = props
    return (
      <Grid container
        sx={{
          "& .PrivateSwitchBase-root": { padding: flagLaptopScreen ? "0px" : "0 5px 0 0 !important" },
          justifyContent: flagLaptopScreen ? 'start' : 'center'
        }}
      >
        <Grid item xs={flagLaptopScreen ? 12 : 2} sx={{ margin: flagLaptopScreen ? "0px 0px 5px 0px" : "" }}>
          <Typography
            variant="outlined"
            sx={{
              fontSize: flagLaptopScreen ? "15px" : "", fontWeight: flagLaptopScreen ? "600" : '500', marginBottom: "5px",
              color: flagLaptopScreen ? "#757575" : "black"
            }}
          >
            Type
          </Typography>
        </Grid>

        <FormGroup row={flagLaptopScreen ? false : true} >
          {carrierTypeDetails?.map((type) => (
            <FormControlLabel
              sx={{ marginLeft: "0px", }}
              key={type.carrier_type_id}
              control={
                <Checkbox
                  sx={{ padding: "5px 5px 0 0 !important" }}
                  size={flagLaptopScreen ? "medium" : "medium"}
                  checked={typeStates[type?.carrier_type_id]}
                  onChange={(event) => handleChangeType(event, type?.carrier_type_id)}
                  name={type.carrier_type_name}
                />
              }
              label={type?.carrier_type_name?.charAt(0)?.toUpperCase() + type.carrier_type_name.slice(1)}
            />
          ))}
        </FormGroup>
      </Grid>
    );
  }
  // COMPONENT FUNCTIONS:--------------------



  // DATAGRID COLUMN:---------
  const columns = [
    {
      field: "statusIndicator",
      headerName: "",
      width: 10,
      minWidth: 10,
      maxWidth: 10,
      cellClassName: "first-column",
      sortable: false,
      renderCell: (params) => {
        const userCount = params?.row?.agent_user_data?.length || 0;

        // Calculate height per indicator dynamically
        const totalRowHeight = 75; // Default height of a row in DataGrid
        const indicatorHeight = userCount > 0 ? totalRowHeight / userCount : totalRowHeight;

        const indicators = params?.row?.agent_user_data?.map((user, index) => {
          const marginBottom = userCount === 2 && index !== userCount - 1 ? "2px" : userCount > 2 ? "5px" : "0";
          const hasAccepted = user?.user_privacy_policy_consent?.some((ndaRow) => ndaRow?.privacy_policy_activity_message?.toLowerCase()?.includes("accepted"));
          return (
            <div
              key={index}
              style={{
                height: `${indicatorHeight - 5}px`,
                width: "4px",
                backgroundColor: hasAccepted ? "#22965E" : "#962222",
                marginBottom,
                borderRadius: "2px",
              }}
            />
          );
        });

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {indicators}
          </div>
        );
      },
    },
    {
      field: "logo",
      headerName: "",
      width: 50,
      headerClassName: "common-header-styles",
      renderCell: (params) => {
        const sortedAgentData = [...(params?.row?.agent_user_data || [])].sort(
          (a, b) => (b.primary_data_flag === true ? 1 : 0) - (a.primary_data_flag === true ? 1 : 0)
        );
        return (
          <Grid
            container
            alignItems={params?.row?.agent_user_data?.length === 2 ? "end" : "center"}
            marginTop={params?.row?.agent_user_data?.length === 1 ? "5px" : "0"}
            justifyContent="center"
            height="100%"
          >
            {sortedAgentData?.map((element) => {
              return (
                <Grid item>
                  {element?.primary_data_flag == true
                    ?
                    <img src={PrimaryLogo} />
                    :
                    <img src={SecondaryLogo} />
                  }
                </Grid>
              )
            })}
          </Grid>
        );
      },
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      width: isLaptopScreen ? 180 : 230,
      headerClassName: "common-header-styles",
      renderCell: (params) => {
        const sortedAgentData = [...(params?.row?.agent_user_data || [])].sort(
          (a, b) => (b.primary_data_flag === true ? 1 : 0) - (a.primary_data_flag === true ? 1 : 0)
        );

        return (<Grid container flexDirection="column" rowGap={4} key={params} mt="1px">
          {sortedAgentData?.map((element) => {
            return (
              <Grid item>
                <Typography
                  color="#1B6AC5"
                  fontFamily="Segoe UI"
                  fontWeight="400"
                  fontSize="15px"
                  lineHeight="20px"
                >
                  {element?.username}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_user_data?.map(v => v.username).join(" "),
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: isLaptopScreen ? 280 : 330,
      headerClassName: "common-header-styles",
      renderCell: (params) => {
        return (<Grid container flexDirection="column" rowGap={4} marginTop="1px">
          {params?.row?.agent_user_data?.map((element) => {
            return (
              <Grid item>
                <Typography
                  variant="span"
                  fontWeight="600"
                  fontSize="13px"
                  lineHeight="20px"
                >

                  {element.email_address}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_user_data?.map(v => v.email_address).join(" "),
      sortable: true,
      headerClassName: "common-header-styles",
    },
    {
      field: "type",
      headerName: "Type",
      width: isLaptopScreen ? 270 : 310,
      headerClassName: "common-header-styles",
      renderCell: (params) => {
        const carrierTypes = params.row.agent_commission_data
          ? params.row.agent_commission_data.map((data) => {
            // Find the carrier type in carrierTypeDetails for dynamic comparison
            const carrierTypeDetail = carrierTypeDetails.find((type) => type.carrier_type_id === data.carrier_type_id);
            return {
              name: carrierTypeDetail?.carrier_type_name || "",
              color: carrierTypeDetail?.carrier_type_color || "gray",
            };
          })
          : [];

        // Remove duplicates (if any)
        const uniqueCarrierTypes = Array.from(new Set(carrierTypes.map((type) => JSON.stringify(type)))).map((type) => JSON.parse(type));

        // Render each type in a Box with conditional styling
        return (
          <Grid container sx={{ columnGap: '8px' }}>
            {uniqueCarrierTypes.map((type, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    border: `1px solid ${type.color}`,
                    padding: "4px 8px",
                    borderRadius: "10px",
                    width: '65px',
                    textAlign: 'center',
                    color: `${type.color}`,
                    fontSize: '12px',
                    fontWeight: '500',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {capitalizeFirstLetter(type?.name)}
                </Box>
              </Grid>
            ))}
          </Grid>
        );
      },
      headerClassName: "type_header_class",
      sortable: false
    },
    ...generateCarrierColumns(carrierTypeDetails),
    {
      field: "createdDate",
      headerName: "Created Date",
      headerClassName: "common-header-styles",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid container sx={{ justifyContent: "center" }} columnGap={2} marginTop="1px">
            <Grid item>
              <Typography
                sx={{ fontSize: "13px", fontWeight: "600", color: "black" }}

              >

                {formatDate(params?.row?.agent_created_at_timestamp)}
              </Typography>
            </Grid>
          </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_created_at_timestamp,
      sortable: true,
    },
    // {
    //   field: "ndaStatus",
    //   headerName: "Policy Status",
    //   width: 250,
    //   headerClassName: "type_header_class",
    //   renderCell: (params) => {
    //     return (
    //       <Grid container flexDirection="column" rowGap={1} marginTop="1px">
    //         {params?.row?.agent_user_data?.map((element) =>
    //           element?.user_privacy_policy_consent?.map((ndaRow, index) => {
    //             const isAccepted = ndaRow?.privacy_policy_activity_message
    //               ?.toLowerCase()
    //               ?.includes("accepted");
    //             return (
    //               <Grid item key={index}>
    //                 <Typography
    //                   fontWeight="400"
    //                   fontSize="15px"
    //                   sx={{
    //                     color: isAccepted ? "#3fa976" : "#d65656", // Green for accepted, red for not accepted
    //                   }}
    //                 >
    //                   {isAccepted ? "Accepted" : "Not Accepted"}
    //                 </Typography>
    //               </Grid>
    //             );
    //           })
    //         )}
    //       </Grid>
    //     );
    //   },
    //   valueGetter: (params) => params?.row?.agent_user_data?.map((v) => v?.user_privacy_policy_consent?.map((ele) => ele.privacy_policy_activity_message))?.flat()?.join(", "),
    //   sortable: true,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      align: "right",
      flex: 1,
      headerClassName: "type_header_class",
      sortable: false,
      getActions: (params) => [
        // <GridActionsCellItem
        //   // icon={<DeleteIcon />}
        //   label="Delete"
        //   // onClick={}
        // />,
        <GridActionsCellItem
          // icon={<Security />}
          label="Delete"
          onClick={() => {
            setDeleteAgentData(params?.row)
            setFlagDeletePopup(true)
          }}
          showInMenu
        />,
      ],
    },
  ];
  // DATAGRID COLUMN:---------

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {flagDeletePopup && (
        <GlobalDialog
          open={flagDeletePopup}
          data={
            <DeleteAgentDialog
              setFlagDeletePopup={setFlagDeletePopup}

              CallUserMe={CallUserMe}
              deleteAgentData={deleteAgentData}

              agentGloablDataList={agentGloablDataList}
              setAgentGlobalDataList={setAgentGlobalDataList}

              agentDataList={agentDataList}
              setAgentDataList={setAgentDataList}
            />
          }
          maxWidth={"sm"}
        />
      )
      }
      <Helmet title="myAdvisorGrids | Agents" />

      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sx={{ margin: '20px 0px 0px' }}>
              <Grid container sx={{ alignItems: "center", justifyContent: 'space-between' }} >
                <Grid item xl={1.5} lg={isMidLaptopScreen ? 6 : 1.5} md={isMidLaptopScreen ? 6 : 1.5} sm={3} xs={11}>
                  <Typography variant="span" className="gbl-nav-head-text">
                    Agent
                  </Typography>
                </Grid>

                {isMidLaptopScreen ?
                  <>
                    <Grid item
                      xl={"auto"} lg={isMidLaptopScreen ? 1.5 : "auto"} md={isMidLaptopScreen ? 1 : "auto"} sm={"auto"} xs={"auto"}
                      sx={{ textAlign: isMidLaptopScreen ? "end" : "", height: '30px' }}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        onClick={handleClick}
                        sx={{ padding: "4px 6px", mr: "2px", border: '1px solid #376fd0', borderRadius: "4px", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
                      >
                        <Icons iconName="filterIcon" iconColor="#233144" iconWidth="20px" iconHeight="20px" />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        slotProps={{
                          paper: {
                            style: {
                              width: '200px',
                              padding: "12px",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                            },
                          },
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Checkboxes flagLaptopScreen={true} handleChangeType={handleChangeType} />
                          </Grid>
                          <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid contauiner>
                              <Grid item xs={12}
                                sx={{
                                  fontSize: "15px", fontWeight: '600', marginBottom: "5px",
                                  color: "#757575"
                                }}>
                                Policy Status
                              </Grid>
                              <Grid item xs={12}></Grid>
                            </Grid>
                            <FormGroup column >
                              <FormControlLabel
                                sx={{ marginLeft: "0px", marginRight: "0px" }}
                                label="Accepted"
                                control={
                                  <Checkbox
                                    size="medium"
                                    sx={{ padding: '2px 5px 2px 0px', fontSize: "15px", }}
                                    checked={ndaStatus.signed}
                                    onChange={handleChangeNda}
                                    name="signed"
                                  />
                                }
                              />
                              <FormControlLabel
                                sx={{ marginLeft: "0px", marginRight: "0px" }}
                                label="Not Accepted"
                                control={
                                  <Checkbox
                                    size="medium"
                                    sx={{ padding: '2px 5px 2px 0px' }}
                                    checked={ndaStatus.not_signed}
                                    onChange={handleChangeNda}
                                    name="not_signed"
                                  />
                                }
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Menu>
                    </Grid>
                  </> :
                  <>
                    <Grid item xs={7}>
                      <Grid container className="agent-checkbox-container">
                        <Grid item xs={6} className="agent-ps-item">
                          <Grid container sx={{ "& .PrivateSwitchBase-root": { padding: " 0 5px 0 0 !important" }, justifyContent: 'center' }}>
                            <Grid item xs={3}>
                              <Typography variant="span" sx={{ verticalAlign: "middle" }}>
                                Policy Status&nbsp;
                              </Typography>
                            </Grid>

                            <Grid item xs={8}>
                              <Grid container sx={{ alignItems: "center" }}>
                                <FormGroup row sx={{ gap: "15px" }}>
                                  <FormControlLabel
                                    sx={{ marginLeft: "0px", marginRight: "0px" }}
                                    label="Accepted"
                                    control={
                                      <Checkbox
                                        size="medium"
                                        checked={ndaStatus.signed}
                                        onChange={handleChangeNda}
                                        name="signed"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    sx={{ marginLeft: "0px", marginRight: "0px" }}
                                    label="Not Accepted"
                                    control={
                                      <Checkbox
                                        size="medium"
                                        checked={ndaStatus.not_signed}
                                        onChange={handleChangeNda}
                                        name="not_signed"
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={6} className="agent-type-item">
                          <Checkboxes handleChangeType={handleChangeType} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                }

                <Grid item xl={3.5} lg={isMidLaptopScreen ? 4 : 3.5} md={isMidLaptopScreen ? 5 : 1.5} sm={7} xs={12}>
                  <Grid container className="agent-btn-filter-container">
                    <Grid item className="agent-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => { navigate("add_agent") }}
                      >
                        New Agent
                      </Button>
                    </Grid>
                    <Grid item xs={6} className="agent-search-item">
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <Input
                          placeholder={"Search"}
                          onChange={(e) => { SearchAgent(e.target.value) }}
                        />
                      </Search>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ m: "10px 0px" }} />
            </Grid>

            <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF", marginTop: "10px" }}>
              <DataGridDemo agentDataList={agentDataList} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>


    </>
  );
};

export default Agents;
