import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";

const Root = styled.div`
  ${'' /* max-width: 520px; */}
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 93vh;
  flex-direction: column;
`;



const NdaError = ({ children }) => {


    // useEffect(() => {
    //     window.location.href = window.location.origin
    // }, [])

    return (
        <>
            <Navbar />
            <Root>
                <CssBaseline />
                <GlobalStyle />
                {children}
                <Outlet />
                <Settings />
            </Root>
        </>
    );
};


export default NdaError;