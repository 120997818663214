import { useContext, useEffect, useState } from "react"
import { Button, Grid, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import "../../../css/Agency/AgencyDialogs/SelectCarrierPopUp.css"
import { GetAgencyCarrierLevelList } from "../../../../utils/api/AgencyDetailsAPI";
import { Context as agencyContext } from '../../../../contexts/reducerContexts/agencyContext';
import { Context as authContext } from '../../../../contexts/reducerContexts/authContext';

const SelectCarrierPopUp = ({
    carrierCheckBoxes, setShowCarrierPopUp, checkedItems, setCheckedCarrierItem, section, maxLevelLength, setCarrierDataToRederTable,
    deleteAgCommissionCarriers, setDeleteAgCommissionCarriers
}) => {

    const [allCheckedDisableSelectAll, setAllCheckedDisableSelectAll] = useState(true)
    const [allSelected, setAllSelected] = useState(false)
    const [previouslyCheckedItems, setPreviouslyCheckedItems] = useState(checkedItems)
    const { agencyCarrierLevelList } = useContext(agencyContext).state
    const { setAgencyCarrierLevelList } = useContext(agencyContext)
    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)


    useEffect(() => {
        setPreviouslyCheckedItems(checkedItems)
    }, [checkedItems])

    useEffect(() => {
        // this line checking all the data coming from backend is checked or not if all data checked then it disable the select all.
        let allAreAlredyChecked = carrierCheckBoxes.every(carrier => (carrier.checked && carrier.alreadyChecked))
        // this line checks all data is selcted on client side then it disable select all. 
        let allAreCurrentChecked = false
        if (Object.keys(previouslyCheckedItems?.[section]).length == carrierCheckBoxes.length) {
            allAreCurrentChecked = true
        }

        setAllCheckedDisableSelectAll(allAreCurrentChecked || allAreAlredyChecked)

    }, [section])

    useEffect(() => {

        if (carrierCheckBoxes.length == Object.keys(previouslyCheckedItems?.[section]).length) {
            setAllSelected(true)
        } else {
            setAllSelected(false)
        }
    }, [previouslyCheckedItems, section])
    console.log('deleteAgCommissionCarriers', deleteAgCommissionCarriers)

    const handleContinueButton = async () => {

        const newSelectedCarriers = []
        const carrierIds = []
        const updateCheckedItems = { ...previouslyCheckedItems?.[section] }

        Object.entries(previouslyCheckedItems?.[section]).forEach(([key, checkedItemsObject]) => {
            // here checking if array is deleted by user or not. if its deleted then we get array of object and assign its assignIds to 
            // agent_commission_carrier_assignment_id in below addNewCarrierData if not deleted then it is null 

            const deletedCarrier = deleteAgCommissionCarriers?.find(data => data.carrierIds == checkedItemsObject.carrier_id)

            const agentCommissionCarrierAssignId = deletedCarrier?.assignIds || null

            if (!checkedItemsObject.alreadyChecked) {
                // this CarrierId array pass to fecthAgencyCarrierLevelDataList to get their level data list in dropdown
                carrierIds.push(checkedItemsObject.carrier_id)

                const addNewCarrierData = {
                    agent_commission_carrier_assignment_id: agentCommissionCarrierAssignId,
                    carrier_name: checkedItemsObject.carrier_name,
                    carrier_id: checkedItemsObject.carrier_id,
                    carrier_direct_level_name: [],
                    carrier_ba_level_name: [],
                    dl_level: [...Array(maxLevelLength).fill({
                        carrier_ba_level_flag: null,
                        carrier_direct_level_flag: null,
                        agent_commission_carrier_assignment_level_id: null,
                        carrier_level_name: "",
                        carrier_level_sequence: null
                    })]
                }
                newSelectedCarriers.push(addNewCarrierData)
                if (updateCheckedItems) {
                    updateCheckedItems[key] = { ...updateCheckedItems[key], alreadyChecked: true };
                }
            }
        });

        //here checking if any of currently checked checkbox previously deleted or not, 
        // if it is deleted and again we are adding then wee need to remove that checkbox's assign id from deleteAgCommissionCarriers array  
        const updateDeleteAgCommissionCarriers = deleteAgCommissionCarriers.filter(data =>
            !Object.values(previouslyCheckedItems?.[section]).some(item => item.carrier_id === data.carrierIds)
        );

        FlagApiLoader(true)
        await GetAgencyCarrierLevelList(carrierIds,
            (res) => {
                if (res?.data?.status) {
                    const carrierLevelList = res.data.data
                    setAgencyCarrierLevelList({
                        ...agencyCarrierLevelList, [section]: [...carrierLevelList, ...(agencyCarrierLevelList[section] || [])],
                    })
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        console.log("GetAgencyCarrierLevelList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("GetAgencyCarrierLevelList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("GetAgencyCarrierLevelList ERROR RESPONSE", err)
                UpdateSnackbarMessage({
                    status: "error", message: err?.data?.error?.message
                })
                FlagApiLoader(false)
            }
        )

        setDeleteAgCommissionCarriers((prevState) => ({ ...prevState, [section]: [...updateDeleteAgCommissionCarriers] }))

        setCheckedCarrierItem(currentState => ({
            ...currentState, [section]: { ...updateCheckedItems }
        }))
        setCarrierDataToRederTable(prevState => ({
            ...prevState, [section]: [...prevState?.[section], ...newSelectedCarriers]
        }))
        setShowCarrierPopUp(false)
    }


    const handleSelectAll = (targetChecked) => {
        const checkAllItems = {}
        const alreadyCheckedKey = []
        const updateCheckedItems = { ...previouslyCheckedItems?.[section] }

        carrierCheckBoxes.forEach(item => {
            const checked = updateCheckedItems[item.carrier_id]
            if (checked != undefined && checked.checked) {
                const alreadyCheckedItem = previouslyCheckedItems?.[section]?.[item.carrier_id]
                if (alreadyCheckedItem != undefined && alreadyCheckedItem.alreadyChecked) {
                    checkAllItems[item.carrier_id] = alreadyCheckedItem
                } else {
                    alreadyCheckedKey.push(item.carrier_id)
                    checkAllItems[item.carrier_id] = {
                        ...item, checked: targetChecked
                    }
                }
            } else {
                const alreadyCheckedItem = previouslyCheckedItems?.[section]?.[item.carrier_id]
                if (alreadyCheckedItem != undefined && alreadyCheckedItem.alreadyChecked) {
                    checkAllItems[item.carrier_id] = alreadyCheckedItem
                } else {
                    alreadyCheckedKey.push(item.carrier_id)
                    checkAllItems[item.carrier_id] = {
                        ...item, checked: targetChecked
                    }
                }
            }
        })
        setPreviouslyCheckedItems(prevState => ({
            ...prevState, [section]: checkAllItems
        }))
    }

    const disableSaveCarrierButton = () => {
        const disableButton = Object.values(previouslyCheckedItems?.[section]).some(data => !data.alreadyChecked && data.checked)
        return disableButton
    }

    return (
        <Grid container className="scp-main-container">

            <Grid item xs={12}>
                <Grid container className="scp-header-container" >
                    <Typography sx={{ fontSize: "20px" }}>
                        Select Carrier
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={11} sx={{ height: "calc(100% - 122px)", margin: "auto" }}>
                <Grid container className="scp-content-container"  >
                    <Grid item xs={12}>
                        <Grid container >
                            <FormControl component="fieldset" variant="standard" fullWidth>
                                <FormGroup className="scp-formgroup-select-all">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={allCheckedDisableSelectAll}
                                                checked={allSelected}
                                                onChange={(e) => {
                                                    const checked = e.target.checked
                                                    handleSelectAll(checked)
                                                    setAllSelected(e.target.checked)
                                                    if (!checked) {
                                                        // this operation preserves the checked items which is saved by user in the backend.
                                                        // and unchecheck all the items which is recently checked by user on client side.
                                                        const updateUnchecked = {}
                                                        Object.values(previouslyCheckedItems?.[section]).forEach(item => {
                                                            if (item.alreadyChecked) {
                                                                updateUnchecked[item.carrier_id] = item
                                                            }
                                                        })
                                                        setPreviouslyCheckedItems(prevState => ({
                                                            ...prevState, [section]: updateUnchecked
                                                        }))
                                                    }
                                                }}
                                            />
                                        }
                                        label="Select All"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ height: "calc(100% - 40px)" }}>
                        <Grid container className="scp-checkbox-container">
                            {carrierCheckBoxes?.length !== 0 ?
                                carrierCheckBoxes.map((row) => {

                                    const alreadyChecked = previouslyCheckedItems?.[section]?.[row.carrier_id]?.alreadyChecked || false
                                    const checked = previouslyCheckedItems?.[section]?.[row.carrier_id]?.checked || false

                                    return (
                                        <Grid key={row.carrier_id} item xs={3} md={4} lg={3} xl={2} textAlign="left">
                                            <FormControl component="fieldset" variant="standard" sx={{ width: "100%" }}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        className='scp-formcontrol-label'
                                                        control={
                                                            <Checkbox
                                                                sx={{ padding: "4px" }}
                                                                name={row.label}
                                                                disabled={alreadyChecked}
                                                                checked={checked}
                                                                onChange={() => {
                                                                    const isChecked = previouslyCheckedItems?.[section]?.[row.carrier_id]
                                                                    if (isChecked) {
                                                                        const deleteCheckedItem = { ...previouslyCheckedItems }
                                                                        delete deleteCheckedItem?.[section]?.[row.carrier_id]
                                                                        setPreviouslyCheckedItems(deleteCheckedItem)
                                                                    } else {
                                                                        setPreviouslyCheckedItems(prevState => ({
                                                                            ...prevState,
                                                                            [section]: {
                                                                                ...prevState[section],
                                                                                [row.carrier_id]: {
                                                                                    carrier_name: row.carrier_name,
                                                                                    checked: true,
                                                                                    alreadyChecked: false,
                                                                                    carrier_id: row?.carrier_id,
                                                                                    carrier_type_flag: row?.carrier_type_flag,
                                                                                    agency_carrier_id: row?.agency_carrier_id
                                                                                }
                                                                            }
                                                                        }))
                                                                    }
                                                                }
                                                                }
                                                            />}
                                                        label={row.carrier_name}
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    );
                                })
                                :
                                <Grid item>
                                    <Typography>
                                        No Data Found
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ height: "60px" }}>
                <Grid container className="scp-button-container">
                    <Button variant="outlined" sx={{ width: "100px" }} onClick={() => setShowCarrierPopUp(false)}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ width: "100px" }}
                        disabled={!disableSaveCarrierButton()}
                        onClick={handleContinueButton}>
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SelectCarrierPopUp;