import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Avatar, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import BaLogo from "../../assets/balogo.png";
import { AddAgencyLogo } from "../../utils/api/AgencyDetailsAPI";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import '../../pages/css/Signin.css'

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 380px
  };
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 500px
  };
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 500px
  };
`;

const BigAvatar = styled(Avatar)`
  width: 252px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;
const LogoComponent = () => (
  <img
    src={BaLogo}
    alt="Logo"
    width="216px"
    height="92px"
    textAlign="center"
    justifyContent="center"
  />
);

function SignIn() {
  const { AgencyLogoAPI } = useContext(AuthContext);
  const { agencyLogo } = useContext(AuthContext).state


  // console.log("agencyLogo", agencyLogo);
  // useEffect(() => {
  //   AgencyLogoAPI()
  // }, [])

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper className="signup-card" >
        <Helmet title="myAdvisorGrids | Sign In" />

        <Grid container sx={{ width: "100%" }} id="grid_container_auth_sign" justifyContent="center" >
          <Grid item width={{ xs: "300px", sm: "350px" }}>

            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid item className="sign-in-agency-logo-item" >
                {agencyLogo?.agency_name?.trim()?.toLowerCase() == "brokers alliance" ?
                  (
                    <Grid container sx={{ height: '100%' }}>
                      <Grid item xs={12} sx={{ height: '100%' }}>
                        <img
                          alt=""
                          src={BaLogo}
                          // src={sample}
                          className="sign-in-agency-logo"
                        />
                      </Grid>
                    </Grid>
                  ) :
                  (agencyLogo?.agency_logo_url !== null) ?
                    <img
                      alt="Logo"
                      src={agencyLogo?.agency_logo_url}
                      className="sign-in-agency-logo"
                    /> :
                    (<></>)
                }
              </Grid>
              <Grid item xs={11.2} sx={{ width: "22vw", }}>
                <SignInComponent />
              </Grid>
            </Grid>

            {/* <Grid container justifyContent="center" ></Grid> */}
            {/* <Typography component="h1" variant="h4" align="center" gutterBottom>
              Welcome to myAdvisorGrids
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Sign in to your account to continue
            </Typography> */}
          </Grid>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;


