import Axios from "../axios";

export const getPrivacyPolicyAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`/auth/get-privacy-policy`,
            { headers: { "Content-Type": "application/json" } }

        )
        console.log("success response of getPrivacyPolicyAPI", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("error response of getPrivacyPolicyAPI", err);
        onError && onError(err);
    }
};

export const getPrivacyPolicyStatusAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`/global/privacy-policy-status`,
            { headers: { "Content-Type": "application/json" } }
        )
        console.log("success response of getPrivacyPolicyStatusAPI", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("error response of getPrivacyPolicyStatusAPI", err);
        onError && onError(err);
    }
};

export const manageUserPrivacyPolicyAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/auth/privacy-policy", data,
            { headers: { "Content-Type": "application/json" } }
        )
        console.log("Success response of manageUserPrivacyPolicyAPI", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Error response of manageUserPrivacyPolicyAPI", err);
        onError && onError(err);
    }
};