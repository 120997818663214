import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import './Navbar.css'
import profileIcon from '../../assets/Global/switch-roles.svg'

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Avatar,
  Grid,
  Button,
  Divider,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { LogOutApi, SwitchUserRole } from "../../utils/api/userDetailApi";
import { TrendingUpTwoTone } from "@mui/icons-material";

const BigAvatar = styled(Avatar)`
background: #1B2430;
cursor:pointer; 
width:40px;
height:40px;
font-size:15px;
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const {
    state,
    UpdateUserDetail,
    UpdateSnackbarMessage,
    UpdateFlagAuthenticated,
    FlagApiLoader,
  } = useContext(AuthContext);
  const { userDetail, delegateUserData } = {
    ...state,
  };


  const { signOut } = useAuth();

  const [userAvatar, setUserAvatar] = useState("CG");
  const [changeMenuItemName, setChangeMenuItemName] = useState("");

  useEffect(() => {
    let gg = userDetail;
    let UserData = userDetail?.data ? userDetail['data'] : userDetail
    if (delegateUserData?.user_name) {
      const namesArray = (!delegateUserData) ? '' : delegateUserData?.user_name.split(' ');
      if (namesArray.length > 0) {
        setUserAvatar((namesArray[0][0].toUpperCase()) + "" + (namesArray[1] ? namesArray[1][0].toUpperCase() : ""))
      }
    } else {
      const namesArray = (!UserData[0]?.user_name) ? '' : UserData[0]?.user_name.split(' ');
      if (namesArray.length > 0) {
        setUserAvatar((namesArray[0][0].toUpperCase()) + "" + (namesArray[1] ? namesArray[1][0].toUpperCase() : ""))
      }
    }

  }, [userDetail, delegateUserData]);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    FlagApiLoader(true);
    await LogOutApi(
      {},
      (res) => {
        console.log("response of logout", res);
        FlagApiLoader(false);
        if (res.data.status) {
          UpdateUserDetail(null);
          UpdateFlagAuthenticated(false);

          UpdateSnackbarMessage({
            status: "success",
            message: "Logged Out Successfully",
          });

          navigate("/auth/sign");
        }
      },
      (err) => {
        console.log("err", err);
        FlagApiLoader(false);
      }
    );

    // signOut();
    // navigate("/auth/sign-in");
  };

  useEffect(() => {
    if (userDetail[0].user_role_assignment_data?.length > 1) {
      let secondRole = userDetail[0].user_role_assignment_data?.filter((obj) => obj.user_role_active_flag == false)
      if (secondRole?.length) {
        let userRoleName = secondRole[0]?.user_role_name == "Member" ? "ARM" : secondRole[0]?.user_role_name
        setChangeMenuItemName(userRoleName)
      }
    }
  }, [userDetail])

  const changeLoginRole = (assignmentId) => {
    let obj = {
      user_role_assignment_id: assignmentId
    }
    SwitchUserRole(obj, (res) => {
      console.log("res", res)
      UpdateUserDetail(res.data.data)
      navigate("/")
      FlagApiLoader(false);
    }, (err) => {
      FlagApiLoader(false);
      console.log("err", err)

    })
  }

  return (
    <React.Fragment>
      <BigAvatar
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        onClick={toggleMenu}
      >
        {userAvatar}
      </BigAvatar>


      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        className="shadow_menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoFocus={false}
        PaperProps={{
          style: {
            width: '300px',
          }
        }}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}

        <Grid item className="nav-profile-menu">
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={8}
                  sx={{ maxWidth: '100%', whiteSpace: 'normal', wordBreak: "break-word", overflowWrap: "break-word" }}
                // overflowWrap: 'anywhere', 
                // wordBreak: 'break-all',
                >
                  <span className="nav-menu-agency-text"
                    style={{ display: "block", overflowWrap: "break-word", textAlign: "left" }}
                  // style={{ overflowWrap: 'break-word' }}
                  >
                    {userDetail[0]?.user_agency_data?.agency_name ? userDetail[0]?.user_agency_data?.agency_name : ""}
                  </span>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  <Button variant="text" className="nav-sign-out-btn" onClick={handleSignOut}>
                    Sign out
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="nav-profile-menu">
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={{ alignItems: 'start' }}>
                <Grid item sx={{ mr: '10px', width: '15%', maxWidth: '15%' }}>
                  <BigAvatar
                  // aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                  // onClick={toggleMenu}
                  >
                    {userAvatar}
                  </BigAvatar>
                </Grid>
                <Grid item sx={{ width: "80%" }}>
                  <Grid container sx={{ justifyContent: 'space-between' }}>
                    <Grid item sx={{ maxWidth: '100%', width: '100%', whiteSpace: 'normal', overflowWrap: 'anywhere', wordBreak: 'break-all' }}>
                      <span className="nav-menu-item-text" style={{ overflowWrap: 'break-word' }}>{userDetail[0]?.user_name ? userDetail[0]?.user_name : ''}</span>
                    </Grid>
                    <Grid item sx={{ maxWidth: '100%', width: '100%', whiteSpace: 'normal', overflowWrap: 'anywhere', wordBreak: 'break-all' }}>
                      <div className="nav-menu-email-text" style={{ wordWrap: 'break-word' }}>{userDetail[0]?.user_email_address ? userDetail[0]?.user_email_address : ''}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {userDetail[0].user_role_assignment_data?.length > 1 &&
          userDetail[0].user_role_assignment_data?.filter((obj) => obj.user_role_active_flag == false)?.length &&
          <>
            <Divider />
            <Grid item
              sx={{ padding: '10px 20px', cursor: 'pointer' }}
              onClick={() => {
                FlagApiLoader(true);
                let selectedRole = userDetail[0].user_role_assignment_data?.filter((obj) => obj.user_role_active_flag == false)[0]
                changeLoginRole(selectedRole.user_role_assignment_id)
              }}
            >
              <div style={{ display: "flex", alignItems: 'center' }}>
                <img src={profileIcon} style={{ width: '20px', height: 'auto' }}></img>
                <div style={{ margin: '0px 10px' }}>
                  <span style={{ verticalAlign: 'sub', }}>Switch to {changeMenuItemName}</span>
                </div>
              </div>
            </Grid>
          </>
        }
      </Menu>
    </React.Fragment >
  );
}

export default NavbarUserDropdown;
