import React, { useContext, useEffect, useState } from "react";
import "../../pages/css/CarriersPopup.css";

import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, } from "@mui/material";
import { GetCarrierDataList, GetCarrierLevelList, } from "../../utils/api/TemplateApi";


import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";

import { useNavigate } from "react-router-dom";

const CarriersPopup = (props) => {

    const {
        FlagApiLoader,
        setFlagCarrierList,
        UpdateSnackbarMessage,
        selectedTab
    } = useContext(authContext)

    const { setCarrierTypeDetails } = useContext(authContext);
    const { carrierTypeDetails } = useContext(authContext).state;

    const {
        setWholeCarrierData,
        setGlobalCarrierDetails,
        setGlobalCarrierLevelDetails,
        // setRollbackGlobalCarrierDetails
    } = useContext(globalCarrierContext);

    const {
        flagType,
        wholeCarrierData,
        globalCarrierDetails,
        globalCarrierLevelDetails,
        // rollbackGlobalCarrierDetails
    } = useContext(globalCarrierContext).state;

    // const [selectedCarrier, setSelectedCarrier] = useState(true)
    const [checkValue, setCheckValue] = useState(false)
    const [hidePopupData, setHidePopupData] = useState(false)

    const carrierType = carrierTypeDetails.find(type => type?.carrier_type_name?.toLowerCase() == flagType?.toLowerCase());
    const carrierTypeId = carrierType ? carrierType.carrier_type_id : null;

    useEffect(() => {
        let global_carrierData = [...wholeCarrierData]
        // let global_templateData = globalCarrierDetails && globalCarrierDetails[flagType] !== undefined && [...globalCarrierDetails[flagType]];
        // let global_templateData = Array.isArray(globalCarrierDetails[flagType]) ? [...globalCarrierDetails[flagType]] : [];

        let global_templateData = Array.isArray(globalCarrierDetails?.[flagType]) ? [...globalCarrierDetails[flagType]] : [];
        FlagApiLoader(true)
        setTimeout(() => {
            GetCarrierDataList(carrierTypeId?.toString(),
                (res) => {
                    if (res.data.status) {
                        let temp2;
                        if (!props.flagAddPage) {
                            temp2 = res.data.data.map((ele) => {
                                var checkCarrrierSelected = global_templateData?.filter(rr => rr.carrier_name == ele.carrier_name)[0]
                                if (checkCarrrierSelected) {
                                    return {
                                        ...ele,
                                        selected: true,
                                        disabled: true

                                    }
                                } else {
                                    return {
                                        ...ele,
                                        selected: false,
                                        disabled: false
                                    }
                                }
                            })
                        }
                        else {
                            temp2 = res.data.data.map((ele) => {
                                var checkCarrrierSelected = global_carrierData.filter(rr => rr.carrier_name == ele.carrier_name)[0]
                                if (checkCarrrierSelected?.selected) {
                                    return {
                                        ...ele,
                                        selected: true,
                                        disabled: true

                                    }
                                } else {
                                    return {
                                        ...ele,
                                        selected: false,
                                        disabled: false
                                    }
                                }
                            })
                        }
                        setWholeCarrierData(temp2)
                        FlagApiLoader(false)
                        setHidePopupData(true)
                    }
                    else {
                        if (res?.data?.error?.message) {
                            UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                            });
                        }
                        else {
                            UpdateSnackbarMessage({
                                status: "error",
                                message: "Something Went Wrong!"
                            });
                        }
                        FlagApiLoader(false)
                    }
                },
                (err) => {
                    console.log("error of GetCarrierDataList api", err);
                    FlagApiLoader(false)
                    setHidePopupData(true)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    });
                })
        }, 500);

    }, [])



    const GetCarriersList = () => {

        let tempSelectedCarriers = wholeCarrierData.filter((ff) => {
            return ff.selected == true
        })

        tempSelectedCarriers = tempSelectedCarriers.map(rr => {
            if (!rr.disabled) {
                return { ...rr, carrier_level_data: [] }
            } else {
                return globalCarrierDetails && globalCarrierDetails[flagType]?.filter(tt => (tt.carrier_types[0]['carrier_id'] == rr.carrier_types[0]['carrier_id']))[0]
            }
        })
        // console.log("tempSelectedCarriers", tempSelectedCarriers);
        setGlobalCarrierDetails({ ...globalCarrierDetails, [flagType]: tempSelectedCarriers });

        let data = wholeCarrierData.filter((ele) => {
            return (ele.selected == true && ele.disabled == false)
        })

        let id = ''
        for (let i = 0; i < data.length; i++) {
            id += `,${data[i].carrier_types[0].carrier_id}`
        }
        let result = id.slice(1)
        FlagApiLoader(true)
        GetCarrierLevelList(result,
            (res) => {
                if (res.data.status) {
                    console.log("response true of GetCarrierLevelList api", res);
                    var findCarrierData = { ...globalCarrierLevelDetails };
                    if (findCarrierData) {
                        if (findCarrierData[flagType]) {
                            findCarrierData[flagType] = [...findCarrierData[flagType], ...res?.data?.data]
                        }
                        else {
                            findCarrierData[flagType] = res?.data?.data;
                        }
                    }
                    else {
                        findCarrierData = {}
                        findCarrierData[flagType] = res?.data?.data;
                    }

                    setGlobalCarrierLevelDetails(findCarrierData)
                    setFlagCarrierList(true);
                    FlagApiLoader(false)
                    props.handleClosePopup();
                }
                else {
                    console.log("response false of GetCarrierLevelList api", res);
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        });
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong! 1"
                        });
                    }
                    FlagApiLoader(false)
                }
            },
            (err) => {
                console.log("error of GetCarrierLevelList api", err);
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong! 2",
                });
            })
    }

    const handleSelectAllCheckbox = (e) => {
        let isChecked = e.target.checked;
        setCheckValue(isChecked);

        let storedCheckboxValue = wholeCarrierData?.map((ele) => {
            if (!ele.disabled) { return { ...ele, selected: isChecked } }
            return ele;
        });
        setWholeCarrierData(storedCheckboxValue);
    }

    const handleCheckbox = (row) => {
        let temp = wholeCarrierData?.map((ele) => {
            if (ele?.carrier_types[0]?.carrier_id == row?.carrier_types[0].carrier_id) {
                ele.selected = !row?.selected
            } return ele
        })
        setWholeCarrierData(temp)
    }

    return (
        <>
            {hidePopupData &&
                <>
                    <Grid item xs={12} sx={{ maxHeight: "80vh", height: "60vh " }}>
                        <Grid container className='cp-head-container' sx={{ height: "100%", justifyContent: "center" }}>
                            <Grid item xs={12} className='cp-head-item' sx={{ height: "60px" }}>
                                <span className='cp-label'>Select Carriers</span>
                            </Grid>
                            <Grid item xs={11} sx={{ height: "calc(100% - 140px)" }}>
                                <Grid container sx={{ height: "100%" }}>
                                    <Grid item xs={12} sx={{ height: "40px", textAlign: "center" }}>
                                        <FormControl component="fieldset" variant="standard">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            // checked={checkValue}
                                                            // onClick={(e) => {
                                                            //     setCheckValue(e.target.checked)
                                                            //     let temp = [...wholeCarrierData];
                                                            //     temp = temp.map(ele => {
                                                            //         ele.selected = e.target.checked
                                                            //         return ele;
                                                            //     });
                                                            //     setWholeCarrierData(temp)
                                                            // }}
                                                            checked={wholeCarrierData?.every((ele) => ele.selected || ele.disabled)}
                                                            onChange={(e) => { handleSelectAllCheckbox(e) }}
                                                        />
                                                    }
                                                    label="Select All"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sx={{ height: "calc(100% - 40px)" }}>
                                        <Grid container className='carriers-checkbox-container' flexDirection="row" sx={{ maxHeight: "100%" }}>
                                            {wholeCarrierData?.map((row) => {
                                                return (
                                                    <Grid item xs={12} sm={4} md={2.4} textAlign="left">
                                                        <FormControl component="fieldset" variant="standard">
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    label={row?.carrier_name}
                                                                    control={
                                                                        <Checkbox
                                                                            name={row.carrier_name}
                                                                            checked={row?.selected ? true : false}
                                                                            disabled={row?.disabled ? true : false}
                                                                            onClick={() => {
                                                                                handleCheckbox(row)
                                                                                // let temp2 = wholeCarrierData.filter((e) => {
                                                                                //     return e.selected == false
                                                                                // })
                                                                                // if (temp2.length > 0) {
                                                                                //     setCheckValue(false)
                                                                                // } else {
                                                                                //     setCheckValue(true)
                                                                                // }
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={11} sx={{ height: "80px" }}>
                                <Grid container sx={{ justifyContent: "center", gap: "20px", height: "100%", padding: "20px 0px" }}>
                                    <Button
                                        variant='outlined'
                                        sx={{ maxWidth: "150px", width: "40%" }}
                                        onClick={() => { props.handleClosePopup() }}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant='contained'
                                        // disabled={selectedCarrier ? true : false}
                                        disabled={wholeCarrierData?.filter((e) => e.selected && !e.disabled).length === 0}
                                        sx={{ maxWidth: "150px", width: "40%" }}
                                        onClick={() => { GetCarriersList() }}
                                    >
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>

                //-------------------------------BELOW COMPONENT IS OLD COMPOENT COMMENTED ON 01-05-24---------------------------

                // useEffect(() => {
                //     let tempSelected = wholeCarrierData?.map((row) => row?.selected == true)
                //     let tempSelectedFlag = (tempSelected.indexOf(true) > -1) ? false : true
                //     setSelectedCarrier(tempSelectedFlag);
                // }, [wholeCarrierData])



                // <Grid item xs={12}>
                //     <Grid container className='cp-head-container' height={{ xs: "90vh", sm: "56vh", md: "initial" }}>
                //         <Grid item xs={12} className='cp-head-item' height={{ xs: "60px", sm: "50px", md: "initial" }}>
                //             <span className='cp-label'>Select Carriers</span>
                //         </Grid>

                //         {wholeCarrierData?.length > 1 ?
                //             <>
                //                 <Grid item xs={11} className='carriers-checkbox-item'>
                //                     <Grid container >
                //                         <Grid item xs={12} textAlign="center">
                //                             {
                //                                 <FormControl component="fieldset" variant="standard">
                //                                     <FormGroup>
                //                                         <FormControlLabel
                //                                             control={
                //                                                 <Checkbox
                //                                                     name="gilad"
                //                                                     checked={
                //                                                         checkValue
                //                                                     }
                //                                                     onClick={(e) => {
                //                                                         setCheckValue(e.target.checked)
                //                                                         let temp = [...wholeCarrierData];
                //                                                         temp = temp.map(ele => {
                //                                                             ele.selected = e.target.checked
                //                                                             return ele;
                //                                                         });
                //                                                         setWholeCarrierData(temp)
                //                                                     }}
                //                                                 />
                //                                             }
                //                                             label="Select All"
                //                                         />
                //                                     </FormGroup>
                //                                 </FormControl>
                //                             }
                //                         </Grid>
                //                     </Grid>
                //                 </Grid>

                //                 <Grid item xs={11} className='carriers-checkbox-item'>
                //                     <Grid container className='carriers-checkbox-container' flexDirection="row" height={{ xs: "65vh", sm: "35vh", md: "40vh" }}>
                //                         {wholeCarrierData?.map((row) => {
                //                             return (
                //                                 <Grid item xs={12} sm={4} md={2.4} textAlign="left">
                //                                     <FormControl component="fieldset" variant="standard">
                //                                         <FormGroup>
                //                                             <FormControlLabel
                //                                                 control={
                //                                                     <Checkbox
                //                                                         name={row.carrier_name}
                //                                                         checked={row?.selected ? true : false}
                //                                                         disabled={row?.disabled ? true : false}
                //                                                         onClick={() => {
                //                                                             let temp = wholeCarrierData.map((ele) => {
                //                                                                 if (ele.carrier_types[0].carrier_id == row.carrier_types[0].carrier_id) {
                //                                                                     ele.selected = !row?.selected
                //                                                                 } return ele
                //                                                             })
                //                                                             setWholeCarrierData(temp)

                //                                                             let temp2 = wholeCarrierData.filter((e) => {
                //                                                                 return e.selected == false
                //                                                             })
                //                                                             if (temp2.length > 0) {
                //                                                                 setCheckValue(false)
                //                                                             } else {
                //                                                                 setCheckValue(true)

                //                                                             }
                //                                                         }}
                //                                                     />
                //                                                 }
                //                                                 label={row.carrier_name}
                //                                             />
                //                                         </FormGroup>
                //                                     </FormControl>
                //                                 </Grid>
                //                             )
                //                         })}
                //                     </Grid>
                //                 </Grid>
                //             </>
                //             :
                //             !apiLoader ?
                //                 <Grid item textAlign="center" mt={2} xs={12}>
                //                     No Carrier Available
                //                 </Grid>
                //                 : ""
                //         }
                //         <Grid item xs={11} sx={{ margin: "30px auto" }}>
                //             <Grid container justifyContent={"space-evenly"}>
                //                 {/* <Grid item>
                //                     <Button
                //                         variant='outlined'
                //                         fullWidth
                //                         onClick={() => { props.handleClosePopup() }}
                //                     >
                //                         Close
                //                     </Button>
                //                 </Grid>
                //                 <Grid item>
                //                     <Button
                //                         variant='contained'
                //                         fullWidth
                //                         disabled={selectedCarrier ? true : false}
                //                         onClick={() => {
                //                             GetCarriersList()
                //                         }}
                //                     >
                //                         Continue
                //                     </Button>
                //                 </Grid> */}
                //                 <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
                //                     <Grid container className='cp-checkbox-cancel-container'>
                //                         <Grid item xl={3} lg={4} md={5} sm={5} xs={9}>
                //                             <Button
                //                                 variant='outlined'
                //                                 fullWidth
                //                                 onClick={() => { props.handleClosePopup() }}
                //                             >
                //                                 Close
                //                             </Button>
                //                         </Grid>
                //                     </Grid>
                //                 </Grid>

                //                 <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
                //                     <Grid container>
                //                         <Grid item xl={3} lg={4} md={5} sm={5} xs={9}>
                //                             <Button
                //                                 variant='contained'
                //                                 fullWidth
                //                                 disabled={selectedCarrier ? true : false}
                //                                 onClick={() => {
                //                                     GetCarriersList()
                //                                 }}
                //                             >
                //                                 Continue
                //                             </Button>
                //                         </Grid>
                //                     </Grid>
                //                 </Grid>

                //             </Grid>
                //         </Grid>
                //     </Grid>
                // </Grid>
            }
        </>
    );
};

export default CarriersPopup;
