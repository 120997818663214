import { AddAgencyLogo } from "../../utils/api/AgencyDetailsAPI";
import { LoginApi, SendOtpApi, UserMeApi } from "../../utils/api/userDetailApi";
import CreateDataContext from "./createDataContext";

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "updateUserDetail": {
      return {
        ...state,
        userDetail: action.payload,
      };
    }
    case "flagAuthenticated": {
      return {
        ...state,
        flagAuthenticated: action.payload,
      };
    }
    case "delegateAccessFlag": {
      return {
        ...state,
        delegateAccessFlag: action.payload,
      };
    }
    case "delegateUserData": {
      return {
        ...state,
        delegateUserData: action.payload,
      };
    }
    case "loginApi": {
      return {
        ...state,
        userDetail: action.payload,
      };
    }
    case "sendOtp": {
      return {
        ...state,
        otpData: action.payload,
      };
    }
    case "apiLoader":
      return {
        ...state,
        apiLoader: action.payload,
      };
    case "initialLoader":
      return {
        ...state,
        initialLoader: action.payload,
      };
    case "updateSnackbarMessage": {
      return {
        ...state,
        snackbarMessage: action.payload,
      };
    }
    case "flagTemplate": {
      return {
        ...state,
        flagTemplate: action.payload,
      };
    }
    case "flagCarrierList": {
      return {
        ...state,
        flagCarrierList: action.payload,
      };
    }

    case "setCarrierLevelData": {
      return {
        ...state,
        carrierLevelData: action.payload,
      };
    }

    case "setSelectedCarrier": {
      return {
        ...state,
        selectedCarrier: action.payload,
      };
    }
    case "setSsoErrorMessage": {
      return {
        ...state,
        ssoErrorMessage: action.payload,
      };
    }
    case 'agencyLogo': {
      return { ...state, agencyLogo: action.payload }
    }

    case 'carrierTypeDetails': {
      return { ...state, carrierTypeDetails: action.payload }
    }

    case 'carrierTypeDetailsForAgency': {
      return { ...state, carrierTypeDetailsForAgency: action.payload }
    }

    default: {
      return state;
    }
  }
};

const UpdateUserDetail = (dispatch) => (data) => {
  dispatch({
    type: "updateUserDetail",
    payload: data,
  });
};

const UpdateFlagAuthenticated = (dispatch) => (data) => {
  dispatch({
    type: "flagAuthenticated",
    payload: data,
  });
};
const UpdateDelegateAccessFlag = (dispatch) => (data) => {
  dispatch({
    type: "delegateAccessFlag",
    payload: data,
  });
};
const UpdateDelegateAccessData = (dispatch) => (data) => {
  dispatch({
    type: "delegateUserData",
    payload: data,
  });
};

const UserMeContextApi = (dispatch) => async () => {
  dispatch({
    type: "initialLoader",
    payload: true,
  });
  await UserMeApi(
    {},
    (res) => {
      if (res?.data?.status) {
        dispatch({
          type: "updateUserDetail",
          payload: res.data.data,
        });
        dispatch({
          type: "flagAuthenticated",
          payload: res.data.status,
        });
        dispatch({
          type: "delegateAccessFlag",
          payload: res?.data?.delegate_access_flag,
        });
        dispatch({
          type: "delegateUserData",
          payload: res?.data?.delegate_user_data,
        });
      } else {
        if (res?.data?.sso_error_flag) {
          dispatch({
            type: "setSsoErrorMessage",
            payload: res?.data?.error?.message,
          });
        }
      }
      dispatch({
        type: "initialLoader",
        payload: false,
      });
    },
    (err) => {
      dispatch({
        type: "initialLoader",
        payload: false,
      });
      console.log(err);
      //   alert("Something went wrong, Please try again later.!");
    }
  );
};

const AgencyLogoAPI = (dispatch) => async () => {
  dispatch({
    type: "initialLoader",
    payload: true,
  });
  await AddAgencyLogo({}, (res) => {
    if (res?.data?.status) {
      dispatch({
        type: 'agencyLogo',
        payload: res?.data?.data[0]
      })
      dispatch({
        type: "initialLoader",
        payload: false,
      });
    }
    else {
      dispatch({
        type: "initialLoader",
        payload: false,
      });
    }
  }, (err) => {
    dispatch({
      type: "initialLoader",
      payload: false,
    });
  })
};

const SentOtpContextApi = (dispatch) => async (data) => {
  await SendOtpApi(
    data,
    (res) => {
      if (res.status) {
        dispatch({
          type: "sendOtp",
          payload: res.data.data,
        });
      }
    },
    (err) => {
      console.log(err);
      //   alert("Something went wrong, Please try again later.!");
    }
  );
};

const LoginContextApi = (dispatch) => async (data) => {
  await LoginApi(
    data,
    (res) => {
      if (res.status) {
        dispatch({
          type: "loginApi",
          payload: res.data.data,
        });
      }
    },
    (err) => {
      console.log(err);
      //   alert("Something went wrong, Please try again later.!");
    }
  );
};
const UpdateSnackbarMessage = (dispatch) => async (data) => {
  dispatch({
    type: "updateSnackbarMessage",
    payload: data,
  });
};
const FlagApiLoader = (dispatch) => async (data) => {
  dispatch({
    type: "apiLoader",
    payload: data,
  });
};
const FlagInitialLoader = (dispatch) => async (data) => {
  dispatch({
    type: "initialLoader",
    payload: data,
  });
};

const setFlagTemplate = (dispatch) => async (data) => {
  dispatch({
    type: "flagTemplate",
    payload: data,
  });
};
const setFlagCarrierList = (dispatch) => async (data) => {
  dispatch({
    type: "flagCarrierList",
    payload: data,
  });
};


const setCarrierLevelData = (dispatch) => async (data) => {
  dispatch({
    type: "setCarrierLevelData",
    payload: data,
  });
};

const setSelectedCarrier = (dispatch) => async (data) => {
  dispatch({
    type: "setSelectedCarrier",
    payload: data,
  });
};

const setAgencyLogo = (dispatch) => async (data) => {
  dispatch({
    type: "agencyLogo",
    payload: data
  });
}

const setCarrierTypeDetails = (dispatch) => async (data) => {
  dispatch({
    type: "carrierTypeDetails",
    payload: data
  });
}

const setCarrierTypeDetailsForAgency = (dispatch) => async (data) => {
  dispatch({
    type: "carrierTypeDetailsForAgency",
    payload: data
  });
}




export const { Provider, Context, state } = CreateDataContext(
  AuthReducer,
  {
    UserMeContextApi,
    SentOtpContextApi,
    UpdateSnackbarMessage,
    LoginContextApi,
    UpdateUserDetail,
    UpdateFlagAuthenticated,
    UpdateDelegateAccessFlag,
    UpdateDelegateAccessData,
    FlagInitialLoader,
    FlagApiLoader,
    setFlagTemplate,
    setFlagCarrierList,

    setCarrierLevelData,
    setSelectedCarrier,
    setAgencyLogo,
    AgencyLogoAPI,
    setCarrierTypeDetails,
    setCarrierTypeDetailsForAgency
  },

  {
    userDetail: null,
    delegateAccessData: null,
    flagAuthenticated: null,
    delegateAccessFlag: null,

    apiLoader: false,
    initialLoader: true,
    otpData: null,
    snackbarMessage: {
      status: false,
      message: "",
    },
    flagTemplate: null,
    flagCarrierList: null,
    carrierLevelData: [],
    selectedCarrier: [],
    ssoErrorMessage: "",
    agencyLogo: '',
    carrierTypeDetails: [],
    carrierTypeDetailsForAgency: []
  }
);
