import { Close } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Context as agencyContext } from '../../../../contexts/reducerContexts/agencyContext';


const AgencyPrivacyPolicyPreview = (props) => {

    const { flagOpenHtmlDialog, setFlagOpenHtmlDialog } = props
    const { agencyHtmlContent, agencyDetails } = useContext(agencyContext).state
    const privacyPolicyFileUrl = agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_url


    return (
        <>
            <Grid container sx={{ minHeight: "80vh" }}>
                <Grid item xs={12} sx={{ padding: '10px 0px', height: "50px" }}>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} sx={{ textAlign: 'center' }}>
                            <Typography variant='span' sx={{ fontSize: '20px', fontWeight: '500', color: 'black' }}>
                                Privacy Policy
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'end' }}>
                            <Close
                                sx={{ cursor: "pointer", marginRight: '15px' }}
                                onClick={() => {
                                    setFlagOpenHtmlDialog(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(80vh - 50px)" }}>
                    <Grid container sx={{ height: "100%", }}>
                        <iframe
                            style={{ height: "100%", width: "100%", paddingLeft: "20px" }}
                            src={privacyPolicyFileUrl ? privacyPolicyFileUrl : agencyDetails?.privacy_policy_gcs_url}
                            srcDoc={agencyHtmlContent}
                            title="Doc"
                        >

                        </iframe>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyPrivacyPolicyPreview