// REACT PLUGIN
import { useContext, useEffect, useState } from "react";

// CSS
import '../../css/Agency/AgencyWhiteLabelDomain.css'

// MUI 
import {
    Button, Grid, Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow, TextField, Typography
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

// HOOKS
import { useSearchParams } from "react-router-dom";

// CONTEXT PLUGIN
import { getAgencyWhiteListedDomainAPI, manageAgencyWhiteListedDomainAPI, validateWhitelistDomain } from "../../../utils/api/AgencyDetailsAPI";
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";
import CheckURLPopUp from "./AgencyDialogs/CheckURLPopUp";
import GlobalDialog from "../../../components/global/GlobalDialog";
import comingSoon from '../../../assets/agency/Cooming-Soon.svg'


const AgencyWhiteLabelDoamin = () => {

    // ------------CONTEXT------------
    const {
        createAgencyId,
        agencyWhiteListedDomainDetails,
        domainProcess
    } = useContext(agencyContext).state

    const {
        setAgencyWhiteListedDomainDetails,
        setDomainProcess
    } = useContext(agencyContext)

    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(AuthContext);
    const { apiLoader, userDetail } = useContext(AuthContext).state;
    // ------------CONTEXT------------



    // ------------HOOKS------------
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")
    // ------------HOOKS------------


    // ------------REGEX------------ 
    const customUrlRegex = /^[a-z0-9]+(-[a-z0-9]+)?\.[a-z]{2,}(\.[a-z]{2,})?$/
    const emailRegex = /^[a-z0-9]+$/;
    // ------------REGEX------------ 


    // ------------USE STATE------------
    const [flagIsClickedYesBtn, setFlagIsClickedYesBtn] = useState('no')
    const [flagMatchedCustomUrl, setFlagMatchedCustomUrl] = useState(false)
    const [flagProceedContinueBtn, setFlagProceedContinueBtn] = useState(false)
    const [flagIsClikedOutside, setFlagIsClikedOutside] = useState(false)
    const [flagShowSendEamilTextfield, setFlagShowSendEmailTextfield] = useState(false)
    const [flagMagDomainDeployed, setFalgMagDomainDeployed] = useState(false)
    const [flagSenderEmailEmpty, setFlagSenderEmailEmpty] = useState(false)
    const [flagFetchingData, setFlagFetchingData] = useState(true)
    const [flagChangeInSenderEmail, setFlagChangeInSenderEmail] = useState(false)
    const [isContinueBtnDisabled, setIsContinueBtnDisabled] = useState(true)
    const [emailDomain, setEmailDomain] = useState('example.com');
    const [domainanchorEl, setDomainanchorEl] = useState(null);
    const [flagOpenConfigureDomain, setFlagOpenConfigureDomain] = useState(false);
    const [flag, setFlag] = useState(false);

    const openDomainMenu = Boolean(domainanchorEl);
    // ------------USE STATE------------

    const tableMenuOptions = ['View',];
    const brokersAllianceAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance"

    // LOGS
    // console.log("agencyWhiteListedDomainDetails", agencyWhiteListedDomainDetails);
    // console.log("createAgencyId", createAgencyId);
    // console.log("emailDomain", emailDomain);
    // console.log("domainProcess", domainProcess);
    // console.log("brokersAllianceAgency dom", brokersAllianceAgency)
    // console.log("userDetail logs", userDetail)

    // USE EFFECTS
    useEffect(() => {
        if (flag) {
            if (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance") {
                setFalgMagDomainDeployed(false)
                setFlagChangeInSenderEmail(false)
                setFlagFetchingData(true)
                let params = {
                    agency_id: createAgencyId ? createAgencyId : agencyId
                }

                FlagApiLoader(true)
                getAgencyWhiteListedDomainAPI(params,
                    (res) => {
                        if (res?.data?.status) {
                            if (res?.data?.data?.length) {
                                console.log("response true of getAgencyWhiteListedDomainAPI", res);
                                setAgencyWhiteListedDomainDetails(res?.data?.data[0])
                                const agencyDomain = res?.data?.data[0].agency_custom_domain_value
                                setEmailDomain(agencyDomain)
                                setFalgMagDomainDeployed(true)
                                setDomainProcess({
                                    ...domainProcess,
                                    continue: true,
                                    editing: false,
                                    showDomainQue: false,
                                    proceedYesButton: true
                                })
                                setFlagFetchingData(false)
                                setFlagChangeInSenderEmail(false)
                                FlagApiLoader(false)
                            }
                            else {
                                setAgencyWhiteListedDomainDetails(res?.data?.data[0])
                                setFlagShowSendEmailTextfield(true)
                                setFalgMagDomainDeployed(false)
                                setDomainProcess({
                                    ...domainProcess,
                                    continue: false,
                                    editing: false,
                                    showDomainQue: true,
                                    proceedYesButton: false
                                })
                                FlagApiLoader(false)
                                setFlagFetchingData(false)
                                setFlagChangeInSenderEmail(false)
                            }
                        }
                        else {
                            console.log("response false of getAgencyWhiteListedDomainAPI", res);
                            FlagApiLoader(false)
                            setFlagFetchingData(false)

                        }
                    },
                    (err) => {
                        console.log("error of getAgencyWhiteListedDomainAPI", err);
                        FlagApiLoader(false)
                        setFlagFetchingData(false)
                    })
            }
        }
    }, [])

    useEffect(() => {
        if (flag) {

            if (!agencyWhiteListedDomainDetails || agencyWhiteListedDomainDetails.length === 0) {
                setIsContinueBtnDisabled(true);
                return;
            }
            const { agency_custom_domain_value, agency_sender_email_address } = agencyWhiteListedDomainDetails;
            const isValidCustomUrl = customUrlRegex.test(agency_custom_domain_value);
            const isEmptyCustomUrl = !agency_custom_domain_value;
            const isEmptyEmail = !agency_sender_email_address;
            const isValidEmail = !isEmptyEmail && emailRegex.test(agency_sender_email_address);

            setIsContinueBtnDisabled(!(isValidCustomUrl && !isEmptyCustomUrl && isValidEmail));
            // setIsContinueBtnDisabled(!(!isEmptyCustomUrl && isValidEmail));
        }
    }, [agencyWhiteListedDomainDetails]);


    // FUNCTIONS : 
    const handleClick = (event) => {
        setDomainanchorEl(event.currentTarget);
    };

    const handleManageAgencyWhitelistedDomain = () => {

        let payload = {
            agency_id: createAgencyId,
            agency_custom_domain_value: agencyWhiteListedDomainDetails?.agency_custom_domain_value,
            agency_sender_email_address: agencyWhiteListedDomainDetails?.agency_sender_email_address + "" + `@${emailDomain}`,
        }

        FlagApiLoader(true)
        manageAgencyWhiteListedDomainAPI(payload,
            (res) => {
                console.log("response true of manageAgencyWhiteListedDomainAPI", res);
                if (res.data.status) {
                    setAgencyWhiteListedDomainDetails(res?.data?.data[0])
                    setDomainProcess({
                        ...domainProcess,
                        continue: true,
                        editing: false,
                        showDomainQue: false,
                        proceedYesButton: true
                    })
                    setFlagChangeInSenderEmail(false)
                    FlagApiLoader(false)
                }
                else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    }
                    FlagApiLoader(false)
                }
            },
            (err) => {
                console.log("response true of manageAgencyWhiteListedDomainAPI", err);
                FlagApiLoader(false);
                UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!", });
            })
    }

    const handleDomainChange = (e) => {
        const inputValue = e.target.value
        setAgencyWhiteListedDomainDetails({
            ...agencyWhiteListedDomainDetails,
            agency_custom_domain_value: inputValue
        });

        if (!inputValue) {
            setFlagMatchedCustomUrl(true);
            setFlagIsClikedOutside(false);
        }
    };

    const handleDomainBlur = () => {
        setFlagIsClikedOutside(true)
        // if (agencyWhiteListedDomainDetails.agency_custom_domain_value) {
        //     let domainValueArr = agencyWhiteListedDomainDetails.agency_custom_domain_value.split(".")
        //     let domain = `${domainValueArr[domainValueArr.length - 2]}.${domainValueArr[domainValueArr.length - 1]}`
        //     setEmailDomain(domain);
        //     setFlagMatchedCustomUrl(true);
        // } else {
        //     setFlagMatchedCustomUrl(false);
        //     setEmailDomain('example.com');
        // }
        if (agencyWhiteListedDomainDetails.agency_custom_domain_value) {
            let domainValueArr = agencyWhiteListedDomainDetails.agency_custom_domain_value.split(".");
            if (domainValueArr.length >= 2) {
                let domain = `${domainValueArr[domainValueArr.length - 2]}.${domainValueArr[domainValueArr.length - 1]}`;
                setEmailDomain(domain);
                setFlagMatchedCustomUrl(true);
            } else {
                setFlagMatchedCustomUrl(false);
                setEmailDomain('example.com');
            }
        } else {
            setFlagMatchedCustomUrl(false);
            setEmailDomain('example.com');
        }
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        console.log("email", email);
        if (emailRegex.test(email) || email === "") {
            if (flagSenderEmailEmpty) { setFlagSenderEmailEmpty(false) }
            if (!flagChangeInSenderEmail) { setFlagChangeInSenderEmail(true) }
            setAgencyWhiteListedDomainDetails({
                ...agencyWhiteListedDomainDetails,
                agency_sender_email_address: email
            });
        }
    };

    const handleOnBlurOfSenderEmail = (e) => {
        const value = e.target.value
        if (value == "") {
            setFlagSenderEmailEmpty(true)
        } else {
            setFlagShowSendEmailTextfield(false)
            setFlagSenderEmailEmpty(false)
        }
    }

    const handleWhiteListDomainValidation = () => {
        const agency_id = brokersAllianceAgency ? agencyId : null
        const payload = { agency_id: parseInt(agency_id) }
        FlagApiLoader(true)
        validateWhitelistDomain(payload,
            (res) => {
                if (res?.data?.status) {
                    const data = res?.data?.data[0]
                    const varifiedAllDomains = data.agency_custom_domain_dns_records_data.every(data => {
                        return data.agency_domain_dns_record_status_name == "Verified"
                    })
                    if (varifiedAllDomains) {
                        UpdateSnackbarMessage({ status: "success", message: "Your Whitelisted Domain Is Ready To Use" });
                    }
                    setAgencyWhiteListedDomainDetails(data)
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                if (err?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: err?.data?.error?.message });
                    FlagApiLoader(false)
                }
                else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                }
            }
        )
    }

    return (
        <>

            <GlobalDialog
                className={"agc-checkurlpop"}
                open={flagOpenConfigureDomain}
                data={<CheckURLPopUp
                    // magurl={agencyDetails.agency_mag_url}
                    // customurl={agencyDetails.agency_custom_url}
                    // agencyId={agencyId}
                    // handleCheckURLPopUp={handleCheckURLPopUp}
                    agencyWhiteListedDomainDetails={agencyWhiteListedDomainDetails}
                    flagOpenConfigureDomain={flagOpenConfigureDomain}
                    setFlagOpenConfigureDomain={setFlagOpenConfigureDomain}
                />}
            />

            <Grid container sx={{ height: '100%' }}>
                <Grid item xs={12} sx={{ height: '100%' }}>
                    <Grid container sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Grid container sx={{ justifyContent: 'center' }}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="span"
                                        sx={{ fontSize: '25px', fontWeight: '600', color: 'black' }}
                                    >
                                        Coming Soon
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '5px' }}>
                                    <Typography
                                        variant="span"
                                        sx={{
                                            color: '#696969',
                                            fontSize: '15px',
                                            fontWeight: '500'
                                        }}
                                    >
                                        This feature will be available soon
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ maxWidth: '200px', width: '200px', height: '200px', maxHeight: '200px' }}>
                                    <img
                                        src={comingSoon}
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {false &&
                <>
                    {!flagFetchingData &&
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="span" className="agwd-domain-head-text">
                                            Whitelist Domain
                                            {
                                                !flagMagDomainDeployed && !domainProcess.showDomainQue &&
                                                <Typography variant="span">
                                                    <span>&nbsp;/&nbsp;</span>
                                                    <span style={{ color: '#1565C0', fontSize: '14px', fontWeight: '500', letterSpacing: '0.1px' }}>
                                                        Domain Authentication
                                                    </span>
                                                </Typography>
                                            }
                                        </Typography>
                                    </Grid>

                                    {!flagMagDomainDeployed &&
                                        <Grid item xs={12} className="agwd-content-main-item">
                                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                                <Grid item xs={10.5}>
                                                    <Grid container>
                                                        {domainProcess.showDomainQue &&
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid item xs={12} >
                                                                        <Typography variant="span" className="agwd-domain-que-text">
                                                                            Do you want to deploy myAdvisorGrids to your custom domain?
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} className="agwd-domain-btn-item">
                                                                        <Grid container sx={{ gap: '20px' }}>
                                                                            <Grid item >
                                                                                <Button
                                                                                    variant={domainProcess.proceedYesButton ? "contained" : "outlined"}
                                                                                    size="medium"
                                                                                    fullWidth
                                                                                    className={"agwd-outlied-btn"}
                                                                                    id='yesbtn'
                                                                                    onClick={() => {
                                                                                        document.getElementById("yesbtn").style.background = '#1565C0'
                                                                                        document.getElementById("yesbtn").style.color = '#ffffff'
                                                                                        document.getElementById("nobtn").style.background = '#ffffff'
                                                                                        document.getElementById("nobtn").style.color = '#1565C0'
                                                                                        setTimeout(() => {
                                                                                            setDomainProcess({
                                                                                                ...domainProcess,
                                                                                                proceedYesButton: true,
                                                                                                continue: false,
                                                                                                editing: false,
                                                                                                showDomainQue: false,
                                                                                            });
                                                                                            document.getElementById("yesbtn").style.background = 'transparent'

                                                                                        }, 200);
                                                                                    }}
                                                                                >
                                                                                    Yes
                                                                                </Button>
                                                                            </Grid>
                                                                            <Grid item >
                                                                                <Button
                                                                                    variant={domainProcess.proceedYesButton == false ? "contained" : "outlined"}
                                                                                    size="medium"
                                                                                    fullWidth
                                                                                    className={"agwd-contained-btn"}
                                                                                    id='nobtn'
                                                                                    onClick={() => {
                                                                                        // document.getElementById("nobtn").style.background = '#ffffff'
                                                                                        // document.getElementById("nobtn").style.color = '#1565C0'
                                                                                        // setTimeout(() => {
                                                                                        //     setDomainProcess({
                                                                                        //         ...domainProcess,
                                                                                        //         proceedYesButton: false,
                                                                                        //         continue: false,
                                                                                        //         editing: false,
                                                                                        //         showDomainQue: false,
                                                                                        //     });
                                                                                        //     document.getElementById("no").style.background = '#1565C0'
                                                                                        //     document.getElementById("nobtn").style.color = '#fff'
                                                                                        // }, 200);
                                                                                    }}
                                                                                >
                                                                                    No
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>}

                                                        {domainProcess.proceedYesButton &&
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid item xs={4}>
                                                                        <Grid container>
                                                                            <Grid item xs={12} className="agwd-domain-field-main-item">
                                                                                <TextField
                                                                                    fullWidth
                                                                                    variant='standard'
                                                                                    autoComplete='off'
                                                                                    name={"Domain_URL"}
                                                                                    className='ag-details-textfield'
                                                                                    inputProps={{ maxLength: 50 }}
                                                                                    FormHelperTextProps={{ sx: { color: 'red' } }}
                                                                                    value={agencyWhiteListedDomainDetails?.agency_custom_domain_value ? agencyWhiteListedDomainDetails?.agency_custom_domain_value : ''}
                                                                                    disabled={domainProcess.continue}
                                                                                    onChange={(e) => { handleDomainChange(e) }}
                                                                                    onFocus={() => {
                                                                                        // if (customUrlRegex.test(agencyWhiteListedDomainDetails?.agency_custom_domain_value)) {
                                                                                        if (agencyWhiteListedDomainDetails?.agency_custom_domain_value) {
                                                                                            setFlagMatchedCustomUrl(true)
                                                                                        } else {
                                                                                            setFlagMatchedCustomUrl(false)
                                                                                        }
                                                                                    }}
                                                                                    label={
                                                                                        <span style={{ fontSize: '15px', fontWeight: '500' }}>
                                                                                            Enter Domain <span className='ag-details-textfield-asterik'>*</span>
                                                                                        </span>
                                                                                    }
                                                                                    InputLabelProps={{ className: "ag-details-textfield-label" }}
                                                                                    helperText={(!flagMatchedCustomUrl && flagIsClikedOutside && agencyWhiteListedDomainDetails?.agency_custom_domain_value) ? "Incorrect Custom URL" : ""}
                                                                                    onBlur={(e) => { handleDomainBlur(e) }}
                                                                                />

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} className="agwd-email-main-item">
                                                                        <Grid container>
                                                                            <Grid item xs={12} sx={{ mb: '8px' }}>
                                                                                <span className="agwd-email-label">Enter Email Address from which you wish to send emails <span style={{ color: 'red' }}>*</span></span>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Grid container className="agwd-email-grid-container">
                                                                                    <Grid container className={'ag-edit-details-mag-url-container'}>
                                                                                        <Grid item sx={{ overflow: "auto" }}>
                                                                                            <Grid container sx={{ alignItems: "center" }}>
                                                                                                {(flagShowSendEamilTextfield || domainProcess.editing) &&
                                                                                                    <TextField
                                                                                                        variant='standard'
                                                                                                        name={"mag_url"}
                                                                                                        autoComplete="off"
                                                                                                        sx={{ maxWidth: '180px', width: '180px', marginRight: "10px" }}
                                                                                                        className='ag-details-textfield'
                                                                                                        value={agencyWhiteListedDomainDetails?.agency_sender_email_address ? agencyWhiteListedDomainDetails?.agency_sender_email_address.split("@", 2)[0] : ""}
                                                                                                        inputProps={{ maxLength: 16 }}
                                                                                                        onChange={(e) => { handleEmailChange(e) }}
                                                                                                        placeholder=""
                                                                                                        onBlur={(e) => handleOnBlurOfSenderEmail(e)}
                                                                                                        FormHelperTextProps={{ sx: { color: 'red' } }}
                                                                                                        InputProps={{
                                                                                                            style: {
                                                                                                                backgroundColor: "white",
                                                                                                                fontFamily: "Nunito",
                                                                                                                fontSize: "13px",
                                                                                                                padding: '3px 10px'

                                                                                                            },
                                                                                                            disableUnderline: true
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                <Typography
                                                                                                    className='ag-details-typography'
                                                                                                    sx={{
                                                                                                        lineHeight: 2.3,
                                                                                                        cursor: flagProceedContinueBtn ? "not-allowed" : "auto",
                                                                                                        color: flagProceedContinueBtn ? "grey" : "black",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        // WILL BE USE IS FURTURE WITH EDIT BUTTON
                                                                                                        // (domainProcess.continue && !domainProcess.editing) ? agencyWhiteListedDomainDetails?.agency_sender_email_address :
                                                                                                        //     (!domainProcess.continue && !domainProcess.editing && !flagShowSendEamilTextfield) ? agencyWhiteListedDomainDetails?.agency_sender_email_address + `@${emailDomain}`
                                                                                                        //         : `@${emailDomain}`
                                                                                                        // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                                                                                                        (domainProcess.continue && !flagShowSendEamilTextfield && !flagChangeInSenderEmail) ? agencyWhiteListedDomainDetails?.agency_sender_email_address
                                                                                                            : ((!domainProcess.continue || domainProcess.continue) && !flagShowSendEamilTextfield && flagChangeInSenderEmail) ? agencyWhiteListedDomainDetails?.agency_sender_email_address + `@${emailDomain}`
                                                                                                                : `@${emailDomain}`
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    {/* {(!flagShowSendEamilTextfield && !domainProcess.continue && !domainProcess.editing) && */}
                                                                                    {(!flagShowSendEamilTextfield) &&
                                                                                        <IconButton onClick={() => setFlagShowSendEmailTextfield(true)}><EditIcon sx={{ fontSize: "20px" }} color="primary" /></IconButton>}
                                                                                </Grid>
                                                                            </Grid>
                                                                            {flagSenderEmailEmpty &&
                                                                                <Grid item xs={12}>
                                                                                    <Typography sx={{ fontSize: "12px", color: "red" }}>
                                                                                        Please fill this field
                                                                                    </Typography>
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        {apiLoader == false && !domainProcess.showDomainQue && !domainProcess.continue &&
                                                            <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                                <Button
                                                                    variant="contained"
                                                                    size="medium"
                                                                    className="agwd-contained-btn"
                                                                    disabled={isContinueBtnDisabled}
                                                                    sx={{
                                                                        '&.Mui-disabled': {
                                                                            background: "#1565C0 !important",
                                                                            opacity: '0.5 !important',
                                                                            color: '#fff',
                                                                            cursor: "not-allowed !important",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        // setFlagProceedContinueBtn(true)
                                                                        handleManageAgencyWhitelistedDomain()
                                                                    }}
                                                                >
                                                                    Continue
                                                                </Button>

                                                            </Grid>}
                                                    </Grid>
                                                </Grid>

                                                {/* {
                                            // flagProceedContinueBtn &&
                                            domainProcess.continue &&
                                            <Grid item xs={0.8}>
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    fullWidth
                                                    className="agwd-edit-btn"
                                                    startIcon={<EditIcon />}
                                                    onClick={() => {
                                                        // setFlagProceedContinueBtn(false)
                                                        setDomainProcess({
                                                            ...domainProcess,
                                                            continue: false,
                                                            editing: true
                                                        })
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            </Grid>
                                        } */}
                                            </Grid>
                                        </Grid>
                                    }

                                    {(agencyWhiteListedDomainDetails !== null && flagMagDomainDeployed) &&
                                        <>
                                            <Grid item xs={12} sx={{ marginTop: "15px" }}>
                                                <TableContainer className="agwd-whitelist-domain-tablecontainer">
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead className='agency-user-table-head'>
                                                            <TableRow>
                                                                <TableCell
                                                                    align="left"
                                                                    className='agency-table-head agnecy-tablefirst-cell'
                                                                    stickyHeader
                                                                >
                                                                    Status
                                                                </TableCell>

                                                                <TableCell
                                                                    align="left"
                                                                    className='agency-table-head'
                                                                    stickyHeader
                                                                >
                                                                    Name
                                                                </TableCell>

                                                                <TableCell
                                                                    align="left"
                                                                    className='agency-table-head'
                                                                    stickyHeader
                                                                >
                                                                </TableCell>

                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody className='agency-user-table-body'>

                                                            <TableRow
                                                            >
                                                                <TableCell
                                                                    align="left"
                                                                    sx={{ background: '#fff' }}
                                                                    className={`awd_domain_record_tbl_cell
                                                                ${agencyWhiteListedDomainDetails?.agency_custom_domain_status_name === "Verified" ? "agwd-status-varified"
                                                                            : agencyWhiteListedDomainDetails?.agency_custom_domain_status_name === "Pending" ? "agwd-status-pending"
                                                                                : agencyWhiteListedDomainDetails?.agency_custom_domain_status_name === "Failed" ? "agwd-status-failed" : ""
                                                                        }`}
                                                                >
                                                                    {agencyWhiteListedDomainDetails?.agency_custom_domain_status_name}
                                                                </TableCell>

                                                                <TableCell
                                                                    align="left"
                                                                    className="awd_domain_record_tbl_cell"
                                                                    sx={{ background: '#fff' }}
                                                                >
                                                                    {agencyWhiteListedDomainDetails?.agency_custom_domain_value}

                                                                </TableCell>

                                                                <TableCell
                                                                    align="right"
                                                                    sx={{ background: '#fff', maxWidth: '150px', }}
                                                                    className="awd_domain_record_tbl_cell"
                                                                >
                                                                    <div>
                                                                        <IconButton
                                                                            aria-label="more"
                                                                            id="long-button"
                                                                            aria-controls={openDomainMenu ? 'long-menu' : undefined}
                                                                            aria-expanded={openDomainMenu ? 'true' : undefined}
                                                                            aria-haspopup="true"
                                                                            className="agwd_table_iconbutton"
                                                                            onClick={(event) => setDomainanchorEl(event.currentTarget)}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                        <Menu
                                                                            id="long-menu"
                                                                            MenuListProps={{
                                                                                'aria-labelledby': 'long-button',
                                                                            }}
                                                                            anchorEl={domainanchorEl}
                                                                            open={openDomainMenu}
                                                                            onClose={() => setDomainanchorEl(null)}
                                                                            PaperProps={{
                                                                                style: {
                                                                                    border: "1px solid #EAEAEA"
                                                                                    // maxHeight: 48 * 4.5,
                                                                                    // width: '20ch',
                                                                                },
                                                                            }}
                                                                        >
                                                                            {tableMenuOptions.map((option) => (
                                                                                <MenuItem key={option} value={option} onClick={() => {
                                                                                    if (option == "View") {
                                                                                        setDomainanchorEl(null)
                                                                                        setFalgMagDomainDeployed(false)
                                                                                    }
                                                                                }}
                                                                                >
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Menu>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>


                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>

                                        </>
                                    }
                                </Grid>
                            </Grid>
                            {
                                (domainProcess.continue && !flagMagDomainDeployed) &&

                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className="agwd-table-text-main-item" >
                                            <span onClick={() => setFlagOpenConfigureDomain(true)}>How to Configure your Domain?</span>
                                        </Grid>
                                        {
                                            agencyWhiteListedDomainDetails != null &&
                                            agencyWhiteListedDomainDetails?.agency_custom_domain_dns_records_data?.length > 0 &&
                                            <>
                                                <Grid item xs={12} className="agwd-table-main-item">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <TableContainer className="agwd-domain-dns-tablecontainer">
                                                                <Table size="small" aria-label="a dense table">
                                                                    <TableHead className='agency-user-table-head'>
                                                                        <TableRow>
                                                                            <TableCell
                                                                                align="left"
                                                                                className='agency-table-head agnecy-tablefirst-cell'
                                                                                stickyHeader
                                                                            >
                                                                                Status
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="left"
                                                                                className='agency-table-head'
                                                                                stickyHeader
                                                                            >
                                                                                Type
                                                                            </TableCell>

                                                                            <TableCell
                                                                                align="left"
                                                                                className='agency-table-head'
                                                                                stickyHeader
                                                                            >
                                                                                Content
                                                                            </TableCell>


                                                                            <TableCell
                                                                                align="left"
                                                                                className='agency-table-head agnecy-tablelast-cell'
                                                                            >
                                                                                Value
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody className='agency-user-table-body'>
                                                                        {
                                                                            agencyWhiteListedDomainDetails?.agency_custom_domain_dns_records_data?.map((domainRecord) => {
                                                                                return (
                                                                                    <TableRow
                                                                                    // className={userId == rowId ? 'agency-table-body-row' : ""} 
                                                                                    >
                                                                                        <TableCell
                                                                                            align="left"
                                                                                            sx={{ background: '#fff' }}
                                                                                            className={`awd_domain_record_tbl_cell 
                                                                            ${domainRecord?.agency_domain_dns_record_status_name === "Verified" ? "agwd-status-varified"
                                                                                                    : domainRecord?.agency_domain_dns_record_status_name === "Pending" ? "agwd-status-pending"
                                                                                                        : domainRecord?.agency_domain_dns_record_status_name === "Failed" ? "agwd-status-failed" : ""
                                                                                                }`}
                                                                                        >
                                                                                            {domainRecord?.agency_domain_dns_record_status_name ? domainRecord?.agency_domain_dns_record_status_name : ''}
                                                                                        </TableCell>

                                                                                        <TableCell
                                                                                            align="left"
                                                                                            sx={{ background: '#fff' }}
                                                                                            className="awd_domain_record_tbl_cell"
                                                                                        >
                                                                                            {domainRecord?.agency_domain_dns_record_type ? domainRecord?.agency_domain_dns_record_type : ''}
                                                                                        </TableCell>

                                                                                        <TableCell
                                                                                            align="left"
                                                                                            sx={{ background: '#fff' }}
                                                                                            className="awd_domain_record_tbl_cell"
                                                                                        >
                                                                                            {domainRecord?.agency_domain_dns_record_status_name ? domainRecord?.agency_domain_dns_record_content : ''}
                                                                                        </TableCell>

                                                                                        <TableCell
                                                                                            align="left"
                                                                                            sx={{ background: '#fff' }}
                                                                                            className="awd_domain_record_tbl_cell"
                                                                                        >
                                                                                            {domainRecord?.agency_domain_dns_record_value ? domainRecord?.agency_domain_dns_record_value : ''}
                                                                                        </TableCell>

                                                                                    </TableRow>
                                                                                )
                                                                            })

                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>

                                                        </Grid>

                                                        <Grid item xs={12} sx={{ margin: '25px 0px 0px' }}>
                                                            <Button
                                                                variant="contained"
                                                                size="medium"
                                                                fullWidth
                                                                className="agwd-contained-btn"
                                                                onClick={handleWhiteListDomainValidation}
                                                            >
                                                                Verify
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }

                                    </Grid>
                                </Grid>
                            }

                        </Grid >
                    }
                </>
            }


        </>
    )

}

export default AgencyWhiteLabelDoamin;