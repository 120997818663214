import React from 'react'
import "../css/Iframe.css"
import "../css/template.css"

import { Divider, Grid, Typography } from '@mui/material'

function Iframe() {
    return (
        <Grid container className='grid-correction-container-height'>
            <Grid item xs={12} className='grid-correction-container-height'>
                <Grid container className='grid-correction-container-height'>
                    <Grid item xs={12} className='grid-correction-head-item mainPageStickyHeader'>
                        <Grid container>
                            <Grid item xs={12} sx={{ margin: "12px 0px" }}>
                                <Typography variant='span' >
                                    Grids Corrections
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className='grid-correction-iframe-main-item'>
                        <Grid container className='grid-correction-container-height' sx={{ alignItems: "center" }}>
                            <Grid item className='grids-correction-iframe-item'>
                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLSe-IqqLkd0Sn91kAxzMFb471O34bK_m_FPIt-Nkwwn2hvHf2Q/viewform?usp=header"
                                    width="100%"
                                    height="800px"
                                    frameBorder="0"
                                    marginHeight="0"
                                    marginWidth="0"
                                    title="Google Form"
                                    style={{ overflow: 'auto', verticalAlign: 'middle' }}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Iframe