import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../css/ViewCarriarStyles/ProductMappingDialog.css'

import { Autocomplete, Box, Button, Divider, Grid, InputAdornment, Popper, TextField, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { syncAICarrieWithMagCarrierproduct } from '../../../utils/api/CarriersApi';


function ProductMappingDialog(props) {

    const {
        setFlagProductMappingDialog,
        carrierId,
        setFlagCall,
        carrierDetails,
        criteriaCellData,
        productRowGroupDetails
    } = props

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)

    const {
        productMappingListData,
    } = useContext(CarrierContext).state

    const [selectedProduct, setSelectedProduct] = useState({
        carrier_mag_id: "",
        carrier_ai_id: "",
        product_mag_id: "",
        product_ai_id: "",
        product_name: "",
        remove_product_maping_flag: false,
    });
    const inputRef = useRef(null);

    useEffect(() => {
        if (carrierDetails) {
            setSelectedProduct({
                carrier_ai_id: carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id : "",
                carrier_mag_id: carrierId,

                product_ai_id: productRowGroupDetails?.groupData?.carrier_product_ai_mapping_data?.product_ai_id,
                product_mag_id: productRowGroupDetails?.groupData?.carrier_product_data_id ? productRowGroupDetails?.groupData?.carrier_product_data_id : "",

                product_name: productMappingListData?.find((item) => item?.product_ai_id == productRowGroupDetails?.groupData?.carrier_product_ai_mapping_data?.product_ai_id)?.product_name
            })
        }
    }, [carrierDetails])

    const saveProductAIMapping = () => {
        const payload = {
            carrier_mag_id: Number(carrierId),
            product_mag_id: Number(productRowGroupDetails?.groupData?.carrier_product_data_id),
            carrier_ai_id: Number(selectedProduct?.carrier_ai_id),
            product_ai_id: Number(selectedProduct?.product_ai_id)
        }

        if (!selectedProduct?.product_ai_id) {
            delete payload.product_ai_id;
            payload.remove_product_mapping_flag = true;
        }


        FlagApiLoader(true)
        syncAICarrieWithMagCarrierproduct(payload,
            (response) => {
                if (response?.data?.status) {
                    setFlagCall(new Date().getTime())
                    setFlagProductMappingDialog(false)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "success",
                        message: response?.data?.data?.message ? response?.data?.data?.message : "Product synced successfully!"
                    });
                }
                else {
                    if (response?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: response?.data?.error?.message ? response?.data?.error?.message : "Something Went Wrong!"
                        });
                    }
                    FlagApiLoader(false)
                }
            },
            (error) => {
                console.log("error", error);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
            }
        )
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} >
                    <Grid container >

                        <Grid item xs={12} className='prd-header-item'>
                            <Typography variant='span'>
                                Edit AI Product {productRowGroupDetails?.groupData?.carrier_product_name ? ` - ${productRowGroupDetails?.groupData?.carrier_product_name}` : ""}
                            </Typography>
                        </Grid>

                        <Grid item xs={10} sx={{ margin: "20px auto" }}>
                            <Grid container sx={{ justifyContent: "center" }}>
                                <Grid item xs={12} sx={{ margin: "10px 0px 15px" }}>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ margin: "0px 0px 10px" }}>
                                                <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                                    <Grid item className='prd-product-autocomplate'>
                                                        AI Product Name
                                                    </Grid>
                                                    <Grid item className='prd-id-field-item'>
                                                        AI Product ID
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container className='prd-field-container'>
                                                    <Grid item className='prd-product-autocomplate'>
                                                        <Autocomplete
                                                            fullWidth
                                                            options={productMappingListData}
                                                            getOptionLabel={(option) => `${option.product_name}`}
                                                            value={productMappingListData?.find(item => Number(item?.product_ai_id) == Number(selectedProduct?.product_ai_id)) || null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setSelectedProduct({
                                                                        carrier_ai_id: newValue?.carrier_ai_id,
                                                                        product_ai_id: newValue?.product_ai_id,
                                                                        product_name: newValue?.product_name
                                                                    });
                                                                } else {
                                                                    // Reset to default empty values
                                                                    setSelectedProduct({
                                                                        carrier_mag_id: carrierId,
                                                                        carrier_ai_id: carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id,
                                                                        product_mag_id: productRowGroupDetails?.groupData?.carrier_product_data_id,
                                                                        product_ai_id: "",
                                                                        // product_name: ""
                                                                    });
                                                                }
                                                            }}
                                                            className='prd-autocomplate'
                                                            popupIcon={<KeyboardArrowDownIcon />}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder={selectedProduct?.product_ai_id ? "" : "Enter Product Name"}
                                                                    label=""
                                                                    variant="outlined"
                                                                    className='prd-autocomplate-textfield'
                                                                    size='small'
                                                                    inputRef={inputRef}
                                                                />
                                                            )}
                                                            PopperComponent={(props) => (
                                                                <Popper
                                                                    {...props}
                                                                    style={{
                                                                        zIndex: 2222,
                                                                        width: inputRef.current ? inputRef?.current?.offsetWidth : "auto", // Match input width
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Divider orientation='vertical' sx={{ height: '30px', border: '1px solid #E3E3E3' }} />
                                                    <Grid item className='prd-id-field-item'>
                                                        <Typography
                                                            variant='span'
                                                            sx={{
                                                                fontSize: '13px',
                                                                fontWeight: "500",
                                                                color: selectedProduct?.product_ai_id ? "black" : "#434343"
                                                            }}
                                                        >
                                                            {selectedProduct?.product_ai_id || "NA"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sx={{ margin: "30px 0px 15px" }}>
                                    <Grid container sx={{ justifyContent: "center", gap: "15px" }}>
                                        <Grid item className='prd-button-width'>
                                            <Button
                                                variant='outlined'
                                                size='medium'
                                                fullWidth
                                                className='prd-close-btn'
                                                onClick={() => { setFlagProductMappingDialog(false) }}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                        <Grid item className='prd-button-width'>
                                            <Button
                                                variant='contained'
                                                size='medium'
                                                fullWidth
                                                disabled={selectedProduct?.product_ai_id == null ? true : false}
                                                className='prd-save-btn'
                                                onClick={() => { saveProductAIMapping() }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ProductMappingDialog