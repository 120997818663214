import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import SidebarNavSection from "./SidebarNavSection";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";

import "../../vendor/perfect-scrollbar.css";
import "../../pages/css/Global/PagesNavbar.css"

import { useNavigate } from "react-router-dom";

const baseScrollbar = (props) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav = ({ items }) => {

  // REMOVE THIS STATE IF EVERY THING WORKS FINE AND AFTER ALL TESTING. *COMENTED ON 01-05-24*
  // const [subAgencyPages, setSubAgencyPages] = useState([])

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;
  const { userDetail } = useContext(AuthContext).state;
  const navigate = useNavigate();

  // REMOVE THIS USEEFFECT IF EVERY THING WORKS FINE AND AFTER ALL TESTING. *COMENTED ON 01-05-24*
  // useEffect(() => {

  //   const updateUserAgencyPagesRoute = () => {

  //     if (
  //       userDetail.length &&
  //       userDetail[0]?.user_role_name === "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() !== "brokers alliance"
  //     ) {
  //       const agencyId = userDetail[0]?.user_agency_data?.agency_id;
  //       const updatedSubAgencyPages = items[0]?.subAgencyPages?.map((page) =>
  //         page.title === "Agency"
  //           ? { ...page, href: agencyId ? `/agency/view_agency?agency_id=${agencyId}` : `/` }
  //           : page

  //       );
  //       setSubAgencyPages(updatedSubAgencyPages);
  //     }
  //   };
  //   updateUserAgencyPagesRoute();

  // }, [userDetail])

  // useEffect(() => {
  //   console.log("--------------------------------");
  //   console.log("test", userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == true && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "member");
  //   console.log("test0", userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == true);
  //   console.log("test1", userDetail[0]?.user_agency_data?.parent_agency_id != null && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin");
  //   console.log("test2", userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == false && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin");
  //   console.log("test3", userDetail[0]?.user_role_name == "Member");
  //   console.log("-----END---------------------------");
  // }, [userDetail])

  return (
    <ScrollbarComponent>
      <List disablePadding className="menu-list-component-styles">
        <Items>

          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                // pages={
                //   userDetail?.length ?
                //     (
                //       (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance") ? item.pages :
                //         (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() !== "brokers alliance") ? item.subAgencyPages :
                //           (userDetail[0]?.user_role_name == "Member") ? item.userPages : item.agentPages
                //     ) : []
                // }
                pages={
                  userDetail?.length ?
                    (
                      (userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == true && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "member") ? item.subAgencyPages :
                        (userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "agent") ? item.agentPages :
                          (userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == true) ? item.pages :
                            (userDetail[0]?.user_agency_data?.parent_agency_id != null && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin") ? item.subAgencyPages :
                              (userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == false && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin") ? item.itemWithoutAgency :
                                (userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "member") && item.userPages
                    ) : []
                }
                title={item.title}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
