import styled from "@emotion/styled";
import {
  ExpandMore,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  IconButton,
  FormGroup,
  Select,
  Box,
  Button,
  FormControl,
  FormLabel,
  Chip,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { spacing, Stack, textAlign } from "@mui/system";
import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackIcon from "../../assets/back-icon.png";
import { Type } from "react-feather";
import { darken, margin, padding } from "polished";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Add } from "@mui/icons-material";
import GlobalDialog from "../../components/global/GlobalDialog";
import CarriersPopup from "../../components/global/CarriersPopup";
import "../../pages/css/template.css";
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import GlobalCarrierTable from "../../components/global/GlobalCarrierTable";
import TemplateLogo from "../../assets/templateLogo.png";
import { CreateTemplate, GetCarrierLevelList, GetTemplateData, GetTemplateDataList, GetTemplateVersionList, UpdateTemplate } from "../../utils/api/TemplateApi";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import deleteAlert from '../../assets/Delete-alert.png'

const CustomChip = styled(Chip)`
  width: 70px;
  height: 25px;
  border-radius: 10px;
  styleOverrides: {
    colorPrimary: {
      backgroundColor: 'red',
    },
    colorSecondary: {
      backgroundColor: 'brown',
    },
  },
`;

const Divider = styled(MuiDivider)(spacing);
const BackIconComponent = () => (
  <img
    src={BackIcon}
    alt="Logo"
    width="28px"
    height="28px"
    text-align="center"
    justify-content="center"
  />
);


const AddNewTemplate = () => {
  const { state, FlagApiLoader, UpdateSnackbarMessage, } = useContext(authContext);
  const { carrierTypeDetails } = useContext(authContext).state;
  const { flagCarrierList, userDetail } = useContext(authContext).state;
  const { setFlagType, setCarrierLevelData, setTemplateName, setTemplateId, setCarrierAnnuityLevelData, setCarrierLifeLevelData } = useContext(globalCarrierContext);
  const { carrierLevelData, flagType, templateName, templateId, globalLifeCarrierData, globalAnnuityCarrierData, globalCarrierDetails } = useContext(globalCarrierContext).state;
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [editFlag, setEditFlag] = useState(false)
  const [deletedCarrierId, setDeletedCarrierId] = useState([])
  const [assignmentId, setAssignmentId] = useState([])
  const [flagOpenCarrierPopup, setFlagOpenCarrierPopup] = useState(false);
  const [flagAddPage, setFlagAddPage] = useState(null);
  const [flagContentSectionActive, setFlagContentSectionActive] = useState(false)
  const [selectedTab, setSelectedTab] = useState("");
  // const [flagEditAlert, setFlagEditAlert] = useState(false);
  const [flagInvalidTemplateName, setFlagInvalidTemplateName] = useState(false);
  const [flagEnableEditTemplate, setFlagEnableEditTemplate] = useState(false);
  const [oldTemplateName, setOldTemplateName] = useState("");
  const [templateList, setTemplateList] = useState([])
  const [changeTemplateCall, setChangeTemplateCall] = useState("default")
  const [templateVersionList, setTemplateVersionList] = useState([])
  const [tempVersionCurrentId, setTempVersionCurrentId] = useState(null)
  const PathName = window.location.pathname

  // USE EFFECT---------------
  useEffect(() => {
    const fetchData = async () => {
      FlagApiLoader(true)
      try {
        let templateIdExists = searchParams.has("id");
        let searchParamsTemplateId = searchParams.get("id");
        let templatVersionId = searchParams.get("version_id")

        if (PathName != "/templates/add_template") {
          await GetTemplateVersionList(
            searchParamsTemplateId,
            (res) => {
              if (res?.data?.status) {
                console.log('response true of GetTemplateVersionList api', res);
                setTemplateVersionList(res.data.data)
                const isTemplateVersionIdObject = res.data.data.find(obj => obj.template_version_id === Number(templatVersionId))
                if (templatVersionId && isTemplateVersionIdObject) {
                  setTempVersionCurrentId(templatVersionId)
                }
                else {
                  const CurrentTemplateVersion = res.data.data.find(obj => obj.template_version_latest === true)
                  setTempVersionCurrentId(CurrentTemplateVersion.template_version_id)
                  navigate(`/templates/view_template?id=${searchParamsTemplateId}&version_id=${CurrentTemplateVersion.template_version_id}`)
                }
              }
              else {
                console.log("response false of GetTemplateVersionList api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }
              }
            },
            (err) => {
              console.log("error of GetTemplateVersionList api", err)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
              FlagApiLoader(false)
            })
        }

        if (templateIdExists === true) {
          if (searchParamsTemplateId !== null) {
            // setTemplateName("")
            setTemplateId(searchParamsTemplateId)
            setFlagAddPage(false)
            GetTemplateList()
          }
          setFlagContentSectionActive(true)
        }
        else {
          setFlagType(null)
          setTemplateName("")
          // setCarrierLevelData([])

          // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
          setCarrierLifeLevelData([])
          setCarrierAnnuityLevelData([])
          // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
          setFlagAddPage(true)
          setFlagContentSectionActive(false)
        }
      }
      catch (err) {
        console.log(err, "This Occurs in UseEFfect")
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!"
        });
        FlagApiLoader(false)
      }
      FlagApiLoader(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (changeTemplateCall !== "default") {
      let searchParamsTemplateId = searchParams.get("id");
      if (searchParamsTemplateId !== null) {
        setTemplateId(searchParamsTemplateId)
        setFlagAddPage(false)
        GetTemplateList()
      }
    }
  }, [changeTemplateCall])

  useEffect(() => {
    // if (flagType == lifeCarrierTypeId) {
    //   setSelectedTab("life")
    // }
    // if (flagType == annuityCarrierTypeId) {
    //   setSelectedTab("annuity")
    // }
    setSelectedTab(flagType)
  }, [flagType])
  // USE EFFECT---------------


  // FUNCTIONS:-----------------
  const GetTemplateList = () => {

    let params = {
      agency_id: userDetail[0]?.user_agency_data.agency_id
    }

    GetTemplateDataList(params,
      // {},
      (res) => {
        if (res.data.status) {
          console.log("response true of GetTemplateDataList api", res);
          setTemplateList(res.data.data)
        }
        else {
          console.log("response false of GetTemplateDataList api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateDataList api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
        FlagApiLoader(false);
      }
    );
  }

  const createNewTemplate = async () => {

    // if (flagType) {
    //   var templateDataArr = [...globalLifeCarrierData];
    // }
    // else {
    //   var templateDataArr = [...globalAnnuityCarrierData];
    // }
    var templateDataArr = [...globalCarrierDetails[selectedTab]]
    var templateDataObj = [];
    var cnt = 1;
    for (var i in templateDataArr) {
      templateDataObj[i] = {};
      templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
      templateDataObj[i]["carrier_level_data"] = [];
      cnt = 1;
      if (templateDataArr[i]["baLevel"]?.carrier_level_id) {
        templateDataObj[i]["carrier_level_data"].push({
          carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
          carrier_ba_level_flag: true,
          carrier_direct_level_flag: false,
          template_level_sequence: cnt,
        });
        cnt++;
      }
      if (templateDataArr[i]["directCarrier"]?.carrier_level_id) {
        templateDataObj[i]["carrier_level_data"].push({
          carrier_level_name:
            templateDataArr[i]["directCarrier"]["carrier_level_name"],
          carrier_ba_level_flag: false,
          carrier_direct_level_flag: true,
          template_level_sequence: cnt,
        });
        cnt++;
      }
      for (var j in templateDataArr[i]["carrier_level_data"]) {
        if (templateDataArr[i]["carrier_level_data"][j]) {
          templateDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: false,
            template_level_sequence: cnt++,
          });
        }
      }
    }

    const carrierType = carrierTypeDetails.find(type => type.carrier_type_name.toLowerCase() === flagType.toLowerCase());
    const carrierTypeId = carrierType ? carrierType.carrier_type_id : null;

    var data = {
      template_name: templateName,
      carrier_type_id: carrierTypeId,
      template_data: templateDataObj,
      agency_id: userDetail[0].user_agency_data.agency_id
    };

    FlagApiLoader(true);
    await CreateTemplate(
      data,
      (res) => {
        FlagApiLoader(false);
        if (res.data.status) {
          console.log("response true of CreateTemplate api", res);
          UpdateSnackbarMessage({
            status: "success",
            message: "Template created successfully."
          });
          navigate("/templates")
        }
        else {
          console.log("response false of GetTemplateVersionList api", res);
          if (res.data.error.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res.data.error.message,
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!",
            });
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateVersionList api", err);
        if (err.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: err.message,
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!",
          });
        }
        FlagApiLoader(false);
      }
    );
  };

  const updateTemplate = async () => {
    FlagApiLoader(true);
    // if (flagType) {
    //   var templateDataArr = [...globalLifeCarrierData];
    // } else {
    //   var templateDataArr = [...globalAnnuityCarrierData];
    // }
    var templateDataArr = [...globalCarrierDetails[selectedTab]]
    var templateDataObj = [];
    var cnt = 1;
    for (var i in templateDataArr) {
      templateDataObj[i] = {};
      if (templateDataArr[i]?.template_carrier_assignment_id) {
        templateDataObj[i]["template_carrier_assignment_id"] = templateDataArr[i]["template_carrier_assignment_id"];
        templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
      } else {
        templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
      }
      templateDataObj[i]["carrier_level_data"] = [];
      cnt = 1;
      if (templateDataArr[i]["baLevel"]?.carrier_level_name) {
        if (templateDataArr[i]?.template_carrier_assignment_id) {
          templateDataObj[i]["carrier_level_data"].push({
            template_carrier_assignment_level_id: templateDataArr[i]["baLevel"]["template_carrier_assignment_level_id"],
            carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
            carrier_ba_level_flag: true,
            carrier_direct_level_flag: false,
            template_level_sequence: cnt,
          });
        } else {
          templateDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
            carrier_ba_level_flag: true,
            carrier_direct_level_flag: false,
            template_level_sequence: cnt,
          });
        }
        cnt++;
      }
      if (templateDataArr[i]["directCarrier"]?.carrier_level_name) {
        if (templateDataArr[i]?.template_carrier_assignment_id) {
          templateDataObj[i]["carrier_level_data"].push({
            template_carrier_assignment_level_id: templateDataArr[i]["directCarrier"]["template_carrier_assignment_level_id"],
            carrier_level_name: templateDataArr[i]["directCarrier"]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: true,
            template_level_sequence: cnt,
          });
        } else {
          templateDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateDataArr[i]["directCarrier"]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: true,
            template_level_sequence: cnt,
          });
        }
        cnt++;
      }
      for (var j in templateDataArr[i]["carrier_level_data"]) {
        if (templateDataArr[i]["carrier_level_data"][j]) {
          if (templateDataArr[i]?.template_carrier_assignment_id) {
            templateDataObj[i]["carrier_level_data"].push({
              template_carrier_assignment_level_id: templateDataArr[i]["carrier_level_data"][j]["template_carrier_assignment_level_id"],
              carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
              carrier_ba_level_flag: false,
              carrier_direct_level_flag: false,
              template_level_sequence: cnt++,
            });
          } else {
            templateDataObj[i]["carrier_level_data"].push({
              carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
              carrier_ba_level_flag: false,
              carrier_direct_level_flag: false,
              template_level_sequence: cnt++,
            });
          }
        }
      }
    }
    var data = {
      template_id: templateId,
      template_name: templateName,
      template_data: templateDataObj,
      deleted_template_carrier_assignment_id: deletedCarrierId
    };

    await UpdateTemplate(data, (res) => {
      if (res?.data?.status) {
        UpdateSnackbarMessage({
          status: "success",
          message: res?.data?.data?.message
        });
        setDeletedCarrierId([])
        GetTemplateVersionList(templateId,
          (res) => {
            if (res?.data?.status) {
              setTemplateVersionList(res.data.data)
              const CurrentTemplateVersion = res.data.data.find(obj => obj.template_version_latest === true)
              setTempVersionCurrentId(CurrentTemplateVersion.template_version_id)
              setEditFlag(false)
              navigate(`/templates/view_template?id=${templateId}&version_id=${CurrentTemplateVersion.template_version_id}`)
            }
            else {
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
            }
          },
          (err) => {
            console.log("error of GetTemplateVersionList api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          })
      }
      else {
        console.log("response status false of UpdateTemplate api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
      FlagApiLoader(false);
    }, (err) => {
      console.log("error of UpdateTemplate api", err);
      UpdateSnackbarMessage({
        status: "error",
        message: "Something went wrong!"
      });
      FlagApiLoader(false);
    })
  };

  const IsCurrentVersion = () => {
    const isCurrentVersion = templateVersionList.find((data) => data.template_version_id == tempVersionCurrentId ? true : false)
    return isCurrentVersion.template_version_latest
  }
  // FUNCTIONS:-----------------


  // COMPONENT FUNCTION:--------------
  function Checkboxes() {
    return (
      <Grid container direction="row">
        <Grid item xs={12} marginTop={{ xs: "20px", sm: "none" }}>
          <Grid container justifyContent={{ xs: "center", md: "start" }} alignItems="center" columnGap={3}>

            <Grid item
            // xl={1.5} lg={2} md={3} sm={3} xs={3}
            >
              <Typography
                // variant="outlined"
                sx={{ color: "#7A7A7A" }}
                gutterBottom
                display="inline"
              >
                Type <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>

            <Grid item>
              <FormControl disabled={!flagAddPage}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={flagType}
                  onChange={(e) => {
                    setFlagType(e.target.value);
                  }}
                >
                  {carrierTypeDetails.map((type) => (
                    <FormControlLabel
                      key={type.carrier_type_id}
                      value={type.carrier_type_name.toString()}
                      control={<Radio />}
                      label={type?.carrier_type_name?.charAt(0)?.toUpperCase() + type.carrier_type_name.slice(1)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {/* COMMENTED AFTER DYNAMIC CARRIER TYPE */}
              {/* <FormControl disabled={!flagAddPage ? true : false} >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={flagType}
                  // sx={{ fontFamily: "Nunito !important", fontWeight: "600 !important" }}
                  onChange={(e) => {
                    // setFlagType(null)
                    setFlagType((e.target.value == true || e.target.value == "true"));
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Life"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Annuity"
                  />
                </RadioGroup>
              </FormControl> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const AccordionSummaryContent = () => (
    <Grid container sx={{ maxHeight: "35px", width: "100%" }} columnGap={3}>
      <Grid item >
        <Grid container columnGap={2}>
          <Grid item textAlign="center">
            {templateName?.length ?
              <img src={TemplateLogo} />
              :
              null
            }
          </Grid>
          <Grid item textAlign="flex-start">
            <Typography fontSize="15px">
              <b>{templateName}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {carrierTypeDetails.length > 0 &&
          carrierTypeDetails.some(type => type.carrier_type_name == flagType) &&
          <Button
            variant="outlined"
            sx={{
              minWidth: { xs: "60px", sm: "60px" },
              borderRadius: "10px",
              padding: '2px 10px',
              justifyContent: 'centre',
              backgroundColor: 'transparent',
              color: carrierTypeDetails.find(type => type.carrier_type_name == flagType)?.carrier_type_color || 'gray',
              border: `1px solid ${carrierTypeDetails.find(type => type.carrier_type_name == flagType)?.carrier_type_color || 'gray'}`,
              "&:hover": {
                color: carrierTypeDetails.find(type => type.carrier_type_name == flagType)?.carrier_type_color || 'gray',
                backgroundColor: "transparent",
                border: `1px solid ${carrierTypeDetails.find(type => type.carrier_type_name == flagType)?.carrier_type_color || 'gray'}`,
              },
              textTransform: 'capitalize'
            }}
          >

            {carrierTypeDetails.length > 0 && carrierTypeDetails.find(type => type.carrier_type_name.toLowerCase() == flagType.toLowerCase())?.carrier_type_name}
          </Button>
        }


        {/* OLD CODE BEFORE DYNAMIC TYPE */}
        {/* {flagType == true ? (
          <CustomChip
            label="Life"
            size="small"
            color="primary"
            variant="outlined"
          />
        ) : flagType == false ? (
          <CustomChip
            label="Annuity"
            size="small"
            color="success"
            variant="outlined"
            sx={{ color: "#2F970B" }}
          />
        ) : null} */}
        {/* <Grid container>
          <Grid item xs={1.5}>
            <img src={SecondaryLogo2} />
          </Grid>
          <Grid item xs={1} textAlign="center">
            <img src={Agent} />
          </Grid>
          <Grid item xs={3.5} textAlign="center">
            <Typography>Johnson M</Typography>
          </Grid>
          <Grid item xs={0.5} textAlign="center">
            <hr
              style={{
                border: "none",
                borderLeft: "1px solid #A3A3A3",
                height: "20px",
                width: "0",
                marginTop: "1px",
                marginBottom: "0px",
                padding: "none",
              }}
            />
          </Grid>
  
          <Grid item xs={1} textAlign="center" sx={{ paddingLeft: "none" }}>
            <img src={Mail} />
          </Grid>
          <Grid item xs={4}>
            <Typography>johnson@gmail.com</Typography>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid item xs={1}>
        {/* <Grid container columnSpacing={3}>
          <Grid item xs={6}>
            <img src={LifeInsuranceChip} alt="life-insurance" />
          </Grid>
          <Grid item xs={6}>
            <img src={AnnuityInsuranceChip} alt="annuity-insurance" />
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );

  const AccordionDetailsContent = () => {

    return (
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{ display: "flex", flexDirection: "row", marginTop: "-30px", paddingBottom: "15px" }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="end" flexDirection="row" columnGap={10}>
            <Grid
              item
              className="tamplate-name-item"
            // xl={5.5}
            // lg={5.5}
            // md={6}
            // sm={6}
            // xs={12}
            // sx={{ textAlign: { xs: "center", md: "end" } }}
            >
              <TextField
                align="center"
                variant="standard"
                label="Template Name"
                name="Template Name"
                value={templateName}
                sx={{ width: "250px" }}
                className="req_fields"
                autoFocus
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
                error={flagInvalidTemplateName}
              />
            </Grid>

            <Grid
              item
            // xl={5.5}
            // lg={5.5}
            // md={6}
            // sm={6}
            // xs={12}
            // sx={{ margin: "0px 15px" }}
            >
              <Checkboxes />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const SimpleAccordion = () => {
    return (
      <Accordion
        defaultExpanded={!flagContentSectionActive}
        sx={{
          backgroundColor: "#EFEFF0",
          // marginBottom: "20px",
          borderRadius: "10px",
          marginTop: '5px'
        }}
        disableGutters
        onChange={() => {
          setFlagContentSectionActive(!flagContentSectionActive);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-dropdown"
          onClick={() => {
            setFlagContentSectionActive(!flagContentSectionActive);
          }}
        >
          {flagContentSectionActive && <AccordionSummaryContent />}
        </AccordionSummary>
        <AccordionDetails>
          <AccordionDetailsContent />
        </AccordionDetails>
      </Accordion>
    );
  };

  // const EditCarrierPopupData = () => {
  //   return (
  //     <>
  //       <Grid item xs={12}>
  //         <Grid container justifyContent="center" className="delete-main-container">
  //           <Grid item xs={11.5} textAlign={"end"}>
  //             <CloseIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagEditAlert(false) }} />
  //           </Grid>
  //           <Grid item xs={12} className="delete-alert-item">
  //             <img src={deleteAlert}></img>
  //           </Grid>
  //           <Grid item xs={12} className="delete-mesg-item">
  //             Are you sure you want to edit {templateName}?
  //           </Grid>
  //           <Grid item xs={8} color="#00345E" textAlign="center">
  //             Note: If you modify this template, all the template data allocated to
  //             agents will be changed.
  //           </Grid>
  //           <Grid item xs={12}>
  //             <Grid container className="delete-btn-container">

  //               <Grid item xs={6} >
  //                 <Grid container className="delete-no-btn-container">
  //                   <Grid item xs={3} className="delete-no-btn-item">
  //                     <Button
  //                       fullWidth
  //                       variant="outlined"
  //                       onClick={() => {
  //                         setEditFlag(true)
  //                         setFlagEditAlert(false)
  //                       }}
  //                     >Yes
  //                     </Button>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //               <Grid item xs={6}>
  //                 <Grid container>
  //                   <Grid item xs={3} className="delete-yes-btn-item">
  //                     <Button
  //                       fullWidth
  //                       variant="contained"
  //                       onClick={() => { setFlagEditAlert(false) }}
  //                     >No</Button>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>

  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </>
  //   )
  // }

  // COMPONENT FUNCTION:--------------

  return (
    <>
      {/* {flagEditAlert && (
        <GlobalDialog
          open={flagEditAlert}
          data={EditCarrierPopupData()}
          maxWidth={"sm"}
          sx={{ borderRadius: "20px" }}
        />
      )} */}

      <Helmet title="myAdvisorGrids | Template" />

      {flagAddPage != null &&
        <>
          {/* Navigation Section */}
          <Grid justifyContent="center" container alignItems="center" mb={3} className="templatemainPageStickyHeader">
            <Grid item xs={11.6} sm={11.6} md={11.6} lg={11.6} xl={11.6} sx={{ textAlign: "center" }}>
              <Grid container textAlign="center" sx={{ alignItems: "center" }}>
                <Grid item>
                  <IconButton
                    sx={{ padding: "0px 10px 0px 0px" }}
                    onClick={() => navigate("/templates")}
                  >
                    <BackIconComponent />
                  </IconButton>
                </Grid>

                <Grid item margin="0px 20px 0px 20px" textAlign={"left"}>
                  {flagAddPage ? (
                    <Typography variant="h3" gutterBottom display="inline">
                      New Template
                    </Typography>
                  ) : (flagEnableEditTemplate ? (
                    <Grid container alignItems="end">
                      <Grid item marginRight="10px">
                        <TextField
                          align="center"
                          variant="standard"
                          name="Template Name"
                          value={templateName}
                          sx={{ width: { xs: "170px", sm: "250px" } }}
                          className="req_fields"
                          // autoFocus
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SaveIcon
                                  sx={{ cursor: "pointer", marginRight: "10px" }}
                                  onClick={() => {
                                    // NEW VALIDATION COMMENTED AFTER MIHIR TOLD 17-10:
                                    // if (!(/^[a-zA-Z0-9 ]+$/.test(templateName))) {
                                    //   setFlagInvalidTemplateName(false);
                                    //   UpdateSnackbarMessage({
                                    //     status: "error",
                                    //     message: "Please enter valid template name"
                                    //   });
                                    //   return;
                                    // }
                                    updateTemplate()
                                    setFlagEnableEditTemplate(false)
                                  }}
                                />
                                <CloseOutlinedIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setTemplateName(oldTemplateName);
                                    setFlagEnableEditTemplate(false)
                                  }}
                                />
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => {
                            setTemplateName(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container alignItems="end">
                      <Grid item>
                        <Typography className="template_name_title" gutterBottom display="inline" marginRight="10px">
                          {templateName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {userDetail[0]?.user_role_name == "Admin" &&
                          <EditIcon sx={{ cursor: "pointer" }} onClick={() => {
                            setOldTemplateName(templateName)
                            setFlagEnableEditTemplate(true)
                          }} />
                        }
                      </Grid>
                      <Grid item>
                        <TextField
                          sx={{
                            "& fieldset": {
                              border: "none",
                            },
                            padding: "0px"
                          }}
                          id="outlined-select-currency"
                          select
                          onChange={(e) => {
                            navigate(`/templates/view_template?id=${e.target.value}`)
                            setChangeTemplateCall(new Date().getTime())
                          }}
                          value=""
                          className="selectTemplateDropdown"
                          SelectProps={{
                            MenuProps: {
                              className: 'selectTemplateDropdown-backdrop',
                            }
                          }}
                        >
                          {templateList.map((option) => (
                            <MenuItem key={option?.template_name} value={option?.template_id}>
                              {option.template_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  )
                  )
                  }
                </Grid>

                <Grid item className="template_type_chip">

                  {/* {flagType == true &&
                    <Button
                      variant="outlined"
                      sx={{
                        width: { xs: "70px", sm: "100px" },
                        borderRadius: "10px",
                        backgroundColor: "#1565C0",
                        color: "white",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#1565C0"
                        }
                      }}
                    > Life
                    </Button>
                  }
                  {flagType == false &&
                    <Button
                      variant="outlined"
                      sx={{
                        border: "1px solid #2F970B",
                        borderRadius: "10px",
                        width: "100px",
                        backgroundColor: "#2F970B",
                        color: "white",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#2F970B"
                        }
                      }}
                    >Annuity
                    </Button>
                  } */}

                  {carrierTypeDetails.length > 0 &&
                    carrierTypeDetails.some(type => type.carrier_type_name == flagType) &&
                    <Button
                      variant="outlined"
                      sx={{
                        width: { xs: "70px", sm: "100px" },
                        borderRadius: "10px",
                        // backgroundColor: "#1565C0",
                        backgroundColor: carrierTypeDetails.find(type => type.carrier_type_name == flagType)?.carrier_type_color || 'gray',
                        color: "white",
                        "&:hover": {
                          color: "white",
                          backgroundColor: carrierTypeDetails.find(type => type.carrier_type_name == flagType)?.carrier_type_color || 'gray'
                        },
                        textTransform: 'capitalize'
                      }}
                    >

                      {carrierTypeDetails.length > 0 && carrierTypeDetails.find(type => type.carrier_type_name.toLowerCase() == flagType.toLowerCase())?.carrier_type_name}
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider mt={4} mb={2} />
            </Grid>
          </Grid>


          {/* Content Section */}
          <Grid container className="main_box_shadow cg-template-inner-scrollable-wrapper" sx={{ backgroundColor: "#FFFFFF", padding: "20px", minHeight: "77vh" }} justifyContent="start">
            {flagAddPage &&
              <Grid item xs={12} sx={{ margin: "0px 0px 10px 0px" }}>
                <SimpleAccordion />
              </Grid>
            }
            <>
              <Grid item xs={12}
                onClick={() => {
                  setFlagContentSectionActive(true)
                }}
                // sx={{ marginTop: flagContentSectionActive ? (globalAnnuityCarrierData.length || globalLifeCarrierData.length) ? "0" : "-26px" : "0" }}
                sx={{ marginTop: flagContentSectionActive ? (globalCarrierDetails && globalCarrierDetails[selectedTab]?.length) ? "0" : "0" : "0" }}
              >
                <Grid
                  container
                  sx={{
                    backgroundColor: (flagType == null || templateName == "") ? "white" : "#EFEFF0",
                    // height: "55vh",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <>
                    <Grid item xs={12} textAlign={'end'} marginBottom={"10px"}>
                      <Box>
                        {!flagAddPage && (userDetail[0]?.user_role_name == "Admin" &&
                          <FormControl sx={{ margin: "5px", width: { xs: "230px", sm: "fit-content", md: "fit-content" } }}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              sx={{
                                backgroundColor: "#fff",
                                "& .MuiSelect-select": {
                                  padding: "10px 14px",
                                },
                                "& fieldset": {
                                  border: "none"
                                }
                              }}
                              MenuProps={{ sx: { zIndex: 2002 } }}
                              value={tempVersionCurrentId}
                              onChange={(e) => {
                                setTempVersionCurrentId(e.target.value)
                                let searchParamsTemplateId = searchParams.get("id");
                                setEditFlag(false)
                                navigate(`/templates/view_template?id=${searchParamsTemplateId}&version_id=${e.target.value}`)
                              }}
                            >
                              {templateVersionList?.map((element) => {
                                const currentVersionList = new Date(element?.template_version_timestamp);
                                const day = currentVersionList.getUTCDate();
                                const month = currentVersionList.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
                                const year = currentVersionList.getUTCFullYear();
                                const hours = currentVersionList.getUTCHours();
                                const minutes = currentVersionList.getUTCMinutes();
                                const seconds = currentVersionList.getUTCSeconds();
                                let formattedDate = "";
                                formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${element.template_version_latest ? '(Current version)' : ''}`.trim()

                                return (
                                  <MenuItem key={element?.template_version_id} value={element?.template_version_id}>{formattedDate}</MenuItem>
                                )
                              })
                              }
                            </Select>
                          </FormControl>
                        )}
                        {(!flagAddPage && editFlag == false && userDetail[0]?.user_role_name == "Admin" && IsCurrentVersion()) &&
                          <Button
                            variant="contained"
                            sx={{ padding: "9px 25px", margin: "5px" }}
                            onClick={() => {
                              setEditFlag(true)
                            }}
                          >
                            Edit Carrier
                          </Button>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12} justifyContent="center">
                      <Grid container justifyContent="center" sx={{ backgroundColor: "#FFFFFF", }}>
                        <Grid item xs={12}>
                          <GlobalCarrierTable
                            flagAddPage={flagAddPage}
                            assignmentId={assignmentId}
                            editFlag={editFlag}
                            setAssignmentId={setAssignmentId}
                            setDeletedCarrierId={setDeletedCarrierId}
                            deletedCarrierId={deletedCarrierId}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            changeTemplateCall={changeTemplateCall}
                            pageName="Template"
                            tempVersionCurrentId={tempVersionCurrentId}
                            setTemplateVersionList={setTemplateVersionList}
                            setTempVersionCurrentId={setTempVersionCurrentId}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  sx={{ justifyContent: "center", margin: "20px 0px" }}
                >
                  {(searchParams.has("id") && (flagType != null && templateName !== "" && flagCarrierList)) ?
                    userDetail[0]?.user_role_name == "Admin" &&
                    <>
                      <Grid
                        item
                        xl={5.5}
                        lg={5.5}
                        md={5.5}
                        sm={5.5}
                        xs={4.5}
                        sx={{ textAlign: "end", margin: "0px 10px" }}
                      >
                        <Grid container justifyContent={"end"}>
                          <Grid item xl={3} lg={5} md={6} sm={6} xs={12}>
                            <Button
                              variant="outlined"
                              fullWidth
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#1565C0",
                                borderColor: "#1565C0",
                              }}
                              onClick={() => { navigate("/templates") }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xl={5.5}
                        lg={5.5}
                        md={5.5}
                        sm={5.5}
                        xs={5.8}
                        sx={{ textAlign: "start", margin: "0px 10px" }}
                      >
                        <Grid container>
                          <Grid item xl={3} lg={5} md={6} sm={6} xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                backgroundColor: "#1565C0",
                              }}
                              onClick={() => {
                                // NEW VALIDATION COMMENTED AFTER MIHIR TOLD 17-10:
                                // if (!(/^[a-zA-Z0-9 ]+$/.test(templateName))) {
                                //   setFlagInvalidTemplateName(false);
                                //   UpdateSnackbarMessage({
                                //     status: "error",
                                //     message: "Please enter valid template name"
                                //   });
                                //   return;
                                // }
                                updateTemplate()
                              }}
                              disabled={templateName == "" || editFlag == false ? true : false}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                    :
                    <>
                      {(flagType != null && templateName !== "" && flagCarrierList) &&
                        <>
                          <Grid
                            item
                            xl={5.5}
                            lg={5.5}
                            md={5.5}
                            sm={5.5}
                            xs={4}
                            sx={{ textAlign: "end", margin: "0px 10px" }}
                          >
                            <Grid container justifyContent={"end"}>
                              <Grid item xl={3} lg={5} md={6} sm={6} xs={12}>
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#1565C0",
                                    borderColor: "#1565C0",
                                  }}
                                  onClick={() => { navigate("/templates") }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xl={5.5}
                            lg={5.5}
                            md={5.5}
                            sm={5.5}
                            xs={6}
                            sx={{ textAlign: "start", margin: "0px 10px" }}
                          >
                            <Grid container>
                              <Grid item xl={3} lg={5} md={6} sm={6} xs={12}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    backgroundColor: "#1565C0",
                                  }}
                                  onClick={() => {
                                    // if (!(/^[a-zA-Z ]+$/.test(templateName))) {
                                    //   setFlagInvalidTemplateName(false)
                                    //   UpdateSnackbarMessage({
                                    //     status: "error",
                                    //     message: "Please enter valid template name"
                                    //   });
                                    //   return;
                                    // }

                                    // NEW VALIDATION COMMENTED AFTER MIHIR TOLD 17-10:
                                    // if (!(/^[a-zA-Z0-9 ]+$/.test(templateName))) {
                                    //   setFlagInvalidTemplateName(false);
                                    //   UpdateSnackbarMessage({
                                    //     status: "error",
                                    //     message: "Please enter valid template name"
                                    //   });
                                    //   return;
                                    // }
                                    createNewTemplate()
                                  }}
                                // disabled={templateName == "" || flagInvalidTemplateName == true}
                                >
                                  Create Template
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      }
                    </>
                  }
                </Grid>
              </Grid>
            </>
            {/* ) : null} */}
          </Grid>
        </>}
    </>
  );
};

export default AddNewTemplate;
