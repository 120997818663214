import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axiosInstance.defaults.headers = { "Content-Type": "application/json" };
axiosInstance.defaults.withCredentials = true;


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Error detected in API call:", error);

    if (error?.response && error?.response?.data && error?.response?.data?.error) {
      if (error?.response?.data?.error?.statusCode == -999) {

        console.log("Session expired. Redirecting to login...", error.response);
        if (window.location.pathname != "/auth/sign") {
          window.location.href = "/auth/sign";
        }
        return Promise.reject("Unauthorized. Redirecting to login...");
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

// window.origin.indexOf("localhost") == -1 ? process.env.REACT_API_BASE_URL1 : process.env.REACT_API_BASE_URL;