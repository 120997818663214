import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Search, Add } from "@mui/icons-material";
import {
  Divider as MuiDivider,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Stack,
  Chip,
  Button,
  Input,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { spacing } from "@mui/system";
// import { DataGrid } from "@mui/x-data-grid";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  useGridApiRef,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";

// Navigation Imports
import { useNavigate } from "react-router-dom";

// API Imports
import { GetCarrierDataList } from "../../utils/api/TemplateApi";

// Context Imports
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";
import "../css/globalDesign.css"
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";
import AddNewCarrierDialog from "./ViewCarrierDialogs/AddNewCarrierDialog";
import GlobalDialog from "../../components/global/GlobalDialog";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)({
  border: "none",
  padding: "20px",

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#233044",
    color: "white"
  },

  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },

  "& .MuiDataGrid-iconSeparator"
    : {
    display: "none",
    visibility: "none",
  },

  "& .MuiDataGrid-root, & .MuiDataGrid-columnHeader,& .MuiDataGrid-root & .MuiDataGrid-cell": {
    outline: "none !important"
  },

  ".MuiDataGrid-row": {
    backgroundColor: "rgba(27, 36, 48, 0.07)",
    margin: "1px 0",
  },

  "& .carrierNameCell:hover": {
    cursor: "pointer",
  },

  "& .MuiDataGrid-row:nth-child(odd)": {
    backgroundColor: "#efefef",
    margin: "1px 0"
  },

  "& .MuiDataGrid-row:nth-child(even)": {
    backgroundColor: "rgba(230 230 230)",
    margin: "1px 0"
  }
});

const CustomTextField = styled(TextField)({
  backgroundColor: "#EFEFF0",
  borderRadius: "5px",
  width: "230px",
  "& input": {
    padding: "9px 16px",
  },
  "& fieldset": {
    border: "none",
  },
});

const CustomChip = styled(Chip)`
  width: 65px;
  height: 27px;
  border-radius: 10px;
  font-weight: 600;
  font-family: 'Nunito';
  styleOverrides: {
    colorPrimary: {
      backgroundColor: 'red',
    },
    colorSecondary: {
      backgroundColor: 'brown',
    },
  },
`;

const GoInput = styled(Input)(({ theme }) => ({
  width: '40px',
  margin: '0 8px',
  '& input': {
    textAlign: 'center',
    textDecoration: 'none'
  },
  background: 'white',
  '& .MuiInput-underline:before': { // Ensure underline is removed
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': { // Remove hover effect
    borderBottom: 'none',
  },
  '& input': {
    textAlign: 'center',
  },
}));

const Carriers = () => {

  const {
    state,
    FlagApiLoader,
    UpdateSnackbarMessage
  } = useContext(authContext)

  const {
    userDetail,
    carrierTypeDetailsForAgency
  } = useContext(authContext).state

  const { setCarrierData } = useContext(templateContext)
  const { carrierData } = useContext(templateContext).state

  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(50);
  const [globalCarrierData, setGlobalCarrierData] = useState([])
  const [flagAddNewCarrierDialog, setFlagAddNewCarrierDialog] = useState(false)
  const [typeStates, setTypeStates] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTypes, setSelectedTypes] = useState(() => {
    const storedTypes = localStorage.getItem('selectedTypes');
    if (storedTypes) {
      return JSON.parse(storedTypes);
    }
    const initialState = {};
    carrierTypeDetailsForAgency.forEach(type => {
      initialState[type.carrier_type_id] = true; // Initially, all checkboxes are true
    });
    return initialState;
  });
  const isAgentProfile = state?.userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "agent"


  // USE EFFECT:-------------------------
  useEffect(() => {
    if (carrierTypeDetailsForAgency?.length > 0) {
      const initialTypeStates = {};
      carrierTypeDetailsForAgency.forEach(type => {
        initialTypeStates[type.carrier_type_id] = true; // Initially set all types to true
      });
      setTypeStates(initialTypeStates);
    }
  }, [carrierTypeDetailsForAgency]);

  useEffect(() => {
    const storedTypes = localStorage.getItem('selectedTypes');
    if (storedTypes) {
      setSelectedTypes(JSON.parse(storedTypes));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedTypes', JSON.stringify(typeStates));
  }, [typeStates]);

  useEffect(() => {
    FlagApiLoader(true)
    GetCarrierDataList(undefined,
      (res) => {
        if (res?.data?.status) {
          console.log("GetCarrierDataList TRUE RESPONSE ===>", res);
          setCarrierData(res.data.data)
          setGlobalCarrierData(res.data.data)

          const loadedCarrierTypeDetails = carrierTypeDetailsForAgency;
          if (Object.keys(selectedTypes).length === 0 && loadedCarrierTypeDetails.length > 0) {
            const initialState = {};
            loadedCarrierTypeDetails.forEach(type => {
              initialState[type.carrier_type_id] = true;
            });
            setSelectedTypes(initialState);
          }
        }
        else {
          if (res?.data?.error?.code === 9087) {
            // window.location.href = "/"
            RevokeDelegateAccessForAdmin((res) => {
              if (res?.data?.status) {
                console.log("RevokeDelegateAccessForAdmin TRUE RESPONSE ===>", res);
                window.location.href = "/"
              }
              else {
                console.log("RevokeDelegateAccessForAdmin FALSE RESPONSE ===>", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }

              }
            },
              (err) => {
                console.log("RevokeDelegateAccessForAdmin ERROR RESPONSE ===>", err);
                UpdateSnackbarMessage({
                  status: "error",
                  message: err?.message
                });
              })
          }
          else {
            console.log("GetCarrierDataList FALSE RESPONSE ===>", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
          }
        }
        FlagApiLoader(false)
      },
      (err) => {
        console.log("GetCarrierDataList ERROR RESPONSE ===>", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });

        FlagApiLoader(false)
      }
    )
  }, [])

  useEffect(() => {
    let filteredData = [...globalCarrierData];

    // Filter by selected carrier types
    filteredData = filteredData.filter(carrier =>
      carrier.carrier_types.some(type =>
        typeStates[type.carrier_type_id] === true
      )
    );

    // Apply the search filter
    if (searchTerm.trim() !== "") {
      filteredData = filteredData.filter(carrier =>
        carrier.carrier_name.toLowerCase().includes(searchTerm.trim().toLowerCase())
      );
    }

    setCarrierData(filteredData);
  }, [typeStates, globalCarrierData, searchTerm]);
  // USE EFFECT:-------------------------


  // HANDLE FUNCTION:------------------------
  const NavigateToViewCarrier = (carrierRow, carrierTypeId) => {
    const carrierTypes = carrierRow?.carrier_types
    const carrierId = carrierTypes.find(item => item?.carrier_type_id === carrierTypeId)?.carrier_id || null
    userDetail[0].user_role_name == "Agent" ? navigate(`/carriers/view_carrier?id=${carrierId}`) : navigate(`/carriers/view_carrier?id=${carrierId}&version_id=`)
  }

  const generateCarrierColumns = (carrierTypes) => {
    if (!carrierTypes) return []; // Ensure carrierTypes is defined

    return carrierTypes.map(type => {
      const fieldName = type?.carrier_type_name?.toLowerCase()
      return {
        field: fieldName,
        headerName: `Updated Timestamp for ${type?.carrier_type_name}`,
        flex: 1,
        renderCell: (params) => {
          const carrierTypes = params.row?.carrier_types ?? [];
          const typeInfo = carrierTypes.find(t => t.carrier_type_name === type.carrier_type_name);
          return (
            <div style={{ color: "#000000" }}>
              {typeInfo ? moment(typeInfo.updated_at_timestamp).format('YYYY-MM-DD HH:mm:ss') : ''}
            </div>
          );
        }
      };
    });
  };

  const handleSearch = (name) => {
    setSearchTerm(name); // Update the search term
  };

  const handleChangeType = (event, carrierTypeId) => {
    setTypeStates(prev => ({
      ...prev,
      [carrierTypeId]: event.target.checked
    }));
  };

  // const handleSearch = (searchQuery) => {
  //   let tempFiltered = [];
  //   if (!searchQuery) {
  //     setCarrierData(globalCarrierData);
  //   } else {
  //     tempFiltered = [...globalCarrierData];
  //     tempFiltered = tempFiltered.filter(
  //       (nn) => nn.carrier_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
  //     );
  //     setCarrierData(tempFiltered);
  //   }
  // };
  // HANDLE FUNCTION:------------------------


  const columns = [
    {
      field: "carrier_name",
      headerName: "Name",
      headerClassName: "name_header",


      flex: 1,
      cellClassName: "carrierNameCell",
      align: "left",
      width: "50px",
      renderCell: (params) => {
        return (
          <Typography
            className="carrier-name"
            color="#1B6AC5"
            fontFamily="Segoe UI"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px"
            marginLeft={{ xs: "0", sm: "20px" }}
          >{params.value}</Typography>
        )
      }
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "name_type",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const carrierTypes = params?.row?.carrier_types || []; // Fallback to an empty array if undefined

        return (
          <Grid container sx={{ columnGap: '8px' }}>
            {carrierTypeDetailsForAgency?.map((carrier) => {
              if (!carrier) return null;
              const { carrier_type_name: carrierName, carrier_type_id: carrierTypeId, carrier_type_color: carrierColor } = carrier;

              const showCarrierButton = carrierTypes.find(item => item?.carrier_type_id == carrierTypeId);

              return (
                <>
                  {showCarrierButton && (
                    <Button
                      key={carrierTypeId}
                      variant="outlined"
                      sx={{
                        border: `1px solid ${carrierColor}`,
                        padding: "4px 8px",
                        borderRadius: "10px",
                        minWidth: '65px',
                        textAlign: 'center',
                        color: carrierColor,
                        fontSize: '12px',
                        fontWeight: 500,
                        height: '25px',
                        textTransform: 'capitalize',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'default'
                      }}
                      onClick={() => NavigateToViewCarrier(params?.row, carrierTypeId)}
                    >
                      {carrierName}
                    </Button>
                  )}
                </>
              );
            })}
          </Grid>
        );
      }
    },
    ...(isAgentProfile ? [] : generateCarrierColumns(carrierTypeDetailsForAgency))
  ];

  function DataGridDemo(prop) {
    const { carrierData } = prop

    const handleOnCellClick = (e) => {
      const carrierId = e?.row.carrier_types[0]?.carrier_id
      const carrierNameField = e.field == "carrier_name"
      if (carrierNameField) {
        userDetail[0].user_role_name == "Agent" ? navigate(`/carriers/view_carrier?id=${carrierId}`)
          :
          navigate(`/carriers/view_carrier?id=${carrierId}&version_id=`)
      }

      // if (e.field == "carrier_name") {
      //   let carrierRow = e.row.carrier_types.filter((row) => {
      //     if (row.carrier_type_flag == true) {
      //       return true
      //     } else {
      //       return false
      //     }
      //   })
      //   let carrierId
      //   if (!carrierRow?.length) {
      //     carrierId = e.row.carrier_types[0].carrier_id
      //   } else {
      //     carrierId = carrierRow[0].carrier_id
      //   }
      //  
      // }
    }

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
      const currentPage = useGridSelector(apiRef, gridPageSelector) + 1;
      const [pageInput, setPageInput] = useState(currentPage);

      const handlePageInputChange = (event) => {
        setPageInput(event.target.value);
      };

      const handleGoClick = () => {
        const pageNumber = Number(pageInput) - 1;
        if (pageNumber >= 0 && pageNumber < pageCount) {
          apiRef.current.setPage(pageNumber);
        }
      };

      const handleChange = (event, value) => {
        apiRef.current.setPage(value - 1);
      };

      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: '#EFEFF0',
            padding: '5px 5px',
            borderRadius: '5px',
            marginBottom: '10px'
          }}>
          <Pagination
            color="primary"
            count={pageCount}
            page={currentPage}
            onChange={handleChange}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#233044 !important",
                "&:hover": {
                  backgroundColor: "#233044 !important",
                }
              }
            }}
            renderItem={(item) => (
              <PaginationItem
                component="div"
                {...item}
                disableRipple
              />
            )}
          />
          {pageCount > 1 && (
            <>
              <GoInput
                value={pageInput}
                // InputProps={{ disableUnderline: true }}
                onChange={handlePageInputChange}
                onBlur={handleGoClick}
                inputProps={{
                  type: 'number',
                  'aria-label': 'Page number',
                }}
              />
              <IconButton onClick={handleGoClick} size="small">
                Go
              </IconButton>
            </>
          )}
        </Box>
      );
    }

    return (
      <Box sx={{ height: { xs: "72vh", sm: "78vh", lg: "77vh", xl: "82vh" }, width: "100%" }} className="carrier-table">
        <CustomDataGrid
          rows={carrierData?.length && carrierData}
          columns={columns}
          getRowId={() => Math.floor((Math.random() * 100000000) + 1)}
          // pageSize={100}
          // rowsPerPageOptions={[12]}
          headerHeight={39}
          experimentalFeatures={{ newEditingApi: true }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableSorting
          onCellClick={handleOnCellClick}
          className="carrier_data_grid"
          pageSize={pageSize}  // Set the number of rows per page
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}  // Options for changing the page size
          pagination
          sx={{
            "& .name_header .css-1jbbcbn-MuiDataGrid-columnHeaderTitle, & .name_type .css-1jbbcbn-MuiDataGrid-columnHeaderTitle": {
              marginLeft: { xs: "0", sm: "18px" }
            },

            "& .MuiDataGrid-footerContainer": {
              borderTop: "none"
            },
            padding: { xs: "10px 10px 0 10px", sm: "10px 20px 20px 20px" },
          }}
          components={{
            Pagination: CustomPagination,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Data Found!
              </Stack>
            ),
          }}
        />
      </Box>
    );
  }

  // function Checkboxes() {
  //   const handleChange = (event, boxType) => {
  //     if (boxType == "life") {
  //       if (event.target.checked == false) {
  //         if (type.flagAnnuity == true) {
  //           setType((a) => {
  //             let tempData = { ...a };
  //             tempData.flagLife = event.target.checked;
  //             return tempData;
  //           });
  //         }
  //       } else {
  //         setType((a) => {
  //           console.log(a);
  //           let tempData = { ...a };
  //           tempData.flagLife = event.target.checked;
  //           return tempData;
  //         });
  //       }
  //     } else if (boxType == "annuity") {
  //       if (event.target.checked == false) {
  //         if (type.flagLife == true) {
  //           setType((a) => {
  //             let tempData = { ...a };
  //             tempData.flagAnnuity = event.target.checked;
  //             return tempData;
  //           });
  //         }
  //       } else {
  //         setType((a) => {
  //           let tempData = { ...a };
  //           tempData.flagAnnuity = event.target.checked;
  //           return tempData;
  //         });
  //       }
  //     }
  //   };

  //   const { flagLife, flagAnnuity } = type;

  //   return (
  //     <Grid container direction="row">
  //       <Grid item>
  //         <Typography
  //           variant="outlined"
  //           sx={{ color: "#7A7A7A" }}
  //           gutterBottom
  //           display="inline"
  //         >
  //           Type
  //         </Typography>
  //       </Grid>
  //       <Grid item>
  //         <FormGroup>
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 sx={{ padding: "0px 0px 0px 20px" }}
  //                 checked={flagLife}
  //                 onChange={(event) => {
  //                   handleChange(event, "life");
  //                 }}
  //                 name="flagLife"
  //               />
  //             }
  //             label="Life"
  //           />
  //         </FormGroup>
  //       </Grid>

  //       <Grid item>
  //         <FormGroup>
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 sx={{ padding: "0px 0px 0px 20px" }}
  //                 checked={flagAnnuity}
  //                 onChange={(event) => {
  //                   handleChange(event, "annuity");
  //                 }}
  //                 name="flagAnnuity"
  //               />
  //             }
  //             label="Annuity"
  //           />
  //         </FormGroup>
  //       </Grid>
  //     </Grid>
  //   );
  // }


  return (
    <>
      <Helmet title="myAdvisorGrids | Carriers" />

      {flagAddNewCarrierDialog && (
        <GlobalDialog
          open={flagAddNewCarrierDialog}
          data={<AddNewCarrierDialog setFlagAddNewCarrierDialog={setFlagAddNewCarrierDialog} />}
          maxWidth={"sm"}
        />
      )}

      {/* Navigation Section */}
      <Grid container justifyContent="center" className="mainPageStickyHeader">
        <Grid item xs={11.5} sm={11.2} md={11.5} lg={11.6} xl={11.7} my={3}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} textAlign={{ xs: "center", sm: "left" }}>
              <Typography variant="h3" gutterBottom display="inline">
                Carriers
              </Typography>
            </Grid>

            <Grid item>
              <Grid container>
                <FormGroup row>
                  {carrierTypeDetailsForAgency?.map((type) => (
                    <FormControlLabel
                      key={type.carrier_type_id}
                      control={
                        <Checkbox
                          checked={typeStates[type.carrier_type_id] || false}
                          onChange={(event) => handleChangeType(event, type.carrier_type_id)}
                          name={type.carrier_type_name}
                        />
                      }
                      label={type?.carrier_type_name?.charAt(0)?.toUpperCase() + type.carrier_type_name.slice(1)}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} textAlign="right">
              <Grid container spacing={2} flexDirection={{ sm: "row" }} gap={4} sx={{ marginTop: "0px !important" }} justifyContent={{ xs: "end", sm: "end", md: "end", lg: "end", xl: "end" }} alignItems="center">
                {state.userDetail[0]?.user_role_name == "Admin" && state.userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={() => navigate("")}
                      onClick={() => {
                        setFlagAddNewCarrierDialog(true)
                      }}

                      sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                    >
                      <Add />
                      New Carrier
                    </Button>
                  </div>
                }
                <CustomTextField
                  sx={{ marginTop: { xs: "20px", sm: "0" }, width: { xs: "100%", sm: "200px" }, }}
                  placeholder="Search"
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider mb={4} />
        </Grid>
      </Grid>


      {/* Agent Data Table Section */}
      <Grid container mt={1} height={"82vh"}>
        <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF" }} height={"82vh"}>
          <DataGridDemo carrierData={carrierData} height={"82vh"} />
        </Grid>
      </Grid>
    </>
  );
};

export default Carriers;