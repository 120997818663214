import React from 'react'
import { Button, Grid, IconButton, Typography } from '@mui/material';
import deleteAlert from "../../assets/Delete-alert.png"
import "./GlobalAlertPopUp.css"
import CloseIcon from '@mui/icons-material/Close';

const GlobalAlertPopUp = (props) => {

    return (
        <Grid container className={props.className}>
            <IconButton className='gap-cross-iconButtton' onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
            {
                props.image &&
                <Grid item xs={12} textAlign={"center"}>
                    <img src={props.image}></img>
                </Grid>
            }
            {
                props.primaryText &&
                <Grid item xs={12} className='gap-primay-text-item'  >
                    <Typography className='gap-primay-text' sx={{ color: props.primaryTextColor }} >
                        {props.primaryText}
                    </Typography>
                </Grid>
            }
            {
                props.secondaryText &&
                <Grid item xs={12} className='gap-secondary-text-item' >
                    <Typography className={props.secondaryTextClassName ? props.secondaryTextClassName : 'gap-secondary-text'} sx={{ color: props.secondaryTextColor }}>
                        {props.secondaryText}
                    </Typography>
                </Grid>
            }
            {
                (props.primaryButtonName || props.secondaryButtonName) &&
                <Grid item xs={12} sx={{ marginTop: "30px" }}>
                    <Grid container className='gap-button-container'>
                        {
                            props.primaryButtonName &&
                            <Button
                                variant={props.primaryButtonVarient}
                                className={props.leftButtonClassName ? props.leftButtonClassName : 'gap-button'}
                                color={props?.primaryButtonColor}
                                onClick={props.onClickPrimary}

                            >
                                {props.primaryButtonName}
                            </Button>
                        }

                        {
                            props.secondaryButtonName &&
                            <Button
                                variant={props.secondaryButtonVarient}
                                className='gap-button'
                                color={props.secondaryButtonColor}
                                onClick={props.onClickSecondary}
                            >

                                {props.secondaryButtonName}
                            </Button>
                        }
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default GlobalAlertPopUp;