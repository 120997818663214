import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Button as MuiButton,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import BaLogo from "../../assets/balogo.png";
import OtpInput from "react18-input-otp";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 252px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;
const LogoComponent = () => (
  <img
    src={BaLogo}
    alt="Logo"
    width="216px"
    height="92px"
    text-align="center"
    justify-content="center"
  />
);

function SignIn() {
  const [otpValue, setOtpValue] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  const handleOtpChange = (value) => {
    setOtpValue(value);
  };

  const handleVerify = () => {
    // Verify the OTP here
    setIsVerified(true);
  };

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper>
        <Helmet title="myAdvisorGrids | Verification" />

        <BigAvatar alt="Lucy" src={BaLogo} />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Authenticate your account
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Verification Code (Sent to herry@mail.com)
        </Typography>
        <Grid
          container
          alignItems="center "
          textAlign="center"
          justifyContent="center"
        >
          <OtpInput
            value={otpValue}
            onChange={handleOtpChange}
            numInputs={6}
            inputStyle={{
              fontSize: "24px",
              width: "36px",
              "@media screen and (max-width: 450px)": {
                width: "25px !important",
              },
              height: "36px",
              margin: "4px",
              borderTop: "0px",
              borderLeft: "0px",
              borderRight: "0px",
              outline: "none",
              textAlign: "center",
              justifyContent: "center",
              // borderColor: showWarning ? "red" : "#000a46",
            }}
            containerStyle={{
              margin: "20px auto 0px",
              // padding: "10px"
            }}
          />
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          //   disabled={isSubmitting}
          mt={3}
        >
          Submit
        </Button>
        <Typography component="h2" variant="body1" align="center">
          Haven't recieved code?
          <Button>Resend Code</Button>
        </Typography>

        {/* <SignInComponent /> */}
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
