import React, { useContext } from 'react'
import '../../css/BaTeam.css'

import { AddNewBaMemberApi, UpdateBaMemberApi } from '../../../utils/api/BATeamApi'
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";


function MemberOperationDialog(props) {


    const {
        setFlagMemberPopup,
        popupType,
        CallUserMe,
        memberDetails,
        setMemberDetails,
        userList,
        setUserList,
        globalUserList,
        setGlobalUserList
    } = props

    const { FlagApiLoader, UpdateSnackbarMessage, } = useContext(AuthContext);

    const Validation = (type) => {
        if (type === "Add") {
            if (memberDetails.user_role_flag === "") {
                return { status: false, message: "Please select type" }
            }
            if (memberDetails.username == "" && memberDetails.email_address == "") {
                return { status: false, message: "Please enter username and email address" }
            }
            if (memberDetails.username == "") {
                return { status: false, message: "Please enter username" }
            }
            if (memberDetails.email_address == "") {
                return { status: false, message: "Please enter email address" }
            }
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(memberDetails.email_address))) {
                return { status: false, message: "Please enter valid email address" }
            }
            if (!(/^[a-zA-Z ]+$/.test(memberDetails.username))) {
                return { status: false, message: "Please enter valid username" }
            }
            return { status: true, message: "" }
        }
        else if (type === "Edit") {
            if (memberDetails.username == "") {
                return { status: false, message: "Please enter username" }
            } else if (!(/^[a-zA-Z ]+$/.test(memberDetails.username))) {
                return { status: false, message: "Please enter valid username" }
            } else {
                return { status: true, message: "" }
            }
        }
    }

    const handleSaveUserDetails = () => {
        if (popupType == "Add") {
            let validateMessage = Validation("Add")
            if (validateMessage?.status) {
                AddNewMember()
            } else {
                UpdateSnackbarMessage({
                    status: "error",
                    message: validateMessage.message
                });
            }
        }
        else if (popupType == "Edit") {
            let validateMessage = Validation("Edit")
            if (validateMessage?.status) {
                UpdateMember()
            }
            else {
                UpdateSnackbarMessage({
                    status: "error",
                    message: validateMessage.message
                });
            }
        }
    }

    const UpdateMember = () => {
        let obj = {
            username: memberDetails.username,
            user_id: memberDetails.user_id,
            agent_user_data_flag: true
        }
        UpdateBaMemberApi(obj, (res) => {
            if (res?.data?.status) {
                console.log("response true of UpdateBaMemberApi api", res);
                let oldData = [...userList]
                let oldGlobalData = [...globalUserList]
                oldData?.map((row) => {
                    if (memberDetails.user_id == row?.user_id) {
                        row.username = res?.data?.data?.new_username
                    }
                })
                oldGlobalData?.map((row) => {
                    if (memberDetails.user_id == row?.user_id) {
                        row.username = res?.data?.new_username
                    }
                })
                setUserList(oldData)
                setGlobalUserList(oldGlobalData)
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "success",
                    message: res?.data?.data?.message
                });
            }
            else {
                console.log("response false of UpdateBaMemberApi api", res);
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                });
            }
        }, (err) => {
            console.log("error of UpdateBaMemberApi api", err);
            setFlagMemberPopup(false)
            FlagApiLoader(false)
            UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!"
            });
        })
    }

    const AddNewMember = () => {
        FlagApiLoader(true)
        let obj = {
            username: memberDetails.username,
            email_address: memberDetails.email_address,
            user_role_flag: memberDetails.user_role_flag
        }
        AddNewBaMemberApi(obj, (res) => {
            if (res?.data?.status) {
                console.log("response true of AddNewBaMemberApi api", res);
                CallUserMe()
                let oldData = [...userList]
                let oldGlobalData = [...globalUserList]
                oldData.unshift(res?.data?.data[0])
                oldGlobalData.unshift(res?.data?.data[0])
                setUserList(oldData)
                setGlobalUserList(oldGlobalData)
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "success",
                    message: "User created successfully."
                });
            }
            else {
                console.log("response false of AddNewBaMemberApi api", res);
                FlagApiLoader(false)
                setFlagMemberPopup(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                });
            }
        }, (err) => {
            console.log("error of AddNewBaMemberApi api", err);
            FlagApiLoader(false)
            UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!"
            });
        })
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container className="cp-head-container">
                    <Grid item xs={12} className="cp-head-item">
                        <span className="cp-label">{popupType == "Add" ? "Add New User" : "Edit User"}</span>
                    </Grid>

                    <Grid item xs={12} marginTop="15px">
                        <Grid container alignItems="center" justifyContent="center" spacing={6}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} textAlign="center">
                                <FormControl disabled={popupType == "Edit" ? true : false} >
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={memberDetails.user_role_flag.toString()}
                                        onChange={(e) => { setMemberDetails({ ...memberDetails, user_role_flag: e.target.value == "true" ? true : e.target.value == "false" ? false : "" }) }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Admin" />
                                        <FormControlLabel value="false" control={<Radio />} label="ARM" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6.4} sm={6.4} md={6.5} lg={6.5} xl={6.5}>
                                <TextField
                                    variant="standard"
                                    id="standard-multiline-flexible"
                                    className="req_fields"
                                    fullWidth
                                    label="Name"
                                    onChange={(event) => { setMemberDetails({ ...memberDetails, username: event.target.value }) }}
                                    value={memberDetails.username}
                                    sx={{
                                        "& .MuiInputBase-root": { height: "40px" }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6.4} sm={6.4} md={6.5} lg={6.5} xl={6.5}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    id="standard-multiline-flexible"
                                    className="req_fields"
                                    label="Email"
                                    onChange={(event) => { setMemberDetails({ ...memberDetails, email_address: event.target.value }) }}
                                    value={memberDetails.email_address}
                                    disabled={popupType == "Edit" ? true : false}
                                    sx={{
                                        "& .MuiInputBase-root": { height: "40px" }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ margin: "50px auto 40px auto" }}>
                        <Grid container justifyContent={"center"} spacing={6}>
                            <Grid item xl={3.2} lg={3.2} md={3.5} sm={3.3} xs={3.3}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    sx={{ padding: "10px 0" }}
                                    onClick={() => {
                                        setFlagMemberPopup(false);
                                    }}
                                >
                                    Close
                                </Button>
                            </Grid>

                            <Grid item xl={3.2} lg={3.2} md={3.5} sm={3.3} xs={3.3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ padding: "10px 0" }}
                                    onClick={() => {
                                        handleSaveUserDetails()
                                    }}
                                >
                                    {popupType === "Add" ? "Create" : popupType === "Edit" ? "Save" : ""}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MemberOperationDialog