import Axios from "../axios";

export const GetCarrierData = async (data, onSuccess, onError) => {
    try {
        // const response = await Axios.get(`/carrier/get-carrier-data?carrier_id=${data.carrier_id}&carrier_version_id=${data.carrier_version_id}`,
        const response = await Axios.get(`/carrier/v2/get-carrier-data?carrier_id=${data.carrier_id}&carrier_version_id=${data.carrier_version_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCarrierVersionListData = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/v2/get-carrier-version-list?carrier_id=${data}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCarrierLevelColorList = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/get-carrier-level-color-list`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const CheckCarrierExistence = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/v2/check-carrier-existence?carrier_name=${data?.carrier_name}&carrier_type_id=${data?.carrier_type_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCarrierDetailsField = async (onSuccess, onError) => {
    try {
        const response = await Axios.get("/carrier/get-carrier-detail-fields-data",
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const CreateCarrierAPI = async (data, onSuccess, onError) => {
    try {
        // const response = await Axios.post("/carrier/create-carrier", data,
        const response = await Axios.post("/carrier/v2/create-carrier", data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const UpdateCarrierAPI = async (data, onSuccess, onError) => {
    try {
        // const response = await Axios.put("/carrier/update-carrier", data,
        const response = await Axios.put("/carrier/v2/update-carrier", data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const CarrierLevelDependencies = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/v2/level/${data?.levelId}/dependencies?carrier_id=${data.carrier_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}



// CRIYERIA GENERIC DATA
export const getCriteriaGenericDataAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`/global/criteria`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};


export const getAICarrierProductListAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`carrier/get-ai-product-list?carrier_ai_id=${data.carrier_ai_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};


export const getAICarrierListAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`carrier/get-ai-carrier-list`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};


export const syncAICarrierWithMagCarrier = async (data, onSuccess, onError) => {
    try {
        // const response = await Axios.put("/carrier/update-carrier", data,
        const response = await Axios.put("carrier/sync-ai-carrier", data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const syncAICarrieWithMagCarrierproduct = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/carrier/sync-ai-carrier-product", data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

// CRIYERIA GENERIC DATA




//  CARRIER EFFECTIVE DATE 
export const getCarrierEffectiveDateListAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/get-carrier-effective-date-list?carrier_id=${data.carrier_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};


export const updateCarrierEffectiveDateAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/carrier/update-carrier-effective-date", data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

export const addAICarrierProductAPI = async (data, onSuccess, onError) => {
    try {

        const response = await Axios.post("/carrier/add-ai-carrier-product", data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

//  CARRIER EFFECTIVE DATE 
