import CreateDataContext from "./createDataContext";

const CarrierReducer = (state, action) => {
    switch (action.type) {
        case "globalLifeCarrierData": {
            return {
                ...state,
                globalLifeCarrierData: action.payload,
            };
        }
        case "globalAnnuityCarrierData": {
            return {
                ...state,
                globalAnnuityCarrierData: action.payload,
            };
        }

        case "carrierLevelData": {
            return {
                ...state,
                carrierLevelData: action.payload,
            };
        }

        case "wholeCarrierData": {
            return {
                ...state,
                wholeCarrierData: action.payload,
            };
        }

        case "flagType": {
            return {
                ...state,
                flagType: action.payload,
            };
        }

        case "templateName": {
            return {
                ...state,
                templateName: action.payload,
            };
        }

        case "templateId": {
            return {
                ...state,
                templateId: action.payload,
            };
        }

        case "setCarrierName": {
            return {
                ...state,
                carrierName: action.payload
            }
        }

        case "setCarrierAnnuityLevelData": {
            return {
                ...state,
                carrierAnnuityLevelData: action.payload
            }
        }

        case "setCarrierLifeLevelData": {
            return {
                ...state,
                carrierLifeLevelData: action.payload
            }
        }

        case "setGlobalCarrierDetails": {
            return {
                ...state,
                globalCarrierDetails: action.payload
            }
        }

        case "setGlobalCarrierLevelDetails": {
            return {
                ...state,
                globalCarrierLevelDetails: action.payload
            }
        }

        case "setTypeSelection": {
            return {
                ...state,
                typeSelection: action.payload
            }
        }

        // case "setCarrierDetailsDuplicate": {
        //     return {
        //         ...state,
        //         carrierDetailsDuplicate: action.payload
        //     }
        // }


        // ----------------- Criteria Generic Data -----------------
        case "setCriteriaGenericData": { return { ...state, criteriaGenericData: action.payload } }
        case "setGenericStateList": { return { ...state, genericStatList: action.payload } }
        // ----------------- Criteria Generic Data -----------------

        // ----------------- Product Generic Data -----------------
        case "setProductMappingListData": { return { ...state, productMappingListData: action.payload } }
        case "setCarrierMappingListData": { return { ...state, carrierMappingListData: action.payload } }
        // ----------------- Product Generic Data -----------------

        default: { return state; }
    }
};

const setCarrierLevelData = (dispatch) => async (data) => {
    dispatch({
        type: "carrierLevelData",
        payload: data,
    });
};

const setFlagType = (dispatch) => async (data) => {
    dispatch({
        type: "flagType",
        payload: data,
    });
};

const setTemplateId = (dispatch) => async (data) => {
    dispatch({
        type: "templateId",
        payload: data,
    });
};

const setTemplateName = (dispatch) => async (data) => {
    dispatch({
        type: "templateName",
        payload: data,
    });
};

const setGlobalLifeCarrierData = (dispatch) => async (data) => {
    dispatch({
        type: "globalLifeCarrierData",
        payload: data,
    });
};
const setGlobalAnnuityCarrierData = (dispatch) => async (data) => {
    dispatch({
        type: "globalAnnuityCarrierData",
        payload: data,
    });
};

const setWholeCarrierData = (dispatch) => async (data) => {
    dispatch({
        type: "wholeCarrierData",
        payload: data,
    });
};

const setCarrierName = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierName",
        payload: data,
    });
};

const setCarrierAnnuityLevelData = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierAnnuityLevelData",
        payload: data,
    });
};

const setCarrierLifeLevelData = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierLifeLevelData",
        payload: data,
    });
};

const setGlobalCarrierDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setGlobalCarrierDetails",
        payload: data,
    });
};

const setGlobalCarrierLevelDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setGlobalCarrierLevelDetails",
        payload: data,
    });
};
const setTypeSelection = (dispatch) => async (data) => {
    dispatch({
        type: "setTypeSelection",
        payload: data,
    });
}

// const setCarrierDetailsDuplicate = (dispatch) => async (data) => {
//     console.log("entered here")
//     dispatch({
//         type: "setCarrierDetailsDuplicate",
//         payload: data,
//     });
// };



// ----------------- Criteria Generic Data -----------------
const setCriteriaGenericData = (dispatch) => async (data) => {
    dispatch({
        type: "setCriteriaGenericData",
        payload: data,
    });
};

const setGenericStateList = (dispatch) => async (data) => {
    dispatch({
        type: "setGenericStateList",
        payload: data,
    });
};

const setProductMappingListData = (dispatch) => async (data) => {
    dispatch({
        type: "setProductMappingListData",
        payload: data,
    });
};

const setCarrierMappingListData = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierMappingListData",
        payload: data,
    });
};
// ----------------- Criteria Generic Data -----------------


export const { Provider, Context, state } = CreateDataContext(
    CarrierReducer,
    {
        // setCarrierDetailsDuplicate
        setFlagType,
        setGlobalLifeCarrierData,
        setGlobalAnnuityCarrierData,
        setCarrierLevelData,
        setWholeCarrierData,
        setTemplateName,
        setTemplateId,
        setCarrierName,
        setCarrierAnnuityLevelData,
        setCarrierLifeLevelData,
        setGlobalCarrierDetails,
        setGlobalCarrierLevelDetails,
        setTypeSelection,
        setCriteriaGenericData,
        setGenericStateList,
        setProductMappingListData,
        setCarrierMappingListData

    },
    {
        // carrierDetailsDuplicate: [],
        flagType: null,
        templateId: null,
        templateName: "",
        carrierLevelData: [],
        carrierLifeLevelData: [],
        carrierAnnuityLevelData: [],
        wholeCarrierData: [{}],
        globalLifeCarrierData: [],
        globalAnnuityCarrierData: [],
        carrierName: "",
        globalCarrierDetails: null,
        globalCarrierLevelDetails: null,
        typeSelection: null,
        criteriaGenericData: [],
        genericStateList: [],
        productMappingListData: [],
        carrierMappingListData: [],


    }
);
