import Axios from "../axios";

export const GetAgentDataList = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agent/get-agent-list`)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetAgentData = async (agentId, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agent/get-agent-data?agent_id=${agentId}`)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const CreateNewAgentApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/agent/create-new-agent", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const AssignTemplateToAgentApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/agent/assign-template-to-agent", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const DetachTemplateToAgentApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete(`/agent/remove-template-assigned-to-agent?agent_id=${data.agent_id}&carrier_type_id=${data.carrier_type_id}`)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const UpdateAgentDataApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/agent/update-agent-carrier-data", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const AddSecondaryAgentDataApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/agent/add-agent-user-data", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const ResendNdaAgentApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/agent/resend-nda", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const SendNewNdaAgentApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/agent/send-new-nda", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const UpdateUserDataApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/user/update-user-data", data)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};
export const DeleteAgentDataApi = async (agent_id, onSuccess, onError) => {
    try {
        const response = await Axios.delete("/agent/remove-agent?agent_id=" + agent_id)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};