import React, { useEffect, useState, useContext } from "react";
import '../css/BaTeam.css'

import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import styled from "@emotion/styled";
import { Add, Close } from "@mui/icons-material";
import {
    Divider as MuiDivider, Typography, Box, Grid, InputBase, TextField,
    FormControlLabel, Button, IconButton, Stack, FormControl, Paper, RadioGroup,
    Radio, Switch,
} from "@mui/material";
import { spacing } from "@mui/system";

import deleteAlert from '../../assets/Delete-alert.png'
import DarkDeleteImg from "../../assets/Darkdelete.png"
import { UserMeApi } from "../../utils/api/userDetailApi";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { GetBAMemberList, AddNewBaMemberApi, UpdateBaMemberApi, DeleteBaMemberApi, assignAgencyManagerToUser } from "../../utils/api/BATeamApi";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    DataGrid,
    GridActionsCellItem,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import GlobalDialog from "../../components/global/GlobalDialog";
import DeleteUserDialog from "./UserComponents/DeleteUserDialog";
import AssignAgencyDialog from "./UserComponents/AssignAgencyDialog";
import MemberOperationDialog from "./CarrierComponents/MemberOperationDialog";

const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
    border-radius: "10px 10px 0 0 !important";
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
  
  .MuiDataGrid-iconSeparator{
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row {
    background-color: rgba(27, 36, 48, 0.07);
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  },

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35, // Adjusted for smaller width
    height: 19,
    padding: 0,
    margin: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#1e3050',
            '& + .MuiSwitch-track': {
                backgroundColor: "#1e3050",
                border: 'unset'
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: "#1e3050",
                color: '#fff'
            },
        },

        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#fff",
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            cursor: 'not-allowed',
            color: "#1e3050",
        },

    },
    '& .MuiFormControlLabel-root': {
        margin: '0px'
    },
    '& .MuiButtonBase-root': {
        color: '#ffffff',
        backgroundColor: '#1e3050',
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 15,
        color: "#1e3050"
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#fff',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        border: '3px solid #a5acb9'
    },
}));

const BATeam = () => {

    const {
        FlagApiLoader, UpdateSnackbarMessage, UpdateUserDetail, UpdateDelegateAccessData,
        UpdateFlagAuthenticated, UpdateDelegateAccessFlag, state,
    } = useContext(AuthContext);

    const { userDetail } = useContext(AuthContext).state;

    const [flagDeletePopup, setFlagDeletePopup] = useState(false);
    const [flagMemberPopup, setFlagMemberPopup] = useState(false)
    const [popupType, setPopupType] = useState("")
    const [deleteUserId, setDeleteUserId] = useState()
    const [memberDetails, setMemberDetails] = useState({ username: "", email_address: "", user_id: "", user_role_flag: "" })
    const [noDataMsgFlag, setNoDataMsgFlag] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [flagOpenAssignAgencyDialog, setFlagOpenAssignAgencyDialog] = useState(false)
    const [userList, setUserList] = useState([]);
    const [globalUserList, setGlobalUserList] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const columns = [
        {
            field: "username",
            headerName: "Name",
            // sortable: false,
            // width: 350,
            flex: 0.9,
            headerClassName: "username_header",
            renderCell: (params) => {
                return (
                    <Typography
                        className="template-name"
                        color="#1B6AC5"
                        fontFamily="Segoe UI"
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="20px"
                        marginLeft={{ xs: "0", sm: "10px" }}
                    >{params.value}</Typography>
                )
            }
        },
        {
            field: "user_email_address",
            headerName: "Email",
            headerClassName: "email_header",
            // sortable: false,
            // width: 500,
            flex: 1.4,
            renderCell: (params) => {
                return (
                    <Typography marginLeft={{ xs: "0", sm: "10px" }}>{params.value}</Typography>
                )
            }
        },
        {
            field: "user_created_timestamp",
            headerName: "Created at",
            headerClassName: "created_at_header",
            // sortable: false,
            // width: 480,
            flex: 0.9,
            renderCell: (params) => {
                const currentVersionList = new Date(params.value);
                const day = currentVersionList.getUTCDate();
                const month = currentVersionList.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
                const year = currentVersionList.getUTCFullYear();
                const hours = currentVersionList.getUTCHours();
                const minutes = currentVersionList.getUTCMinutes();
                const seconds = currentVersionList.getUTCSeconds();
                let formattedDate = "";
                formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

                return (
                    <Typography >{formattedDate}</Typography>
                )
            }
        },
        {
            field: "user_role_name",
            headerName: "Role",
            headerClassName: "role_header",
            // sortable: false,
            // width: 220,
            flex: 0.4,
            renderCell: (params) => {
                return (
                    <Typography >{params.value == "Member" ? "ARM" : params.value == "Admin" ? "Admin" : ""}</Typography>
                )
            }
        },
        {
            field: "agency_admin_flag",
            headerName: "Agency Manager Access",
            headerClassName: "agency-manager-column",
            hide: userDetail[0]?.agency_admin_flag ? false : true,
            flex: 0.8,
            align: 'center',
            maxWidth: 200,
            renderCell: (params) => {
                const flagAdmin = params?.row?.agency_admin_flag;
                const userId = params?.row?.user_id;
                return (
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={flagAdmin}
                                disabled={flagAdmin}
                                onChange={(e) => {
                                    setSelectedUserId(userId);
                                    setFlagOpenAssignAgencyDialog(true);
                                }}
                            />}
                        label=""
                    />
                )
            }
        },
        // {
        //     field: "user_privacy_policy_accepted_flag",
        //     headerName: "Policy Status",
        //     headerClassName: "role_header",
        //     // sortable: false,
        //     // width: 220,
        //     flex: 0.5,
        //     renderCell: (params) => {
        //         return (
        //             <Typography
        //                 variant="span"
        //                 sx={{
        //                     color: params.row.user_privacy_policy_accepted_flag ? '#22965E' : '#962222'
        //                 }}
        //             >
        //                 {params.row.user_privacy_policy_accepted_flag ? 'Accepted' : 'Not Accepted'}
        //             </Typography>
        //         )
        //     }
        // },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            sortable: false,
            align: "center",
            headerClassName: "action_header",
            flex: 0.2,
            getActions: (params) => {
                const agencyAdminFlag = params?.row?.agency_admin_flag;
                const userId = params?.row?.user_id;
                const actions = [
                    <GridActionsCellItem
                        key="edit"
                        MenuProps={{
                            PaperProps: {
                                className: 'ac-table-select-paper'
                            }
                        }}
                        label={<span style={{ color: 'black' }}>Edit</span>}
                        sx={{ '& .MuiListItemIcon-root': { maxWidth: '25px', minWidth: '25px', mr: '8px' } }}
                        icon={<EditIcon sx={{ maxWidth: '25px', minWidth: '25px', color: 'black', fontSize: '18px' }} />}
                        showInMenu
                        onClick={() => {
                            setPopupType("Edit");
                            setMemberDetails({
                                username: params.row.username,
                                email_address: params.row.user_email_address,
                                user_id: params.row.user_id,
                                user_role_flag: params.row.user_role_name === "Admin" ? "true" : "false"
                            });
                            setFlagMemberPopup(true);
                        }}
                    />
                ];

                // if (userDetail[0]?.agency_admin_flag) {
                //     actions.push(
                //         <GridActionsCellItem
                //             key="assign-agency"
                //             sx={{ '& .MuiListItemIcon-root': { maxWidth: '25px', minWidth: '25px', mr: '8px' } }}
                //             icon={agencyAdminFlag ? '' : <AssignmentIcon sx={{ maxWidth: '25px', minWidth: '25px', color: 'black', fontSize: '18px' }} />}
                //             disabled={agencyAdminFlag}
                //             showInMenu
                //             label={
                //                 <span style={{ color: 'black', display: 'flex' }}>
                //                     {agencyAdminFlag && (
                //                         <CheckCircleIcon
                //                             sx={{ color: 'green', fontSize: '18px', marginRight: '4px', verticalAlign: 'sub', mr: '8px' }}
                //                         />
                //                     )}
                //                     <span>{agencyAdminFlag ? "Agency Manager" : "Assign as Agency Manager"}</span>
                //                 </span>
                //             }
                //             onClick={() => {
                //                 setSelectedUserId(userId);
                //                 setFlagOpenAssignAgencyDialog(true);
                //             }}
                //         />
                //     );
                // }

                if (params.row.user_role_name !== "Admin") {
                    actions.push(
                        <GridActionsCellItem
                            key="delete"
                            showInMenu
                            sx={{ '& .MuiListItemIcon-root': { maxWidth: '25px', width: '100%', minWidth: '25px', mr: '8px', justifyContent: 'center' } }}
                            icon={<img src={DarkDeleteImg} style={{ width: 'auto', height: 'auto', }} />}
                            label="Delete"
                            onClick={() => {
                                setDeleteUserId(params.row.user_id);
                                setFlagDeletePopup(true);
                            }}
                        />
                    );
                }

                return actions;
            },
        }
    ];


    // EFFECTS
    useEffect(() => {
        if (userList?.length) {
            setNoDataMsgFlag(true)
        } else {
            setNoDataMsgFlag(false)
        }
    }, [userList])

    useEffect(() => {
        FlagApiLoader(true)
        GetBAMemberList(
            (res) => {
                if (res.data.status) {
                    console.log("response true of GetBAMemberList api", res);
                    // let sortedUserList = res?.data?.data?.sort((a, b) =>
                    //     a.user_created_timestamp < b.user_created_timestamp ? 1 :
                    //         b.user_created_timestamp < a.user_created_timestamp ? -1 : 0
                    // )
                    const userList = res?.data?.data
                    // const dummyData = generateDummyData(500);
                    // const combinedData = [...userList, ...dummyData];
                    setUserList(userList)
                    setGlobalUserList(userList)
                    FlagApiLoader(false)
                }
                else {
                    console.log("response false of GetBAMemberList api", res);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                    });
                    FlagApiLoader(false)
                }
            },
            (err) => {
                FlagApiLoader(false)
                console.log("error of GetBAMemberList api", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!",
                });
            }
        )
    }, [])

    // API FUNCTION
    const CallUserMe = () => {
        UserMeApi({}, (res) => {
            UpdateFlagAuthenticated(res?.data?.status)
            if (res?.data?.status) {
                UpdateUserDetail(res.data.data)
                UpdateDelegateAccessFlag(res?.data?.delegate_access_flag)
                if (res?.data?.delegate_access_flag) {
                    UpdateDelegateAccessData(res?.data?.delegate_user_data)
                }
            }
        }, (err) => {
            console.log((err))
        })
    }

    // HANDLE FUNCTION
    const handleSearch = (name) => {
        let tempGlobalData = [...globalUserList]
        if (!name) {
            setUserList(tempGlobalData)
        } else {
            tempGlobalData = tempGlobalData?.filter((obj) => obj.username.toLowerCase().indexOf(name.toLowerCase()) > -1)
            setUserList(tempGlobalData)
        }
    }

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
        const currentPage = useGridSelector(apiRef, gridPageSelector) + 1;
        const [pageInput, setPageInput] = useState(currentPage);

        useEffect(() => {
            // Update pageInput whenever currentPage changes
            setPageInput(currentPage);
        }, [currentPage]);

        const handlePageInputChange = (event) => {
            setPageInput(event.target.value);
        };

        const handleGoClick = () => {
            const pageNumber = Number(pageInput) - 1;
            if (pageNumber >= 0 && pageNumber < pageCount) {
                apiRef.current.setPage(pageNumber);
            }
        };

        const handleChange = (event, value) => {
            apiRef.current.setPage(value - 1);
        };

        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    background: '#EFEFF0',
                    padding: '5px 5px',
                    borderRadius: '5px',
                    marginBottom: '10px'
                }}
            >
                <Pagination
                    color="primary"
                    count={pageCount}
                    page={currentPage}
                    onChange={handleChange}
                    sx={{
                        "& .Mui-selected": {
                            backgroundColor: "#233044 !important",
                            "&:hover": {
                                backgroundColor: "#233044 !important",
                            }
                        }
                    }}
                    renderItem={(item) => (
                        <PaginationItem
                            component="div"
                            {...item}
                            disableRipple
                        />
                    )}
                />
                {pageCount > 1 && (
                    <>
                        <TextField
                            value={pageInput}
                            onChange={handlePageInputChange}
                            onBlur={handleGoClick}
                            autoComplete="off"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleGoClick();
                                }
                            }}
                            inputProps={{
                                type: 'number',
                                'aria-label': 'Page number',
                                min: 1,
                                max: pageCount,
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                width: '50px',
                                textAlign: 'center',
                                "& .MuiInputBase-input": {
                                    textAlign: 'center',
                                    background: '#ffffff',
                                    padding: "5px 7px",
                                    borderRadius: "3px"
                                }
                            }}
                        />
                        <IconButton onClick={handleGoClick} size="small" sx={{ ml: '5px' }}>
                            Go
                        </IconButton>
                    </>
                )}
            </Box>
        );
    }

    const getCellClassName = (params) => {
        if (params.field == "username") {
            return "username_cell_class"
        }
        if (params.field == "user_email_address") {
            return "email_cell_class"
        }
        if (params.field == "user_created_timestamp") {
            return "created_cell_class"
        }
        if (params.field == "user_role_name") {
            return "role_cell_class"
        }
        if (params.field == "actions") {
            return "action_cell_class"
        }
    }

    return (
        <>
            {flagDeletePopup && (
                <GlobalDialog
                    open={flagDeletePopup}
                    data={
                        <DeleteUserDialog
                            CallUserMe={CallUserMe}
                            setFlagDeletePopup={setFlagDeletePopup}
                            setUserList={setUserList}
                            setGlobalUserList={setGlobalUserList}
                            deleteUserId={deleteUserId}
                            userList={userList}
                            globalUserList={globalUserList}
                        />
                    }
                    maxWidth={"sm"}
                    disableAutoFocus={true}
                />
            )}

            {flagMemberPopup && (
                <GlobalDialog
                    open={flagMemberPopup}
                    data={
                        <MemberOperationDialog
                            flagMemberPopup={flagMemberPopup}
                            setFlagMemberPopup={setFlagMemberPopup}

                            popupType={popupType}
                            CallUserMe={CallUserMe}

                            memberDetails={memberDetails}
                            setMemberDetails={setMemberDetails}

                            userList={userList}
                            setUserList={setUserList}

                            globalUserList={globalUserList}
                            setGlobalUserList={setGlobalUserList}
                        />
                    }
                    maxWidth={"sm"}
                />
            )}

            {flagOpenAssignAgencyDialog && (
                <GlobalDialog
                    open={flagOpenAssignAgencyDialog}
                    data={
                        <AssignAgencyDialog
                            setUserList={setUserList}
                            setGlobalUserList={setGlobalUserList}
                            setFlagOpenAssignAgencyDialog={setFlagOpenAssignAgencyDialog}
                            selectedUserId={selectedUserId}
                        />}
                    maxWidth={"sm"}
                />
            )}

            <Helmet title="myAdvisorGrids | Users" />

            {/* Navigation Section */}
            <Grid container justifyContent="center" alignItems="center" className="mainPageStickyHeader">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} my={3}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center"
                        mb={1}
                    >
                        {/* BA Team */}
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={3}>
                            <Grid container justifyContent={{ xs: "center", sm: "start" }}>
                                <Typography variant="h3" gutterBottom display="inline">
                                    Users
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* New Member & Search Button  */}
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Grid container spacing={2} flexDirection={{ xs: "column", sm: "row" }} justifyContent={{ xs: "center", sm: "space-between", md: "end", lg: "end", xl: "end" }} alignItems="center">
                                <Grid item width={{ xs: "100%", sm: "initial" }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { setPopupType("Add"); setMemberDetails({ username: "", email_address: "", user_id: "", user_role_flag: "" }); setFlagMemberPopup(true) }}
                                            sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                                        >
                                            <Add />
                                            New User
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item width={{ xs: "100%", sm: "initial", md: "50%", lg: "initial" }}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <Input
                                            placeholder={"Search"}
                                            onChange={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                        />
                                    </Search>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={4} />
                </Grid>
            </Grid>

            {/* Users Table Section */}
            <Grid container mt={1}>
                <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF" }}>
                    <Box sx={{ height: { xs: "60vh", sm: "70vh", md: "79vh", lg: "80vh", xl: "80vh" }, width: "100%" }} className="template-table">
                        <CustomDataGrid
                            rows={userList?.length && userList}
                            columns={columns}
                            // pageSize={userList.length}
                            getRowId={(row) => row?.user_id}
                            experimentalFeatures={{ newEditingApi: true }}
                            headerHeight={39}
                            className="user_datagrid_class"
                            sx={{
                                padding: { xs: "10px 10px 0 10px", sm: "10px 20px 20px 20px" },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none"
                                },
                                flexDirection: 'column-reverse',
                                "& .MuiDataGrid-sortIcon": {
                                    color: "white !important"
                                },
                            }}
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            disableSorting
                            getCellClassName={getCellClassName}
                            pageSize={pageSize}  // Set the number of rows per page
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 15, 20]}  // Options for changing the page size
                            pagination
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        {noDataMsgFlag == false && state.apiLoader == false ? "No Data Found!" : ""}
                                    </Stack>
                                ),
                            }}
                            getRowClassName={(params) => {
                                const policyFlag = params.row.user_privacy_policy_accepted_flag;
                                return policyFlag ? "border-green-color" : "border-red-color";
                            }}
                        // BFR PAGINATION
                        // pageSize={15}
                        // rowsPerPageOptions={[10]}
                        // hideFooterPagination
                        // hideFooter
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default BATeam;
