import React, { useContext, useEffect, useState } from 'react'
import '../../css/Agency/AgencyCloneTemplate.css'
import { Button, Dialog, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Select, FormControl, MenuItem, InputAdornment, styled, IconButton, Divider } from '@mui/material'
import AgencyCloneTemplateDialog from './AgencyDialogs/AgencyCloneTemplateDialog'
import { GetAgencyTemplateDataList, GetTemplateDataAPI } from '../../../utils/api/AgencyDetailsAPI'
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as AuthContext } from '../../../contexts/reducerContexts/authContext'
import AgencyTemplateData from './AgencyTemplateData'
import { Search } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CustomTextField = styled(TextField)({
    backgroundColor: "#EFEFF0",
    borderRadius: "5px",
    width: "230px",
    "& input": {
        padding: "9px 16px",
    },
    "& fieldset": {
        border: "none",
    },
});


const AgencyCloneTemplate = () => {

    const {
        setAgencyTemplateDetails,
        setAgencyTemplateData,
        setToggleDetails
    } = useContext(agencyContext)

    const {
        createAgencyId,
        agencyTemplateDetails,
        agencyDuplicateTemplateDetails,
        agencyTemplateData,
        toggleDetails,
    } = useContext(agencyContext).state

    const {
        agencyDetails,
        userDetail,
        carrierTypeDetailsForAgency
    } = useContext(AuthContext).state

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(AuthContext)

    const [fatchCloneTemplateDetails, setFatchCloneTemplateDetails] = useState(null)
    const [flagOpenCloneDialog, setFlagOpenCloneDialog] = useState(false)
    const [flagViewAgencyTemplate, setFlagViewAgencyTemplate] = useState(false)
    const [maxDlLength, setMaxDlLength] = useState(10)
    const [selctedTemplate, setSelctedTemplate] = useState(null);
    const isBaTemplate = toggleDetails == 'ba_template'

    const handleOpenCloneDialogDetails = (data) => {
        setFatchCloneTemplateDetails(data)
        setFlagOpenCloneDialog(true)
    }

    const handleOnClickOfTableView = (template_id) => {
        const payLoad = {
            template_id: template_id,
            agency_id: userDetail[0]?.user_agency_data?.agency_id
        }
        FlagApiLoader(true)
        GetTemplateDataAPI(
            payLoad,
            (res) => {
                if (res?.data?.status) {
                    const data = res?.data?.data
                    let maxDlLevelLength = 10
                    const templateData = data[0]?.template_data.map((temp_data) => {
                        const rowData = {
                            carrier_name: temp_data?.carrier_name,
                            ba_level_name: temp_data?.carrier_level_data?.filter(temp_data => temp_data?.carrier_ba_level_flag)[0]?.carrier_level_name,
                            direct_level_name: temp_data?.carrier_level_data?.filter(temp_data => temp_data?.carrier_direct_level_flag)[0]?.carrier_level_name,
                            dl_level: temp_data?.carrier_level_data?.filter(temp_data => !temp_data?.carrier_direct_level_flag && !temp_data?.carrier_ba_level_flag)
                        }

                        if (rowData?.dl_level?.length > maxDlLevelLength) {
                            maxDlLevelLength = rowData?.dl_level?.length
                        }
                        return rowData
                    })
                    console.log("templateData", templateData)
                    setAgencyTemplateData(templateData)
                    setSelctedTemplate(template_id)
                    setFlagViewAgencyTemplate(true)
                    setMaxDlLength(maxDlLevelLength)
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        console.log("GetTemplateDataAPI ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("GetTemplateDataAPI ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("GetTemplateDataAPI form cpm-->rr", err)
                FlagApiLoader(false)
                if (err?.data?.error?.message) {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: err?.data?.error?.message
                    })
                } else {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    })
                }

            }
        )
    }

    const handleSelectChange = (event) => {
        handleOnClickOfTableView(event.target.value);
    };

    const handleSearch = (searchQuery) => {
        const query = searchQuery?.toLowerCase()
        if (agencyDuplicateTemplateDetails != null) {
            const chatbotFilterData = agencyDuplicateTemplateDetails.filter((ele) => {
                return (
                    ele.template_name !== null && ele?.template_name?.toLowerCase().includes(query)
                )
            })
            setAgencyTemplateDetails(chatbotFilterData)
        }
    };



    return (
        <>
            {flagOpenCloneDialog &&
                <Dialog
                    maxWidth="md"
                    open={flagOpenCloneDialog}
                    className='agtc-clone-dialog'
                >
                    <AgencyCloneTemplateDialog
                        setFatchCloneTemplateDetails={setFatchCloneTemplateDetails}
                        fatchCloneTemplateDetails={fatchCloneTemplateDetails}
                        setFlagOpenCloneDialog={setFlagOpenCloneDialog}
                    />
                </Dialog>
            }
            <Grid container
                sx={{
                    // height: '100%' 
                    height: "calc(100% - 65px)"
                }}
            >
                {flagViewAgencyTemplate ? (<></>) :
                    <>
                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant='span'
                                        sx={{ fontSize: '17px', fontWeight: '500', color: '#1b2430' }}
                                    >
                                        Template List
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'end', }}>
                                    <CustomTextField
                                        sx={{
                                            marginRight: '5px'
                                        }}
                                        placeholder="Search"
                                        onChange={(e) => {
                                            handleSearch(e.target.value)
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} marginTop={"12px"}><Divider /></Grid>
                    </>
                }
                <Grid item xs={12} className='agct-main-item' sx={{ height: '100%' }}>
                    <Grid container sx={{ height: '100%' }}>
                        {!flagViewAgencyTemplate ?
                            (
                                <Grid item xs={12}
                                    sx={{
                                        margin: "10px 0px 0px",
                                        // height: isBaTemplate ? "calc(100% - 45px) !important" : "calc(100% - 15px) !important",
                                        height: isBaTemplate ? "100% !important" : "calc(100% - 15px) !important",
                                        overflow: "auto"
                                    }}>
                                    <TableContainer className="agtc-table-container">
                                        <Table stickyHeader size="small" aria-label="a dense table" className='agency-Ba-Template-table'>
                                            <TableHead className='agency-user-table-head'>
                                                <TableRow>
                                                    <TableCell
                                                        align="left"
                                                        className='agency-table-head agnecy-tablefirst-cell'
                                                        width={"50%"}
                                                    >
                                                        Name
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        className='agency-table-head'
                                                    >
                                                        Type
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            display: agencyTemplateDetails?.length != 0 ? 'table-cell' : 'none'
                                                        }}
                                                        className='agency-table-head'
                                                    >
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody className='agency-user-table-body'>
                                                {agencyTemplateDetails?.length != 0 ?
                                                    agencyTemplateDetails?.map((ele, index) => {
                                                        let color = (carrierTypeDetailsForAgency && carrierTypeDetailsForAgency?.find(carrier => carrier.carrier_type_name == ele.carrier_type_name)?.carrier_type_color) ?? 'black'
                                                        return (
                                                            <>
                                                                <TableRow className='agtc-table-thead'>
                                                                    <TableCell
                                                                        align="left"
                                                                        sx={{ background: '#fff', }}
                                                                        className={`awd_domain_record_tbl_cell`}
                                                                    >
                                                                        {ele?.template_name}
                                                                    </TableCell>

                                                                    <TableCell
                                                                        align="left"
                                                                        sx={{ background: '#fff', padding: '5px 5px 5px 10px !important' }}
                                                                        className="awd_domain_record_tbl_cell"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                border: `1px solid ${color}`,
                                                                                color: color,
                                                                                borderRadius: '15px',
                                                                                padding: '3px 10px',
                                                                                fontSize: '13px',
                                                                                fontWeight: '500',
                                                                                width: '150px',
                                                                                maxWidth: '70px',
                                                                                minWidth: '70px',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            {ele?.carrier_type_name}
                                                                        </div>
                                                                    </TableCell>

                                                                    <TableCell
                                                                        align="right"
                                                                        sx={{ background: '#fff', maxWidth: '250px', textAlign: 'end', padding: '5px 15px !important' }}
                                                                        className="awd_domain_record_tbl_cell"
                                                                    >
                                                                        <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                                                            <Grid container sx={{ justifyContent: 'end', gap: '10px' }}>
                                                                                <Grid item className='s'>
                                                                                    <Button
                                                                                        variant='outlined'
                                                                                        size='small'
                                                                                        fullWidth
                                                                                        className='agtc-outlined-btn'
                                                                                        onClick={() => {
                                                                                            handleOnClickOfTableView(ele?.template_id)
                                                                                        }}
                                                                                    >
                                                                                        View
                                                                                    </Button>
                                                                                </Grid>
                                                                                <Grid item className='agtc-clone-template-btn-item'>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        size='small'
                                                                                        fullWidth
                                                                                        className='agtc-contained-btn'
                                                                                        onClick={() => {
                                                                                            handleOpenCloneDialogDetails(ele)
                                                                                        }}
                                                                                    >
                                                                                        Use This Template
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        )
                                                    }) :
                                                    <TableRow sx={{ background: 'white', }}>
                                                        <TableCell sx={{ textAlign: "center", fontSize: "14px", padding: '13px' }} colSpan={3}>
                                                            No Data Found
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={12} sx={{ height: "100%" }}>
                                        <Box className="agct-main-tmp-data-box">
                                            <Box className="agct-header-box" >
                                                <IconButton
                                                    disableFocusRipple
                                                    disableRipple
                                                    disableTouchRipple
                                                    sx={{ padding: '5px 5px 5px 0px' }}
                                                    onClick={() => { setFlagViewAgencyTemplate(false) }}
                                                >
                                                    <ArrowBackIcon style={{ color: '#1b2430' }} />
                                                </IconButton>
                                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                    <Select
                                                        className="agct-header-select"
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        MenuProps={{
                                                            className: "agct-header-select-popover"
                                                        }}
                                                        value={selctedTemplate}
                                                        onChange={handleSelectChange}
                                                    >
                                                        {agencyTemplateDetails?.map((data, ind) => {
                                                            return (
                                                                <MenuItem key={ind} value={data.template_id}>{data.template_name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Grid item xs={12} sx={{ maxHeight: 'max-content' }}>
                                                <Divider />
                                            </Grid>
                                            <Box
                                                sx={{
                                                    // height: "calc(100% - 44px)" 
                                                    height: '100%'
                                                }}
                                            >
                                                <AgencyTemplateData
                                                    maxDlLength={maxDlLength}
                                                    templateData={agencyTemplateData}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )
                        }

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default AgencyCloneTemplate