import React, { useContext } from 'react'
import { Button, Grid } from '@mui/material'
import { Close } from "@mui/icons-material";
import deleteAlert from '../../../assets/Delete-alert.png'
import { DeleteBaMemberApi } from '../../../utils/api/BATeamApi';
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";


function DeleteUserDialog(props) {

    const {
        CallUserMe,
        setFlagDeletePopup,
        setUserList,
        setGlobalUserList,
        deleteUserId,
        userList,
        globalUserList
    } = props

    const { FlagApiLoader, UpdateSnackbarMessage, } = useContext(AuthContext);

    const deleteUser = () => {
        FlagApiLoader(true)
        DeleteBaMemberApi(deleteUserId,
            (res) => {
                if (res?.data?.status) {
                    console.log("response true of DeleteBaMemberApi api", res);
                    CallUserMe()
                    let oldData = [...userList]
                    let oldGlobalData = [...globalUserList]
                    oldData = oldData?.filter((rr) => rr.user_id != deleteUserId)
                    oldGlobalData = oldGlobalData?.filter((rr) => rr.user_id != deleteUserId)
                    setUserList(oldData)
                    setGlobalUserList(oldGlobalData)
                    setFlagDeletePopup(false)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message
                    });
                }
                else {
                    console.log("response false of DeleteBaMemberApi api", res);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
                    });
                    setFlagDeletePopup(false)
                    FlagApiLoader(false)
                }
            },
            (err) => {
                console.log("error of DeleteBaMemberApi api", err);
                UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                setFlagDeletePopup(false)
                FlagApiLoader(false)
            })
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container className="delete-main-container">
                    <Grid item xs={11.5} textAlign={"end"}>
                        <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
                    </Grid>
                    <Grid item xs={12} className="delete-alert-item">
                        <img src={deleteAlert}></img>
                    </Grid>
                    <Grid item xs={12} className="delete-mesg-item">
                        Are you sure you want to delete this user?
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="delete-btn-container">
                            <Grid container className="popup-delete-button-container" >
                                <Button
                                    variant="contained"
                                    sx={{ width: "100px" }}
                                    onClick={() => { deleteUser() }}
                                >
                                    Yes
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ width: "100px" }}
                                    onClick={() => { setFlagDeletePopup(false); }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DeleteUserDialog