import { Divider, Grid, IconButton, Popover, Typography } from '@mui/material'
import React from 'react'
import "../../css/ViewCarriers.css"
import CloseIcon from '@mui/icons-material/Close';


function InsertRowPopover(props) {

    const {
        rowId, rowPopOveropen, anchorElForRow, setAnchorElForRow,
        addNewRowForSection, selectedRowIndex, setSelectedRowIndex
    } = props

    return (
        <>

            <div>
                <Popover
                    id={rowId}
                    open={rowPopOveropen}
                    anchorEl={anchorElForRow}
                    // onClose={handleAddRowPopOverClose}
                    onClose={() => { setAnchorElForRow(null) }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            padding: "8px 15px 10px",
                            width: '150px',
                            borderRadius: '10px'
                        }
                    }}
                    className="vw-action-popover"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        <Grid item xs={11}>
                                            <Typography variant="span" sx={{ fontSize: "11px", fontWeight: "400", color: "#909090" }}>Add New Row</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            {/* <IconButton sx={{ padding: "2px", }} onClick={() => handleAddRowPopOverClose()}> */}
                                            <IconButton sx={{ padding: "2px", }} onClick={() => setAnchorElForRow(null)}>
                                                <CloseIcon style={{ fontSize: "15px", color: "#909090", fontWeight: "600" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                            <Typography
                                                variant="span"
                                                className="vw-popover-action-text"
                                                onClick={() => {
                                                    setAnchorElForRow(null)
                                                    addNewRowForSection(selectedRowIndex?.groupData, selectedRowIndex?.levelIdx, selectedRowIndex?.groupIdx)
                                                }}
                                            >
                                                Insert Above
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                            <Typography
                                                variant="span"
                                                className="vw-popover-action-text"
                                                onClick={() => {
                                                    setAnchorElForRow(null)
                                                    addNewRowForSection(selectedRowIndex?.groupData, selectedRowIndex?.levelIdx, selectedRowIndex?.groupIdx + 1)
                                                }}
                                            >
                                                Insert Below
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popover>
            </div>

        </>
    )
}

export default InsertRowPopover