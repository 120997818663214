import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import React, { useContext } from 'react'

import { Close, ExpandMore, } from "@mui/icons-material";
import FileUpload from '../../../components/global/FileUpload';
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import moment from 'moment';
import PdfIcon from "../../../assets/pdf-icon.png";
import DeleteIcon from "../../../assets/delete-icon.png";

const ResourcesAccordians = (props) => {

    const {
        carrierDetails,
        setFlagCall,
        setImageUrl,
        imageUrl,
        setFlagResourceExpand,
        flagResourceExpand,
        setFlagFileUploadPopup,
        flagCall,
        setFlagPdfPopup,
        setDeleteresourceData,
        setFlagDeletePopup
    } = props

    const { userDetail, } = useContext(authContext).state
    const subAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance"
    const brokersAllianceAgent = userDetail[0]?.user_role_name == "Agent"



    return (
        <>
            <Accordion
                disableGutters
                sx={{
                    backgroundColor: "#EFEFF0",
                    borderRadius: "7px",
                    paddingLeft: "10px",
                    marginBottom: '15px !important'
                }}
                className='caarrier-accordian'
                expanded={flagResourceExpand}
            // onClick={() => { setFlagResourceExpand(!flagResourceExpand) }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMore onClick={() => {
                            if (userDetail[0]?.user_role_name == "Admin") {
                                setFlagResourceExpand(!flagResourceExpand)
                            }
                        }} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ color: "#1565C0", fontSize: "16px", fontWeight: "600" }}
                    onClick={() => {
                        if (userDetail[0]?.user_role_name == "Member" || brokersAllianceAgent) {
                            setFlagResourceExpand(!flagResourceExpand)
                        }
                    }}
                >
                    <Grid container justifyContent="start">
                        <Grid item xl={0.8} onClick={() => {
                            if (userDetail[0]?.user_role_name == "Admin") {
                                setFlagResourceExpand(!flagResourceExpand)
                            }
                        }}>
                            Resources
                        </Grid>
                        <Grid item xl={0.3} textAlign="left">
                            {
                                (flagResourceExpand && userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance")
                                &&
                                <AddBoxOutlinedIcon sx={{ marginLeft: "10px", marginTop: "3px" }} onClick={() => { setFlagFileUploadPopup(true) }} />
                            }
                        </Grid>
                        <Grid item
                            xl={10.9}
                            onClick={() => {
                                if (userDetail[0]?.user_role_name == "Admin") {
                                    setFlagResourceExpand(!flagResourceExpand)
                                }
                            }}>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className="ResourcesAccordianDetails" >
                    <Grid spacing={5} container>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={userDetail[0]?.user_role_name == "Admin" && (userDetail[0]?.user_agency_data?.agency_name.trim().toLowerCase() === "brokers alliance") ? 8 : 12}>
                            <Grid container height="200px" sx={{ overflowY: props?.carrierDetails?.carrier_resources?.length ? "auto" : "auto", overflowX: "auto" }}>
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    <Grid container flexDirection="row">
                                        {carrierDetails?.carrier_resources?.length ? carrierDetails?.carrier_resources?.map((file) => {
                                            return (
                                                <Grid item xs={12} md={12}
                                                    sx={{
                                                        backgroundColor: "#FFFFFF",
                                                        padding: { xs: "5px", md: "10px" },
                                                        borderRadius: "7px",
                                                        marginRight: "15px",
                                                        marginBottom: "10px"
                                                    }}
                                                >
                                                    <Grid container justifyContent="flex-start" alignItems="center">
                                                        <Grid item xs={11}
                                                            sx={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setImageUrl(file?.carrier_resource_file_url)
                                                                setFlagPdfPopup(true)
                                                            }}
                                                        >
                                                            <Grid
                                                                container
                                                                alignItems="center"
                                                            // sx={{ columnGap: { xs: 2, sm: 5 } }}
                                                            >
                                                                <Grid item xs={2} sm={1} md={1.5} lg={1.2} xl={1}>
                                                                    <img src={PdfIcon} />
                                                                </Grid>

                                                                <Grid item xs={10} sm={4} md={4} lg={3.5} xl={2.5}>
                                                                    <Grid container alignItems="center" justifyContent="center" marginLeft={{ xs: "10px", sm: "0" }} >
                                                                        <Grid item xs={12} sx={{ wordWrap: "break-word", fontWeight: "600" }}>
                                                                            {file?.carrier_resource_file_name}
                                                                        </Grid>

                                                                        <Grid item xs={12} width="100%">
                                                                            <Grid container display={{ xs: "flex", sm: "none" }}>
                                                                                <Grid item xs={6}>
                                                                                    {moment(file?.carrier_resource_created_at_timestamp).format("YYYY/MM/DD HH:mm:ss")}
                                                                                </Grid>
                                                                                <Grid item xs={4} >{Math.round((file?.carrier_resource_file_size / 1024 / 1024) * 100) / 100}MB</Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item sm={2.5} md={3} lg={3} xl={2} display={{ xs: "none", sm: "inline" }}>
                                                                    {moment(file?.carrier_resource_created_at_timestamp).format("YYYY-MM-DD HH:mm:ss")}
                                                                </Grid>
                                                                <Grid item sm={2} md={3} lg={3} xl={2} display={{ xs: "none", sm: "inline" }}>
                                                                    {Math.round((file?.carrier_resource_file_size / 1024 / 1024) * 100) / 100}MB
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            !subAgency &&
                                                            <Grid item xs={0.9}>
                                                                <Grid container justifyContent="end">
                                                                    <Grid item>
                                                                        {
                                                                            props?.userRole == "Admin" &&
                                                                            <img
                                                                                style={{ cursor: "pointer" }}
                                                                                src={DeleteIcon}
                                                                                onClick={() => {
                                                                                    setDeleteresourceData({
                                                                                        carrier_detail: props.carrierDetails,
                                                                                        file_detail: file
                                                                                    })
                                                                                    setFlagDeletePopup(true)
                                                                                    // DeleteResouceFile(props.carrierDetails, props?.setFlagCall, props?.flagCall, file) 
                                                                                }} />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                            :
                                            <>
                                                <Grid item xs={12} md={12} textAlign="center" marginTop={"10px"} >No Files Found</Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {(userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name.trim().toLowerCase() === "brokers alliance") &&
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <FileUpload
                                    type="file"
                                    carrierDetails={carrierDetails}
                                    setFlagCall={setFlagCall}
                                    flagCall={flagCall}
                                    setFlagFileUploadPopup={setFlagFileUploadPopup}
                                    saveData={(e) => {
                                        // onChangeSave("widget_logo_id", e);
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ResourcesAccordians