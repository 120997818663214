import React, { useContext, useEffect, useRef, useState } from 'react'

import '../../css/ViewCarriarStyles/ProductMappingDialog.css'
import { Autocomplete, Box, Button, Divider, Grid, InputAdornment, Popper, TextField, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"
import { syncAICarrierWithMagCarrier } from '../../../utils/api/CarriersApi';
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";


function CarrierMappingDialog(props) {

    const { setFlagCarrierMappingDialog, carrierId, setFlagCall, carrierDetails } = props

    const {
        carrierMappingListData,
        productMappingListData
    } = useContext(CarrierContext).state

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)

    const inputRef = useRef(null);
    const [selectedCarrier, setSelectedCarrier] = useState({
        carrier_ai_id: "",
        carrier_name: "",
        remove_carrier_mapping_flag: false,
    });

    useEffect(() => {
        if (carrierDetails) {
            const carrierName = carrierMappingListData?.find((ele) => ele?.carrier_ai_id == carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id)?.carrier_name
            setSelectedCarrier({
                carrier_ai_id: carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id,
                carrier_name: carrierName ? carrierName : ""
            })
        }
    }, [carrierDetails])


    const saveCarrierWithMagCarrierData = () => {
        let payload = {
            carrier_mag_id: carrierId,
            carrier_ai_id: selectedCarrier?.carrier_ai_id,
            remove_carrier_mapping_flag: false,
        };


        if (!selectedCarrier?.carrier_ai_id) {
            delete payload.carrier_ai_id;
            payload.remove_carrier_mapping_flag = true;
        }

        FlagApiLoader(true)
        syncAICarrierWithMagCarrier(payload,
            (response) => {
                if (response?.data?.status) {
                    setFlagCall(new Date().getTime())
                    setFlagCarrierMappingDialog(false)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "success",
                        message: response?.data?.data?.message ? response?.data?.data?.message : "Carrier synced successfully!"
                    });
                }
                else {
                    if (response?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: response?.data?.error?.message
                        });
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        });
                    }
                    FlagApiLoader(false)
                }
            },
            (error) => {
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!",
                });
                FlagApiLoader(false)
            })
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} >
                    <Grid container >

                        <Grid item xs={12} className='prd-header-item'>
                            <Typography variant='span'>
                                Edit AI Carrier - {carrierDetails?.carrier_name ? carrierDetails?.carrier_name : ""}
                            </Typography>
                        </Grid>

                        <Grid item xs={10} sx={{ margin: "20px auto" }}>
                            <Grid container sx={{ justifyContent: "center" }}>
                                <Grid item xs={12} sx={{ margin: "10px 0px 15px" }}>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ margin: "0px 0px 10px" }}>
                                                <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                                    <Grid item className='prd-product-autocomplate'>
                                                        AI Carrier Name
                                                    </Grid>
                                                    <Grid item className='prd-id-field-item'>
                                                        AI Carrier ID
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container className='prd-field-container'>
                                                    <Grid item className='prd-product-autocomplate'>
                                                        <Autocomplete
                                                            fullWidth
                                                            className='prd-autocomplate'
                                                            options={carrierMappingListData}
                                                            getOptionLabel={(option) => `${option?.carrier_name}`}
                                                            popupIcon={<KeyboardArrowDownIcon />}
                                                            value={selectedCarrier?.carrier_ai_id ? selectedCarrier : null}
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    setSelectedCarrier({
                                                                        carrier_ai_id: newValue?.carrier_ai_id,
                                                                        carrier_name: newValue?.carrier_name
                                                                    });
                                                                } else {
                                                                    setSelectedCarrier(prev => ({
                                                                        ...prev,
                                                                        carrier_ai_id: "",
                                                                        carrier_name: "",
                                                                        remove_carrier_mapping_flag: true
                                                                    }));
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder={selectedCarrier?.carrier_name ? "" : "Enter Carrier Name"}
                                                                    label=""
                                                                    variant="outlined"
                                                                    className='prd-autocomplate-textfield'
                                                                    size='small'
                                                                    inputRef={inputRef}
                                                                />
                                                            )}
                                                            PopperComponent={(props) => (
                                                                <Popper
                                                                    {...props}
                                                                    style={{
                                                                        zIndex: 2222,
                                                                        width: inputRef.current ? inputRef.current.offsetWidth : "auto", // Match input width
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Divider orientation='vertical' sx={{ height: '30px', border: '1px solid #E3E3E3' }} />
                                                    <Grid item className='prd-id-field-item'>
                                                        <Typography
                                                            variant='span'
                                                            sx={{
                                                                fontSize: '13px',
                                                                fontWeight: "500",
                                                                color: selectedCarrier?.carrier_ai_id ? "black" : "#434343"
                                                            }}
                                                        >
                                                            {selectedCarrier?.carrier_ai_id || "NA"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} sx={{ margin: "30px 0px 15px" }}>
                                    <Grid container sx={{ justifyContent: "center", gap: "15px" }}>
                                        <Grid item className='prd-button-width'>
                                            <Button
                                                variant='outlined'
                                                size='medium'
                                                fullWidth
                                                className='prd-close-btn'
                                                onClick={() => { setFlagCarrierMappingDialog(false) }}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                        <Grid item className='prd-button-width'>
                                            <Button
                                                variant='contained'
                                                size='medium'
                                                fullWidth
                                                className='prd-save-btn'
                                                disabled={selectedCarrier?.carrier_ai_id == null ? true : false}
                                                onClick={() => {
                                                    saveCarrierWithMagCarrierData()
                                                }}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CarrierMappingDialog