import { Close } from '@mui/icons-material'
import { Button, Divider, Grid, IconButton, Popper, Radio, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Icons from '../../../components/icons/icons'
import '../../css/ViewCarriarStyles/CarrierEffectiveDialog.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { getCarrierEffectiveDateListAPI, updateCarrierEffectiveDateAPI } from '../../../utils/api/CarriersApi'
import moment from 'moment'
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";


const CarrierEffectiveDialog = (props) => {


    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)
    const { userDetail } = useContext(authContext).state
    const { setFlagOpenEffectiveDateDialog, carrierEffectiveDatesDetails, setCarrierEffectiveDatesDetails, carrierId, fetchCarrierEffectiveDateList, carrierDetails } = props

    const [editingId, setEditingId] = useState(null);
    const [editedDate, setEditedDate] = useState(null);
    const [carrierDates, setCarrierDates] = useState([]);
    const brokersAllianceAdmin = (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance")
    const activeDateId = carrierDetails?.carrier_effective_date_data?.carrier_effective_date_id;

    useEffect(() => {
        if (carrierEffectiveDatesDetails[0]?.carrier_effective_dates) {
            const initialDates = carrierEffectiveDatesDetails[0].carrier_effective_dates.map(item => ({
                ...item,
                isSaved: false
            }));
            setCarrierDates(initialDates);
        }
    }, [carrierEffectiveDatesDetails]);

    const handleEditClick = (id, existingDate) => {
        setEditingId(id);
        setEditedDate(existingDate ? new Date(existingDate) : new Date());
    };

    // Handle Date Change
    const handleDateChange = (date) => {
        setEditedDate(date);
    };

    // Handle Save
    const handleSave = async () => {
        if (editedDate && editingId !== null) {
            const formattedDate = moment(editedDate)?.format("YYYY-MM-DD");

            // Prepare the payload for API call
            const payload = {
                carrier_id: carrierId,
                carrier_effective_date_data: {
                    carrier_effective_date_id: editingId,
                    carrier_effective_date_from: formattedDate
                }
            };

            // Call the update API function
            FlagApiLoader(true)
            await updateCarrierEffectiveDateAPI(payload,
                (response) => {
                    if (response?.data?.status) {
                        console.log("Update Successful", response);
                        const updatedDates = carrierDates?.map((item) =>
                            item.carrier_effective_date_id == editingId ? { ...item, carrier_effective_date_from: formattedDate, isSaved: true } : item
                        );
                        setCarrierDates(updatedDates);
                        setEditingId(null);
                        if (brokersAllianceAdmin) {
                            const payload = { carrier_id: carrierId };
                            fetchCarrierEffectiveDateList(payload);
                        }
                        FlagApiLoader(false)
                    }
                    else {
                        console.error("Error in updating date:", response?.data?.error?.message || "Unknown error");
                        UpdateSnackbarMessage({
                            status: "error",
                            message: response?.data?.error?.message ? response?.data?.error?.message : "Something Went Wrong!"
                        });
                        FlagApiLoader(false)
                    }
                },
                (error) => {
                    console.error("API call error:", error);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: error?.data?.error?.message ? error?.data?.error?.message : "Something Went Wrong!"
                    });
                    FlagApiLoader(false)
                }
            );
        }
    };




    return (
        <>
            <Grid container>
                <Grid item xs={12} className='ct-ed-head-item'>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} sx={{ textAlign: "center" }}>
                            <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: "600", color: "#1565C0" }}>
                                Effective Date
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "end" }}>
                            <Close onClick={() => { setFlagOpenEffectiveDateDialog(false) }} sx={{ cursor: "pointer", verticalAlign: "middle" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11.5} className="ct-ed-main-item">
                    <Grid container>
                        <Grid item xs={11.2} sx={{ margin: "0 auto" }}>
                            <Grid container>
                                {carrierDates.length > 0 && carrierDates?.map((item, index) => {
                                    const isEditing = item?.carrier_effective_date_id == editingId;
                                    const isActive = item?.carrier_effective_date_id == activeDateId;

                                    return (
                                        <Grid item key={index} xs={12}
                                            sx={{
                                                marginBottom: index == carrierDates.length - 1 ? '0px' : '15px',
                                                backgroundColor: isEditing ? '#F9F9FC' : isActive ? '#F9F9FC' : 'transparent', // Set background based on ID
                                                border: isActive ? '1px solid #bed8ff' : "none",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} className="ct-ed-date-format-item">
                                                    <Grid container sx={{ height: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xs={10}>
                                                            <Grid container sx={{ gap: '10px' }}>
                                                                <Grid item sx={{ maxWidth: isEditing ? "150px" : "auto" }}>
                                                                    {isEditing ? (
                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                            <DatePicker
                                                                                inputFormat="yyyy-MM-dd"
                                                                                value={editedDate}
                                                                                onChange={handleDateChange}
                                                                                slotProps={{ popper: { placement: 'top-start' } }}
                                                                                renderInput={(params) => <TextField variant='standard' {...params} />}
                                                                                PopperProps={{
                                                                                    style: {
                                                                                        zIndex: 2100,
                                                                                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"
                                                                                    },
                                                                                    modifiers: [
                                                                                        {
                                                                                            name: 'flip',
                                                                                            enabled: false, // Prevents flipping to another side
                                                                                        },
                                                                                        {
                                                                                            name: 'preventOverflow',
                                                                                            options: {
                                                                                                altBoundary: true,
                                                                                                tether: false,
                                                                                                rootBoundary: 'viewport',
                                                                                                padding: 8,
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            name: 'offset',
                                                                                            options: {
                                                                                                offset: [10, 0], // Slight spacing from the input box
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                            />
                                                                        </LocalizationProvider>
                                                                    ) : (
                                                                        <Typography variant="span" className="ct-ed-date-text">
                                                                            {item.carrier_effective_date_from ? moment(item?.carrier_effective_date_from).format("YYYY-MM-DD") : ''}
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                                {!isEditing && (
                                                                    <>
                                                                        <Grid item sx={{ color: '#989898' }}>
                                                                            <Divider orientation="vertical" sx={{ color: '#919191', borderWidth: '1.5px' }} />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="span" className="ct-ed-user-text">
                                                                                {item?.updated_by_user_data?.username ? item?.updated_by_user_data?.username : ""}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Grid container sx={{ justifyContent: 'end', gap: '10px', alignItems: 'center' }}>
                                                                <Grid item>
                                                                    {isEditing ? (
                                                                        <Button onClick={handleSave} size="small" variant="contained">Save</Button>
                                                                    ) : (
                                                                        <>
                                                                            {/* {item.isSaved && (
                                                                        <Icons iconName="checkmarkIcon" iconWidth="17px" iconHeight="17px" styles={{ verticalAlign: 'middle' }} />
                                                                    )} */}
                                                                            <IconButton onClick={() => handleEditClick(item.carrier_effective_date_id, item.carrier_effective_date_from)}>
                                                                                <Icons iconName="editIcon" iconWidth="17px" iconHeight="17px" />
                                                                            </IconButton>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default CarrierEffectiveDialog