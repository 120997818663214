import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import "../../../css/Agency/AgencyDialogs/ExistingAgencyAgent.css";
import { Columns, Search as SearchIcon } from "react-feather";
import {
  Button,
  Checkbox,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,

  TableRow,
} from "@mui/material";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import styled from "@emotion/styled";
import { darken } from "polished";
import CloseIcon from "@mui/icons-material/Close";
import { GetAgentDataList } from "../../../../utils/api/AgentApi";
import PrimaryLogo from "../../../../assets/primary-logo.png";
import SecondaryLogo from "../../../../assets/secondary-logo.png";
import { Context as authContext } from "../../../../contexts/reducerContexts/authContext";
import { Tab } from "@mui/icons-material";
import { Context as agencyContext } from '../../../../contexts/reducerContexts/agencyContext';

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 15px;
  }
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;
const ExistingAgencyAgentDialog = (props) => {
  const { flagOpenExistAgencyAgentDialog, setFlagOpenExistAgencyAgentDialog } =
    props;
  const [isDisabled, setIsDisabled] = useState(true)
  const [existingAgentDataList, setExistingAgentDataList] = useState([]);
  const [checkedExistingAgentDataList, setCheckedExistingAgentDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7)
  const [searchExistingAgentDataList, setSearchExistingAgentDataList] = useState()

  const {
    FlagApiLoader,
    UpdateSnackbarMessage
  } = useContext(authContext)

  const {
    existingAgencyAgents,

    agencyDetails
  } = useContext(agencyContext).state

  const {
    setExistingAgencyAgents,
    setAgencyDetails,
  } = useContext(agencyContext);

  useEffect(() => {
    FlagApiLoader(true)
    GetAgentDataList(
      (res) => {
        if (res?.data?.status) {
          let sortedAgentList = res?.data?.data?.sort((a, b) =>
            a.agent_created_at_timestamp < b.agent_created_at_timestamp
              ? 1
              : a.agent_created_at_timestamp > b.agent_created_at_timestamp
                ? -1
                : 0
          );
          setExistingAgentDataList(sortedAgentList);
          setSearchExistingAgentDataList(sortedAgentList)
          FlagApiLoader(false)


        } else {
          if (res?.data?.error?.message) {
            console.log("GetAgentDataList ERROR RESPONSE", res)
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            })
            FlagApiLoader(false)
          }
          else {
            console.log("GetAgentDataList ERROR RESPONSE", res)
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            })
            FlagApiLoader(false)
          }
        }
      },
      (err) => {
        console.log("GetAgentDataList ERROR RESPONSE", err)
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: err?.data?.error?.message
        })
      }
    );
  }, []);



  const handleSubmitExistingAgencyAgents = () => {
    FlagApiLoader(true);
    let updatedAgencyUserData = [...agencyDetails?.agency_user_data];
    for (let i = 0; i < checkedExistingAgentDataList.length; i++) {
      if (Array.isArray(checkedExistingAgentDataList[i].agent_user_data)) {
        for (let j = 0; j < checkedExistingAgentDataList[i].agent_user_data.length; j++) {

          updatedAgencyUserData.push({
            index: updatedAgencyUserData.length,
            id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
            username: checkedExistingAgentDataList[i].agent_user_data[j].username,
            email_address: checkedExistingAgentDataList[i].agent_user_data[j].email_address,
            user_role_flag: checkedExistingAgentDataList[i].agent_user_data[j].primary_data_flag,
            agent_id: checkedExistingAgentDataList[i].agent_id
          })
        }
      }
    }


    setAgencyDetails({
      ...agencyDetails,
      agency_user_data: updatedAgencyUserData
    })

    setFlagOpenExistAgencyAgentDialog(false);

    FlagApiLoader(false);

  };


  const handleAgenctSearch = (event) => {
    const query = event?.target?.value?.toLowerCase();

    if (searchExistingAgentDataList !== null && searchExistingAgentDataList !== undefined) {
      const searchData = searchExistingAgentDataList.filter((data) => {
        const agentUserData = data?.agent_user_data || [];
        const agentCommissionData = data?.agent_commission_data || [];

        const userMatch = agentUserData.some((user) => {
          return (
            (user?.email_address && user?.email_address?.toLowerCase()?.includes(query)) ||
            (user?.username && user?.username?.toLowerCase()?.includes(query))
          );
        });

        const templateMatch = agentCommissionData.some((commission) => {
          return (
            commission?.template_name && commission?.template_name?.toLowerCase().includes(query)
          );
        });

        return userMatch || templateMatch;
      });
      setExistingAgentDataList(searchData);
    }
  };

  const onSelectExistingAgentData = (agentId, checked) => {
    // Find the selected agent data based on agentId
    const selectedAgentData = existingAgentDataList.find(item => item.agent_id === agentId);

    // Copy the current state of checkedExistingAgentDataList
    const updatedList = [...checkedExistingAgentDataList];

    if (checked) {
      // Add the selected agent data to the list if it's not already there
      if (!updatedList.find(item => item.agent_id === agentId)) {
        updatedList.push(selectedAgentData);
      }
    } else {
      // Remove the agent data from the list
      const index = updatedList.findIndex(item => item.agent_id === agentId);
      if (index !== -1) {
        updatedList.splice(index, 1);
      }
    }
    if (checkedExistingAgentDataList.length == 0 && checked) {
      setIsDisabled(false);
    }
    if (checkedExistingAgentDataList.length == 1 && !checked) {
      setIsDisabled(true);
    }
    // Set the updated list and manage disabled state

    setCheckedExistingAgentDataList(updatedList);
  };
  return (
    <>
      <Grid container gap={8}>
        <Grid item xs={12} className="exad-head-item">
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid container justifyContent={"center"}>
                <Typography variant="span" className="exad-label">
                  Add Agency Agent
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className="exad-close-button"
                onClick={() => {
                  setFlagOpenExistAgencyAgentDialog(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <Grid item xs={3}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={"Search"} onChange={(e) => handleAgenctSearch(e)} />
              </Search>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <Grid item xs={10}>
              <Grid conatainer className="exad-grid-table-container" >
                <TableContainer className="exad-table-container">
                  <Table size="small" aria-label="a dense table">
                    <TableHead className="exad-table-header">
                      <TableRow>
                        <TableCell
                          align="left"
                          className="exad-table-head"
                          stickyHeader

                          width="50px"
                        ></TableCell>
                        <TableCell
                          align="left"
                          className="exad-table-head"
                          stickyHeader

                          width="40px"
                        ></TableCell>
                        <TableCell
                          align="left"
                          className="exad-table-head"
                          stickyHeader

                          width={"180px"}
                        >
                          Name
                        </TableCell>

                        <TableCell
                          align="left"
                          className="exad-table-head"
                          stickyHeader

                          width={"300px"}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="left"
                          className="exad-table-head"
                          stickyHeader

                          width={"220px"}
                        >
                          Life Template
                        </TableCell>

                        <TableCell
                          align="left"
                          className="exad-table-head"
                          stickyHeader

                          width={"220px"}
                        >
                          Annuity Template
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {existingAgentDataList?.length > 0 ? existingAgentDataList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (<TableRow className="exad-table-row" >
                          <TableCell
                            align="left"
                            className="exad-table-cell"
                            stickyHeader
                            padding="none"
                            width="50px"
                          >
                            <Checkbox
                              color="primary"
                              // indeterminate={
                              //   numSelected > 0 && numSelected < rowCount
                              // }
                              // checked={rowCount > 0 && numSelected === rowCount}
                              onChange={(e) => onSelectExistingAgentData(row?.agent_id, e.target.checked)}
                              value={row?.agent_id}
                              checked={checkedExistingAgentDataList.some(item => item.agent_id === row.agent_id)}
                            // inputProps={{
                            //   "aria-label": "select all desserts",
                            // }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            className="exad-table-cell"
                            stickyHeader
                            width="40px"


                          >
                            <Grid container className="exad-table-cell-gap" rowGap={"8px"}>

                              {row?.agent_user_data?.map((element) => {
                                return (
                                  <Grid item>
                                    <Grid
                                      container
                                    >
                                      {element?.primary_data_flag == false ? (
                                        <img src={SecondaryLogo} />
                                      ) : (
                                        <img src={PrimaryLogo} />
                                      )}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </TableCell>
                          <TableCell
                            align="left"
                            className=" exad-table-cell"
                            stickyHeader

                          >
                            <Grid container className="exad-table-cell-gap" rowGap={"10px"}>

                              {row?.agent_user_data?.map((element) => {
                                return (
                                  <Grid item>
                                    <Grid
                                      container


                                    >
                                      {element?.primary_data_flag == false ? (
                                        <Typography variant="span" className="exad-table-name-secondary">
                                          {element?.username}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="span"

                                        >
                                          {element?.username}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </TableCell>

                          <TableCell
                            align="left"
                            className="exad-table-cell"
                            stickyHeader
                            width={"300px"}
                          >
                            <Grid container className="exad-table-cell-gap" rowGap={"10px"} >

                              {row?.agent_user_data?.map((element) => {
                                return (
                                  <Grid item>
                                    <Grid
                                      container
                                      alignItems={"center"}

                                    >
                                      {element?.primary_data_flag == false ? (
                                        <Typography variant="span" className="exad-table-name-secondary">
                                          {element?.email_address}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="span"

                                        >
                                          {element?.email_address}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="exad-table-cell"
                            stickyHeader
                            width={"220px"}
                          >
                            {row?.agent_commission_data?.map((element) => {
                              return (
                                element.template_assign_flag == true &&
                                element.carrier_type_flag == true && (
                                  <Grid item>
                                    <Typography
                                      fontFamily="Segoe UI"
                                      fontWeight="400"
                                      fontSize="14px"
                                    >
                                      {element?.template_name}
                                    </Typography>
                                  </Grid>
                                )
                              );
                            })}
                          </TableCell>

                          <TableCell
                            align="left"
                            className="exad-table-cell"
                            stickyHeader
                            padding="none"
                            width={"220px"}
                          >
                            {row?.agent_commission_data?.map((element) => {
                              return (
                                element.template_assign_flag == true &&
                                element.carrier_type_flag == false && (
                                  <Grid item>
                                    <Typography
                                      fontFamily="Segoe UI"
                                      fontWeight="400"
                                      fontSize="14px"
                                    >
                                      {element?.template_name}
                                    </Typography>
                                  </Grid>
                                )
                              );
                            })}
                          </TableCell>
                        </TableRow>)
                      }
                      ) : (
                        <TableRow >
                          <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                            No Data Available
                          </TableCell>
                        </TableRow>

                      )}
                    </TableBody>

                  </Table>
                </TableContainer>
                {existingAgentDataList?.length > 3 &&
                  <TablePagination
                    className='exad-table-footer-pagination'
                    component={'div'}
                    rowsPerPage={7}
                    page={page}
                    colSpan={6}
                    padding="none"
                    count={existingAgentDataList?.length}
                    rowsPerPageOptions={[]}
                    onPageChange={(e, newPage) => setPage(newPage)}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className="exad-buttons-container">
            <Button
              size="large"
              variant="outlined"
              // sx={{ width: "140px", fontWeight: "500 !important", border: "1px #1565C0" }}
              className="exad-table-buttons"
              onClick={() => {
                setFlagOpenExistAgencyAgentDialog(false);
              }}
            >
              Close
            </Button>

            <Button
              size="large"
              variant="contained"
              sx={{ width: "140px", fontWeight: "400 !important" }}
              disabled={isDisabled}
              onClick={(e) => handleSubmitExistingAgencyAgents(e)}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExistingAgencyAgentDialog;
