import React, { useContext, useState } from 'react'

import "../../css/template.css"
import "../../css/ViewCarriers.css"
import { useNavigate } from 'react-router-dom'
import { Box, Button, Divider, FormControl, Grid, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import Icons from '../../../components/icons/icons'
import EditIcon from '@mui/icons-material/Edit';
import BackIcon from "../../../assets/back-icon.png";
import LifeAnnuityButtons from './LifeAnnuityBttons'

import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { CheckCarrierExistence, GetCarrierData } from '../../../utils/api/CarriersApi'
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"



function CarrierTableNavigation(props) {

    const {
        editDetails, setEditDetails, setFlagCall, carrierData,
        carrierPlaceholderName, setCarrierDetails, carrierDetails,
        searchParams, setCarrierTypeId, carrierTypeId, PathName,
        tableContainerRef, carrierNameDetailsDuplicate, setFlagCarrierMappingDialog, setChangeInCarrierType,
        setHideTransperentCell, setFlagShowAddIcons, flagShowAddIcons, setFlagUnsavedChangesDialog,
        currentlyAddedCols, handleValidation, setCurrentVersion, setCarrierNameDetailsDuplicate,
        setSearchParams, RevokeFunction, currentVersion, carrierVersionList
    } = props

    const { userDetail, apiLoader, carrierTypeDetailsForAgency } = useContext(authContext).state
    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)
    const { carrierMappingListData } = useContext(CarrierContext).state

    const userRoleAdmin = userDetail[0]?.user_role_name == "Admin"
    const agencyNameBrokersAlliance = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
    const brokersAllianceAgent = userDetail[0]?.user_role_name == "Agent"
    const navigate = useNavigate()

    const [flagCarrierNameExist, setFlagCarrierNameExist] = useState(false)
    const [carrierExitenceErrorMessage, setCarrierExitenceErrorMessage] = useState("");
    const [carrierDetailsDuplicate, setCarrierDetailsDuplicate] = useState([])



    const handleCarrierNameExistence = (e) => {

        const trimmedName = carrierDetails?.carrier_name?.trim();
        if (trimmedName != "" && carrierDetails?.carrier_type_id != "") {
            let payload = {
                carrier_name: carrierDetails?.carrier_name?.trim(),
                carrier_type_id: carrierDetails?.carrier_type_id
            }

            FlagApiLoader(true)
            CheckCarrierExistence(payload, (res) => {
                const carrierExistanceResponse = res?.data?.data[0]
                if (res?.data?.status == true && res?.data?.data?.code == 9095) {
                    const message = res?.data?.data?.message
                    // UpdateSnackbarMessage({ status: "error", message: message })
                    // setCarrierExitenceErrorMessage(message)
                    setTimeout(() => {
                        FlagApiLoader(false)
                    }, 300);
                    setFlagCarrierNameExist(true)
                }
                else {
                    if (carrierExistanceResponse.carrier_exist_flag == true) {
                        const error = "This carrier name already exists!";
                        // UpdateSnackbarMessage({ status: "error", message: "This carrier name already exists!" })
                        setCarrierExitenceErrorMessage(error)
                        setTimeout(() => {
                            FlagApiLoader(false)
                        }, 300);
                        setFlagCarrierNameExist(true)
                    }
                    else {
                        setCarrierDetails({
                            ...carrierDetails,
                            carrier_name: e.target.value
                        })
                        setCarrierDetailsDuplicate({
                            ...carrierDetailsDuplicate,
                            carrier_name: e.target.value
                        })
                        setFlagCarrierNameExist(false)
                        setCarrierExitenceErrorMessage("");
                    }
                    setTimeout(() => {
                        FlagApiLoader(false)
                    }, 300);
                }
            },
                (err) => {
                    console.log('CheckCarrierExistence ERROR RESPONSE====>', err)
                    UpdateSnackbarMessage({ status: "error", message: 'Somthing Went Wrong' })
                    FlagApiLoader(false)
                    setCarrierExitenceErrorMessage("");
                })
        }
    }

    const handleChangeVersion = (e) => {
        FlagApiLoader(true)
        let carrierId = searchParams.get("id");
        let obj = {
            carrier_id: carrierId,
            carrier_version_id: e.target.value
        }
        GetCarrierData(obj,
            (res) => {
                if (res?.data?.status) {
                    // alert('4')
                    const responseData = res?.data?.data[0]
                    const updatedCarrierDetails = {
                        ...res?.data?.data[0],
                        carrier_details: {
                            carrier_data_column_left: responseData?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                            carrier_data_column_right: responseData?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                        },

                        carrier_ai_mapping_data: responseData?.carrier_ai_mapping_data ? responseData?.carrier_ai_mapping_data : {},
                        carrier_level: responseData?.carrier_level?.length > 0 ? responseData.carrier_level : [],

                        carrier_product: responseData?.carrier_product?.length > 0 ? responseData?.carrier_product.map(product => ({
                            ...product,
                            carrier_product_ai_mapping_data: product.carrier_product_ai_mapping_data,
                            carrier_product_data: product.carrier_product_data?.sort((a, b) => parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence))?.map(data => ({
                                ...data,
                                carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) => parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)),
                            })),
                        }))
                            :
                            [
                                {
                                    carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                    carrier_product_data: [
                                        {
                                            carrier_product_ai_mapping_data: {},
                                            carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                            carrier_product_name: "",
                                            carrier_product_note: "",
                                            carrier_product_ba_total_comp: "",
                                            carrier_product_commission_data: res?.data?.data[0]?.carrier_level?.map(dt => ({
                                                carrier_ba_level_flag: false,
                                                carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                                carrier_level_id: dt?.carrier_level_id,
                                                carrier_level_name: dt.carrier_level_name,
                                                carrier_level_sequence: "",
                                                carrier_product_commission_value: "",
                                            })),
                                            carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : [],
                                        }
                                    ]
                                }
                            ]
                    };
                    setCarrierDetails(updatedCarrierDetails)
                    setCarrierDetailsDuplicate(updatedCarrierDetails)
                    setCarrierNameDetailsDuplicate(updatedCarrierDetails)
                    setCurrentVersion(obj.carrier_version_id)
                    setSearchParams({ id: carrierId, version_id: obj.carrier_version_id })
                    FlagApiLoader(false)
                }
                else {
                    if (res?.data?.error?.code === 9087) {
                        RevokeFunction()
                    }
                    else {
                        if (res?.data?.error?.message) {
                            UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                            });
                        }
                        else {
                            UpdateSnackbarMessage({
                                status: "error",
                                message: "Something Went Wrong!"
                            });
                        }
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("error of GetCarrierData api", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something went wrong!",
                });
                FlagApiLoader(false)
            }
        )
    }



    return (
        <>
            <Grid container justifyContent="center" className="mainPageStickyHeader">
                <Grid item xs={12} sm={12} md={12} lg={11.6} xl={12}>
                    <Grid container rowGap={2} alignItems="center" width="100%" marginTop={"7px"}>
                        <Grid item
                            xs={editDetails ? 12 : 11}
                            sm={editDetails ? 8 : 8.5}
                            md={editDetails ? 8 : 8.5}
                            lg={editDetails ? 6 : 3}
                            xl={editDetails ? 6 : 4}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignContent: "center" }}>
                                        {!editDetails &&
                                            <IconButton
                                                sx={{ padding: "0px 10px 0px 0px" }}
                                                onClick={() => navigate("/carriers")}
                                            >
                                                <img
                                                    src={BackIcon}
                                                    alt="Logo"
                                                    width="28px"
                                                    height="28px"
                                                    text-align="center"
                                                    justify-content="center"
                                                />
                                            </IconButton>
                                        }

                                        <Grid item>
                                            <Grid container sx={{ gap: "10px" }}>
                                                {!editDetails &&
                                                    <Grid item>
                                                        <FormControl
                                                            variant="standard"
                                                            sx={{ m: 1, minWidth: 120 }}
                                                        >
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                className="vc-nav-carrier-select"
                                                                disableUnderline
                                                                value={searchParams.get("id")}
                                                                MenuProps={{
                                                                    className: 'vc-nav-carrier-dropdown-backdrop',
                                                                }}
                                                                PaperProps={
                                                                    { className: 'vc-nav-carrier-dropdown-paper' }
                                                                }
                                                                onChange={(e) => {
                                                                    let navigateURL = brokersAllianceAgent ? `/carriers/view_carrier?id=${e.target.value}` : `/carriers/view_carrier?id=${e.target.value}&version_id=`
                                                                    navigate(navigateURL)
                                                                    let value = e.target.value
                                                                    setFlagCall(e.target.value)
                                                                }}
                                                            >
                                                                {
                                                                    carrierData?.map((option) => {

                                                                        const matchedCarrierId = carrierTypeDetailsForAgency?.find(data => data.carrier_type_id == carrierTypeId)?.carrier_type_id || null
                                                                        const carrierTypes = option?.carrier_types
                                                                        let carrier = undefined
                                                                        if (matchedCarrierId) {
                                                                            carrier = carrierTypes?.find(carrier => carrier?.carrier_type_id == matchedCarrierId)
                                                                            if (carrier == undefined) {
                                                                                carrier = carrierTypes?.find(carrier => carrier?.carrier_type_id != matchedCarrierId)
                                                                            }
                                                                        }
                                                                        // if (carrierTypeId === "life") {
                                                                        //   value = option?.carrier_types?.filter(carrier => carrier.carrier_type_flag)[0]?.carrier_id
                                                                        //   if (value == undefined) { value = option?.carrier_types?.filter(carrier => !carrier.carrier_type_flag)[0]?.carrier_id }
                                                                        // }
                                                                        // else {
                                                                        //   value = option?.carrier_types?.filter(carrier => !carrier.carrier_type_flag)[0]?.carrier_id
                                                                        //   if (value == undefined) { value = option?.carrier_types?.filter(carrier => carrier.carrier_type_flag)[0]?.carrier_id }
                                                                        // }
                                                                        return (
                                                                            <MenuItem
                                                                                key={option?.carrier_name}
                                                                                value={carrier?.carrier_id}
                                                                            >
                                                                                {option.carrier_name}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {editDetails &&
                                                    <Grid item>
                                                        <Grid container sx={{ flexDirection: "column" }}>
                                                            <Grid item sx={{ margin: "0px 0px 2px" }}>
                                                                <TextField
                                                                    autoComplete="off"
                                                                    variant="standard"
                                                                    className="vc-edit-carrier-field"
                                                                    value={carrierDetails?.carrier_name}
                                                                    placeholder={carrierPlaceholderName}
                                                                    onBlur={(e) => {
                                                                        const trimmedName = carrierDetails?.carrier_name?.trim();
                                                                        const trimmedNameDuplicate = carrierNameDetailsDuplicate?.carrier_name?.trim();
                                                                        // if (!skipCheckOnBlur) {
                                                                        if (trimmedName != trimmedNameDuplicate) {
                                                                            handleCarrierNameExistence(e); // Call API only if trimmed name is not empty
                                                                        } else {
                                                                            setFlagCarrierNameExist(false); // Reset if no change or only whitespace
                                                                        }
                                                                        // }
                                                                        // setSkipCheckOnBlur(false)
                                                                    }}
                                                                    onFocus={(e) => {
                                                                        const trimmedNameDuplicate = carrierNameDetailsDuplicate?.carrier_name?.trim();
                                                                        if (e?.target?.value?.trim() == trimmedNameDuplicate) {
                                                                            setFlagCarrierNameExist(false); // Disable save/cancel if value matches original
                                                                        } else {
                                                                            setFlagCarrierNameExist(true); // Enable save/cancel if value is different
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setCarrierDetails({
                                                                            ...carrierDetails,
                                                                            carrier_name: e.target.value
                                                                        })
                                                                        if (e.target.value == carrierNameDetailsDuplicate?.carrier_name) {
                                                                            setFlagCarrierNameExist(false); // Disable save/cancel if value matches original
                                                                        } else {
                                                                            setFlagCarrierNameExist(true); // Enable save/cancel if value is different
                                                                        }
                                                                        setCarrierExitenceErrorMessage("");
                                                                    }}
                                                                />
                                                            </Grid>
                                                            {carrierExitenceErrorMessage &&
                                                                <Grid item className="vc-carrier-error-item">
                                                                    <Typography variant="span">
                                                                        {carrierExitenceErrorMessage ? carrierExitenceErrorMessage : ""}
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {(userRoleAdmin && agencyNameBrokersAlliance) &&
                                                    <Grid item className="ct-carrier-ai-chip-item">
                                                        <Grid container sx={{ alignItems: "center", height: "100%", gap: "5px" }}>
                                                            <Grid item>
                                                                <Typography variant="span" className="carrier-id-label">AI Carrier ID & Name:</Typography>&nbsp;

                                                                <Typography
                                                                    variant="span"
                                                                    className="ct-ai-product-text"
                                                                    sx={{ verticalAlign: "baseline !important", color: carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "black" : "" }}
                                                                >
                                                                    {carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id : "NA"}
                                                                </Typography>

                                                            </Grid>

                                                            <Divider orientation="vertical" sx={{ height: "20px" }} />

                                                            <Grid item>
                                                                <Grid container>
                                                                    <Tooltip
                                                                        componentsProps={{
                                                                            tooltip: {
                                                                                sx: {
                                                                                    bgcolor: "#1B2430",
                                                                                    color: "#ffffff",
                                                                                    padding: "5px",
                                                                                    fontSize: "11px",
                                                                                    fontWeight: 500,
                                                                                },
                                                                            },
                                                                        }}
                                                                        title={
                                                                            (carrierMappingListData?.find((ele) => ele?.carrier_ai_id == carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id)?.carrier_name || "").length > 9
                                                                                ? carrierMappingListData?.find((ele) => ele?.carrier_ai_id == carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id)?.carrier_name
                                                                                : ""
                                                                        }
                                                                        disableHoverListener={
                                                                            (carrierMappingListData?.find((ele) => ele?.carrier_ai_id == carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id)?.carrier_name || "").length <= 9
                                                                        }
                                                                    >
                                                                        <Typography component={'div'} className="ct-mapping-name-text">
                                                                            {carrierMappingListData?.find((ele) => ele?.carrier_ai_id == carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id)?.carrier_name || ""}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                    <IconButton
                                                                        sx={{ padding: "2px", margin: '0px 0px 2px 1px', verticalAlign: 'baseline !important' }}
                                                                        onClick={() => {
                                                                            setFlagCarrierMappingDialog(true)
                                                                        }}
                                                                    >
                                                                        <Icons iconName="editIcon" iconWidth="15px" iconHeight="15px" iconColor="#1565C0" />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {!editDetails &&
                            <Grid item xs={1} sm={3.5} md={3.5} lg={4} xl={4} justifyContent={"end"}>
                                <div className="displaySliderButtonAbove">
                                    <LifeAnnuityButtons
                                        editDetails={editDetails}
                                        carrierTypeId={carrierTypeId}
                                        setChangeInCarrierType={setChangeInCarrierType}
                                        setCarrierTypeId={setCarrierTypeId}
                                        carrierDetails={carrierDetails}
                                    />
                                </div>

                                {carrierDetails.carrier_version_latest && userRoleAdmin &&
                                    <div className="displayEditButtonAbove">
                                        <Button
                                            className="viewCarrier_EditIconBtn"
                                            variant="contained"
                                            onClick={() => setEditDetails(true)}
                                            size="small"
                                        >
                                            <EditIcon />
                                        </Button>
                                    </div>
                                }

                                {(carrierDetails.carrier_version_latest && userRoleAdmin && agencyNameBrokersAlliance) &&
                                    <div className="displayEditCarrierButtonAbove">
                                        <Button
                                            className="viewCarrier_EditBtn"
                                            startIcon={<EditIcon />}
                                            sx={{
                                                size: "small",
                                                borderRadius: "4px",
                                                backgroundColor: "#1565C0",
                                                marginRight: "20px",
                                                padding: "8px 20px",
                                                color: "white",
                                                "&:hover": {
                                                    color: "white",
                                                    backgroundColor: "#1565C0"
                                                }
                                            }}
                                            onClick={() => {
                                                setEditDetails(true)
                                                setHideTransperentCell(true)
                                                setFlagShowAddIcons(false)
                                            }}
                                        >
                                            Edit Carrier
                                        </Button>
                                    </div>
                                }
                            </Grid>
                        }

                        <Grid item
                            xs={12}
                            sm={editDetails ? 4 : 12}
                            md={editDetails ? 4 : 12}
                            lg={editDetails ? 6 : 5}
                            xl={editDetails ? 6 : 4}
                            display={'flex'}
                            justifyContent={'end'}
                        >
                            <Grid container>
                                <Grid item xs={12} >
                                    {editDetails ?
                                        <Box className="viewCarrier_SaveCancel_Box" sx={{ justifyContent: "end" }}>
                                            <Button
                                                disabled={flagShowAddIcons || flagCarrierNameExist}
                                                variant="outlined"
                                                onClick={() => {
                                                    if (tableContainerRef.current) {
                                                        tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
                                                    }
                                                    setFlagUnsavedChangesDialog(true)
                                                }}
                                                sx={{ padding: "7px 40px", marginRight: "10px" }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                disabled={flagShowAddIcons || flagCarrierNameExist}
                                                onClick={(e) => {
                                                    if (currentlyAddedCols && currentlyAddedCols?.length > 0 && flagShowAddIcons) {
                                                        UpdateSnackbarMessage({
                                                            status: "error",
                                                            message: "Please first finish level addition process"
                                                        })
                                                        return;
                                                    }
                                                    handleValidation()
                                                }}
                                                sx={{
                                                    padding: "7px 40px",
                                                    borderRadius: "4px",
                                                    marginLeft: "10px",
                                                    backgroundColor: "#1565C0",
                                                    color: "white",
                                                    "&:hover": {
                                                        color: "white",
                                                        backgroundColor: "#1565C0"
                                                    }
                                                }}>
                                                {PathName == "/carriers/add_carrier" ? "Create" : "Save"}
                                            </Button>
                                        </Box>
                                        :
                                        <Box className="viewCarrier_EditBtnVersionList_Container" sx={{ justifyContent: "end" }}>
                                            {(carrierDetails.carrier_version_latest && userRoleAdmin && agencyNameBrokersAlliance) ?
                                                (
                                                    <div className="displayEditButtonBelow">
                                                        <Button
                                                            className="viewCarrier_EditBtn"
                                                            startIcon={<EditIcon />}
                                                            sx={{
                                                                size: "small",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#1565C0",
                                                                marginRight: "20px",
                                                                padding: "8px 20px",
                                                                color: "white",
                                                                "&:hover": {
                                                                    color: "white",
                                                                    backgroundColor: "#1565C0"
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                setEditDetails(true)
                                                                setHideTransperentCell(true)
                                                                setFlagShowAddIcons(false)
                                                            }}>
                                                            Edit Carrier
                                                        </Button>
                                                    </div>
                                                ) : ("")
                                            }

                                            <div className="displaySliderButtonBelow">
                                                <LifeAnnuityButtons
                                                    editDetails={editDetails}
                                                    carrierTypeId={carrierTypeId}
                                                    setChangeInCarrierType={setChangeInCarrierType}
                                                    setCarrierTypeId={setCarrierTypeId}
                                                    carrierDetails={carrierDetails}
                                                />
                                            </div>

                                            {userRoleAdmin && agencyNameBrokersAlliance &&
                                                <FormControl className="viewCarrier_VersionList_Dropdown">
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={currentVersion}
                                                        onChange={(e) => { handleChangeVersion(e) }}
                                                        sx={{
                                                            backgroundColor: "#EFEFF0",
                                                            "& .MuiSelect-select": {
                                                                padding: "10px 14px",
                                                            },
                                                            "& fieldset": {
                                                                border: "none"
                                                            }
                                                        }}
                                                        MenuProps={{
                                                            disablePortal: false,
                                                            PaperProps: {
                                                                sx: {
                                                                    maxHeight: "275px",
                                                                    overflow: "auto"
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {carrierVersionList?.map((element) => {
                                                            const currentVersionList = new Date(element?.carrier_version_timestamp);
                                                            const day = currentVersionList.getUTCDate();
                                                            const month = currentVersionList.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
                                                            const year = currentVersionList.getUTCFullYear();
                                                            const hours = currentVersionList.getUTCHours();
                                                            const minutes = currentVersionList.getUTCMinutes();
                                                            const seconds = currentVersionList.getUTCSeconds();
                                                            let formattedDate = "";
                                                            formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${element.carrier_version_latest ? '(Current version)' : ''}`.trim()
                                                            return (
                                                                <MenuItem value={element?.carrier_version_id}>{formattedDate}</MenuItem>
                                                            )
                                                        })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            }
                                        </Box>
                                    }
                                </Grid>

                                {editDetails &&
                                    <Grid item xs={12} className="cg-crs-text-item" sx={{ textAlign: 'end' }}>
                                        <Typography component={'span'} sx={{ color: "#777777", fontSize: "12px", fontWeight: "500" }}>
                                            Note: Changes such as Create, Edit and Delete will only take effect when the
                                            <Typography component={'span'} sx={{ color: "#000", fontSize: "12px", fontWeight: "500" }}> Save </Typography>button is pressed.
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ m: "10px 0px 15px" }}>
                    <Divider my={2} mb={4} />
                </Grid>
            </Grid>
        </>
    )
}

export default CarrierTableNavigation