import React, { useContext, useState } from 'react';
import '../../css/ViewCarriarStyles/AddNewCarrierDialog.css'
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { CheckCarrierExistence } from '../../../utils/api/CarriersApi';
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { useNavigate } from 'react-router-dom';
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"

const AddNewCarrierDialog = (props) => {
    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext);
    const { carrierTypeDetailsForAgency } = useContext(authContext).state;
    const { setCarrierName } = useContext(CarrierContext);

    const [flagCarrierExistanceCheck, setFlagCarrierExistanceCheck] = useState(false)
    const [newCarrierData, setNewCarrierData] = useState({ carrier_name: "", carrier_type_id: "" })

    const navigate = useNavigate()

    const handleCarrierAdd = () => {
        if (newCarrierData?.carrier_name != "" && newCarrierData?.carrier_type_id != "") {
            CheckCarrierExistence(newCarrierData, (res) => {
                let carrierExistanceResponse = res?.data?.data[0]
                if (res?.data?.status == true && res?.data?.data?.code == 9095) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.data?.message })
                }
                else {
                    if (carrierExistanceResponse.carrier_exist_flag == true) {
                        setFlagCarrierExistanceCheck(true)
                    }
                    else {
                        setCarrierName(newCarrierData?.carrier_name)
                        navigate("/carriers/add_carrier", { state: newCarrierData })
                    }
                }
            }, (err) => {
                console.log('CheckCarrierExistence ERROR RESPONSE====>', err)
                UpdateSnackbarMessage({ status: "error", message: 'Somthing Went Wrong' })
            })
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container flexDirection={"column"} justifyContent={'center'}>

                    <Grid item xs={12} className='anc_header'>
                        <Typography>Add Carrier</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={7} className='anc_nametype'>
                                <Grid container alignItems={'flexStart'}>
                                    <Grid item xs={12} className='anc_textfield'>
                                        <TextField
                                            value={newCarrierData?.name}
                                            placeholder='Carrier Name'
                                            fullWidth
                                            variant="standard"
                                            helperText={flagCarrierExistanceCheck ? "This carrier already exists!" : null}
                                            FormHelperTextProps={{ sx: { color: 'red' } }}
                                            onFocus={() => { setFlagCarrierExistanceCheck(false) }}
                                            onChange={(event) => {
                                                setNewCarrierData({ ...newCarrierData, carrier_name: event.target.value })
                                            }}></TextField>
                                    </Grid>
                                    <Grid item xs={12} className='anc_type_radiobtns'>
                                        <div className='anc_type_radiobtns_header'>
                                            <Typography>Type</Typography>
                                            <sup style={{ color: "red" }} className='anc_required_symbol'>*</sup>
                                        </div>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue=""
                                                name="radio-buttons-group"
                                                onChange={(event) => {
                                                    setNewCarrierData({ ...newCarrierData, carrier_type_id: event.target.value })
                                                }}
                                            >
                                                {carrierTypeDetailsForAgency?.map(data => {
                                                    return (<FormControlLabel
                                                        key={data?.carrier_type_id}
                                                        value={data?.carrier_type_id}
                                                        control={<Radio />} label={data?.carrier_type_name} />
                                                    )
                                                })}
                                                {/* <FormControlLabel value={true} control={<Radio />} label="Life" />
                                                <FormControlLabel value={false} control={<Radio />} label="Annuity" /> */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className='anc_btns' xs={7}>
                                <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                    <Grid item xs={5}>
                                        <Button
                                            fullWidth
                                            className="anc_btns_close"
                                            variant='outlined'
                                            onClick={() => props.setFlagAddNewCarrierDialog(false)} >
                                            Close
                                        </Button>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            className={(newCarrierData?.carrier_name == "" || newCarrierData?.carrier_type_id == "") ?
                                                "anc_btns_save_disabled"
                                                : "anc_btns_save"
                                            }
                                            onClick={handleCarrierAdd}>
                                            Continue
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddNewCarrierDialog