import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Divider,
    Grid,
    InputBase,
    Typography,
    Avatar,
    IconButton,
    Box,
    TextField,
    Tooltip
} from "@mui/material";
// import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    GridToolbarContainer,
    useGridApiRef,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";

import styled from '@emotion/styled';
import { Columns, Search as SearchIcon } from "react-feather";
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { darken } from "polished";
import '../../css/Agency/Agency.css'
import { getAgencyDataList } from '../../../utils/api/AgencyDetailsAPI';
import { flexbox, maxWidth, minWidth, Stack, width } from '@mui/system';
import moment from 'moment'
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";
import BackIcon from "../../../assets/back-icon.png";
import GlobalDialog from '../../../components/global/GlobalDialog';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
// import { useDemoData } from "@mui/x-data-grid-generator";



const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;


const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  ,
  .MuiDataGrid-iconSeparator
  {
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;

// const generateCarrierColumns = (carrierTypes) => {

//     return carrierTypes.map(type => ({


//         field: `${type.carrier_type_name.toLowerCase()}_carrier_count`,
//         headerName: `# ${type.carrier_type_name} Carriers`,
//         minWidth: computedWidth,
//         maxWidth: computedWidth + 50,
//         flex: 1,
//         // maxWidth: 136,
//         align: "center",
//         headerAlign: "center",
//         description: `${type.carrier_type_name} Carriers Count`,
//         renderCell: (params) => {
//             const count = params?.row?.agency_info?.[`${type.carrier_type_name.toLowerCase()}_carrier_count`];
//             return (
//                 <div style={{ color: type.carrier_type_color }}>
//                     {count}
//                 </div>
//             );
//         }
//     }));
// };



const generateCarrierColumns = (carrierTypes) => {
    return carrierTypes.map(type => {
        let computedWidth;

        if (type.carrier_type_name.toLowerCase() == 'life') {
            computedWidth = 125;
        }
        else if (type.carrier_type_name.toLowerCase() == 'annuity') {
            computedWidth = 147;
        }
        else if (type.carrier_type_name.toLowerCase() == 'health') {
            computedWidth = 140;
        }
        else {
            // Default dynamic width for other carrier types
            const baseWidth = 22; // Average pixel width per character
            const padding = 21; // Adjust padding based on your design
            const nameLength = type.carrier_type_name.length;
            computedWidth = nameLength * baseWidth + padding; // Dynamic width based on character length
        }

        return {
            field: `${type.carrier_type_name.toLowerCase()}_carrier_count`,
            headerName: `# ${type.carrier_type_name} Carriers`,
            headerClassName: 'carrier-headers',
            minWidth: computedWidth, // dynamic minimum width based on character count
            maxWidth: computedWidth + 40, // slightly larger max width for flexibility
            flex: 1,
            align: "center",
            headerAlign: "center",
            description: `${type.carrier_type_name} Carriers Count`,
            cellClassName: 'carrier-headers-cell',
            renderCell: (params) => {
                const count = params?.row?.agency_info?.[`${type.carrier_type_name.toLowerCase()}_carrier_count`];
                return (
                    <div className='agency-common-text' style={{ color: type.carrier_type_color }}>
                        {count}
                    </div>
                );
            }
        };
    });
};

const generateDummyData = (numRows) => {
    const dummyData = [];
    for (let i = 1; i <= numRows; i++) {
        dummyData.push(
            {
                "agency_id": 379,
                "created_at_timestamp": "2024-08-20T15:28:04.000Z",
                "created_by_email_address": "frank.esquibel@brokersalliance.com",
                "created_by_user_id": 699,
                "agency_name": "National Brokers Goup",
                "agency_email_address": "NBG@brokersalliance.com",
                "agency_logo_gcs_id": 1212,
                "agency_logo_url": "https://api-dev.myadvisorgrids.com/static/assets/ZnawLJvTqpCShfh17241676718811724167671881--nbg_fake_1724167671871.png",
                "agency_logo_name": "nbg fake.png",
                "agency_logo_size": "1615",
                "agency_favicon_gcs_id": 1213,
                "agency_favicon_url": "https://api-dev.myadvisorgrids.com/static/assets/TXd4y9ngXjYveeu17241676828631724167682863--nbg_fake_1724167682854.png",
                "agency_favicon_name": "nbg fake.png",
                "agency_favicon_size": "1615",
                "agency_mag_url": "nationalbrokersgoup-dev.myadvisorgrids.com",
                "agency_custom_domain_data": [],
                "agency_status_id": 3,
                "agnecy_status_name": "Active",
                "parent_agency_id": 1,
                "agency_info": {
                    "agent_count": 0,
                    "admin_count": 2,
                    "arm_count": 0,
                    "life_carrier_count": 68,
                    "annuity_carrier_count": 36,
                    "health_carrier_count": 0
                }
            }
        );
    }
    return dummyData;
};


const Agency = () => {
    const navigate = useNavigate()
    const pathname = window.location.pathname
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const { agencyDetails } = useContext(agencyContext).state
    const { carrierTypeDetailsForAgency } = useContext(AuthContext).state;
    const {
        setAgencyDetails,
        setFileSelected,
        setAgencyFavIcon,
        setFileUploaded,
        setFavIconFileUploaded,
        setAgencyHtmlContent,
        setToggleDetails
    } = useContext(agencyContext)

    const {
        FlagApiLoader,
        UpdateSnackbarMessage,
        state
    } = useContext(authContext)

    const [agencyRowDetails, setAgencyRowDetails] = useState([])
    const [searchRowDetails, setSearchRowDetails] = useState([])
    const [showLogoImage, setShowLogoImage] = useState(false)
    const [logoInfo, setLogoInfo] = useState({})
    const [noDataMsgFlag, setNoDataMsgFlag] = useState(true);
    const PAGE_SIZE = 5;
    const apiRef = useGridApiRef();
    const [pageSize, setPageSize] = useState(50);
    const [dataGridColumn, setDataGridColumn] = useState([])

    useEffect(() => {
        if (carrierTypeDetailsForAgency?.length != 0) {
            // generating dynamic column based backend carriers
            const columns = [
                // {
                //     field: 'index',
                //     hide: true,
                //     headerName: '',
                //     // width: "40",
                //     width: 10,
                //     sortable: false,
                //     renderCell: (params) => `${params.api.getRowIndex(params.id) + 1}`,
                // },
                {
                    field: 'agency_logo_url',
                    headerName: 'Logo',
                    width: 60,
                    // minWidth: 50,
                    // maxWidth: 60,
                    headerClassName: "ag-datagrid-logo-header-cell",
                    sortable: false,
                    description: "Agency Logo",
                    renderCell: (params) => {
                        // console.log("params->", params);
                        let agencyLogo = params.row.agency_logo_url
                        const agency_id = params.row.agency_id
                        return (<Avatar src={agencyLogo ? agencyLogo : ""} sx={{ width: 32, height: 32 }} />)
                    },
                    cellClassName: (params) => {
                        let firstColStyles = params.field
                        if (firstColStyles === "agency_logo_url") {
                            return "ag-datagrid-logo-body-cell"
                        }

                    }
                },
                {
                    field: 'agency_name',
                    headerName: 'Agency Name',
                    description: "Agency Name",
                    minWidth: 200,
                    width: 200,
                    flex: 1,
                    maxWidth: "100%",
                    renderCell: (params) => {
                        const agencyName = params?.row?.agency_name
                        return (
                            <>
                                <span className='agency-name-text'>{agencyName}</span>
                            </>
                        )
                    }
                },

                {
                    field: 'agency_email_address',
                    headerName: 'Email',
                    flex: 1,
                    minWidth: 250,
                    // maxWidth: 290,
                    description: "Email",
                    renderCell: (params) => {
                        const email = params?.row?.agency_email_address;
                        return (
                            <Tooltip
                                title={<span style={{ fontSize: "12px" }}>{email}</span>}
                                placement="bottom"
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        width: '100%',
                                    }}
                                    className='agency-common-text'
                                >
                                    {email}
                                </span>
                            </Tooltip>
                        );
                    }
                },
                {
                    field: 'agency_mag_url',
                    headerName: 'MAG URL',
                    flex: 1,
                    minWidth: 250,
                    // maxWidth: 300,
                    description: "MAG URL",
                    renderCell: (params) => {
                        const magURL = params?.row?.agency_mag_url;
                        return (
                            <Tooltip
                                title={<span style={{ fontSize: "12px" }}>{magURL}</span>}
                                placement="bottom"
                            >
                                <span style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    width: '100%'
                                }}
                                    className='agency-common-text'
                                >
                                    {magURL}
                                </span>
                            </Tooltip>
                        );
                    }
                },
                {
                    field: 'agent_count',
                    headerName: '# Agents',
                    width: 102,
                    align: "center",
                    headerAlign: "center",
                    description: "Agent Count",
                    renderCell: (params) => {
                        const agentCount = params?.row?.agency_info.agent_count
                        return (
                            <>
                                <span className='agency-common-text'>{agentCount}</span>
                            </>
                        )
                    }
                },
                {
                    field: 'admin_count',
                    headerName: '# Admin',
                    minWidth: 102,
                    align: "center",
                    headerAlign: "center",
                    description: "Admin Count",
                    renderCell: (params) => {
                        const adminCount = params?.row?.agency_info?.admin_count
                        return (
                            <>
                                <span className='agency-common-text'>{adminCount}</span>
                            </>
                        )
                    }
                },
                ...generateCarrierColumns(carrierTypeDetailsForAgency),
                {
                    field: 'agnecy_status_name',
                    headerName: 'Status',
                    minWidth: 100,
                    // minWidth: 170,
                    // maxWidth: 170,
                    align: "center",
                    headerAlign: "center",
                    description: "White Label Status",
                    renderCell: (params) => {
                        let agentStatus = params.row.agnecy_status_name;
                        return (
                            <>
                                <div
                                    style={{
                                        border: agentStatus.trim().toLowerCase() == "active" ? '1px solid #1ACC2C' : "1px solid #CCB01A",
                                        color: agentStatus.trim().toLowerCase() == "active" ? '#1ACC2C' : "#CCB01A",
                                        background: agentStatus.trim().toLowerCase() == "active" ? '#1ACC2C1A' : "#CCB01A1A",
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        borderRadius: "5px",
                                        padding: "3px 10px",
                                        textAlign: "center",
                                        width: "80px"
                                    }}
                                >
                                    {agentStatus.trim().toLowerCase() == "active" ? "active" : "inactive"}
                                </div>

                            </>
                        )

                    }
                },
                {
                    field: 'created_at_timestamp',
                    headerName: 'Created Date',
                    minWidth: 130,
                    flex: 0.5,
                    // minWidth: 134,
                    // maxWidth: 134,
                    description: "Created Date",
                    renderCell: (params) => {
                        return (
                            <span className='agency-common-text'>{moment(params?.row?.created_at_timestamp).format('DD/MM/YYYY')}</span>
                        )
                    }
                },
            ]
            setDataGridColumn(columns)
        }

    }, [carrierTypeDetailsForAgency])


    useEffect(() => {
        FlagApiLoader(true)
        getAgencyDataList({},
            (res) => {
                if (res?.data?.status) {
                    console.log("response of get agency list", res.data.data);
                    const responseData = res.data.data
                    // const dummyData = generateDummyData(500);
                    // const combinedData = [...res.data.data, ...dummyData];
                    setAgencyRowDetails(responseData)
                    setSearchRowDetails(responseData)
                    FlagApiLoader(false)
                }
                else {
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res.data.error.message,
                    });
                }
            }, (err) => {
                console.log("response of get agency list", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: err.data.error.message,
                });
                FlagApiLoader(false)
            })
    }, [])

    useEffect(() => {
        if (searchRowDetails?.length) {
            setNoDataMsgFlag(true)
        } else {
            setNoDataMsgFlag(false)
        }
    }, [searchRowDetails])

    const handleSearchBar = (query) => {
        const filteredRows = agencyRowDetails.filter((item) =>
            item.agency_name.toLowerCase().includes(query.toLowerCase()) ||
            item.agency_email_address.toLowerCase().includes(query.toLowerCase()) ||
            item.agency_mag_url.toLowerCase().includes(query.toLowerCase())
        )
        setSearchRowDetails(filteredRows)
    }
    const GoInput = styled(Input)(({ theme }) => ({
        width: '40px',
        margin: '0 8px',
        '& input': {
            textAlign: 'center',
            textDecoration: 'none'
        },
        background: 'white',
    }));

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
        const currentPage = useGridSelector(apiRef, gridPageSelector) + 1;
        const [pageInput, setPageInput] = useState(currentPage);

        useEffect(() => {
            // Update pageInput whenever currentPage changes
            setPageInput(currentPage);
        }, [currentPage]);

        const handlePageInputChange = (event) => {
            setPageInput(event.target.value);
        };

        const handleGoClick = () => {
            const pageNumber = Number(pageInput) - 1;
            if (pageNumber >= 0 && pageNumber < pageCount) {
                apiRef.current.setPage(pageNumber);
            }
        };

        const handleChange = (event, value) => {
            apiRef.current.setPage(value - 1);
        };

        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    background: '#EFEFF0',
                    padding: '5px 5px',
                    borderRadius: '5px',
                    marginBottom: '10px'
                }}
            >
                <Pagination
                    color="primary"
                    count={pageCount}
                    page={currentPage}
                    onChange={handleChange}
                    sx={{
                        "& .Mui-selected": {
                            backgroundColor: "#233044 !important",
                            "&:hover": {
                                backgroundColor: "#233044 !important",
                            }
                        }
                    }}
                    renderItem={(item) => (
                        <PaginationItem
                            component="div"
                            {...item}
                            disableRipple
                        />
                    )}
                />
                {pageCount > 1 && (
                    <>
                        <TextField
                            value={pageInput}
                            onChange={handlePageInputChange}
                            onBlur={handleGoClick}
                            autoComplete="off"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleGoClick();
                                }
                            }}
                            inputProps={{
                                type: 'number',
                                'aria-label': 'Page number',
                                min: 1,
                                max: pageCount,
                            }}
                            variant="standard"
                            size="small"
                            sx={{
                                width: '50px',
                                textAlign: 'center',
                                "& .MuiInputBase-input": {
                                    textAlign: 'center',
                                    background: '#ffffff',
                                    padding: "5px 7px",
                                    borderRadius: "3px"
                                }
                            }}
                        />
                        <IconButton onClick={handleGoClick} size="small" sx={{ ml: '5px' }}>
                            Go
                        </IconButton>
                    </>
                )}
            </Box>
        );
    }

    return (
        <>
            <Grid container sx={{ height: "100%" }}>
                <Grid item xs={12} >
                    <Grid container sx={{ height: "100%", gap: "10px " }}>

                        {/* HEADER */}
                        <Grid item xs={12} sx={{ marginTop: "15px" }}>
                            <Grid container sx={{ alignItems: "center", padding: "0px 23px" }}>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {pathname == "/agency" ?
                                            (<>
                                                <Typography variant='span' className='ag-header-main-typo'>
                                                    Agency
                                                </Typography>
                                            </>) : (agencyId || pathname == "agency/add_agency" &&
                                                <>
                                                    <IconButton
                                                        sx={{ padding: "0px 10px 0px 0px" }}
                                                        onClick={() => navigate("/carriers")}
                                                    >
                                                        <img
                                                            src={BackIcon}
                                                            alt="Logo"
                                                        />
                                                    </IconButton>
                                                    <Typography variant='span' className='ag-header-main-typo'>
                                                        Agency
                                                    </Typography>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>

                                {/* HEADER RIGHT CONTENT */}
                                <Grid item xs={6}>
                                    <Grid container sx={{ justifyContent: "end", gap: "15px", alignItems: "center" }}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                                                onClick={() => {
                                                    setAgencyDetails({
                                                        ...agencyDetails,
                                                        agency_name: "",
                                                        agency_email_address: "",
                                                        agency_mag_url: "",
                                                        agency_custom_url: null,
                                                        agency_user_data: [
                                                            {
                                                                index: 0,
                                                                id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                                                user_id: "",
                                                                master_user_id: "",
                                                                username: "",
                                                                email_address: "",
                                                                user_role_flag: true,
                                                            },
                                                        ],
                                                        agency_logo_gcs_id: null,
                                                        agency_favicon_gcs_id: null,
                                                        privacy_policy_gcs_id: null,
                                                        privacy_policy_content: null,
                                                        agency_privacy_policy: {
                                                            privacy_policy_id: null,
                                                            privacy_policy_name: "",
                                                            privacy_policy_file_name: "",
                                                            privacy_policy_gcs_url: "",
                                                            privacy_policy_file_size: "",
                                                            privacy_policy_content: ""
                                                        }

                                                    })
                                                    setFileSelected(null)
                                                    setFileUploaded(false)
                                                    setAgencyFavIcon(null)
                                                    setFavIconFileUploaded(false)
                                                    setAgencyHtmlContent(null)
                                                    setToggleDetails("agency_details")
                                                    navigate('/agency/add_agency')
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                New Agency
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Search>
                                                <SearchIconWrapper>
                                                    <SearchIcon />
                                                </SearchIconWrapper>
                                                <Input
                                                    placeholder={"Search"}
                                                    onChange={(e) => handleSearchBar(e.target.value)}
                                                />
                                            </Search>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* DIVIDER */}
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        {/* TABLE */}
                        <Grid item xs={12}>
                            <Grid container className='ag-table-main-container'>
                                <Grid item xs={12}
                                    sx={{
                                        height: "calc(100vh - 220px)",
                                        overflow: 'auto',
                                        // height: '100%'
                                    }}
                                >
                                    <CustomDataGrid
                                        className='ag-datagrid'
                                        rows={searchRowDetails}
                                        columns={dataGridColumn}
                                        disableColumnMenu={true}
                                        rowSelection={false}
                                        disableColumnSelector
                                        disableSelectionOnClick
                                        density="standard"
                                        getRowId={(row) => (row.agency_id)}
                                        onCellClick={(params) => { navigate(`/agency/view_agency?agency_id=${params.row.agency_id}`) }}
                                        experimentalFeatures={{ newEditingApi: true }}
                                        pageSize={pageSize}  // Set the number of rows per page
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowsPerPageOptions={[5, 10, 15, 20]}  // Options for changing the page size
                                        pagination
                                        sx={{
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                                                outline: "none !important",
                                            },
                                            "& .MuiDataGrid-sortIcon": {
                                                opacity: "1 !important",
                                                color: "white !important"
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                borderTop: "none"
                                            }
                                        }}
                                        components={{
                                            Pagination: CustomPagination,
                                            NoRowsOverlay: () => (
                                                <Stack height="100%" alignItems="center" justifyContent="center">
                                                    {"No Data Found!"}
                                                </Stack>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Agency