import Axios from "../axios"
export const getAgencyData = async (agency_id, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/get-agency-data?agency_id=${agency_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const getAgencyDataList = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/get-agency-data-list`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const checkAgencyExistence = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/check-agency-existence?agency_name=${data.agency_name}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}


export const checkAgencyURLExistence = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/check-agency-url-existence?agency_url=${data.agency_url}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const UploadFileAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`/storage/file`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const DeleteFileAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete(`/storage/file?resource_gcs_data_id=${data?.resource_gcs_data_id}`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const createNewAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/create-agency`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const updateAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put(`agency/update-agency-data`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const addUpdateAgencyCarrier = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/manage-agency-carrier`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const AddAgencyLogo = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/global/get-company-logo`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const verifyAgencyWhiteLabelURL = async (agency_id, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/dns-lookup?agency_id=${agency_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const activateAgencyApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/activate-agency`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}


// CARRIER SECTION:

export const GetAgencyTemplateDataList = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/template/get-template-data-list?agency_id=${data.agency_id}`);
        onSuccess && onSuccess(response);
    } catch (err) {
        console.log()
        onError && onError(err);
    }
};

export const AssignTemplateToAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/agency/assign-template-to-agency", data);
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetAgencyCarrierData = async (agency_id, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/get-agency-carrier-data?agency_id=${agency_id}`);
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const RemoveTemplateToAgencyAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete(`agency/remove-template-assigned-to-agency?agency_id=${data?.agency_id}&carrier_type_id=${data.carrier_type_id}`);
        console.log("response of RemoveTemplateToAgencyAPI", response);
        onSuccess && onSuccess(response);
    } catch (err) {
        console.log("error of RemoveTemplateToAgencyAPI", err);
        onError && onError(err);
    }
};


export const RemoveAgnecyCommissionDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete(`agency/remove-agency-commission-data?agency_id=${data?.agency_id}&agent_commission_data_id=${data.agent_commission_data_id}&remove_all_flag=${data.remove_all_flag}`);
        console.log("response of RemoveAgnecyCommissionDataAPI", response);
        onSuccess && onSuccess(response);
    } catch (err) {
        console.log("error of RemoveAgnecyCommissionDataAPI", err);
        onError && onError(err);
    }
};

export const CreateUpdateAgencyCarrierDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put(`agency/update-agency-carrier-data`, data);
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetAgencyCarrierLevelList = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/get-carrier-level-list?carrier_id=${data}`,)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

// CARRIER SECTION:


// WHITELISTED DOMAIN :

export const getAgencyWhiteListedDomainAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/domain-whitelist?agency_id=${data.agency_id}`)
        console.log("response of getAgencyWhiteListedDomainAPI", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("error of getAgencyWhiteListedDomainAPI", err);
        onError && onError(err);
    }
}

export const manageAgencyWhiteListedDomainAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/domain-whitelist`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        console.log("response of manageAgencyWhiteListedDomainAPI", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("error of manageAgencyWhiteListedDomainAPI", err);
        onError && onError(err);
    }
}

export const deleteWhiteLabelDomainAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete(`agency/domain-whitelist?agency_id=${data?.agency_id}`);
        console.log("response of deleteWhiteLabelDomainAPI", response);
        onSuccess && onSuccess(response);
    } catch (err) {
        console.log("error of deleteWhiteLabelDomainAPI", err);
        onError && onError(err);
    }
};

export const validateWhitelistDomain = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/validate-whitelist-domain`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        console.log("response of validateWhitelistDomain", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("error of validateWhitelistDomain", err);
        onError && onError(err);
    }
}

// WHITELISTED DOMAIN :


// BA TEMPLATE:

export const getRemaingCarrierListAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`agency/get-remaining-carrier-list?agency_id=${data.agency_id}&template_id=${data.template_id}`)
        console.log("response of getRemaingCarrierListAPI", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("error of getRemaingCarrierListAPI", err);
        onError && onError(err);
    }
}


export const GetTemplateDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/template/get-template-data?template_id=${data.template_id}&agency_id=${data.agency_id}`)
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};


// BA TEMPLATE: