import createDataContext from "./createDataContext";

const AgencyReducer = (state, action) => {
    switch (action.type) {
        case "carrierListData": {
            return { ...state, carrierListData: action.payload }
        }
        case "agencyDetails": {
            return { ...state, agencyDetails: action.payload }
        }

        case "agencyCarrieData": {
            return { ...state, agencyCarrierData: action.payload }
        }
        case "fileSelectedData": {
            return { ...state, fileSelected: action.payload }
        }
        case "flagFileUploaded": {
            return { ...state, fileUploaded: action.payload }
        }
        case "favIconData": {
            return { ...state, agencyFavIcon: action.payload }
        }
        case "flagFavIconFileUploaded": {
            return { ...state, favIconFileUploaded: action.payload }
        }
        case "agencyTemplateDataList": {
            return { ...state, agencyTemplateDataList: action.payload }
        }
        case "agencyCarrierDetails": {
            return { ...state, agencyCarrierDetails: action.payload }
        }
        case "agencyCarrierLevelList": {
            return { ...state, agencyCarrierLevelList: action.payload }
        }
        case "setHtmlFileSelected": {
            return { ...state, htmlFileSelected: action.payload }
        }
        case "setHtmlFileUploaded": {
            return { ...state, htmlFileUploaded: action.payload }
        }
        case "setAgencyHtmlContent": {
            return { ...state, agencyHtmlContent: action.payload }
        }
        case "setAgencyHtmlFile": {
            return { ...state, agencyHtmlFile: action.payload }
        }
        case "deletedFilesId": {
            return { ...state, deletedFilesId: action.payload }
        }
        case "setAgencyWhiteListedDomainDetails": {
            return { ...state, agencyWhiteListedDomainDetails: action.payload }
        }
        case "setCreateAgencyId": {
            return { ...state, createAgencyId: action.payload }
        }
        case "setDomainProcess": {
            return { ...state, domainProcess: action.payload }
        }
        case "setAgencyTemplateList": {
            return { ...state, agencyTemplateList: action.payload }
        }
        case "setAgencyTemplateData": {
            return { ...state, agencyTemplateData: action.payload }
        }
        case "setAgencyTemplateDetails": {
            return { ...state, agencyTemplateDetails: action.payload }
        }
        case "setCloneTemplateDetails": {
            return { ...state, cloneTemplateDetails: action.payload }
        }
        case "setToggleDetails": {
            return { ...state, toggleDetails: action.payload }
        }
        case "setExistingAgencyAgents": {
            return { ...state, existingAgencyAgents: action.payload }
        }
        case "setAgencyDuplicateTemplateDetails": {
            return { ...state, agencyDuplicateTemplateDetails: action.payload }
        }
        case "setFlagRenderTableData": {
            return { ...state, flagRenderTableData: action.payload }
        }
        case "setSubagencyDuplicateTemplateDetails": {
            return { ...state, subagencyDuplicateTemplateDetails: action.payload }
        }
    }
};

const setCarrierListData = (dispatch) => async (data) => {
    dispatch({
        type: "carrierListData",
        payload: data
    });
}


const setAgencyDetails = (dispatch) => async (data) => {
    dispatch({
        type: "agencyDetails",
        payload: data
    });
}

const setAgencyCarrieData = (dispatch) => async (data) => {
    dispatch({
        type: "agencyCarrieData",
        payload: data
    });
}

const setFileSelected = (dispatch) => async (data) => {
    dispatch({
        type: "fileSelectedData",
        payload: data
    });
}

const setFileUploaded = (dispatch) => async (data) => {
    dispatch({
        type: "flagFileUploaded",
        payload: data
    });
}

const setAgencyFavIcon = (dispatch) => async (data) => {
    dispatch({
        type: "favIconData",
        payload: data
    });
}

const setFavIconFileUploaded = (dispatch) => async (data) => {
    dispatch({
        type: "flagFavIconFileUploaded",
        payload: data
    });
}

const setAgencyTemplateDataList = (dispatch) => async (data) => {
    dispatch({
        type: "agencyTemplateDataList",
        payload: data
    });
}

const setAgencyCarrierDetails = (dispatch) => async (data) => {
    dispatch({
        type: "agencyCarrierDetails",
        payload: data
    });
}

const setAgencyCarrierLevelList = (dispatch) => async (data) => {
    dispatch({
        type: "agencyCarrierLevelList",
        payload: data
    });
}

const setHtmlFileSelected = (dispatch) => async (data) => {
    dispatch({
        type: "setHtmlFileSelected",
        payload: data
    });
}

const setHtmlFileUploaded = (dispatch) => async (data) => {
    dispatch({
        type: "setHtmlFileUploaded",
        payload: data
    });
}

const setAgencyHtmlContent = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyHtmlContent",
        payload: data
    });
}
const setAgencyHtmlFile = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyHtmlFile",
        payload: data
    });
}
const setDeletedFilesIds = (dispatch) => async (data) => {
    dispatch({
        type: "deletedFilesId",
        payload: data
    });
}


const setAgencyWhiteListedDomainDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyWhiteListedDomainDetails",
        payload: data
    });
}

const setCreateAgencyId = (dispatch) => async (data) => {
    dispatch({
        type: "setCreateAgencyId",
        payload: data
    });
}
const setDomainProcess = (dispatch) => async (data) => {
    dispatch({
        type: "setDomainProcess",
        payload: data
    });
}

const setAgencyTemplateDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyTemplateDetails",
        payload: data
    });
}
const setCloneTemplateDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setCloneTemplateDetails",
        payload: data
    });
}
const setToggleDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setToggleDetails",
        payload: data
    });
}
const setAgencyTemplateList = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyTemplateList",
        payload: data
    });
}
const setAgencyTemplateData = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyTemplateData",
        payload: data
    });
}
const setExistingAgencyAgents = (dispatch) => async (data) => {
    dispatch({
        type: "setExistingAgencyAgents",
        payload: data
    });
}
const setAgencyDuplicateTemplateDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setAgencyDuplicateTemplateDetails",
        payload: data
    });
}

const setFlagRenderTableData = (dispatch) => async (data) => {
    dispatch({
        type: "setFlagRenderTableData",
        payload: data
    });
}
const setSubagencyDuplicateTemplateDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setSubagencyDuplicateTemplateDetails",
        payload: data
    });
}

export const { Provider, Context, state } = createDataContext(
    AgencyReducer,
    {
        setCarrierListData,
        setAgencyDetails,
        setAgencyCarrieData,
        setFileSelected,
        setFileUploaded,
        setAgencyFavIcon,
        setFavIconFileUploaded,
        setAgencyTemplateDataList,
        setAgencyCarrierDetails,
        setAgencyCarrierLevelList,
        setHtmlFileSelected,
        setHtmlFileUploaded,
        setAgencyHtmlContent,
        setAgencyHtmlFile,
        setDeletedFilesIds,
        setAgencyWhiteListedDomainDetails,
        setCreateAgencyId,
        setDomainProcess,
        setAgencyTemplateDetails,
        setAgencyDuplicateTemplateDetails,
        setSubagencyDuplicateTemplateDetails,
        setCloneTemplateDetails,
        setToggleDetails,
        setAgencyTemplateData,
        setAgencyTemplateList,
        setExistingAgencyAgents,
        setFlagRenderTableData,
    },
    {

        // --------BA TEMPLATES--------------
        agencyTemplateDetails: [],
        agencyDuplicateTemplateDetails: [],
        subagencyDuplicateTemplateDetails: [],

        cloneTemplateDetails: null,
        // --------BA TEMPLATES--------------
        // agencyData : 

        toggleDetails: 'agency_details',
        agencyDropdownList: [],
        carrierListData: { life: [], annuity: [] },
        agencyDetails: {
            agency_name: "",
            agency_email_address: "",
            agency_mag_url: "",
            agency_custom_url: null,
            agency_user_data: [
                {
                    index: 0,
                    id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    user_id: "",
                    master_user_id: "",
                    username: "",
                    email_address: "",
                    user_role_flag: true,
                    agent_id: null
                },
            ],
            agency_logo_gcs_id: null,
            agency_favicon_gcs_id: null,
            agency_privacy_policy: {
                privacy_policy_id: null,
                privacy_policy_gcs_id: null,
                privacy_policy_name: "",
                privacy_policy_file_name: "",
                privacy_policy_gcs_url: "",
                privacy_policy_file_size: "",
                privacy_policy_content: ""
            }
        },
        agencyCarrierData: [], //already checked data,
        fileSelected: null,
        fileUploaded: false,
        agencyFavIcon: null,
        favIconFileUploaded: false,

        // FOR HTML FILE UPLOAD
        htmlFileSelected: null,
        htmlFileUploaded: false,
        agencyHtmlFile: null,
        agencyHtmlContent: null,
        agencyTemplateDataList: { life: [], annuity: [] },
        agencyCarrierDetails: {},
        agencyCarrierLevelList: { life: [], annuity: [] },
        deletedFilesId: { logoIds: [], favIconIds: [], privacyPolicyIds: [] },

        // --------WHITE LISTED DOMAIN--------------
        createAgencyId: null,
        agencyWhiteListedDomainDetails: null,
        domainProcess: {
            editing: false,
            continue: false,
            showDomainQue: false,
            proceedYesButton: false
        },
        // --------WHITE LISTED DOMAIN--------------
        // Agency Tenplate Component States 
        agencyTemplateList: [],
        agencyTemplateData: [],

        //---------Existing Agency Agent details------
        //Existing agency Agents array
        existingAgencyAgents: [],

        flagRenderTableData: false
    }
)