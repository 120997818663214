import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, useRoutes } from "react-router-dom";
// import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { Provider } from "./contexts/reducerContexts/authContext";
import { Provider as TemplateProvider } from "./contexts/reducerContexts/templateContext";
import { Provider as AgentProvider } from "./contexts/reducerContexts/agentContext";
import { Provider as AgencyProvider } from "./contexts/reducerContexts/agencyContext"
import { Provider as AgencyDetailsProvider } from "./contexts/reducerContexts/agencyContext"

import GlobalRoutes from "./routes";
import SignIn from "./components/auth/SignIn";
import { AuthContext, AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

// comment

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  // const content = useRoutes(routes);

  const { theme } = useTheme();


  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s " defaultTitle="myAdvisorGrids" />
        {/* <Provider        store={store}> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              {/* <AuthContext> */}
              <Provider>
                <AgencyProvider>
                  <AgencyDetailsProvider>
                    <TemplateProvider>
                      <AgentProvider>
                        <GlobalRoutes />
                      </AgentProvider>
                    </TemplateProvider>
                  </AgencyDetailsProvider>
                </AgencyProvider>
              </Provider>
              {/* </AuthContext> */}
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
        {/* </Provider> */}
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
