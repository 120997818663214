import { Button, Dialog, Grid, Typography } from "@mui/material";
import deleteAlert from "../../../assets/Delete-alert.png";
import "../../css/ViewCarriarStyles/DeleteCarrierLevelPopUp.css"
import { FocusTrap } from "@mui/base";

export default function DeleteCarrierLevelPopUp(props) {

    // console.log("propssssss", props)

    const handleLevelDelete = () => {
        // debugger;
        let props_carrier_level_id = props.levelSelectedData.carrier_level_id || props.levelSelectedData.carrier_level_id_custom;
        let findLevelIdx = { ...props?.carrierDetails }.carrier_level.findIndex(level => level.carrier_level_id == props_carrier_level_id || level?.carrier_level_id_custom == props_carrier_level_id);

        let newCarrierLevel = { ...props.carrierDetails }.carrier_level
        newCarrierLevel?.splice(findLevelIdx, 1);

        props?.carrierDetails.carrier_level_data?.map((level) => {
            level?.carrier_group_data?.map((group) => {
                group?.carrier_commission_data?.splice(findLevelIdx, 1)
            })
        })

        props?.setCarrierDetails({ ...props.carrierDetails, carrier_level: newCarrierLevel })
        props.setFlagDialogGloballyRender(false)

    }

    const {
        flagCarrierCustomizationDeleteDialog,
        setFlagCarrierCustomizationDeleteDialog,
        flagCarrierCustomizationGlobalDeleteDialog,
        setFlagCarrierCustomizationGlobalDeleteDialog,
        setFlagDialogGloballyRender,
        setHideTransperentCell
    } = props

    return (
        <FocusTrap open={true} disableEnforceFocus={true}>
            <Dialog
                open={true}
                // maxWidth="sm"
                container={() => document.getElementById('custom-grid')}
                onClose={() => {
                    setFlagCarrierCustomizationGlobalDeleteDialog(false)
                    setFlagCarrierCustomizationDeleteDialog(false)
                    setFlagDialogGloballyRender(false)
                }}
                className='vw-delete-level-dialog'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEnforceFocus={true}
            >
                <Grid container>
                    <Grid item xs={12} className="dclp-item-1">
                        <Typography>
                            Delete Carrier Level
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="dclp-item-2" sx={{ margin: "5px 0px" }}>
                        <img src={deleteAlert} alt="del img" className="dclp-item-2-img" />
                    </Grid>
                    <Grid item xs={12} className="dclp-item-3" sx={{ margin: "5px 0px" }}>
                        <Grid container sx={{ margin: "10px 0px" }}>
                            <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                <Typography className="dclp-info-typo">
                                    Once you proceed with the deletion, the following actions will take place:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                <Typography className="dclp-alert-typo">
                                    All templates and users associated with this carrier level will be removed.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ margin: "15px 0px 40px" }}>
                        <Grid container sx={{ justifyContent: "center", gap: "20px" }}>
                            <Grid item sx={{ width: "130px", maxWidth: "130px", textAlign: "end" }}>
                                <Button
                                    fullWidth
                                    className="dclp-btn"
                                    variant="contained"
                                    onClick={() => {
                                        setFlagCarrierCustomizationGlobalDeleteDialog(false)
                                        setFlagCarrierCustomizationDeleteDialog(false)
                                        handleLevelDelete()
                                        setHideTransperentCell(true)
                                    }}
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item sx={{ width: "130px", maxWidth: "130px" }}>
                                <Button
                                    fullWidth
                                    className="dclp-alert-btn"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setFlagCarrierCustomizationGlobalDeleteDialog(false)
                                        setFlagCarrierCustomizationDeleteDialog(false)
                                        props.setFlagDialogGloballyRender(false)
                                        setHideTransperentCell(true)
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </FocusTrap>

    )
}