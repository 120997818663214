import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  User,
} from "react-feather";
import { ReactComponent as Agent } from "../../assets/agents.svg";
import { ReactComponent as Carrier } from "../../assets/carriers.svg";
import { ReactComponent as Logo } from "../../assets/levels.svg";
import { ReactComponent as Template } from "../../assets/templates.svg";

import AgentIcon from "../../assets/agent-icon.png"
import CarrierIcon from "../../assets/carrier-icon.png"
import TemplateIcon from "../../assets/template-icon.png"
import BATeamIcon from "../../assets/ba-team-icon.png"
import ProfileIcon from "../../assets/profile-icon.png"
import AgencyLogo from "../../assets/AgencyLogo.png"

const AgentIconComponent = () => {
  return <img src={AgentIcon} />
}

const CarrierIconComponent = () => {
  return <img src={CarrierIcon} />
}

const TemplateIconComponent = () => {
  return <img src={TemplateIcon} />
}

const BATeamIconComponent = () => {
  return <img src={BATeamIcon} />
}

const ProfileIconComponent = () => {
  return <img src={ProfileIcon} />
}

const AgencyIconComponent = () => {
  return <img src={AgencyLogo} />
}



const agentPageSection = [

  {
    href: "/carriers",
    icon: Carrier,
    title: "Carriers",
  },

  {
    href: "/tasks",
    icon: User,
    title: "Profile",
    // badge: "17",
  },

];

const elementsSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Components",
    children: [
      {
        href: "/components/alerts",
        title: "Alerts",
      },
      {
        href: "/components/accordion",
        title: "Accordion",
      },
      {
        href: "/components/avatars",
        title: "Avatars",
      },
      {
        href: "/components/badges",
        title: "Badges",
      },
      {
        href: "/components/buttons",
        title: "Buttons",
      },
      {
        href: "/components/cards",
        title: "Cards",
      },
      {
        href: "/components/chips",
        title: "Chips",
      },
      {
        href: "/components/dialogs",
        title: "Dialogs",
      },
      {
        href: "/components/lists",
        title: "Lists",
      },
      {
        href: "/components/menus",
        title: "Menus",
      },
      {
        href: "/components/pagination",
        title: "Pagination",
      },
      {
        href: "/components/progress",
        title: "Progress",
      },
      {
        href: "/components/snackbars",
        title: "Snackbars",
      },
      {
        href: "/components/tooltips",
        title: "Tooltips",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
    children: [
      {
        href: "/charts/chartjs",
        title: "Chart.js",
      },
      {
        href: "/charts/apexcharts",
        title: "ApexCharts",
      },
    ],
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/pickers",
        title: "Pickers",
      },
      {
        href: "/forms/selection-controls",
        title: "Selection Controls",
      },
      {
        href: "/forms/selects",
        title: "Selects",
      },
      {
        href: "/forms/text-fields",
        title: "Text Fields",
      },
      {
        href: "/forms/editors",
        title: "Editors",
      },
      {
        href: "/forms/formik",
        title: "Formik",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
    children: [
      {
        href: "/tables/simple-table",
        title: "Simple Table",
      },
      {
        href: "/tables/advanced-table",
        title: "Advanced Table",
      },
      {
        href: "/tables/data-grid",
        title: "Data Grid",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    children: [
      {
        href: "/icons/material-icons",
        title: "Material Icons",
      },
      {
        href: "/icons/feather-icons",
        title: "Feather Icons",
      },
    ],
  },
  {
    href: "/maps",
    icon: Map,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
];

const docsSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Documentation",
  },
  {
    href: "/changelog",
    icon: List,
    title: "Changelog",
    badge: "v4.2.1",
  },
];

// ----------------------------------------------------------------------------------------------------
const pagesSection = [
  {
    href: "/",
    icon: AgentIconComponent,
    title: "Agents",
  },
  {
    href: "/carriers",
    icon: CarrierIconComponent,
    title: "Carriers",
  },
  {
    href: "/templates",
    icon: TemplateIconComponent,
    title: "Templates",
  },
  {
    href: "/users",
    icon: BATeamIconComponent,
    title: "Users",
  },
  {
    href: "/agency",
    icon: AgencyIconComponent,
    title: "Agency",
  },
  // {
  //   href: "/tasks",
  //   icon: ProfileIconComponent,
  //   title: "Profile",
  // },
];

const agentPagesSection = [
  {
    href: "/",
    icon: CarrierIconComponent,
    title: "Carriers",
  },
  // {
  //   href: "/profile",
  //   icon: ProfileIconComponent,
  //   title: "Profile",
  // },
];

const userPagesSection = [
  {
    href: "/",
    icon: AgentIconComponent,
    title: "Agents",
  },
  {
    href: "/carriers",
    icon: CarrierIconComponent,
    title: "Carriers",
  },
  {
    href: "/templates",
    icon: TemplateIconComponent,
    title: "Templates",
  }
];

const subAgencyPagesSection = [
  {
    href: "/",
    icon: AgentIconComponent,
    title: "Agents",
  },
  {
    href: "/carriers",
    icon: CarrierIconComponent,
    title: "Carriers",
  },
  {
    href: "/templates",
    icon: TemplateIconComponent,
    title: "Templates",
  },
  {
    href: "/agency",
    icon: BATeamIconComponent,
    title: "Agency",
  },
];

const itemWoAgency = [
  {
    href: "/",
    icon: AgentIconComponent,
    title: "Agents",
  },
  {
    href: "/carriers",
    icon: CarrierIconComponent,
    title: "Carriers",
  },
  {
    href: "/templates",
    icon: TemplateIconComponent,
    title: "Templates",
  },
  {
    href: "/users",
    icon: BATeamIconComponent,
    title: "Users",
  },

];
// ----------------------------------------------------------------------------------------------------


const navItems = [
  {
    title: "",
    pages: pagesSection,
    agentPages: agentPagesSection,
    userPages: userPagesSection,
    subAgencyPages: subAgencyPagesSection,
    itemWithoutAgency: itemWoAgency
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default navItems;
