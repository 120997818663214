import { Button, Grid, Typography } from "@mui/material";
import "../../css/Agency/ApprovalWaiting.css"
import timer from "../../../assets/timer.gif"
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ApprovalWaiting = () => {
    const navigate = useNavigate();


    useEffect(() => {
        navigate("/");
    }, [])

    return (
        <Grid container className="aw-main-container">
            <Grid item xs={10} sx={{ margin: "100px auto" }}>
                <Grid container className="aw-main-content-conatiner">

                    <Grid item xs={12} sm={12} md={12} lg={10} xl={7}>
                        <img src={timer} alt="timer" />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={10} xl={7}>
                        <Typography className="aw-main-heading">
                            Waiting for Approval, Currently Inactive
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={10} xl={7} className="aw-golbal-itme" >
                        <Typography component="span" sx={{ fontSize: "15px" }}>
                            We regret to inform you that this agency is
                            <span style={{ color: "#E10202" }}> currently inactive</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={10} xl={7} className="aw-golbal-itme" >
                        <Typography component="span" sx={{ fontSize: "15px" }}>
                            Our team is diligently reviewing it for approval, ensuring the highest standards of service and reliability.
                            We kindly ask for your patience during this process. Rest assured, once the URL is approved, you will
                            gain full access to the agency's site.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={10} xl={7} className="aw-golbal-itme" >
                        <Button variant="contained">
                            Send Reminder
                        </Button>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}
export default ApprovalWaiting;