import Axios from "../axios";

export const GetBAMemberList = async (onSuccess, onError) => {
  try {
    const response = await Axios.get("/user/get-member-list");
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const AddNewBaMemberApi = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/user/add-new-member", data)
    onSuccess && onSuccess(response)
  } catch (err) {
    onError && onError(err);
  }
}
export const UpdateBaMemberApi = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.put("/user/update-user-data", data)
    onSuccess && onSuccess(response)
  } catch (err) {
    onError && onError(err);
  }
}
export const DeleteBaMemberApi = async (userId, onSuccess, onError) => {
  try {
    const response = await Axios.delete("/user/remove-member?user_id=" + userId)
    onSuccess && onSuccess(response)
  } catch (err) {
    onError && onError(err);
  }
}

export const assignAgencyManagerToUser = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/user/add-agency-manager", data)
    onSuccess && onSuccess(response)
  } catch (err) {
    onError && onError(err);
  }
}
