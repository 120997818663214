import React from 'react'
import { Button, Divider, Box, Typography, Grid, TextField, useMediaQuery, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styled from '@emotion/styled'
import "../css/AddCarrier.css"


const dummy = [
    {
        carrier_detail_id: 85,
        carrier_detail_field_id: 1,
        carrier_detail_field_value: "Daily",
        carrier_detail_field_name: "Cut Off",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 1
    },
    {
        carrier_detail_id: 87,
        carrier_detail_field_id: 2,
        carrier_detail_field_value: "Daily",
        carrier_detail_field_name: "Release Date",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 2
    },
    {
        carrier_detail_id: 88,
        carrier_detail_field_id: 3,
        carrier_detail_field_value: "Check: $50; EFT: $10",
        carrier_detail_field_name: "Min Ck / Min EFT",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 3
    },
    {
        carrier_detail_id: 90,
        carrier_detail_field_id: 4,
        carrier_detail_field_value: "N/A",
        carrier_detail_field_name: "Commissionable Policy",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 4
    },
    {
        carrier_detail_id: 91,
        carrier_detail_field_id: 5,
        carrier_detail_field_value: "Unearned; Death Claim 1-24 mos 100%",
        carrier_detail_field_name: "Chargeback Schedule",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 5
    },
    {
        carrier_detail_id: 93,
        carrier_detail_field_id: 6,
        carrier_detail_field_value: "2,000.00 max per case",
        carrier_detail_field_name: "Advancement Cap",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 6
    },
    {
        carrier_detail_id: 94,
        carrier_detail_field_id: 7,
        carrier_detail_field_value: "800-736-7311",
        carrier_detail_field_name: "Commission Contact",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 7
    },
    {
        carrier_detail_id: 96,
        carrier_detail_field_id: 8,
        carrier_detail_field_value: "11/22/2013",
        carrier_detail_field_name: "Carrier Grids Dated",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 8
    },
    {
        carrier_detail_id: 97,
        carrier_detail_field_id: 9,
        carrier_detail_field_value: null,
        carrier_detail_field_name: "Other Notes:",
        carrier_detail_flag_internal_info: false,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 9
    },
]
const rightDummy = [
    {
        carrier_detail_id: 100,
        carrier_detail_field_id: 10,
        carrier_detail_field_value: "95",
        carrier_detail_field_name: "Street Level/SGA Level",
        carrier_detail_flag_internal_info: true,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 10
    },
    {
        carrier_detail_id: 103,
        carrier_detail_field_id: 11,
        carrier_detail_field_value: "125",
        carrier_detail_field_name: "BA Level",
        carrier_detail_flag_internal_info: true,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 11
    },
    {
        carrier_detail_id: 106,
        carrier_detail_field_id: 12,
        carrier_detail_field_value: "One Life",
        carrier_detail_field_name: "Upline",
        carrier_detail_flag_internal_info: true,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 12
    },
    {
        carrier_detail_id: 109,
        carrier_detail_field_id: 13,
        carrier_detail_field_value: "139086",
        carrier_detail_field_name: "BA Appt Number",
        carrier_detail_flag_internal_info: true,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 13
    },
    {
        carrier_detail_id: 112,
        carrier_detail_field_id: 14,
        carrier_detail_field_value: "https:/drive.google.com/drive/folders/1cpWeJLYYco6JHNBIFUtuZL6KR4UiekZz",
        carrier_detail_field_name: "Drive Folder",
        carrier_detail_flag_internal_info: true,
        carrier_detail_flag_show_field: true,
        carrier_detail_field_sequence: 14
    }
]
const TableHeadings = [
    'Types',
    'State',
    'Product',
    'Sub Name',
    'BA Total Comp'
]


const CustomLabelTypography = styled(Typography)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding-top:4.5px
`;

const AddCarrier = () => {
    const minScreenWidth = useMediaQuery('(max-width:1280px)')
    return (
        <Grid container rowGap={5} padding={'0px 20px'}>
            {/* _____________NAVIGATION SECTION START_________________ */}
            <Grid item xs={12} className='AC-item-Navigation'>
                <Grid rowGap={4} container>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="AC-buttonHeading-Box">
                            <Box sx={{ maxWidth: '500px' }}>
                                <Typography className='AC-Heading'>
                                    AGL
                                </Typography>
                            </Box>

                            <Button
                                className='AC-Nav-Button'
                            >
                                Annuity
                            </Button>
                        </Box>

                    </Grid>
                    <Grid xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: 'end' }}>
                        <Box className="AC-Nav-CancelSave-ButtonBox">
                            <Button
                                className='AC-Cancel-Button'
                                sx={{ padding: "6px 22px" }}
                                variant='outlined'
                            >
                                Cancel
                            </Button>
                            <Button
                                className='AC-Blue-Button'
                            >
                                Create
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {/* _____________NAVIGATION SECTION END____________________ */}
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {/* _____________MAIN SECTION START_________________ */}
            <Grid item xs={12}>
                <Grid container className='AC-mainSection-Container'>
                    {/*_________________ Dettails Section Start_________________ */}
                    <Grid item xs={12}>
                        <Grid container rowGap={4}>
                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                <Grid container
                                    sx={{
                                        display: "grid",
                                        gridTemplateRows: "repeat(6, auto)",
                                        gridAutoFlow: "column",
                                        gridTemplateColumns: `repeat(${Math.ceil(dummy.length / 6)}, 1fr)`,
                                        gap: "10px",
                                        columnGap: '20px',
                                        '@media (max-width: 840px)': {
                                            gridTemplateRows: `repeat(${dummy?.length}, auto)`,
                                            gridTemplateColumns: "repeat(1, 1fr)"
                                        }
                                    }}
                                >
                                    {
                                        dummy.map((data, ind) => (

                                            (data?.carrier_detail_field_name !== "" && data !== undefined) &&
                                            <Grid item
                                                key={ind}
                                                sx={{ height: 'fit-content' }}>
                                                <Grid container
                                                    alignItems={"start"} >

                                                    <Grid item className="AC-Main-DetailsLable">
                                                        <CustomLabelTypography sx={{ color: "#7A7A7A" }}>
                                                            {data?.carrier_detail_field_name}
                                                        </CustomLabelTypography>
                                                    </Grid>

                                                    <Grid item className="AC-Main-DetailsContent">
                                                        <TextField
                                                            multiline
                                                            defaultValue={data?.carrier_detail_field_value}
                                                            variant="standard"
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: false,
                                                                style: { fontSize: "15px" },
                                                            }}
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid >
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={0.3}
                                className='AC-Main-Details-DividerItem' >
                                <Divider
                                    orientation={minScreenWidth ? 'horizontal' : 'vertical'}
                                    className='AC-Main-Details-Divider' />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={3.7}>
                                <Grid container rowGap={3}>
                                    {
                                        rightDummy?.map((row, index) =>
                                        (
                                            <Grid key={index} item xs={12}>
                                                <Grid container
                                                    className="AC-Main-InternalInfo-Container">

                                                    <Grid item
                                                        className="AC-Main-InternalInfo-ItmeLable" >

                                                        <CustomLabelTypography sx={{ color: "#7A7A7A", marginRight: "10px" }}>
                                                            {row?.carrier_detail_field_name}
                                                        </CustomLabelTypography>
                                                    </Grid>

                                                    <Grid item
                                                        className="AC-Main-internainfo-ItemContent">
                                                        {
                                                            row?.carrier_detail_field_name == 'Drive Folder' ?
                                                                (
                                                                    <TextField
                                                                        defaultValue={row?.carrier_detail_field_value}
                                                                        variant="standard"
                                                                        fullWidth
                                                                        multiline
                                                                        InputProps={{
                                                                            readOnly: false,
                                                                            style: { fontSize: "15px" },
                                                                        }}
                                                                    />

                                                                )
                                                                :
                                                                (
                                                                    <TextField
                                                                        defaultValue={row?.carrier_detail_field_value}
                                                                        variant="standard"
                                                                        fullWidth
                                                                        multiline
                                                                        InputProps={{
                                                                            readOnly: false,
                                                                            style: { fontSize: "15px" },
                                                                        }}
                                                                    />
                                                                )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    {/* _________________Dettails Section End_________________ */}


                    {/* _________________Table Section Start_________________ */}
                    <Grid item xs={12} className='AC-Main-Table-Item'>
                        <Grid container rowGap={4} className='AC-Main-Table-Container'>
                            <Grid item xs={12} className='AC-Main-Table-Container-Item-1' >
                                <Typography className='AC-Main-Table-Typo'>
                                    Levels
                                </Typography>
                                <Button
                                    className='AC-Blue-Button'
                                    startIcon={<AddCircleOutlineIcon />}>
                                    Level
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "15px", borderBottom: 'none' }}>

                                                </TableCell>
                                                {
                                                    TableHeadings.map((data, ind) => (
                                                        <TableCell key={ind}
                                                            size='small'
                                                            className="AC-TableHead-Cell"
                                                        >
                                                            {data}
                                                        </TableCell>
                                                    ))
                                                }
                                                <TableCell
                                                    size='small'
                                                    sx={{ border: ' 1px solid #c0c0c0', backgroundColor: " #1b2430" }}
                                                >

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ width: "15px", borderBottom: 'none' }}>

                                                </TableCell>
                                                {
                                                    TableHeadings.map((data, ind) => (
                                                        <TableCell key={ind}
                                                            size='small'
                                                            className='AC-TableBody-Cell'
                                                        >
                                                            sdkfj
                                                        </TableCell>
                                                    ))
                                                }
                                                <TableCell
                                                    size='small'
                                                    sx={{ backgroundColor: " #fff" }}
                                                >

                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* _________________Table Section End_________________ */}
                </Grid>
            </Grid>
            {/* _____________MAIN SECTION END_________________ */}
        </Grid >
    )
}

export default AddCarrier