import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// window.origin.indexOf("localhost") == -1
//   ? process.env.REACT_API_BASE_URL1
//   : process.env.REACT_API_BASE_URL;

axiosInstance.defaults.headers = {
  "Content-Type": "application/json",
};
axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => error
);

// Promise.reject(
//   (error.response && error.response.data) || "Something went wrong"
// )
export default axiosInstance;
