import { LoginApi, SendOtpApi, UserMeApi } from "../../utils/api/userDetailApi";
import CreateDataContext from "./createDataContext";

const TemplateReducer = (state, action) => {
  switch (action.type) {
    case "setCarrierLevelData": {
      return {
        ...state,
        carrierLevelData: action.payload,
      };
    }
    case "flagTemplate": {
      return {
        ...state,
        flagTemplate: action.payload,
      };
    }
    case "templateName": {
      return {
        ...state,
        templateName: action.payload,
      };
    }
    case "templateData": {
      return {
        ...state,
        templateData: action.payload,
      };
    }
    case "carrierData": {
      return {
        ...state,
        carrierData: action.payload,
      };
    }
    case "setSelectedCarrier": {
      return {
        ...state,
        selectedCarrier: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};


const setCarrierLevelData = (dispatch) => async (data) => {
  dispatch({
    type: "setCarrierLevelData",
    payload: data,
  });
};

const setSelectedCarrier = (dispatch) => async (data) => {
  dispatch({
    type: "setSelectedCarrier",
    payload: data,
  });
};

const setFlagTemplate = (dispatch) => async (data) => {
  dispatch({
    type: "flagTemplate",
    payload: data,
  });
};
const setTemplateName = (dispatch) => async (data) => {
  dispatch({
    type: "templateName",
    payload: data,
  });
};
const setTemplateData = (dispatch) => async (data) => {
  dispatch({
    type: "templateData",
    payload: data,
  });
};
const setCarrierData = (dispatch) => async (data) => {
  dispatch({
    type: "carrierData",
    payload: data,
  });
};

export const { Provider, Context, state } = CreateDataContext(
  TemplateReducer,
  {
    setFlagTemplate,
    setCarrierLevelData,
    setSelectedCarrier,
    setTemplateName,
    setCarrierData,
    setTemplateData,
  },

  {
    flagTemplate: null,
    carrierData: [{}],
    templateName: "Untitled",
    carrierLevelData: [],
    selectedCarrier: [],
    templateData: [
      {
        template_name: "Untitled",
        template_data: []
      }
    ]
  }
);
