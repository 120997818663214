import React, { useState, useContext, useEffect, } from 'react'
import {
    Grid, Typography, TextField,
    Button, IconButton, Checkbox, Box,
    LinearProgress,
    FormControl,
    Select,
    MenuItem,
    Tooltip,
    Divider,
    useMediaQuery,
    Menu,
    Popover,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import styled from '@emotion/styled'

import "../../css/Agency/AgencyDetails.css"
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// ASSETS
import greenCheckimg from "../../../assets/greenCheck.png"
import error from "../../../assets/error.png"
import FileUploadIcon from "../../../assets/file-upload-icon.png";
import loadedFile from "../../../assets/loadedfile.png";
import DarkDeleteImg from "../../../assets/Darkdelete.png"
import LightDaleteImg from "../../../assets/lightDlete.png"

//ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

// CONTEXT AND FUNCTIONS
import { Context as authContext } from '../../../contexts/reducerContexts/authContext'
import { DeleteResources, UploadResources } from '../../../utils/api/TemplateApi'
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DeleteFileAgency, UploadFileAgency, checkAgencyExistence, checkAgencyURLExistence, createNewAgency, updateAgency } from '../../../utils/api/AgencyDetailsAPI'
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";

import { useNavigate, useSearchParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import htmlIcon from '../../../assets/html-file-icon.png'
import VisibilityIcon from '@mui/icons-material/Visibility';
import AgencyPrivacyPolicyPreview from './AgencyDialogs/AgencyPrivacyPolicyPreview'
import GlobalDialog from '../../../components/global/GlobalDialog'
import ExistingAgencyAgentDialog from "./AgencyDialogs/ExistingAgencyAgentDialog";

const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  ,
  .MuiDataGrid-iconSeparator
  {
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;


const AgencyDetails = (props) => {

    const {
        setCheckLogoFavId,
        checkLogoFavId,
        setCustomUrlChecked,
        customUrlChecked,
        isValidHtml,
        setIsDisabled
    } = props

    const pathName = window.location.pathname
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const {
        FlagApiLoader,
        UpdateSnackbarMessage,
    } = useContext(authContext)

    const {
        agencyDetails,
        fileSelected,
        fileUploaded,
        agencyFavIcon,
        favIconFileUploaded,

        htmlFileSelected,
        htmlFileUploaded,
        agencyHtmlFile,
        agencyHtmlContent,
        deletedFilesId,
        createAgencyId,
        agencyDropdownList
    } = useContext(agencyContext).state

    const {
        setAgencyDetails,
        setFileSelected,
        setFileUploaded,
        setAgencyFavIcon,
        setFavIconFileUploaded,

        setHtmlFileSelected,
        setHtmlFileUploaded,
        setAgencyHtmlContent,
        setAgencyHtmlFile,
        setDeletedFilesIds,
        setCreateAgencyId,
        setAgencyDropdownList
    } = useContext(agencyContext)

    const { userDetail } = useContext(AuthContext).state;

    const smallScreen = useMediaQuery('(max-width:1250px)');
    const [showURLTextField, setShowURLTextField] = useState(false)
    const [progressBar, setProgressBar] = useState(false)
    const [flagMatchedAgency, setFlagMatchedAgency] = useState(false)
    const [flagMatchedMag, setFlagMatchedMag] = useState(false)
    const [flagMatchedCustomUrl, setFlagMatchedCustomUrl] = useState(false)
    const [helperTextForAgencyName, setHelperTextForAgencyName] = useState('')
    // const [isDisabled, setIsDisabled] = useState(true);
    //FavIcon States
    const [upLoadingFile, setUpLoadingFile] = useState(false)

    const [htmlFileProgressBar, setHtmlFileProgressBar] = useState(false)
    const [flagOpenHtmlDialog, setFlagOpenHtmlDialog] = useState(false)
    const [flagHtmlFileUploaded, setFlagHtmlFileUploaded] = useState(false)
    const [maxHeight, setMaxHeight] = useState(0)
    //Pagination State
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3)
    const [userId, setUserID] = useState(null)
    const [copiedMagUrl, setCopiedMagUrl] = useState(false)
    const [flagHtmlFileIsDeleting, setFlagHtmlFileIsDeleting] = useState(true)
    const [flagDisabledPreviewButton, setFlagDisabledPreviewButton] = useState(false)
    const emailRegex = /^([a-zA-Z0-9\._-]+)@([a-zA-Z)-9])+.([a-zA-Z]+)(.[a-zA-Z]+)?$/
    const customUrlRegex = /^[a-z0-9]+(-[a-z0-9]+)?\.[a-z]{2,}(\.[a-z]{2,})?$/
    const subAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance"
    const [flagOpenExistAgencyAgentDialog, setFlagOpenExistAgencyAgentDialog] =
        useState(false);
    const [agUserTableMenuAnchorEl, setAgUserTableMenuAnchorEl] = useState(null);
    const [popOver, setPopOver] = useState(null);
    const open = Boolean(agUserTableMenuAnchorEl);

    useEffect(() => {
        const alreadyChecked = agencyDetails.agency_custom_url != null
        setCustomUrlChecked(alreadyChecked)
    }, [agencyDetails])

    useEffect(() => {
        const isHtmlCodeEmpty = agencyHtmlContent == "" || agencyHtmlContent == null
        const isHtmlFileUploaded = agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_url == "" || agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_id == null
        const disableHtmlCodeAndContentPrevButton = (isHtmlFileUploaded && isHtmlCodeEmpty) || (!isHtmlCodeEmpty && !isValidHtml(agencyHtmlContent))
        setFlagDisabledPreviewButton(disableHtmlCodeAndContentPrevButton)
    }, [agencyDetails, agencyHtmlContent])

    function convertFileSize(fileSizeInBytes) {
        if (fileSizeInBytes < 1024) {
            return fileSizeInBytes + " bytes";
        } else if (fileSizeInBytes < 1024 * 1024) {
            return (fileSizeInBytes / 1024).toFixed(2) + " KB";
        } else if (fileSizeInBytes < 1024 * 1024 * 1024) {
            return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        } else {
            return (fileSizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
        }
    }

    //Function To Add Row In DataGrid
    const handleAddRow = () => {
        setAgUserTableMenuAnchorEl(null);
        const lastUserDetails = agencyDetails?.agency_user_data[0]
        //while making new agency there no lastUserDetails so it is undefinded we need to add new one
        if (lastUserDetails === undefined) {
            setAgencyDetails({
                ...agencyDetails,
                agency_user_data: [
                    {
                        // index: agencyDetails.agency_user_data.length,
                        index: 0,
                        id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                        username: "",
                        email_address: "",
                        user_role_flag: true,
                    }
                ]
            })
            setUserID(null)

        }
        const { username, email_address, id } = lastUserDetails
        if (username == "" || email_address == "") {
            setUserID(id)
            setTimeout(() => {
                setUserID(null)
            }, 800)
        } else {
            // setAgencyDetails({
            //     ...agencyDetails,
            //     agency_user_data: [
            //         {
            //             index: 0,
            //             id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
            //             username: "",
            //             email_address: "",
            //             user_role_flag: true,
            //         },
            //         ...agencyDetails.agency_user_data
            //     ]
            // })
            // for (let i = 1; i < newAgencyAdminData.length; i++) {
            //     newAgencyAdminData[i].index += 1; // Adjust the property name if it's different
            // }
            // setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData });
            const newAgencyAdmin = {
                index: 0,
                id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                username: "",
                email_address: "",
                user_role_flag: true,
            };

            // Create a new array with the new object at the first position
            const newAgencyAdminData = [newAgencyAdmin, ...agencyDetails.agency_user_data];

            // Increment the index of all subsequent objects by 1
            for (let i = 1; i < newAgencyAdminData.length; i++) {
                newAgencyAdminData[i].index += 1;
            }

            // Update the state with the modified array
            setAgencyDetails({
                ...agencyDetails,
                agency_user_data: newAgencyAdminData,
            });
            setUserID(null)

        }
    }

    // DO NOT REMOVE:
    // const handleAgencyDetails = (e) => {
    //     const fieldName = e.target.name;
    //     const value = e?.target?.value;
    //     let formattedValue = value;
    //     formattedValue = formattedValue?.trim();

    //     if (fieldName === 'agency_name') {
    //         formattedValue = value?.replace(/[^a-z0-9 ]/gi, '')?.replace(/ /gi, '')?.toLowerCase();
    //     }
    //     else {
    //         const regex = /^[a-zA-Z0-9\s]*$/;
    //         if (!regex.test(value)) {
    //             return;
    //         }
    //         else {
    //             formattedValue = formattedValue.toLowerCase();
    //         }
    //     }

    //     if (fieldName === 'agency_name') {
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_name: value,
    //             agency_mag_url: formattedValue.slice(0, 20)
    //         });
    //     } else {
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_mag_url: formattedValue
    //         });
    //     }
    // };

    // UPDATED VALIDATION 16-09
    const handleAgencyDetails = (e) => {
        const fieldName = e.target.name;
        const value = e?.target?.value;
        let formattedValue = value;
        formattedValue = formattedValue?.trim();

        if (fieldName === 'agency_name') {
            formattedValue = value;
        }
        else {
            // Corrected regex to ensure only lowercase alphanumeric characters are allowed
            const regex = /^[a-z0-9]*$/;
            if (!regex.test(formattedValue.replace(/ /g, ''))) {
                return;
            }
        }

        if (fieldName === 'agency_name') {
            setAgencyDetails({
                ...agencyDetails,
                agency_name: value,
                agency_mag_url: value.replace(/[^a-z0-9]/gi, '').toLowerCase() // Ensure the MAG URL is lowercase
            });
        } else {
            setAgencyDetails({
                ...agencyDetails,
                agency_mag_url: formattedValue // This is already lowercase and validated
            });
        }
    };

    // const handleAgencyDetails = (e) => {
    //     const { name, value } = e.target;
    //     if (name === 'agency_name') {
    //         // Automatically fill and format the agency_mag_url based on agency_name
    //         const formattedMagURL = value.replace(/[^a-z0-9]/gi, '').toLowerCase();
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_name: value,
    //             agency_mag_url: formattedMagURL
    //         });
    //     } else if (name === 'mag_url') {
    //         // Apply validation only for MAG URL when edited directly
    //         const regex = /^[a-z0-9]+$/;
    //         if (regex.test(value)) {
    //             setAgencyDetails({
    //                 ...agencyDetails,
    //                 agency_mag_url: value.toLowerCase()
    //             });
    //         }
    //     }
    // };


    //close the dialogue box of existing agents
    const handleClose = (event, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            setFlagOpenExistAgencyAgentDialog(false);
        }
    };

    // PNG FILE UPLOAD. 
    const handlePNGFilesUploading = (e) => {
        const file = e.target.files[0]
        if (!file) return;

        if (file.type !== "image/png") {
            UpdateSnackbarMessage({
                status: "error",
                message: "Only PNG files supported",
            })
            return
        }

        const fileSize = file.size / (1024 * 1024)
        if (fileSize > 2) {
            UpdateSnackbarMessage({
                status: "error",
                message: "File size exceeds 2 MB",
            });
            return;
        }
        setProgressBar(true)

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("AGENCY_LOGO_PATH", true);

        UploadFileAgency(formData, (res) => {
            if (res?.data?.status) {
                setProgressBar(false)
                setFileUploaded(true)
                setFileSelected(file);
                setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: res?.data?.data[0]?.resource_gcs_data_id })
            }
            else {
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                }
                else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                }
                setProgressBar(false)
                setFileUploaded(false)
            }
        }, (err) => {
            UpdateSnackbarMessage({
                status: "error",
                message: err?.data?.error?.message,
            });
            setProgressBar(false)
            setFileUploaded(false)
        })


        // setProgressBar(true)
        // setTimeout(() => {
        //     setProgressBar(false)
        //     setFileUploaded(true)
        // }, 5000)
    }

    // PNG FAVICON UPLOAD. 
    const handleFavIconFileUpLoading = (e) => {
        const file = e.target.files[0]

        if (!file) return;

        if (file.type !== "image/png") {
            UpdateSnackbarMessage({
                status: "error",
                message: " Only PNG files supported",
            })
            return
        }

        const fileSize = file.size / (1024 * 1024)
        if (fileSize > 2) {
            UpdateSnackbarMessage({
                status: "error",
                message: "File size exceeds 2 MB",
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("AGENCY_LOGO_PATH", true);

        setUpLoadingFile(true)
        UploadFileAgency(formData, (res) => {
            if (res?.data?.status) {
                setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: res?.data?.data[0]?.resource_gcs_data_id })
                setAgencyFavIcon(file)
                setUpLoadingFile(false)
                setFavIconFileUploaded(true)
            } else {
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                }
                else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                }
                setUpLoadingFile(false)
                setFavIconFileUploaded(false)
            }
        },
            (err) => {
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
                setUpLoadingFile(false)
                setFavIconFileUploaded(false)
            }
        )

    }

    // HTML FILE UPLOAD.
    const handleHtmlFileUploading = (e) => {
        const file = e.target.files[0];
        setHtmlFileProgressBar(true)

        if (!file) return;

        if (file.type !== "text/html") {
            UpdateSnackbarMessage({
                status: "error",
                message: "Only HTML files supported",
            });
            return;
        }



        const formData = new FormData();
        formData.append("file", file);
        formData.append("AGENCY_LOGO_PATH", true);

        console.log("formDatajfdk", formData);
        UploadFileAgency(formData, (res) => {
            console.log("reponse of html file upload", res)
            if (res?.data?.status) {
                setHtmlFileProgressBar(false)
                setHtmlFileUploaded(true)
                setHtmlFileSelected(file);
                setAgencyHtmlFile(file)
                setFlagHtmlFileIsDeleting(true)
                setAgencyDetails({
                    ...agencyDetails,
                    agency_privacy_policy: {
                        ...agencyDetails?.agency_privacy_policy,
                        privacy_policy_gcs_id: res?.data?.data[0]?.resource_gcs_data_id,
                        privacy_policy_gcs_url: res?.data?.data[0]?.resource_file_url,
                        privacy_policy_content: null
                    },

                })
                setAgencyHtmlContent(null)
            }
            else {
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                }
                else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                }
                setProgressBar(false)
                setHtmlFileUploaded(false)
            }
        }, (err) => {
            console.log("error of file", err)
            UpdateSnackbarMessage({
                status: "error",
                message: err?.data?.error?.message,
            });
            setProgressBar(false)
            setHtmlFileUploaded(false)
        })


        // setHtmlFileProgressBar(true)
        // setTimeout(() => {
        //     setHtmlFileProgressBar(false)
        //     setHtmlFileSelected(true)
        // }, 5000)
    }

    const handleMatchingEmails = (email, index) => {
        const foundMatchingEmail = !!agencyDetails?.agency_user_data.find(item => {
            return item.email_address === email && item.index !== index;
        });
        return foundMatchingEmail;
    };


    const handleDeleteFileWithoutCall = (fileName) => {
        if (fileName == "favicon") {
            const latestFavIconId = checkLogoFavId?.favIconId
            setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: null })
            setAgencyFavIcon(null)
            setFavIconFileUploaded(false)
            // THIS STATE WILL BE USE IN FUTURE
            setDeletedFilesIds({ ...deletedFilesId, favIconIds: [...deletedFilesId.favIconIds, latestFavIconId] })
        }
        else if (fileName == "logo") {
            const latestLogoId = checkLogoFavId?.logoId
            setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: null })
            setFileSelected(null)
            setFileUploaded(false)
            // THIS STATE WILL BE USE IN FUTURE
            setDeletedFilesIds({ ...deletedFilesId, logoIds: [...deletedFilesId.logoIds, latestLogoId] })
        }
        else if (fileName == "html") {
            const latestPrivacyPolicyIds = checkLogoFavId?.privacyPolicyId
            setAgencyDetails({
                ...agencyDetails,
                agency_privacy_policy: {
                    ...agencyDetails?.agency_privacy_policy,
                    privacy_policy_gcs_id: null, privacy_policy_gcs_url: ""
                },
            })
            setAgencyHtmlFile(null)
            setHtmlFileUploaded(false)
            // THIS STATE WILL BE USE IN FUTURE
            setDeletedFilesIds({ ...deletedFilesId, privacyPolicyIds: [...deletedFilesId.privacyPolicyIds, latestPrivacyPolicyIds] })
        }
    }

    // DELETE CALL
    const handleFileDelete = (file) => {
        // debugger
        if (file == "favicon") {
            setUpLoadingFile(true)
        }
        else if (file == "logo") {
            setProgressBar(true)
        }
        else if (file == "html") {
            setHtmlFileProgressBar(true)
        }
        else {
            setUpLoadingFile(false)
            setProgressBar(false)
            setHtmlFileProgressBar(false)
        }

        // file == "favicon" ? setUpLoadingFile(true) : setProgressBar(true)

        if ((agencyDetails?.agency_logo_gcs_id == checkLogoFavId?.logoId) && file == "logo" && agencyId) {
            setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: null })
            setProgressBar(false)
            setFileUploaded(false)
        }
        else if ((agencyDetails?.agency_favicon_gcs_id == checkLogoFavId?.favIconId) && file == "favicon" && agencyId) {
            setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: null })
            setUpLoadingFile(false)
            setFavIconFileUploaded(false)
        }
        else if ((agencyDetails?.agency_privacy_policy && agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_id == checkLogoFavId?.privacyPolicyId) && file == "html" && agencyId) {
            // setAgencyDetails({ ...agencyDetails, privacy_policy_gcs_id: null })
            setAgencyDetails({
                ...agencyDetails,
                agency_privacy_policy: {
                    ...agencyDetails?.agency_privacy_policy,
                    privacy_policy_gcs_id: null,
                    privacy_policy_gcs_url: ""
                },
            })
            setHtmlFileProgressBar(false)
            setHtmlFileUploaded(false)
        }
        else {
            let payloadForDelete = {
                resource_gcs_data_id:
                    file == "favicon" ? agencyDetails?.agency_favicon_gcs_id :
                        file == "logo" ? agencyDetails?.agency_logo_gcs_id :
                            file == "html" && agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_id
            }

            DeleteFileAgency(payloadForDelete, (res) => {
                if (res?.data?.status) {
                    if (file == "favicon") {
                        setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: null })
                        setUpLoadingFile(false)
                        setFavIconFileUploaded(false)
                    }
                    else if (file == "logo") {
                        setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: null })
                        setProgressBar(false)
                        setFileUploaded(false)
                    }
                    else if (file == "html") {
                        // setAgencyDetails({ ...agencyDetails, privacy_policy_gcs_id: null })
                        setAgencyDetails({
                            ...agencyDetails,
                            agency_privacy_policy: {
                                ...agencyDetails?.agency_privacy_policy,
                                privacy_policy_gcs_id: null,
                                privacy_policy_gcs_url: ""

                            },
                        })
                        setFlagHtmlFileIsDeleting(true)
                        setHtmlFileProgressBar(false)
                        setHtmlFileUploaded(false)
                    }
                }
                else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                    setProgressBar(false)
                    setFileUploaded(false)
                    setUpLoadingFile(false)
                }
            }, (err) => {
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
                setProgressBar(false)
                setFileUploaded(false)
                setUpLoadingFile(false)
            })
        }
    }

    //handling popover
    const handlePopoverOpen = (event) => {
        setPopOver(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopOver(null);
    };

    //delete row
    const handleDeleteRow = (row) => {
        // Function to delete a row
        const findIdxToDelete = agencyDetails?.agency_user_data.findIndex(agency => agency.index === row.index);
        if (findIdxToDelete !== -1) {
            // const newAgencyAdminData = [...agencyDetails.agency_user_data];
            // newAgencyAdminData.splice(findIdxToDelete, 1);
            // for(let i=findIdxToDelete+1;i<findIdxToDelete)
            // newAgencyAdminData.slice(findIdxToDelete,agencyDetails?.agency_user_data?.length);

            // const updateAgencyAdminData=
            // setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData });
            const newAgencyAdminData = [...agencyDetails.agency_user_data];

            // Remove the object at findIdxToDelete
            newAgencyAdminData.splice(findIdxToDelete, 1);

            // Update indices of remaining objects, assuming they have an `index` property
            for (let i = findIdxToDelete; i < newAgencyAdminData.length; i++) {
                newAgencyAdminData[i].index -= 1; // Adjust the property name if it's different
            }
            setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData });

            // Adjust page if current page becomes empty
            if (row.index % rowsPerPage == 0 && page > 0) {
                setPage(page - 1); // Move back one page if current page becomes empty
            }
        }
    };
    return (
        <>
            {flagOpenHtmlDialog &&
                <GlobalDialog
                    open={flagOpenHtmlDialog}
                    data={
                        <AgencyPrivacyPolicyPreview
                            flagOpenHtmlDialog={flagOpenHtmlDialog}
                            setFlagOpenHtmlDialog={setFlagOpenHtmlDialog}
                        />
                    }
                    maxWidth={"xl"}
                />
            }
            {flagOpenExistAgencyAgentDialog && (
                <GlobalDialog
                    open={flagOpenExistAgencyAgentDialog}
                    data={
                        <ExistingAgencyAgentDialog
                            flagOpenExistAgencyAgentDialog={flagOpenExistAgencyAgentDialog}
                            setFlagOpenExistAgencyAgentDialog={
                                setFlagOpenExistAgencyAgentDialog
                            }
                        />
                    }
                    onClose={handleClose}
                    maxWidth={"lg"}
                />
            )}
            <Grid container className='ag-details-main-container'  >
                <Grid item xs={12} >
                    <Grid container className='ag-details-container' sx={{ justifyContent: "space-between" }}>
                        <Grid item xs={5.5}>
                            <TextField
                                disabled={(agencyId || subAgency) ? true : false}
                                autoComplete='off'
                                fullWidth
                                name={"agency_name"}
                                value={agencyDetails.agency_name}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e) => {
                                    handleAgencyDetails(e)
                                }}
                                onFocus={() => setShowURLTextField(true)}
                                className='ag-details-textfield'
                                variant='standard'
                                label={
                                    <span>Agency Name<span className='ag-details-textfield-asterik'>*</span></span>
                                }
                                InputLabelProps={{
                                    className: "ag-details-textfield-label"
                                }}
                                helperText={flagMatchedAgency ? helperTextForAgencyName : ""}
                                FormHelperTextProps={{ sx: { color: 'red' } }}
                                sx={{
                                    '& .MuiInput-root:after': {
                                        borderBottom: !flagMatchedAgency ? "2px solid #376fd0 !important" : "2px solid red !important"
                                    },
                                    '& .MuiInput-root:before': {
                                        borderBottom: !flagMatchedAgency ? "1px solid rgba(0, 0, 0, 0.42) !important" : "1px solid red !important"
                                    }
                                }}
                                onBlur={() => {
                                    let payloadForAgencyName = {
                                        agency_name: agencyDetails?.agency_name
                                    }
                                    let payloadForAgencyMagUrl = {
                                        agency_url: agencyDetails?.agency_mag_url + "" + `${process.env.REACT_APP_MAG_DOMAIN}`
                                    }

                                    if (agencyDetails?.agency_name || agencyDetails?.agency_mag_url) {

                                        if (!subAgency) {
                                            checkAgencyExistence(payloadForAgencyName, (res) => {

                                                if (res?.data?.status) {
                                                    console.log("Agency Existance response", res?.data?.data[0])
                                                    if (res?.data?.data[0].agency_exist_flag) {
                                                        setFlagMatchedAgency(true)
                                                        setHelperTextForAgencyName('Agency is already exists.')
                                                    }
                                                    else {
                                                        setFlagMatchedAgency(false);
                                                        setHelperTextForAgencyName('')
                                                    }
                                                }
                                            }, (err) => {
                                                setFlagMatchedAgency(false);
                                                setHelperTextForAgencyName(err?.data?.error?.message,)
                                            })

                                            checkAgencyURLExistence(payloadForAgencyMagUrl,
                                                (res) => {
                                                    if (res?.data?.status) {
                                                        console.log("Agency URL Existance response", res?.data?.data[0])
                                                        if (res?.data?.data[0].agency_url_exist_flag) {
                                                            setFlagMatchedMag(true)
                                                        }
                                                        else {
                                                            setFlagMatchedMag(false);
                                                        }
                                                    }
                                                    else {
                                                        setFlagMatchedMag(false);
                                                    }
                                                }, (err) => {
                                                    setFlagMatchedMag(false);
                                                    UpdateSnackbarMessage({
                                                        status: "error",
                                                        message: err?.data?.error?.message,
                                                    });
                                                })
                                        }
                                    }

                                }}
                            />
                        </Grid>
                        <Grid item xs={5.5}>
                            <TextField
                                fullWidth
                                name={"agency_email"}
                                disabled={(agencyId || subAgency) ? true : false}
                                value={agencyDetails.agency_email_address}
                                onChange={(e) => {
                                    setAgencyDetails({
                                        ...agencyDetails,
                                        agency_email_address: e.target.value
                                    })
                                }}
                                variant='standard'
                                className='ag-details-textfield'
                                label={
                                    <span>Agency Email<span className='ag-details-textfield-asterik'>*</span></span>
                                }
                                InputLabelProps={{
                                    className: "ag-details-textfield-label"
                                }}

                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "25px" }}>
                    {/* THIS CONTAINER CONTAINES BELOW COMENTED CODE */}
                    <Grid container className='ag-details-container'  >
                        <Grid item xs={12}>
                            <Grid container className='ag-details-url-container'>
                                <Grid item sx={{ marginTop: "11px" }}>
                                    <Grid container className={agencyId ? 'ag-edit-details-mag-url-container' : 'ag-details-mag-url-container '}>
                                        <Grid item>
                                            <Typography className='ag-details-typography'> MAG URL :</Typography>
                                        </Grid>
                                        <Grid item sx={{ flex: "1", overflow: "auto" }}>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                {showURLTextField ?
                                                    (
                                                        <>
                                                            <TextField
                                                                variant='standard'
                                                                className='ag-details-url-textfield ag-details-textfield'
                                                                name={"mag_url"}
                                                                value={agencyDetails.agency_mag_url}
                                                                inputProps={{ maxLength: 10 }}
                                                                onChange={(e) => {
                                                                    handleAgencyDetails(e)
                                                                }}
                                                                InputProps={{
                                                                    // readOnly: true,
                                                                    style: {
                                                                        backgroundColor: "white",
                                                                        fontFamily: "Nunito",
                                                                        fontSize: "13px",
                                                                        padding: '3px 10px'

                                                                    },
                                                                    disableUnderline: true
                                                                }}
                                                                sx={{
                                                                    '& .MuiInput-root': {
                                                                        border: flagMatchedMag ? '1px solid red !important' : '1px solid transparent'
                                                                    }
                                                                }}

                                                                onBlur={() => {
                                                                    let payloadForAgencyMagUrl = {
                                                                        agency_url: agencyDetails?.agency_mag_url + "" + `${process.env.REACT_APP_MAG_DOMAIN}`
                                                                    }
                                                                    if (agencyDetails?.agency_mag_url != "" && agencyDetails?.agency_mag_url !== null) {
                                                                        if (!subAgency) {
                                                                            // debugger
                                                                            checkAgencyURLExistence(payloadForAgencyMagUrl,
                                                                                (res) => {
                                                                                    // debugger
                                                                                    if (res?.data?.status) {
                                                                                        console.log("Agency URL Existance response", res?.data?.data[0])
                                                                                        if (res?.data?.data[0].agency_url_exist_flag) {
                                                                                            setFlagMatchedMag(true)
                                                                                            UpdateSnackbarMessage({
                                                                                                status: "error",
                                                                                                message: "This URL already exists!",
                                                                                            });
                                                                                        }
                                                                                        else {
                                                                                            setFlagMatchedMag(false);
                                                                                            // UpdateSnackbarMessage({
                                                                                            //     status: "error",
                                                                                            //     message: res?.data?.error?.message,
                                                                                            // });
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        UpdateSnackbarMessage({
                                                                                            status: "error",
                                                                                            message: res?.data?.error?.message,
                                                                                        });
                                                                                    }
                                                                                }, (err) => {
                                                                                    setFlagMatchedMag(false);
                                                                                    UpdateSnackbarMessage({
                                                                                        status: "error",
                                                                                        message: err?.data?.error?.message,
                                                                                    });
                                                                                })
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <Typography className='ag-details-typography'>{process.env.REACT_APP_MAG_DOMAIN}</Typography>
                                                        </>
                                                    ) : (
                                                        <Typography className='ag-magurl-typography'>
                                                            {`${agencyDetails?.agency_mag_url == "" ? `agencyname${process.env.REACT_APP_MAG_DOMAIN}` : agencyDetails?.agency_mag_url}`}
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid item sx={{ marginLeft: "10px" }}>
                                            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                {agencyId ?
                                                    <img src={!flagMatchedMag ? greenCheckimg : error} /> : <></>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {flagMatchedMag &&
                                    <Grid item xs={12} sx={{ ml: '100px', mt: '1px' }}>
                                        <Typography
                                            variant='span'
                                            sx={{
                                                fontSize: "11px",
                                                fontWeight: '500',
                                                color: 'red'
                                            }}
                                        >
                                            MAG URL is already exists.
                                        </Typography>
                                    </Grid>
                                }
                                {pathName != "/agency/add_agency" &&
                                    <Grid item sx={{ marginTop: "11px" }}>
                                        <Tooltip
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            open={copiedMagUrl}
                                            placement={"top-start"}
                                            title="Copied Mag URL"
                                            PopperProps={{ disablePortal: true }}
                                        >
                                            <IconButton
                                                color='primary'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${agencyDetails.agency_mag_url}`)
                                                    setCopiedMagUrl(true)
                                                    setTimeout(() => { setCopiedMagUrl(false) }, 1000)
                                                }}
                                            >
                                                <ContentCopyOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/* Commented due to White Label Domain Developement, (15-04-24) */}
                        {/* <Grid item xs={12}>
                            <Grid container className='ag-details-textfield-Checkbox-container'>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'}>
                                        <Typography className='ag-details-typography'>Would you like to incorporate myAdvisorGrids into your website?</Typography>
                                        <Checkbox
                                            size='medium'
                                            // checked={agencyData.custom_url_flage}
                                            name={"custom_url_flage"}
                                            checked={customUrlChecked}
                                            onChange={(e) => {
                                                if (customUrlChecked) {
                                                    setAgencyDetails({
                                                        ...agencyDetails,
                                                        agency_custom_url: null
                                                    })
                                                }
                                                setCustomUrlChecked((prevState) => !prevState)
                                            }} />
                                    </Grid>
                                </Grid>

                                {
                                    customUrlChecked &&
                                    <Grid item xs={12} sx={{ marginBottom: "12px" }}>
                                        <TextField
                                            fullWidth
                                            error={flagMatchedCustomUrl}
                                            className='ag-details-textfield'
                                            name={"custom_url"}
                                            variant='standard'
                                            value={agencyDetails.agency_custom_url}
                                            onChange={(e) => {
                                                // let value = e.target.value.toLowerCase().replace(/[^a-z0-9-.]/gi, '')
                                                // value = value.replace(/^[\-.]+/, '')
                                                const inputValue = e.target.value.toLowerCase().replace(/[^a-z0-9-.]/gi, '')
                                                setAgencyDetails({
                                                    ...agencyDetails,
                                                    agency_custom_url: inputValue
                                                })
                                            }}
                                            label={
                                                <span>Specify the domain/sub-domain for myAdvisorGrids white labeling<span className='ag-details-textfield-asterik'>*</span></span>
                                            }
                                            helperText={
                                                flagMatchedCustomUrl ? agencyDetails.agency_custom_url == "" || agencyDetails.agency_custom_url == null ?
                                                    'Please Fill This Filed' : "Incorrect Custom URL" : ""}
                                            onBlur={() => {
                                                if (customUrlRegex.test(agencyDetails.agency_custom_url)) {
                                                    setFlagMatchedCustomUrl(false)
                                                } else {
                                                    setFlagMatchedCustomUrl(true)
                                                }
                                            }}
                                            onFocus={() => setFlagMatchedCustomUrl(false)}
                                            InputLabelProps={{
                                                className: "ag-details-textfield-label"
                                            }}
                                        />

                                    </Grid>
                                }
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "25px", maxHeight: "243px" }}>
                    <Grid container className='ag-details-container' sx={{ height: "100%" }} >

                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant='span' className='ag-details-typography'>Agency Users
                                    <span className='ag-details-textfield-asterik'>*</span>
                                </Typography>
                                {/* {(agencyId || subAgency) && */}
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    variant='text'
                                    sx={{
                                        fontSize: "16px",
                                        color: "#1565C0",
                                        padding: "0px",
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                        }
                                    }}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleAddRow}
                                // onClick={(e) => { setAgUserTableMenuAnchorEl(e.currentTarget) }}
                                >
                                    Users
                                </Button>
                                {/* <Menu
                                    id="basic-menu"
                                    anchorEl={agUserTableMenuAnchorEl}
                                    open={open}
                                    onClose={() => { setAgUserTableMenuAnchorEl(null); }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleAddRow} className='ag-menu-menuitem'>
                                        <Typography variant="span" color="initial" className='ag-menu-text' >

                                            New User
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => { setAgUserTableMenuAnchorEl(null); setFlagOpenExistAgencyAgentDialog(true); }} className='ag-menu-menuitem'>
                                        <Typography variant="span" color="initial" className='ag-menu-text'>

                                            Existing Agent
                                        </Typography>
                                    </MenuItem>

                                </Menu> */}
                                {/*  } */}

                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: "11px", height: "calc(100% - 33px)" }}>
                            <TableContainer sx={{ height: '100%' }} >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead className='agency-user-table-head'>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                                className='agency-table-head agnecy-tablefirst-cell'
                                                stickyHeader
                                            >
                                                Username <span style={{ color: "#FF0000" }}>*</span>
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                className='agency-table-head'
                                                stickyHeader
                                            >
                                                Email <span style={{ color: "#FF0000" }}>*</span>
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                className='agency-table-head'
                                                stickyHeader

                                            >
                                                Roles <span style={{ color: "#FF0000" }}>*</span>
                                            </TableCell>

                                            <TableCell
                                                align="right"
                                                stickyHeader
                                                className='agency-table-head'

                                                sx={{ minWidth: "22px" }}
                                            >
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className='agency-table-head agnecy-tablelast-cell'

                                            >
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='agency-user-table-body'>
                                        {agencyDetails.agency_user_data.length > 0 ? agencyDetails?.agency_user_data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const rowId = row.id ? row.id : ""
                                            const isDuplicateUser = handleMatchingEmails(row?.email_address, row?.index)
                                            return (
                                                <>
                                                    <TableRow className={userId == rowId ? 'agency-table-body-row' : ""} >
                                                        <TableCell align="left" className='agency-table-body-cell'>
                                                            <TextField
                                                                size='large'
                                                                fullWidth
                                                                type='text'
                                                                placeholder='Enter First Name'
                                                                className='ag-details-datagrid-inp'
                                                                autoComplete="off"
                                                                value={row?.username}
                                                                onChange={(e) => {
                                                                    console.log('value of name', e.target.value)
                                                                    let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                    let newAgencyAdminData = prevAgencyAdminData?.map((admin, adminIndex) => {
                                                                        console.log("adminid", admin);
                                                                        if (admin.index == row?.index) {
                                                                            return {
                                                                                ...admin, username: e.target.value
                                                                            }
                                                                        }
                                                                        return admin
                                                                    })
                                                                    setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode == 32) {
                                                                        e.stopPropagation();
                                                                    } else {
                                                                        console.log("not propogate");
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" className='agency-table-body-cell'>
                                                            <TextField
                                                                size='large'
                                                                fullWidth
                                                                type='text'
                                                                placeholder='Enter Email'
                                                                disabled={row?.user_id ? true : false}
                                                                autoComplete="off"
                                                                className={`ag-details-datagrid-inp ${row?.user_id ? 'agency-email-field-disabled' : ''}`}
                                                                value={row?.email_address}
                                                                onChange={(e) => {
                                                                    let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                    let newAgencyAdminData = prevAgencyAdminData?.map((admin, adminIndex) => {
                                                                        if (admin.index == row?.index) {
                                                                            return {
                                                                                ...admin, email_address: e.target.value
                                                                            }
                                                                        }
                                                                        return admin
                                                                    })
                                                                    setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode == 32) {
                                                                        e.stopPropagation();
                                                                    } else {
                                                                        console.log("not propogate");
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" padding="none" className='agency-table-body-cell' >
                                                            <FormControl fullWidth >
                                                                <Select
                                                                    className='ag-details-datagrid-role'

                                                                    IconComponent={KeyboardArrowDownIcon}
                                                                    value={row?.user_role_flag}
                                                                    onChange={(e) => {
                                                                        // console.log('here', params?.row?.id)
                                                                        let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                        let newAgencyAdminData = prevAgencyAdminData?.map((admin, adminIndex) => {
                                                                            if (admin.index == row?.index) {
                                                                                return {
                                                                                    ...admin, user_role_flag: e.target.value
                                                                                }
                                                                            }
                                                                            return admin
                                                                        })
                                                                        setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                    }}
                                                                >
                                                                    <MenuItem value={true}>Admin</MenuItem>
                                                                    <MenuItem value={false}>ARM</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align='right' padding='none'>
                                                            <IconButton
                                                                sx={{ padding: "0px", margin: "auto" }}
                                                                disabled={agencyDetails?.agency_user_data?.length == 1 && row?.index == 0 && row?.username == '' && row?.email_address == ''}
                                                                onClick={(e) => {
                                                                    handleDeleteRow(row)
                                                                    //     if (agencyDetails?.agency_user_data?.length == 1) {
                                                                    //         let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                    //         console.log("prevAgencyAdminData", prevAgencyAdminData);
                                                                    //         let newAgencyAdminData = prevAgencyAdminData?.map((admin, i) => {
                                                                    //             console.log("admin", admin);
                                                                    //             if (i == 0) {
                                                                    //                 return {
                                                                    //                     ...admin,
                                                                    //                     username: "",
                                                                    //                     email_address: "",
                                                                    //                     user_id: null,
                                                                    //                     master_user_id: null,
                                                                    //                 }
                                                                    //             }
                                                                    //             return admin
                                                                    //         })
                                                                    //         setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                    //         console.log("newAgencyAdminData", newAgencyAdminData);
                                                                    //         return
                                                                    //     }
                                                                    //     // debugger
                                                                    //     let findIdxToDelete = { ...agencyDetails }.agency_user_data?.findIndex(agency => agency.index == row.index);
                                                                    //     if (findIdxToDelete !== -1) {
                                                                    //         let prevAgencyAdminData = [...agencyDetails.agency_user_data];
                                                                    //         prevAgencyAdminData.splice(findIdxToDelete, 1);
                                                                    //         setAgencyDetails({ ...agencyDetails, agency_user_data: prevAgencyAdminData });
                                                                    //     }
                                                                }}
                                                            >
                                                                {agencyDetails?.agency_user_data?.length == 1 && row?.index == 0 && row?.username == '' && row?.email_address == '' ? <img src={LightDaleteImg} /> : <img src={DarkDeleteImg} />}
                                                            </IconButton>
                                                        </TableCell>


                                                        <TableCell align="center" padding='none' sx={{ minWidth: "20px" }}>
                                                            {
                                                                isDuplicateUser ?
                                                                    (
                                                                        <Tooltip
                                                                            title="Duplicate rows found for user 'user_email_adress'. Please remove duplicate values to continue."
                                                                            componentsProps={{
                                                                                tooltip: {
                                                                                    sx: {
                                                                                        bgcolor: 'common.black',
                                                                                        color: "white",
                                                                                        '& .MuiTooltip-arrow': {
                                                                                            color: 'black',
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                        >
                                                                            <ErrorOutlineOutlinedIcon aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                                aria-haspopup="true"
                                                                                onMouseEnter={handlePopoverOpen}
                                                                                onMouseLeave={handlePopoverClose}
                                                                                fontSize='medium'
                                                                                sx={{
                                                                                    verticalAlign: "bottom",
                                                                                    color: "#cc0000",
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'rgba(0, 0, 0, 0.03)', // Example hover effect
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    ) : null
                                                            }

                                                        </TableCell>

                                                    </TableRow>
                                                </>
                                            )
                                        }
                                        ) :
                                            <TableRow >
                                                <TableCell colSpan={5} padding='none' className='ag-table-no-data-cell'>
                                                    <Typography variant="span" color="initial" className='ag-table-no-data-text'>

                                                        No Data Available
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    {agencyDetails?.agency_user_data?.length > 3 &&
                                        <TableFooter className='agency-user-table-footer'>
                                            <TablePagination
                                                className='agency-user-table-footer-pagination'
                                                rowsPerPage={3}
                                                page={page}
                                                colSpan={5}
                                                count={agencyDetails?.agency_user_data.length}
                                                rowsPerPageOptions={[]}
                                                onPageChange={(e, newPage) => setPage(newPage)}
                                            />
                                        </TableFooter>}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "25px" }}>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                        <Grid item
                            xs={12} sm={12}
                            md={agencyId ? 12 : 5.8}
                            lg={5.8}
                        >
                            <Grid container className='ag-details-container' >

                                <Grid item xs={12} className='ag-details-file-item-typo'>
                                    <Typography component='span' className='ag-details-typography'>Upload Logo</Typography>
                                    <Typography component="span" className='ag-details-typography-grey'> (Only PNG file supported. Max file size 2MB)</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: "11px" }} >
                                    <Grid container>
                                        <Grid item className="ag-details-file-card ">
                                            <Grid container>
                                                <Grid item className="ag-details-file-inputs">
                                                    {!fileUploaded ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                multiple
                                                                className="ag-details-file-inputs"
                                                                accept="image/png"
                                                                onChange={(e) => handlePNGFilesUploading(e)}
                                                            ></input>

                                                            <button
                                                                className="ag-details-file-inputs ag-input-button"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                <Grid container alignItems={'center'} justifyContent={'center'}>

                                                                    <Grid item sx={{ width: "43px" }}>
                                                                        <Grid container className="ag-details-FileUploadIcon" >
                                                                            <img style={{ width: "30px" }} src={FileUploadIcon} />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Grid container className='ag-details-filetext-container'>
                                                                            Drag your files here or <span style={{ marginLeft: "5px", color: "#1565C0" }}> Browse </span>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </button>
                                                        </>
                                                        :
                                                        <Grid container sx={{ alignItems: "center" }}>

                                                            <Grid item sx={{ flex: 1, overflow: "hidden" }}>
                                                                <Grid container className='ag-details-uploadedfile-conatiner'>

                                                                    <Grid item>
                                                                        <img style={{ marginLeft: "10px" }} src={loadedFile} />
                                                                    </Grid>

                                                                    <Grid item className='ag-details-uploadedfile-item'>
                                                                        <Typography className='ag-details-noWrap-Typo'>
                                                                            {fileSelected?.name}
                                                                            <Typography sx={{ color: "#676767" }}>{convertFileSize(fileSelected?.size)}</Typography>
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item >
                                                                <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <IconButton
                                                                        onClick={() => { handleDeleteFileWithoutCall("logo") }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {progressBar && <LinearProgress />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item
                            xs={12} sm={12}
                            md={agencyId ? 12 : 5.8} lg={5.8}
                            marginTop={{ md: agencyId ? "25px" : "0px", lg: "0px" }}
                        >
                            <Grid container className='ag-details-container' >

                                <Grid item xs={12} className='ag-details-file-item-typo'>
                                    <Typography component='span' className='ag-details-typography'>Upload Favicon</Typography>
                                    <Typography component="span" className='ag-details-typography-grey'> (Only PNG file supported. Max file size 2MB)</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: "11px" }}>
                                    <Grid container>
                                        <Grid item className="ag-details-file-card ">
                                            <Grid container>
                                                <Grid item className="ag-details-file-inputs">
                                                    {!favIconFileUploaded ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                multiple
                                                                className="ag-details-file-inputs"
                                                                accept="image/png"
                                                                onChange={(e) => handleFavIconFileUpLoading(e)}
                                                            ></input>

                                                            <button
                                                                className="ag-details-file-inputs ag-input-button"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                <Grid container alignItems={'center'} justifyContent={'center'}>

                                                                    <Grid item sx={{ width: "43px" }}>
                                                                        <Grid container className="ag-details-FileUploadIcon" >
                                                                            <img style={{ width: "30px" }} src={FileUploadIcon} />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Grid container className='ag-details-filetext-container'>
                                                                            Drag your files here or{" "}<span style={{ marginLeft: "5px", color: "#1565C0" }}> Browse </span>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </button>
                                                        </>
                                                        :
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid item sx={{ flex: 1, overflow: "hidden" }}>
                                                                <Grid container className='ag-details-uploadedfile-conatiner'>

                                                                    <Grid item>
                                                                        <img style={{ marginLeft: "10px" }} src={loadedFile} />
                                                                    </Grid>

                                                                    <Grid item className='ag-details-uploadedfile-item'>
                                                                        <Typography className='ag-details-noWrap-Typo'>
                                                                            {agencyFavIcon?.name ? agencyFavIcon?.name : ""}
                                                                            <Typography sx={{ color: "#676767" }}>{convertFileSize(agencyFavIcon?.size ? agencyFavIcon?.size : 0)}</Typography>
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item >
                                                                <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            // handleFileDelete("favicon")
                                                                            handleDeleteFileWithoutCall("favicon")
                                                                        }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {upLoadingFile && <LinearProgress />}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* 
                TEMPORARY COMMENTED 03-06-24
                Grid item xs={12} sx={{ marginTop: "25px" }}>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                        <Grid item xs={12} sm={12} md={agencyId ? 12 : 5.8} lg={5.6}>
                            <Grid container className='ag-details-container' >
                                <Grid item xs={12} className='ag-privacy-policy-item-typo'>
                                    <Typography component='span' className='ag-details-typography'>Upload Privacy Policy </Typography>
                                    <Typography component="span" className='ag-details-typography-grey'>
                                        (Only HTML file supported)
                                        <span className='ag-details-textfield-asterik'> *</span>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: "11px" }} >
                                    <Grid container>
                                        <Grid item className="ag-details-file-card ">
                                            <Grid container>
                                                <Grid item className="ag-details-file-inputs">
                                                    {!htmlFileUploaded ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                multiple
                                                                className="ag-details-file-inputs"
                                                                accept="text/html"
                                                                onChange={(e) => handleHtmlFileUploading(e)}
                                                            ></input>

                                                            <button
                                                                className="ag-details-file-inputs ag-input-button"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                <Grid container alignItems={'center'} justifyContent={'center'}>

                                                                    <Grid item sx={{ width: "43px" }}>
                                                                        <Grid container className="ag-details-FileUploadIcon" >
                                                                            <img style={{ width: "30px" }} src={FileUploadIcon} />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Grid container className='ag-details-filetext-container'>
                                                                            Drag your files here or <span style={{ marginLeft: "5px", color: "#1565C0" }}> Browse </span>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </button>
                                                        </>
                                                        :
                                                        <Grid container sx={{ alignItems: "center" }}>

                                                            <Grid item sx={{ flex: 1, overflow: "hidden" }}>
                                                                <Grid container className='ag-details-uploadedfile-conatiner'>

                                                                    <Grid item>
                                                                        <img style={{ marginLeft: "10px", height: 'auto', width: '35px' }} src={htmlIcon} />
                                                                    </Grid>

                                                                    <Grid item className='ag-details-uploadedfile-item'>
                                                                        <Typography className='ag-details-noWrap-Typo'>
                                                                            {agencyHtmlFile?.name ? agencyHtmlFile?.name : ""}
                                                                            <Typography sx={{ color: "#676767" }}>{convertFileSize(agencyHtmlFile?.size ? agencyHtmlFile?.size : 0)}</Typography>
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item >
                                                                <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            // handleFileDelete("html")
                                                                            handleDeleteFileWithoutCall('html')
                                                                        }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {htmlFileProgressBar && <LinearProgress />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider
                            orientation={smallScreen ? "horizontal" : "vertical"}
                            variant="middle"
                            className='agency-or-divider'
                            flexItem
                        >
                            OR
                        </Divider>
                        <Grid item xs={12} sm={12} md={agencyId ? 12 : 5.8} lg={5.6} marginTop={{ md: agencyId ? "25px" : "0px", lg: "0px" }}>
                            <Grid container className='ag-details-container' sx={{ alignItems: 'center' }} >
                                <Grid item xs={12} sx={{ textAlign: 'end' }} className='ag-details-file-item-typo'>
                                    <Button
                                        startIcon={<VisibilityIcon style={{ color: 'black', fontSize: '18px' }} />}
                                        variant='text'
                                        disabled={flagDisabledPreviewButton}
                                        sx={{
                                            fontSize: "14px",
                                            color: "#1565C0",
                                            padding: "0px",
                                            '&:hover': { backgroundColor: '#fff', },
                                            '&:disabled': { color: "rgb(21, 101, 192)", opacity: 0.5 }
                                        }}
                                        onClick={() => { setFlagOpenHtmlDialog(true) }}
                                    >
                                        Preview
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '11px' }}>
                                    <textarea
                                        id="agency-html-code"
                                        rows="3"
                                        type="html"
                                        className='ag-html-textarea'
                                        placeholder='Enter HTML Code here...'
                                        value={agencyHtmlContent || ""}
                                        onChange={(e) => {
                                            setAgencyHtmlContent(e.target.value)
                                            if (htmlFileUploaded && flagHtmlFileIsDeleting) {
                                                setFlagHtmlFileIsDeleting(false)
                                                // handleFileDelete("html")
                                                handleDeleteFileWithoutCall('html')
                                            }
                                            // const input = e.target.value;

                                            // // Regular expression to validate HTML content
                                            // const htmlRegex = /<[^>]*>?/;

                                            // // Check if the input contains HTML content
                                            // if (!htmlRegex.test(input)) {
                                            //     UpdateSnackbarMessage({
                                            //         status: "error",
                                            //         message: "Please enter a valid HTML Content.",
                                            //     });
                                            // }
                                            // else {
                                            //     setAgencyHtmlContent(input)
                                            //     if (htmlFileUploaded && flagHtmlFileIsDeleting) {
                                            //         setFlagHtmlFileIsDeleting(false)
                                            //         handleFileDelete("html")
                                            //     }
                                            // }

                                        }}
                                    >
                                    </textarea>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
                */}
            </Grid >
        </>
    )
}
export default AgencyDetails