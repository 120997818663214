import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Grid, Typography, Divider, Button, IconButton, CircularProgress, } from '@mui/material'
import "../../css/Agency/AgencyCarrierDetails.css"
// COMPONENTS
import AgencyDetails from './AgencyDetails'
import AgencyCarrier from './AgencyCarrier'
import AgencyWhiteLabelDoamin from './AgencyWhiteLabelDomain'
import AgencyTemplate from './AgencyTemplate'
// CONTEXT
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as authContext } from '../../../contexts/reducerContexts/authContext';
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";
// API
import { GetCarrierDataList } from '../../../utils/api/TemplateApi';
import {
    createNewAgency, updateAgency, addUpdateAgencyCarrier, getAgencyData,
    GetAgencyCarrierData, GetAgencyCarrierLevelList, GetAgencyTemplateDataList
} from '../../../utils/api/AgencyDetailsAPI'

import BackIcon from "../../../assets/back-icon.png";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AgencyCloneTemplate from './AgencyCloneTemplate'


const AgencyCarrierDetails = () => {

    const pathName = window.location.pathname
    const navigate = useNavigate()
    const emailRegex = /^([a-zA-Z0-9\._-]+)@([a-zA-Z)-9])+.([a-zA-Z]+)(.[a-zA-Z]+)?$/
    const customUrlRegex = /^[a-z0-9]+([-.a-z0-9]+)?\.[a-z]{2,}(\.[a-z]{2,})?$/

    const { agencyDetails, agencyDropdownList, toggleDetails } = useContext(agencyContext).state
    const { setAgencyDropdownList, setToggleDetails } = useContext(agencyContext)
    const { userDetail, carrierTypeDetailsForAgency } = useContext(AuthContext).state;
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const subAgencyAdmin = (userDetail[0]?.user_agency_data?.parent_agency_id != null && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin")
    const subAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance"
    const brokersAllianceAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance"
    const agencyIdFromUserDetailsCtx = userDetail[0]?.user_agency_data?.agency_id

    const [checkLogoFavId, setCheckLogoFavId] = useState({ logoId: null, favIconId: null, privacyPolicyId: null })
    const [checkedItems, setCheckedItems] = useState({})
    const [alreadyCheckedKey, setAlreadyCheckedKeys] = useState({})
    const [carrierCheckBoxes, setCarrierCheckBoxes] = useState({})
    const [carrierTypesList, setCarrierTypesList] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState({ template_id: null, carrier_type_flag: null })
    const [isDisabled, setIsDisabled] = useState(true)
    const [agencyUpdated, setAgencyUpdated] = useState(false)
    const [customUrlChecked, setCustomUrlChecked] = useState(false)
    const [flagRender, setFlagRender] = useState(false)

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)
    const {
        apiLoader
    } = useContext(authContext).state

    const {
        setAgencyDetails,
        setAgencyCarrieData,
        setFileSelected,
        setFileUploaded,
        setAgencyFavIcon,
        setFavIconFileUploaded,
        setCarrierListData,
        setAgencyTemplateDataList,
        setAgencyTemplateDetails,
        setAgencyDuplicateTemplateDetails,
        setAgencyCarrierDetails,
        setAgencyCarrierLevelList,
        setHtmlFileUploaded,
        setAgencyHtmlFile,
        setAgencyHtmlContent,
        setCreateAgencyId,
        setAgencyWhiteListedDomainDetails,
        setFlagRenderTableData
    } = useContext(agencyContext)

    const {
        selectedSize,
        agencyFavIcon,
        fileUploaded,
        favIconFileUploaded,
        agencyCarrierData,
        agencyCarrierDetails,
        agencyHtmlFile,
        agencyHtmlContent,
        flagRenderTableData
    } = useContext(agencyContext).state

    console.log("agencyCarrierDetails", agencyCarrierDetails);


    const emptyAgencyDetails = {
        agency_name: "",
        agency_email_address: "",
        agency_mag_url: "",
        agency_custom_url: null,
        agency_user_data: [
            {
                index: 0,
                id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                user_id: "",
                master_user_id: "",
                username: "",
                email_address: "",
                user_role_flag: true,
            },
        ],
        agency_logo_gcs_id: null,
        agency_favicon_gcs_id: null,
        privacy_policy_gcs_id: null,
        privacy_policy_content: null,
        agency_privacy_policy: {
            privacy_policy_id: null,
            privacy_policy_name: "",
            privacy_policy_file_name: "",
            privacy_policy_gcs_url: "",
            privacy_policy_file_size: "",
            privacy_policy_content: ""
        }
    }

    const isAgencyDetails = toggleDetails == "agency_details"
    const isWhiteLabelDomain = toggleDetails == "whitelist_domain"
    const isAgencyCarrier = toggleDetails == "agency_carrier"
    const isBaTemplate = toggleDetails == 'ba_template'
    const isAgencyTemplate = toggleDetails == "agency_template"

    useEffect(() => {
        const agency_id = searchParams.get("agency_id")
        setCheckedItems({})
        setAlreadyCheckedKeys({})
        setAgencyCarrierDetails({})
        // setAgencyCarrierLevelList({ life: [], annuity: [] })
        setToggleDetails("agency_details")
        setAgencyUpdated(false)
        setAgencyDetails({ ...emptyAgencyDetails })
        setAgencyHtmlContent(null)
        setAgencyHtmlFile(null)
        setHtmlFileUploaded(false)
        setAgencyWhiteListedDomainDetails(null)
        // setAgencyTemplateDataList({ life: [], annuity: [] })
        setAgencyTemplateDetails([])
        setAgencyDuplicateTemplateDetails([])
        if (agency_id || subAgency) {
            FlagApiLoader(true)
            const agencyID = subAgency ? agencyIdFromUserDetailsCtx : agency_id
            getAgencyData(agencyID,
                (res) => {
                    console.log("RESPONSE OF AGENCY DATA", res)
                    if (res?.data?.status) {
                        const checkedData = res?.data?.data[0].agency_carrier_data
                        const agencyDetailsData = res?.data?.data[0]
                        setAgencyCarrieData(checkedData)
                        if (res?.data?.data) {
                            setCreateAgencyId(res?.data?.data[0].agency_id)
                            setAgencyDetails({
                                ...agencyDetails,
                                agency_name: agencyDetailsData?.agency_name,
                                agency_email_address: agencyDetailsData?.agency_email_address,
                                // agency_mag_url: agencyDetailsData?.agency_mag_url.split(".myadvisorgrids.com")[0],
                                agency_mag_url: agencyDetailsData?.agency_mag_url,
                                agency_custom_url: agencyDetailsData.agency_custom_url,
                                agency_user_data: agencyDetailsData?.agency_user_data?.map((ele, i) => {
                                    return {
                                        index: i,
                                        master_user_id: ele.master_user_id,
                                        user_id: ele.user_id,
                                        username: ele.username,
                                        email_address: ele.email_address,
                                        user_role_flag: ele.user_role_flag,
                                        //agent_id added if there is null then null will be added
                                        agent_id: ele.agent_id ? ele.agent_id : null
                                    }
                                }),
                                agency_logo_gcs_id: agencyDetailsData?.agency_logo_gcs_id,
                                agency_favicon_gcs_id: agencyDetailsData?.agency_favicon_gcs_id,
                                agency_favicon_url: agencyDetailsData?.agency_favicon_url,
                                agency_logo_url: agencyDetailsData?.agency_logo_url,
                                agnecy_status_name: agencyDetailsData?.agnecy_status_name,
                                agency_privacy_policy: agencyDetailsData?.agency_privacy_policy
                            })
                            setAgencyHtmlContent(agencyDetailsData?.agency_privacy_policy?.privacy_policy_content)
                            setFileUploaded(agencyDetailsData?.agency_logo_name || agencyDetailsData?.agency_logo_size ? true : false)
                            setFileSelected({
                                ...selectedSize,
                                name: agencyDetailsData?.agency_logo_name,
                                size: agencyDetailsData?.agency_logo_size,
                            })
                            setFavIconFileUploaded(agencyDetailsData.agency_favicon_name || agencyDetailsData?.agency_favicon_size ? true : false)
                            setAgencyFavIcon({
                                ...agencyFavIcon,
                                name: agencyDetailsData.agency_favicon_name,
                                size: agencyDetailsData?.agency_favicon_size,
                            })

                            if (agencyDetailsData.agency_privacy_policy) {
                                setHtmlFileUploaded(agencyDetailsData.agency_privacy_policy.privacy_policy_file_name || agencyDetailsData?.agency_privacy_policy.privacy_policy_file_size ? true : false)
                                setAgencyHtmlFile({
                                    ...agencyHtmlFile,
                                    name: agencyDetailsData?.agency_privacy_policy.privacy_policy_file_name,
                                    size: agencyDetailsData?.agency_privacy_policy.privacy_policy_file_size,
                                })
                            }

                            setCheckLogoFavId({
                                ...checkLogoFavId,
                                logoId: agencyDetailsData.agency_logo_gcs_id,
                                favIconId: agencyDetailsData.agency_favicon_gcs_id,
                                privacyPolicyId: agencyDetailsData?.agency_privacy_policy && agencyDetailsData?.agency_privacy_policy?.privacy_policy_gcs_id
                            })

                        }
                        FlagApiLoader(false)
                    }
                    else {
                        if (res?.data?.error?.message) {
                            console.log("getAgencyData ERROR RESPONSE", res)
                            UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                            })
                            FlagApiLoader(false)
                        }
                        else {
                            console.log("getAgencyData ERROR RESPONSE", res)
                            UpdateSnackbarMessage({
                                status: "error",
                                message: "Something Went Wrong!"
                            })
                            FlagApiLoader(false)
                        }
                    }
                },
                (err) => {
                    console.log("getAgencyData ERROR RESPONSE", err)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: err?.data?.error?.message
                    })
                }
            )
            if (!subAgency) {
                FlagApiLoader(true)
                GetAgencyCarrierData(agencyId,
                    (res) => {
                        if (res?.data?.status) {
                            const data = res?.data?.data[0]

                            // HERE EXTRACTING ALL THE CARRIER IDs TO GET CARRIER LIST FOR THE TABLE ROWs DROPDOWN.

                            if (data?.agency_commission_data.length != 0) {
                                const lifeFilteredCarrierData = data?.agency_commission_data.find(data => data.carrier_type_id == 1)
                                // console.log('lifeFilteredCarrierData', lifeFilteredCarrierData)
                                setSelectedTemplate({
                                    template_id: lifeFilteredCarrierData?.template_id ? lifeFilteredCarrierData.template_id : null,
                                    carrier_type_flag: lifeFilteredCarrierData?.carrier_type_id ? lifeFilteredCarrierData.carrier_type_id : null,
                                    template_name: lifeFilteredCarrierData?.template_name ? lifeFilteredCarrierData.template_name : null
                                })
                            }
                            setAgencyCarrierDetails(data)
                        }
                    }
                )
            }
        } else {
            setAgencyCarrieData([])
        }

        if (!subAgency) {
            // BELOW CALL FOR LIFE ANNUITY CARRIER DATA LIST AND TEMPLATE DATA LIST
            let params = {
                agency_id: agency_id
            }
            if (pathName != "/agency/add_agency") {
                const fetchTemplateAndCarrierData = async () => {
                    const templatAndCarrierPromise = [fetchCarrierData(),]
                    //   fetchTemplateDataList(params) removed from templatAndCarrierPromise array
                    await Promise.all(templatAndCarrierPromise)
                }
                fetchTemplateAndCarrierData()
            }
        }
        if (pathName != "/agency/add_agency") {
            if (!subAgencyAdmin) {
                const ba_agency_id = userDetail[0]?.user_agency_data?.agency_id
                fetchBATemplateData({ agency_id: ba_agency_id })
            }
        }
    }, [])


    useEffect(() => {
        if (carrierTypeDetailsForAgency?.length > 0) {
            const initialStates = carrierTypeDetailsForAgency?.reduce((object, carrier) => {
                const carrierName = carrier?.carrier_type_name
                object.objectOfObject[carrierName] = {}
                object.arrayOfObject[carrierName] = []
                return object
            }, { arrayOfObject: {}, objectOfObject: {}, nullCarriers: {} })
            const { objectOfObject, arrayOfObject } = initialStates
            // SETTING INIITAL VALUES OF ALL STATE BASED ON CARRIER TYPES 
            setAlreadyCheckedKeys({ ...arrayOfObject })
            setCarrierCheckBoxes({ ...arrayOfObject })
            setCheckedItems({ ...objectOfObject })

            // ADD THIS FOR DYNAMIC TYPE 
            setAgencyCarrierLevelList({ ...arrayOfObject })
            setAgencyTemplateDataList({ ...arrayOfObject })
        }
    }, [carrierTypeDetailsForAgency])

    useEffect(() => {
        if (pathName != '/agency/add_agency')
            if (carrierTypeDetailsForAgency?.length != 0 && Object?.keys(agencyCarrierDetails) != 0) {
                const carrierTypeObjectWithIds = {}
                agencyCarrierDetails?.agency_commission_data?.forEach(agc_data => {
                    const carrierName = carrierTypeDetailsForAgency?.find(carrier => carrier?.carrier_type_id == agc_data?.carrier_type_id)?.carrier_type_name
                    const carrierIds = (agc_data.template_assign_flag == true ?
                        agc_data.template_data.map(tmp_data => tmp_data.carrier_id)
                        :
                        agc_data.agent_carrier_assignment_data.map(aca_data => aca_data.carrier_id))
                    // storing all these carrier ids to fetch there carrier level data 
                    carrierTypeObjectWithIds[carrierName] = carrierIds
                })

                if (Object.keys(carrierTypeObjectWithIds).length != 0) {
                    fecthAgencyCarrierLevelDataList(carrierTypeObjectWithIds)
                }
            }
    }, [carrierTypeDetailsForAgency, agencyCarrierDetails])

    console.log(carrierTypeDetailsForAgency, "qwerty carrierTypeDetailsForAgency")
    console.log(agencyCarrierDetails, "qwerty agencyCarrierDetails")

    // USEEFFECT TO SORT LIFE AND ANNUITY DATA
    useEffect(() => {
        if (carrierTypeDetailsForAgency?.length > 0) {
            // this reduce function only used to set initial empty objects and array carrier type name as a key
            const initialCheckedItemAndAreadyCheckedObject = carrierTypeDetailsForAgency?.reduce((object, carrier) => {
                const carrierName = carrier?.carrier_type_name
                object.checkedItems[carrierName] = {}
                object.alreadyChecked[carrierName] = []
                return object
            }, { checkedItems: {}, alreadyChecked: {} })

            const initialCheckedItemObject = initialCheckedItemAndAreadyCheckedObject?.checkedItems
            const initialAlreadyCheckedItemObject = initialCheckedItemAndAreadyCheckedObject?.alreadyChecked

            carrierTypesList?.forEach((carrier) => {
                carrier?.carrier_types?.forEach((item) => {
                    // NOTE:carrierId and carrier type id both are different.
                    // same this for name also
                    const carrierTypeName = item?.carrier_type_name
                    const carrierId = item?.carrier_id
                    const checkedItem = agencyCarrierData?.find(checkedItem => (
                        (checkedItem.carrier_id == item?.carrier_id)
                        &&
                        (checkedItem.carrier_type_id == item?.carrier_type_id)
                    ))
                    initialAlreadyCheckedItemObject[carrierTypeName].push({
                        carrier_name: carrier?.carrier_name,
                        carrier_id: item?.carrier_id,
                        carrier_type_flag: item?.carrier_type_flag,
                        checked: !!checkedItem,
                        alreadyChecked: !!checkedItem,
                        agency_carrier_id: checkedItem?.agency_carrier_id || null
                    })
                    if (checkedItem) {
                        initialCheckedItemObject[carrierTypeName][carrierId] = {
                            carrier_name: carrier?.carrier_name,
                            checked: true,
                            alreadyChecked: true,
                            carrier_id: item.carrier_id,
                            carrier_type_flag: item.carrier_type_flag,
                            agency_carrier_id: checkedItem.agency_carrier_id
                        }
                    }
                })
            })
            // console.log('initialAlreadyCheckedItemObject', initialAlreadyCheckedItemObject)
            setCarrierCheckBoxes({ ...initialAlreadyCheckedItemObject })
            setCheckedItems({ ...initialCheckedItemObject })
        }
    }, [agencyCarrierData, carrierTypesList, carrierTypeDetailsForAgency])


    useEffect(() => {
        const checkValidation = () => {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(agencyDetails.agency_email_address);
            const isValidAdminEmails = agencyDetails?.agency_user_data?.every(admin => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(admin.email_address));
            const isValidCustomUrl = customUrlRegex.test(agencyDetails.agency_custom_url)
            const isEmptyCustomUrl = agencyDetails.agency_custom_url == "" && agencyDetails.agency_custom_url == null
            // const isHtmlCodeEmpty = agencyHtmlContent == "" || agencyHtmlContent == null
            // const isHtmlFileUploaded = agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_url == "" || agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_id == null

            let duplicateEmailFound = false;
            const duplicateEmails = new Set()

            for (const userData of agencyDetails?.agency_user_data) {
                if (duplicateEmails.has(userData?.email_address)) {
                    duplicateEmailFound = true
                    break
                }
                duplicateEmails.add(userData?.email_address)
            }

            const isValidAgency = (
                agencyDetails.agency_name &&
                ((isValidCustomUrl) || (!customUrlChecked) || (customUrlChecked && isEmptyCustomUrl)) &&
                isValidEmail &&
                isValidAdminEmails &&
                (agencyDetails.agency_user_data.length > 0 ? agencyDetails.agency_user_data.every(admin => admin.username && admin.email_address) : false)
                //TEMPORARY COMMENTED 03-06-24, THIS CONDITION IS TO VALIDATE THE HTML FILE AND HTML CODE WITH CREATE AGENCY BUTTON , 
                // && (!isHtmlFileUploaded || !isHtmlCodeEmpty)
            );
            setIsDisabled(!isValidAgency || duplicateEmailFound);
        };
        checkValidation();
    }, [agencyDetails, customUrlChecked, agencyHtmlContent]);

    const fetchBATemplateData = async (payLoad) => {
        FlagApiLoader(true)
        await GetAgencyTemplateDataList(payLoad, (res) => {
            if (res?.data?.status) {
                const templateData = res?.data?.data
                setAgencyTemplateDetails([...templateData])
                setAgencyDuplicateTemplateDetails(res?.data?.data)
                FlagApiLoader(false)
            } else {
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                })
                FlagApiLoader(false)
            }
        },
            (err) => {
                console.log('Error Of GetAgencyTemplateDataList==>', err)
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                })
                FlagApiLoader(false)
            }
        )
    }

    const fetchCarrierData = async () => {
        FlagApiLoader(true)
        await GetCarrierDataList(
            '',
            (res) => {
                if (res?.data?.status) {
                    const LifeAnnuityCombineData = res?.data?.data
                    setCarrierTypesList([...LifeAnnuityCombineData])
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        console.log("GetCarrierDataList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("GetCarrierDataList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("GetCarrierDataList ERROR RESPONSE", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            }
        )
    }

    const fetchTemplateDataList = async (payLoad) => {
        FlagApiLoader(true)
        await GetAgencyTemplateDataList(payLoad,
            (res) => {
                if (res?.data?.status) {
                    const agencyTemplates = res.data.data

                    console.log('GetAgencyTemplateDataList', res)
                    const lifeTemplate = agencyTemplates.filter(templates => templates.carrier_type_flag)
                    const annuityTemplate = agencyTemplates.filter(templates => !templates.carrier_type_flag)
                    setAgencyTemplateDataList({
                        life: lifeTemplate,
                        annuity: annuityTemplate
                    })
                    setAgencyTemplateDetails(res?.data?.data)
                    setAgencyDuplicateTemplateDetails(res?.data?.data)
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        console.log("GetAgencyTemplateDataList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("GetAgencyTemplateDataList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("GetAgencyTemplateDataList err>>>>", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            }
        )

    }

    const fecthAgencyCarrierLevelDataList = async (carrierTypeObjectWithIds) => {
        const carrierIds = Object?.values(carrierTypeObjectWithIds).flat()
        const carrierTypesNamesArray = Object?.keys(carrierTypeObjectWithIds)
        // console.log('carrierTypesNamesArray', carrierTypesNamesArray)
        // console.log('carrierIds flat=======>', carrierIds)


        FlagApiLoader(true)
        await GetAgencyCarrierLevelList(carrierIds,
            (res) => {
                if (res?.data?.status) {
                    const carrierLevelList = res.data.data
                    const carrierTypesLevelObject = carrierTypesNamesArray?.reduce((carrerTypesLevelObject, key) => {
                        // Filter the carrierLevelList based on the carrier id and 
                        // storing them into respective carrier type as a carrier_type_name key.   
                        carrerTypesLevelObject[key] = carrierLevelList.filter(carrier =>
                            carrierTypeObjectWithIds[key]?.some(id => id == carrier.carrier_id)
                        );
                        return carrerTypesLevelObject;
                    }, {})
                    // const lifeCarrierLevelList = carrierLevelList?.filter(carrier => lifeAnnnuityCarrierIdsObject?.life?.some(id => id == carrier.carrier_id))
                    // const annuityCarrierLevelList = carrierLevelList?.filter(carrier => lifeAnnnuityCarrierIdsObject?.annuity?.some(id => id == carrier.carrier_id))
                    // console.log('carrierTypesLevelObject', carrierTypesLevelObject)
                    setAgencyCarrierLevelList(carrierTypesLevelObject)
                    FlagApiLoader(false)
                } else {
                    if (res?.data?.error?.message) {
                        console.log("GetAgencyCarrierLevelList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("GetAgencyCarrierLevelList ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("GetAgencyCarrierLevelList ERROR RESPONSE", err)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
                FlagApiLoader(false)
            }
        )

    }

    const fetchAgencyData = async (agencyid) => {
        FlagApiLoader(true)
        await getAgencyData(agencyid,
            (res) => {
                console.log("RESPONSE OF AGENCY DATA", res)
                if (res?.data?.status) {
                    const checkedData = res?.data?.data[0].agency_carrier_data
                    const agencyDetailsData = res?.data?.data[0]
                    setAgencyCarrieData(checkedData)
                    if (res?.data?.data) {
                        setCreateAgencyId(res?.data?.data[0].agency_id)
                        setAgencyDetails({
                            ...agencyDetails,
                            agency_name: agencyDetailsData?.agency_name,
                            agency_email_address: agencyDetailsData?.agency_email_address,
                            // agency_mag_url: agencyDetailsData?.agency_mag_url.split(".myadvisorgrids.com")[0],
                            agency_mag_url: agencyDetailsData?.agency_mag_url,
                            agency_custom_url: agencyDetailsData.agency_custom_url,
                            agency_user_data: agencyDetailsData?.agency_user_data?.map((ele, i) => {
                                return {
                                    index: i,
                                    master_user_id: ele.master_user_id,
                                    user_id: ele.user_id,
                                    username: ele.username,
                                    email_address: ele.email_address,
                                    user_role_flag: ele.user_role_flag,
                                }
                            }),
                            agency_logo_gcs_id: agencyDetailsData?.agency_logo_gcs_id,
                            agency_favicon_gcs_id: agencyDetailsData?.agency_favicon_gcs_id,
                            agency_favicon_url: agencyDetailsData?.agency_favicon_url,
                            agency_logo_url: agencyDetailsData?.agency_logo_url,
                            agnecy_status_name: agencyDetailsData?.agnecy_status_name,
                            agency_privacy_policy: agencyDetailsData?.agency_privacy_policy
                        })
                        setAgencyHtmlContent(agencyDetailsData?.agency_privacy_policy?.privacy_policy_content)
                        setFileUploaded(agencyDetailsData?.agency_logo_name || agencyDetailsData?.agency_logo_size ? true : false)
                        setFileSelected({
                            ...selectedSize,
                            name: agencyDetailsData?.agency_logo_name,
                            size: agencyDetailsData?.agency_logo_size,
                        })
                        setFavIconFileUploaded(agencyDetailsData.agency_favicon_name || agencyDetailsData?.agency_favicon_size ? true : false)
                        setAgencyFavIcon({
                            ...agencyFavIcon,
                            name: agencyDetailsData.agency_favicon_name,
                            size: agencyDetailsData?.agency_favicon_size,
                        })

                        if (agencyDetailsData.agency_privacy_policy) {
                            setHtmlFileUploaded(agencyDetailsData.agency_privacy_policy.privacy_policy_file_name || agencyDetailsData?.agency_privacy_policy.privacy_policy_file_size ? true : false)
                            setAgencyHtmlFile({
                                ...agencyHtmlFile,
                                name: agencyDetailsData?.agency_privacy_policy.privacy_policy_file_name,
                                size: agencyDetailsData?.agency_privacy_policy.privacy_policy_file_size,
                            })
                        }

                        setCheckLogoFavId({
                            ...checkLogoFavId,
                            logoId: agencyDetailsData.agency_logo_gcs_id,
                            favIconId: agencyDetailsData.agency_favicon_gcs_id,
                            privacyPolicyId: agencyDetailsData?.agency_privacy_policy && agencyDetailsData?.agency_privacy_policy?.privacy_policy_gcs_id
                        })

                    }
                    FlagApiLoader(false)
                }
                else {
                    if (res?.data?.error?.message) {
                        console.log("getAgencyData ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("getAgencyData ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("getAgencyData ERROR RESPONSE", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            }
        )
        FlagApiLoader(false)
    }
    const isValidHtml = (html) => {
        // Void elements
        if (html == null || html == "") return false

        const voidElements = [
            'area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input',
            'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'
        ];

        const tags = html.match(/<[^>]+>/g);
        if (!tags) return false; // No tags at all, not valid if we expect HTML

        const stack = [];

        for (let tag of tags) {
            const tagNameMatch = tag.match(/<\/?(\w+)/);
            if (!tagNameMatch) continue; // Invalid tag, skip

            const tagName = tagNameMatch[1].toLowerCase();
            if (tag.startsWith('</')) {
                // Closing tag
                if (stack.length == 0 || stack.pop() != tagName) {
                    // Stack is empty (no opening tag before) or tags do not match
                    return false;
                }
            } else if (!voidElements.includes(tagName)) {
                // Opening tag that is not a void element
                stack.push(tagName);
            }
        }
        return stack.length == 0; // Stack should be empty if all tags are matched
    };


    const handleSubmitAgency = (e) => {
        let inValidEmailFound = false;

        if (!emailRegex.test(agencyDetails?.agency_email_address)) {
            UpdateSnackbarMessage({ status: "error", message: "Invalid email address" });
            return;
        }

        for (let user of agencyDetails?.agency_user_data) {

            if (!emailRegex.test(user.email_address)) {
                UpdateSnackbarMessage({ status: "error", message: "Invalid email address in table" });
                inValidEmailFound = true
                break;
            }
        }

        if (inValidEmailFound) {
            return;
        }

        if (agencyHtmlContent != "" && agencyHtmlContent != null) {
            if (!isValidHtml(agencyHtmlContent)) {
                UpdateSnackbarMessage({ status: "error", message: "Invalid HTML Code" });
                return
            }
        }

        if (agencyId || subAgency) {
            const agencyID = subAgency ? agencyIdFromUserDetailsCtx : agencyId
            let agencyUpdatePayload = {
                agency_id: agencyID,
                agency_email_address: agencyDetails?.agency_email_address,
                agency_mag_url: agencyDetails?.agency_mag_url,
                agency_custom_url: agencyDetails.agency_custom_url,
                agency_logo_gcs_id: agencyDetails.agency_logo_gcs_id,
                agency_favicon_gcs_id: agencyDetails.agency_favicon_gcs_id,
                agency_privacy_policy_gcs_id: agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_id,
                agency_privacy_policy_content: agencyHtmlContent,
                agency_user_data: agencyDetails?.agency_user_data?.map((admin) => ({
                    username: admin?.username,
                    email_address: admin?.email_address,
                    user_role_flag: admin?.user_role_flag,
                    master_user_id: admin?.master_user_id ? admin?.master_user_id : null,
                    user_id: admin?.user_id ? admin?.user_id : null,
                    agent_id: admin?.agent_id ? admin?.agent_id : null
                })),
            }
            console.log("agencyUpdatePayload", agencyUpdatePayload);
            FlagApiLoader(true);
            updateAgency(agencyUpdatePayload,
                async (res) => {
                    console.log("Update Agency Response", res?.data?.data);
                    if (res?.data?.status) {
                        if (res?.data?.data.code == 9056) {

                            UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.data.message,
                            });

                        }
                        else {
                            const agencyid = res?.data?.data[0]?.agency_id
                            setCreateAgencyId(agencyid)
                            await fetchAgencyData(agencyid)
                            UpdateSnackbarMessage({ status: "success", message: "Agency updated successfully" });
                        }
                        // setAgencyDetails(res?.data?.data?.[0])

                        FlagApiLoader(false);
                        setToggleDetails("agency_details")
                        setAgencyUpdated(true)
                        // navigate('/agency')
                    }
                    else {
                        if (res?.data?.error?.message) {
                            UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                            FlagApiLoader(false)
                        }
                        else {
                            UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                            FlagApiLoader(false)
                        }
                    }
                },
                (err) => {
                    console.log("error", err);
                    FlagApiLoader(false);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!",
                    });
                    navigate('/agency')
                })
        }
        else {
            let agencyPayload = {
                agency_name: agencyDetails?.agency_name,
                agency_email_address: agencyDetails?.agency_email_address,
                agency_mag_url: agencyDetails?.agency_mag_url + `${process.env.REACT_APP_MAG_DOMAIN}`,
                agency_custom_url: agencyDetails.agency_custom_url,
                agency_logo_gcs_id: agencyDetails.agency_logo_gcs_id,
                agency_favicon_gcs_id: agencyDetails.agency_favicon_gcs_id,
                privacy_policy_gcs_id: agencyDetails?.agency_privacy_policy?.privacy_policy_gcs_id,
                privacy_policy_content: agencyHtmlContent,
                agency_user_data: agencyDetails?.agency_user_data?.map((admin) => ({
                    username: admin?.username,
                    email_address: admin?.email_address,
                    user_role_flag: admin?.user_role_flag,
                    agent_id: admin?.agent_id ? admin?.agent_id : null
                })),
            }
            console.log("agencyPayload", agencyPayload)
            FlagApiLoader(true);
            createNewAgency(agencyPayload,
                async (res) => {
                    console.log("create agency response", agencyPayload);
                    if (res?.data?.status) {
                        UpdateSnackbarMessage({
                            status: "success",
                            message: "Agency Created Succesfully",
                        });

                        // -----------NEED TO REMOVE THIS NO ERROR OCCURS AFTER TESTING------------
                        // setCheckLogoFavId({
                        //     ...checkLogoFavId,
                        //     logoId: res?.data?.data[0].agency_logo_gcs_id,
                        //     favIconId: res?.data?.data[0].agency_favicon_gcs_id,
                        //     privacyPolicyId: res?.data?.data[0]?.privacy_policy_gcs_id
                        // })
                        // setAgencyDetails(res?.data?.data[0])
                        // ------------------------------------------------------------------------

                        const agencyid = res?.data?.data[0]?.agency_id
                        setCreateAgencyId(agencyid)
                        await fetchAgencyData(agencyid)

                        navigate(`/agency/view_agency?agency_id=${res?.data?.data[0]?.agency_id}`)
                        setToggleDetails("agency_carrier")

                        const fetchTemplateAndCarrierData = async () => {
                            const templatAndCarrierPromise = [fetchCarrierData(), fetchBATemplateData({ agency_id: userDetail[0]?.user_agency_data?.agency_id })]
                            await Promise.all(templatAndCarrierPromise)
                        }
                        await fetchTemplateAndCarrierData()

                        FlagApiLoader(false);
                    }
                    else {
                        if (res?.data?.error?.message) {
                            UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                            FlagApiLoader(false)
                        }
                        else {
                            UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                            FlagApiLoader(false)
                        }
                    }
                },
                (err) => {
                    console.log("create agency error", err)
                    FlagApiLoader(false);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!",
                    });
                    navigate('/agency')
                })
        }
    }

    const handleSaveCarrierDetails = () => {
        const checkedCarriers = Object.keys(checkedItems).reduce((arr, key) => {
            const checkedItemArray = Object.keys(checkedItems[key]).reduce((acc, item) => {
                if (checkedItems[key][item].checked == true) {
                    const payLoadItme = checkedItems[key][item]
                    acc.push({
                        carrier_id: payLoadItme.carrier_id,
                        carrier_type_flag: payLoadItme.carrier_type_flag,
                        // check if  agency_carrier_id==null, if its null the don't add agency_carrier_id 
                        ...(payLoadItme.agency_carrier_id && { agency_carrier_id: payLoadItme.agency_carrier_id })
                    })
                }
                return acc
            }, [])
            return [...arr, ...checkedItemArray]
        }, [])

        const agency_id = searchParams.get("agency_id")
        const payLoad = {
            agency_id: agency_id,
            agency_carrier_data: checkedCarriers
        }

        FlagApiLoader(true)
        addUpdateAgencyCarrier(
            payLoad,
            (res) => {
                if (res.data.status) {
                    getAgencyData(
                        agency_id, (res) => {
                            if (res?.data?.status) {
                                const checkedData = res?.data?.data[0].agency_carrier_data
                                setAgencyCarrieData(checkedData)
                                FlagApiLoader(false)
                            } else {
                                if (res?.data?.error?.message) {
                                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                                    FlagApiLoader(false)
                                }
                                else {
                                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                                    FlagApiLoader(false)
                                }
                            }
                        }, (err) => {
                            UpdateSnackbarMessage({
                                status: "error",
                                message: err?.data?.error?.message,
                            })
                        })
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message,
                    })
                } else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                }

            },
            (err) => {
                console.log("handleSaveCarrier err", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
            }
        )
    }

    const handleToggleDetails = useCallback((detailsType) => {
        setToggleDetails(detailsType);
    }, [toggleDetails]);

    return (
        <>
            <Grid container sx={{ height: "100%" }}>
                <Grid item xs={12} sx={{ height: "calc(100vh - 79px)" }} className='main-item-1'>
                    <Grid container sx={{ height: "100%" }}>
                        <Grid item xs={12} sx={{ marginTop: "15px" }}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item xs={3}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        {subAgency ? (<></>) :
                                            (<IconButton
                                                sx={{ padding: "0px 10px 0px 0px", cursor: "pointer" }}
                                                onClick={() => {
                                                    const rout = subAgency ? "/" : "/agency"
                                                    navigate(rout)
                                                }}
                                            >
                                                <img src={BackIcon} alt="Logo" style={{ height: '24px', width: 'auto' }} />
                                            </IconButton>)
                                        }
                                        <Typography
                                            variant='span'
                                            className='ag-header-main-typo'
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                const rout = subAgency ? "/" : "/agency"
                                                navigate(rout)
                                            }}
                                        >
                                            {agencyId ? agencyDetails.agency_name : "Agency"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container sx={{ justifyContent: 'center' }}>
                                        <Grid item xs={subAgency ? 6.5 : 12}>
                                            {(userDetail[0]?.user_agency_data?.parent_agency_id == null && userDetail[0]?.agency_admin_flag == true) ?
                                                (
                                                    <Grid container className={agencyId ? 'ag-header-navigation-btns-container' : ''}>
                                                        <>
                                                            {(pathName == "/agency/view_agency") &&
                                                                <>
                                                                    <Button
                                                                        variant={isAgencyDetails ? "contained" : "text"}
                                                                        className={isAgencyDetails ? "ag-active-btn " : 'ag-navigation-btn'}
                                                                        onClick={() => setToggleDetails("agency_details")}
                                                                    >
                                                                        Agency Details
                                                                    </Button>

                                                                    <Button
                                                                        // disabled
                                                                        variant={isWhiteLabelDomain ? "contained" : "text"}
                                                                        className={isWhiteLabelDomain ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                        // onClick={() => setToggleDetails("whitelist_domain")}
                                                                        onClick={() => handleToggleDetails("whitelist_domain")}
                                                                    >
                                                                        Whitelist Domain&nbsp;
                                                                        <Typography
                                                                            variant='span'
                                                                            className={isWhiteLabelDomain ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                            sx={{
                                                                                fontSize: '10px',
                                                                                fontWeight: '500',
                                                                                borderRadius: '30px',
                                                                                padding: '1px 7px',
                                                                                color: isWhiteLabelDomain ? 'white' : '#1565C0',
                                                                                backgroundColor: isWhiteLabelDomain ? "#495059 !important" : 'trasparent',
                                                                                border: isWhiteLabelDomain ? '' : '1px solid #1565C0'
                                                                            }}
                                                                        >
                                                                            Coming Soon
                                                                        </Typography>
                                                                    </Button>

                                                                    <Button
                                                                        variant={isAgencyCarrier ? "contained" : "text"}
                                                                        className={isAgencyCarrier ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                        onClick={() => handleToggleDetails("agency_carrier")}

                                                                    // onClick={() => setToggleDetails("agency_carrier")}
                                                                    >
                                                                        Agency Carriers
                                                                    </Button>

                                                                    <Button
                                                                        variant={isBaTemplate ? "contained" : "text"}
                                                                        className={isBaTemplate ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                        onClick={() => setToggleDetails("ba_template")}

                                                                    >
                                                                        BA Templates
                                                                    </Button>

                                                                    <Button
                                                                        variant={isAgencyTemplate ? "contained" : "text"}
                                                                        className={isAgencyTemplate ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                        onClick={() => setToggleDetails("agency_template")}
                                                                    >
                                                                        Agency Templates
                                                                    </Button>
                                                                </>
                                                            }
                                                        </>
                                                    </Grid>
                                                ) : (
                                                    (userDetail[0]?.user_agency_data?.parent_agency_id != null && userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "admin") ?
                                                        (
                                                            <Grid container className={'ag-header-navigation-btns-container'}>
                                                                <>
                                                                    <Button
                                                                        variant={isAgencyDetails ? "contained" : "text"}
                                                                        className={isAgencyDetails ? "ag-active-btn " : 'ag-navigation-btn'}
                                                                        onClick={() => setToggleDetails("agency_details")}
                                                                    >
                                                                        Agency Details
                                                                    </Button>

                                                                    <Button
                                                                        // disabled
                                                                        variant={isWhiteLabelDomain ? "contained" : "text"}
                                                                        className={isWhiteLabelDomain ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                        onClick={() => setToggleDetails("whitelist_domain")}
                                                                    >
                                                                        Whitelist Domain&nbsp;
                                                                        <Typography
                                                                            variant='span'
                                                                            className={isWhiteLabelDomain ? "ag-active-btn" : 'ag-navigation-btn'}
                                                                            sx={{
                                                                                fontSize: '10px',
                                                                                fontWeight: '500',
                                                                                borderRadius: '30px',
                                                                                padding: '1px 7px',
                                                                                color: isWhiteLabelDomain ? 'white' : '#1565C0',
                                                                                backgroundColor: isWhiteLabelDomain ? "#495059 !important" : 'trasparent',
                                                                                border: isWhiteLabelDomain ? '' : '1px solid #1565C0'
                                                                            }}
                                                                        >
                                                                            Coming Soon
                                                                        </Typography>
                                                                    </Button>
                                                                </>
                                                            </Grid>
                                                        ) :
                                                        (<></>)
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={!subAgency ? 4 : 3}></Grid>
                            </Grid>
                        </Grid>

                        {/* DIVIDER */}
                        <Grid item xs={12} sx={{ marginTop: "15px" }} >
                            <Divider />
                        </Grid>

                        <Grid item xs={12} className={isBaTemplate ? 'agc-details-baTemp-item' : 'agc-details-center-griditem'}>
                            <Grid container className={'agency-details-container'}>
                                <Grid item
                                    md={agencyId ? 9.5 : 11} lg={agencyId ? 10 : 8} xl={isAgencyDetails ? 8 : 12}
                                    sx={{ height: isAgencyDetails ? "calc(100% - 60px)" : "100%" }}
                                    className='agc-details-comp-item'
                                >
                                    <Grid container className={isBaTemplate || isAgencyTemplate ? "agc-details-ba-temp-container" : 'agc-details-center-comp-container'}>
                                        <Grid item xs={12} sx={{ height: "100%" }}>
                                            {
                                                isAgencyDetails ? (
                                                    <AgencyDetails
                                                        setCheckLogoFavId={setCheckLogoFavId}
                                                        checkLogoFavId={checkLogoFavId}
                                                        setCustomUrlChecked={setCustomUrlChecked}
                                                        customUrlChecked={customUrlChecked}
                                                        isValidHtml={isValidHtml}
                                                        setIsDisabled={setIsDisabled}
                                                    />
                                                ) : isWhiteLabelDomain ? (
                                                    <AgencyWhiteLabelDoamin />
                                                ) : isAgencyCarrier ? (
                                                    <AgencyCarrier
                                                        checkedItems={checkedItems}
                                                        carrierCheckBoxes={carrierCheckBoxes}
                                                        alreadyCheckedKey={alreadyCheckedKey}
                                                        selectedTemplate={selectedTemplate}
                                                        setCheckedItems={setCheckedItems}
                                                        setCarrierCheckBoxes={setCarrierCheckBoxes}
                                                        setSelectedTemplate={setSelectedTemplate}
                                                        fecthAgencyCarrierLevelDataList={fecthAgencyCarrierLevelDataList}
                                                    />
                                                ) : isBaTemplate ? (
                                                    <AgencyCloneTemplate />
                                                ) : isAgencyTemplate ? (
                                                    <AgencyTemplate />
                                                ) : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* {isAgencyDetails && <Grid item md={agencyId ? 0 : 0.5} lg={agencyId ? 0 : 2} xl={2}></Grid>} */}

                                {
                                    isAgencyDetails &&
                                    <Grid item xs={12} sx={{ marginTop: "20px" }}>
                                        <Grid container className='agc-button-container'>

                                            <Button
                                                size='large'
                                                variant="outlined"
                                                sx={{ width: "140px", fontWeight: "500 !important" }}
                                                onClick={() => {
                                                    const rout = subAgency ? "/" : "/agency"
                                                    navigate(rout)
                                                }}
                                            >
                                                Cancel
                                            </Button>


                                            <Button
                                                size='large'
                                                variant="contained"
                                                sx={{ width: "140px", fontWeight: "400 !important" }}
                                                disabled={isDisabled}
                                                onClick={(e) => handleSubmitAgency(e)}
                                            >
                                                {agencyId || subAgency ? "Save" : "Create Agency"}
                                            </Button>

                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyCarrierDetails