import React, { useContext } from 'react'
import { ClickAwayListener, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import Icons from '../../../components/icons/icons';
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import "../../css/ViewCarriers.css"


const ViewCarrierPreviewDialog = (props) => {

    const { userDetail } = useContext(authContext).state

    const {
        productMappingListData,
    } = useContext(CarrierContext).state
    const {
        editDetails,
        flagAfterLevelPopoverRender,
        flagCustomizationCarriarSectionDialog,
        flagCarrierCustomizationGlobalDeleteDialog,
        deleteRowPopupFlag,
        isSelectedRow,
        hideTransparentCell,
        flagCarrierCustomizationDeleteDialog,
        selectedRowGroupIdOfDialog,
        setFlagLevelTablePopup,
        setSelectedRowGroupOfDialog,
        carrierDetails,
        rightSideHeaders,
        leftSideHeaders,

        userRoleAdmin,
        userRoleMember,
        agencyNameBrokersAlliance,
        brokersAllianceAgent,
        subAgency,

        setStoredRowDataId,
        storedRowDataId,
        setFlagProductMappingDialog,
        setProductRowGroupDetails

    } = props

    const notABrokersAlliance = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance"

    const generateTooltipContent = (criteria) => {

        if (!criteria || criteria.length == 0) return "";
        const criteriaMap = {};

        criteria?.forEach((crit) => {
            const { criteria_name, carrier_product_criteria_data } = crit;

            if (!criteriaMap[criteria_name]) {
                criteriaMap[criteria_name] = [];
            }

            switch (criteria_name) {
                // case "Sub Type":
                //   const subtypeValue = carrier_product_criteria_data?.map((data) => data.criteria_field_value)?.join(", ");
                //   criteriaMap[criteria_name]?.push(subtypeValue);
                //   break;

                case "Sub Type":
                    const subtypeValue = carrier_product_criteria_data?.filter(data => data.criteria_field_value !== null && data.criteria_field_value !== "")?.map(data => data.criteria_field_value)?.join(", ");
                    if (subtypeValue) {
                        if (!criteriaMap[criteria_name]) {
                            criteriaMap[criteria_name] = [];
                        }
                        criteriaMap[criteria_name].push(subtypeValue);
                    }
                    break;


                case "Issue Age":
                    const issueAgeRanges = [];
                    let issueFrom = "";
                    let issueTo = "";

                    carrier_product_criteria_data.forEach((element) => {
                        if (element?.criteria_field_name === "From Age" && element?.criteria_field_value) {
                            issueFrom = element.criteria_field_value;
                        } else if (element?.criteria_field_name === "To Age") {
                            if (element?.criteria_field_option_value === "Lifetime") {
                                issueTo = "+";
                            } else if (element?.criteria_field_option_value === "Custom Text Field" && element?.criteria_field_value) {
                                issueTo = ` to ${element.criteria_field_value}`;
                            }
                            issueAgeRanges.push(`${issueFrom}${issueTo}`);
                            issueFrom = "";
                            issueTo = "";
                        }
                    });
                    criteriaMap[criteria_name]?.push(issueAgeRanges.join(`| `));
                    break;

                case "Renewal Year":
                    const renewalYearRanges = [];
                    let renewalFrom = "";
                    let renewalTo = "";

                    carrier_product_criteria_data.forEach((element) => {
                        if (element?.criteria_field_name === "Start Year" && element?.criteria_field_value) {
                            renewalFrom = element.criteria_field_value;
                        }
                        else if (element?.criteria_field_name === "End Year") {
                            if (element?.criteria_field_option_value === "Lifetime") {
                                renewalTo = "+";
                            }
                            else if (element?.criteria_field_option_value === "Custom Text Field" && element?.criteria_field_value) {
                                renewalTo = ` to ${element.criteria_field_value}`;
                            }
                            renewalYearRanges.push(`${renewalFrom}${renewalTo}`);
                            renewalFrom = "";
                            renewalTo = "";
                        }
                    });
                    criteriaMap[criteria_name]?.push(renewalYearRanges.join(`| `));
                    break;

                case "State":
                    const stateValues = carrier_product_criteria_data?.filter((data) => data?.criteria_field_option_value);
                    if (stateValues?.find(element => element?.criteria_field_option_value === "Select All")) {
                        criteriaMap[criteria_name].push("All Available");
                    }
                    else {
                        const states = stateValues?.map(element => element?.criteria_field_option_value);

                        // Determine number of columns based on state count
                        const columnCount = states.length > 30 ? 3 : states.length > 15 ? 2 : 1;

                        if (columnCount == 1) {
                            // Show states as a single comma-separated list
                            criteriaMap[criteria_name].push(states.join(", "));
                        }
                        else {
                            // Split states into columns
                            const chunkSize = Math.ceil(states.length / columnCount);
                            const columns = Array.from({ length: columnCount }, (_, colIndex) => states.slice(colIndex * chunkSize, (colIndex + 1) * chunkSize));

                            const tableRows = Array.from({ length: chunkSize }).map((_, rowIndex) => {
                                const rowCells = columns.map((column) => column[rowIndex] || ""); // Handle uneven splits
                                return `
                  <tr>
                      ${rowCells.map((state) => `<td style="padding-right: 20px; font-size: 13px; color: #FFFFFF;">${state}</td>`).join("")}
                  </tr>
              `;
                            }).join("");

                            criteriaMap[criteria_name].push(`<table style="width: 100%; border-spacing: 0; border-collapse: collapse;">${tableRows}</table>`);
                        }
                    }
                    break;

                default:
            }
            // case "Issue Age":
            //   const ageOrYearValues = carrier_product_criteria_data?.map(element => {
            //     if (element?.criteria_field_name == "From Age") {
            //       return element?.criteria_field_value
            //     }
            //     else {
            //       return (
            //         element?.criteria_field_option_value == "Not Applicable" ? "" :
            //           element?.criteria_field_option_value == "Lifetime" ? "+" :
            //             element?.criteria_field_option_value == "Custom Text Field" ? " to " + element?.criteria_field_value :
            //               " "
            //       );
            //     }
            //   })
            //   criteriaMap[criteria_name]?.push(ageOrYearValues?.join(" | "));
            //   break;

            // case "Renewal Year":
            //   // Handle Issue Age and Renewal Year.
            //   const ageOrYearValues1 = carrier_product_criteria_data?.map(element => {
            //     if (element?.criteria_field_name == "Start Year") {
            //       return element?.criteria_field_value
            //     }
            //     else {
            //       return (
            //         element?.criteria_field_option_value == "Not Applicable" ? "" :
            //           element?.criteria_field_option_value == "Lifetime" ? "+" :
            //             element?.criteria_field_option_value == "Custom Text Field" ? " to " + element?.criteria_field_value :
            //               " "
            //       );
            //     }
            //   })
            //   criteriaMap[criteria_name]?.push(ageOrYearValues1.join(" | "));
            //   break;

            // case "State":
            //   // Handle State.1
            //   const stateValues = carrier_product_criteria_data?.filter((data) => data?.criteria_field_option_value)

            //   if (stateValues?.filter(element => element?.criteria_field_option_value == "Select All")?.length) {
            //     criteriaMap[criteria_name].push("All Available");
            //   } else {
            //     criteriaMap[criteria_name].push(stateValues?.filter((val) => val)?.map(element => element?.criteria_field_option_value).join(", "));
            //   }
            //   break;
        });

        Object.keys(criteriaMap)?.forEach(key => {
            if (!criteriaMap[key]?.length) {
                delete criteriaMap[key];
            }
        });

        const criteriaDescriptions = Object.entries(criteriaMap)?.map(([key, values]) => {
            const capitalizedKey = key?.charAt(0)?.toUpperCase() + key?.slice(1)?.toLowerCase();
            return `
    <div style="margin-bottom: 5px">
      <table >
        <thead></thead> <!-- Empty thead for structure -->
        <tbody>
          <tr>
            <td
             style="
                text-overflow: ellipsis; 
                padding-right: 10px; 
                vertical-align: baseline;
                min-width: 98px;
              "
            >
              <span
                class="input-span"
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #A0A0A0;
                margin-bottom: 5px"
              >
                ${capitalizedKey}:
              </span>
            </td>
            <td>
              <span
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #FFFFFF;"
              >
                ${values.map((value, index) => index < values.length - 1 ? `${value}<span style="color:rgb(145, 145, 145);"> | </span>` : value).join("")}  
                <!-- Combine all values into one row -->
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      `;
        });
        return criteriaDescriptions.join("")
    }

    const generateInputContent = (criteria) => {
        if (!criteria || criteria.length === 0) return "";

        const criteriaMap = {};

        criteria.forEach((crit) => {
            const { criteria_name, carrier_product_criteria_data } = crit;

            if (!criteriaMap[criteria_name]) {
                criteriaMap[criteria_name] = [];
            }

            switch (criteria_name) {
                case "Sub Type":
                    // Handle Sub Type directly using criteria_field_value.
                    const subtypeValue = carrier_product_criteria_data
                        .map((data) => data.criteria_field_value)
                        .join(", ");
                    criteriaMap[criteria_name].push(subtypeValue);
                    break;

                case "Issue Age":
                    const issueAgeValues = carrier_product_criteria_data.map((rr) => {
                        if (rr.criteria_field_name == "From Age") {
                            return rr.criteria_field_value;
                        } else {
                            return rr.criteria_field_option_value === "Not Applicable"
                                ? ""
                                : rr.criteria_field_option_value === "Lifetime"
                                    ? "+"
                                    : rr.criteria_field_option_value === "Custom Text Field"
                                        ? " to " + rr.criteria_field_value
                                        : " ";
                        }
                    });
                    criteriaMap[criteria_name].push(issueAgeValues.join(""));
                    break;

                case "Renewal Year":
                    const renewalYearValues = carrier_product_criteria_data.map((rr) => {
                        if (rr.criteria_field_name == "Start Year") {
                            return rr.criteria_field_value;
                        } else {
                            return rr.criteria_field_option_value === "Not Applicable"
                                ? ""
                                : rr.criteria_field_option_value === "Lifetime"
                                    ? "+"
                                    : rr.criteria_field_option_value === "Custom Text Field"
                                        ? " to " + rr.criteria_field_value
                                        : " ";
                        }
                    });
                    criteriaMap[criteria_name].push(renewalYearValues.join(""));
                    break;

                case "State":
                    const stateValues = carrier_product_criteria_data.filter(
                        (data) => data.criteria_field_option_value
                    );
                    if (
                        stateValues.filter((rr) => rr.criteria_field_option_value == "Select All")
                            .length
                    ) {
                        criteriaMap[criteria_name].push("All Available");
                    } else {
                        criteriaMap[criteria_name].push(
                            stateValues
                                .filter((val) => val)
                                .map((rr) => rr.criteria_field_option_value)
                                .join(", ")
                        );
                    }
                    break;

                default:
                    break;
            }
        });

        // Convert the map into a readable format.
        const criteriaDescriptions = Object.entries(criteriaMap).map(([key, values]) => {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
            return `${capitalizedKey}: ${values.join(", ")}`;
        });

        return criteriaDescriptions.join(" | ");
    };

    return (
        <>
            <Grid item xs={12} textAlign="center">
                <Grid container className="" sx={{ justifyContent: "center", padding: { xs: "10px", sm: "20px" } }}>
                    <Grid item xs={12} textAlign={"end"} marginBottom={{ xs: "5px", sm: "10px" }}>
                        <Close
                            sx={{ cursor: "pointer" }}
                            width="100%"
                            onClick={() => {
                                setFlagLevelTablePopup(false)
                                setSelectedRowGroupOfDialog((prevId) => ({ ...prevId, selectedId: null }))
                            }}
                        />
                    </Grid>

                    <TableContainer
                        className=""
                        sx={{ height: "80vh" }}
                        component={Paper}
                    >
                        <Table
                            className="templateCarrierTable"
                            aria-label="a dense table"
                            size="small"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    {(userRoleAdmin && agencyNameBrokersAlliance) &&
                                        <TableCell className="header-color sticky-table-head  ct-dialog-product-mapping-header" sx={{ borderRight: "1px solid white" }}>
                                            {/* BLANK HEADER */}
                                        </TableCell>
                                    }

                                    <TableCell className={`header-color sticky-table-head ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-product-header-agentview" : "ct-dialog-product-header"}`}>
                                        Product
                                    </TableCell>

                                    <TableCell className={`header-color sticky-table-head ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-criteria-header-agentview" : "ct-dialog-criteria-header"}`}>
                                        Criteria
                                    </TableCell>

                                    <TableCell className={`header-color sticky-table-head ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-notes-header-agentview" : "ct-dialog-notes-header"}`}
                                        sx={{
                                            borderRight: (notABrokersAlliance || brokersAllianceAgent) ? "2px solid #EFEFF0" : "",
                                        }}
                                    >
                                        Notes
                                    </TableCell>

                                    {(userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ?
                                        <TableCell
                                            className={`header-color sticky-table-head ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-baComp-header-agentview" : "ct-dialog-baComp-header"}`}
                                            sx={{
                                                borderRight: "2px solid white",
                                                // HERE UPDATE BG COLOR FOR THIS COLUMN 
                                                backgroundColor: "#F1C232 !important",
                                            }}
                                        >
                                            BA Total Comp
                                        </TableCell>
                                        :
                                        //  HERE I COMMENTED CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY 
                                        <>
                                            {leftSideHeaders?.map((leftCell, leftIndex) => {
                                                return (
                                                    <TableCell
                                                        className="header-color"
                                                        sx={{
                                                            minWidth: '100px',
                                                            maxWidth: '100px',
                                                            background: `${leftCell?.carrier_level_color_hex_code} !important`,
                                                            borderRight: leftCell && leftCell?.carrier_ba_level_flag ? "2px solid white" : ""
                                                            // HERE COMMENTED BECAUSE ALREADY SEPARATED LEFT AND RIGH SIDE HEADER
                                                            // borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                        }}
                                                    >
                                                        {leftCell?.carrier_level_name}
                                                    </TableCell>
                                                )
                                            })}
                                        </>
                                        // HERE I COMMENTED CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY
                                    }

                                    {
                                        (userRoleAdmin || userRoleMember) ?
                                            carrierDetails?.carrier_level?.map((levelRow, i) => {
                                                return (
                                                    <TableCell
                                                        className="header-color"
                                                        align="left"
                                                        stickyHeader
                                                        sx={{ minWidth: '60px', background: `${levelRow?.carrier_level_color_hex_code} !important` }}
                                                    >
                                                        {levelRow?.carrier_level_name}
                                                    </TableCell>
                                                );
                                            })
                                            :
                                            <>
                                                {rightSideHeaders?.map((rightCell, i) => {
                                                    return (
                                                        <TableCell
                                                            className="header-color"
                                                            align="left"
                                                            stickyHeader
                                                            sx={{
                                                                minWidth: '60px',
                                                                backgroundColor: `${rightCell?.carrier_level_color_hex_code} !important`,
                                                                // HERE COMMENTED BECAUSE ALREADY SEPARATED LEFT AND RIGH SIDE HEADER
                                                                // borderRight: (i === 0) ? '2px solid white' : ""
                                                            }}
                                                        >
                                                            {rightCell?.carrier_level_name}
                                                        </TableCell>
                                                    );
                                                })}
                                            </>
                                    }

                                    {1000 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                        <TableCell
                                            id="blank-tablecell"
                                            className={`sticky-table-head ${editDetails ? "addLeft_Border" : ""}`}
                                            sx={{
                                                width: `${1000 - carrierDetails?.carrier_level?.length * 60}px`,
                                                backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                                color: "#ffffff"
                                            }}
                                            colSpan={5}
                                        >
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <ClickAwayListener onClickAway={() => setSelectedRowGroupOfDialog((prevId) => ({ ...prevId, selectedId: null }))} >
                                <TableBody>
                                    {carrierDetails?.carrier_product?.map((groups) => {
                                        return (
                                            groups?.carrier_product_data?.map((groupData, i) => {

                                                let flagSaprator = groups?.carrier_product_data.length == i + 1 ? true : false;
                                                let leftSideData = groupData?.carrier_product_commission_data?.filter((obj) => obj.carrier_ba_level_flag == true)
                                                let rightSideData = groupData?.carrier_product_commission_data?.filter((obj) => !obj.carrier_ba_level_flag)
                                                const productName = productMappingListData?.find((item) => item?.product_ai_id == groupData?.carrier_product_ai_mapping_data?.product_ai_id)?.product_name

                                                const selectedGroupId = groups?.carrier_product_id || groups.carrier_group_id_custom
                                                const sectedRowId = groups?.carrier_product_data[i].carrier_product_data_id || groups?.carrier_product_data[i].carrier_group_data_id_custom
                                                const carrierGroupDataLen = groups?.carrier_product_data?.length
                                                return (
                                                    <TableRow
                                                        className={`
                                                            vc-table-row 
                                                            ${selectedGroupId == selectedRowGroupIdOfDialog.selectedId ? carrierGroupDataLen <= 1 ? "border-all border-container" :
                                                                `${i === 0 ? "border-top" : flagSaprator ? "border-bottom" : "bordered-left-right"} border-container` :
                                                                sectedRowId == isSelectedRow ? "border-all border-container" : ""
                                                            }`
                                                        }
                                                        onClick={() => {
                                                            setSelectedRowGroupOfDialog((prevId) => ({ ...prevId, selectedId: selectedGroupId }))
                                                        }}
                                                    >
                                                        {(userRoleAdmin && agencyNameBrokersAlliance) &&
                                                            <TableCell
                                                                sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", borderRight: "1px solid #cfcfcf" }}
                                                                className={`sticky-table-body sticky-table-color ct-dialog-product-mapping-body`}
                                                            >
                                                                <Grid item sx={{ height: "100%" }}>
                                                                    <Grid container sx={{ height: '100%', alignItems: "center", justifyContent: "center" }}>
                                                                        {groupData?.carrier_product_ai_mapping_data && groupData?.carrier_product_ai_mapping_data?.product_ai_id ?
                                                                            (
                                                                                <>
                                                                                    <Grid item
                                                                                        onMouseEnter={() => { setStoredRowDataId(groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom); }}
                                                                                        onMouseLeave={() => { setStoredRowDataId(null) }}
                                                                                        sx={{ cursor: !!groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "not-allowed !important" : "pointer" }}
                                                                                    >
                                                                                        <Grid container>
                                                                                            <Tooltip
                                                                                                arrow
                                                                                                PopperProps={{ className: 'expand-preview-tooltip' }}
                                                                                                placement="right-end"
                                                                                                title={
                                                                                                    <div>
                                                                                                        <div style={{ fontSize: "12px", fontWeight: "500", color: "#676767", marginBottom: "5px" }}>
                                                                                                            AI Product Mapping ID & Name
                                                                                                        </div>
                                                                                                        <div style={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}>
                                                                                                            ({groupData?.carrier_product_ai_mapping_data?.product_ai_id}) {productName}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                componentsProps={{
                                                                                                    tooltip: {
                                                                                                        sx: {
                                                                                                            bgcolor: "#F8F8F8",
                                                                                                            padding: "10px",
                                                                                                            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                                                                                                        },
                                                                                                    },
                                                                                                }}
                                                                                            >
                                                                                                <IconButton
                                                                                                    disabled={groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id}
                                                                                                    onClick={() => {
                                                                                                        setProductRowGroupDetails({
                                                                                                            groups: groups,
                                                                                                            groupData: groupData
                                                                                                        });
                                                                                                        setFlagProductMappingDialog(true);
                                                                                                    }}
                                                                                                >
                                                                                                    {(!carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id || storedRowDataId != (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)) ? (
                                                                                                        <Icons
                                                                                                            iconName="mappedIcon"
                                                                                                            iconColor={carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "#0DBD0D" : "#BDBDBD"}
                                                                                                            iconWidth="18px"
                                                                                                            iconHeight="18px"
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <Icons
                                                                                                            iconName="editIcon"
                                                                                                            iconColor="#000000"
                                                                                                            iconWidth="17px"
                                                                                                            iconHeight="17px"
                                                                                                        />
                                                                                                    )}
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid item
                                                                                        onMouseEnter={() => { setStoredRowDataId(groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom); }}
                                                                                        onMouseLeave={() => { setStoredRowDataId(null); }}
                                                                                        sx={{ cursor: !!groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "not-allowed !important" : "pointer" }}
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                setProductRowGroupDetails({ groups: groups, groupData: groupData });
                                                                                                setFlagProductMappingDialog(true);
                                                                                            }}
                                                                                            disabled={!!groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id}
                                                                                        >
                                                                                            {groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? (
                                                                                                <Icons
                                                                                                    iconName="cloneIcon"
                                                                                                    iconColor="#BDBDBD"
                                                                                                    iconWidth="17px"
                                                                                                    iconHeight="17px"
                                                                                                />
                                                                                            ) : storedRowDataId == groupData?.carrier_product_data_id ? (
                                                                                                <Icons
                                                                                                    iconName="addIcon"
                                                                                                    iconColor="#000000"
                                                                                                    iconWidth="17px"
                                                                                                    iconHeight="17px"
                                                                                                />
                                                                                            ) : (
                                                                                                <Icons
                                                                                                    iconName="cloneIcon"
                                                                                                    iconColor="#1565C0"
                                                                                                    iconWidth="17px"
                                                                                                    iconHeight="17px"
                                                                                                />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </>
                                                                            )}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        }

                                                        <TableCell
                                                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
                                                            className={`sticky-table-body sticky-table-color ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-product-body-agentview" : "ct-dialog-product-body "}`}
                                                        >
                                                            {groupData?.carrier_product_name?.length > 22 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        placement="right-end"
                                                                        title={groupData?.carrier_product_name}
                                                                        disableHoverListener={groupData?.carrier_product_name?.length < 22}
                                                                        disableFocusListener={groupData?.carrier_product_name?.length < 22}
                                                                        PopperProps={{
                                                                            className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100, cursor: 'default' },
                                                                        }}
                                                                    >
                                                                        <div className='vc-dialog-product-tooltip-styles'>
                                                                            {groupData?.carrier_product_name}
                                                                        </div>
                                                                    </Tooltip>
                                                                ) :
                                                                <div style={{ cursor: 'default' }}>
                                                                    {groupData?.carrier_product_name}
                                                                </div>
                                                            }
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", cursor: "pointer" }}
                                                            className={`sticky-table-body sticky-table-color ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-criteria-body-agentview" : "ct-dialog-criteria-body"}`}

                                                        // className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-3"
                                                        >
                                                            {groupData?.carrier_product_criteria?.length > 0 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        placement="right"
                                                                        title={
                                                                            groupData?.carrier_product_criteria?.length > 0 ?
                                                                                (<div dangerouslySetInnerHTML={{ __html: generateTooltipContent(groupData?.carrier_product_criteria) }} />) : (<></>)
                                                                        }
                                                                        PopperProps={{
                                                                            // className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100, maxWidth: "480px", },
                                                                        }}
                                                                        componentsProps={{
                                                                            tooltip: {
                                                                                sx: {
                                                                                    padding: "10px",
                                                                                    bgcolor: "#1B2430",
                                                                                    color: "#ffffff",
                                                                                    padding: "10px",
                                                                                    maxWidth: "480px",
                                                                                },
                                                                            },
                                                                        }}
                                                                    >
                                                                        <div className='vc-dialog-product-tooltip-styles' style={{ maxWidth: "150px", display: "inline-block", border: "none" }}>{generateInputContent(groupData?.carrier_product_criteria)}</div>
                                                                    </Tooltip>
                                                                ) :
                                                                (<div className='vc-dialog-product-tooltip-styles' style={{ maxWidth: "150px", display: "inline-block", border: "none", }}>{generateInputContent(groupData?.carrier_product_criteria)}</div>)
                                                            }
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                borderRight: (notABrokersAlliance || brokersAllianceAgent) ? "2px solid #EFEFF0" : ""
                                                            }}
                                                            className={`sticky-table-body sticky-table-color ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-notes-body-agentview" : "ct-dialog-notes-body "}`}
                                                        >
                                                            {groupData?.carrier_product_note?.replace(/->/g, "").trim()?.length > 26 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={groupData?.carrier_product_note?.replace(/->/g, "").trim()}
                                                                        PopperProps={{
                                                                            className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100, cursor: 'default' },
                                                                        }}
                                                                        placement="right-end"
                                                                    >
                                                                        <div className="vc-dialog-product-tooltip-styles" style={{ cursor: 'default' }}>
                                                                            {groupData?.carrier_product_note?.replace(/->/g, "").trim()}
                                                                        </div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <div style={{ cursor: 'default' }}>
                                                                        {groupData?.carrier_product_note?.replace(/->/g, "").trim()}
                                                                    </div>
                                                                )
                                                            }
                                                        </TableCell>

                                                        {((userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance) ?
                                                            (
                                                                <TableCell
                                                                    sx={{
                                                                        borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                        borderRight: "2px solid #EFEFF0"
                                                                    }}
                                                                    className={`sticky-table-body sticky-table-color ${(brokersAllianceAgent || userRoleMember || notABrokersAlliance) ? "ct-dialog-baComp-body-agentview" : "ct-dialog-baComp-body"}`}
                                                                >
                                                                    {groupData?.carrier_product_ba_total_comp?.length > 13 ?
                                                                        (
                                                                            <Tooltip
                                                                                arrow
                                                                                title={groupData?.carrier_product_ba_total_comp}
                                                                                PopperProps={{
                                                                                    className: 'vc-tabel-tooltip',
                                                                                    style: { zIndex: 2100, cursor: 'default' },
                                                                                }}
                                                                                placement="right-end"
                                                                            >
                                                                                <div className="vc-dialog-product-tooltip-styles" style={{ cursor: 'default' }}>
                                                                                    {groupData?.carrier_product_ba_total_comp}
                                                                                </div>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <div style={{ cursor: 'default' }}>
                                                                                {groupData?.carrier_product_ba_total_comp}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </TableCell>
                                                            )
                                                            :
                                                            (
                                                                // HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY 
                                                                <>
                                                                    {leftSideData?.map((leftDataCell) => {
                                                                        let applyBorderRight = leftSideHeaders?.some((leftHeader) => Number(leftHeader?.carrier_level_id) == Number(leftDataCell?.carrier_level_id) && leftHeader?.carrier_ba_level_flag == true);
                                                                        return (
                                                                            <TableCell
                                                                                sx={{
                                                                                    padding: "0px 15px !important",
                                                                                    borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                                    // HERE COMMENTED BECAUSE ALREADY SEPARATED LEFT AND RIGH SIDE HEADER
                                                                                    // borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : ""
                                                                                    borderRight: applyBorderRight ? "2px solid #EFEFF0" : ""
                                                                                }}
                                                                                className="sticky-table-body sticky-table-color ct-dialog-left-data-body"
                                                                            >
                                                                                {leftDataCell?.carrier_product_commission_value}
                                                                            </TableCell>
                                                                        )
                                                                    })}
                                                                </>
                                                                //  HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY 
                                                            )
                                                        }

                                                        {
                                                            (userRoleAdmin || userRoleMember) ?
                                                                // THIS IS BODY DATA OF ADMIN AND MEMBER
                                                                groupData?.carrier_product_commission_data?.map((commissionData) => {
                                                                    return (
                                                                        <TableCell
                                                                            sx={{
                                                                                padding: "0px 15px !important",
                                                                                borderBottom: flagSaprator ? "7px solid #EFEFF0" : ""
                                                                            }}
                                                                        >
                                                                            {commissionData?.carrier_product_commission_value}
                                                                        </TableCell>
                                                                    )
                                                                })
                                                                :
                                                                // THIS IS BODY DATA OF AGENT 
                                                                rightSideData?.map((rightDataCell, i) => {
                                                                    let applyBorderRight = leftSideHeaders?.some((leftHeader) => Number(leftHeader?.carrier_level_id) == Number(rightDataCell?.carrier_level_id) && leftHeader?.carrier_ba_level_flag == true);
                                                                    return (
                                                                        <TableCell
                                                                            sx={{
                                                                                padding: "0px 15px !important",
                                                                                borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                                borderRight: applyBorderRight ? "2px solid #EFEFF0" : ""
                                                                                // HERE COMMENTED BECAUSE ALREADY SEPARATED LEFT AND RIGH SIDE HEADER
                                                                                // borderRight: i == 0 ? "2px solid #EFEFF0" : ""
                                                                            }}
                                                                        >
                                                                            {rightDataCell?.carrier_product_commission_value}
                                                                        </TableCell>
                                                                    )
                                                                })
                                                        }

                                                        {
                                                            ((carrierDetails?.carrier_level?.length > 0 || carrierDetails?.carrier_level?.length == 0) && (1000 - carrierDetails?.carrier_level?.length * 60 >= 60)) &&
                                                            (
                                                                <TableCell
                                                                    className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                                    colSpan={5}
                                                                    sx={{
                                                                        borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                                        borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
                                                                        width: `${1000 - carrierDetails?.carrier_level?.length * 60}px `
                                                                    }}
                                                                >
                                                                </TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                )
                                            }))
                                    })}
                                </TableBody>
                            </ClickAwayListener>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}


export default ViewCarrierPreviewDialog