import React from 'react'
import { Typography, Grid, Button } from "@mui/material";
import { Close } from '@mui/icons-material';
import deleteAlert from '../../../assets/Delete-alert.png'
import '../../css/ViewCarriarStyles/UnsavedChangesDialog.css'
import { useNavigate } from "react-router-dom";

const UnsavedChangesDialog = (props) => {
    const pathname = window.location.pathname
    const navigate = useNavigate()

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ padding: "5px 0px" }}>
                    <Grid container>
                        <Grid item xs={12} className='vcd-close-icon-item'>
                            <Close
                                sx={{ cursor: "pointer", fontSize: "18px" }}
                                onClick={() => {
                                    props.setEditDetails(true)
                                    props.setHideTransperentCell(true)
                                    props.setFlagUnsavedChangesDialog(false)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <img src={deleteAlert}></img>
                        </Grid>
                        <Grid item xs={11} className='vcd-warning-text-item' >
                            <Typography variant='span' className='vcd-warning-text'>
                                You have unsaved changes. If you leave without saving, your changes will be lost.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className='vcd-btn-text-item' >
                            <Grid container sx={{ justifyContent: "center", gap: "25px" }} >
                                <Grid item xs={2.5}>
                                    <Grid container sx={{ justifyContent: "end" }}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    props.setFlagUnsavedChangesDialog(false)
                                                    props.setEditDetails(false)
                                                    props.setHideTransperentCell(false)
                                                    props.setCarrierDetails(props.carrierDetailsDuplicate)
                                                    props.removeUnsavedChangesHandler()
                                                    if (pathname == '/carriers/add_carrier') {
                                                        navigate('/carriers')
                                                    }
                                                }}

                                            >Yes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color='error'
                                                onClick={() => {
                                                    // props.setEditDetails(true)
                                                    props.setHideTransperentCell(true)
                                                    props.setFlagUnsavedChangesDialog(false)
                                                }}
                                            >No</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UnsavedChangesDialog