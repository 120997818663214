import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import '../css/ViewCarriers.css'

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CleaningServices, Close, ExpandMore } from "@mui/icons-material";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// DIALOG
import DeleteCarrierLevelPopUp from "./ViewCarrierDialogs/DeleteCarrierLevelPopup";
import DeleteCarriarSectionConfirmationDialog from "./ViewCarrierDialogs/DeleteCarriarSectionConfirmationDialog";
import LevelCustomizationDialog from "./ViewCarrierDialogs/LevelCustomizationDialog";
import UnsavedChangesDialog from "./ViewCarrierDialogs/UnsavedChangesDialog";
import GlobalDialog from "../../components/global/GlobalDialog";
import DeleteCarrierSectionDialog from "./ViewCarrierDialogs/DeleteCarrierSectionDialog";
import ResourcesAccordians from "./CarrierComponents/ResourcesAccordians";
import CarrierPDFViewerDialog from "./CarrierComponents/CarrierPDFViewerDialog";
import CriteriaDialog from "./ViewCarrierDialogs/CriteriaDialog";
import FileUploadDialog from "./ViewCarrierDialogs/FileUploadDialog";
import DeleteResourceAlertDialog from "./ViewCarrierDialogs/DeleteResourceAlertDialog";
import ViewCarrierPreviewDialog from "./ViewCarrierDialogs/ViewCarrierPreviewDialog";

// MUI 
import {
  Link,
  Divider as MuiDivider,
  Typography,
  Grid,
  TextField,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  useMediaQuery,
  Backdrop,
  Tooltip,
  ClickAwayListener,
  Dialog,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import Loader from "../Loader/loader";
import styled from "@emotion/styled";
import Popover from '@mui/material/Popover';

// API
import { CheckCarrierExistence, CreateCarrierAPI, getAICarrierListAPI, getAICarrierProductListAPI, GetCarrierData, GetCarrierDetailsField, getCarrierEffectiveDateListAPI, GetCarrierLevelColorList, GetCarrierVersionListData, getCriteriaGenericDataAPI, UpdateCarrierAPI } from "../../utils/api/CarriersApi";
import { DeleteResources, GetCarrierDataList } from "../../utils/api/TemplateApi";
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";

// CONTEXT
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";
import { Context as CarrierContext } from "../../contexts/reducerContexts/globalCarrierContext"
import Icons from "../../components/icons/icons";
import ProductMappingDialog from "./ViewCarrierDialogs/ProductMappingDialog";
import CarrierMappingDialog from "./ViewCarrierDialogs/CarrierMappingDialog";
import CarrierTableNavigation from "./ViewCarrierComponents/CarrierTableNavigation";
import ColumnLevelPopover from "./ViewCarrierComponents/ColumnLevelPopover";
import InsertRowPopover from "./ViewCarrierComponents/InsertRowPopover";
import AddNewSectionPopover from "./ViewCarrierComponents/AddNewSectionPopover";
import TableAccordianHeader from "./ViewCarrierComponents/TableAccordianHeader";
import SaveSetEffectiveDateDialog from "./ViewCarrierDialogs/SaveSetEffectiveDateDialog";
import CarrierEffectiveDialog from "./ViewCarrierDialogs/CarrierEffectiveDialog";
import moment from "moment";


const Divider = styled(MuiDivider)(spacing);
const CustomLabelTypography = styled(Typography)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding-top:4.5px
`;


const ViewCarrier = () => {

  // CONTEXT
  const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)
  const { userDetail, apiLoader, carrierTypeDetailsForAgency } = useContext(authContext).state
  const { setCarrierData } = useContext(templateContext)
  const { carrierData } = useContext(templateContext).state
  const { carrierName, productMappingListData } = useContext(CarrierContext).state
  const {
    setGenericStateList,
    setCriteriaGenericData,
    setCarrierMappingListData,
    setProductMappingListData
  } = useContext(CarrierContext)


  // CONST
  const location = useLocation();
  const navigate = useNavigate();
  const minScreenWidth = useMediaQuery('(max-width:1280px)')
  const PathName = window.location.pathname
  const defaultCriteriaText = {
    carrier_product_id: 0,
    carrier_product_id_custom: 0,
    carrier_product_data_id: 0,
    carrier_product_data_id_custom: 0,
  }

  // REF
  const tableContainerRef = useRef(null)
  const tableRef = useRef(null);
  const textFieldRef = useRef(null);
  const levelNameInputRef = useRef(null);

  const subAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance"
  const brokersAllianceAdmin = (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance")
  const brokersAllianceMember = (userDetail[0]?.user_role_name == "Member" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance")

  const userRoleAdmin = userDetail[0]?.user_role_name == "Admin"
  const userRoleMember = userDetail[0]?.user_role_name == "Member"
  const brokersAllianceAgent = userDetail[0]?.user_role_name == "Agent"
  const agencyNameBrokersAlliance = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
  const subAgencyAdmin = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" && userDetail[0]?.user_role_name == "Admin"

  // LOCAL VARIABLE
  const [editDetails, setEditDetails] = useState(window.location.pathname == "/carriers/add_carrier" ? true : false)
  const [carrierVersionList, setCarrierVersionList] = useState()
  const [carrierDetails, setCarrierDetails] = useState({ carrier_details: { carrier_data_column_left: [], carrier_data_column_right: [] } })
  const [carrierNameDetailsDuplicate, setCarrierNameDetailsDuplicate] = useState({ carrier_details: { carrier_data_column_left: [], carrier_data_column_right: [] } })
  const [carrierKeyDetails, setCarrierKeyDetails] = useState({
    carrier_data_column_left: [],
    carrier_data_column_right: []
  });
  const [carrierDetailsDuplicate, setCarrierDetailsDuplicate] = useState([])
  const [currentVersion, setCurrentVersion] = useState("")
  const [changeInCarrierType, setChangeInCarrierType] = useState(null)
  const [flagCall, setFlagCall] = useState("default")
  const [imageUrl, setImageUrl] = useState("")
  let [searchParams, setSearchParams] = useSearchParams();
  const [flagFileUploadPopup, setFlagFileUploadPopup] = useState(false);
  const [deleteResourceData, setDeleteresourceData] = useState({ carrier_detail: {}, file_detail: {} })
  const [carrierTypeId, setCarrierTypeId] = useState()
  const [AddColumn, setAddColumn] = useState(null)
  const [addRow, setAddRow] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElForRow, setAnchorElForRow] = React.useState(null);
  const [anchorElForLevelsColumn, setAnchorElForLevelsColumn] = React.useState({ type: null, columnId: 0 });
  const [selectedRowGroupId, setSelectedRowGroup] = useState({ selectId: null, selectedId: null })
  const [selectedRowGroupIdOfDialog, setSelectedRowGroupOfDialog] = useState({ selectId: null, selectedId: null })
  const [isSelectedRow, setSelectedRow] = useState(null)
  const [flagDialogGloballyRender, setFlagDialogGloballyRender] = useState(false)
  const [flagAfterLevelPopoverRender, setFlagAfterLevelPopoverRender] = useState(false)
  const [flagCreateEffectiveDateRender, setFlagCreateEffectiveDateRender] = useState(false)


  // LEVEL FLOW:----------------------------
  const [levelSelectedData, setLevelSelectedData] = useState(null)
  const [colorList, setColorList] = useState([])
  const [selectedColor, setSelectedColor] = useState({ color: null, hexCode: null })
  const [sectionOrRow, setSectionOrRow] = useState('')
  const [emptyLevelName, setEmptyLevelName] = useState(false)

  // DIALOG:----------------------------
  const [flagDeleteCarriarSectionDialog, setFlagDeleteCarriarSectionDialog] = useState(false)
  const [flagCustomizationCarriarSectionDialog, setFlagCustomizationCarriarSectionDialog] = useState(false)
  const [flagUnsavedChangesDialog, setFlagUnsavedChangesDialog] = useState(false)
  const [selectedLine, setSelectedLine] = useState(null)
  const [selectedLinePopover, setSelectedLinePopover] = useState({ groups: null, index: null })
  const [selectedRowIndex, setSelectedRowIndex] = useState({ groupData: null, levelIdx: null, groupIdx: null })
  const [flagShowCloseLevelSection, setFlagShowCloseLevelSection] = useState(true)
  const [flagAddRow, setFlagAddRow] = useState(false)
  const [flagShowAddIcons, setFlagShowAddIcons] = useState(false)
  const [flagCriteriDialog, setFlagCriteriDialog] = useState(false)
  const [flagCarrierCustomizationGlobalDialog, setFlagCarrierCustomizationGlobalDialog] = useState(false)
  const [flagCarrierCustomizationDeleteDialog, setFlagCarrierCustomizationDeleteDialog] = useState(false)
  const [flagCarrierCustomizationGlobalDeleteDialog, setFlagCarrierCustomizationGlobalDeleteDialog] = useState(false)
  const [flagProductMappingDialog, setFlagProductMappingDialog] = useState(false)
  const [flagCarrierMappingDialog, setFlagCarrierMappingDialog] = useState(false)
  const [flagLevelTablePopup, setFlagLevelTablePopup] = useState(false)
  const [flagPdfPopup, setFlagPdfPopup] = useState(false)
  const [flagDeletePopup, setFlagDeletePopup] = useState(false);
  const [flagResourceExpand, setFlagResourceExpand] = useState(false);
  const [flagCarrierDetailsExpand, setFlagCarrierDetailsExpand] = useState(true);
  const [flagLevelAccordionExpanded, setFlagLevelAccordionExpanded] = useState(true)
  const [flagSetEffectiveDateDialog, setFlagSetEffectiveDateDialog] = useState(false)
  const [flagOpenEffectiveDateDialog, setFlagOpenEffectiveDateDialog] = useState(false)
  const [flagNewProductView, setFlagNewProductView] = useState(false)


  // STORED DATA
  const [storedProductId, setStoredProductId] = useState({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
  const [storedProductCriteriaId, setStoredProductCriteriaId] = useState({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
  const [storedProductNotesId, setStoredProductNoteId] = useState({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
  const [storedProductBatotalId, setStoredProductBaTotalId] = useState({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
  const [deleteRowPopupFlag, setDeleteRowPopupFlag] = useState(false)
  const [showCriteriaText, setShowCriteriaText] = useState(defaultCriteriaText)
  const [needsRefocus, setNeedsRefocus] = useState(false);
  const [editingCell, setEditingCell] = useState(null);
  const [criteriaCellData, setCriteriaCellData] = useState({ groups: null, groupData: null, });
  const [productRowGroupDetails, setProductRowGroupDetails] = useState(null);
  const [storedRowDataId, setStoredRowDataId] = useState(null)
  const [carrierPlaceholderName, setCarrierPlaceholderName] = useState('')
  const [carrierEffectiveDatesDetails, setCarrierEffectiveDatesDetails] = useState([])


  const [productIds, setProductIds] = useState({ productId: null, productDataIds: null, productDataCustomIds: null, index: 0 })
  const [newLevelData, setNewLevelData] = useState({
    carrier_level_after: null,
    carrier_level_id: null,
    carrier_level_name: "",
    carrier_ba_level_flag: false,
    // carrier_level_sequence: 1,
    carrier_level_color_id: 1,
    carrier_level_color_hex_code: "#1b2430",
    carrier_level_color_name: "Default"
  })
  const [hideTransparentCell, setHideTransperentCell] = useState(false)
  const [carrierLevelLength, setCarrierLevelLength] = useState(false)
  const [slectedAddIcon, setSelectedAddIcon] = useState(null)
  const [isColHover, setColHover] = useState(false)
  const [currentlyAddedCols, setCurrentlyAddedCols] = useState([])

  const rowPopOveropen = Boolean(anchorElForRow);
  const rowId = rowPopOveropen ? 'simple-popover1' : undefined;
  const [sliderCoordinates, setSliderCoordinates] = useState({ x: 0, y: 0 })
  const [disableAddNewGroup, setDisableAddNewGroup] = useState(false)
  const numsOfCarrierLevelCols = carrierDetails?.carrier_level?.length


  //  Level Popover
  const columnCellPopoverOpen = Boolean(anchorElForLevelsColumn.type);
  const columnId = columnCellPopoverOpen ? 'simple-popover1' : undefined;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let leftSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj?.carrier_ba_level_flag == true)
  let rightSideHeaders = carrierDetails?.carrier_level?.filter((obj) => !obj?.carrier_ba_level_flag)
  // let Levels
  // if (leftSideHeaders && rightSideHeaders) {
  //   Levels = [...leftSideHeaders, ...rightSideHeaders]
  // }

  // const [skipCheckOnBlur, setSkipCheckOnBlur] = useState(false);
  // const [carrierExitenceErrorMessage, setCarrierExitenceErrorMessage] = useState("");
  // const [flagCarrierNameExist, setFlagCarrierNameExist] = useState(false)
  // const [addLevels, setAddLevels] = useState(null)

  // USE EFFECTS:---------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (needsRefocus && textFieldRef.current) {
      textFieldRef.current.focus();
      setNeedsRefocus(false); // Reset after focusing
    }
  }, [needsRefocus]);

  useEffect(() => {
    const carrierLevelLen = carrierDetails?.carrier_product?.length || 0;
    setCarrierLevelLength(carrierLevelLen < 2);

    const isSingleCarrierLevel = carrierLevelLen <= 1;
    setDisableAddNewGroup(isSingleCarrierLevel);
  }, [carrierDetails]);


  // FOR FOCUS ON LEVEL FIELDS
  useEffect(() => {
    if (slectedAddIcon != null) {
      setTimeout(() => {
        levelNameInputRef?.current?.focus();
      }, 100);
    }
  }, [slectedAddIcon]);

  useEffect(() => {
    if (window.location.pathname == "/carriers/add_carrier") {
      FlagApiLoader(true)
      GetCarrierDetailsField((res) => {
        if (res?.data?.status) {
          console.log("response true of GetCarrierDetailsField api", res);

          let carrier_details = {
            carrier_data_column_left: res?.data?.data?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
            carrier_data_column_right: res?.data?.data?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
          }

          setCarrierKeyDetails({ ...carrierKeyDetails, ...carrier_details });
          setCarrierDetails({
            carrier_name: carrierName,
            carrier_type_id: location?.state?.carrier_type_id,
            carrier_details: carrier_details,
            carrier_ai_mapping_data: {},
            carrier_level: [],
            carrier_product: [
              {
                carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_product_data: [
                  {
                    carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_product_name: "",
                    carrier_product_note: "",
                    carrier_product_ba_total_comp: "",
                    carrier_product_commission_data: [],
                    carrier_product_ai_mapping_data: {}
                  }
                ]
              },
            ],
          })
          setCarrierDetailsDuplicate({
            carrier_name: carrierName,
            carrier_type_id: location?.state?.carrier_type_id, carrier_details: carrier_details,
            carrier_ai_mapping_data: {},
            carrier_level: [],
            carrier_product: [
              {
                carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_product_data: [
                  {
                    carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_product_name: "",
                    carrier_product_note: "",
                    carrier_product_ba_total_comp: "",
                    carrier_product_commission_data: [],
                    carrier_product_ai_mapping_data: {}
                  }
                ]
              },
            ],
          })
          setCarrierNameDetailsDuplicate({
            carrier_name: carrierName,
            carrier_type_id: location?.state?.carrier_type_id, carrier_details: carrier_details,
            carrier_level: [],
            carrier_ai_mapping_data: {},
            carrier_product: [
              {
                carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                // carrier_product_ai_mapping_data: {},
                carrier_product_data: [
                  {
                    carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_product_name: "",
                    carrier_product_note: "",
                    carrier_product_ba_total_comp: "",
                    carrier_product_commission_data: [],
                    carrier_product_ai_mapping_data: {}
                  }
                ]
              },
            ],
          })
          FlagApiLoader(false)
        }
        else {
          console.log("response false of GetCarrierDetailsField api", res);
          FlagApiLoader(false)
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
        (err) => {
          console.log("error of GetCarrierDetailsField api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!",
          });
          FlagApiLoader(false)
        })

      if (userRoleAdmin && agencyNameBrokersAlliance) {
        FlagApiLoader(true)
        GetCarrierLevelColorList(
          (res) => {
            if (res?.data?.status) {
              console.log("response true of GetCarrierLevelColorList api", res);
              setColorList(res?.data?.data?.sort((a, b) => a.carrier_level_color_id - b.carrier_level_color_id))
              FlagApiLoader(false)
            }
            else {
              console.log("response false of GetCarrierLevelColorList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
              FlagApiLoader(false)
            }
          },
          (err) => {
            console.log("error of GetCarrierLevelColorList api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!",
            });
            FlagApiLoader(false)
          })
      }
    }
    else {
      FlagApiLoader(true)
      let carrierIdExists = searchParams.has("id");
      let versionIdExists = searchParams.has("version_id");

      if (carrierIdExists) {
        let searchParamsCarrierId = searchParams.get("id");
        let searchParamsVersionId = searchParams.get("version_id");

        GetCarrierDataList(undefined,
          (res) => {
            if (res?.data?.status) {
              setCarrierData(res.data.data)
              // FlagApiLoader(false)
            }
            else {
              // FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
            }
          },
          (err) => {
            console.log("err", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
            // FlagApiLoader(false)
          }
        )

        // 1st call
        if ((brokersAllianceAdmin || brokersAllianceMember) && !subAgency) {
          GetCarrierVersionListData(searchParamsCarrierId,
            (res) => {
              if (res.data.status) {
                if (searchParamsVersionId) {
                  setCurrentVersion(Number(searchParamsVersionId))
                  // FlagApiLoader(false)
                }
                else {
                  res?.data?.data[0]?.carrier_version_data?.map((row) => {
                    if (row.carrier_version_latest) {
                      setCurrentVersion(row?.carrier_version_id)
                      setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                      mainDataCall(searchParamsCarrierId, row?.carrier_version_id);
                    }
                  })
                }
                setCarrierVersionList(res.data.data[0].carrier_version_data)
              }
              else {
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }
                FlagApiLoader(false)
                navigate("/carriers")
              }
            },
            (err) => {
              FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              navigate("/carriers")
            }
          )

          // EFFECTIVE DATE
          if (brokersAllianceAdmin) {
            const payload = { carrier_id: searchParamsCarrierId };
            fetchCarrierEffectiveDateList(payload);
          }
        }

        // 2nd Call
        if (userRoleAdmin && agencyNameBrokersAlliance) {
          // FlagApiLoader(true)
          GetCarrierLevelColorList((res) => {
            if (res?.data?.status) {
              console.log("response true of GetCarrierLevelColorList api", res);
              setColorList(res?.data?.data?.sort((a, b) => a.carrier_level_color_id - b.carrier_level_color_id))
              // FlagApiLoader(false)
            }
            else {
              console.log("response false of GetCarrierLevelColorList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
            }
          },
            (err) => {
              console.log("error of GetCarrierLevelColorList api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              // FlagApiLoader(false)
            })
        }

        GetCarrierDetailsField((res) => {
          if (res?.data?.status) {
            console.log("response true of GetCarrierDetailsField", res);

            let carrier_details = {
              carrier_data_column_left: res?.data?.data?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
              carrier_data_column_right: res?.data?.data?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
            }

            setCarrierDetails({
              carrier_name: carrierName,
              carrier_type_id: location?.state?.carrier_type_id,
              carrier_details: carrier_details,
              carrier_ai_mapping_data: {},
              carrier_level: [],
              carrier_product: [
                {
                  carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                  carrier_product_data: [
                    {
                      carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                      carrier_product_name: "",
                      carrier_product_note: "",
                      carrier_product_ba_total_comp: "",
                      carrier_product_commission_data: [],
                      carrier_product_ai_mapping_data: {}
                    }
                  ]
                },
              ],
            })
            setCarrierKeyDetails({ ...carrierKeyDetails, ...carrier_details });
          }
          else {
            console.log("response false of GetCarrierDetailsField api", res);
            FlagApiLoader(false)
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
              FlagApiLoader(false)
            }
          }
        },
          (err) => {
            console.log("error of GetCarrierDetailsField api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!",
            });
            FlagApiLoader(false)
          })

        let obj = {
          carrier_id: searchParamsCarrierId,
          carrier_version_id: searchParamsVersionId,
        }

        // 3rd call
        setTimeout(() => {
          GetCarrierData(obj,
            (res) => {
              FlagApiLoader(false)
              if (res.data.status) {
                const responseData = res?.data?.data[0]
                const updatedCarrierDetails = {
                  ...responseData,
                  carrier_details: {
                    carrier_data_column_left: responseData?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                    carrier_data_column_right: responseData?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                  },

                  // carrier_ai_mapping_data: responseData?.carrier_ai_mapping_data ? responseData?.carrier_ai_mapping_data : {},
                  carrier_level: responseData?.carrier_level?.length > 0 ? responseData.carrier_level : [],
                  carrier_product: responseData?.carrier_product?.length > 0 ?
                    responseData?.carrier_product.map(product => ({
                      ...product,
                      carrier_product_data: product.carrier_product_data?.sort((a, b) => parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence))?.map(data => ({
                        ...data,
                        carrier_product_ai_mapping_data: data.carrier_product_ai_mapping_data,
                        carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) => parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)),
                      })),
                    }))
                    :
                    [{
                      carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                      carrier_product_ai_mapping_data: {},
                      carrier_product_data: [
                        {
                          carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                          carrier_product_name: "",
                          carrier_product_note: "",
                          carrier_product_ba_total_comp: "",
                          carrier_product_commission_data: responseData?.carrier_level?.map(dt => ({
                            carrier_ba_level_flag: false,
                            carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                            carrier_level_id: dt?.carrier_level_id,
                            carrier_level_name: dt.carrier_level_name,
                            carrier_level_sequence: "",
                            carrier_product_commission_value: "",
                          })),
                          carrier_product_ai_mapping_data: {},
                          carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : []
                        }
                      ]
                    }]
                };

                setCarrierDetails(updatedCarrierDetails)
                setCarrierPlaceholderName(updatedCarrierDetails?.carrier_name)
                setCarrierNameDetailsDuplicate(updatedCarrierDetails)
                setCarrierDetailsDuplicate(JSON.parse(JSON.stringify(updatedCarrierDetails)))
                setCarrierTypeId(responseData?.carrier_type_id)
              }
              else {
                if (res?.data?.error?.code === 9087) {
                  RevokeFunction()
                }
                else {
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: res?.data?.error?.message
                    });
                  }
                  else {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!"
                    });
                  }
                  FlagApiLoader(false)
                  navigate("/carriers")
                }
              }
            },
            (err) => {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              FlagApiLoader(false)
              navigate("/carriers")
            })
        }, 300);
      }
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        FlagApiLoader(true);
        await getCriteriaGenericDataAPI(
          (res) => {
            if (res?.data?.status) {
              const genericResponse = res?.data?.data
              setCriteriaGenericData(genericResponse);
            }
            else {
              setCriteriaGenericData([])
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
            }
          },
          (err) => {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
          });

        if (userRoleAdmin && agencyNameBrokersAlliance) {
          await getAICarrierListAPI((res) => {
            console.log("response of ai carrier", res);
            if (res?.data?.status) {
              const carrierAIResponse = res?.data?.data
              setCarrierMappingListData(carrierAIResponse);
            }
            else {
              setCarrierMappingListData([])
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
            }

          }, (err) => {
            console.log("response err of ai carrier", err);
          });
        };
      }
      catch (error) {
        console.error("Error fetching data", error);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (userRoleAdmin && agencyNameBrokersAlliance) {
      if (carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id) {
        FlagApiLoader(true)
        const fetchProductMappingData = async () => {
          try {
            const payloadForProductMappingData = {
              carrier_ai_id: carrierDetails.carrier_ai_mapping_data.carrier_ai_id
            };

            await getAICarrierProductListAPI(payloadForProductMappingData,
              (response) => {
                console.log("response of getAICarrierProductListAPI", response);
                const responseData = response?.data?.data
                setProductMappingListData(responseData)
                FlagApiLoader(false)
              },
              (error) => {
                console.log("response of getAICarrierProductListAPI", error);
                setProductMappingListData([])
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something went wrong!",
                });
                FlagApiLoader(false)
              }
            );
          } catch (error) {
            console.error("Error fetching product mapping data", error);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
            FlagApiLoader(false)
          }
        };

        fetchProductMappingData();
      }
    }
  }, [carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id]);

  useEffect(() => {
    setChangeInCarrierType(null)
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
    }

    const fetchRequireData = async () => {
      if (flagCall != "default") {
        FlagApiLoader(true)
        let carrierIdExists = searchParams.has("id");
        let versionIdExists = searchParams.has("version_id");
        let latestVersionId = ""

        if (carrierIdExists) {
          let searchParamsCarrierId = searchParams.get("id");
          let searchParamsVersionId = searchParams.get("version_id");
          let payload = { carrier_id: searchParamsCarrierId }

          await GetCarrierDataList(undefined,
            (res) => {
              if (res.data.status) {
                console.log("GetCarrierDataList TRUE RESPONSE", res);
                setCarrierData(res.data.data)
                FlagApiLoader(false)
              }
              else {
                FlagApiLoader(false)
                console.log("GetCarrierDataList FALSE RESPONSE", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                }
                else {
                  UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                }
              }
            },
            (err) => {
              console.log("GetCarrierDataList ERROR RESPONSE", err);
              UpdateSnackbarMessage({ status: "error", message: "Something went wrong!", });
              FlagApiLoader(false)
            }
          )

          if ((brokersAllianceAdmin || brokersAllianceMember) && !subAgency) {
            FlagApiLoader(true)
            await GetCarrierVersionListData(searchParamsCarrierId,
              (res) => {
                if (res.data.status) {
                  if (searchParamsVersionId) {
                    res?.data?.data[0]?.carrier_version_data?.map((row) => {
                      if (row.carrier_version_latest) {
                        setCurrentVersion(row?.carrier_version_id)
                        setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                        latestVersionId = row?.carrier_version_id;
                        mainDataCall(searchParamsCarrierId, row?.carrier_version_id)
                      }
                    })
                  }
                  else {
                    res?.data?.data[0]?.carrier_version_data?.map((row) => {
                      if (row.carrier_version_latest) {
                        setCurrentVersion(row?.carrier_version_id)
                        setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                        latestVersionId = row?.carrier_version_id;
                        mainDataCall(searchParamsCarrierId, row?.carrier_version_id)
                      }
                    })
                  }
                  setCarrierVersionList(res.data.data[0].carrier_version_data)
                }
                else {
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: res?.data?.error?.message
                    });
                  }
                  else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                  }
                  navigate("/carriers")
                  FlagApiLoader(false)
                }
              },
              (err) => {
                console.log("GetTemplateVersionList ERROR RESPONSE", err);
                UpdateSnackbarMessage({ status: "error", message: "Something went wrong!", });
                FlagApiLoader(false)
                navigate("/carriers")
              }
            )

            // EFFECTIVE DATE 
            if (brokersAllianceAdmin) {
              const payload = { carrier_id: searchParamsCarrierId };
              await fetchCarrierEffectiveDateList(payload);
            }
          }

          if (!searchParamsVersionId && brokersAllianceAdmin) {
            return;
          }

          //BELOW GetCarrierData API SHOULD BE CALL FOR EVERY ROLE BECAUSE CARRIER TAB IS AVAILABLE FOR EVERY ROLE.
          // THAT IS WHAY THE  BELOW CONDITIONS ARE COMMENTED.REMOVE THIS LATER 
          let obj = {
            carrier_version_id: searchParamsVersionId,
            carrier_id: searchParamsCarrierId,
          }
          // THIS CALL TRIGGER ONLY USER ROLE AGENT AND MEMBER ONLY 
          if (brokersAllianceAgent || userRoleMember || subAgency) {
            FlagApiLoader(true)
            await GetCarrierData(obj,
              (res) => {
                if (res.data.status) {
                  const responseData = res?.data?.data[0]
                  const updatedCarrierDetails = {
                    ...responseData,
                    carrier_details: {
                      carrier_data_column_left: responseData?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                      carrier_data_column_right: responseData?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                    },

                    carrier_ai_mapping_data: responseData?.carrier_ai_mapping_data ? responseData?.carrier_ai_mapping_data : {},
                    carrier_level: responseData?.carrier_level?.length > 0 ? responseData.carrier_level : [],

                    carrier_product: responseData?.carrier_product?.length > 0 ? responseData?.carrier_product.map(product => ({
                      ...product,
                      carrier_product_ai_mapping_data: product.carrier_product_ai_mapping_data,
                      carrier_product_data: product.carrier_product_data?.sort((a, b) => parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence))?.map(data => ({
                        ...data,
                        carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) =>
                          parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)
                        ),
                      })),
                    }))
                      :
                      [
                        {
                          carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                          carrier_product_data: [
                            {
                              carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                              carrier_product_name: "",
                              carrier_product_note: "",
                              carrier_product_ba_total_comp: "",
                              carrier_product_commission_data: responseData?.carrier_level?.map(dt => ({
                                carrier_ba_level_flag: false,
                                carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                carrier_level_id: dt?.carrier_level_id,
                                carrier_level_name: dt.carrier_level_name,
                                carrier_level_sequence: "",
                                carrier_product_commission_value: "",
                              })),
                              carrier_product_ai_mapping_data: {},
                              carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : [],
                            }
                          ]
                        }
                      ]
                  };

                  setCarrierDetails(updatedCarrierDetails)
                  setCarrierNameDetailsDuplicate(updatedCarrierDetails)
                  setCarrierDetailsDuplicate(updatedCarrierDetails)
                  setCarrierTypeId(responseData?.carrier_type_id)
                  FlagApiLoader(false)
                }
                else {
                  if (res?.data?.error?.code === 9087) {
                    RevokeFunction()
                  }
                  else {
                    if (res?.data?.error?.message) {
                      UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    }
                    else {
                      UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    }
                    navigate("/carriers")
                    FlagApiLoader(false)
                  }
                  FlagApiLoader(false)
                }
              },
              (err) => {
                console.log("GetCarrierData ERROR RESPONSE", err);
                FlagApiLoader(false)
                UpdateSnackbarMessage({ status: "error", message: "Something went wrong!", });
                navigate("/carriers")
              })
          }
        }
        setFlagResourceExpand(false);
      }
    }

    fetchRequireData()
  }, [flagCall])

  // OLD CODE :
  // useEffect(() => {
  //   console.log("changeInCarrierType", changeInCarrierType);
  //   if (changeInCarrierType !== null) {
  //     let carrierId = carrierDetails?.carrier_types_list?.length ? carrierDetails?.carrier_types_list[0]?.carrier_id : ""
  //     let navigateURL = brokersAllianceAgent ? `/carriers/view_carrier?id=${carrierId}` : `/carriers/view_carrier?id=${carrierId}&version_id=`
  //     navigate(navigateURL)
  //     const flag = new Date().getTime()
  //     setFlagCall(flag)
  //   }
  // }, [changeInCarrierType])

  useEffect(() => {
    if (changeInCarrierType !== null) {

      let matchedCarrier = carrierDetails?.carrier_types_list?.find(item => item?.carrier_type_id == changeInCarrierType);
      let carrierId = matchedCarrier ? matchedCarrier.carrier_id : "";

      let navigateURL = brokersAllianceAgent ? `/carriers/view_carrier?id=${carrierId}` : `/carriers/view_carrier?id=${carrierId}&version_id=`;
      navigate(navigateURL);
      const flag = new Date().getTime();
      setFlagCall(flag);
    }
  }, [changeInCarrierType]);


  useEffect(() => {
    if (PathName === "/carriers/add_carrier") {
      setHideTransperentCell(true)
    } else {
      setHideTransperentCell(false)
    }
  }, [])


  const fetchCarrierEffectiveDateList = async (payload) => {
    try {
      const response = await getCarrierEffectiveDateListAPI(
        payload,
        (response) => {
          console.log("getCarrierEffectiveDateListAPI response", response);

          if (response?.data?.status) {
            const responseData = response?.data?.data?.map((carrier) => ({
              ...carrier,
              carrier_effective_dates: carrier?.carrier_effective_dates?.sort((a, b) => new Date(b.carrier_effective_date_from) - new Date(a.carrier_effective_date_from)) || []
            }));
            setCarrierEffectiveDatesDetails(responseData);
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: response?.data?.error?.message || "Something went wrong!",
            });
            setCarrierEffectiveDatesDetails([]);
          }
        },
        (error) => {
          console.error("getCarrierEffectiveDateListAPI error", error);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something went wrong!",
          });
          setCarrierEffectiveDatesDetails([]);
        }
      );
      return response;
    }
    catch (error) {
      console.error("Error while fetching carrier effective dates:", error);
      UpdateSnackbarMessage({
        status: "error",
        message: "Something went wrong!",
      });
      setCarrierEffectiveDatesDetails([]);
    }
  };

  const handleFocus = (event) => {
    const input = event.target;
    if (editDetails) {
      input.classList.add("highlight-cell");  // Add the CSS class to highlight the cell
    }
    // Set cursor position to the end of the text
    input.selectionStart = input.selectionEnd = input.value.length;

    input.scrollLeft = input.scrollWidth;

    if (input.readOnly) {
      input.selectionStart = input.selectionEnd;
    }
  };

  const handleBlur = (event) => {
    const input = event.target;
    if (editDetails) {
      input.classList.remove("highlight-cell");  // Remove the CSS class to remove the highlight
    }
    // Clear selection when input loses focus
    input.selectionStart = input.selectionEnd;
  };

  const handleClickInput = (event, groups, groupData, field) => {
    event.target.readOnly = true;
    setStoredProductCriteriaId({
      carrier_product_data_id_custom: null,
      carrier_product_data_id: null
    })
    if (editDetails) {
      if (field == "carrier_product_criteria") {
        setFlagCriteriDialog(true)
        setCriteriaCellData(groups)
        setProductIds({
          productId: groups?.carrier_product_id,
          productDataIds: groupData.carrier_product_data_id,
          productDataCustomIds: groupData.carrier_product_data_id_custom
        })
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const currentInput = event.target;

      if (editDetails) {
        const inputs = Array.from(tableRef.current.querySelectorAll('input'));
        const currentIndex = inputs.indexOf(currentInput);
        const currentTableCell = currentInput.closest('td');
        const currentTableRow = currentTableCell.closest('tr');
        const currentSection = currentTableRow.closest('tbody');
        const allSections = Array.from(tableRef.current.querySelectorAll('tbody'));
        const currentCellIndex = Array.from(currentTableRow.querySelectorAll('td')).indexOf(currentTableCell);
        const currentRowIndex = Array.from(currentSection.querySelectorAll('tr')).indexOf(currentTableRow);
        const sectionIndex = allSections.indexOf(currentSection);

        if (event.key === 'Enter') {
          if (currentInput.getAttribute("name") === "carrier_product_name" && !editingCell) {
            // Open dialog if entering the cell for the first time
            // setFlagCriteriDialog(true);
            setEditingCell(currentInput);  // Mark the input as currently being edited but keep read-only
            event.preventDefault();  // Prevent the default enter key behavior (submitting form, etc.)
          }
          else if (editingCell && editingCell === currentInput) {
            // Handle exiting edit mode for any input
            setEditingCell(null);
            currentInput.readOnly = true;
            let nextInput = null;
            if (currentRowIndex + 1 < currentSection.rows.length) {
              nextInput = currentSection.rows[currentRowIndex + 1].cells[currentCellIndex].querySelector('input');
            } else if (sectionIndex + 1 < allSections.length) {
              nextInput = allSections[sectionIndex + 1].rows[0].cells[currentCellIndex].querySelector('input');
            }
            if (nextInput) {
              nextInput.focus();
              nextInput.readOnly = true;
            }
            event.preventDefault();
          }
          else {
            // Enter edit mode for any other cell
            setEditingCell(currentInput);
            currentInput.readOnly = false;
            event.preventDefault();
          }
        }
        else if (['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'].includes(event.key) && !editingCell) {
          // Handle arrow navigation when not editing
          event.preventDefault();
          const movement = {
            ArrowRight: () => currentIndex + 1 < inputs.length ? inputs[currentIndex + 1] : undefined,
            ArrowLeft: () => currentIndex - 1 >= 0 ? inputs[currentIndex - 1] : undefined,
            ArrowDown: () => currentRowIndex + 1 < currentSection.rows.length ? currentSection.rows[currentRowIndex + 1].cells[currentCellIndex].querySelector('input') : undefined,
            ArrowUp: () => currentRowIndex - 1 >= 0 ? currentSection.rows[currentRowIndex - 1].cells[currentCellIndex].querySelector('input') : undefined,
          };

          const nextInput = movement[event.key]();
          if (nextInput) {
            nextInput.focus();
            nextInput.readOnly = true;
          }
        }
      }
    };

    const table = tableRef.current;
    if (table) {
      table.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (table) {
        table.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [editingCell, editDetails]);  // Only re-run the effect if editingCell changes

  // USE EFFECTS:---------------------------------------------------------------------------------------------------------------------



  // HANDLER FUNCTIONS:-----------------------------------------------------------------------------------------------------------------
  const handleCarrierDetailsChangeLeft = (e, carrier_detail_field_id) => {
    const currentData = carrierDetails?.carrier_details?.carrier_data_column_left || [];

    // Check if the item with the specific id exists
    const foundIndex = currentData.findIndex(det => det.carrier_detail_field_id == carrier_detail_field_id);

    if (foundIndex !== -1) {
      const newData = currentData.map((det, index) =>
        index === foundIndex ? { ...det, carrier_detail_field_value: e.target.value } : det
      );

      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_left: newData
        }
      });
    }
    else {
      // Add new item if it doesn't exist
      const newData = [...currentData, {
        carrier_detail_field_id: carrier_detail_field_id,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
        carrier_detail_field_value: e.target.value,
        carrier_detail_field_name: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_field_name,
        carrier_detail_flag_internal_info: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_internal_info,
        carrier_detail_flag_show_field: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_show_field,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
      }];
      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_left: newData
        }
      });
    }
  };

  const handleCarrierDetailsChangeRight = (e, carrier_detail_field_id) => {
    const currentData = carrierDetails?.carrier_details?.carrier_data_column_right || [];

    // Check if the item with the specific id exists
    const foundIndex = currentData.findIndex(det => det.carrier_detail_field_id == carrier_detail_field_id);

    if (foundIndex !== -1) {
      const newData = currentData.map((det, index) =>
        index === foundIndex ? { ...det, carrier_detail_field_value: e.target.value } : det
      );
      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_right: newData
        }
      });
    }
    else {
      // Add new item if it doesn't exist
      const newData = [...currentData, {
        carrier_detail_field_id: carrier_detail_field_id,
        carrier_detail_field_value: e.target.value,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
        carrier_detail_field_name: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_field_name,
        carrier_detail_flag_internal_info: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_internal_info,
        carrier_detail_flag_show_field: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_show_field,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
      }];
      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_right: newData
        }
      });
    }
  };

  // CARRIER NORMAL FUNCTIONS:-------------------------
  const handleClick = (event, id) => {
    setSelectedRowGroup({
      selectId: id,
      selectedId: null
    })
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedLine(null)
  };

  const handleDeletePopOverExtraFuncs = () => {
    setHideTransperentCell(true)
    setSelectedRowGroup({
      selectId: null,
      selectedId: null
    })
    setSelectedRow(null)
  }

  const removeUnsavedChangesHandler = () => {
    setSelectedAddIcon(null)
    setFlagShowAddIcons(false)
    setEditDetails(false)
    document.getElementById('addLevelPopup').style.display = "none"
  }
  // HANDLER FUNCTIONS:---------------------------------------------------------------------------------------------------------------------



  // CARRIER TABLE FUNCTIONS:---------------------------------------------------------------------------------------
  const handleAddRowPopOverClick = (event, groupData, levelIdx, groupIdx) => {
    setAnchorElForRow(event.currentTarget);
    setSelectedRowIndex({
      groupData: groupData,
      levelIdx: levelIdx,
      groupIdx: groupIdx
    })
  };

  const handleCarrierLevelData = (e, carrier_product_id, carrier_product_data_id_custom, name) => {
    const newCarrierDetails = {
      ...carrierDetails,
      carrier_product: carrierDetails?.carrier_product?.map((group) => {
        if (group?.carrier_product_id == carrier_product_id || group?.carrier_product_id_custom == carrier_product_id) {
          return {
            ...group,
            carrier_product_data: group?.carrier_product_data?.map((groupData) => {
              if (groupData.carrier_product_data_id_custom == carrier_product_data_id_custom || groupData?.carrier_product_data_id == carrier_product_data_id_custom) {
                return { ...groupData, [name]: e.target.value }
              }
              return groupData
            })
          }
        }
        return group;
      })
    }
    setCarrierDetails(newCarrierDetails)
  }

  const handleCarrierLevelCommissionData = (e, carrier_product_id, carrier_product_data_id_custom, carrier_product_commission_id) => {
    const newCarrierDetails = {
      ...carrierDetails,
      carrier_product: carrierDetails?.carrier_product?.map((group) => {
        if (group?.carrier_product_id == carrier_product_id || group?.carrier_product_id_custom == carrier_product_id) {
          return {
            ...group,
            carrier_product_data: group?.carrier_product_data?.map((groupData) => {
              if (groupData.carrier_product_data_id == carrier_product_data_id_custom || groupData.carrier_product_data_id_custom == carrier_product_data_id_custom) {
                return {
                  ...groupData,
                  carrier_product_commission_data: groupData?.carrier_product_commission_data?.map((commission) => {
                    if (commission?.carrier_product_commission_id == carrier_product_commission_id || commission?.carrier_commission_value_id_custom == carrier_product_commission_id) {
                      return { ...commission, carrier_product_commission_value: e.target.value }
                    }
                    return commission
                  })
                }
              }
              return groupData
            })
          }
        }
        return group;
      })
    }
    setCarrierDetails(newCarrierDetails)

  }

  const handleAddNewLevel = () => {
    // let idToFind = newLevelData?.carrier_level_after == null ? carrierDetails?.carrier_level.length > 0 ? carrierDetails?.carrier_level[0].carrier_level_id : null : newLevelData?.carrier_level_after;

    if (newLevelData?.carrier_level_name == "") {
      setEmptyLevelName(true)
      UpdateSnackbarMessage({
        status: "error",
        message: "Please add level name"
      })
      return;
    }

    let duplicateFound = carrierDetails?.carrier_level?.find(level => level?.carrier_level_id != newLevelData?.carrier_level_id && level?.carrier_level_name == newLevelData?.carrier_level_name)
    if (duplicateFound) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please select unique level name"
      })
      setEmptyLevelName(true)
      return
    }

    let prevCarrierLevel = [...carrierDetails.carrier_level];
    let findCurrLevelIdx = prevCarrierLevel?.findIndex(level =>
      (level?.carrier_level_id != null && level?.carrier_level_id == newLevelData?.carrier_level_after) ||
      (level?.carrier_level_id_custom != null && level?.carrier_level_id_custom == newLevelData?.carrier_level_after)
    );

    let new_custom_id = new Date().getTime() + parseInt(Math.random() * 1000000000000000);

    let newCarrierLevelData = carrierDetails?.carrier_product?.map((section) => {
      return {
        ...section,
        carrier_product_data: section?.carrier_product_data?.map((group) => {
          let newCommission = [...group.carrier_product_commission_data]
          newCommission.splice(findCurrLevelIdx + 1, 0, {
            carrier_level_id_custom: new_custom_id,
            carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
            carrier_level_name: newLevelData?.carrier_level_name,
            carrier_ba_level_flag: false,
            // carrier_level_sequence: 1,
            carrier_product_commission_value: ""
          })
          // // console.log("newCommision", newCommission)
          return { ...group, carrier_product_commission_data: newCommission }
        })
      }
    });

    if (findCurrLevelIdx != -1) {
      prevCarrierLevel.splice(findCurrLevelIdx + 1, 0, {
        "carrier_level_id_custom": new_custom_id,
        "carrier_level_name": newLevelData?.carrier_level_name,
        "carrier_ba_level_flag": false,
        // "carrier_level_sequence": 1,
        // "carrier_level_color_id": 3,
        "carrier_level_color_hex_code": newLevelData?.carrier_level_color_hex_code,
        carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
        "carrier_level_color_name": newLevelData?.carrier_level_color_name
      })
    }
    else {
      prevCarrierLevel.splice(findCurrLevelIdx + 1, 0, {
        "carrier_level_id_custom": new_custom_id,
        "carrier_level_name": newLevelData?.carrier_level_name,
        "carrier_ba_level_flag": false,
        // "carrier_level_sequence": 1,
        // "carrier_level_color_id": 3,
        "carrier_level_color_hex_code": newLevelData?.carrier_level_color_hex_code,
        carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
        "carrier_level_color_name": newLevelData.carrier_level_color_name
      })
    }

    setCarrierDetails({ ...carrierDetails, carrier_level: prevCarrierLevel, carrier_product: newCarrierLevelData })
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level: prevCarrierLevel, carrier_level_data: newCarrierLevelData })
    setNewLevelData({
      carrier_level_after: null,
      "carrier_level_id": null,
      "carrier_level_name": "",
      "carrier_ba_level_flag": false,
      // "carrier_level_sequence": 1,
      "carrier_level_color_id": 1,
      "carrier_level_color_hex_code": "#1b2430",
      "carrier_level_color_name": "Default"
    })
    // handleCloseColumnPopover()
    // setAnchorElForLevelsColumn({ type: null, columnId: 0 });
    document.getElementById("addLevelPopup").style.display = "none"
    setAnchorElForLevelsColumn({ type: null, columnId: 0 })
    setFlagAfterLevelPopoverRender(false)
    document.getElementById('addLevelPopup').style.display = "none"
    currentlyAddedCols.push(new_custom_id)
    setSelectedAddIcon(null)
  }

  const addNewRowForSection = (currGroup, levelIdx, groupIdx) => {

    let newCarrierLevelData = carrierDetails?.carrier_product?.map((level, i) => {
      if (i === levelIdx) {
        let newGroupData = { ...level }.carrier_product_data;
        const carrierProductId = level.carrier_product_id || new Date().getTime() + parseInt(Math.random() * 1000000000000000);

        let newObj = {
          ...currGroup,
          carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
          carrier_product_ai_mapping_data: null,
          carrier_product_name: "",
          carrier_product_note: "",
          carrier_product_ba_total_comp: "",
          carrier_product_commission_data: currGroup?.carrier_product_commission_data?.map((commission) => {
            let carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
            return {
              carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
              [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
              carrier_level_name: commission?.carrier_level_name,
              carrier_ba_level_flag: commission?.carrier_ba_level_flag,
              carrier_product_commission_value: "",
            };
          }),
          // Empty the criteria when adding a new row
          carrier_product_criteria: [],

        };

        delete newObj?.carrier_product_data_id;
        newGroupData.splice(groupIdx, 0, newObj);

        return {
          ...level,
          carrier_product_data: newGroupData,
          carrier_product_id: carrierProductId,
        };
      }
      return level;
    });

    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData });
    setAnchorElForRow(null);
    // handleAddRowPopOverClose();
  };

  const addNewSection = (currGroup, levelIdx) => {

    const newCarrierLevelData = [...carrierDetails?.carrier_product];

    const currGroupData = { ...currGroup.carrier_product_data[0] };
    const newObj = JSON.parse(JSON.stringify(currGroupData));

    newObj.carrier_product_data_id_custom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);
    newObj.carrier_product_name = "";
    newObj.carrier_product_note = "";
    newObj.carrier_product_ba_total_comp = "";
    newObj.carrier_product_ai_mapping_data = null;
    delete newObj?.carrier_product_data_id;

    newObj.carrier_product_commission_data = newObj.carrier_product_commission_data.map((commission) => {
      const carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
      return {
        carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
        [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
        carrier_level_name: commission?.carrier_level_name,
        carrier_ba_level_flag: commission?.carrier_ba_level_flag,
        carrier_product_commission_value: ""
      };
    });
    newObj.carrier_product_criteria = [];

    newCarrierLevelData.splice(levelIdx, 0, {
      carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
      carrier_product_data: [newObj]
    });

    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData });
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level_data: newCarrierLevelData });
    handleClose();
  };

  const copyAboveSection = (currGroup, levelIdx) => {
    const newCarrierLevelData = [...carrierDetails?.carrier_product];
    newCarrierLevelData.splice(levelIdx, 0, {
      carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
      carrier_product_data: currGroup?.carrier_product_data.map((product) => {
        const currGroupData = { ...product };
        const newObj = JSON.parse(JSON.stringify(currGroupData));

        newObj.carrier_product_data_id_custom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);
        newObj.carrier_product_ai_mapping_data = null;
        delete newObj?.carrier_product_data_id;

        newObj.carrier_product_commission_data = newObj.carrier_product_commission_data.map((commission) => {
          const carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
          return {
            carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
            [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
            carrier_level_name: commission?.carrier_level_name,
            carrier_ba_level_flag: commission?.carrier_ba_level_flag,
            carrier_product_commission_value: commission?.carrier_product_commission_value
          };
        });
        newObj.carrier_product_criteria.forEach(item => {
          delete item.carrier_product_criteria_id;
          item.carrier_product_criteria_id_custom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);

          item?.carrier_product_criteria_data?.forEach(criteriaData => {
            delete criteriaData.carrier_product_criteria_data_id;
            criteriaData.carrier_product_criteria_data_id_custom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);
          });
        });

        return newObj;
      })
    });

    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData });
    handleClose();
  }

  const copyRowInSection = (currGroup, levelIdx, groupIdx, position = 'below') => {

    let newCarrierLevelData = carrierDetails?.carrier_product?.map((level, i) => {
      if (i === levelIdx) {
        let newGroupData = [...level.carrier_product_data];
        const newIdCustom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);
        let copiedObject = {
          ...currGroup,
          carrier_product_data_id_custom: newIdCustom,
          carrier_product_ai_mapping_data: null,

          carrier_product_commission_data: currGroup?.carrier_product_commission_data?.map(commission => {
            let copiedCommission = { ...commission };
            delete copiedCommission.carrier_product_commission_id; // Remove primary key

            return {
              ...copiedCommission,
              carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
              carrier_product_commission_value: copiedCommission?.carrier_product_commission_value ?? "" // Ensure correct value
            };
          }),

          carrier_product_criteria: currGroup.carrier_product_criteria?.map(criteria => {
            let copiedCriteria = { ...criteria };
            delete copiedCriteria.carrier_product_criteria_id; // Remove primary key
            return {
              ...copiedCriteria,
              carrier_product_criteria_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
              carrier_product_criteria_data: criteria?.carrier_product_criteria_data?.map(criteriaData => {
                let copiedCriteriaData = { ...criteriaData };
                delete copiedCriteriaData.carrier_product_criteria_data_id; // Remove primary key
                return {
                  ...copiedCriteriaData,
                  carrier_product_criteria_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                };
              })
            };
          }),
        };

        // Remove the original ID if you are treating it as completely new
        delete copiedObject.carrier_product_data_id;
        copiedObject.carrier_product_sequence = null;

        // Adjust index based on position (above or below)
        const insertIndex = position == 'above' ? groupIdx : groupIdx + 1;
        newGroupData.splice(insertIndex, 0, copiedObject);

        return { ...level, carrier_product_data: newGroupData };
      }
      return level;
    });

    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData });
    setAnchorElForRow(null);
  };

  const deleteRowForSection = () => {
    const updateCarrierLevelData = carrierDetails?.carrier_product?.reduce((car_level_arr, car_data, index) => {
      if (index === selectedRowIndex?.levelIdx) {
        const updateGroupData = car_data?.carrier_product_data?.filter((grp_data, ind) => ind !== selectedRowIndex?.groupIdx);
        if (updateGroupData?.length !== 0) {
          car_level_arr.push({
            ...car_data, carrier_product_data: updateGroupData
          });
        }
      } else { car_level_arr.push(car_data); }

      return car_level_arr;
    }, []);

    setCarrierDetails({ ...carrierDetails, carrier_product: updateCarrierLevelData })
    setDeleteRowPopupFlag(false)
    handleClose()
  }

  const deleteSection = () => {
    let newCarrierLevelData = [...carrierDetails?.carrier_product];
    newCarrierLevelData?.splice(selectedLinePopover?.index, 1)
    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData })
    setDeleteRowPopupFlag(false)
  }


  // CARRIER TABLE FUNCTIONS:---------------------------------------------------------------------------------------
  const saveCriteareDetails = (criteriaData, childProductIds) => {
    for (let i in criteriaData) {
      // if (criteriaData[i]?.criteria_name == "Sub Type") {
      //   // if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value))) {
      //   //   delete criteriaData[i];
      //   // }
      //   if (!criteriaData[i]?.carrier_product_criteria_data?.length) {
      //     delete criteriaData[i];
      //   }
      // }
      if (criteriaData[i]?.criteria_name == "Sub Type") {
        if (!criteriaData[i]?.carrier_product_criteria_data?.length ||
          !criteriaData[i]?.carrier_product_criteria_data?.some(data => data.criteria_field_value && data.criteria_field_value.trim() != "")) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i]?.criteria_name == "Issue Age") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value && !rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i]?.criteria_name == "Renewal Year") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value && !rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i]?.criteria_name == "Excess Year") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value && !rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i]?.criteria_name == "Policy Year") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value && !rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i].criteria_name == "State") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
    }

    if (childProductIds.productId && childProductIds.productDataIds) {
      setCarrierDetails((prevData) => {
        const carrProductData = prevData?.carrier_product?.map((prodData, index) => {
          if (prodData?.carrier_product_id == childProductIds?.productId) {
            const updateCarrierProdData = prodData?.carrier_product_data?.map(dt => {
              if (dt?.carrier_product_data_id == childProductIds?.productDataIds) {
                return {
                  ...dt,
                  carrier_product_criteria: [...criteriaData.filter(rr => (rr))]
                }
              }
              return dt;
            })
            return {
              ...prodData,
              carrier_product_data: updateCarrierProdData
            }
          }

          return prodData;
        })
        return {
          ...prevData, carrier_product: carrProductData
        }
      })
    }
    else {
      setCarrierDetails((prevData) => {
        const carrProductData = prevData?.carrier_product?.map((prodData, index) => {
          const updateCarrierProdData = prodData?.carrier_product_data?.map(dt => {
            if (dt.carrier_product_data_id_custom == childProductIds?.productDataCustomIds) {
              return {
                ...dt,
                carrier_product_criteria: [...criteriaData.filter(rr => (rr))]
              }
            }
            return dt;
          })
          return {
            ...prodData,
            carrier_product_data: updateCarrierProdData
          }
        })
        return {
          ...prevData, carrier_product: carrProductData
        }
      })
    }
  };

  const generateTooltipContent = (criteria) => {


    if (!criteria || criteria.length == 0) return "";
    const sortedCriteria = [...criteria].sort((a, b) => Number(a.criteria_id) - Number(b.criteria_id));
    const criteriaMap = {};

    sortedCriteria?.forEach((crit) => {
      const { criteria_name, carrier_product_criteria_data } = crit;

      if (!criteriaMap[criteria_name]) {
        criteriaMap[criteria_name] = [];
      }

      switch (criteria_name) {
        // case "Sub Type":
        //   const subtypeValue = carrier_product_criteria_data?.map((data) => data.criteria_field_value)?.join(", ");
        //   criteriaMap[criteria_name]?.push(subtypeValue);
        //   break;

        case "Sub Type":
          const subtypeValue = carrier_product_criteria_data?.filter(data => data.criteria_field_value !== null && data.criteria_field_value !== "")?.map(data => data.criteria_field_value)?.join(", ");
          if (subtypeValue) {
            if (!criteriaMap[criteria_name]) {
              criteriaMap[criteria_name] = [];
            }
            criteriaMap[criteria_name].push(subtypeValue);
          }
          break;

        case "Type":
          const typeValue = carrier_product_criteria_data
            ?.filter(data => data.criteria_field_value != null && data.criteria_field_value !== "")
            ?.map(data => data.criteria_field_value)
            ?.join(", ");
          if (typeValue) {
            criteriaMap[criteria_name].push(typeValue);
          }
          break;

        case "Issue Age":
          const issueAgeRanges = [];
          let issueFrom = "";
          let issueTo = "";

          carrier_product_criteria_data.forEach((element) => {
            if (element?.criteria_field_name === "From Age" && element?.criteria_field_value) {
              issueFrom = element.criteria_field_value;
            } else if (element?.criteria_field_name === "To Age") {
              if (element?.criteria_field_option_value === "Lifetime") {
                issueTo = "+";
              } else if (element?.criteria_field_option_value === "Custom Text Field" && element?.criteria_field_value) {
                issueTo = ` to ${element.criteria_field_value}`;
              }
              issueAgeRanges.push(`${issueFrom}${issueTo}`);
              issueFrom = "";
              issueTo = "";
            }
          });
          criteriaMap[criteria_name]?.push(issueAgeRanges.join(`| `));
          break;

        case "Renewal Year":
          const renewalYearRanges = [];
          let renewalFrom = "";
          let renewalTo = "";

          carrier_product_criteria_data.forEach((element) => {
            if (element?.criteria_field_name === "Start Year" && element?.criteria_field_value) {
              renewalFrom = element.criteria_field_value;
            }
            else if (element?.criteria_field_name === "End Year") {
              if (element?.criteria_field_option_value === "Lifetime") {
                renewalTo = "+";
              }
              else if (element?.criteria_field_option_value === "Custom Text Field" && element?.criteria_field_value) {
                renewalTo = ` to ${element.criteria_field_value}`;
              }
              renewalYearRanges.push(`${renewalFrom}${renewalTo}`);
              renewalFrom = "";
              renewalTo = "";
            }
          });
          criteriaMap[criteria_name]?.push(renewalYearRanges.join(`| `));
          break;

        case "Excess Year":
          const excessYearRanges = [];
          let excessFrom = "";
          let excessTo = "";

          carrier_product_criteria_data.forEach((element) => {
            if (element?.criteria_field_name === "Start Year" && element?.criteria_field_value) {
              excessFrom = element.criteria_field_value;
            }
            else if (element?.criteria_field_name === "End Year") {
              if (element?.criteria_field_option_value === "Lifetime") {
                excessTo = "+";
              }
              else if (element?.criteria_field_option_value === "Custom Text Field" && element?.criteria_field_value) {
                excessTo = ` to ${element.criteria_field_value}`;
              }
              excessYearRanges.push(`${excessFrom}${excessTo}`);
              excessFrom = "";
              excessTo = "";
            }
          });
          criteriaMap[criteria_name]?.push(excessYearRanges.join(`| `));
          break;

        case "Policy Year":
          const policyYearRanges = [];
          let policyFrom = "";
          let policyTo = "";

          carrier_product_criteria_data.forEach((element) => {
            if (element?.criteria_field_name === "Start Year" && element?.criteria_field_value) {
              policyFrom = element.criteria_field_value;
            }
            else if (element?.criteria_field_name === "End Year") {
              if (element?.criteria_field_option_value === "Lifetime") {
                policyTo = "+";
              }
              else if (element?.criteria_field_option_value === "Custom Text Field" && element?.criteria_field_value) {
                policyTo = ` to ${element.criteria_field_value}`;
              }
              policyYearRanges.push(`${policyFrom}${policyTo}`);
              policyFrom = "";
              policyTo = "";
            }
          });
          criteriaMap[criteria_name]?.push(policyYearRanges.join(`| `));
          break;

        case "State":
          const stateValues = carrier_product_criteria_data?.filter((data) => data?.criteria_field_option_value);
          if (stateValues?.find(element => element?.criteria_field_option_value === "Select All")) {
            criteriaMap[criteria_name].push("All Available");
          }
          else {
            const states = stateValues?.map(element => element?.criteria_field_option_value);

            // Determine number of columns based on state count
            const columnCount = states.length > 30 ? 3 : states.length > 15 ? 2 : 1;

            if (columnCount == 1) {
              // Show states as a single comma-separated list
              criteriaMap[criteria_name].push(states.join(", "));
            }
            else {
              // Split states into columns
              const chunkSize = Math.ceil(states.length / columnCount);
              const columns = Array.from({ length: columnCount }, (_, colIndex) => states.slice(colIndex * chunkSize, (colIndex + 1) * chunkSize));

              const tableRows = Array.from({ length: chunkSize }).map((_, rowIndex) => {
                const rowCells = columns.map((column) => column[rowIndex] || ""); // Handle uneven splits
                return `
                  <tr>
                      ${rowCells.map((state) => `<td style="padding-right: 20px; font-size: 13px; color: #FFFFFF;">${state}</td>`).join("")}
                  </tr>
              `;
              }).join("");

              criteriaMap[criteria_name].push(`<table style="width: 100%; border-spacing: 0; border-collapse: collapse;">${tableRows}</table>`);
            }
          }
          break;

        default:
      }
    });

    Object.keys(criteriaMap)?.forEach(key => {
      if (!criteriaMap[key]?.length) {
        delete criteriaMap[key];
      }
    });

    const criteriaDescriptions = Object.entries(criteriaMap)?.map(([key, values]) => {
      const capitalizedKey = key?.charAt(0)?.toUpperCase() + key?.slice(1)?.toLowerCase();
      return `
    <div style="margin-bottom: 5px">
      <table >
        <thead></thead> <!-- Empty thead for structure -->
        <tbody>
          <tr>
            <td
             style="
                text-overflow: ellipsis; 
                padding-right: 10px; 
                vertical-align: baseline;
                min-width: 98px;
              "
            >
              <span
                class="input-span"
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #A0A0A0;
                margin-bottom: 5px"
              >
                ${capitalizedKey}:
              </span>
            </td>
            <td>
              <span
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #FFFFFF;"
              >
                ${values.map((value, index) => index < values.length - 1 ? `${value}<span style="color:rgb(145, 145, 145);"> | </span>` : value).join("")}  
                <!-- Combine all values into one row -->
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      `;
    });
    return criteriaDescriptions.join("")
  }

  const generateInputContent = (criteria) => {
    if (!criteria || criteria.length === 0) return "";

    const sortedCriteria = [...criteria]?.sort((a, b) => Number(a.criteria_id) - Number(b.criteria_id));
    const criteriaMap = {};

    sortedCriteria?.forEach((crit) => {
      const { criteria_name, carrier_product_criteria_data } = crit;

      if (!criteriaMap[criteria_name]) {
        criteriaMap[criteria_name] = [];
      }

      switch (criteria_name) {
        // case "Sub Type":
        //   // Handle Sub Type directly using criteria_field_value.
        //   const subtypeValue = carrier_product_criteria_data
        //     .map((data) => data.criteria_field_value)
        //     .join(", ");
        //   criteriaMap[criteria_name].push(subtypeValue);
        //   break;

        case "Sub Type":
          const subtypeValue = carrier_product_criteria_data
            ?.filter(data => data.criteria_field_value !== null && data.criteria_field_value !== "")
            ?.map(data => data.criteria_field_value)
            ?.join(", ");

          if (subtypeValue) {
            if (!criteriaMap[criteria_name]) {
              criteriaMap[criteria_name] = [];
            }
            criteriaMap[criteria_name]?.push(subtypeValue);
          }
          break;

        case "Type":
          const typeValue = carrier_product_criteria_data
            ?.filter(data => data.criteria_field_value !== null && data.criteria_field_value !== "")
            ?.map(data => data.criteria_field_value)
            ?.join(", ");
          if (typeValue) {
            criteriaMap[criteria_name].push(typeValue);
          }
          break;

        case "Issue Age":
          const issueAgeValues = carrier_product_criteria_data.map((rr) => {
            if (rr.criteria_field_name == "From Age") {
              return rr.criteria_field_value;
            } else {
              return rr.criteria_field_option_value === "Not Applicable"
                ? ""
                : rr.criteria_field_option_value === "Lifetime"
                  ? "+"
                  : rr.criteria_field_option_value === "Custom Text Field"
                    ? " to " + rr.criteria_field_value
                    : " ";
            }
          });
          criteriaMap[criteria_name].push(issueAgeValues.join(""));
          break;

        case "Renewal Year":
          const renewalYearValues = carrier_product_criteria_data.map((rr) => {
            if (rr.criteria_field_name == "Start Year") {
              return rr.criteria_field_value;
            } else {
              return rr.criteria_field_option_value === "Not Applicable"
                ? ""
                : rr.criteria_field_option_value === "Lifetime"
                  ? "+"
                  : rr.criteria_field_option_value === "Custom Text Field"
                    ? " to " + rr.criteria_field_value
                    : " ";
            }
          });
          criteriaMap[criteria_name].push(renewalYearValues.join(""));
          break;

        case "Excess Year":
          const excessYearValues = carrier_product_criteria_data.map((rr) => {
            if (rr.criteria_field_name == "Start Year") {
              return rr.criteria_field_value;
            } else {
              return rr.criteria_field_option_value === "Not Applicable"
                ? ""
                : rr.criteria_field_option_value === "Lifetime"
                  ? "+"
                  : rr.criteria_field_option_value === "Custom Text Field"
                    ? " to " + rr.criteria_field_value
                    : " ";
            }
          });
          criteriaMap[criteria_name].push(excessYearValues.join(""));
          break;

        case "Policy Year":
          const policyYearValues = carrier_product_criteria_data.map((rr) => {
            if (rr.criteria_field_name == "Start Year") {
              return rr.criteria_field_value;
            } else {
              return rr.criteria_field_option_value === "Not Applicable"
                ? ""
                : rr.criteria_field_option_value === "Lifetime"
                  ? "+"
                  : rr.criteria_field_option_value === "Custom Text Field"
                    ? " to " + rr.criteria_field_value
                    : " ";
            }
          });
          criteriaMap[criteria_name].push(policyYearValues.join(""));
          break;

        case "State":
          const stateValues = carrier_product_criteria_data.filter(
            (data) => data.criteria_field_option_value
          );
          if (stateValues.filter((rr) => rr.criteria_field_option_value == "Select All").length) {
            criteriaMap[criteria_name].push("All Available");
          } else {
            criteriaMap[criteria_name].push(
              stateValues
                .filter((val) => val)
                .map((rr) => rr.criteria_field_option_value)
                .join(", ")
            );
          }
          break;

        default:
          break;
      }
    });

    Object.keys(criteriaMap)?.forEach(key => {
      if (!criteriaMap[key]?.length) {
        delete criteriaMap[key];
      }
    });

    // Convert the map into a readable format.
    const criteriaDescriptions = Object.entries(criteriaMap).map(([key, values]) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      return `${capitalizedKey}: ${values.join(", ")}`;
    });

    return criteriaDescriptions.join(" | ");
  };


  // API FUNCTIONS:---------------------------------------------------------------------------------------------------------------------
  const handleValidation = () => {
    let outerArr = []
    let errorFound = false;
    carrierDetails?.carrier_product?.map((level) => {
      level?.carrier_product_data?.map((group) => {
        let innerArr = [];
        group.carrier_product_name == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_product_note == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_product_ba_total_comp == "" ? innerArr.push(0) : innerArr.push(1)
        group?.carrier_product_commission_data?.map((commission) => {
          commission?.carrier_product_commission_value == "" ? innerArr.push(0) : innerArr.push(1)
        })
        outerArr.push(innerArr)
      })

    })

    for (let i = 0; i < outerArr.length; i++) {
      for (let j = 0; j < outerArr[i].length; j++) {
        if (outerArr[i][j] == 0) {
          let rowEmptyFound = outerArr[i].every((val) => val == 0);
          let colEmptyFound = outerArr.every((row) => row[j] === 0);
          if (rowEmptyFound && colEmptyFound) {
            errorFound = true
            break;
          }
        }
      }
    }

    if (errorFound) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please add the levels in the product"
      })
      return
    }
    else {
      setFlagSetEffectiveDateDialog(true)
    }
  }

  const handleUpdateCarrierAfterEffectiveDate = (selectedEffectiveDateData, dateType) => {

    if (PathName == "/carriers/add_carrier") {
      const filteredCarrierProductLevelData = carrierDetails?.carrier_product?.filter(group => group?.carrier_product_data?.length > 0);
      const createdEffectiveDateForPayload = selectedEffectiveDateData?.carrier_effective_date_from

      filteredCarrierProductLevelData.map(filterData => {
        if (filterData.carrier_product_id_custom) {
          delete filterData.carrier_product_id_custom;
          delete filterData.carrier_product_id;
        }
        return filterData;
      })

      let payload = {
        ...carrierDetails,
        carrier_product: filteredCarrierProductLevelData,
        carrier_effective_date: createdEffectiveDateForPayload,
        carrier_details: [
          ...carrierDetails?.carrier_details?.carrier_data_column_left,
          ...carrierDetails?.carrier_details?.carrier_data_column_right
        ]
      }

      if (!subAgency) {
        FlagApiLoader(true)
        CreateCarrierAPI(payload, (res) => {
          console.log("CreateTemplate SUCCESS RESPONSE ===>", res);
          if (res?.data?.status) {
            setFlagSetEffectiveDateDialog(false)
            navigate("/carriers")
            UpdateSnackbarMessage({ status: "success", message: "New Carrier Created Successfully" })
            FlagApiLoader(false)
          }
          else {
            console.log("response false of CreateCarrierAPI api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
              });
            }
            setFlagSetEffectiveDateDialog(false)
            FlagApiLoader(false)
          }
        }, (err) => {
          console.log("error of CreateCarrierAPI api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: err?.data?.error?.message
          })
          setFlagSetEffectiveDateDialog(false)
          FlagApiLoader(false)
        })
      }
    }
    else {
      let { carrier_effective_date_data, ...filteredCarrierDetails } = carrierDetails;

      const filteredCarrierLevelData = filteredCarrierDetails?.carrier_product?.filter((group) => group?.carrier_product_data?.length > 0);
      filteredCarrierLevelData?.forEach((filterData) => {
        if (filterData.carrier_product_id_custom) {
          delete filterData.carrier_product_id;
          delete filterData.carrier_product_id_custom;
        }
      });

      let payload = {
        ...filteredCarrierDetails,
        carrier_product: filteredCarrierLevelData,
        carrier_effective_date: selectedEffectiveDateData ? moment(selectedEffectiveDateData?.carrier_effective_date_from)?.format("YYYY-MM-DD") : null,
        carrier_effective_date_id: selectedEffectiveDateData ? selectedEffectiveDateData?.carrier_effective_date_id : null,
        carrier_details: [...filteredCarrierDetails?.carrier_details?.carrier_data_column_left, ...filteredCarrierDetails?.carrier_details?.carrier_data_column_right],
        carrier_level: filteredCarrierDetails?.carrier_level?.map((ele) => ({
          ...ele,
          carrier_level_color_id: Number(ele.carrier_level_color_id),
          carrier_level_id: Number(ele.carrier_level_id),
        })),
      };

      if (dateType != "new" || dateType == "existing") {  // Remove keys if dateType is "existing"
        delete payload.carrier_effective_date;
      }


      if (!subAgency) {
        FlagApiLoader(true)
        UpdateCarrierAPI(payload, (res) => {
          if (res?.data?.status) {
            console.log("response true of UpdateCarrierAPI api", res);
            const successMessage = res?.data?.data?.message
            setFlagCall(new Date().getTime())
            setEditDetails(false)
            setHideTransperentCell(false)
            UpdateSnackbarMessage({
              status: "success",
              message: successMessage
            })
            setFlagSetEffectiveDateDialog(false)
            FlagApiLoader(false)
            setFlagShowAddIcons(false)
          }
          else {
            console.log("response false of UpdateCarrierAPI api", res);
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message ? res?.data?.error?.message : "Something Went Wrong!"
            });
            setFlagSetEffectiveDateDialog(false)
            FlagApiLoader(false)
          }
        },
          (err) => {
            console.log("error of UpdateCarrierAPI api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: err?.data?.error?.message
            })
            FlagApiLoader(false)
          })
      }
    }
  }

  const DeleteResouceFile = () => {
    setFlagDeletePopup(false)
    FlagApiLoader(true)
    let obj = {
      carrier_id: deleteResourceData?.carrier_detail?.carrier_id,
      carrier_resource_id: deleteResourceData?.file_detail.carrier_resource_id
    }
    DeleteResources(obj,
      (res) => {
        if (res?.data?.status) {
          console.log("response true of DeleteResources api", res);
          setFlagCall(new Date().getTime())
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });
        }
        else {
          console.log("response false of DeleteResources api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateVersionList api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
      })
  }

  const mainDataCall = (searchParamsCarrierId, latestVersionId) => {

    const payload = { carrier_id: searchParamsCarrierId, carrier_version_id: latestVersionId }
    FlagApiLoader(true)

    GetCarrierData(payload,
      (res) => {
        if (res.data.status) {
          console.log("Response of change carrier in dropdown", res);

          const responseData = res?.data?.data[0]
          const newDetails = responseData?.carrier_details;
          const leftDetails = newDetails.filter(carrier => !carrier.carrier_detail_flag_internal_info);
          const rightDetails = newDetails.filter(carrier => carrier.carrier_detail_flag_internal_info);


          // COMMENTED : BFR NOT SET THE CARRIER FIELD IN THE FIRST TIME WHENN USER COMES AND SAVE CARRIER FIELDS
          // const newLeftDetailsMap = leftDetails?.reduce((acc, detail) => {
          //   acc[detail.carrier_detail_field_id] = detail;
          //   return acc;
          // }, {});

          // const newRightDetailsMap = rightDetails?.reduce((acc, detail) => {
          //   acc[detail.carrier_detail_field_id] = detail;
          //   return acc;
          // }, {});

          // const updatedLeftDetails = carrierKeyDetails?.carrier_data_column_left?.map(detail => ({
          //   ...detail,
          //   carrier_detail_field_value: newLeftDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_field_value || '',
          //   carrier_detail_id: newLeftDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_id || detail.carrier_detail_id
          // }));

          // const updatedRightDetails = carrierKeyDetails?.carrier_data_column_right?.map(detail => ({
          //   ...detail,
          //   carrier_detail_field_value: newRightDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_field_value || '',
          //   carrier_detail_id: newRightDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_id || detail.carrier_detail_id
          // }));

          const updatedCarrierDetails = {
            ...responseData,

            carrier_details: { carrier_data_column_left: leftDetails, carrier_data_column_right: rightDetails },

            carrier_ai_mapping_data: responseData?.carrier_ai_mapping_data ? responseData?.carrier_ai_mapping_data : {},
            carrier_level: responseData?.carrier_level?.length > 0 ? responseData?.carrier_level : [],
            carrier_product: responseData?.carrier_product?.length > 0 ? responseData?.carrier_product.map(product => ({
              ...product,
              carrier_product_data: product.carrier_product_data?.sort((a, b) => parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence))?.map(data => ({
                ...data,
                carrier_product_ai_mapping_data: data.carrier_product_ai_mapping_data,
                carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) => parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)),
              })),
            }))
              :
              [
                {
                  carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                  carrier_product_data: [
                    {
                      carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                      carrier_product_name: "",
                      carrier_product_note: "",
                      carrier_product_ba_total_comp: "",
                      carrier_product_commission_data: responseData?.carrier_level?.map(dt => ({
                        carrier_ba_level_flag: false,
                        carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                        carrier_level_id: dt?.carrier_level_id,
                        carrier_level_name: dt.carrier_level_name,
                        carrier_level_sequence: "",
                        carrier_product_commission_value: "",
                      })),
                      carrier_product_ai_mapping_data: {},
                      carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : [],
                    }
                  ]
                }
              ]
          };

          setCarrierDetails(updatedCarrierDetails)
          setCarrierDetailsDuplicate(updatedCarrierDetails)
          setCarrierNameDetailsDuplicate(updatedCarrierDetails)
          setCarrierTypeId(responseData?.carrier_type_id)
          setFlagCall("default")
          FlagApiLoader(false)
        }
        else {
          if (res?.data?.error?.code === 9087) {
            RevokeFunction()
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message,
            });
            FlagApiLoader(false)
            navigate("/carriers")
          }
        }

      }, (err) => {
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
        navigate("/carriers")
      })
  }

  const RevokeFunction = () => {
    FlagApiLoader(true)
    RevokeDelegateAccessForAdmin((res) => {
      if (res?.data?.status) {
        console.log("response true of RevokeDelegateAccessForAdmin api", res);
        window.location.href = "/"
      }
      else {
        console.log("response false of RevokeDelegateAccessForAdmin api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
      FlagApiLoader(false)
    },
      (err) => {
        console.log("error of RevokeDelegateAccessForAdmin api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: err?.message
        });
        FlagApiLoader(false)
      })
  }
  // API FUNCTIONS:---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div class="temp carrier-view-page-wrapper">
        {apiLoader && <Loader />}

        {flagOpenEffectiveDateDialog &&
          <GlobalDialog
            open={flagOpenEffectiveDateDialog}
            data={<CarrierEffectiveDialog
              setFlagOpenEffectiveDateDialog={setFlagOpenEffectiveDateDialog}

              setCarrierEffectiveDatesDetails={setCarrierEffectiveDatesDetails}
              carrierEffectiveDatesDetails={carrierEffectiveDatesDetails}

              carrierId={searchParams.get("id")}
              fetchCarrierEffectiveDateList={fetchCarrierEffectiveDateList}

              carrierDetails={carrierDetails}
            />
            }
            maxWidth="sm"
          />
        }


        {flagSetEffectiveDateDialog && (
          <GlobalDialog
            open={flagSetEffectiveDateDialog}
            maxWidth={"sm"}
            data={
              <SaveSetEffectiveDateDialog
                flagSetEffectiveDateDialog={flagSetEffectiveDateDialog}
                setFlagSetEffectiveDateDialog={setFlagSetEffectiveDateDialog}

                carrierEffectiveDatesDetails={carrierEffectiveDatesDetails}
                setCarrierEffectiveDatesDetails={setCarrierEffectiveDatesDetails}

                flagCreateEffectiveDateRender={flagCreateEffectiveDateRender}

                handleUpdateCarrierAfterEffectiveDate={handleUpdateCarrierAfterEffectiveDate}
                editDetails={editDetails}
                carrierDetails={carrierDetails}

              />
            }
          />
        )}

        {flagCriteriDialog && (
          <GlobalDialog
            open={flagCriteriDialog}
            maxWidth={"xl"}
            data={
              <CriteriaDialog
                flagCriteriDialog={flagCriteriDialog}
                setFlagCriteriDialog={setFlagCriteriDialog}
                carrierDetails={carrierDetails}
                setCarrierDetails={setCarrierDetails}
                saveCriteareDetails={saveCriteareDetails}
                productIds={productIds}
                setCriteriaCellData={setCriteriaCellData}
                criteriaCellData={criteriaCellData}
              />
            }
          />
        )}

        {flagProductMappingDialog && (
          <GlobalDialog
            open={flagProductMappingDialog}
            maxWidth={"sm"}
            data={
              <ProductMappingDialog
                setFlagProductMappingDialog={setFlagProductMappingDialog}
                carrierId={searchParams.get("id")}
                setFlagCall={setFlagCall}
                carrierDetails={carrierDetails}
                criteriaCellData={criteriaCellData}
                productRowGroupDetails={productRowGroupDetails}

                flagNewProductView={flagNewProductView}
                setFlagNewProductView={setFlagNewProductView}

                setProductMappingListData={setProductMappingListData}
              />
            }
          />
        )}

        {flagCarrierMappingDialog && (
          <GlobalDialog
            open={flagCarrierMappingDialog}
            maxWidth={"sm"}
            data={
              <CarrierMappingDialog
                setFlagCarrierMappingDialog={setFlagCarrierMappingDialog}
                carrierId={searchParams.get("id")}
                setFlagCall={setFlagCall}
                carrierDetails={carrierDetails}

              />
            }
          />
        )}

        {flagLevelTablePopup && (
          <GlobalDialog
            maxWidth={"false"}
            open={flagLevelTablePopup}
            data={
              <ViewCarrierPreviewDialog
                leftSideHeaders={leftSideHeaders}
                rightSideHeaders={rightSideHeaders}
                editDetails={editDetails}
                flagAfterLevelPopoverRender={flagAfterLevelPopoverRender}
                flagCustomizationCarriarSectionDialog={flagCustomizationCarriarSectionDialog}
                flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                deleteRowPopupFlag={deleteRowPopupFlag}
                isSelectedRow={isSelectedRow}
                hideTransparentCell={hideTransparentCell}
                flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                selectedRowGroupIdOfDialog={selectedRowGroupIdOfDialog}
                setFlagLevelTablePopup={setFlagLevelTablePopup}
                setSelectedRowGroupOfDialog={setSelectedRowGroupOfDialog}
                carrierDetails={carrierDetails}

                subAgency={subAgency}
                userRoleAdmin={userRoleAdmin}
                userRoleMember={userRoleMember}
                agencyNameBrokersAlliance={agencyNameBrokersAlliance}
                brokersAllianceAgent={brokersAllianceAgent}

                // PRODUCT MAPPING
                setStoredRowDataId={setStoredRowDataId}
                storedRowDataId={storedRowDataId}
                setFlagProductMappingDialog={setFlagProductMappingDialog}
                setProductRowGroupDetails={setProductRowGroupDetails}

              />
            }
          />
        )}

        {flagPdfPopup && (
          <Dialog
            open={flagPdfPopup}
            maxWidth={"xxl"}
            sx={{
              zIndex: 2005,
              "& .MuiPaper-root": { width: '100% !important', }
            }}
          >
            <CarrierPDFViewerDialog
              setFlagPdfPopup={setFlagPdfPopup}
              imageUrl={imageUrl}
            />
          </Dialog>
        )}

        {flagFileUploadPopup && (
          <GlobalDialog
            open={flagFileUploadPopup}
            data={
              <FileUploadDialog
                flagFileUploadPopup={flagFileUploadPopup}
                setFlagFileUploadPopup={setFlagFileUploadPopup}
                carrierDetails={carrierDetails}
                setFlagCall={setFlagCall}
                flagCall={flagCall}
              />
            }
            maxWidth={"sm"}
          />
        )}

        {flagDeletePopup && (
          <GlobalDialog
            open={flagDeletePopup}
            data={
              <DeleteResourceAlertDialog
                deleteResourceData={deleteResourceData}
                setFlagDeletePopup={setFlagDeletePopup}
                DeleteResouceFile={DeleteResouceFile}
              />
            }
            maxWidth={"sm"}
          />
        )}

        {flagDeleteCarriarSectionDialog && (
          <GlobalDialog
            onClose={() => {
              setFlagDialogGloballyRender(false)
            }}
            open={flagDeleteCarriarSectionDialog}
            data={<DeleteCarriarSectionConfirmationDialog />}
            maxWidth={"xs"}
          />
        )}

        {true && (
          <GlobalDialog
            open={flagUnsavedChangesDialog}
            data={
              <UnsavedChangesDialog
                setFlagUnsavedChangesDialog={setFlagUnsavedChangesDialog}
                setEditDetails={setEditDetails}
                setHideTransperentCell={setHideTransperentCell}
                setCarrierDetails={setCarrierDetails}
                carrierDetailsDuplicate={carrierDetailsDuplicate}
                removeUnsavedChangesHandler={removeUnsavedChangesHandler}
              />}
            className={"unsaved-changes-backdrop"}
            maxWidth={"sm"}
          />
        )}

        {flagDialogGloballyRender && (
          <Backdrop
            sx={{ color: '#fff', zIndex: 108 }}
            open={flagDialogGloballyRender}
          >
          </Backdrop>
        )}

        {flagAfterLevelPopoverRender && (
          <Backdrop
            sx={{ color: '#fff', zIndex: 1101 }}
            open={flagAfterLevelPopoverRender}
          >
          </Backdrop>
        )}

        {flagCarrierCustomizationGlobalDialog && (
          <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '100' }}></div>
        )}

        {flagCarrierCustomizationGlobalDeleteDialog &&
          <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '100' }}></div>
        }

        <div id="deleteHighlighter" style={{ position: "fixed", top: "0", left: "0", border: "1px solid #1565c0", width: "0px", height: "40px", zIndex: "108" }}></div>

        <Helmet title="myAdvisorGrids | Carrier View" />

        {/* NAVIGATION SECTION */}
        <CarrierTableNavigation
          PathName={PathName}
          setEditDetails={setEditDetails}

          editDetails={editDetails}
          setCarrierDetails={setCarrierDetails}

          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}

          searchParams={searchParams}
          setSearchParams={setSearchParams}

          setFlagShowAddIcons={setFlagShowAddIcons}
          flagShowAddIcons={flagShowAddIcons}

          carrierTypeId={carrierTypeId}
          setCarrierTypeId={setCarrierTypeId}

          setFlagCall={setFlagCall}
          carrierData={carrierData}
          carrierPlaceholderName={carrierPlaceholderName}

          setCarrierEffectiveDatesDetails={setCarrierEffectiveDatesDetails}
          carrierEffectiveDatesDetails={carrierEffectiveDatesDetails}

          flagOpenEffectiveDateDialog={flagOpenEffectiveDateDialog}
          setFlagOpenEffectiveDateDialog={setFlagOpenEffectiveDateDialog}

          flagCreateEffectiveDateRender={flagCreateEffectiveDateRender}
          setFlagCreateEffectiveDateRender={setFlagCreateEffectiveDateRender}

          carrierDetails={carrierDetails}
          tableContainerRef={tableContainerRef}
          searchParamsCarrierId={searchParams?.get("id")}
          carrierNameDetailsDuplicate={carrierNameDetailsDuplicate}
          currentlyAddedCols={currentlyAddedCols}
          carrierVersionList={carrierVersionList}

          setFlagCarrierMappingDialog={setFlagCarrierMappingDialog}
          setFlagUnsavedChangesDialog={setFlagUnsavedChangesDialog}
          setChangeInCarrierType={setChangeInCarrierType}
          setHideTransperentCell={setHideTransperentCell}
          setCarrierNameDetailsDuplicate={setCarrierNameDetailsDuplicate}

          handleValidation={handleValidation}
          RevokeFunction={RevokeFunction}
        />
        {/* NAVIGATION SECTION */}


        {/* CONTENT SECTION */}
        <Grid container spacing={2} mt={1} sx={{ maxWidth: { xs: "100vw", md: "87vw" }, margin: "auto" }}>
          <Grid item xs={12} id={"carrier_view_container"} className="main_box_shadow cg-view-carrier-inner-scrollable-wrapper" sx={{ backgroundColor: "#FFFFFF", padding: "15px !important", }}>
            <Grid container sx={{ maxHeight: "100%", }}>

              {/* ----------- CARRIER DETAILS SECTION ----------- */}
              <Grid item xs={12}>
                <Accordion
                  disableGutters
                  className="carrier_dertails_accordian"
                  sx={{
                    backgroundColor: "#EFEFF0",
                    // padding: "0px 9px 15px",
                    marginBottom: "20px !important",
                  }}
                  expanded={!editDetails ? flagCarrierDetailsExpand : true}
                >
                  <AccordionSummary
                    expandIcon={!editDetails && <ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ color: "#1565C0", fontWeight: "600", fontSize: "16px", paddingLeft: editDetails ? "9px" : "25px" }}
                    onClick={() => {
                      if (editDetails) {
                        return
                      }
                      setFlagCarrierDetailsExpand(!flagCarrierDetailsExpand)
                      setEditDetails(false)
                    }}
                  >
                    Carrier Details
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container rowGap={4} padding={editDetails ? "0px" : "0px 25px"} >
                      <Grid item xs={12} sm={12} md={12} lg={8.7} xl={editDetails ? 7.5 : (userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ? 9 : 12}>
                        <Grid container spacing={5} justifyContent={"space-between"} sx={{ height: "100%" }}>
                          <Grid item xs={12}>
                            <Grid container
                              sx={{
                                display: "grid",
                                gridTemplateRows: "repeat(6, auto)",
                                gridAutoFlow: "column",
                                gridTemplateColumns: `repeat(${Math.ceil(carrierKeyDetails.carrier_data_column_left?.length / 6)}, 1fr)`,
                                gap: "8px",
                                columnGap: '20px',
                                '@media (max-width: 840px)': {
                                  gridTemplateRows: `repeat(${carrierKeyDetails.carrier_data_column_left?.length}, auto)`,
                                  gridTemplateColumns: "repeat(1, 1fr)"
                                }
                              }}
                            >
                              {carrierKeyDetails?.carrier_data_column_left?.map((ele, ind) => {
                                const fatchDetails = carrierDetails?.carrier_details?.carrier_data_column_left?.find(dt => dt.carrier_detail_field_id == ele?.carrier_detail_field_id)
                                return (
                                  <>
                                    <Grid item key={ind} sx={{ height: 'fit-content' }}>
                                      <Grid container alignItems={"start"} sx={{}} >

                                        <Grid item className="carrierVersion_DetailsLable">
                                          <CustomLabelTypography sx={{ color: "#7A7A7A" }}>
                                            {ele?.carrier_detail_field_name}
                                          </CustomLabelTypography>
                                        </Grid>

                                        <Grid item className="carrierVersion_DetailsContent">
                                          <TextField
                                            key={ind}
                                            id="standard-read-only-input"
                                            multiline
                                            value={fatchDetails?.carrier_detail_field_value ?? ""}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                              className: editDetails && "vc-version-custom-textfield",
                                              readOnly: !editDetails,
                                              style: { fontSize: "15px" },
                                              disableUnderline: !editDetails,
                                            }}
                                            onChange={(e) => {
                                              handleCarrierDetailsChangeLeft(e, ele?.carrier_detail_field_id)
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                )
                              })}
                            </Grid>
                          </Grid>
                          {carrierDetails?.carrier_data_column_left?.filter(dt => dt?.carrier_detail_field_id == 15)[0].carrier_detail_flag_show_field == true && (
                            <>
                              {
                                !editDetails && <Grid item xs={12} display={"flex"} alignItems={'center'} justifyContent={'center'} >
                                  <Typography
                                    className="carrier-details-footer-Note"
                                  >
                                    All percentages are subject to change. This grid does not serve as a binding contract. Please contact your BA recruiter with any questions you may have.
                                  </Typography>
                                </Grid>
                              }
                            </>
                          )
                          }
                          {editDetails ? (<></>) : (
                            <>
                              <Grid item xs={12} sx={{ textAlign: "center", marginBottom: '10px' }}>
                                <Typography variant="span" sx={{ color: "#1565C0", fontSize: "13px", fontWeight: "500" }}>
                                  All percentages are subject to change. This grid does not serve as a binding contract. Please contact your BA recruiter with any questions you may have.
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>

                      {
                        (userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ? (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={0.3} xl={0.3} container direction={"row"} justifyContent="center">
                              <Divider orientation={minScreenWidth ? 'horizontal' : 'vertical'} sx={minScreenWidth ? { display: 'flex', width: "100%" } : {}} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={3} xl={editDetails ? 4.2 : 2.7}>
                              <Grid
                                container
                                flexDirection="column"
                                rowGap={3}
                              >
                                <Grid item xs={12} textAlign={"center"}>
                                  <Typography variant="span"
                                    sx={{
                                      textTransform: "none",
                                      color: "#00345E",
                                      fontSize: "15px",
                                      fontWeight: "600"
                                    }}>
                                    Internal Info Only
                                  </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid container rowGap={editDetails ? 3 : 0}>
                                    {
                                      carrierKeyDetails?.carrier_data_column_right?.map((ele, index) => {
                                        const fatchDetails = carrierDetails?.carrier_details?.carrier_data_column_right?.find(dt => dt.carrier_detail_field_id == ele?.carrier_detail_field_id)
                                        return (
                                          <>
                                            <Grid key={index} item xs={12}>
                                              <Grid container className="viewCarrier_Internalinfo_Container" alignItems={'start'}>
                                                <Grid item className="viewCarrier_Internalinfo_itemLable" >
                                                  <CustomLabelTypography sx={{ color: "#7A7A7A", marginRight: "10px" }}>
                                                    {ele?.carrier_detail_field_name}
                                                  </CustomLabelTypography>
                                                </Grid>
                                                <Grid item className="viewCarrier_Internalinfo_itemContent" sx={{ wordBreak: "break-all" }}>
                                                  {
                                                    fatchDetails?.carrier_detail_field_name == 'Drive Folder' ?
                                                      (
                                                        editDetails ?
                                                          <TextField
                                                            key={index}
                                                            id="standard-read-only-input"
                                                            defaultValue={fatchDetails?.carrier_detail_field_value ?? ""}
                                                            variant="standard"
                                                            fullWidth
                                                            multiline
                                                            InputProps={{
                                                              className: editDetails && "vc-version-custom-textfield",
                                                              readOnly: !editDetails,
                                                              style: { fontSize: "15px" },
                                                              disableUnderline: !editDetails,
                                                            }}
                                                            onChange={(e) => {
                                                              handleCarrierDetailsChangeRight(e, ele?.carrier_detail_field_id)
                                                            }}
                                                          />
                                                          :
                                                          <Link href={fatchDetails?.carrier_detail_field_value} target="_blank" >
                                                            {fatchDetails?.carrier_detail_field_value}
                                                          </Link>
                                                      )
                                                      :
                                                      (
                                                        <TextField
                                                          key={index}
                                                          id="standard-read-only-input"
                                                          // defaultValue={row?.carrier_detail_field_value}
                                                          value={fatchDetails?.carrier_detail_field_value ?? ""}
                                                          variant="standard"
                                                          fullWidth
                                                          multiline
                                                          InputProps={{
                                                            className: editDetails && "vc-version-custom-textfield",
                                                            readOnly: !editDetails,
                                                            style: { fontSize: "15px" },
                                                            disableUnderline: !editDetails,
                                                          }}
                                                          onChange={(e) => {
                                                            handleCarrierDetailsChangeRight(e, ele?.carrier_detail_field_id)
                                                          }}
                                                        />
                                                      )
                                                  }
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </>
                                        )
                                      }

                                      )
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (<></>)
                      }
                    </Grid>
                    {/* New GRID END */}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {/* ----------- CARRIER DETAILS SECTION ----------- */}


              {/* ----------- RESOURCE SECTION ----------- */}
              {!editDetails &&
                <Grid item xs={12}>
                  <ResourcesAccordians
                    userRole={userDetail[0]?.user_role_name}
                    carrierDetails={carrierDetails}
                    setImageUrl={setImageUrl}
                    imageUrl={imageUrl}
                    flagResourceExpand={flagResourceExpand}
                    setFlagResourceExpand={setFlagResourceExpand}
                    setFlagFileUploadPopup={setFlagFileUploadPopup}
                    setFlagCall={setFlagCall}
                    flagCall={flagCall}
                    setFlagPdfPopup={setFlagPdfPopup}
                    setDeleteresourceData={setDeleteresourceData}
                    setFlagDeletePopup={setFlagDeletePopup}
                  />
                </Grid>
              }
              {/* ----------- RESOURCE SECTION ----------- */}


              {/* ----------- TABLE SECTION ----------- */}
              <Grid item xs={12} className={"vw-levels-table-main-item"}>
                <Grid container>
                  {/* COLUMN LEVEL POPOVER : IF THIS IS NOT WORKING THEN ADD CODE FROM LOCAL STORAGE ITS NAME IS  ViewCarrierDetailsBackupBfrCarierTableNavigation */}
                  <div>
                    <ColumnLevelPopover
                      columnId={columnId}
                      columnCellPopoverOpen={columnCellPopoverOpen}
                      anchorElForLevelsColumn={anchorElForLevelsColumn}
                      carrierDetails={carrierDetails}
                      PathName={PathName}
                      newLevelData={newLevelData}
                      emptyLevelName={emptyLevelName}
                      colorList={colorList}

                      setAnchorElForLevelsColumn={setAnchorElForLevelsColumn}
                      setLevelSelectedData={setLevelSelectedData}
                      setFlagAfterLevelPopoverRender={setFlagAfterLevelPopoverRender}
                      setNewLevelData={setNewLevelData}
                      setEmptyLevelName={setEmptyLevelName}
                      handleAddNewLevel={handleAddNewLevel}
                    />
                  </div>
                  {/* COLUMN LEVEL POPOVER*/}

                  {/* INSERT ROW POPOVER*/}
                  <div>
                    <InsertRowPopover
                      rowId={rowId}
                      rowPopOveropen={rowPopOveropen}
                      anchorElForRow={anchorElForRow}
                      setAnchorElForRow={setAnchorElForRow}
                      addNewRowForSection={addNewRowForSection}
                      selectedRowIndex={selectedRowIndex}
                      setSelectedRowIndex={setSelectedRowIndex}
                      copyRowInSection={copyRowInSection}
                    />
                  </div>
                  {/* INSERT ROW POPOVER*/}

                  {/* ADD NEW SECTION POPOVER */}
                  <div>
                    <AddNewSectionPopover
                      open={open}
                      id={id}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      addNewSection={addNewSection}
                      setDeleteRowPopupFlag={setDeleteRowPopupFlag}
                      setSelectedRowGroup={setSelectedRowGroup}
                      PathName={PathName}
                      carrierDetails={carrierDetails}
                      selectedLinePopover={selectedLinePopover}
                      carrierLevelLength={carrierLevelLength}
                      selectedRowGroupId={selectedRowGroupId}
                      disableAddNewGroup={disableAddNewGroup}
                      setSectionOrRow={setSectionOrRow}
                      copyAboveSection={copyAboveSection}
                    />
                  </div>
                  {/* ADD NEW SECTION POPOVER */}

                  {/* TABLE ACCORDIAN SECTION */}
                  <TableAccordianHeader
                    editDetails={editDetails}

                    carrierDetails={carrierDetails}
                    setCarrierDetails={setCarrierDetails}

                    flagLevelAccordionExpanded={flagLevelAccordionExpanded}

                    flagShowAddIcons={flagShowAddIcons}
                    setFlagShowAddIcons={setFlagShowAddIcons}

                    currentlyAddedCols={currentlyAddedCols}
                    setCurrentlyAddedCols={setCurrentlyAddedCols}

                    tableContainerRef={tableContainerRef}
                    setFlagLevelTablePopup={setFlagLevelTablePopup}

                    flagShowCloseLevelSection={flagShowCloseLevelSection}
                    setFlagShowCloseLevelSection={setFlagShowCloseLevelSection}

                    setAnchorElForLevelsColumn={setAnchorElForLevelsColumn}
                  />
                  {/* TABLE ACCORDIAN SECTION */}


                  {flagShowCloseLevelSection ? (
                    <>
                      <Grid item xs={12} className="custom_grid" id="custom-grid" textAlign="center" sx={{ position: "relative" }}>
                        {deleteRowPopupFlag && (
                          <DeleteCarrierSectionDialog
                            sectionOrRow={sectionOrRow}
                            deleteRowPopupFlag={deleteRowPopupFlag}
                            setDeleteRowPopupFlag={setDeleteRowPopupFlag}
                            deleteSection={deleteSection}
                            deleteRowForSection={deleteRowForSection}
                            extraFunction={handleDeletePopOverExtraFuncs}
                            flagDialogGloballyRender={flagDialogGloballyRender}
                            setFlagDialogGloballyRender={setFlagDialogGloballyRender}
                            setFlagAfterLevelPopoverRender={setFlagAfterLevelPopoverRender}
                          />
                        )}

                        {flagCarrierCustomizationDeleteDialog && (
                          <DeleteCarrierLevelPopUp
                            flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                            setFlagCarrierCustomizationDeleteDialog={setFlagCarrierCustomizationDeleteDialog}

                            flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                            setFlagCarrierCustomizationGlobalDeleteDialog={setFlagCarrierCustomizationGlobalDeleteDialog}
                            carrierDetails={carrierDetails}
                            setCarrierDetails={setCarrierDetails}
                            levelSelectedData={levelSelectedData}
                            setFlagDialogGloballyRender={setFlagDialogGloballyRender}
                            setHideTransperentCell={setHideTransperentCell}
                          />
                        )}

                        {flagCustomizationCarriarSectionDialog && (
                          <LevelCustomizationDialog
                            carrierDetails={carrierDetails}
                            setCarrierDetails={setCarrierDetails}
                            levelSelectedData={levelSelectedData}
                            colorList={colorList}
                            setFlagCustomizationCarriarSectionDialog={setFlagCustomizationCarriarSectionDialog}
                            setFlagCarrierCustomizationGlobalDialog={setFlagCarrierCustomizationGlobalDialog}

                            flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                            setFlagCarrierCustomizationDeleteDialog={setFlagCarrierCustomizationDeleteDialog}
                            setFlagDialogGloballyRender={setFlagDialogGloballyRender}

                            flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                            setFlagCarrierCustomizationGlobalDeleteDialog={setFlagCarrierCustomizationGlobalDeleteDialog}
                            carrierId={searchParams.get("id")}
                            setSelectedAddIcon={setSelectedAddIcon}
                            setHideTransperentCell={setHideTransperentCell}
                            setCurrentlyAddedCols={setCurrentlyAddedCols}
                          />
                        )}

                        {/* --------------- TABLE JSX PART STARTS HERE -------------- */}
                        <Grid container className="" sx={{ justifyContent: "center", position: "relative" }}>
                          <TableContainer
                            id="custom-table"
                            className="vc-main-table-contanier"
                            sx={{ height: "55vh", position: "relative", paddingTop: "1px" }}
                            component={Paper}
                            ref={tableContainerRef}
                          >

                            <div style={{ position: "absolute", top: "40px", left: sliderCoordinates?.x, zIndex: "2002", transition: "all 1s", display: "none", textAlign: "left", width: "325px" }} id="addLevelPopup">
                              <Box className={`AddColumn_Popper ${(slectedAddIcon > carrierDetails?.carrier_level?.length - 7 && carrierDetails?.carrier_level?.length - 7 > 0) ? "position-right" : "position-left"} `}>
                                <Grid item xs={12} size={{ xs: 12 }} sx={{ padding: "10px" }}>
                                  <Grid container>
                                    {carrierDetails?.carrier_level?.length != 0 &&
                                      <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>After Level</Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Select
                                              fullWidth
                                              variant="standard"
                                              size="medium"
                                              MenuProps={{
                                                className: 'vc-addLevel-dropdown-backdrop',
                                              }}
                                              value={newLevelData?.carrier_level_after == "None" ? "None" : newLevelData?.carrier_level_after}
                                              onChange={(e) => {
                                                setSelectedAddIcon(null)
                                                setNewLevelData({ ...newLevelData, carrier_level_after: e.target.value })
                                                if (e.target.value === "None") {
                                                  setSelectedAddIcon("nullPlus")
                                                  setLevelSelectedData({
                                                    name: e.target.name,
                                                    value: e.target.value
                                                  })
                                                } else {
                                                  const levelIndex = carrierDetails?.carrier_level.find((level) => level.carrier_level_id == e.target.value || level?.carrier_level_id_custom == e.target.value)
                                                  setSelectedAddIcon(levelIndex.carrier_level_id || levelIndex.carrier_level_id_custom)
                                                  setLevelSelectedData({
                                                    name: e.target.name,
                                                    value: e.target.value
                                                  })
                                                }
                                                let dataIdAttr = e.target.value == "None" ? (carrierDetails?.carrier_level[0].carrier_level_id || carrierDetails?.carrier_level[0].carrier_level_id_custom) : e.target.value
                                                const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                const popup = document.getElementById('addLevelPopup')
                                                element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                setSliderCoordinates({
                                                  x: e.target.value == "None" ? element.offsetLeft - 8 : (element.offsetLeft + popup.clientWidth) > document.getElementById("vc-level-table").clientWidth ? (element.offsetLeft + element.offsetWidth - popup.clientWidth - 8) : element.offsetLeft + element.offsetWidth - 8,
                                                  y: element.x
                                                })

                                                document.getElementById('addLevelPopup').style.position = "none"
                                              }}
                                            >
                                              <MenuItem value={"None"} label={"None"}>None</MenuItem>
                                              {carrierDetails?.carrier_level?.map((level) => (
                                                <MenuItem value={level.carrier_level_id || level.carrier_level_id_custom} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                              ))}
                                            </Select>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    }

                                    <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Grid container sx={{ alignItems: "center" }}>
                                            <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Level Name</Typography>
                                            <Typography variant="span" sx={{ color: "red", fontSize: "14px", fontWeight: "500" }}>*</Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <TextField
                                            size="small"
                                            variant="standard"
                                            error={emptyLevelName}
                                            fullWidth
                                            value={newLevelData.carrier_level_name}
                                            inputRef={levelNameInputRef}
                                            onChange={(e) => {
                                              setNewLevelData({ ...newLevelData, carrier_level_name: e.target.value })
                                            }}
                                          ></TextField>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Select Color</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Select
                                            fullWidth
                                            variant="standard"
                                            size="medium"
                                            value={{
                                              carrier_level_color_hex_code: newLevelData?.carrier_level_color_hex_code,
                                              carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
                                              carrier_level_color_name: newLevelData?.carrier_level_color_name,
                                            }}
                                            MenuProps={{
                                              className: 'vc-addLevel-dropdown-backdrop',
                                              sx: { zIndex: 2002 }
                                            }}
                                            onChange={(e) => {
                                              setNewLevelData({ ...newLevelData, carrier_level_color_id: Number(e.target.value?.carrier_level_color_id), carrier_level_color_hex_code: e.target.value, carrier_level_color_name: e.target.name })
                                              setSelectedColor({
                                                color: e.target.value.carrier_level_color_name,
                                                hexCode: e.target.value.carrier_level_color_hex_code
                                              })
                                              setNewLevelData({ ...newLevelData, carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code, carrier_level_color_name: e.target.value.carrier_level_color_name, carrier_level_color_id: Number(e.target.value.carrier_level_color_id) })

                                            }}
                                            renderValue={() =>
                                              <div>
                                                <span className='lcd-dropdown-colorbox' style={{ background: newLevelData.carrier_level_color_hex_code }}>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <span>{newLevelData.carrier_level_color_name}</span>
                                              </div>}
                                          >
                                            {
                                              colorList?.map((color, i) => {
                                                return (
                                                  <MenuItem key={i} value={{
                                                    carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                                    carrier_level_color_id: Number(color.carrier_level_color_id),
                                                    carrier_level_color_name: color.carrier_level_color_name,

                                                  }}>
                                                    <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}>

                                                    </span>
                                                    <span value={color}>{color?.carrier_level_color_name}</span>
                                                  </MenuItem>
                                                )
                                              })
                                            }
                                          </Select>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} sx={{ margin: "25px 0px 10px" }}>
                                      <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                        <Grid item xs={5}>
                                          <Button
                                            variant="outlined"
                                            size="medium"
                                            fullWidth
                                            onClick={() => {
                                              setSelectedAddIcon(null)
                                              document.getElementById('addLevelPopup').style.display = "none"
                                              setHideTransperentCell(true)
                                              // HERE PUT THE BACKDROP CONDITION FOR HIDE APPNAVBAR
                                              setFlagAfterLevelPopoverRender(false)
                                              setNewLevelData({
                                                carrier_level_after: null,
                                                carrier_level_id: null,
                                                carrier_level_name: "",
                                                carrier_ba_level_flag: false,
                                                // carrier_level_sequence: 1,
                                                carrier_level_color_id: 1,
                                                carrier_level_color_hex_code: "#1b2430",
                                                carrier_level_color_name: "Default"
                                              })
                                              setFlagAfterLevelPopoverRender(false)
                                              setEmptyLevelName(false)
                                              tableContainerRef.current.style.overflow = 'auto'
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Button
                                            variant="contained"
                                            size="medium"
                                            fullWidth
                                            onClick={() => {
                                              handleAddNewLevel()
                                              setHideTransperentCell(true)
                                              setFlagDialogGloballyRender(false)
                                              // HERE PUT THE BACKDROP CONDITION FOR HIDE APPNAVBAR
                                              tableContainerRef.current.style.overflow = 'auto'

                                            }}
                                          >
                                            Add
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </div>

                            <Table
                              id="vc-level-table"
                              sx={{ position: "relative", width: "auto !important", minWidth: "100%" }}
                              className="templateCarrierTable"
                              aria-label="a dense table"
                              size="small"
                              stickyHeader
                              ref={tableRef}
                            >
                              <TableHead>
                                {/*********************  HIDE LEVEL COLUMN **********************/}
                                {(flagShowAddIcons && !deleteRowPopupFlag) &&
                                  <TableRow className="leveltable_IconRow vc_icons_row table_icon_row">
                                    <TableCell
                                      className="leveltable_IconCell-static sticky-table-head ct-sticky-header-operationCell"
                                      sx={{ border: "none", paddingRight: "8px" }}
                                      data-type="header-plus-static-cells"
                                    >
                                      {/* blank */}
                                    </TableCell>

                                    {(userRoleAdmin && agencyNameBrokersAlliance) &&
                                      <TableCell
                                        className={`leveltable_IconCell-static sticky-table-head ${editDetails ? "ct-sticky-prodmap-header" : "ct-sticky-view-prodmap-header"}`}
                                        data-type="header-plus-static-cells"
                                      >
                                        {/* product mapping col */}
                                      </TableCell>
                                    }

                                    <TableCell
                                      data-type="header-plus-static-cells"
                                      className={`leveltable_IconCell-static sticky-table-head ${editDetails ? "ct-sticky-product-header" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-product-header" : "ct-sticky-view-product-header"}`}
                                    >
                                      {/* product */}
                                    </TableCell>

                                    <TableCell
                                      data-type="header-plus-static-cells"
                                      className={`leveltable_IconCell-static sticky-table-head ${editDetails ? "ct-sticky-criteria-header" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-criteria-header" : "ct-sticky-view-criteria-header"}`}
                                    >
                                      {/* criteria */}
                                    </TableCell>

                                    <TableCell
                                      data-type="header-plus-static-cells"
                                      className={`leveltable_IconCell-static sticky-table-head ${editDetails ? "ct-sticky-header-notes" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-header-notes" : "ct-sticky-view-header-notes"}`}
                                      sx={{ borderRight: leftSideHeaders?.length == 0 ? "2px solid #EFEFF0" : "" }}
                                    >
                                      {/* notes */}
                                    </TableCell>


                                    {/* FOR PLUS ICON IF ADMIN AND MEMBER THEN IT SHOUD START FROM SCROLLABLE PART  */}
                                    {(userRoleAdmin || userRoleMember || subAgency) ?
                                      (
                                        <TableCell
                                          // className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-6-updated"
                                          className={`leveltable_IconCell-static sticky-table-head ${editDetails ? "ct-sticky-bacomp-header" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-bacomp-header" : "ct-sticky-view-bacomp-header"}`}
                                          sx={{ borderRight: "2px solid white", display: 'block', border: "none" }}
                                          data-type="header-plus-static-cells"
                                        >
                                          {/* ba total cc */}
                                        </TableCell>
                                      ) : (
                                        <>
                                          {leftSideHeaders?.map((leftCell, leftIndex) => {
                                            return (
                                              <>
                                                {flagShowAddIcons && slectedAddIcon === leftIndex ?
                                                  (<TableCell
                                                    className={`leveltable_IconCell addLeft_Border carrier-left-head-tablecell sticky-table-head ${editDetails ? "ct-sticky-bacomp-header" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-bacomp-header" : "ct-sticky-view-bacomp-header"}`}
                                                    stickyHeader
                                                    onClick={(e) => {

                                                      document.getElementById('addLevelPopup').style.display = "block";
                                                      const element = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                      element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                      setSliderCoordinates({
                                                        x: element.offsetLeft + element.offsetWidth - 8,
                                                        y: element.x
                                                      })
                                                      setLevelSelectedData(leftCell)
                                                      setFlagCustomizationCarriarSectionDialog(true)
                                                      setFlagAfterLevelPopoverRender(true)
                                                      setHideTransperentCell(false)
                                                      setNewLevelData({ ...newLevelData, carrier_level_after: e.currentTarget.getAttribute('data-id') })
                                                    }}
                                                    sx={{
                                                      borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                      background: `${leftCell?.carrier_level_color_hex_code} !important`,
                                                      cursor: editDetails ? "pointer" : "auto"
                                                    }}
                                                  >
                                                    <IconButton className={(editDetails && slectedAddIcon == leftIndex) ? "buleIcon" : "leveltable_IconButton"}  >
                                                      <AddCircleIcon />
                                                    </IconButton>
                                                  </TableCell >)
                                                  : (<></>)
                                                }
                                              </>
                                            )
                                          })}
                                        </>
                                      )
                                    }

                                    {(userRoleAdmin || userRoleMember) ?
                                      carrierDetails?.carrier_level?.map((levelRow, i) => {
                                        let carrierId = levelRow.carrier_level_id || levelRow.carrier_level_id_custom
                                        const firstCondition = slectedAddIcon == carrierId;
                                        const secondCondition = isColHover == carrierId
                                        const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                        return (
                                          <>
                                            {flagShowAddIcons ?
                                              (<TableCell
                                                sx={{
                                                  borderLeft:
                                                    (i == 0 && slectedAddIcon == null && (isColHover == null || isColHover !== "nullPlus")) ? "1.5px solid #c0c0c0"
                                                      : (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0"
                                                        : "none",
                                                  textAlign: slectedAddIcon === 'nullPlus' ? "left !important" : "right !important"
                                                }}
                                                className={
                                                  isBlueLeftBorder ? "blue-left-border zero-Padding "
                                                    : (slectedAddIcon === null) ? "addLeft_Border pointer-event leveltable_IconCell"
                                                      : (slectedAddIcon === 'nullPlus') ? "leveltable_IconCell" : "leveltable_IconCell"
                                                }
                                                stickyHeader
                                                data-parent-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                onClick={(e) => {
                                                }}
                                              >
                                                <Grid container>
                                                  <Grid item xs={6}>
                                                    {i == 0 && (
                                                      <Grid container position={"relative"}>
                                                        <IconButton
                                                          data-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                          aria-describedby={columnId}
                                                          sx={{
                                                            display: slectedAddIcon != null && slectedAddIcon != "nullPlus" ? "none" : "",
                                                          }}
                                                          className={(editDetails && slectedAddIcon === "nullPlus") || (isColHover == "nullPlus") ? "first-blue-Addicon " : "first-leveltable_IconButton"}
                                                          onClick={(e) => {
                                                            if (tableContainerRef.current) {
                                                              tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                              tableContainerRef.current.style.overflow = 'hidden'
                                                            }
                                                            setSelectedAddIcon('nullPlus')
                                                            setAddColumn(levelRow.carrier_level_id)
                                                            setFlagAfterLevelPopoverRender(true)
                                                            document.getElementById('addLevelPopup').style.display = "block";
                                                            let dataIdAttr = e.currentTarget.getAttribute('data-id')
                                                            const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft - 8,
                                                              y: element.x
                                                            })
                                                            setNewLevelData({ ...newLevelData, carrier_level_after: null })
                                                          }}
                                                          onMouseEnter={(e) => {
                                                            setColHover("nullPlus")
                                                            let dataIdAttr = e.currentTarget.getAttribute('data-id');
                                                            const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);

                                                            if (element) {
                                                              setSliderCoordinates({
                                                                x: element.offsetLeft - 8,
                                                                y: element.x
                                                              })
                                                              let showPoover = document.getElementById('addLevelPopup');
                                                              showPoover.classList.add('addLevelPopup-popover-animation')
                                                            }
                                                          }}
                                                          onMouseLeave={() => setColHover(null)}
                                                        >
                                                          <AddCircleIcon
                                                            fontSize="10px"
                                                          />
                                                        </IconButton>
                                                      </Grid>
                                                    )}
                                                  </Grid>
                                                  <Grid item xs={6}>
                                                    <Grid container display={"flex"} justifyContent={"end"} position={"relative"}>
                                                      <IconButton
                                                        disableRipple
                                                        aria-describedby={columnId}
                                                        data-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                        onClick={(e) => {
                                                          if (tableContainerRef.current) {
                                                            tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                            tableContainerRef.current.style.overflow = 'hidden'
                                                          }
                                                          setSelectedAddIcon(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                          setAddColumn(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                          setFlagAfterLevelPopoverRender(true)
                                                          let dataIdAttr = e.currentTarget.getAttribute('data-id')
                                                          const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                          document.getElementById('addLevelPopup').style.display = "block";
                                                          const butnRect = element.getBoundingClientRect()
                                                          const totalWidth = butnRect.left + 325 - 50
                                                          const gridItem = document.getElementById("custom-grid")
                                                          const gridItemWidth = gridItem.clientWidth + 50
                                                          if (totalWidth >= gridItemWidth) {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft + element.offsetWidth - 8 - 325,
                                                              y: element.x
                                                            })
                                                          } else {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft + element.offsetWidth - 8,
                                                              y: element.x
                                                            })
                                                          }
                                                          setNewLevelData({ ...newLevelData, carrier_level_after: dataIdAttr })
                                                        }}

                                                        onMouseEnter={(e) => {
                                                          setColHover(levelRow.carrier_level_id || levelRow?.carrier_level_id_custom);
                                                          let dataIdAttr = e.currentTarget.getAttribute('data-id');
                                                          const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                          const butnRect = element.getBoundingClientRect()
                                                          const totalWidth = butnRect.left + 325 - 50
                                                          const gridItemWidth = document.getElementById("custom-grid").clientWidth + 50
                                                          if (element) {
                                                            if (totalWidth >= gridItemWidth) {
                                                              setSliderCoordinates({
                                                                x: element.offsetLeft + element.offsetWidth - 8 - 325,
                                                                y: element.x
                                                              })
                                                            } else {
                                                              setSliderCoordinates({
                                                                x: element.offsetLeft + element.offsetWidth - 8,
                                                                y: element.x
                                                              })
                                                            }
                                                            let showPoover = document.getElementById('addLevelPopup');
                                                            showPoover.classList.add('addLevelPopup-popover-animation')
                                                          }
                                                        }}
                                                        onMouseLeave={() => setColHover(null)}
                                                        sx={{ display: slectedAddIcon !== null && slectedAddIcon !== carrierId ? "none" : "" }}
                                                        className={isBlueLeftBorder ? "blue-Addicon" : "leveltable_IconButton"}
                                                        disableElevation={true}
                                                      >
                                                        <AddCircleIcon
                                                          fontSize="10px"
                                                        />
                                                      </IconButton>

                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </TableCell >) : (<></>)
                                            }

                                          </>
                                        );

                                      })
                                      :
                                      rightSideHeaders?.map((rightCell, i) => {
                                        return (
                                          <>
                                            {flagShowAddIcons ?
                                              <TableCell
                                                align="left"
                                                stickyHeader
                                                className="leveltable_IconCell addLeft_Border"
                                                data-id={rightCell?.carrier_level_id || rightCell?.carrier_level_id_custom}
                                                onClick={(e) => {
                                                  setHideTransperentCell(false)
                                                  setFlagAfterLevelPopoverRender(true)
                                                  document.getElementById('addLevelPopup').style.display = "block";
                                                  const element = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                  element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                  setSliderCoordinates({
                                                    x: element.offsetLeft + element.offsetWidth - 8,
                                                    y: element.x
                                                  })
                                                  setNewLevelData({ ...newLevelData, carrier_level_after: e.currentTarget.getAttribute('data-id') })
                                                }}
                                              >
                                                <IconButton className="leveltable_IconButton">
                                                  <AddCircleIcon />
                                                </IconButton>
                                              </TableCell> : <></>
                                            }
                                          </>
                                        );
                                      })
                                    }

                                    {carrierDetails?.carrier_level && carrierDetails?.carrier_level?.length <= 10 &&
                                      (900 - carrierDetails?.carrier_level?.length * 60 >= 60) &&
                                      <TableCell
                                        id="blank-tablecell"
                                        data-type="header-plus-static-cells"
                                        className={`sticky-table-head new-carrier-blank-row ${editDetails ? "addLeft_Border" : ""}`}
                                        sx={{
                                          width: `${900 - carrierDetails?.carrier_level?.length * 60}px`,
                                          backgroundColor: "transparent",
                                          color: "#ffffff"
                                        }}
                                        colSpan={4}
                                      >
                                      </TableCell>
                                    }
                                  </TableRow>
                                }
                                {/*********************  HIDE LEVEL COLUMN **********************/}


                                {/*********************  CARRIER HEADER  **********************/}
                                <TableRow className={flagShowAddIcons && !deleteRowPopupFlag ? "vc_header_row-top" : "vc_header_row"}>
                                  {(hideTransparentCell && !flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog) &&
                                    <TableCell
                                      className={`sticky-table-head table-right-table-cells hide-vc-tbl-cell-by-bg sticky-carrier-table-head-1`}
                                      sx={{
                                        border: "none",
                                        borderBottom: "none",
                                        paddingRight: "8px"
                                      }}
                                    >
                                    </TableCell>
                                  }

                                  {/* ADDED : CREATE FAKE SPACING */}
                                  {(userRoleAdmin && agencyNameBrokersAlliance && editDetails) &&
                                    (flagCarrierCustomizationDeleteDialog || flagCustomizationCarriarSectionDialog) &&
                                    <TableCell className={`ct-delete-dialog-attachment-styles`}></TableCell>
                                  }

                                  {(userRoleAdmin && agencyNameBrokersAlliance) &&
                                    <TableCell
                                      stickyHeader
                                      className={`sticky-table-head carrier-head-columns-padding  ${editDetails ? "addLeft_Border ct-sticky-prodmap-header" : "ct-sticky-view-prodmap-header"}`}
                                      // ${hideTransparentCell ? "ct-sticky-prodmap-body" : "ct-cell-with-left-prop"}
                                      sx={{
                                        backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                        color: "#ffffff",
                                        borderRight: "1px solid white"
                                      }}
                                    >
                                      {/* Product map */}
                                    </TableCell>
                                  }

                                  <TableCell
                                    stickyHeader
                                    className={`sticky-table-head carrier-head-columns-padding ${editDetails ? "addLeft_Border ct-sticky-product-header" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-product-header" : "ct-sticky-view-product-header"}`}
                                    sx={{
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                      color: "#ffffff"
                                    }}
                                  >
                                    Product
                                  </TableCell>

                                  <TableCell
                                    stickyHeader
                                    className={`ct-sticky-criteria-header carrier-head-columns-padding sticky-table-head ${editDetails ? "addLeft_Border ct-sticky-criteria-header sticky-table-head" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-criteria-header" : "ct-sticky-view-criteria-header"}`}
                                    // className={`sticky-carrier-table-head-3 carrier-head-columns-padding ${editDetails ? "addLeft_Border" : ""} sticky-table-head`}

                                    sx={{
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                      color: "#ffffff"
                                    }}
                                  >
                                    Criteria
                                  </TableCell>

                                  <TableCell
                                    stickyHeader
                                    // className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-5 ${editDetails ? "addLeft_Border" : ""}`}
                                    className={`ct-sticky-header-notes sticky-table-head carrier-head-columns-padding ${editDetails ? "addLeft_Border ct-sticky-header-notes" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-header-notes" : "ct-sticky-view-header-notes"}`}
                                    sx={{
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                      borderRight: (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) ? "2px solid #EFEFF0" : "",
                                      color: "#ffffff"
                                    }}
                                  >
                                    Notes
                                  </TableCell>

                                  {(userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ?
                                    <TableCell
                                      stickyHeader
                                      // className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-6-updated ${editDetails ? "addLeft_Border" : ""}`}                                
                                      className={`sticky-table-head carrier-head-columns-padding  ${editDetails ? "addLeft_Border ct-sticky-bacomp-header" : (brokersAllianceAgent || userRoleMember) ? "ct-sticky-agentview-bacomp-header" : "ct-sticky-view-bacomp-header"}`}
                                      sx={{
                                        borderRight: carrierDetails?.carrier_level?.length == 0 ? "2px solid white" : (!editDetails ? "3px solid #EFEFF0" : editDetails && '1px solid #000000'),
                                        color: "#ffffff",
                                        // HERE UPDATE BG COLOR FOR THIS COLUMN 
                                        backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#F1C232" : deleteRowPopupFlag ? "#F1C232" : "#F1C232",
                                      }}
                                    >
                                      BA Total Comp
                                    </TableCell>
                                    :
                                    <>
                                      {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                      {/* {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" && */}
                                      <>
                                        {leftSideHeaders?.map((leftCell, leftIndex) => {
                                          return (
                                            <TableCell
                                              // className={`header-color carrier-head-columns-padding carrier-table-head-6-updated-agent ${editDetails ? "addLeft_Border" : ""}`}
                                              className={`header-color carrier-head-columns-padding ct-header-agent ${editDetails ? "addLeft_Border" : ""}`}
                                              sx={{
                                                background: `${(slectedAddIcon === leftCell.carrier_level_id || slectedAddIcon === leftCell.carrier_level_id_custom) ? "#1b2430" : leftCell?.carrier_level_color_hex_code} !important`,
                                                cursor: editDetails ? "pointer" : "auto",
                                                borderRight: leftCell && leftCell?.carrier_ba_level_flag ? "2px solid white" : ""
                                                // HERE COMMENTED BECAUSE ALREADY SEPARATED LEFT AND RIGH SIDE HEADER
                                                // borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                              }}
                                              stickyHeader
                                              onDoubleClick={() => {
                                                if (editDetails) {
                                                  setLevelSelectedData(leftCell)
                                                  setFlagCustomizationCarriarSectionDialog(true)
                                                  setSelectedAddIcon(leftCell.carrier_level_id || leftCell.carrier_level_id_custom)
                                                  setFlagShowAddIcons(false)
                                                  setHideTransperentCell(false)
                                                }
                                              }}
                                            >
                                              <div>
                                                {leftCell?.carrier_level_name}
                                              </div>
                                            </TableCell>
                                          )
                                        })}
                                      </>
                                      {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                      {/* } */}
                                    </>
                                  }

                                  {
                                    (userRoleAdmin || userRoleMember) &&

                                      userDetail[0]?.user_agency_data?.parent_agency_id == null ?
                                      carrierDetails?.carrier_level?.map((levelRow, i) => {
                                        let carrierId = levelRow.carrier_level_id || levelRow.carrier_level_id_custom
                                        const firstCondition = slectedAddIcon == carrierId;
                                        const secondCondition = isColHover == carrierId
                                        const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                        return (
                                          <TableCell
                                            className={`header-color cell-width  ${isBlueLeftBorder ? "blue-left-border" : editDetails ? "addLeft_Border add_padding" : ""} `}
                                            align="left"
                                            stickyHeader
                                            sx={{
                                              padding: "0px",
                                              zIndex: slectedAddIcon == null ? "2" :
                                                ((slectedAddIcon == levelRow.carrier_level_id || slectedAddIcon == levelRow?.carrier_level_id_custom) && !flagCustomizationCarriarSectionDialog) ? "1300 !important" :
                                                  ((slectedAddIcon == levelRow.carrier_level_id || slectedAddIcon == levelRow?.carrier_level_id_custom) && flagCustomizationCarriarSectionDialog) ? "2000 !important" : "2 !important",
                                              cursor: editDetails ? "pointer" : "auto",
                                              background: `${levelRow?.carrier_level_color_hex_code} !important`,
                                              borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : (i === 0 && editDetails) && "1.5px solid #EFEFF0",
                                            }}
                                            onDoubleClick={() => {
                                              if (editDetails) {
                                                setLevelSelectedData(levelRow)
                                                setFlagCustomizationCarriarSectionDialog(true)
                                                setSelectedAddIcon(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                setFlagShowAddIcons(false)
                                                setHideTransperentCell(false)
                                              }
                                            }}
                                          >
                                            <div className="vc-tablecell-header-div">{levelRow?.carrier_level_name}</div>
                                          </TableCell>
                                        );
                                      })
                                      :
                                      rightSideHeaders?.map((rightCell, i) => {
                                        return (
                                          <TableCell
                                            className={`header-color scrolling_header_cell ${editDetails ? "addLeft_Border" : ""}`}
                                            align="left"
                                            stickyHeader
                                            onDoubleClick={() => {
                                              if (editDetails) {
                                                setLevelSelectedData(rightCell)
                                                setFlagCustomizationCarriarSectionDialog(true)
                                                setSelectedAddIcon(rightCell.carrier_level_id || rightCell.carrier_level_id_custom)
                                                setFlagShowAddIcons(false)
                                                setHideTransperentCell(false)
                                              }
                                            }}
                                            sx={{
                                              background: `${(slectedAddIcon == rightCell.carrier_level_id || slectedAddIcon == rightCell.carrier_level_id_custom) ? "#1b2430" : rightCell?.carrier_level_color_hex_code} !important`,
                                              cursor: editDetails ? "pointer" : "auto",
                                              borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1px solid #1565c0" : "",
                                            }}
                                          >
                                            <div style={{ width: "100%" }}>
                                              {rightCell?.carrier_level_name}
                                            </div>
                                          </TableCell>
                                        );
                                      })
                                  }

                                  {carrierDetails?.carrier_level && carrierDetails?.carrier_level?.length <= 10 &&
                                    900 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                    <TableCell
                                      id="blank-tablecell"
                                      className={`sticky-table-head ${editDetails ? "addLeft_Border" : ""}`}
                                      colSpan={4}
                                      sx={{
                                        width: `${900 - carrierDetails?.carrier_level?.length * 60}px`,
                                        backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                        color: "#ffffff"
                                      }}
                                    >
                                    </TableCell>
                                  }
                                </TableRow>
                                {/*********************  CARRIER HEADER  **********************/}
                              </TableHead>

                              <ClickAwayListener onClickAway={() => setSelectedRowGroup((prevId) => ({ ...prevId, selectedId: null }))} >
                                <TableBody>
                                  {(hideTransparentCell && !deleteRowPopupFlag && (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog)) &&
                                    <TableRow>
                                      <TableCell
                                        className={`vc-table-transparent-cell sticky-table-body first-add-section-cell`}
                                        sx={{
                                          position: "sticky",
                                          zIndex: "110 !important",
                                          left: "0",
                                          background: flagCarrierCustomizationDeleteDialog || flagCustomizationCarriarSectionDialog || deleteRowPopupFlag ? 'transparent' : '#efeff0',
                                        }} >
                                        <Grid container>
                                          <Grid item xs={6}>
                                            <Grid container justifyContent={'end'} >
                                              <div
                                                className={`${(selectedLine == 0) ? "selected-dot-icon" : "default-dot-icon"}`}
                                                onClick={(e) => {
                                                  setSelectedRowGroup({
                                                    selectId: carrierDetails?.carrier_product[0].carrier_product_id || carrierDetails?.carrier_product[0].carrier_product_id_custom,
                                                    selectedId: null
                                                  })
                                                  setSelectedLinePopover({
                                                    groups: carrierDetails?.carrier_product[0],
                                                    index: 0
                                                  })
                                                  setSelectedLine(0)
                                                  setAnchorEl(e.currentTarget)
                                                }}
                                                onMouseEnter={() => {
                                                  setSelectedLine(0)
                                                }}
                                                onMouseLeave={() => {
                                                  if (anchorEl == null) {
                                                    setSelectedLine(null)
                                                  }
                                                }}
                                              >
                                              </div>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={6} margin={'auto'}>
                                            {selectedLine == 0 && <Divider fullWidth color="#1565C0" className="vc-rowgroup-divider" />}
                                          </Grid>
                                        </Grid>
                                      </TableCell>

                                      <TableCell
                                        colSpan={numsOfCarrierLevelCols + 6}
                                        className={`shubh-2 vc-table-transparent-cell sticky-table-body first-add-section-cell`}
                                      >
                                        {selectedLine == 0 && <Divider fullWidth color="#1565C0" className="vc-rowgroup-divider" />}
                                      </TableCell>
                                    </TableRow>
                                  }
                                  {carrierDetails?.carrier_product?.map((groups, j) => {
                                    return (
                                      groups?.carrier_product_data?.map((groupData, i) => {

                                        // groups 
                                        const selectedGroupId = groups?.carrier_product_id || groups.carrier_product_id_custom
                                        const sectedRowId = groups?.carrier_product_data[i].carrier_product_data_id || groups?.carrier_product_data[i].carrier_product_data_id_custom
                                        const carrierGroupDataLen = groups?.carrier_product_data?.length
                                        const productName = productMappingListData?.find((item) => item?.product_ai_id == groupData?.carrier_product_ai_mapping_data?.product_ai_id)?.product_name

                                        // groupData
                                        let flagSaprator = groups?.carrier_product_data.length == i + 1 ? true : false;
                                        let present = groups?.carrier_product_id || groups?.carrier_product_id_custom
                                        let leftSideData = groupData?.carrier_product_commission_data?.filter((obj) => obj?.carrier_ba_level_flag == true)
                                        let rightSideData = groupData?.carrier_product_commission_data?.filter((obj) => !obj?.carrier_ba_level_flag)

                                        return (
                                          <>

                                            <TableRow
                                              data-row-id={groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}
                                              data-section-id={present}
                                              key={groups?.carrier_product_id || groups?.carrier_product_id_custom}
                                              className={`${editDetails ? 'vc-editable-tabel-row vc-editable-tabel-row-highlight' : "vc-table-row"} 
                                              ${selectedGroupId === selectedRowGroupId.selectedId ? carrierGroupDataLen <= 1 ? "border-all border-container" :
                                                  `${i === 0 ? "border-top" : flagSaprator ? "border-bottom" : "bordered-left-right"} border-container` :
                                                  sectedRowId === isSelectedRow ? "border-all border-container" : ""
                                                }`
                                              }
                                              onClick={() => {
                                                setSelectedRowGroup((prevId) => ({ ...prevId, selectedId: selectedGroupId }))
                                              }}
                                              onMouseEnter={() => {
                                                if (editDetails) {
                                                  if (groupData.carrier_product_data_id) {
                                                    setAddRow(groupData.carrier_product_data_id)
                                                  }
                                                  else if (groupData?.carrier_product_data_id_custom) {
                                                    setAddRow(groupData.carrier_product_data_id_custom)
                                                  }
                                                  else {
                                                    setAddRow(null)
                                                  }
                                                }
                                              }}
                                              onMouseLeave={editDetails ? () => { anchorElForRow == null ? setAddRow(null) : console.log("..") } : () => { }}>
                                              {(hideTransparentCell && (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog)) &&
                                                <TableCell
                                                  className={`vc-table-transparent-cell 
                                                sticky-table-body 
                                                sticky-carrier-table-body-1 
                                                add_padding hide-vc-tbl-cell-by-bg `}
                                                  sx={{
                                                    border: "none",
                                                    borderBottom: "none",
                                                    paddingRight: "8px !important"
                                                  }}
                                                >
                                                  {(addRow === groupData.carrier_product_data_id || addRow === groupData.carrier_product_data_id_custom) &&
                                                    <Box className="vc-addremove-btn-container" aria-describedby={selectedGroupId}>
                                                      <IconButton
                                                        className="vc-addremove-buttons"
                                                        onClick={(e) => {
                                                          handleAddRowPopOverClick(e, groupData, j, i)
                                                        }}
                                                      >
                                                        <AddCircleOutlineIcon className="vc-addremove-buttons" />
                                                      </IconButton>

                                                      <IconButton
                                                        className="vc-addremove-buttons"
                                                        // ADDED AFTER : LAST ROW OF THE TABLE IS REMAINING 
                                                        disabled={PathName === "/carriers/add_carrier" ? (carrierGroupDataLen <= 1 || carrierLevelLength >= 2) : (carrierDetails?.carrier_product.length <= 1 && carrierGroupDataLen <= 1)}
                                                        // disabled={PathName === "/carriers/add_carrier" ? (carrierGroupDataLen <= 1 || carrierLevelLength >= 2) : false}
                                                        onClick={(event) => {
                                                          event.stopPropagation()
                                                          setSelectedRowGroup((prevId) => ({ ...prevId, selectId: null, selectedId: null }))
                                                          setSelectedRowIndex({
                                                            groupData: groupData,
                                                            levelIdx: j,
                                                            groupIdx: i
                                                          })
                                                          setSectionOrRow("row")
                                                          setSelectedRow(sectedRowId)
                                                          setFlagDialogGloballyRender(true)
                                                          setDeleteRowPopupFlag(true)
                                                          setTimeout(() => {
                                                            let rowToHighLight = document.querySelector(`[data-row-id="${groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}"]`);
                                                            let rect = rowToHighLight.getBoundingClientRect();
                                                            let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                                            let tableContainer = document.getElementById('custom-table').getBoundingClientRect();
                                                            let top = rect.y;
                                                            let left = tableContainer.left + 24;
                                                            let width = tableContainer.width - 22;
                                                            let height = 40;
                                                            hiddenHighLighter.style.top = `${top}px`;
                                                            hiddenHighLighter.style.width = `${width}px`;
                                                            hiddenHighLighter.style.left = `${left}px`;
                                                            hiddenHighLighter.style.height = `${height}px`;
                                                            hiddenHighLighter.style.border = `#3479C9`;
                                                          }, 900)
                                                          document.body.style.overflow = 'hidden';
                                                          document.getElementById("carrier_view_container").style.overflow = "hidden"

                                                        }}>
                                                        <RemoveCircleOutlineIcon className="vc-addremove-buttons" />
                                                      </IconButton>
                                                    </Box>
                                                  }
                                                </TableCell>
                                              }

                                              {/* ADDED : CREATE FAKE SPACING */}
                                              {(userRoleAdmin && agencyNameBrokersAlliance && editDetails) &&
                                                (flagCarrierCustomizationDeleteDialog || flagCustomizationCarriarSectionDialog) &&
                                                <TableCell className={`ct-delete-dialog-attachment-styles`}></TableCell>
                                              }

                                              {(userRoleAdmin && agencyNameBrokersAlliance) &&
                                                <TableCell
                                                  key={'product_ai_id-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                                  className={`sticky-table-body sticky-table-color table-left-padding-tableCell ct-sticky-prodmap-body
                                                ${editDetails ? "ct-sticky-prodmap-header addLeft_Border" : "ct-sticky-view-prodmap-header"} add_padding `}
                                                  //  ${hideTransparentCell ? "ct-sticky-prodmap-body" : "ct-cell-with-left-prop"} 
                                                  sx={{
                                                    borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                    borderRight: editDetails ? "" : "1px solid #cfcfcf"
                                                  }}
                                                >
                                                  <Grid item sx={{ height: "100%" }}>
                                                    <Grid container sx={{ height: '100%', alignItems: "center", justifyContent: "center" }}>

                                                      {groupData?.carrier_product_ai_mapping_data && groupData?.carrier_product_ai_mapping_data?.product_ai_id ?
                                                        (
                                                          <>
                                                            <Grid item
                                                              onMouseEnter={() => { setStoredRowDataId(groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom); }}
                                                              onMouseLeave={() => { setStoredRowDataId(null) }}
                                                              sx={{ cursor: !!groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "not-allowed !important" : "pointer" }}
                                                            >
                                                              <Grid container>
                                                                <Tooltip
                                                                  arrow
                                                                  PopperProps={{ className: 'product-mapping-tooltip' }}
                                                                  placement="right-end"
                                                                  title={
                                                                    <div>
                                                                      <div style={{ fontSize: "12px", fontWeight: "500", color: "#676767", marginBottom: "5px" }}>
                                                                        AI Product Mapping ID & Name
                                                                      </div>
                                                                      <div style={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}>
                                                                        ({groupData?.carrier_product_ai_mapping_data?.product_ai_id}) {productName}
                                                                      </div>
                                                                    </div>
                                                                  }
                                                                  componentsProps={{
                                                                    tooltip: {
                                                                      sx: {
                                                                        bgcolor: "#F8F8F8",
                                                                        padding: "10px",
                                                                        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                                                                      },
                                                                    },
                                                                  }}
                                                                >
                                                                  <IconButton
                                                                    disabled={groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id}
                                                                    onClick={() => {
                                                                      setProductRowGroupDetails({
                                                                        groups: groups,
                                                                        groupData: groupData
                                                                      });
                                                                      setFlagProductMappingDialog(true);
                                                                    }}
                                                                  >
                                                                    {(!carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id || storedRowDataId != (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)) ? (
                                                                      <Icons
                                                                        iconName="mappedIcon"
                                                                        iconColor={carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "#0DBD0D" : "#BDBDBD"}
                                                                        iconWidth="18px"
                                                                        iconHeight="18px"
                                                                      />
                                                                    ) : (
                                                                      <Icons
                                                                        iconName="editIcon"
                                                                        iconColor="#000000"
                                                                        iconWidth="17px"
                                                                        iconHeight="17px"
                                                                      />
                                                                    )}
                                                                  </IconButton>
                                                                </Tooltip>
                                                              </Grid>
                                                            </Grid>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <Grid item
                                                              onMouseEnter={() => { setStoredRowDataId(groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom); }}
                                                              onMouseLeave={() => { setStoredRowDataId(null); }}
                                                              sx={{ cursor: !!groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? "not-allowed !important" : "pointer" }}
                                                            >
                                                              <IconButton
                                                                onClick={() => {
                                                                  setProductRowGroupDetails({ groups: groups, groupData: groupData });
                                                                  setFlagProductMappingDialog(true);
                                                                }}
                                                                disabled={!!groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id}
                                                              >
                                                                {groupData?.carrier_product_data_id_custom || !carrierDetails?.carrier_ai_mapping_data?.carrier_ai_id ? (
                                                                  <Icons
                                                                    iconName="cloneIcon"
                                                                    iconColor="#BDBDBD"
                                                                    iconWidth="17px"
                                                                    iconHeight="17px"
                                                                  />
                                                                ) : storedRowDataId == groupData?.carrier_product_data_id ? (
                                                                  <Icons
                                                                    iconName="addIcon"
                                                                    iconColor="#000000"
                                                                    iconWidth="17px"
                                                                    iconHeight="17px"
                                                                  />
                                                                ) : (
                                                                  <Icons
                                                                    iconName="cloneIcon"
                                                                    iconColor="#1565C0"
                                                                    iconWidth="17px"
                                                                    iconHeight="17px"
                                                                  />
                                                                )}
                                                              </IconButton>
                                                            </Grid>
                                                          </>
                                                        )}
                                                    </Grid>
                                                  </Grid>
                                                </TableCell>
                                              }

                                              <TableCell
                                                key={'carrier_product_name-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                                className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border ct-sticky-product-body" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-product-body" : "ct-sticky-view-product-body"} add_padding `}
                                                sx={{
                                                  borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                }}
                                                onMouseEnter={() => {
                                                  setStoredProductId({
                                                    carrier_product_data_id: groupData.carrier_product_data_id || null,
                                                    carrier_product_data_id_custom: groupData.carrier_product_data_id_custom || null
                                                  })
                                                }}
                                                onMouseLeave={() => {
                                                  setStoredProductId({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
                                                }}
                                              // onKeyUp={(e) => {
                                              //   handleCarrierLevelData(
                                              //     e,
                                              //     groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                              //     groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_name'
                                              //   )
                                              // }}
                                              >
                                                <Tooltip
                                                  arrow
                                                  open={
                                                    (
                                                      (storedProductId.carrier_product_data_id !== null && storedProductId.carrier_product_data_id == groupData.carrier_product_data_id) ||
                                                      (storedProductId.carrier_product_data_id_custom !== null && storedProductId.carrier_product_data_id_custom == groupData.carrier_product_data_id_custom)
                                                    )
                                                    && String(groupData?.carrier_product_name || "").trim().length > 26
                                                  }
                                                  key={groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}
                                                  title={groupData?.carrier_product_name ?? ""}
                                                  disableHoverListener={groupData?.carrier_product_name?.length < 26}
                                                  disableFocusListener={groupData?.carrier_product_name?.length < 26}
                                                  PopperProps={{ className: 'vc-tabel-tooltip' }}
                                                  placement="right-end"
                                                >
                                                  <input
                                                    style={{ cursor: "default" }}
                                                    onFocus={handleFocus}  // Apply background when input is focused
                                                    onBlur={handleBlur}
                                                    onClick={handleClickInput}
                                                    className="vc-table-right-table-cells-input vc-table-product-tooltip-styles"
                                                    readOnly={!editDetails}
                                                    value={groupData?.carrier_product_name ?? ""}
                                                    onChange={(e) => {
                                                      handleCarrierLevelData(
                                                        e,
                                                        groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                        groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                        'carrier_product_name'
                                                      )
                                                    }}
                                                    onKeyUp={(e) => {
                                                      if (e.key == "Enter") {
                                                        setStoredProductId({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
                                                      }
                                                    }}
                                                  />
                                                </Tooltip>
                                              </TableCell>

                                              <TableCell
                                                id={`criteria-field-${groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}`}
                                                key={'carrier_product_criteria-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                                sx={{
                                                  borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                }}
                                                onMouseEnter={() => {
                                                  setStoredProductCriteriaId({
                                                    carrier_product_data_id_custom: groupData?.carrier_product_data_id_custom || null,
                                                    carrier_product_data_id: groupData?.carrier_product_data_id || null
                                                  })
                                                  if (editDetails) {
                                                    if (groupData?.carrier_product_criteria?.length > 0) {
                                                      setShowCriteriaText({
                                                        carrier_product_id: groups?.carrier_product_id || 0,
                                                        carrier_product_id_custom: groups?.carrier_product_id_custom || 0,
                                                        carrier_product_data_id: groupData?.carrier_product_data_id || 0,
                                                        carrier_product_data_id_custom: groupData?.carrier_product_data_id_custom || 0,
                                                      })
                                                    }
                                                    else { setShowCriteriaText(defaultCriteriaText) }
                                                  }
                                                }}
                                                onMouseLeave={() => {
                                                  setStoredProductCriteriaId({
                                                    carrier_product_data_id_custom: null,
                                                    carrier_product_data_id: null
                                                  })
                                                  if (editDetails) { setShowCriteriaText(defaultCriteriaText) }
                                                }}
                                                className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding ct-sticky-criteria-body" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-criteria-body" : "ct-sticky-view-criteria-body"}`}
                                              // className={`sticky-table-body sticky-table-color sticky-carrier-table-body-3 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                              >
                                                {groupData?.carrier_product_criteria?.length > 0 ?
                                                  <Tooltip
                                                    arrow
                                                    open={
                                                      (
                                                        (storedProductCriteriaId.carrier_product_data_id !== null && storedProductCriteriaId.carrier_product_data_id == groupData.carrier_product_data_id) ||
                                                        (storedProductCriteriaId.carrier_product_data_id_custom !== null && storedProductCriteriaId.carrier_product_data_id_custom == groupData.carrier_product_data_id_custom)
                                                      )
                                                    }
                                                    placement="right-end"
                                                    PopperProps={{ className: 'criteria-tooltip' }}
                                                    title={<div dangerouslySetInnerHTML={{ __html: generateTooltipContent(groupData?.carrier_product_criteria) }} />}
                                                    componentsProps={{
                                                      tooltip: {
                                                        sx: {
                                                          bgcolor: "#1B2430",
                                                          color: "#ffffff",
                                                          padding: "10px",
                                                          maxWidth: "480px",
                                                          "& .MuiPopper-root": {
                                                            zIndex: 2100,
                                                          },
                                                        },
                                                      },
                                                    }}
                                                  >
                                                    {(
                                                      (showCriteriaText['carrier_product_id'] == groups?.carrier_product_id && showCriteriaText['carrier_product_data_id'] == groupData?.carrier_product_data_id) ||
                                                      (showCriteriaText['carrier_product_id_custom'] == groups?.carrier_product_id_custom && showCriteriaText['carrier_product_data_id_custom'] == groupData?.carrier_product_data_id_custom)
                                                    ) ?
                                                      <input
                                                        name="carrier_product_criteria"
                                                        autoComplete="off"
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        onClick={(event) => handleClickInput(event, groups, groupData, "carrier_product_criteria")}
                                                        className="vc-table-right-table-cells-input vc-table-content-input-wrapper vc-add-criteria-input"
                                                        readOnly={!editDetails}
                                                        style={{ cursor: "pointer" }}
                                                        value={groupData?.carrier_product_criteria.length > 0 ? "Edit Criteria" : ""}
                                                        onKeyDown={(event) => {
                                                          if (editDetails) {
                                                            if (event.key === "Enter") {
                                                              setFlagCriteriDialog(true)
                                                              setCriteriaCellData(groups)
                                                              setShowCriteriaText({
                                                                ...showCriteriaText,
                                                                carrier_product_data_id: null,
                                                                carrier_product_id_custom: null
                                                              })
                                                              setProductIds({
                                                                productId: groups?.carrier_product_id,
                                                                productDataIds: groupData.carrier_product_data_id,
                                                                productDataCustomIds: groupData.carrier_product_data_id_custom
                                                              })
                                                            }
                                                          }
                                                          if (event.key == "Enter") {
                                                            setStoredProductCriteriaId({
                                                              carrier_product_data_id_custom: null,
                                                              carrier_product_data_id: null
                                                            })
                                                          }

                                                        }}
                                                      />
                                                      :
                                                      <input
                                                        onFocus={handleFocus}  // Apply background when input is focused
                                                        onBlur={handleBlur}
                                                        onClick={(event) => handleClickInput(event, groups, groupData, "carrier_product_criteria")}
                                                        className={`vc-table-right-table-cells-input vc-table-content-input-wrapper`}
                                                        value={generateInputContent(groupData?.carrier_product_criteria)}
                                                        style={{
                                                          maxWidth: "165px",
                                                          textOverflow: "ellipsis",
                                                          flexWrap: "nowrap",
                                                          overflow: "hidden",
                                                          display: "inline-block",
                                                          border: "none",
                                                          cursor: editDetails ? "pointer" : "default"
                                                        }}
                                                        onKeyDown={(event) => {
                                                          if (editDetails) {
                                                            if (event.key === "Enter") {
                                                              setFlagCriteriDialog(true)
                                                              setCriteriaCellData(groups)
                                                              setProductIds({
                                                                productId: groups?.carrier_product_id,
                                                                productDataIds: groupData.carrier_product_data_id,
                                                                productDataCustomIds: groupData.carrier_product_data_id_custom
                                                              })
                                                            }
                                                          }
                                                          if (event.key == "Enter") {
                                                            setStoredProductCriteriaId({
                                                              carrier_product_data_id_custom: null,
                                                              carrier_product_data_id: null
                                                            })
                                                          }
                                                        }}
                                                      />
                                                    }
                                                  </Tooltip>
                                                  :
                                                  <input
                                                    // disabled={!editDetails}
                                                    name="carrier_product_criteria"
                                                    autoComplete="off"
                                                    onFocus={handleFocus}  // Apply background when input is focused
                                                    onBlur={handleBlur}
                                                    className="vc-table-right-table-cells-input vc-add-criteria-input"
                                                    readOnly={!editDetails}
                                                    style={{ cursor: editDetails ? 'pointer' : 'default' }}
                                                    value={brokersAllianceAdmin && editDetails ? "Add Criteria" : ""}
                                                    onClick={(event) => handleClickInput(event, groups, groupData, "carrier_product_criteria")}
                                                    onKeyDown={(event) => {
                                                      if (editDetails) {
                                                        if (event.key === "Enter") {
                                                          setFlagCriteriDialog(true)
                                                          setCriteriaCellData(groups)
                                                          setProductIds({
                                                            productId: groups?.carrier_product_id,
                                                            productDataIds: groupData.carrier_product_data_id,
                                                            productDataCustomIds: groupData.carrier_product_data_id_custom
                                                          })
                                                        }
                                                      }
                                                    }}
                                                  />
                                                }
                                              </TableCell>

                                              <TableCell
                                                key={'carrier_product_note-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                                sx={{
                                                  borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                  borderRight: ((userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) ? "2px solid #EFEFF0"
                                                    : (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) && editDetails ? '1px solid #000000' : '')
                                                }}
                                                className={`sticky-table-body sticky-table-color ct-sticky-body-notes table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding ct-sticky-body-notes" : (brokersAllianceAgent || userRoleMember || subAgency) ? "ct-sticky-agentview-body-notes" : "ct-sticky-view-body-notes"}`}
                                                // className={`sticky-table-body sticky-table-color sticky-carrier-table-body-4 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                onMouseEnter={() => { setStoredProductNoteId({ carrier_product_data_id: groupData.carrier_product_data_id || null, carrier_product_data_id_custom: groupData.carrier_product_data_id_custom || null }) }}
                                                onMouseLeave={() => { setStoredProductNoteId({ carrier_product_data_id: null, carrier_product_data_id_custom: null }) }}
                                              >
                                                <Tooltip
                                                  arrow
                                                  open={
                                                    (
                                                      (storedProductNotesId.carrier_product_data_id !== null && storedProductNotesId.carrier_product_data_id == groupData.carrier_product_data_id) ||
                                                      (storedProductNotesId.carrier_product_data_id_custom !== null && storedProductNotesId.carrier_product_data_id_custom == groupData.carrier_product_data_id_custom)
                                                    )
                                                    && String(groupData?.carrier_product_note || "").trim().length > 25
                                                  }
                                                  title={groupData?.carrier_product_note?.replace(/->/g, "") ?? ""}
                                                  disableHoverListener={groupData?.carrier_product_note?.length < 25}
                                                  disableFocusListener={groupData?.carrier_product_note?.length < 25}
                                                  PopperProps={{ className: 'vc-tabel-tooltip' }}
                                                  placement="right-end"
                                                >
                                                  <input
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onClick={handleClickInput}
                                                    readOnly={!editDetails}
                                                    value={editDetails ? groupData?.carrier_product_note?.replace(/->/g, "") ?? "" : groupData?.carrier_product_note?.replace(/->/g, "")}
                                                    className="vc-table-right-table-cells-input"
                                                    style={{
                                                      cursor: "default",
                                                      textOverflow: groupData?.carrier_product_note?.length > 25 ? "ellipsis" : "",
                                                      overflow: groupData?.carrier_product_note?.length > 25 ? "hidden" : "auto"
                                                    }}
                                                    onChange={(e) => {
                                                      handleCarrierLevelData(
                                                        e,
                                                        groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                        groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                        'carrier_product_note'
                                                      )
                                                    }}
                                                    onKeyUp={(e) => {
                                                      if (e.key == "Enter") {
                                                        setStoredProductNoteId({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
                                                      }
                                                    }}
                                                  />
                                                </Tooltip>
                                              </TableCell>

                                              {(userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ?
                                                (
                                                  <TableCell
                                                    // className={`sticky-table-body sticky-table-color sticky-carrier-table-body-6 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                    className={`sticky-table-body sticky-table-color ct-sticky-bacomp-body table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding ct-sticky-bacomp-body" : (brokersAllianceAgent || userRoleMember) ? "ct-sticky-agentview-bacomp-body" : "ct-sticky-view-bacomp-body"}`}
                                                    sx={{
                                                      borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                      borderRight: (!editDetails ? "2px solid #EFEFF0" : editDetails && '1px solid #000000')
                                                    }}
                                                    onMouseEnter={() => {
                                                      setStoredProductBaTotalId({
                                                        carrier_product_data_id: groupData.carrier_product_data_id || null,
                                                        carrier_product_data_id_custom: groupData.carrier_product_data_id_custom || null
                                                      })
                                                    }}
                                                    onMouseLeave={() => { setStoredProductBaTotalId({ carrier_product_data_id: null, carrier_product_data_id_custom: null }) }}
                                                  >
                                                    <Tooltip
                                                      arrow
                                                      open={
                                                        (
                                                          (storedProductBatotalId.carrier_product_data_id !== null && storedProductBatotalId.carrier_product_data_id == groupData.carrier_product_data_id) ||
                                                          (storedProductBatotalId.carrier_product_data_id_custom !== null && storedProductBatotalId.carrier_product_data_id_custom == groupData.carrier_product_data_id_custom)
                                                        )
                                                        && String(groupData?.carrier_product_ba_total_comp || "").trim().length > 15
                                                      }
                                                      title={groupData?.carrier_product_ba_total_comp ?? ""}
                                                      disableHoverListener={groupData?.carrier_product_ba_total_comp?.length < 15}
                                                      disableFocusListener={groupData?.carrier_product_ba_total_comp?.length < 15}
                                                      PopperProps={{ className: 'vc-tabel-tooltip' }}
                                                      placement="right-end"
                                                    >
                                                      <input
                                                        onFocus={handleFocus}  // Apply background when input is focused
                                                        onBlur={handleBlur}
                                                        onClick={handleClickInput}
                                                        value={groupData?.carrier_product_ba_total_comp ?? ""}
                                                        className="vc-table-right-table-cells-input"
                                                        readOnly={!editDetails}
                                                        style={{
                                                          cursor: 'default',
                                                          textOverflow: groupData?.carrier_product_ba_total_comp?.length > 15 ? "ellipsis" : "",
                                                          overflow: groupData?.carrier_product_ba_total_comp?.length > 15 ? "hidden" : "auto"
                                                        }}
                                                        onChange={(e) => {
                                                          handleCarrierLevelData(
                                                            e,
                                                            groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                            groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                            'carrier_product_ba_total_comp'
                                                          )
                                                        }}
                                                        onKeyUp={(e) => {
                                                          if (e.key == "Enter") {
                                                            setStoredProductBaTotalId({ carrier_product_data_id: null, carrier_product_data_id_custom: null })
                                                          }
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </TableCell>
                                                ) : (
                                                  <>
                                                    {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                                    {/* {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" && */}
                                                    <>
                                                      {leftSideData?.map((leftDataCell, i) => {
                                                        return (
                                                          <TableCell
                                                            sx={{
                                                              borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                              // borderRight: i == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                              // borderRight: i == 0 ? "2px solid #EFEFF0" : "",
                                                              borderRight: leftDataCell && leftDataCell?.carrier_ba_level_flag ? "2px solid red" : "",
                                                              // HERE BORDER LEFT COMMENTED BCZ IN AGENT ISSUE IS OCCUED DURING SCROLL ******
                                                              borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                            }}
                                                            className={`sticky-table-color table-left-padding-tableCell ${((editDetails && slectedAddIcon == i) || isColHover === i) ? "blue-left-border" : editDetails ? " addLeft_Border add_padding" : ""}`}
                                                          >
                                                            {editDetails ?
                                                              <input
                                                                onFocus={handleFocus}  // Apply background when input is focused
                                                                onBlur={handleBlur}
                                                                onClick={handleClickInput}
                                                                className="vc-table-right-table-cells-input"
                                                                disabled={!editDetails}
                                                                value={leftDataCell?.carrier_product_commission_value ?? ""}
                                                                onChange={(e) => {
                                                                  handleCarrierLevelCommissionData(
                                                                    e,
                                                                    groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                                    groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                                    leftDataCell?.carrier_product_commission_id || leftDataCell?.carrier_commission_value_id_custom
                                                                  )
                                                                }}
                                                              />
                                                              :
                                                              leftDataCell?.carrier_product_commission_value ?? ""
                                                            }
                                                          </TableCell>
                                                        )
                                                      })
                                                      }
                                                    </>
                                                    {/* } */}
                                                  </>
                                                )
                                              }

                                              {(userRoleAdmin || userRoleMember) ?
                                                groupData?.carrier_product_commission_data?.map((commissionData, ind) => {
                                                  let carrierId = commissionData.carrier_level_id || commissionData.carrier_level_id_custom
                                                  const firstCondition = slectedAddIcon == carrierId;
                                                  const secondCondition = isColHover == carrierId

                                                  const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog
                                                  const isAddLeftBorder = editDetails;
                                                  return (
                                                    <TableCell
                                                      suppressContentEditableWarning
                                                      className={`vc-table-right-table-cells table-left-padding-tableCell ${isBlueLeftBorder ? "blue-left-border" : isAddLeftBorder ? "addLeft_Border add_padding" : ""}`}
                                                      sx={{
                                                        borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                        zIndex: slectedAddIcon == null ? "2" : (slectedAddIcon == commissionData.carrier_level_id || slectedAddIcon == commissionData?.carrier_level_id_custom) ? "2000" : "2",
                                                        borderLeft: (ind === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                      }}
                                                    >
                                                      {editDetails ?
                                                        <input className="vc-table-right-table-cells-input"
                                                          onFocus={handleFocus}  // Apply background when input is focused
                                                          onBlur={handleBlur}
                                                          onClick={handleClickInput}
                                                          disabled={!editDetails}
                                                          onChange={(e) => {
                                                            handleCarrierLevelCommissionData(
                                                              e,
                                                              groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                              commissionData?.carrier_product_commission_id || commissionData?.carrier_commission_value_id_custom
                                                            )
                                                          }}
                                                          value={commissionData?.carrier_product_commission_value ?? ""}
                                                        />
                                                        :
                                                        commissionData?.carrier_product_commission_value ?? ""
                                                      }
                                                    </TableCell>
                                                  )
                                                })
                                                :
                                                // HERE IS THE BA LEVEL BODY DATA SAPERATION AND APPLY BORDER RIGHT FOR DIFFERENTIATION
                                                rightSideData?.map((rightDataCell, i) => {
                                                  let carrierId = rightDataCell.carrier_level_id || rightDataCell.carrier_level_id_custom
                                                  const firstCondition = slectedAddIcon == carrierId;
                                                  const secondCondition = isColHover == carrierId
                                                  const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                                  let applyBorderRight = leftSideHeaders?.some((leftHeader) => Number(leftHeader?.carrier_level_id) == Number(rightDataCell?.carrier_level_id) && leftHeader?.carrier_ba_level_flag == true);
                                                  return (
                                                    <TableCell
                                                      className={`vc-table-right-table-cells table-left-padding-tableCell ${(editDetails && slectedAddIcon == null) ? "addLeft_Border add_padding" : isBlueLeftBorder ? "blue-left-border" : ""}`}
                                                      sx={{
                                                        borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                        borderRight: applyBorderRight ? "2px solid  #EFEFF0" : "",
                                                        borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                        // HERE COMMENTED BECAUSE ALREADY SEPARATED LEFT AND RIGH SIDE HEADER
                                                        // borderRight: i == 0 ? "2px solid  #EFEFF0" : "",
                                                      }}

                                                    >
                                                      {editDetails ?
                                                        <input
                                                          className="vc-table-right-table-cells-input"
                                                          onFocus={handleFocus}  // Apply background when input is focused
                                                          onBlur={handleBlur}
                                                          onClick={handleClickInput}
                                                          value={rightDataCell?.carrier_product_commission_value ?? ""}
                                                          disabled={!editDetails}
                                                          onChange={(e) => {
                                                            handleCarrierLevelCommissionData(
                                                              e,
                                                              groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                              groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                              rightDataCell?.carrier_product_commission_id || rightDataCell?.carrier_commission_value_id_custom
                                                            )
                                                          }}
                                                        />
                                                        :
                                                        rightDataCell?.carrier_product_commission_value ?? ""
                                                      }
                                                    </TableCell>
                                                  )
                                                })
                                              }
                                              {/* HERE ADD CONDITION FOR ADDING AND DELETING TABLE CELLS SO PUT THE OR CONDITION  */}
                                              {groupData?.carrier_product_commission_data.length <= 10 &&
                                                ((carrierDetails?.carrier_level?.length > 0 || carrierDetails?.carrier_level?.length == 0) && (900 - carrierDetails?.carrier_level?.length * 60 >= 60)) &&
                                                (<TableCell
                                                  className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                  colSpan={4}
                                                  sx={{
                                                    borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                    // borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
                                                    width: `${900 - carrierDetails?.carrier_level?.length * 60}px`
                                                  }}
                                                >
                                                </TableCell>)
                                              }
                                            </TableRow>

                                            {hideTransparentCell &&
                                              <>
                                                {i == groups?.carrier_product_data.length - 1 && (
                                                  <TableRow>
                                                    <TableCell
                                                      className={`vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                      sx={{
                                                        padding: '0px',
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: "5px",
                                                        background: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog || deleteRowPopupFlag ? "transparent" : "#efeff0",
                                                        position: "sticky",
                                                        zIndex: "110 !important",
                                                        left: "0"
                                                      }}
                                                    >
                                                      {
                                                        (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog) &&
                                                        <div className={"vw-img-div vw-img-click-div"}
                                                          onClick={(e) => {
                                                            if (carrierDetails?.carrier_product?.length == 1) {
                                                              setSelectedRowGroup({
                                                                selectId: carrierDetails?.carrier_product[0].carrier_product_id || carrierDetails?.carrier_product[0].carrier_product_id_custom,
                                                                selectedId: null
                                                              })
                                                              setSelectedLinePopover({
                                                                groups: groups,
                                                                index: j + 1
                                                              })
                                                              setAnchorEl(e.currentTarget)
                                                            }
                                                            else {
                                                              let idTempArr = j == carrierDetails?.carrier_product?.length - 1 ? carrierDetails?.carrier_product[j] : carrierDetails?.carrier_product[j + 1];
                                                              handleClick(e, idTempArr.carrier_product_id || idTempArr.carrier_product_id_custom)
                                                              setSelectedLinePopover({
                                                                groups: groups,
                                                                index: j + 1
                                                              })
                                                            }
                                                            setSelectedLine(groups?.carrier_product_id || groups.carrier_product_id_custom)
                                                            setFlagAddRow(true)
                                                            setSectionOrRow("section")
                                                          }}
                                                        >
                                                          <div
                                                            style={{ background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "#1B243080" }}
                                                            className={`${(selectedLine == groups?.carrier_product_id || selectedLine == groups?.carrier_product_id_custom) ? "selected-dot-icon" : "default-dot-icon"}`}
                                                            onClick={() => {
                                                              let idTempArr = j == carrierDetails?.carrier_product?.length - 1 ? carrierDetails?.carrier_product[j - 2] : carrierDetails?.carrier_product[j + 1];
                                                              setSelectedRowGroup({
                                                                selectId: idTempArr?.carrier_product_id || idTempArr?.carrier_product_id_custom,
                                                                selectedId: null
                                                              })
                                                              setSelectedLine(groups?.carrier_product_id || groups.carrier_product_id_custom)
                                                              setFlagAddRow(true)
                                                              setSelectedLinePopover({
                                                                groups: groups,
                                                                index: j + 1
                                                              })
                                                              setSectionOrRow("section")
                                                            }}
                                                            onMouseEnter={() => {
                                                              setSelectedLine(groups?.carrier_product_id || groups.carrier_product_id_custom)
                                                            }}
                                                            onMouseLeave={() => {
                                                              if (anchorEl == null) {
                                                                setSelectedLine(null)
                                                              }
                                                              else {
                                                              }
                                                            }}
                                                          >
                                                          </div>
                                                        </div>
                                                      }
                                                      {(selectedLine == present) &&
                                                        <Divider sx={{ width: "54%", background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent" }} className="vc-rowgroup-divider" />
                                                      }
                                                    </TableCell>

                                                    <TableCell
                                                      className={`shubh-1 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                      colSpan={leftSideData.length + rightSideData.length}
                                                      sx={{
                                                        padding: '0px',
                                                        height: "1px",
                                                      }}
                                                    >
                                                      {(selectedLine == present) &&
                                                        <Divider
                                                          fullWidth
                                                          className="vc-rowgroup-divider"
                                                          sx={{
                                                            background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent"
                                                          }}
                                                        />
                                                      }
                                                    </TableCell>

                                                    {PathName == '/carriers/view_carrier' ? (
                                                      <>
                                                        <TableCell
                                                          className={`shubh-2 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                          colSpan={leftSideData.length + rightSideData.length < 10 ? 10 : leftSideData.length + rightSideData.length}
                                                          sx={{
                                                            padding: '0px',
                                                            height: "1px",
                                                          }}
                                                        >
                                                          {(
                                                            selectedLine == present) &&
                                                            <Divider
                                                              fullWidth
                                                              className="vc-rowgroup-divider"
                                                              sx={{
                                                                background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent"
                                                              }}
                                                            />
                                                          }
                                                        </TableCell>
                                                      </>
                                                    ) : (
                                                      <TableCell
                                                        className={`vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                        colSpan={20}
                                                        sx={{
                                                          padding: '0px',
                                                          height: "1px",
                                                        }}
                                                      >
                                                        {(selectedLine == present) &&
                                                          <Divider
                                                            fullWidth
                                                            className="vc-rowgroup-divider"
                                                            sx={{
                                                              background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent"
                                                            }}
                                                          />
                                                        }
                                                      </TableCell>
                                                    )
                                                    }
                                                  </TableRow>
                                                )}
                                              </>
                                            }
                                          </>
                                        )
                                      }))
                                  })}
                                </TableBody>
                              </ClickAwayListener>
                            </Table>
                          </TableContainer>
                        </Grid>
                        {/* --------------- TABLE JSX PART STARTS HERE -------------- */}
                      </Grid>
                    </>
                  ) : (<></>)}
                </Grid>
              </Grid>
              {/* ----------- TABLE SECTION ----------- */}

            </Grid>
          </Grid>
        </Grid>
        {/* CONTENT SECTION */}
      </div>

    </>
  );
};

export default ViewCarrier;
