import React, { useEffect, useRef, useState } from 'react'
import "../../css/ViewCarriarStyles/CarrierEffectiveDialog.css"

import { Close } from '@mui/icons-material';
import { Button, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';

function SaveSetEffectiveDateDialog(props) {

    const {
        setFlagSetEffectiveDateDialog, handleUpdateCarrierAfterEffectiveDate, carrierEffectiveDatesDetails,
        editDetails, carrierDetails, flagCreateEffectiveDateRender
    } = props

    const [openDatepickerPopup, setOpenDatepickerPopup] = useState(false)
    const [renderDatepicker, setRenderDatepicker] = useState(false)
    const [selectedEffectiveDate, setSelectedEffectiveDate] = useState({
        carrier_effective_date_id: "",
        carrier_effective_date_from: "",
    });
    const [selectedDate, setSelectedDate] = useState("");
    const [dateType, setDateType] = useState("")

    useEffect(() => {
        if (carrierDetails?.carrier_effective_date_data && carrierEffectiveDatesDetails?.[0]?.carrier_effective_dates) {
            const matchedDate = carrierEffectiveDatesDetails[0]?.carrier_effective_dates?.find((date) => date?.carrier_effective_date_id == carrierDetails?.carrier_effective_date_data?.carrier_effective_date_id);

            if (matchedDate) {
                setSelectedEffectiveDate({
                    carrier_effective_date_id: Number(matchedDate?.carrier_effective_date_id),
                    carrier_effective_date_from: moment(matchedDate?.carrier_effective_date_from)?.format("YYYY-MM-DD")
                });
            }
        }
    }, [carrierEffectiveDatesDetails, carrierDetails]);


    const handleChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue == "createNew") {
            setDateType("new")
            // Open DatePicker and set today's date
            setRenderDatepicker(true);
            setOpenDatepickerPopup(true);

            setSelectedEffectiveDate({
                carrier_effective_date_from: moment().format("YYYY-MM-DD"),
            });
        } else {
            setDateType("existing")
            const selectedId = Number(selectedValue);

            const selectedDateObj = carrierEffectiveDatesDetails?.[0]?.carrier_effective_dates?.find((date) => Number(date.carrier_effective_date_id) == selectedId);

            if (!selectedDateObj) {
                console.error("Selected date not found.");
                return;
            }

            // Set the selected date from dropdown
            setSelectedEffectiveDate({
                carrier_effective_date_id: selectedDateObj.carrier_effective_date_id,
                carrier_effective_date_from: moment(selectedDateObj.carrier_effective_date_from).format("YYYY-MM-DD"),
            });

            // Hide DatePicker if previously open
            setRenderDatepicker(false);
            setOpenDatepickerPopup(false);
        }
    };

    const rollBackSubmission = () => {
        setOpenDatepickerPopup(false)
        setRenderDatepicker(false)
        setSelectedDate(null)
        setSelectedEffectiveDate({
            carrier_effective_date_id: "",
            carrier_effective_date_from: "",
        })
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} className='ct-ed-head-item'>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} sx={{ textAlign: "center" }}>
                            <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: "600", color: "#1565C0" }}>
                                Set Effective Date
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "end" }}>
                            <Close onClick={() => { setFlagSetEffectiveDateDialog(false); setDateType(""); }} sx={{ cursor: "pointer", verticalAlign: "middle" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} sx={{ margin: "20px auto" }}>
                    <Grid container>
                        <Grid item xs={11} sx={{ textAlign: "center", }} className='ct-ed-note-item'>
                            <Typography variant='span' >
                                Note: This is the date from when carrier data changed will get effective form
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ position: "relative", alignItems: "center" }}>
                            <Grid container>
                                <Grid item className='ct-ef-date-item'>
                                    <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <Grid item xs={12} className='ct-ed-label-dropdown-item'>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                <Grid item xs={4.5} className='ct-ed-date-label'>
                                                    {flagCreateEffectiveDateRender ? "New Effective Date" : renderDatepicker ? "New Effective Date" : "Effective Date"}
                                                </Grid>

                                                {editDetails && !flagCreateEffectiveDateRender ?
                                                    (<Grid item xs={7.5}>
                                                        {renderDatepicker ? (
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    open={openDatepickerPopup}
                                                                    value={selectedDate}
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            setSelectedDate(date);
                                                                            setSelectedEffectiveDate({
                                                                                carrier_effective_date_from: moment(date).format("YYYY-MM-DD"),
                                                                            });
                                                                        }
                                                                        // if (date) {
                                                                        //     setSelectedDate(date)
                                                                        //     setOpenDatepickerPopup(false)
                                                                        //     setRenderDatepicker(true)
                                                                        // }
                                                                        // else {
                                                                        //     setRenderDatepicker(false)
                                                                        //     setOpenDatepickerPopup(false)
                                                                        // }
                                                                    }}
                                                                    onClose={() => {
                                                                        setTimeout(() => {
                                                                            setSelectedDate((prevDate) => {
                                                                                if (prevDate) {
                                                                                    setOpenDatepickerPopup(false);
                                                                                    setRenderDatepicker(true);
                                                                                } else {
                                                                                    setRenderDatepicker(false);
                                                                                    setOpenDatepickerPopup(false);
                                                                                }
                                                                                return prevDate;
                                                                            });
                                                                        }, 300);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            placeholder="yyyy-mm-dd"
                                                                            className='ct-ed-render-field'
                                                                            onClick={() => setOpenDatepickerPopup(true)}
                                                                            inputProps={{ ...params.inputProps, readOnly: true }}
                                                                            onChange={(e) => {
                                                                                if (e.target.value === "") {
                                                                                    setSelectedDate(null);
                                                                                    setSelectedEffectiveDate({
                                                                                        carrier_effective_date_from: "",
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                    inputFormat="yyyy-MM-dd"
                                                                    PopperProps={{
                                                                        style: {
                                                                            zIndex: 2100,
                                                                            boxShadow: "0px 0px 10px 0px #0000001f"
                                                                        },
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        ) : (
                                                            <Select
                                                                fullWidth
                                                                defaultValue={selectedEffectiveDate?.carrier_effective_date_id || ""}
                                                                value={selectedEffectiveDate?.carrier_effective_date_id || ""}
                                                                onChange={handleChange}
                                                                displayEmpty
                                                                MenuProps={{
                                                                    style: { zIndex: 2100 },
                                                                    className: "ct-ed-dropdown-boxShadow"
                                                                }}
                                                                className='ct-ed-dropdown'
                                                                renderValue={(e) =>
                                                                    selectedEffectiveDate.carrier_effective_date_id ? (
                                                                        <div style={{ textAlign: "left" }}>
                                                                            <span> {moment(selectedEffectiveDate?.carrier_effective_date_from).format("YYYY-MM-DD")}</span>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ textAlign: "left", color: "gray" }}>
                                                                            Please select date
                                                                        </div>
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value="createNew">
                                                                    <Typography color="primary">Create new effective date</Typography>
                                                                </MenuItem>
                                                                {carrierEffectiveDatesDetails?.[0]?.carrier_effective_dates?.map((date) => (
                                                                    <MenuItem
                                                                        key={date.carrier_effective_date_id}
                                                                        value={Number(date.carrier_effective_date_id)}
                                                                    >
                                                                        {moment(date?.carrier_effective_date_from)?.format("YYYY-MM-DD")}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    </Grid>
                                                    ) : (flagCreateEffectiveDateRender &&
                                                        <Grid item xs={7.5}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    open={openDatepickerPopup}
                                                                    value={selectedDate}
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            setSelectedDate(date);
                                                                            setSelectedEffectiveDate({
                                                                                carrier_effective_date_from: moment(date).format("YYYY-MM-DD"),
                                                                            });
                                                                        }
                                                                    }}
                                                                    onClose={() => {
                                                                        setTimeout(() => {
                                                                            setSelectedDate((prevDate) => {
                                                                                if (prevDate) {
                                                                                    setOpenDatepickerPopup(false);
                                                                                    setRenderDatepicker(true);
                                                                                } else {
                                                                                    setRenderDatepicker(false);
                                                                                    setOpenDatepickerPopup(false);
                                                                                }
                                                                                return prevDate;
                                                                            });
                                                                        }, 300);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            fullWidth
                                                                            placeholder="yyyy-mm-dd"
                                                                            className='ct-ed-render-field'
                                                                            onClick={() => setOpenDatepickerPopup(true)}
                                                                            inputProps={{ ...params.inputProps, readOnly: true }}
                                                                            onChange={(e) => {
                                                                                if (e.target.value === "") {
                                                                                    setSelectedDate(null);
                                                                                    setSelectedEffectiveDate({
                                                                                        carrier_effective_date_from: "",
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                    inputFormat="yyyy-MM-dd"
                                                                    PopperProps={{
                                                                        style: {
                                                                            zIndex: 2100,
                                                                            boxShadow: "0px 0px 10px 0px #0000001f"
                                                                        },
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>

                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {(renderDatepicker && !flagCreateEffectiveDateRender) &&
                                    <Grid item sx={{ bottom: "18px", right: "30px", position: "absolute" }}>
                                        <IconButton sx={{ padding: "5px" }} onClick={() => rollBackSubmission()}>
                                            <CancelIcon sx={{ color: "#1565C0", fontSize: "22px" }} />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ margin: "0px 0px 30px" }}>
                    <Grid container sx={{ justifyContent: 'center', gap: "15px" }}>
                        <Grid item className='ct-ed-save-close-btn-item'>
                            <Button
                                variant='outlined'
                                size='medium'
                                fullWidth
                                className='ct-ed-close-btn'
                                onClick={() => { setFlagSetEffectiveDateDialog(false); setDateType("") }}
                            >
                                Close
                            </Button>
                        </Grid>
                        <Grid item className='ct-ed-save-close-btn-item'>
                            <Button
                                variant='contained'
                                size='medium'
                                fullWidth
                                className='ct-ed-save-btn'
                                disabled={selectedEffectiveDate.carrier_effective_date_from == null || selectedEffectiveDate.carrier_effective_date_from == ""}
                                onClick={() => { handleUpdateCarrierAfterEffectiveDate(selectedEffectiveDate, dateType) }}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SaveSetEffectiveDateDialog