import React, { useContext } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import myAdvisorLogo from "../../assets/myAdvisorLogo.png";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";
import CloseIcon from '@mui/icons-material/Close';
import "./Navbar.css"

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  const { UpdateSnackbarMessage, FlagApiLoader } = useContext(AuthContext);
  const { delegateAccessFlag, delegateUserData, userDetail, } = useContext(AuthContext).state;
  const agencyName = userDetail && userDetail[0]?.user_agency_data?.agency_name


  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar sx={{ minHeight: "60px !important" }}>
          <Grid container alignItems="center" width={"98%"}>
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            <Grid item xs display={{ xs: "inline", sm: "none" }}></Grid>

            <Grid
              item
              justifyContent="center"
              textAlign="center"
              sx={{ color: "#1565C0", marginLeft: { xs: "10px", md: "32px" } }}
            >
              <img
                src={myAdvisorLogo}
                height={"40px"}
                width={"100%"}
                style={{ marginTop: '3px' }} />
            </Grid>

            {/* <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
              </Search>
            </Grid> */}

            {/* DELEGATE USER WHEN TRUE OTHERWISE BLANK */}
            <Grid item xs sx={{ marginRight: '5px', }}>
              <Grid container sx={{ alignItems: "center" }}>
                {delegateAccessFlag == true ?
                  (<>
                    {userDetail.length &&
                      <Grid item xs sx={{ marginLeft: "15px", textAlign: 'center' }}>
                        <Typography
                          variant="span"
                          sx={{
                            fontSize: '15px',
                            fontWeight: '600',
                            color: "#000000",
                          }}
                        >
                          You are logged in as : <Typography
                            variant="span"
                            sx={{
                              fontSize: '15px',
                              fontWeight: '600',
                              color: "#1565C0",
                            }}
                          > {userDetail[0]?.user_name} </Typography>
                        </Typography>
                      </Grid>
                    }
                  </>) :
                  (<>
                    <Grid item xs></Grid>
                  </>)
                }
              </Grid>
            </Grid>

            {/* EXIT ACCESS */}
            <Grid item sx={{ marginLeft: '5px' }}>
              {delegateAccessFlag == true &&
                <Button
                  variant="contained"
                  startIcon={<CloseIcon sx={{ margin: '0px !important' }} />}
                  sx={{
                    padding: "7px 15px",
                    margin: "0px 5px",
                    '& .MuiButton-startIcon': {
                      margin: '0px 5px 0px 0px !important'
                    },
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    FlagApiLoader(true)
                    RevokeDelegateAccessForAdmin((res) => {
                      if (res?.data?.status) {
                        window.location.href = "/"
                        FlagApiLoader(false)
                      }
                      else {
                        if (res?.data?.error?.message) {
                          UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                          });
                          FlagApiLoader(false)
                        }
                        else {
                          UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                          });
                          FlagApiLoader(false)
                        }
                      }
                    }, (err) => {
                      UpdateSnackbarMessage({
                        status: "error",
                        message: err?.message
                      });
                      FlagApiLoader(false)
                    })
                  }}
                >
                  Exit Access

                  {/* BEFORE DELAEGATE CHANGES */}
                  {/* Back to Admin Panel */}
                </Button>
              }
            </Grid>

            <Grid item className="nav-agency-name-item">
              <Typography variant="span">
                {agencyName}
              </Typography>
            </Grid>

            {/* PROFILE DROPDOWN */}
            <Grid item>
              <NavbarUserDropdown />
            </Grid>

          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
